import { FeedItem } from 'model/FeedItem'
import { Pack } from 'model/Pack'

import {
  savePackObject,
  updatePackObject,
  deletePackObject,
  saveUserObject,
  updateUserObject,
} from 'services/searchService'

import { isMock } from 'utils/envs'

import {
  initializeRemoteServerService as initializeFirebaseRemoteServerService,
  getUser as getRemoteUser,
  getUserByUsername as getRemoteMockUserByUsername,
  checkIfUsernameExists as checkIfUsernameExistsRemote,
  postUser as postRemoteUser,
  editUser as editRemoteUser,
  putUserPortfolio as putRemoteUserPortfolio,
  postUserPortfolioImage as postRemoteUserPortfolioImage,
  incrementUserGallery as incrementRemoteUserGallery,
  getPack as getRemotePack,
  getPacks as getRemotePacks,
  getLastPacks as getRemoteLastPacks,
  postPack as postRemotePack,
  putPack as putRemotePack,
  deletePack as deleteRemotePack,
  postReview as postRemoteReview,
  deleteReview as deleteRemoteReview,
  getUserEvents as getRemoteUserEvents,
  getUserEvent as getRemoteUserEvent,
  postAgendaEvent as postRemoteAgendaEvent,
  putAgendaEvent as putRemoteAgendaEvent,
  deleteAgendaEvent as deleteRemoteAgendaEvent,
  putAgendaEventGalleryData as putRemoteAgendaEventGalleryData,
  getUserGalleries as getRemoteUserGalleries,
  getGallery as getRemoteGallery,
  getGalleryPhoto as getRemoteGalleryPhoto,
  getGalleryPhotos as getRemoteGalleryPhotos,
  getGalleryMarkedPhotosIds as getRemoteGalleryMarkedPhotosIds,
  createGalleryArchive as createRemoteGalleryArchive,
  createGalleryChangesListener as createRemoteGalleryChangesListener,
  postGallery as postRemoteGallery,
  putGallery as putRemoteGallery,
  postGalleryPhoto as postRemoteGalleryPhoto,
  deleteGallery as deleteRemoteGallery,
  deleteGalleryPhoto as deleteRemoteGalleryPhoto,
  deleteGalleryPhotos as deleteRemoteGalleryPhotos,
  postGallerySharingOptions as postRemoteGallerySharingOptions,
  postGalleryMarkedPhotoIds as postRemoteGalleryMarkedPhotoIds,
  postUserImage as postRemoteUserImage,
  postPackImage as postRemotePackImage,
  postPackImages as postRemotePackImages,
  postPortfolioImage as postRemotePortfolioImage,
  postPortfolioImages as postRemotePortfolioImages,
  deleteImage as deleteRemoteImage,
  deleteImages as deleteRemoteImages,
  addWatermarkToGalleryPhoto,
  postUserWatermark,
  fetchUserWatermarks,
  fetchUserWatermark,
  putUserWatermark,
  deleteUserWatermark,
} from '../firebaseServer'

import {
  getFeed as getMockFeed,
  getPack as getMockPack,
  getPacks as getMockPacks,
  postPack as postMockPack,
  putPack as putMockPack,
  deletePack as deleteMockPack,
  postReview as postMockReview,
  deleteReview as deleteMockReview,
  getUser as getMockUser,
  getUserByUsername as getMockUserByUsername,
  checkIfUsernameExists as checkIfUsernameExistsMock,
  postUser as postMockUser,
  editUser as editMockUser,
  putUserPortfolio as putMockUserPortfolio,
  postUserPortfolioImage as postMockUserPortfolioImage,
  incrementUserGallery as incrementMockUserGallery,
  getUserEvents as getMockUserEvents,
  getUserEvent as getMockUserEvent,
  postAgendaEvent as postMockAgendaEvent,
  putAgendaEvent as putMockAgendaEvent,
  deleteAgendaEvent as deleteMockAgendaEvent,
  getUserWorkingHours as getMockUserWorkingHours,
  setUserWorkingHours as setMockUserWorkingHours,
  getUserGalleries as getMockUserGalleries,
  getGallery as getMockGallery,
  getGalleryPhoto as getMockGalleryPhoto,
  getGalleryPhotos as getMockGalleryPhotos,
  getGalleryMarkedPhotosIds as getMockGalleryMarkedPhotosIds,
  createGalleryArchive as createMockGalleryArchive,
  createGalleryChangesListener as createMockGalleryChangesListener,
  postGallery as postMockGallery,
  putGallery as putMockGallery,
  postGalleryPhoto as postMockGalleryPhoto,
  deleteGallery as deleteMockGallery,
  deleteGalleryPhoto as deleteMockGalleryPhoto,
  deleteGalleryPhotos as deleteMockGalleryPhotos,
  postGallerySharingOptions as postMockGallerySharingOptions,
  postGalleryMarkedPhotoIds as postMockGalleryMarkedPhotoIds,
  postUserImage as postMockUserImage,
  postPackImage as postMockPackImage,
  postPackImages as postMockPackImages,
  postPortfolioImage as postMockPortfolioImage,
  postPortfolioImages as postMockPortfolioImages,
  deleteImage as deleteMockImage,
  deleteImages as deleteMockImages,
} from '../mockServer'

export const getFeed = (size, category) => {
  if (isMock()) {
    return getMockFeed(size)
      .then(feed => feed.map(feedItem => new FeedItem(feedItem)))
  }
  return getRemoteLastPacks(size, category)
    .then(
      feed => {
        return (feed || [])
          .filter(feedItem => feedItem.user && feedItem.pack.packInfo.media?.images?.length > 0) // to be resilient, make sure pack has a user, eg: when user is removed and by any error, packs don't
          .map(feedItem => new FeedItem(feedItem))
      }
    )
}

export const getPack = packId => {
  if (isMock()) {
    return getMockPack(packId)
      .then(pack => new Pack(pack))
  }
  return getRemotePack(packId)
}

export const getPacks = (packIds, user) => {
  if (isMock()) {
    return getMockPacks(packIds, user)
      .then(packs => packs.map(pack => new Pack(pack)))
  }

  return getRemotePacks(packIds, user)
}

export const postPack = (packData, visibility) => {
  if (isMock()) {
    return postMockPack(packData, visibility)
      .then(returnData => {
        savePackObject(packData)
        return returnData
      })
  }

  return postRemotePack(packData, visibility)
    .then(returnData => {
      savePackObject(packData)
      return returnData
    })
}

export const putPack = (packData, visibility) => {
  let putPackPromise

  if (isMock()) {
    putPackPromise = putMockPack
  } else {
    putPackPromise = putRemotePack
  }

  return putPackPromise(packData, visibility)
    .then(returnData => {
      updatePackObject(packData)
      return returnData
    })
}

export const deletePack = pack => {
  if (isMock()) {
    return deleteMockPack(pack)
      .then(() => deletePackObject(pack))
  }

  return deleteRemotePack(pack)
    .then(() => deletePackObject(pack))
}

export const postReview = (packId, review) => {
  if (isMock()) {
    return postMockReview(packId, review)
  }

  return postRemoteReview(packId, review)
}

export const deleteReview = (packId, reviewId) => {
  if (isMock()) {
    return deleteMockReview(packId, reviewId)
  }

  return deleteRemoteReview(packId, reviewId)
}

export const getUser = userId => {
  if (isMock()) {
    return getMockUser(userId)
  }

  return getRemoteUser(userId)
}

export const getUserByUsername = userId => {
  if (isMock()) {
    return getMockUserByUsername(userId)
  }

  return getRemoteMockUserByUsername(userId)
}

export const checkIfUsernameExists = username => {
  if (isMock()) {
    return checkIfUsernameExistsMock(username)
  }

  return checkIfUsernameExistsRemote(username)
}

export const editUser = (user, merge, updateSearchService = true) => {
  if (isMock()) {
    return editMockUser(user, merge)
      .then(() => {
        updateSearchService && updateUserObject(user)
      })
  }

  return editRemoteUser(user, merge)
    .then(() => {
      if (updateSearchService) {
        updateUserObject(user)

        const newPackUserData = {
          uid: user.uid,
          name: user.name,
          avatar: user.avatar,
        }

        const newUserPacksData = user.packs?.cards.map(card => ({
          id: card.id,
          user: newPackUserData,
        }))

        newUserPacksData && updatePackObject(newUserPacksData)
      }
    })
}

export const putUserPortfolio = (userUid, cards, layoutsData, layout) => {
  if (isMock()) {
    return putMockUserPortfolio(userUid, cards, layoutsData, layout)
  }

  return putRemoteUserPortfolio(userUid, cards, layoutsData, layout)
}

export const postUserPortfolioImage = (userUid, image) => {
  if (isMock()) {
    return postMockUserPortfolioImage(userUid, image)
  }

  return postRemoteUserPortfolioImage(userUid, image)
}

export const incrementUserGallery = (user, increment = true, portefolioGalleryCounter = false) => {
  if (isMock()) {
    return incrementMockUserGallery(user, increment, portefolioGalleryCounter)
  }

  return incrementRemoteUserGallery(user, increment, portefolioGalleryCounter)
}

export const postUser = user => {
  if (isMock()) {
    return postMockUser(user)
      .then(() => saveUserObject(user))
  }

  return postRemoteUser(user)
    .then(() => saveUserObject(user))
}

export const getUserEvents = (userUid, options) => {
  if (isMock()) {
    return getMockUserEvents(userUid, options)
  }

  return getRemoteUserEvents(userUid, options)
}

export const getUserEvent = (userUid, eventId) => {
  if (isMock()) {
    return getMockUserEvent(userUid, eventId)
  }

  return getRemoteUserEvent(userUid, eventId)
}

export const postAgendaEvent = (userUid, event) => {
  if (isMock()) {
    return postMockAgendaEvent(userUid, event)
  }

  return postRemoteAgendaEvent(userUid, event)
}

export const putAgendaEvent = (userUid, event) => {
  if (isMock()) {
    return putMockAgendaEvent(userUid, event)
  }

  return putRemoteAgendaEvent(userUid, event)
}

export const deleteAgendaEvent = (userUid, eventId) => {
  if (isMock()) {
    return deleteMockAgendaEvent(userUid, eventId)
  }

  return deleteRemoteAgendaEvent(userUid, eventId)
}

export const putAgendaEventGalleryData = (userUid, galleryEventId, gallery) => {
  if (isMock()) {
    return putMockAgendaEvent(userUid, galleryEventId, gallery)
  }

  return putRemoteAgendaEventGalleryData(userUid, galleryEventId, gallery)
}

export const getUserWorkingHours = getMockUserWorkingHours

export const setUserWorkingHours = setMockUserWorkingHours

export const getUserGalleries = (userId, options) => {
  if (isMock()) {
    return getMockUserGalleries()
  }

  return getRemoteUserGalleries(userId, options)
}

export const getGallery = (galleryId, fullData) => {
  if (isMock()) {
    return getMockGallery(galleryId, fullData)
  }

  return getRemoteGallery(galleryId, fullData)
}

export const getGalleryPhoto = (galleryId, photoId) => {
  if (isMock()) {
    return getMockGalleryPhoto(galleryId, photoId)
  }

  return getRemoteGalleryPhoto(galleryId, photoId)
}

export const getGalleryPhotos = (galleryId, photoIdsToLoad) => {
  if (isMock()) {
    return getMockGalleryPhotos(galleryId, photoIdsToLoad)
  }

  return getRemoteGalleryPhotos(galleryId, photoIdsToLoad)
}

export const getGalleryMarkedPhotosIds = (galleryId, photoIdsToLoad) => {
  if (isMock()) {
    return getMockGalleryMarkedPhotosIds(galleryId, photoIdsToLoad)
  }

  return getRemoteGalleryMarkedPhotosIds(galleryId, photoIdsToLoad)
}

export const createGalleryArchive = galleryId => {
  if (isMock()) {
    return createMockGalleryArchive(galleryId)
  }

  return createRemoteGalleryArchive(galleryId)
}

export const createGalleryChangesListener = (galleryId, callback) => {
  if (isMock()) {
    return createMockGalleryChangesListener(galleryId, callback)
  }

  return createRemoteGalleryChangesListener(galleryId, callback)
}

export const postGallery = gallery => {
  if (isMock()) {
    return postMockGallery(gallery)
  }

  return postRemoteGallery(gallery)
}

export const putGallery = (galleryId, data, updateWatermarks, eventData) => {
  if (isMock()) {
    return putMockGallery(galleryId, data, updateWatermarks, eventData)
  }

  return putRemoteGallery(galleryId, data, updateWatermarks, eventData)
}

export const postGalleryPhoto = (galleryId, photo, progressCallback, watermarkId) => {
  if (isMock()) {
    return postMockGalleryPhoto(galleryId, photo, progressCallback, watermarkId)
  }

  return postRemoteGalleryPhoto(galleryId, photo, progressCallback, watermarkId)
}

export const deleteGallery = gallery => {
  if (isMock()) {
    return deleteMockGallery(gallery)
  }

  return deleteRemoteGallery(gallery)
}

export const deleteGalleryPhotos = (galleryId, photos, areMarkedPhoto) => {
  if (isMock()) {
    return deleteMockGalleryPhotos(galleryId, photos, areMarkedPhoto)
  }

  return deleteRemoteGalleryPhotos(galleryId, photos, areMarkedPhoto)
}

export const deleteGalleryPhoto = (galleryId, photo, isMarkedPhoto) => {
  if (isMock()) {
    return deleteMockGalleryPhoto(galleryId, photo, isMarkedPhoto)
  }

  return deleteRemoteGalleryPhoto(galleryId, photo, isMarkedPhoto)
}

export const postGallerySharingOptions = (galleryId, sharingOptions) => {
  if (isMock()) {
    return postMockGallerySharingOptions(galleryId, sharingOptions)
  }

  return postRemoteGallerySharingOptions(galleryId, sharingOptions)
}

export const postGalleryMarkedPhotoIds = (galleryId, markedPhotos) => {
  if (isMock()) {
    return postMockGalleryMarkedPhotoIds(galleryId, markedPhotos)
  }

  return postRemoteGalleryMarkedPhotoIds(galleryId, markedPhotos)
}

export const initializeRemoteServerService = () => {
  if (isMock()) {
    return
  }

  return initializeFirebaseRemoteServerService()
}

export const postUserImage = (file, userUid) => {
  if (isMock()) {
    return postMockUserImage(file, userUid)
  }

  return postRemoteUserImage(file, userUid)
}

export const postPackImage = (file, imageId, userUid, packId) => {
  if (isMock()) {
    return postMockPackImage(file, imageId, userUid, packId)
  }

  return postRemotePackImage(file, imageId, userUid, packId)
}

export const postPackImages = (files, userUid, packId) => {
  if (isMock()) {
    return postMockPackImages(files, userUid, packId)
  }

  return postRemotePackImages(files, userUid, packId)
}

export const postPortfolioImage = (file, userUid, progressCallback) => {
  if (isMock()) {
    return postMockPortfolioImage(file, userUid, progressCallback)
  }

  return postRemotePortfolioImage(file, userUid, progressCallback)
}

export const postPortfolioImages = (images, userUid) => {
  if (isMock()) {
    return postMockPortfolioImages(images, userUid)
  }

  return postRemotePortfolioImages(images, userUid)
}

export const deleteImage = imageSrc => {
  if (isMock()) {
    return deleteMockImage(imageSrc)
  }

  return deleteRemoteImage(imageSrc)
}

export const deleteImages = imageSrcs => {
  if (isMock()) {
    return deleteMockImages(imageSrcs)
  }

  return deleteRemoteImages(imageSrcs)
}

// At this point, I don't think it makes sense to keep mockServer handlers since we can mock everything with Firebase simulator
// If in the future we would need to change server (other than Firebase), we would need to implement a proper mock server anyways, if needed

export const triggerWatermarkJobToPhoto = (galleryId, photoId) => {
  return addWatermarkToGalleryPhoto(galleryId, photoId)
}

export const getUserWatermarks = userUid => {
  return fetchUserWatermarks(userUid)
}

export const getUserWatermark = (userUid, watermarkId) => {
  return fetchUserWatermark(userUid, watermarkId)
}

export const postWatermark = (userUid, watermark) => {
  return postUserWatermark(userUid, watermark)
}

export const putWatermark = (userUid, watermark) => {
  return putUserWatermark(userUid, watermark)
}

export const deleteWatermark = (userUid, watermark) => {
  return deleteUserWatermark(userUid, watermark)
}
