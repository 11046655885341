import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'

import { Button, IconButton } from 'components/Button'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'

import { IconPicker } from '../IconPicker'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { t } from 'services/i18n'

import './styles.scss'

const getNewQuickInfo = () => ({
  icon: 'scatter_plot',
  title: '',
  description: '',
})

export const InformationsInput = props => {
  const [ info, setInfo ] = useState(props.initialInfo || '')
  const [ price, setPrice ] = useState(props.initialPrice?.value ?? null)
  const [ priceFrom, setPriceFrom ] = useState(props.initialPrice?.priceFrom ?? false)
  const [ quickInfo, setQuickInfo ] = useState(props.initialQuickInfo || [getNewQuickInfo()])
  const [ modalIsOpen, setModalIsOpen ] = useState(false)
  const [ modalContext, setModalContext ] = useState(null)
  const { isMobile } = useBreakpoints()

  const addQuickInfo = () => setQuickInfo([...quickInfo, getNewQuickInfo()])

  const onInfoChange = event => {
    const info = event.target.value
    setInfo(info)
    props.onChange && props.onChange(info)
  }

  const onPriceChange = event => {
    const price = event.target.value
    setPrice(price)
    if (price <= 0) {
      props.onPriceChange && props.onPriceChange(null)
    } else {
      props.onPriceChange && props.onPriceChange({
        value: price,
        priceFrom,
      })
    }
  }

  const onPriceFromChange = event => {
    const priceFrom = event.target.checked
    setPriceFrom(priceFrom)
    if (price <= 0) {
      props.onPriceChange && props.onPriceChange(null)
    } else {
      props.onPriceChange && props.onPriceChange({
        value: price,
        priceFrom,
      })
    }
  }

  const removeQuickInfo = quickInfoIdx => {
    const newQuickInfo = [...quickInfo]
    newQuickInfo.splice(quickInfoIdx, 1)
    setQuickInfo(newQuickInfo)
    props.onQuickInfoChange && props.onQuickInfoChange(newQuickInfo)
  }

  const onQuickInfoPropertyEdit = (quickInfoIdx, property, value) => {
    const quickInfoItem = { ...quickInfo[quickInfoIdx] }
    quickInfoItem[property] = value
    const newQuickInfo = [...quickInfo.slice(0, quickInfoIdx), quickInfoItem, ...quickInfo.slice(quickInfoIdx + 1)]
    setQuickInfo(newQuickInfo)
    props.onQuickInfoChange && props.onQuickInfoChange(newQuickInfo)
  }

  const onQuickInfoIconEdit = (quickInfoIdx, value) => onQuickInfoPropertyEdit(quickInfoIdx, 'icon', value)
  const onQuickInfoTitleEdit = (quickInfoIdx, value) => onQuickInfoPropertyEdit(quickInfoIdx, 'title', value)
  const onQuickInfoDescriptionEdit = (quickInfoIdx, value) => onQuickInfoPropertyEdit(quickInfoIdx, 'description', value)

  const openModal = quickInfoIdx => {
    setModalIsOpen(true)
    setModalContext({ quickInfoIdx })
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const chooseIcon = iconName => {
    closeModal()
    onQuickInfoIconEdit(modalContext.quickInfoIdx, iconName)
    setModalContext(null)
  }

  return (
    <div className='info-input'>
      <TextField
        className='info-input__description'
        id='info-input__description'
        label={t('components.pack_input.info_input.description')}
        variant='outlined'
        multiline
        maxRows={6}
        onChange={onInfoChange}
        value={info}
      />

      <div className='info-input__price'>
        <Text bold className='info-input__price__title'>{t('components.pack_input.info_input.price')}</Text>
        <TextField
          variant='outlined'
          onChange={onPriceChange}
          value={price}
          placeholder='Preço'
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>€</InputAdornment>,
          }}
        />
        <div className='info-input__price__price-from'>
          <FormControlLabel
            control={(
              <Switch
                disabled={price <= 0}
                checked={priceFrom}
                onChange={onPriceFromChange}
                name='pack-information-input-price-form-checkbox'
                color='primary'
                inputProps={{ 'aria-label': 'pack-information input price form checkbox' }}
              />
            )}
            label={t('components.pack_input.info_input.price-from')}
          />
        </div>
      </div>

      <div className='info-input__quick-info-wrapper'>
        <Text bold className='info-input__quick-info-title'>{t('components.pack_input.info_input.quick-info')}</Text>
        {quickInfo.map((quickInfo, quickInfoIdx) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={quickInfoIdx} className='info-input__quick-info'>
              <div className='info-input__quick-info__wrapper'>
                <Icon
                  className='info-input__quick-info__icon'
                  onClick={() => openModal(quickInfoIdx)}
                >
                  {quickInfo.icon}
                </Icon>

                <div className='info-input__quick-info__main-content'>
                  <TextField
                    className='info-input__quick-info__title'
                    id='info-input__quick-info__title'
                    label={`Título ${quickInfoIdx + 1}`}
                    onChange={event => onQuickInfoTitleEdit(quickInfoIdx, event.target.value)}
                    value={quickInfo.title}
                  />
                  <TextField
                    className='info-input__quick-info__description'
                    id='info-input__quick-info__description'
                    label='Insere a descrição'
                    variant='outlined'
                    multiline
                    size='small'
                    onChange={event => onQuickInfoDescriptionEdit(quickInfoIdx, event.target.value)}
                    value={quickInfo.description}
                  />
                </div>
              </div>
              <div className='info-input__quick-info__options'>
                {!isMobile
                  ? (
                    <Button
                      variant='outlined'
                      color='danger'
                      size='small'
                      startIcon={<DeleteIcon />}
                      onClick={() => removeQuickInfo(quickInfoIdx)}
                    >
                      {t('components.pack_input.info_input.remove')}
                    </Button>
                  )
                  : (
                    <IconButton
                      color='danger'
                      size='small'
                      aria-label='delete'
                      onClick={() => removeQuickInfo(quickInfoIdx)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
              </div>
            </div>
          )
        })}

        <Button
          variant='outlined'
          color='default'
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={addQuickInfo}
        >
          {t('components.pack_input.info_input.add')}
        </Button>
      </div>

      <Modal
        title={t('components.pack_input.icon_picker.title')}
        closeModal={closeModal}
        open={modalIsOpen}
        noPadding
      >
        <IconPicker chooseIcon={chooseIcon} />
      </Modal>
    </div>
  )
}
