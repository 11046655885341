import classNames from 'classnames'
import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AccountSettings } from 'scenes/UserSettings/components/AccountSettings'
import { PasswordSettings } from 'scenes/UserSettings/components/PasswordSettings'
import { PersonalisationSettings } from 'scenes/UserSettings/components/PersonalisationSettings'

import { Breadcrumb } from 'components/Breadcrumb'
import { Link } from 'components/Link'
import { BackdropLoading } from 'components/LoadingComponent'
import { SimpleList } from 'components/SimpleList'
import { Text } from 'components/Text'

import { ProfileSettings } from './components/ProfileSettings'

import {
  fetchUser as fetchUserAction,
  editUserInfo as editUserInfoAction,
  updateUserPlan as updateUserPlanAction,
  editUserAndAvatar as editUserAndAvatarAction,
} from 'store/users/actions'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { createUserSettingsLink, subRoutesNames } from 'services/routingService'

import './styles.scss'

const renderContent = (tabId, user = {}, editUserInfo, editUserAndAvatar, updateUserPlan) => {
  switch (tabId) {
    case subRoutesNames.USER_SETTINGS.PROFILE:
      return (
        <ProfileSettings
          user={user}
          onUserInfoSave={editUserInfo}
          editUserAndAvatar={editUserAndAvatar}
        />
      )
    case subRoutesNames.USER_SETTINGS.PASSWORD:
      return <PasswordSettings user={user} />
    case subRoutesNames.USER_SETTINGS.ACCOUNT:
      return (
        <AccountSettings
          user={user}
          editUserInfo={editUserInfo}
          updateUserPlan={updateUserPlan}
        />
      )
    case subRoutesNames.USER_SETTINGS.PERSONALISATION:
      return (
        <PersonalisationSettings
          user={user}
          editUserInfo={editUserInfo}
          updateUserPlan={updateUserPlan}
        />
      )
    default:
      return (
        <ProfileSettings
          user={user}
          onUserInfoSave={editUserInfo}
          editUserAndAvatar={editUserAndAvatar}
        />
      )
  }
}

const UserSettings = () => {
  const tabs = getConfigValue('user_settings.tabs').map(tab => ({
    ...tab,
    label: t(tab.label),
  }))
  const { page: userSettingsPage = tabs[0].id } = useParams()
  const hasUserLogedIn = useSelector(state => state.users.currentUser)
  const logedInUser = useSelector(state => hasUserLogedIn && state.users.users[state.users.currentUser.uid])
  const loadingUserData = useSelector(state => hasUserLogedIn && state.users.loading)

  const dispatch = useDispatch()

  const fetchUser = useCallback(
    () => dispatch(fetchUserAction(logedInUser.uid)),
    [dispatch, logedInUser.uid]
  )

  const editUserInfo = useCallback(
    info => dispatch(editUserInfoAction(logedInUser, info)),
    [dispatch, logedInUser]
  )

  const updateUserPlan = useCallback(
    info => dispatch(updateUserPlanAction(logedInUser, info)),
    [dispatch, logedInUser]
  )

  const editUserAndAvatar = useCallback(
    (info, avatar) => dispatch(editUserAndAvatarAction(logedInUser, info, avatar)),
    [dispatch, logedInUser]
  )

  useEffect(() => {
    // hasUserLogedIn as a dependecy to perform only one request (when the logedInUserId is available)
    hasUserLogedIn && !logedInUser && fetchUser()
  }, [fetchUser, hasUserLogedIn, logedInUser])

  return (
    <article className='user-settings'>
      <div className='user-settings__header'>
        <Breadcrumb
          items={[
            {
              title: 'Preferências',
            }, {
              title: tabs.find(tab => tab.id === userSettingsPage)?.label,
            },
          ]}
        />
      </div>
      <div className='user-settings__main'>
        <SimpleList className='user-settings__tabs' itemClassName='user-settings__tabs__item'>
          {tabs.map(tab => (
            <Link
              key={tab.id}
              className={classNames({
                'user-settings__tabs__item--selected': userSettingsPage === tab.id,
              })}
              href={createUserSettingsLink(tab.id)}
            >
              <Text
                className={classNames('user-settings__tabs__item-text', {
                  'user-settings__tabs__item-text--selected': userSettingsPage === tab.id,
                })}
                bold={userSettingsPage === tab.id}
                color={userSettingsPage === tab.id ? 'text__default-color' : 'text__grey-color'}
              >
                {tab.label}
              </Text>
            </Link>
          ))}
        </SimpleList>
        <div className='user-settings__content'>
          {renderContent(userSettingsPage, logedInUser, editUserInfo, editUserAndAvatar, updateUserPlan)}
        </div>
      </div>
      <BackdropLoading
        className='user-settings__loading'
        isLoading={loadingUserData}
        size={60}
      />
    </article>
  )
}

export default UserSettings
