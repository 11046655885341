import React from 'react'

import { LoadingComponent } from 'components/LoadingComponent'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'

import { t } from 'services/i18n'

import './styles.scss'

// This modal is used to show a success feedback to the user when an email is verified with success
// This modal communicates with the backend to confirm the user has isVerified property
export const VerifyEmailModal = ({ open, closeModal }) => {
  const onCloseModal = () => closeModal()

  return (
    <Modal
      open={open}
      title={t('profile_settings.account_settings.verify_email_modal.title')}
      closeModal={onCloseModal}
      actionButton={{
        text: 'OK',
        onClick: onCloseModal,
      }}
    >
      <div className='verify-email-modal__content'>
        <Text as='p' type='body'>{t('profile_settings.account_settings.verify_email_modal.description')}</Text>
        <Text as='p' type='body'>{t('profile_settings.account_settings.verify_email_modal.come_back')}</Text>
        <LoadingComponent className='verify-email-modal__loading' isLoading linear />
      </div>
    </Modal>
  )
}
