import classNames from 'classnames'
import React from 'react'

import './styles.scss'

export const SimpleList = props => (
  <ol className={classNames('simple-list', props.className)}>
    {React.Children.map(props.children, child => (
      <li className={props.itemClassName}>
        {child}
      </li>
    ))}
  </ol>
)
