import { useEffect, useState } from 'react'
import throttle from 'underscore/modules/throttle'

export const SCROLL_OFFSET = {
  TOP: 0,
  MIDDLE: 1,
  BOTTOM: 2,
}

const getScrollPosition = elmt => {
  const offset = elmt.scrollTop + (elmt.innerHeight || 0)
  const height = elmt.offsetHeight

  if (offset >= height) {
    return SCROLL_OFFSET.BOTTOM
  }

  if (offset <= 0) {
    return SCROLL_OFFSET.TOP
  }

  return SCROLL_OFFSET.MIDDLE
}

export const useScrollPosition = (initialNode = document.body) => {
  const [offset, setOffset] = useState(initialNode ? getScrollPosition(initialNode) : 0)

  useEffect(() => {
    if (initialNode == null) {
      return
    }

    const scrollListener = () => initialNode && setOffset(getScrollPosition(initialNode))

    const throttleScrollListener = throttle(scrollListener, 500)

    initialNode.addEventListener('scroll', throttleScrollListener)

    return () => initialNode.removeEventListener('scroll', throttleScrollListener)
  }, [initialNode])

  return offset
}
