import { prices as MOCK_PRICES } from './__mocks__/prices'
import { products as MOCK_PRODUCTS } from './__mocks__/products'

const DEFAULT_TIME_TO_RESOLVE = 1200

const resolvePromiseAfterMs = (valueToResolve, timeToResolve = DEFAULT_TIME_TO_RESOLVE) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(valueToResolve)
    }, timeToResolve)
  })
}

export const getProducts = resolvePromiseAfterMs(MOCK_PRODUCTS)

export const getPrices = resolvePromiseAfterMs(MOCK_PRICES)
