import classNames from 'classnames'
import React, { Children } from 'react'

import './styles.scss'

const DEFAULT_SEPARATOR = '·'

export const List = props => (
  <div className={classNames('separator-list', props.className, {
    'separator-list--vertical': props.vertical,
    'separator-list--nowrap': props.nowrap,
  })}
  >
    {props.children && (
      Children.map(props.children, (child, idx) => {
        const withSeparatorExplicit = props.withSeparator !== false
        const hasSeparator = (withSeparatorExplicit && props.separatedIndexes)
          ? props.separatedIndexes.includes(idx)
          : props.withSeparator || (props.separator && withSeparatorExplicit)
        const isLastIndex = props.children.length - 1 === idx || !Array.isArray(props.children)

        return (
          <div
            style={props.padding ? { '--item-padding' : `${props.padding}px` } : {} }
            className={classNames('separator-list__item', props.itemClassName, {
              'separator-list__item--vertical': props.vertical,
              'separator-list__item--with-margin': props.padding && !props.vertical,
            })}
          >
            {child}
            {hasSeparator && !isLastIndex && child !== null && (
              <div
                className={classNames('separator-list__separator', props.separatorClassName, {
                  'separator-list__separator--vertical': props.vertical,
                })}
              >
                {props.separator || DEFAULT_SEPARATOR}
              </div>
            )}
          </div>
        )
      })
    )}
  </div>
)
