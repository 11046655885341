import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextField } from 'components/TextField'

import './styles.scss'

const DEFAULT_NR_INPUTS = 6

const getInitialValues = nrOfInputs => {
  return Array(nrOfInputs).fill('').map((_, index) => ({
    key: index,
    value: '',
  }))
}

export const CodeInput = ({
  nrOfInputs = DEFAULT_NR_INPUTS,
  autoSubmit,
  onSubmit,
  onChangeCode,
  disabled,
}) => {
  const [values, setValues] = useState(getInitialValues(nrOfInputs))
  const [focusedIndex, setFocusedIndex] = useState(0)
  const [allFocused, setAllFocused] = useState(false)
  const submited = useRef(false)
  const refs = useRef([])

  const updateValues = useCallback((newValues, newFocusedIndex) => {
    if (newFocusedIndex !== undefined) {
      setFocusedIndex(newFocusedIndex)
    }

    setValues(newValues)
    submited.current = false
    onChangeCode(newValues.map(valData => valData.value).join(''))
  }, [onChangeCode])

  useEffect(() => {
    const newValues = getInitialValues(nrOfInputs)
    updateValues(newValues)
  }, [nrOfInputs, updateValues])

  useEffect(() => {
    if (refs.current[focusedIndex] && refs.current[focusedIndex] !== document.activeElement) {
      refs.current[focusedIndex].focus()
    }
  }, [focusedIndex])

  useEffect(() => {
    const allInputsHaveValues = values.every(value => !!value.value)
    if (allInputsHaveValues && focusedIndex > values.length - 1 && autoSubmit && onSubmit && !submited.current) {
      submited.current = true
      onSubmit(values.join(''))
    }
  }, [focusedIndex, values, autoSubmit, onSubmit])

  const handleChange = index => e => {
    if (allFocused) {
      setAllFocused(false)
    }

    const inputNewValue = { value: e.target.value, key: index }
    const newValues = [
      ...values.slice(0, index),
      inputNewValue,
      ...values.slice(index + 1),
    ]
    updateValues(newValues, focusedIndex + 1)
  }

  const handlePaste = e => {
    if (allFocused) {
      setAllFocused(false)
    }

    const pastedValue = e.clipboardData.getData('text')

    const newValues = values.map((currVal, index) => ({ value: pastedValue[index] || '', key: currVal.key }))
    updateValues(newValues, pastedValue.length)
  }

  const handleKeyDown = e => {
    if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
      setAllFocused(true)
    }

    if (e.key === 'Backspace') {
      if (allFocused) {
        const newValues = getInitialValues(nrOfInputs)
        updateValues(newValues, 0)
        setAllFocused(false)
        return
      }
      setFocusedIndex(focusedIndex - 1)
    }
  }

  const handleFocus = index => e => {
    if (allFocused) {
      setAllFocused(false)
    }

    e.target.setSelectionRange(0, 1)
    setFocusedIndex(index)
  }

  return (
    <div className='code-input'>
      {values.map(({ value, key }, index) => {
        return (
          <TextField
            autoComplete='one-time-code'
            autoFocus={index === 0}
            key={key}
            ref={el => refs.current[index] = el}
            className='code-input__input'
            value={value}
            onChange={handleChange(index)}
            variant='outlined'
            onKeyDown={handleKeyDown}
            inputProps={{
              maxlength: '1',
            }}
            onFocus={handleFocus(index)}
            focused={focusedIndex === index || allFocused}
            onPaste={handlePaste}
            disabled={disabled}
          />
        )
      })}
    </div>
  )
}
