export const MOCK_PACKS = {
  exhaustiveNbHits: true,
  hits: [
    {
      name: 'Pack1',
      photographerName: 'Julius',
      rating: 4.5,
      nrOfComments: 10,
      categories: [
        'Fotos',
        'Casamentos',
      ],
      country: 'Portugal',
      city: 'Coimbra',
      objectID: '6951990',
      _highlightResult: {
        name: {
          value: '<em>Pack</em>1',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['pack'],
        },
        categories: [
          {
            value: 'Fotos',
            matchLevel: 'none',
            matchedWords: [],
          },
          {
            value: 'Casamentos',
            matchLevel: 'none',
            matchedWords: [],
          },
        ],
        country: {
          value: 'Portugal',
          matchLevel: 'none',
          matchedWords: [],
        },
        city: {
          value: 'Coimbra',
          matchLevel: 'none',
          matchedWords: [],
        },
      },
    },
    {
      name: 'Pack1',
      photographerName: 'Julius',
      rating: 4.5,
      nrOfComments: 10,
      categories: [
        'Fotos',
        'Batizados',
      ],
      country: 'Portugal',
      city: 'Leiria',
      address: 'Juncal',
      objectID: '6951950',
      _highlightResult: {
        name: {
          value: '<em>Pack</em>1',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['pack'],
        },
        categories: [
          {
            value: 'Fotos',
            matchLevel: 'none',
            matchedWords: [],
          },
          {
            value: 'Batizados',
            matchLevel: 'none',
            matchedWords: [],
          },
        ],
        country: {
          value: 'Portugal',
          matchLevel: 'none',
          matchedWords: [],
        },
        city: {
          value: 'Leiria',
          matchLevel: 'none',
          matchedWords: [],
        },
      },
    },
    {
      name: 'Pack1',
      photographerName: 'John Doe',
      rating: 4,
      nrOfComments: 5,
      categories: [
        'Drones',
        'Filmes',
        'Casamentos',
      ],
      country: 'Portugal',
      city: 'Leiria',
      address: 'Juncal',
      objectID: '6410650',
      _highlightResult: {
        name: {
          value: '<em>Pack</em>1',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['pack'],
        },
        categories: [
          {
            value: 'Drones',
            matchLevel: 'none',
            matchedWords: [],
          },
          {
            value: 'Filmes',
            matchLevel: 'none',
            matchedWords: [],
          },
          {
            value: 'Casamentos',
            matchLevel: 'none',
            matchedWords: [],
          },
        ],
        country: {
          value: 'Portugal',
          matchLevel: 'none',
          matchedWords: [],
        },
        city: {
          value: 'Leiria',
          matchLevel: 'none',
          matchedWords: [],
        },
      },
    },
  ],
  hitsPerPage: 20,
  index: 'dev_Packs',
  nbHits: 0,
  nbPages: 0,
  page: 0,
  params: 'query=pack&',
  processingTimeMS: 1,
  query: 'pack',
}
