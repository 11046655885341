import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './styles.scss'

const BOLD_CODE = '**'
const ANCHOR_CODE = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/

export const Text = React.forwardRef((props, ref) => {
  const { className, children, as, type = 'body', lineLimit, color = 'text__default-color', bold, uppercase } = props
  const Wrapper = as ?? 'div'

  const style = {}

  if (lineLimit > 1) {
    style['-webkit-line-clamp'] = lineLimit
  }

  const customClassName = classNames('text', {
    'text--title': type === 'title',
    'text--title2': type === 'title2',
    'text--title3': type === 'title3',
    'text--title4': type === 'title4',
    'text--subtitle': type === 'subtitle',
    'text--subtitle2': type === 'subtitle2',
    'text--subtitle3': type === 'subtitle3',
    'text--body': type === 'body',
    'text--body2': type === 'body2',
    'text--default-color': color === 'text__default-color',
    'text--primary-color': color === 'text__primary-color',
    'text--secondary-color': color === 'text__secondary-color',
    'text--light-color': color === 'text__light-color',
    'text--grey-color': color === 'text__grey-color',
    'text--enhanced-grey-color': color === 'text__enhanced-grey-color',
    'text--white-color': color === 'text__white-color',
    'text--disabled-color': color === 'text__disabled-color',
    'text--with-vertical-limit': lineLimit && lineLimit > 1,
    'text--with-ellipsis': lineLimit && lineLimit === 1,
    'text--bold': bold,
    'text--uppercase': uppercase,
  }, className)

  if (children?.includes?.(BOLD_CODE)) {
    const splittedText = children.split(/\*\*/)

    const parsedText = splittedText.reduce((prev, curr, index) => {
      const isOdd = index % 2 !== 0

      if (isOdd) {
        return prev + curr.bold()
      }

      return prev + curr
    }, '')

    const text = {
      __html: parsedText,
    }

    return (
      <Wrapper
        className={customClassName}
        dangerouslySetInnerHTML={text}
        style={style}
      />
    )
  }

  // since children might be an array, we have to check if match is available
  // children array is not supported yet
  if (children?.match?.(ANCHOR_CODE)){
    return (
      <Wrapper
        className={customClassName}
        dangerouslySetInnerHTML={{ __html: children }}
        style={style}
      />
    )
  }

  return (
    <Wrapper
      ref={ref}
      className={customClassName}
      style={style}
    >
      {children}
    </Wrapper>
  )
})

Text.propTypes = {
  as: PropTypes.oneOf(['blockquote', 'cite', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'legend', 'li', 'p', 'q', 'span']),
  type: PropTypes.oneOf(['title', 'title2', 'title3', 'title4', 'subtitle', 'subtitle2', 'body', 'body2']),
  color: PropTypes.oneOf(['text__default-color', 'text__primary-color', 'text__secondary-color', 'text__alternate-color', 'text__light-color', 'text__grey-color', 'text__white-color', 'text__disabled-color']),
  lineLimit: PropTypes.number,
}
