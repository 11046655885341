import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { V2_IMAGE_SUFIX } from 'services/firebaseServer'

export const useImageInput = (reset, initialImages) => {
  const [ images, setImages ] = useState(initialImages || [])

  useEffect(() => {
    if (initialImages) {
      setImages(initialImages)
    }
  }, [initialImages])

  const onImageChoose = event => {
    if (event.target?.files?.[0]) {
      const imageIndex = reset ? 0 : images.length
      Array.from(event.target.files)
        .forEach((file, idx) => {
          saveImage(file, imageIndex + idx, reset)
        })
      event.target.value = '' // reset input event target value to be able to upload image twice
    }
  }

  const saveImage = (file, imgIdx, reset) => {
    const image = new Image()

    if (reset && imgIdx === 0) {
      setImages([{ loading: true }])
    } else {
      setImages(state => [...state, { loading: true }])
    }

    image.onload = e => {
      const newImg = e.target
      addImage(imgIdx, {
        id: uuidv4() + V2_IMAGE_SUFIX,
        src: newImg.src,
        width: newImg.width,
        height: newImg.height,
        loading: false,
        file,
        thumbnails: {
          initiated: Date.now(),
        },
      })
    }

    image.src = URL.createObjectURL(file) // convert to base64 string
  }

  const addImage = (imageIndex, newImageProps) => {
    const image = { ...images[imageIndex] }
    const newImage = {
      ...image,
      ...newImageProps,
    }
    setImages(state => {
      const newImages = [
        ...state.slice(0, imageIndex),
        newImage,
        ...state.slice(imageIndex + 1),
      ]
      return newImages
    })
  }

  return [ images, onImageChoose ]
}
