import Icon from '@mui/material/Icon'
import React from 'react'

import { Text } from 'components/Text'

import './styles.scss'

export const InformationTab = props => {
  return (
    <div className='information'>
      <Icon className='information__icon'>{props.iconId}</Icon>
      <div className='information__content'>
        <Text type='subtitle2'>{props.title}</Text>
        <Text className='information__body'>{props.body}</Text>
      </div>
    </div>
  )
}
