import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useUrlQuery } from 'hooks/useUrlQuery'
import { Error } from 'scenes/Error/'
import Post from './components/Post'
import PostCard from './components/PostCard'
import PostCardSkeleton from './components/PostCardSkeleton'
import PostSkeleton from './components/PostSkeleton'
import Tags from './components/Tags'

import './styles.scss'

const Blog = () => {
  const { slug } = useParams()
  const [posts, setPosts] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(true)
  const [filteredPosts, setFilteredPosts] = useState([])
  const urlTag = useUrlQuery('tag')

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const metadata = await import('./metadata.json')
        setTags(metadata.tags)
        setPosts(metadata.posts)
        setLoading(false)
      } catch (err) {
        console.error('Error fetching posts:', err)
        throw new Error('Failed to fetch posts.')
      }
    }

    fetchPosts()
  }, [])

  // Filter posts when the tag parameter changes
  useEffect(() => {
    if (urlTag) {
      const filtered = posts.filter(post => post.tags.includes(urlTag))
      setFilteredPosts(filtered)
    } else {
      setFilteredPosts(posts)
    }
  }, [urlTag, posts])

  const currentPost = slug ? posts.find(post => post.slug === slug) : null
  if (!currentPost && slug && !loading) {
    return <Error error={{ name: 'PAGE_NOT_FOUND' }} />
  }

  if (slug) {
    if (currentPost) {
      return (
        <div className='container'>
          <div className='container__post-page'>
            <Post slug={slug} metadata={currentPost} />
          </div>
        </div>
      )
    }
    return (
      <div className='container'>
        <PostSkeleton />
      </div>
    )
  }

  if (loading) {
    return (
      <div className='container'>
        <Tags tags={tags} urlTag={urlTag} />
        <div className='container__posts'>
          {
            Array.from({ length: 6 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <PostCardSkeleton key={index} />
            ))
          }
        </div>
      </div>
    )
  }

  return (
    <div className='container'>
      <Tags tags={tags} urlTag={urlTag} />
      <div className='container__posts'>
        {
          filteredPosts.map(post => (
            <PostCard key={post.slug} {...post} />
          ))
        }
      </div>
    </div>
  )
}

export default Blog
