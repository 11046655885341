import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React, { Fragment } from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'

import { createPackLink } from 'services/routingService'

import './styles.scss'

export const UserPackSkeleton = () => (
  <div>
    <Skeleton animation='wave' variant='rectangular' height={260} />
    <h2>
      <Skeleton animation='wave' width='70%' height={20} />
    </h2>
    <p>
      <Skeleton animation='wave' width='100%' height={20} />
      <Skeleton animation='wave' width='100%' height={20} />
      <Skeleton animation='wave' width='100%' height={20} />
    </p>
  </div>
)

const userPackUI = (image, title, price, description) => {
  return (
    <Fragment>
      <Image
        className='user-pack__image'
        alt='pack image 1'
        thumbnailsSpecs={[
          { size: 's', media: '(max-width: 600px)' },
          { size: 'm', media: '(max-width: 960px)' },
          { size: 's', media: '(max-width: 1280px)' },
          { size: 'm', media: '(min-width: 1280px)' },
        ]}
        minHeight={120}
        generateThumbnailIfError
        {...image}
      />
      <div className='user-pack__info-wrapper'>
        <div className='user-pack__info'>
          <div className='user-pack__info__main-info'>
            <h3
              className={classNames('user-pack__info__title', {
                'user-pack__info__title--alone': !description,
              })}
            >
              {title}
            </h3>
            {price && (
              <p className='user-pack__info__price'>
                {price.priceFrom && ' Desde '}
                <b className='user-pack__info__price__value'>{price.value}</b>
                €
              </p>
            )}
          </div>
          {description && <p className='user-pack__info__description'>{description}</p>}
        </div>
      </div>
    </Fragment>
  )
}

export const UserPack = props => {
  const userPack = userPackUI(props.image, props.title, props.price, props.description)

  const userPackClassName = classNames('user-pack', {
    'user-pack--clickable': props.onClick || props.editMode,
    'user-pack--with-min-height': !props.image,
  })

  return props.onClick || props.editMode
    ? (
      <div tabIndex={0} className={userPackClassName} onClick={props.onClick}>
        {userPack}
      </div>
    )
    : (
      <div className={userPackClassName}>
        <Link href={props.uploading ? undefined : createPackLink(props.packId)}>
          {userPack}
        </Link>
      </div>
    )
}
