export const RESIZE_BOX = {
  TOP: 0,
  RIGHT: 1,
  BOTTOM: 2,
  LEFT: 3,
}

export const CURSOR_TYPE = {
  MOVE: 0,
  WIDTH_SIZE: 1,
  HEIGHT_SIZE: 2,
}

export const isInsideRect = (x, y, rect, insideOffset = 0) => {
  return rect.x + insideOffset <= x && x <= rect.x + rect.width - insideOffset &&
    rect.y + window.scrollY + insideOffset <= y && y <= rect.y + window.scrollY + rect.height - insideOffset
}

export const setProtectionCoverCursor = (coverElmnt, cursorType) => {
  switch (cursorType) {
    case CURSOR_TYPE.MOVE:
      coverElmnt.style.cursor = 'move'
      break
    case CURSOR_TYPE.WIDTH_SIZE:
      coverElmnt.style.cursor = 'ew-resize'
      break
    case CURSOR_TYPE.HEIGHT_SIZE:
      coverElmnt.style.cursor = 'ns-resize'
      break
    default:
      coverElmnt.style.cursor = 'pointer'
  }
}

export const setProtectionCoverStyle = (coverElmnt, wrapperElementRect) => {
  coverElmnt.style.position = 'fixed'
  coverElmnt.style.left = wrapperElementRect.left + 'px'
  coverElmnt.style.top = wrapperElementRect.top + 'px'
  coverElmnt.style.width = wrapperElementRect.width + 'px'
  coverElmnt.style.height = wrapperElementRect.height + 'px'
  coverElmnt.style.zIndex = '1'
  coverElmnt.classList.add('unselectable')
}

// This protection cover is used to listen to mouse events
// This is usefull because this way we don't get stacking problems (with z-index) and, most important,
// we can move our copy element without having problems with the masonry layout and the update's in the DOM
export const createProtectionCover = wrapperElmnt => {
  const coverElmnt = document.createElement('div')

  setProtectionCoverStyle(coverElmnt, wrapperElmnt.getBoundingClientRect())

  document.body.appendChild(coverElmnt)

  return coverElmnt
}

export const createDuplicatedCard = (refs, clickedElementIndex, duplicatedNodeRef) => {
  const duplicatedNode = refs.current[clickedElementIndex].elmt.cloneNode(true)
  duplicatedNodeRef.current = duplicatedNode
  const currentCardRect = refs.current[clickedElementIndex].rect
  duplicatedNode.style.position = 'fixed'
  duplicatedNode.style.left = currentCardRect.left + 'px'
  duplicatedNode.style.top = currentCardRect.top + 'px'
  duplicatedNode.style.width = currentCardRect.width + 'px'
  duplicatedNode.style.height = currentCardRect.height + 'px'
  duplicatedNode.style.userSelect = 'none'
  duplicatedNode.classList.add('unselectable')

  document.body.appendChild(duplicatedNode)
}

const CARD_PADDING = 5

export const createCardResizerElmt = (resizeElmt, cardRect) => {
  resizeElmt.current.style.position = 'fixed'
  resizeElmt.current.style.left = `${cardRect.left + CARD_PADDING}px`
  resizeElmt.current.style.top = `${cardRect.top + CARD_PADDING}px`
  resizeElmt.current.style.width = `${cardRect.width - CARD_PADDING * 2}px`
  resizeElmt.current.style.height = `${cardRect.height - CARD_PADDING * 2}px`
  resizeElmt.current.style.border = '1px dashed gray'
  resizeElmt.current.classList.add('unselectable')

  createTopResizerBox(resizeElmt.current)
  createRightResizerBox(resizeElmt.current)
  createBottomResizerBox(resizeElmt.current)
  createLeftResizerBox(resizeElmt.current)
}

const RESIZER_BOX_SIZE = 10

const createResizerBox = parent => {
  const topResizer = document.createElement('div')
  topResizer.style.position = 'absolute'
  topResizer.style.width = `${RESIZER_BOX_SIZE}px`
  topResizer.style.height = `${RESIZER_BOX_SIZE}px`
  topResizer.style.border = '1px solid gray'
  parent.appendChild(topResizer)
  return topResizer
}

const createTopResizerBox = parent => {
  const resizerId = 'top-resizer-box'
  const currentResizerBox = document.getElementById(resizerId)
  const resizerBox = currentResizerBox || createResizerBox(parent)
  resizerBox.id = resizerId
  resizerBox.style.left = `calc(50% - ${RESIZER_BOX_SIZE / 2}px)`
  resizerBox.style.top = `-${RESIZER_BOX_SIZE / 2}px`
}

const createRightResizerBox = parent => {
  const resizerId = 'right-resizer-box'
  const currentResizerBox = document.getElementById(resizerId)
  const resizerBox = currentResizerBox || createResizerBox(parent)
  resizerBox.id = resizerId
  resizerBox.style.right = `-${RESIZER_BOX_SIZE / 2}px`
  resizerBox.style.top = `calc(50% - ${RESIZER_BOX_SIZE / 2}px)`
}

const createBottomResizerBox = parent => {
  const resizerId = 'bottom-resizer-box'
  const currentResizerBox = document.getElementById(resizerId)
  const resizerBox = currentResizerBox || createResizerBox(parent)
  resizerBox.id = resizerId
  resizerBox.style.bottom = `-${RESIZER_BOX_SIZE / 2}px`
  resizerBox.style.left = `calc(50% - ${RESIZER_BOX_SIZE / 2}px)`
}

const createLeftResizerBox = parent => {
  const resizerId = 'left-resizer-box'
  const currentResizerBox = document.getElementById(resizerId)
  const resizerBox = currentResizerBox || createResizerBox(parent)
  resizerBox.id = resizerId
  resizerBox.style.left = `-${RESIZER_BOX_SIZE / 2}px`
  resizerBox.style.top = `calc(50% - ${RESIZER_BOX_SIZE / 2}px)`
}

// These are the clickable areas for the resizer boxes
// They should be twice the size the resize boxes

const RESIZER_CLICKABLE_BOX_SIZE = 20

const createTopResizerBoxRect = parentRect => ({
  x: parentRect.left + parentRect.width / 2 - RESIZER_CLICKABLE_BOX_SIZE / 2,
  y: parentRect.top + CARD_PADDING - RESIZER_CLICKABLE_BOX_SIZE / 2,
  width: RESIZER_CLICKABLE_BOX_SIZE,
  height: RESIZER_CLICKABLE_BOX_SIZE,
})

const createRightResizerBoxRect = parentRect => ({
  x: parentRect.left + parentRect.width - CARD_PADDING - RESIZER_CLICKABLE_BOX_SIZE / 2,
  y: parentRect.top + parentRect.height / 2 - RESIZER_CLICKABLE_BOX_SIZE / 2,
  width: RESIZER_CLICKABLE_BOX_SIZE,
  height: RESIZER_CLICKABLE_BOX_SIZE,
})

const createBottomResizerBoxRect = parentRect => ({
  x: parentRect.left + parentRect.width / 2 - RESIZER_CLICKABLE_BOX_SIZE / 2,
  y: parentRect.top + parentRect.height - CARD_PADDING - RESIZER_CLICKABLE_BOX_SIZE / 2,
  width: RESIZER_CLICKABLE_BOX_SIZE,
  height: RESIZER_CLICKABLE_BOX_SIZE,
})

const createLeftResizerBoxRect = parentRect => ({
  x: parentRect.left + CARD_PADDING - RESIZER_CLICKABLE_BOX_SIZE / 2,
  y: parentRect.top + parentRect.height / 2 - RESIZER_CLICKABLE_BOX_SIZE / 2,
  width: RESIZER_CLICKABLE_BOX_SIZE,
  height: RESIZER_CLICKABLE_BOX_SIZE,
})

export const isInsideResizerBox = (clickX, clickY, rect) => {
  if (isInsideRect(clickX, clickY, createTopResizerBoxRect(rect))) {
    return RESIZE_BOX.TOP
  }
  if (isInsideRect(clickX, clickY, createRightResizerBoxRect(rect))) {
    return RESIZE_BOX.RIGHT
  }
  if (isInsideRect(clickX, clickY, createBottomResizerBoxRect(rect))) {
    return RESIZE_BOX.BOTTOM
  }
  if (isInsideRect(clickX, clickY, createLeftResizerBoxRect(rect))) {
    return RESIZE_BOX.LEFT
  }
}
