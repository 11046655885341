export const MOCK_CATEGORIES = {
  exhaustiveNbHits: true,
  hits: [
    {
      name: 'Casamentos',
      numberOfPacks: 2,
      numberOfPhotographers: 1,
      objectID: '1284230',
      packIds: ['packId1', 'packId2'],
      photographerIds: ['photographerId1'],
      related: ['Festas'],
      _highlightResult: {
        name: {
          value: '<em>Casa</em>mentos',
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: ['casa'],
        },
        related: [
          {
            value: 'Festas',
            matchLevel: 'none',
            matchedWords: [],
          },
        ],
      },
    },
  ],
  hitsPerPage: 20,
  index: 'dev_Categories',
  nbHits: 0,
  nbPages: 0,
  page: 0,
  params: 'query=ph&',
  processingTimeMS: 1,
  query: 'ph',
}
