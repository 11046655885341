import React from 'react'

import { Faq } from './components/Faq'

import './styles.scss'

export const FaqSection = props => {
  return (
    <div className='faq-section'>
      <ol className='faq-section__list'>
        {props.faqs?.map(faq => {
          return (
            <Faq
              key={faq.question}
              question={faq.question}
              answer={faq.answer}
            />
          )
        })}
      </ol>
    </div>
  )
}
