import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'
import React from 'react'

import { Button, IconButton, LinkButton } from 'components/Button'

import './styles.scss'

export const GenericAddButton = ({ onClick, href, label, iconId, inline, startIcon, id, disabled, withinList }) => {
  const DynamicComponent = href ? LinkButton : Button
  return (
    <div id={id}>
      <div className={classNames('generic-add-button', {
        'generic-add-button--inline': inline,
        'generic-add-button--no-position': withinList,
      })}
      >
        <DynamicComponent
          disabled={disabled}
          color='primary'
          variant='outlined'
          onClick={onClick}
          href={href}
          startIcon={startIcon ? <Icon>{iconId}</Icon> : null}
        >
          {label}
        </DynamicComponent>
      </div>
      <div className={classNames('generic-add-button generic-add-button--mobile', {
        'generic-add-button--inline': inline,
        'generic-add-button--no-position': withinList,
      })}
      >
        <IconButton disabled={disabled} href={href} onClick={onClick}>
          {!iconId && <AddBoxOutlined />}
          {iconId && <Icon>{iconId}</Icon>}
        </IconButton>
      </div>
    </div>
  )
}

export const GenericAddButtonList = ({ list }) => {
  return (
    <div className='generic-add-button-list'>
      {list
        .filter(genericAddButtonProps => !genericAddButtonProps.isDisabled)
        .map(genericAddButtonProps => (
          <GenericAddButton
            key={genericAddButtonProps.key}
            id={genericAddButtonProps.key}
            {...genericAddButtonProps}
            inline
            withinList
          />
        ))}
    </div>
  )
}
