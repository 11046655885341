import Clear from '@mui/icons-material/Clear'
import Paper from '@mui/material/Paper'
import classNames from 'classnames'
import React, { useEffect, useState, useRef } from 'react'

import { IconButton } from 'components/Button'
import { LoadingComponent } from 'components/LoadingComponent'
import { TextField } from 'components/TextField'

import { useDebounce } from 'hooks/useDebounce'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import './styles.scss'

// TODO: set if it is debounced via prop
// TODO: check if it works fine without being async

/**
 * @param {{
 *  onAutocompleteChange: Function,
 *  onInputChange: Function,
 *  onClearInput: Function,
 *  addFreeSolo: Boolean,
 *  addFreeSoloText: String,
 *  renderOption: Function,
 *  inputLabel: String,
 *  noResultsLabel: String,
 *  optionLabelProp: (option: Object) => string
 *  openOnFocus: Boolean,
 * }} props
 *
 * props:
 * - onAutocompleteChange: function to be called when a new option is selected
 * - addFreeSolo: if true, user can add new options
 * - addFreeSoloText: prop to dynamically set the free solo text. ex: "Add ${value}"
 * - renderOption: custom function to return the option UI in the list
 * - inputLabel: input default label
 * - noResultsLabel: no results default label
 * - optionLabel: gets the input value that will be displayed in the input when an option is selected.
 *  It's also used to render the options list UI if renderOption is not provided
 * - openOnFocus: opens options when input is focused
 */
export const AutoCompleteInput = props => {
  const {
    initialInputValue,
    onInputChange: parentOnInputChange,
    onClearInput,
    onAutocompleteChange,
    isLoading,
    results,
    addFreeSolo,
    addFreeSoloText,
    optionLabel,
    renderOption: parentRenderOption,
    noResultsLabel,
    inputLabel,
    openOnFocus,
    ...restProps
  } = props

  const [value, setValue] = useState(initialInputValue || '')
  const [shouldBeClosed, setShouldBeClosed] = useState(false)
  const [focused, setFocused] = useState(false)
  const debouncedSearchTerm = useDebounce(value, 250)
  const elmtRef = useRef()

  /**
   * Function to filter options property
   */
  const autoCompleteFilterOptions = options => {
    const filtered = [...options]

    if (!isLoading && results.length === 0 && noResultsLabel) {
      filtered.push({
        isEmpty: true,
      })
    }

    if (value !== '' && (addFreeSolo || addFreeSoloText)) {
      filtered.push({
        inputValue: value,
        name: addFreeSoloText ? addFreeSoloText(value) : value,
      })
    }

    return filtered
  }

  const filteredResults = autoCompleteFilterOptions(results)

  const open =
    openOnFocus
      ? filteredResults.length > 0 && !shouldBeClosed && focused
      : filteredResults.length > 0 && value !== '' && !shouldBeClosed && focused

  useOnClickOutside(elmtRef, () => setShouldBeClosed(true), open)

  useEffect(() => {
    setValue(initialInputValue)
  }, [initialInputValue])

  useEffect(() => {
    parentOnInputChange && parentOnInputChange(debouncedSearchTerm)
  }, [debouncedSearchTerm, parentOnInputChange])

  /**
   * Used to determine the string value for a given option. It's used to fill the input (and the list box options if renderOption is not provided)
   */
  const getOptionLabel = option => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }

    if (option.inputValue) {
      return option.inputValue
    }

    return optionLabel
      ? optionLabel(option)
      : option.name
  }

  /**
   * Function to return the UI of each option shown in the results dropdown
   */
  const renderOption = option => {
    // check first if option is not the last one (Add value)
    if (parentRenderOption && option.inputValue === undefined && option.isEmpty === undefined) {
      return parentRenderOption(option)
    }

    if (option.isEmpty) {
      return noResultsLabel
    }

    return option.name
  }

  const onInputChange = event => {
    if (!addFreeSolo && !addFreeSoloText) {
      return
    }

    shouldBeClosed && setShouldBeClosed(false)
    setValue(event.target.value)
  }

  const clearInput = () => {
    setValue('')
    onClearInput && onClearInput()
  }

  /**
   * Function called when a new option is selected
   */
  const onListItemClick = item => () => {
    setValue(getOptionLabel(item))
    setShouldBeClosed(true)
    onAutocompleteChange && onAutocompleteChange(item)
  }

  const onInputFocus = () => {
    if (openOnFocus && shouldBeClosed) {
      setShouldBeClosed(false)
    }
    setFocused(true)
    props.onInputFocus && props.onInputFocus()
  }

  return (
    <div ref={elmtRef} {...restProps} className='auto-complete-input'>
      <TextField
        onFocus={onInputFocus}
        value={value}
        onChange={onInputChange}
        variant='outlined'
        label={inputLabel}
      />
      {value && (
        <IconButton className='auto-complete-input__clear-icon' onClick={clearInput} aria-label='Clear auto complete input' color='inherit'>
          <Clear />
        </IconButton>
      )}
      <Paper
        className={classNames('auto-complete-input__paper', {
          'auto-complete-input__paper--open': open,
        })}
      >
        <ol className='auto-complete-input__list'>
          {filteredResults
            .map(item => (
              <li
                key={item.name}
                onClick={onListItemClick(item)}
                className={classNames('auto-complete-input__list-item', {
                  'auto-complete-input__list-item--no-hover': item.isEmpty,
                })}
              >
                {renderOption(item)}
              </li>
            ))}
        </ol>
        {props.paperFooter}
      </Paper>
      {isLoading && (
        <LoadingComponent
          isLoading={isLoading}
          size={20}
          className='auto-complete-input__loading'
        />
      )}
    </div>
  )
}
