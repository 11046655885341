import { v4 as uuidv4 } from 'uuid'

import { NOTIFICATION_LISTENER_ID } from 'components/GlobalNotifications'

export class NotificationSystem {
  _listeners = {}

  subscribe(id, func) {
    if (id === undefined && func === undefined) {
      throw Error('To subscribe the notification system, an id must be passed')
    }
    this._listeners[id] = func
  }

  unSubscribe(id) {
    delete this._listeners[id]
  }

  /**
   * function that is called to notify notification listneres
   * @param {string | {
   *  id: string,
   *  message: string,
   * }} notification
   * @param {string | array} listenerKeys string or array of listener keys that should listen to the notification.
   * By default it's used the NOTIFICATION_LISTENER_ID, located in the GlobalNotifications component
   */
  notify(notification, listenerKeys = NOTIFICATION_LISTENER_ID) {
    let listersKeys = Object.keys(this._listeners)

    if (listenerKeys) {
      const filterPredicate = Array.isArray(listenerKeys)
        ? listenerKey => listenerKeys.includes(listenerKey)
        : listenerKey => listenerKeys === listenerKey

      listersKeys = listersKeys
        .filter(filterPredicate)
    }

    let notificationObject = notification

    // if notification is not an object (notify can be called with just a message string), let's create an object so that types are always the same
    if (typeof notification !== 'object') {
      notificationObject = {
        id: uuidv4(),
        message: notification,
      }
    }

    // if notification is an object but id is not set yet, let's create an id
    if (notificationObject.id === undefined) {
      notificationObject = {
        ...notificationObject,
        id: uuidv4(),
      }
    }

    listersKeys
      .forEach(listenerKey => {
        this._listeners[listenerKey](notificationObject)
      })
  }
}

var notificationSystem

export const getNotificationSystem = () => {
  if (notificationSystem === undefined) {
    notificationSystem = new NotificationSystem()
    return notificationSystem
  }

  return notificationSystem
}
