import { useEffect, useState, useRef } from 'react'

export const useHideOnScroll = ({ topOffset = 0, bottomOffset = 0, offset = 0 }) => {
  const prevScrollY = useRef(window.scrollY)
  const [isHidden, setIsHidden] = useState(false)

  // Note: this is not working on >tablet breakpoints
  // because .backoffice__content has an overflow: scroll;
  useEffect(() => {
    const onScroll = () => {
      setIsHidden(isHidden => {
        const scrollY = window.scrollY
        const scrolledDown = scrollY > prevScrollY.current
        const isBeforeBottomThreshold = window.scrollY <= topOffset
        const scrollHeight = document.body.offsetHeight
        const isAfterBottomThreshold = (window.innerHeight + scrollY) > (scrollHeight - bottomOffset)

        if (scrolledDown && !isHidden && !isBeforeBottomThreshold && !isAfterBottomThreshold) {
          return true
        } else if ((!scrolledDown && isHidden) || isBeforeBottomThreshold || isAfterBottomThreshold) {
          return false
        } else {
          prevScrollY.current = window.scrollY
          return isHidden
        }
      })
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [offset, topOffset, bottomOffset])

  return isHidden
}
