import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React from 'react'
import { MdComment } from 'react-icons/md'

import { Categories } from 'components/Categories'
import { IconLabel } from 'components/IconLabel'
import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { List } from 'components/List'
import { PackPin, PackPinSkeleton } from 'components/PackPin'
import { Rating } from 'components/Rating'
import UserCircular from 'components/UserCircular'

import { createPackLink, createUserLink } from 'services/routingService'

import './styles.scss'

export const PackSearchResultItemSkeleton = props => {
  if (props.selectedViewId === 'gallery') {
    return <PackPinSkeleton />
  }

  return <PackSearchListResultItemSkeleton />
}

const PackSearchListResultItemSkeleton = () => (
  <div className='search-result-item'>
    <div className='search-result-item__img-wrapper'>
      <Skeleton animation='wave' variant='rectangular' width='100%' height={220} />
    </div>
    <div className='search-result-item__content'>
      <Skeleton animation='wave' variant='text' width={140} />
      <Skeleton animation='wave' variant='text' width={140} />
      <Skeleton animation='wave' variant='text' width={140} />
      <p className='search-result-item__content__description'>
        <Skeleton animation='wave' variant='rectangular' width='100%' height={60} />
      </p>
      <div className='search-result-item__content__user-info' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Skeleton animation='wave' variant='circular' width={40} height={40} />
        <Skeleton animation='wave' variant='text' width={140} height={20} />
      </div>
    </div>
  </div>
)

const PackSearchListResultItem = props => {
  const packHref = createPackLink(props.id || 'wrong-id', undefined, true, ['q', 'v'])
  const userHref = createUserLink(props.user.id, undefined, true, ['q', 'v'])

  return (
    <div className={classNames('search-result-item', props.className)}>
      <Link href={packHref}>
        <div className='search-result-item__img-wrapper'>
          {props.image?.src && (
            <Image
              src={props.image.src}
              width={props.image.width}
              height={props.image.height}
              thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
            />
          )}
        </div>
      </Link>
      <div className='search-result-item__content'>
        <Link href={packHref} underlined>
          <h3 className='search-result-item__content__title'>{props.title}</h3>
        </Link>
        {(props.ratingCount || props.commentCount) && (
          <div className='search-result-item__content__pack-info'>
            <List withSeparator>
              {props.ratingCount && <Rating initialValue={props.rating} countValue={props.ratingCount} readOnly size='small' />}
              {props.commentCount && <IconLabel icon={MdComment} label={props.commentCount} />}
            </List>
          </div>
        )}
        <Categories
          className='search-result-item__content__pack-categories'
          categories={props.categories}
        />
        <p className='search-result-item__content__description'>{props.description}</p>
        <UserCircular
          href={userHref}
          className='search-result-item__content__user-info'
          avatar={props.user.avatar}
          name={props.user.name}
          withFallback
        />
      </div>
    </div>
  )
}

export const PackSearchResultItem = props => {
  if (props.selectedViewId === 'gallery') {
    return (
      <PackPin
        packId={props.id}
        title={props.title}
        headerLabel=''
        headerImageSize={{
          imgWidth: 1200,
          imgHeight: 901,
        }}
        headerImageSrc={props.image?.src}
        like={false}
        userUid={props.user.id}
        footerImgSrc={props.user.avatar}
        name={props.user.name}
        className={props.className}
        noInfo
      />
    )
  }

  return <PackSearchListResultItem {...props} />
}
