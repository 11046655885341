import { collection, doc, getDoc, getDocs, increment, onSnapshot, query, runTransaction, setDoc, updateDoc, where } from '@firebase/firestore'
import { getDatabase } from './'

import { logError } from 'utils/errorCapture'
import { getFunctions, httpsCallable } from 'firebase/functions'

const getUsersRef = () => collection(getDatabase(), 'users')
const getUserRef = userUid => doc(getDatabase(), 'users', userUid)
const getGalleriesRef = () => collection(getDatabase(), 'galleries')

/**
 * This will do any necessary transformations needed to map the server user structure to the app user structure
 * This is needed, for example, because of retrocompatibility
 */
const transformUser = user => {
  // TODO: this should be already transformed in server
  if (user.portfolio && Array.isArray(user.portfolio.cards)) {
    const portfolioCards = user.portfolio.cards.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr,
      }
    }, {})

    user.portfolio.cards = portfolioCards
  }

  return user
}

export const getUser = userUid => {
  const docRef = getUserRef(userUid)

  console.info('Fetching user:', userUid)
  return getDoc(docRef)
    .then(user => {
      console.info('Fetched user:', user)
      if (user.exists()) {
        return transformUser(user.data())
      } else {
        // doc.data() will be undefined in this case
        console.log('No such user with userUid', userUid)
        return null
      }
    }).catch(error => {
      logError(error, `Error fetching user ${userUid}: ${error}`)
    })
}

export const createUserChangeListener = (userUid, callback) => {
  const unsubscribeListener = onSnapshot(
    getUserRef(userUid),
    doc => callback(doc.data(), doc)
  )

  return unsubscribeListener
}

export const createUserStorageChangeListener = (userUid, callback) => {
  return createUserChangeListener(userUid, (data, doc) => {
    callback(data.storageSize, doc)
  })
}

export const getUserByUsername = username => {
  const q = query(
    getUsersRef(),
    where('username', '==', username)
  )

  console.info('Fetching user with username:', username)
  return getDocs(q)
    .then(querySnapshot => {
      if (querySnapshot.size <= 0) {
        console.error('No such user with!')
        throw new Error('No such user with username', username)
      }

      console.info('Fetched user with username:', username)
      const users = []
      querySnapshot.forEach(user => {
        // doc.data() is never undefined for query doc snapshots
        users.push(transformUser(user.data()))
      })
      return users[0]
    }).catch(error => {
      logError(error, `Error fetching user with username ${username}: ${error}`)
      throw error
    })
}

export const getUsers = userUids => {
  const q = query(
    getUsersRef(),
    where('uid', 'in', userUids)
  )

  console.info('Fetching users:', userUids)
  return getDocs(q)
    .then(querySnapshot => {
      const users = []
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        users.push(transformUser(doc.data()))
      })
      return users
    })
    .catch(error => {
      logError(error, `Error fetching users ${userUids}: ${error}`)
      throw error
    })
}

export const postUser = user => {
  const docRef = getUserRef(user.uid)

  return setDoc(docRef, { ...user })
    .then(() => {
      console.log(`User ${user.uid} successfully written!`)
    })
    .catch(error => {
      logError(error, 'Error creating user: ' + error)
    })
}

export const editUser = (user, merge = true) => {
  const docRef = getUserRef(user.uid)

  // storageSize can NEVER be updated by FE
  // Otherwise, sync bugs will happen
  delete user.storageSize

  return setDoc(docRef, { ...user }, { merge })
    .then(() => {
      console.log(`User ${user.uid} successfully written!`)
    })
    .catch(error => {
      logError(error, 'Error creating user: ' + error)
    })
}

export const getEditUserPacksTransactionData = (userUid, data = {}) => {
  const userRef = getUserRef(userUid)

  const userDataWithUpdatedPacks = {
    uid: userUid,
    'packs.cards': data.userPacks,
    'packs.hiddenCards': data.userHiddenPacks,
  }

  return { userRef, userDataWithUpdatedPacks }
}

export const putUserPortfolio = (userUid, cards, layoutsData, layouts) => {
  const docRef = getUserRef(userUid)

  const portfolioData = {
    id: userUid,
    'portfolio.cards': cards,
    'portfolio.layoutsData': layoutsData,
  }

  if (layouts) {
    portfolioData['portfolio.layouts'] = layouts
  }

  return updateDoc(docRef, { ...portfolioData })
    .then(() => {
      console.log(`User portfolio ${userUid} successfully updated!`)
    })
    .catch(error => {
      logError(error, 'Error updating user portfolio: ' + error)
    })
}

export const postUserPortfolioImage = (userUid, image) => {
  const docRef = getUserRef(userUid)

  return runTransaction(getDatabase(), transaction => {
    // This code may get re-run multiple times if there are conflicts.
    return transaction.get(docRef)
        .then(sfDoc => {
          if (!sfDoc.exists()) {
            throw Error(`Error adding portfolio image to user ${userUid}. Error: User does not exist!`)
          }

          // Add the new image to all layouts
          const layoutsData = sfDoc.data().portfolio.layoutsData || {}
          Object.keys(layoutsData).forEach(layoutDataKey => {
            layoutsData[layoutDataKey].cards.push({
              id: image.id,
            })
          })

          const portfolioData = {
            id: userUid,
            [`portfolio.cards.${image.id}`]: image,
            'portfolio.layoutsData': layoutsData,
          }

          transaction.update(docRef, portfolioData)
        })
  }).then(() => {
    console.log(`User portfolio ${userUid} successfully updated!`)
  }).catch(error => {
    logError(error, 'Error updating user portfolio: ' + error)
    throw error
  })
}

export const getIncrementUserGalleryBatchData = userUid => {
  const galleryUserRef = getUserRef(userUid)
  const galleryUserData = {
    'galleries.galleryCount': increment(1),
  }

  return { galleryUserRef, galleryUserData }
}

export const incrementUserGallery = (user, inc, portefolioGalleryCounter) => {
  const docRef = getUserRef(user.uid)

  const incrementValue = increment(inc ? 1 : -1)

  const data = {
    [`galleries.${portefolioGalleryCounter ? 'portefolioGalleryCount' : 'galleryCount'}`]: incrementValue,
  }

  return updateDoc(docRef, data)
    .catch(error => {
      logError(error, 'Error incrementing gallery count: ' + error)
    })
}

export const checkIfUsernameExists = username => {
  const q = query(
    getUsersRef(),
    where('username', '==', username)
  )

  console.info(`Checking if username ${username} exists`)
  return getDocs(q)
    .then(querySnapshot => {
      return querySnapshot.size > 0
    }).catch(error => {
      logError(error, `Error checking if username ${username} exists: ${error}`)
    })
}

export const getUserGalleries = (userId, options) => {
  const constraints = []
  if (options?.onlyPublicGalleries) {
    constraints.push(where('sharingOptions.shared', '==', true))
  }

  if (options?.eventId) {
    constraints.push(where('eventId', '==', options?.eventId))
  }

  const q = query(
    getGalleriesRef(),
    where('userUid', '==', userId),
    ...constraints
  )

  console.info('Fetching galleries for user:', userId)
  return getDocs(q)
    .then(eventsSnapshot => {
      console.info('Fetched galleries for user:', userId)

      const galleries = eventsSnapshot.docs
        ?.reduce((prev, currDoc) => {
          const gallery = currDoc.data()
          prev[gallery.id] = gallery
          return prev
        }, {}) || {}

      return galleries
    })
    .catch(error => {
      logError(error, `Error fetching galleries from user ${userId}: ${error}`)
      throw error
    })
}

export const getUserPortfolioPhoto = (userUid, photoId) => {
  const docRef = getUserRef(userUid)

  console.info(`Fetching user photo ${photoId} from user ${userUid} portfolio`)
  return getDoc(docRef)
    .then(user => {
      console.info('Fetched user:', user)
      if (user.exists()) {
        const userData = user.data()
        return userData.portfolio?.cards?.[photoId] ?? null
      } else {
        // doc.data() will be undefined in this case
        console.log('No such user with userUid', userUid)
        return null
      }
    }).catch(error => {
      logError(error, `Error fetching user ${userUid}: ${error}`)
    })
}

export const resetMFA = (email, pwd, recoveryCode) => {
  console.log(`Reseting Multifactor Authentication from user with email ${email}`)

  const resetMFAFunction = httpsCallable(
    getFunctions(),
    'resetMfa',
    { limitedUseAppCheckTokens: true } // get fresh AppCheck token to call the function. More info: https://firebase.google.com/docs/app-check/cloud-functions?hl=pt&authuser=2&_gl=1*alaeom*_ga*MTQ4NjYwOTMzMi4xNjU2OTcwOTQ5*_ga_CW55HF8NVT*MTcxMDAxODI1NC4yMDIuMS4xNzEwMDE4MzcxLjU4LjAuMA..#replay-protection
  )

  return resetMFAFunction({ email, password: pwd, mfaRecoveryCode: recoveryCode })
      .then(watermarkData => {
        console.log(`Multifactor Authentication reset for user with email ${email}!`)
        return watermarkData.data
      })
      .catch(error => {
        logError(error, `Error reseting Multifactor Authentication for user with email ${email}: ${error}`)
        throw error
      })
}

export const postRecoveryCode = (userUid, mfaRecoveryCode) => {
  console.log(`Adding user ${userUid} MFA Recovery Code`)

  const mfaRecoverCodeDoc = doc(getDatabase(), 'users', userUid, 'recoveryCodes', 'mfaRecoveryCode')

  return setDoc(mfaRecoverCodeDoc, { value: mfaRecoveryCode })
    .then(() => {
      console.info('User recovery code successfully created')
    }).catch(error => {
      logError(error, `Error creating MFA Recovery Code for user user ${userUid}: ${error}`)
    })
}

export const getRecoveryCode = userUid => {
  console.log(`Fetching user ${userUid} MFA Recovery Code`)

  const mfaRecoverCodeDoc = doc(getDatabase(), 'users', userUid, 'recoveryCodes', 'mfaRecoveryCode')

  return getDoc(mfaRecoverCodeDoc)
    .then(recoveryCodeData => {
      console.info('Fetched user recovery code')
      if (recoveryCodeData.exists()) {
        const userData = recoveryCodeData.data()
        return userData.value
      } else {
        // doc.data() will be undefined in this case
        return null
      }
    }).catch(error => {
      logError(error, `Error fetching user ${userUid} recovery code: ${error}`)
      throw error
    })
}
