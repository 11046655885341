import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  fetchGalleries as fetchGalleriesAction,
} from 'store/galleries/actions'
import {
  fetchPacks as fetchPacksAction,
  uploadPack as uploadPackAction,
  removePack as removePackAction,
} from 'store/packs/actions'
import {
  fetchUser as fetchUserAction,
  fetchUserByUsername as fetchUserByUsernameAction,
  addUserPack as addUserPackAction,
  addUserPortfolioCards as addUserPortfolioCardsAction,
  editUserAvatar as editUserAvatarAction,
  editUserInfo as editUserInfoAction,
  updateWalkthroughData as updateWalkthroughDataAction,
  editUserPacks as editUserPacksAction,
} from 'store/users/actions'

export const useLocalActions = ({ userId, username, user }) => {
  const dispatch = useDispatch()

  const fetchUser = useCallback(
    () => dispatch(fetchUserAction(userId)),
    [dispatch, userId]
  )

  const fetchUserByUsername = useCallback(
    () => dispatch(fetchUserByUsernameAction(username, true)),
    [dispatch, username]
  )

  const fetchUserGalleries = useCallback(
    (options, fetchAdjacentData) =>
      dispatch(fetchGalleriesAction(userId, options, fetchAdjacentData)),
    [dispatch, userId]
  )

  const fetchUserPacks = useCallback(
    () => dispatch(fetchPacksAction(user.packsIds, user)),
    [dispatch, user]
  )

  const uploadPack = useCallback(
    (newPack, visibility) => dispatch(uploadPackAction(newPack, visibility, user)),
    [dispatch, user]
  )

  const removePack = useCallback(
    pack => dispatch(removePackAction(pack)),
    [dispatch]
  )

  const addUserPack = useCallback(
    (packId, addToStart, visible, postToServer) =>
      dispatch(addUserPackAction(user, packId, addToStart, visible, postToServer)),
    [dispatch, user]
  )

  const addUserPortfolioCards = useCallback(
    (images, addToStart, onProgressChange) =>
      dispatch(addUserPortfolioCardsAction(user, images, undefined, addToStart, onProgressChange)),
    [dispatch, user]
  )

  const editUserAvatar = useCallback(
    avatarFile => dispatch(editUserAvatarAction(user, avatarFile)),
    [dispatch, user]
  )

  const editUserInfo = useCallback(
    info => dispatch(editUserInfoAction(user, info)),
    [dispatch, user]
  )

  const updateWalkthroughData = useCallback(
    (walkthroughKey, walkthroughData) => dispatch(updateWalkthroughDataAction(user, walkthroughKey, walkthroughData)),
    [dispatch, user]
  )

  const editUserPacks = useCallback(
    packIds => dispatch(editUserPacksAction(user, packIds)),
    [dispatch, user]
  )

  return {
    fetchUser,
    fetchUserByUsername,
    fetchUserGalleries,
    fetchUserPacks,
    uploadPack,
    removePack,
    addUserPack,
    addUserPortfolioCards,
    editUserAvatar,
    editUserInfo,
    updateWalkthroughData,
    editUserPacks,
  }
}
