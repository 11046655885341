import ClearIcon from '@mui/icons-material/Clear'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Thumbs, FreeMode, Keyboard } from 'swiper/modules'
import classNames from 'classnames'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { IconButton } from 'components/Button'
import { Image } from 'components/Image'
import { useBreakpoints } from 'hooks/useBreakpoints'

import 'swiper/css' // only core Swiper styles
import 'swiper/css/bundle' // all Swiper styles including all modules styles (like Navigation, Pagination, etc.)

import './styles.scss'

const onContextMenu = galleryDownloadIsAllowed => event => {
  if (galleryDownloadIsAllowed) {
    return true
  }
  event.preventDefault()
  event.stopPropagation()
  event.stopImmediatePropagation?.()
  return false
}

const renderSlideImage = (image, toggleUI, isThumb, isSelected, galleryDownloadIsAllowed, galleryWatermark) => {
  const photoSrc = galleryWatermark?.id && (galleryWatermark?.id === image.watermark?.watermarkId)
    ? image.watermark?.src
    : image.src
  const applyingWatermark = image.applyingWatermark || image.watermark?.isLoading
  const shouldBlurImage = applyingWatermark

  const thumbnails = isThumb
    ? [ { size: 'xs', media: '(min-width: 0px)' } ]
    : [
      { size: 'm', media: '(max-width: 600px)' },
      { size: 'l', media: '(min-width: 600px) and (max-width: 1920px)' },
      { size: 'xl', media: '(min-width: 1920px)' },
    ]

  return (
    <SwiperSlide key={image.id}>
      <Image
        className={classNames('carousel__slide__image', {
          'carousel__slide__image--thumb': isThumb,
          'carousel__slide__image--selected': isThumb && isSelected,
          'carousel__slide__image--non-selectable': !galleryDownloadIsAllowed,
          'carousel__slide__image--blurred': shouldBlurImage,
        })}
        thumbnailsSpecs={thumbnails}
        alt='carousel slide'
        src={photoSrc}
        onClick={isThumb ? undefined : toggleUI}
        generateThumbnailIfError
        onContextMenu={onContextMenu(galleryDownloadIsAllowed)}
        draggable={!!galleryDownloadIsAllowed}
        lazyLoaded
      />
    </SwiperSlide>
  )
}

const getSlidesPerView = ({ isMobileAndUp, isSmallTabletAndUp, isDesktopAndUp }) => {
  if (isDesktopAndUp) {
    return 12
  }

  if (isSmallTabletAndUp) {
    return 8
  }

  if (isMobileAndUp) {
    return 6
  }
}

export const Carousel = ({
  modalIsOpen,
  closeModal,
  onChangeIndex,
  imgs = [],
  imgsLength,
  initialPhotoId,
  actionButtons: customActionButtons,
  galleryDownloadIsAllowed,
  galleryWatermark,
}) => {
  const breakpoints = useBreakpoints()
  const dismissibleUI = breakpoints.isMobile || breakpoints.isSmallTablet
  const [activeIndex, setActiveIndex] = useState(0)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [carousellUIEnabled, setCarousellUIEnabled] = useState(true)
  const [thumbsSwiperIsHidden, setThumbsSwiperIsHidden] = useState(false)

  useEffect(() => {
    if (!modalIsOpen) {
      setThumbsSwiper(null)
    }
  }, [modalIsOpen])

  const onSlideChange = slider => {
    const nextCurrentIndex = slider.realIndex

    setActiveIndex(nextCurrentIndex)
    onChangeIndex && onChangeIndex(nextCurrentIndex)
  }

  const toggleUI = () => {
    if (dismissibleUI) {
      setCarousellUIEnabled(state => !state)
    }
  }

  const onCloseModal = event => {
    closeModal()
    event.preventDefault()
    event.stopPropagation()
  }

  if (!modalIsOpen) {
    return null
  }

  const iconGetter = () => {
    return thumbsSwiperIsHidden ? <ExpandLessIcon fontSize='medium' /> : <ExpandMoreIcon fontSize='medium' />
  }

  const actionButtons = [
    ...(customActionButtons || []),
    {
      id: 'marked-photo-icon-2',
      iconGetter,
      onClick: () => setThumbsSwiperIsHidden(!thumbsSwiperIsHidden),
    },
  ]

  return (
    <section>
      <div>
        <div
          className={classNames('carousel__title', {
            'carousel__title--carousel-ui-enabled': carousellUIEnabled,
          })}
        >
          {`${activeIndex + 1}/${imgsLength}`}
        </div>
        <IconButton
          className={classNames('carousel__close-btn', {
            'carousel__close-btn--carousel-ui-enabled': carousellUIEnabled,
          })}
          color='default'
          size='small'
          aria-label='Close Carousel'
          onClick={onCloseModal}
        >
          <ClearIcon />
        </IconButton>
      </div>
      <div className='carousel__swiper-wrapper'>
        <div
          className={classNames('carousel__swiper', {
            'carousel__swiper--fullscreen': thumbsSwiperIsHidden || !carousellUIEnabled,
          })}
        >
          <Swiper
            modules={[Navigation, Pagination, Thumbs, FreeMode, Keyboard]}
            initialSlide={imgs.findIndex(img => img.id === initialPhotoId)}
            style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
            className='carousel__swiper-slide'
            navigation={carousellUIEnabled}
            pagination={{
              'dynamicBullets': carousellUIEnabled,
            }}
            loop
            thumbs={{ swiper: thumbsSwiper }}
            keyboard={{ enabled: true, onlyInViewport: false }}
            onSlideChange={onSlideChange}
          >
            {
              imgs.map((img, index) => {
                return renderSlideImage(
                  img,
                  toggleUI,
                  false,
                  activeIndex === index,
                  galleryDownloadIsAllowed,
                  galleryWatermark
                )
              })
            }
          </Swiper>
          {actionButtons && actionButtons.length > 0 && (
            <div
              className={classNames('carousel__swiper__action-buttons', {
                'carousel__swiper__action-buttons--carousel-ui-enabled': carousellUIEnabled,
              })}
            >
              {actionButtons.map(actionButton => {
                const isVisible = actionButton.visible ?? true
                if (!isVisible) {
                  return false
                }

                if (actionButton.componentGetter) {
                  return actionButton.componentGetter(imgs[activeIndex])
                }

                return (
                  <IconButton
                    key={actionButton.id}
                    className='carousel__swiper__action-button'
                    color='default'
                    size='small'
                    aria-label='Close Carousel'
                    onClick={() => actionButton.onClick(imgs[activeIndex])}
                  >
                    {actionButton.iconGetter(imgs[activeIndex])}
                  </IconButton>
                )
              })}
            </div>
          )}
        </div>
        <Swiper
          modules={[Thumbs]}
          className={classNames('carousel__swiper-thumbs', {
            'carousel__swiper-thumbs--carousel-ui-disabled': !carousellUIEnabled || thumbsSwiperIsHidden,
          })}
          onSwiper={setThumbsSwiper}
          slidesPerView={getSlidesPerView(breakpoints)}
          spaceBetween={4}
          freeMode
          watchSlidesProgress
        >
          {
            imgs.map((img, index) =>
              renderSlideImage(
                img,
                toggleUI,
                true,
                activeIndex === index,
                galleryDownloadIsAllowed,
                galleryWatermark
              ))
          }
        </Swiper>
      </div>
    </section>
  )
}
