import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import React, { useEffect, useState } from 'react'

import { Image } from 'components/Image'
import { UserCircularInput } from 'components/UserCircularInput'

import { useAlert } from 'hooks/useAlert'
import { useUploading } from 'hooks/useUploading'

import { t } from 'services/i18n'

import { resizeImage, convertToWebP } from 'utils/images'

import './styles.scss'

const MAX_SIZE_IN_BYTES = 600000
const ALERT_DURATION = 5000

const SAMPLE_IMAGES = [
  'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/assets%2Fsample%20avatar%20images%2Fgeneric-avatar-4.png?alt=media&token=e8294618-d484-4d31-bed7-7119d72f8b48',
  'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/assets%2Fsample%20avatar%20images%2Fgeneric-avatar-5.png?alt=media&token=34b9dcb8-f463-4d03-ad0d-d909cbee2e3f',
  'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/assets%2Fsample%20avatar%20images%2Fgeneric-avatar-6.png?alt=media&token=e6f333cd-64cb-48ca-9707-f06a0d231d4c',
  'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/assets%2Fsample%20avatar%20images%2Fgeneric-avatar-7.png?alt=media&token=68236372-251c-494d-8499-43e708167282',
  'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/assets%2Fsample%20avatar%20images%2Fgeneric-avatar-8.png?alt=media&token=62f05682-9d9e-477f-b67a-66d521013001',
]

export const PhotoPicker = props => {
  const initialSource = props.src
  const [ imageSrc, setImageSrc ] = useState(initialSource)
  const [ alertIsOpen, openAlert ] = useAlert({ timeout: ALERT_DURATION })
  const [ uploadingStatus, startUploading, finishUploading ] = useUploading({ initialDelay: 500 })

  useEffect(() => {
    setImageSrc(initialSource)
  }, [initialSource])

  const sendConvertedImage = convertedImageData => {
    finishUploading()
    setImageSrc(convertedImageData.src)
    props.onImageChange && props.onImageChange({
      name: 'avatar.webp',
      blob: convertedImageData.blob,
      src: convertedImageData.src,
    })
  }

  const onImageChange = image => {
    const imageSizeInBytes = image.file?.size
    if (imageSizeInBytes > MAX_SIZE_IN_BYTES) {
      openAlert(true)
      startUploading()
      // first convert to webp so that image size in bytes is not dependent in image type
      convertToWebP(image.file)
        .then(webpImageData => {
          if (webpImageData.blob.size > MAX_SIZE_IN_BYTES) {
            // resize to a maximum width or height of 600px
            resizeImage({
              imageSrc: webpImageData.src,
              maxWidth: 1600,
              maxHeight: 1600,
            })
            .then(sendConvertedImage)
          } else {
            sendConvertedImage(webpImageData)
          }
        })
    } else {
      props.onImageChange && !image.loading && props.onImageChange(image)
    }

    !image.loading && setImageSrc(image?.src)
  }

  const setDefaultImage = sampleImageIndex => () => {
    setImageSrc(SAMPLE_IMAGES[sampleImageIndex])
    props.onImageChange && props.onImageChange({ src: SAMPLE_IMAGES[sampleImageIndex] })
  }

  const onRemoveImageClick = () => {
    setImageSrc(undefined)
    props.onImageChange && props.onImageChange('')
  }

  return (
    <div className='photo-picker'>
      <div className='photo-picker__content'>
        <div className='photo-picker__input'>
          <UserCircularInput
            onImageInputChange={onImageChange}
            size={160}
            noInfo
            avatar={imageSrc}
            uploadStatus={uploadingStatus}
            onRemoveImageClick={onRemoveImageClick}
            disabledRemoveImageButton={!imageSrc}
          />
        </div>
        <div className='photo-picker__default-images-wrapper'>
          <div className='photo-picker__default-images-label'>
            Ou escolhe uma imagem nossa...
          </div>
          <div className='photo-picker__default-images-wrapper'>
            <div className='photo-picker__default-images'>
              {SAMPLE_IMAGES.map((image, sampleImageIndex) => {
                return (
                  <Image
                    // eslint-disable-next-line react/no-array-index-key
                    key={sampleImageIndex}
                    onClick={setDefaultImage(sampleImageIndex)}
                    className='photo-picker__default-images__image'
                    width={60}
                    src={image}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Collapse className='photo-picker__alert' in={alertIsOpen}>
        <Alert severity='warning'>
          {t('components.photo_picker.oversize_alert', {
            'image_size': MAX_SIZE_IN_BYTES,
          })}
        </Alert>
      </Collapse>
    </div>
  )
}
