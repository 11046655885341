import DiamondIcon from '@mui/icons-material/DiamondOutlined'
import LaptopIcon from '@mui/icons-material/Laptop'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react'
import { SketchPicker as ColorPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { GalleryCover } from 'scenes/Gallery/components/GalleryCover'

import { Alert } from 'components/Alert'
import { Button } from 'components/Button'
import { GalleryMasonryLayout } from 'components/GalleryMasonryLayout'
import { HelpTooltip } from 'components/HelpTooltip'
import { LoadingComponent } from 'components/LoadingComponent'
import { Modal, ModalSection, SimpleMessageModal } from 'components/Modal'
import { ModalActionButtons } from 'components/ModalActionButtons'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { TextField } from 'components/TextField'
import { WatermarkPin } from 'components/WatermarkPin'

import { CoverPhotoPicker } from './components/CoverPhotoPicker'
import { WatermarksPicker } from './components/WatermarksPicker'

import { useModal } from 'hooks/useModal'
import { ACTION_KEYS, useRestrictedAction } from 'hooks/useRestrictedAction'

import { fetchUserWatermarks } from 'store/watermarks/actions'

import { t } from 'services/i18n'

import './styles.scss'

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  Popover,
  ToggleButtonGroup,
  ToggleButton,
  Slider,
} from '@mui/material'

const presetColors = [
  { color: '#ffffff', title: 'White', fontColor: '#000000' },
  { color: '#a36140', title: 'Brown (Crayola)', fontColor: '#ffffff' },
  { color: '#e6d5ce', title: 'Bone', fontColor: '#000000' },
  { color: '#b09875', title: 'Pale Taupe', fontColor: '#ffffff' },
  { color: '#bf968c', title: 'Rosy Brown', fontColor: '#ffffff' },
  { color: '#515747', title: 'Gray-Asparagus', fontColor: '#ffffff' },
  { color: '#3d4c58', title: 'Outer Space', fontColor: '#ffffff' },
  { color: '#e7e7e7', title: 'Platinum', fontColor: '#000000' },
]

const coverTypes = [
  {
    type: 'center-expanded',
    label: 'Capa central expandida',
  },
  {
    type: 'center-horizontal',
    label: 'Capa central',
  },
  {
    type: 'center-vertical-left',
    label: 'Capa esquerda',
  },
]

const layoutNrOfColumnsOffsetConfig = [
  {
    value: 1,
    label: 'pequenas',
  },
  {
    value: -1,
    label: 'grandes',
  },
]

const layoutMarginOffsetConfig = [
  {
    value: 0,
    label: 'pequenas',
  },
  {
    value: 4,
    label: 'grandes',
  },
]

const boxHeighPercentages = [
  0.75,
  1.10,
  0.75,
  0.90,
  1.00,
  0.65,
  1.10,
  1.10,
  0.75,
  0.75,
  1.10,
  0.75,
  0.90,
  0.80,
  0.65,
  1.10,
  1.10,
  0.75,
  1.10,
  0.75,
]

export const GalleryEdit = props => {
  const initialGallery = props.initialGallery || {}

  const [ title, setTitle ] = useState(initialGallery.title || '')
  const [ downloadIsAllowed, setDownloadIsAllowed ] = useState(initialGallery.downloadIsAllowed ?? false)
  const [ markedPhotosAreAllowed, setMarkedPhotosAreAllowed ] =
    useState(initialGallery.markedPhotos?.markedPhotosAreAllowed ?? false)
  const [ markedPhotosMaxLimit, setMarkedPhotosMaxLimit ] = useState(initialGallery.markedPhotos?.markedPhotosMaxLimit)
  const [ modalIsOpen, openModal, closeModal ] = useModal()
  const [ coverPickerModalIsOpen, openCoverPickerModal, closeCoverPickerModal ] = useModal()
  const [ colorPickerIsOpen, openColorPicker, closeColorPicker, colorPickerContext ] = useModal()
  const [
    downloadConfirmationModalIsOpen,
    openDownloadConfirmationModal,
    closeDownloadConfirmationModal,
    downloadConfirmationModalContext,
  ] = useModal()
  const [
    addWatermarkConfirmationModalIsOpen,
    openAddWatermarkConfirmationModal,
    closeAddWatermarkConfirmationModal,
  ] = useModal()
  const [ watermark, setWatermark ] = useState(initialGallery.watermark)
  const watermarksAreLoading = useSelector(state => state.watermarks.loading)
  const watermarksData = useSelector(state => state.watermarks.data)
  const watermarkData = watermarksData?.[watermark?.id]
  const [ textColor, setTextColor ] = useState(initialGallery.personalisation?.coverOptions.colorSet.fontColor ?? '#fff')
  const [ backgroundColor, setBackgroundColor ] = useState(initialGallery.personalisation?.coverOptions.colorSet.backgroundColor ?? '#fff')
  const [ coverType, setCoverType ] = useState(initialGallery.personalisation?.coverOptions.type ?? 'center-expanded')
  const [ borderOptionIsSelected, setBorderOptionIsSelected ] =
    useState(initialGallery.personalisation?.coverOptions.border ?? false)
  const [ uppercaseOptionIsSelected, setUppercaseOptionIsSelected ] =
    useState(initialGallery.personalisation?.coverOptions.uppercase ?? true)
  const [ rectAnglesOptionIsSelected, setRectAnglesOptionIsSelected ] =
    useState(initialGallery.personalisation?.coverOptions.rectAngles ?? false)
  const [ hideCover, setHideCover ] =
    useState(initialGallery.personalisation?.coverOptions.hideCover ?? false)
  const [ layoutNrOfColumnsOffset, setLayoutNrOfColumnsOffset ] =
    useState(initialGallery.personalisation?.layoutOptions?.columnsOffset ?? 0)
  const [ layoutMarginOffset, setLayoutMarginOffset ] =
    useState(initialGallery.personalisation?.layoutOptions?.marginOffset ?? 0)
  const [ layoutAllowWideImages, setLayoutAllowWideImages ] =
    useState(initialGallery.personalisation?.layoutOptions?.wideImages ?? false)
  const [ layoutRectAngles, setLayoutRectAngles ] =
    useState(initialGallery.personalisation?.layoutOptions?.rectAngles ?? false)
  const [device, setDevice] = useState('desktop')
  const initialCoverPhoto = initialGallery?.coverPhoto === null
    ? initialGallery.photos?.[0] || {}
    : initialGallery.coverPhoto || {}
  const [newPickedCoverImage, setNewPickedCoverImage] = useState(initialCoverPhoto)

  const coverOptions = {
    type: coverType,
    border: borderOptionIsSelected,
    uppercase: uppercaseOptionIsSelected,
    colorSet: {
      backgroundColor,
      fontColor: textColor,
    },
    rectAngles: rectAnglesOptionIsSelected,
    hideCover,
  }

  const layoutOptions = {
    columnsOffset: layoutNrOfColumnsOffset,
    marginOffset: layoutMarginOffset,
    wideImages: layoutAllowWideImages,
    rectAngles: layoutRectAngles,
  }

  const isRestrictedPredicate = useCallback((userPlanIsLowerThanRequired, options) => {
    const watermarksUsedInGalleriesCount = Object.keys(watermarksData || {})
      .reduce((prev, currWatermarkKey) => {
        const watermarkGalleries = watermarksData[currWatermarkKey].galleries || {}
        return prev + Object.keys(watermarkGalleries).length
      }, 0)

    // return is restricted if:
    // - user is in a lower plan than PLOTU PRO Base
    // - user is PLOTU PRO Base but has more than 2 watermarks
    return options.userPlanIndex === 1 ? watermarksUsedInGalleriesCount >= 2 : userPlanIsLowerThanRequired
  }, [watermarksData])

  const customNotifyOptionsPredicate = useCallback((_userPlanIsLowerThanRequired, options) => {
    if (options.userPlanIndex === 1) {
      return {
        titlei18nSuffix: 'more_title',
        updateLabeli18nSuffix: 'more_update_label',
      }
    }

    return null
  }, [])

  const restrictedActionOptions = useMemo(() => {
    return { isRestrictedPredicate, customNotifyOptionsPredicate }
  }, [isRestrictedPredicate, customNotifyOptionsPredicate])

  const [hasPermission, showUpsell] =
    useRestrictedAction(
      props.logedInUser,
      ACTION_KEYS.WATERMARKS,
      restrictedActionOptions
    )

  const dispatch = useDispatch()
  const fetchWatermarks = useCallback(
    () => dispatch(fetchUserWatermarks(props.logedInUser)),
    [dispatch, props.logedInUser]
  )

  useEffect(() => {
    setTitle(initialGallery.title || '')
    setDownloadIsAllowed(initialGallery.downloadIsAllowed ?? false)
    setMarkedPhotosAreAllowed(initialGallery.markedPhotos?.markedPhotosAreAllowed ?? false)
    setMarkedPhotosMaxLimit(initialGallery.markedPhotos?.markedPhotosMaxLimit)
    setWatermark(initialGallery.watermark)
  }, [
    initialGallery.title,
    initialGallery.downloadIsAllowed,
    initialGallery.markedPhotos,
    initialGallery.watermark,
  ])

  useEffect(() => {
    // always refresh watermarks when modal is loaded and if gallery has a watermark associated
    if (watermarksAreLoading === undefined && !watermarksData) {
      fetchWatermarks()
    }
  }, [watermarksAreLoading, watermarksData, fetchWatermarks])

  const onTitleChange = event => {
    const newTitle = event.target.value
    setTitle(newTitle)
  }

  const handleDownloadAllowedChange = event => {
    const newValue = event.target.checked

    if (!!newValue && !!watermark) {
      openDownloadConfirmationModal(newValue)
      return
    }

    setDownloadIsAllowed(newValue)
  }

  const onDownloadConfirmationModalCtaClick = () => {
    closeDownloadConfirmationModal()
    setDownloadIsAllowed(downloadConfirmationModalContext)
  }

  const handleMarkedPhotosAllowedChange = event => {
    const value = event.target.checked

    if (value === false && Object.keys(initialGallery.markedPhotos?.photoIds || {}).length > 0) {
      const res = window.confirm(t('back_office.gallery.edit_modal.confirm_disable_marked_photos'))
      if (!res) {
        return
      }
    }

    setMarkedPhotosAreAllowed(value)
  }

  const handleMarkedPhotosMaxLimitChange = event => {
    const value = event.target.value
    setMarkedPhotosMaxLimit(value)
  }

  const onWatermarkPick = watermark => {
    closeModal()
    setWatermark(watermark)
  }

  const onSaveGallery = () => {
    const galleryData = {
      ...initialGallery,
      id: initialGallery.id || uuidv4(),
      title,
      markedPhotos: {
        ...initialGallery.markedPhotos,
        markedPhotosAreAllowed,
        markedPhotosMaxLimit,
      },
      downloadIsAllowed,
      creationDate: moment().unix(),
      personalisation: {
        coverOptions,
        layoutOptions,
      },
    }

    // add or remove watermark from gallery
    if (watermark) {
      galleryData.watermark = {
        id: watermark.id,
        initiatedTimestamp: Date.now(), // this is also being applied in server function, but it's here just as a safe guard
      }
    } else {
      galleryData.watermark = null
    }

    const oldCoverPhotoId = initialGallery.coverPhotoId
    if (newPickedCoverImage.id && oldCoverPhotoId !== newPickedCoverImage.id) {
      props.onCoverPhotoPick(newPickedCoverImage.id)
    }

    props.onSaveGallery(galleryData, true)
  }

  const onAddWatermarkClick = () => {
    if (!hasPermission) {
      showUpsell()
      return
    }

    if (downloadIsAllowed) {
      openAddWatermarkConfirmationModal()
      return
    }

    openModal()
  }

  const onAddWatermarkConfirmationModalCtaClick = () => {
    closeAddWatermarkConfirmationModal()
    openModal()
  }

  const onColorChange = color => {
    if (colorPickerContext.target === 'text') {
      setTextColor(color.hex)
    } else {
      setBackgroundColor(color.hex)
      const presetColor = presetColors.find(presetColor => presetColor.color === color.hex)
      if (presetColor) {
        setTextColor(presetColor.fontColor)
      }
    }
  }

  const onCoverTypeChange = event => {
    const newValue = event.target.value
    setCoverType(newValue)
    if (backgroundColor === '#fff' && (newValue === 'center-horizontal' || newValue === 'center-vertical-left')) {
      setTextColor('#000000')
    }
  }

  const onLayoutNrOfColumnsOffsetChange = (_event, newValue) => {
    if (typeof newValue === 'number') {
      setLayoutNrOfColumnsOffset(newValue)
    }
  }

  const onLayoutMarginOffsetChange = (_event, newValue) => {
    if (typeof newValue === 'number') {
      setLayoutMarginOffset(newValue)
    }
  }

  const onDeviceChange = (_event, newDevice) => {
    if (newDevice !== null) {
      setDevice(newDevice)
    }
  }

  const onChooseCoverBtnPhotoClick = () => {
    props.fetchAllGalleryPhotos()
    openCoverPickerModal()
  }

  const handlePhotoClick = photo => {
    closeCoverPickerModal()
    setNewPickedCoverImage(photo)
  }

  return (
    <Fragment>
      <ModalSection noMargin>
        <div className='gallery-edit__primary-field'>
          <TextField
            label={t('back_office.gallery.edit_modal.title_label')}
            value={title}
            onChange={onTitleChange}
            fullWidth
          />
        </div>
      </ModalSection>

      <ModalSection title='Opções' subTitle='Configura a tua galeria e permite que os teus utilizadores escolham ou façam download das tuas fotos'>
        <div className='gallery-edit__primary-field'>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={downloadIsAllowed}
                  onChange={handleDownloadAllowedChange}
                />
              )}
              label={t('back_office.gallery.edit_modal.allow_download_label')}
            />
          </FormGroup>
          <HelpTooltip>
            <div className='gallery-edit__help'>
              <h2 className='gallery-edit__help__title'>{t('back_office.gallery.edit_modal.allow_download_help.title')}</h2>
              <p>{t('back_office.gallery.edit_modal.allow_download_help.description')}</p>
            </div>
          </HelpTooltip>
        </div>

        <div className='gallery-edit__primary-field'>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={markedPhotosAreAllowed}
                  onChange={handleMarkedPhotosAllowedChange}
                />
              )}
              label={t('back_office.gallery.edit_modal.allow_marked_photos_label')}
            />
          </FormGroup>
          <HelpTooltip>
            <React.Fragment>
              <h2 className='gallery-edit__help__title'>{t('back_office.gallery.edit_modal.allow_marked_photos_help.title')}</h2>
              <p>{t('back_office.gallery.edit_modal.allow_marked_photos_help.description')}</p>
            </React.Fragment>
          </HelpTooltip>
        </div>
        {markedPhotosAreAllowed && (
          <div className='gallery-edit__max-limit'>
            <TextField
              className='gallery-edit__max-limit-input'
              label='Limite máximo'
              helperText='Escolhe o número máximo de fotos que podem ser marcadas'
              placeholder='Sem limite'
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              type='number'
              value={markedPhotosMaxLimit}
              onChange={handleMarkedPhotosMaxLimitChange}
            />
          </div>
        )}
      </ModalSection>

      <ModalSection title='Marca de água' subTitle='Escolhe uma marca de água para aplicar em todas as fotos da galeria'>
        {watermark && !watermarkData && (
          <LoadingComponent
            isLoading
            size={30}
            className='gallery-edit__watermark-loading'
          />
        )}
        {watermarkData && (
          <div onClick={onAddWatermarkClick} className='gallery-edit__watermark'>
            <WatermarkPin watermark={watermarkData} />
          </div>
        )}
        <div className='gallery-edit__watermark-buttons'>
          {watermark && (
            <Button
              onClick={() => setWatermark()}
              variant='outlined'
              color='neutral'
            >
              Remover marca de água
            </Button>
          )}
          <Button
            startIcon={!watermark ? <DiamondIcon /> : undefined}
            onClick={onAddWatermarkClick}
            variant='outlined'
          >
            {watermark ? 'Editar Marca de Água' : 'Adicionar Marca de Água'}
          </Button>
        </div>
      </ModalSection>

      <ModalSection title='Personalização' subTitle='Personaliza a galeria com a tua marca' last>
        <div className='gallery-edit__cover'>
          <Text type='subtitle'>Capa</Text>
          <ToggleButtonGroup
            value={device}
            exclusive
            onChange={onDeviceChange}
            aria-label='dispositivo a usar para a preview'
          >
            <ToggleButton value='desktop' aria-label='desktop'>
              <LaptopIcon />
            </ToggleButton>
            <ToggleButton value='mobile' aria-label='mobile'>
              <PhoneAndroidIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <div className='gallery-edit__cover__photo-picker-btn-wrapper'>
            <Button onClick={onChooseCoverBtnPhotoClick}>
              Escolher foto de capa
            </Button>
          </div>
          <div className='gallery-edit__cover__device-wrapper'>
            <div
              className={classNames('gallery-edit__cover__width-container', {
                'gallery-edit__cover__width-container--mobile': device === 'mobile',
              })}
            >
              <div
                className={classNames('gallery-edit__cover__aspect-ratio-container', {
                  'gallery-edit__cover__aspect-ratio-container--mobile': device === 'mobile',
                })}
              />
              <div className='gallery-edit__cover__preview'>
                <GalleryCover
                  gallery={{
                    ...initialGallery,
                    coverPhoto: newPickedCoverImage,
                    personalisation: {
                      coverOptions,
                    },
                  }}
                  contentRef={null}
                  isMobilePreview={device === 'mobile'}
                  isPreview
                />
              </div>
            </div>
          </div>
          <Alert variant='text' severity='info'>
            <Text type='body2'>Representação da capa. Versão final pode variar.</Text>
          </Alert>
          <Stack className='gallery-edit__cover__options'>
            <FormControl>
              <Text as='label' type='subtitle2' color='text__enhanced-grey-color'>Tipo de capa</Text>
              <RadioGroup
                aria-labelledby='radio-buttons-group-cover-type'
                value={coverType}
                onChange={onCoverTypeChange}
              >
                {coverTypes.map(coverOption => {
                  return (
                    <FormControlLabel
                      key={coverOption.type}
                      value={coverOption.type}
                      control={<Radio />}
                      label={coverOption.label}
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>

            <FormGroup>
              <Text as='label' type='subtitle2' color='text__enhanced-grey-color'>Opções</Text>
              <FormControlLabel control={<Checkbox />} checked={borderOptionIsSelected} onChange={event => setBorderOptionIsSelected(event.target.checked)} label='Moldura' />
              <FormControlLabel control={<Checkbox />} checked={uppercaseOptionIsSelected} onChange={event => setUppercaseOptionIsSelected(event.target.checked)} label='Letras maiúsculas' />
              <FormControlLabel control={<Checkbox />} checked={rectAnglesOptionIsSelected} onChange={event => setRectAnglesOptionIsSelected(event.target.checked)} label='Ângulos retos' />
              <FormControlLabel control={<Checkbox />} checked={hideCover} onChange={event => setHideCover(event.target.checked)} label='Esconder foto de capa na galeria' />
            </FormGroup>
          </Stack>
          <div className='gallery-edit__cover__color-picker-wrapper'>
            <Text type='subtitle2' color='text__enhanced-grey-color'>Cores</Text>
            <div className='gallery-edit__cover__color-picker-list'>
              <div className='gallery-edit__cover__color-picker'>
                <button
                  className='gallery-edit__cover__color-picker__color-box'
                  style={{ backgroundColor }}
                  onClick={event => openColorPicker({ ref: event.currentTarget, target: 'background' })}
                />
                <Text type='body2'>Fundo</Text>
              </div>
              <div className='gallery-edit__cover__color-picker'>
                <button
                  tabIndex='0'
                  className='gallery-edit__cover__color-picker__color-box'
                  style={{ backgroundColor: textColor }}
                  onClick={event => openColorPicker({ ref: event.currentTarget, target: 'text' })}
                />
                <Text type='body2'>Texto</Text>
              </div>
            </div>
            <Popover
              open={colorPickerIsOpen}
              anchorEl={colorPickerContext?.ref}
              onClose={closeColorPicker}
            >
              <ColorPicker
                color={colorPickerContext?.target === 'text' ? textColor : backgroundColor}
                onChange={onColorChange}
                presetColors={colorPickerContext?.target === 'text' ? ['white', 'black'] : presetColors}
              />
            </Popover>
          </div>
        </div>
        <div className='gallery-edit__layout'>
          <Text type='subtitle'>Layout</Text>
          <Stack gap='x3' className='gallery-edit__layout__previews'>
            <div className='gallery-edit__layout__desktop-frame'>
              <div className='gallery-edit__layout__desktop-frame__outer-screen'>
                <div className='gallery-edit__layout__desktop-frame__screen'>
                  <div className='gallery-edit__layout__desktop-preview'>
                    <GalleryMasonryLayout
                      boxHeighPercentages={boxHeighPercentages}
                      previewNrOfColumns={3}
                      layoutOptions={layoutOptions}
                    >
                      {new Array(20).fill(0).map((_, i) => i)
                        .map(key => {
                          return (
                            <div
                              key={key}
                              style={{ paddingTop: 100 * boxHeighPercentages[key] + '%' }}
                              className={classNames('gallery-edit__layout__preview-box', {
                                'gallery-edit__layout__preview-box--rect-angles': layoutRectAngles,
                              })}
                            />
                          )
                        })}
                    </GalleryMasonryLayout>
                  </div>
                </div>
              </div>
              <div className='gallery-edit__layout__desktop-frame__bottom' />
              <div className='gallery-edit__layout__desktop-frame__column' />
              <div className='gallery-edit__layout__desktop-frame__base' />
            </div>
            <div className='gallery-edit__layout__iphone-frame'>
              <div className='gallery-edit__layout__iphone-frame__screen'>
                <div className='gallery-edit__layout__iphone-frame__dynamic-island' />
                <div className='gallery-edit__layout__mobile-preview'>
                  <GalleryMasonryLayout
                    boxHeighPercentages={boxHeighPercentages}
                    previewNrOfColumns={2}
                    layoutOptions={layoutOptions}
                  >
                    {new Array(20).fill(0).map((_, i) => i)
                  .map(key => {
                    return (
                      <div
                        key={key}
                        style={{ paddingTop: 100 * boxHeighPercentages[key] + '%' }}
                        className={classNames('gallery-edit__layout__preview-box', {
                          'gallery-edit__layout__preview-box--rect-angles': layoutRectAngles,
                        })}
                      />
                    )
                  })}
                  </GalleryMasonryLayout>
                </div>
              </div>
            </div>
          </Stack>
          <Alert variant='text' severity='info'>
            <Text type='body2'>
              O tamanho e disposição final das fotografias pode variar, consoante o tamanho do dispositivo.
            </Text>
          </Alert>
          <Stack className='gallery-edit__cover__options'>
            <Stack vertical gap='x3' className='gallery-edit__cover__options__left'>
              <FormControl>
                <Text as='label' type='subtitle2' color='text__enhanced-grey-color'>Tamanho das fotografias</Text>
                <Slider
                  aria-label='Tamanho das fotografias'
                  value={layoutNrOfColumnsOffset}
                  valueLabelDisplay='off'
                  step={1}
                  marks={layoutNrOfColumnsOffsetConfig}
                  min={-2}
                  max={2}
                  size='small'
                  onChange={onLayoutNrOfColumnsOffsetChange}
                />
              </FormControl>
              <FormControl>
                <Text as='label' type='subtitle2' color='text__enhanced-grey-color'>Tamanho da margem</Text>
                <Slider
                  aria-label='Tamanho da margem'
                  value={layoutMarginOffset}
                  valueLabelDisplay='off'
                  step={1}
                  marks={layoutMarginOffsetConfig}
                  min={-1}
                  max={5}
                  size='small'
                  onChange={onLayoutMarginOffsetChange}
                />
              </FormControl>
            </Stack>
            <FormGroup>
              <Text as='label' type='subtitle2' color='text__enhanced-grey-color'>Opções</Text>
              <FormControlLabel control={<Checkbox />} checked={layoutAllowWideImages} onChange={event => setLayoutAllowWideImages(event.target.checked)} label='Expandir fotografias automaticamente' />
              <FormControlLabel control={<Checkbox />} checked={layoutRectAngles} onChange={event => setLayoutRectAngles(event.target.checked)} label='Ângulos retos' />
            </FormGroup>
          </Stack>
        </div>
      </ModalSection>

      <ModalSection footer>
        <ModalActionButtons
          leftButton={{
            action: props.onRemove,
            label: t('back_office.calendar.appointment.delete'),
            hidden: !props.initialGallery,
          }}
          secondaryButton={{ action: props.onCancel, label: t('back_office.gallery.edit_modal.cancel') }}
          primaryButton={{ action: onSaveGallery, label: t('back_office.gallery.edit_modal.confirm') }}
        />
      </ModalSection>
      <Modal
        open={modalIsOpen}
        closeModal={closeModal}
        title={watermarksData ? 'Editar Marca de Água' : 'Adicionar Marca de Água'}
        withSections
        secondaryActionButton={{
          onClick: closeModal,
          text: 'Cancelar',
        }}
      >
        <WatermarksPicker
          watermarksData={watermarksData}
          logedInUser={props.logedInUser}
          onWatermarkPick={onWatermarkPick}
        />
      </Modal>
      <SimpleMessageModal
        open={downloadConfirmationModalIsOpen}
        closeModal={closeDownloadConfirmationModal}
        modalTitle={t('back_office.gallery.download_confirmation_modal.title')}
        message={t('back_office.gallery.download_confirmation_modal.message')}
        secondaryActionButtonText={t('back_office.gallery.download_confirmation_modal.cancel')}
        secondaryActionButtonVariant='text'
        secondaryActionButtonColor='default'
        onSecondaryActionButton={closeDownloadConfirmationModal}
        actionButtonText={t('back_office.gallery.download_confirmation_modal.confirm')}
        actionButtonColor='primary'
        onActionButton={onDownloadConfirmationModalCtaClick}
      />
      <SimpleMessageModal
        open={addWatermarkConfirmationModalIsOpen}
        closeModal={closeAddWatermarkConfirmationModal}
        modalTitle={t('back_office.gallery.add_watermark_confirmation_modal.title')}
        message={t('back_office.gallery.add_watermark_confirmation_modal.message')}
        secondaryActionButtonText={t('back_office.gallery.add_watermark_confirmation_modal.cancel')}
        secondaryActionButtonVariant='text'
        secondaryActionButtonColor='default'
        onSecondaryActionButton={closeAddWatermarkConfirmationModal}
        actionButtonText={t('back_office.gallery.add_watermark_confirmation_modal.confirm')}
        actionButtonColor='primary'
        onActionButton={onAddWatermarkConfirmationModalCtaClick}
      />
      <Modal
        open={coverPickerModalIsOpen}
        closeModal={closeCoverPickerModal}
        title='Escolher Foto de Capa'
        withSections
        secondaryActionButton={{
          onClick: closeCoverPickerModal,
          text: 'Cancelar',
        }}
      >
        <CoverPhotoPicker
          photosLayout={initialGallery.photosLayout || []}
          photos={initialGallery.photos || {}}
          onCoverPhotoPick={handlePhotoClick}
          loading={props.photosAreLoading}
          coverPhotoId={newPickedCoverImage.id}
        />
      </Modal>
    </Fragment>
  )
}
