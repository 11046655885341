import React from 'react'
import classNames from 'classnames'
import { Link } from 'components/Link'
import { createBlogLink } from 'services/routingService'

import './styles.scss'

const Tags = ({ tags, urlTag }) => {
  return (
    <div className='tags-container'>
      {tags.map(tag => (
        <Link
          key={tag}
          href={urlTag === tag
            ? createBlogLink({ removedParameters: ['tag'] })
            : createBlogLink({ queryParameters: { tag } })}
          className={classNames('tags-container__link', { 'tags-container__link--active': urlTag === tag })}
        >
          {tag}
        </Link>
      ))}
    </div>
  )
}

export default Tags
