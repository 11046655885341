import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ImageIcon from '@mui/icons-material/Image'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'

import { Grid } from 'components/Grid'
import { Image } from 'components/Image'
import { ImageInput } from 'components/ImageInput'
import { MenuWrapper } from 'components/MenuWrapper'

import { useDragable } from 'hooks/useDragable'

import { getImagesTotalSize } from 'utils/images'

import './styles.scss'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Component that maintains a list of images and a Images Input component (ImageInput).
 * Both components know each other, particulary the way that images are deleted from the list,
 * so that all the images lofic can be maintained inside ImageInput component.
 */
export const ImageListInput = props => {
  const [ images, setImages ] = useState(props.initialImages || [])
  const inputImages = useRef(props.initialImages || [])
  const [ info, setInfo ] = useState()
  const [ mainImageId, setMainImageId ] = useState(props.initialMainImageId)
  const initialImagesBeforeDragging = useRef(null)

  const changePositions = (initialImageId, finalImageId) => {
    const initialImageIndex = images.findIndex(image => image.id === initialImageId)
    const finalImageIndex = images.findIndex(image => image.id === finalImageId)
    const newImages = reorder(
      images,
      initialImageIndex,
      finalImageIndex
    )
    setImages(newImages)
    // update input images state so that it is sync with the new changes if user wants to add new photos
    inputImages.current = newImages
  }

  const resetPositions = () => {
    setImages(initialImagesBeforeDragging.current)
  }

  const [onDragStart, onDragEnter, onDragOver, onDragLeave, onDragDrop, onDragEnd, draggingItemId] =
    useDragable({ changePositions, resetPositions })

  const onCustomDragStart = event => {
    onDragStart(event)
    initialImagesBeforeDragging.current = images
  }

  const onChange = newImages => {
    const newImagesAsArray = props.multiple ? newImages : [newImages] // keep as an array to lower component maintenance
    setImages(newImagesAsArray)

    if (props.onChange) {
      props.onChange(newImages) // keep array or single image to propagate. Parent components shouldn't have nothing to do about this logic
    }

    if (props.onImagesTotalSizeChange) {
      props.onImagesTotalSizeChange(getImagesTotalSize(newImagesAsArray))
    }

    if (props.onImagesLoadedChange) {
      props.onImagesLoadedChange(newImagesAsArray.every(image => !image.loading))
    }
  }

  const onMainImageIdChange = mainImageId => {
    setMainImageId(mainImageId)
    props.onMainImageIdChange && props.onMainImageIdChange(mainImageId)
  }

  const removeImage = (imageIndex, image) => () => {
    if (props.extraImages && imageIndex < props.extraImages.length) {
      props.removeExtraImage(image)
      return
    }

    const newImages = [...images]
    if (mainImageId && newImages[imageIndex].id === mainImageId) {
      setMainImageId(undefined)
    }

    newImages.splice(imageIndex, 1)

    setImages(newImages)
    // update input images state so that it is sync with the new changes if user wants to add new photos
    inputImages.current = newImages
  }

  const closeSnackbar = () => {
    setInfo(null)
  }

  const getImageOptions = (img, index) => {
    const options = []

    if (!props.preventDeleteOption) {
      options.push({
        text: 'Apagar',
        iconId:  'delete',
        onClick: removeImage(index, img),
      })
    }

    if (!props.ignoreMainImage && props.multiple) {
      options.push({
        text: 'Usar como foto de capa',
        iconId: 'photo',
        onClick: () => onMainImageIdChange(img.id),
      })
    }

    return options
  }

  const isMainImage = (image, idx) => {
    if (props.ignoreMainImage || !props.multiple) {
      return false
    }
    return mainImageId === image.id || (mainImageId == null && idx === 0)
  }

  const imagesToList = props.extraImages ? [...props.extraImages, ...images] : images

  return (
    <div>
      <Grid spacing={10} xs={6} sm={4} md={3} xl={2}>
        {imagesToList.map((image, idx) => {
          const menuOptions = getImageOptions(image, idx)
          return (
            <div
              key={image.id}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDragEnd={onDragEnd}
              onDrop={onDragDrop}
            >
              <MenuWrapper
                className='images-input__menu-wrapper'
                editMode={menuOptions.length > 0}
                options={menuOptions}
              >
                <div
                  className={classNames('images-input__list-item', props.imageWrapperClassName)}
                >
                  {!image.loading && (
                    <Image
                      id={image.id}
                      draggable
                      onDragStart={onCustomDragStart}
                      className={classNames('images-input__list-item__image', {
                        'images-input__list-item__image--dragging': draggingItemId === image.id,
                      })}
                      alt={`input gallery item ${idx + 1}`}
                      src={image.src}
                      thumbnailsSpecs={[{ size: 'xs', media: '(min-width: 0px)' }]}
                    />
                  )}
                  {image.loading && (
                    <div className='images-input__list-item__loading'>
                      <CircularProgress />
                    </div>
                  )}
                  {image.processing && (
                    <div className='images-input__list-item__loading' />
                  )}
                  {isMainImage(image, idx) && (
                    <div className='images-input__list-item__main-image'>
                      <ImageIcon />
                    </div>
                  )}
                </div>
              </MenuWrapper>
            </div>
          )
        })}
        {!props.multiple && images.length > 0 && images[0].loading !== true
          ? null
          : (
            <ImageInput
              initialImages={inputImages.current}
              multiple={props.multiple}
              acceptOptions={props.acceptOptions}
              onChange={onChange}
              classNameWrapper='images-input__list-item images-input__list-item--add-image'
              classNameWrapperFocused='images-input__list-item--add-image--focused'
              className='images-input__input'
            >
              <AddCircleOutlineOutlinedIcon fontSize='large' />
            </ImageInput>
          )}
      </Grid>
      <Snackbar open={!!info} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity='info'>
          {info}
        </Alert>
      </Snackbar>
    </div>
  )
}
