import { createBackOfficeLink, subRoutesNames } from 'services/routingService'

const BACK_OFFICE_PAGES_URLS = subRoutesNames.BACK_OFFICE

// add translations
export const BACK_OFFICE_PAGES_CONFIG = [
  {
    id: BACK_OFFICE_PAGES_URLS.DASHBOARD,
    icon: 'home',
    text: 'Dashboard',
    url: createBackOfficeLink(),
  },
  {
    id: BACK_OFFICE_PAGES_URLS.EVENTS,
    subRoutes: [BACK_OFFICE_PAGES_URLS.CALENDAR],
    icon: 'date_range',
    text: 'Eventos',
    url: createBackOfficeLink(BACK_OFFICE_PAGES_URLS.EVENTS),
  },
  {
    id: BACK_OFFICE_PAGES_URLS.PACKS,
    icon: 'apps',
    text: 'Packs',
    url: createBackOfficeLink(BACK_OFFICE_PAGES_URLS.PACKS),
  },
  {
    id: BACK_OFFICE_PAGES_URLS.LAYOUT,
    icon: 'dashboard',
    text: 'Layouts',
    url: createBackOfficeLink(BACK_OFFICE_PAGES_URLS.LAYOUT),
  },
  {
    id: BACK_OFFICE_PAGES_URLS.GALLERIES,
    icon: 'photo_size_select_actual',
    text: 'Galerias',
    url: createBackOfficeLink(BACK_OFFICE_PAGES_URLS.GALLERIES),
  },
  // {
  //   id: BACK_OFFICE_PAGES_URLS.CLIENTS,
  //   icon: 'assignment_ind',
  //   text: 'Clients',
  //   url: createBackOfficeLink(BACK_OFFICE_PAGES_URLS.CLIENTS)
  // },
  // {
  //   id: BACK_OFFICE_PAGES_URLS.STATS,
  //   icon: 'bar_chart',
  //   text: 'Estatísticas',
  //   url: createBackOfficeLink(BACK_OFFICE_PAGES_URLS.STATS)
  // },
]
