import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'

import { NOTIFICATION_TYPE } from 'components/GlobalNotifications'

import { getGalleryArchiveDownloadURL } from 'services/firebaseServer'
import { getNotificationSystem } from 'services/notificationSystem'
import { createGalleryChangesListener, createGalleryArchive, getGallery } from 'services/serverBridge'

import { logError } from 'utils/errorCapture'

const notificationSystem = getNotificationSystem()
const MAX_TIMEOUT_ARCHIVING_GALLERY_FEEDBACK = 120000 // 2 minutes

const getDownloadURL = (galleryId, archiveFilePath, retries, delay) => {
  // this is very attached to Firebase so calling the firebaseServer service directly is not a big deal
  return getGalleryArchiveDownloadURL(galleryId, archiveFilePath, retries, delay)
}

/**
 * This hook handles everything related to the gallery archive download
 * It starts by requesting the gallery data in `handleGalleryArchive` function
 *  1. if gallery data has already an archive data that is valid, it downloads the archive directly
 *  2. if the archive is still being created in the server (progress is below 100), we start the "archive is being created" state (archiveIsBeingCreated variable) with the current progress retrieved from the server
 *    the archive job is not triggered
 *    the gallery changes listener is created with createGalleryChangesListener
 *      when the changes listener checks the progress is 100% we fetch the Download URL and start the download
 *    a timeout (galleryListenerTimeout) is created to regurarlly check if the gallery changes listener was called
 *      if the timeout is reached, we check again the gallery progress in the database and compare it to the last saved progress
 *        if the progress has not changed, we reset the local progress, trigger the timeout again and the remote archiving job
 *          we remove the "archive is being created" state and jump to state 3
 *        if the progress has really changed, we update the local progress and trigger the timeout again
 *  3. if there is no archive already and it's not being created in the server, we:
 *    start the "downloading state" (archiveIsBeingCreated variable)
 *    trigger the archiving job
 *    the gallery changes listener is created with createGalleryChangesListener
 *      when the changes listener checks the progress is 100% we fetch the Download URL and start the download
 *    a timeout (galleryListenerTimeout) is created to regurarlly check if the gallery changes listener was called
 *      if the timeout is reached we cancel the "downloading state" and show an error to the user
 */
export const useHandleGalleryArchive = galleryId => {
  const [downloadingProgress, setDownloadingProgress ] = useState(0)
  const [downloadinGallery, setDownloadinGallery ] = useState(false)
  const [downloadingSnackbarOpen, setDownloadingSnackbarOpen ] = useState(false)
  const unsubscribeListener = useRef()
  const galleryListenerTimeout = useRef()
  const archiveIsBeingCreated = useRef()
  const pageIsVisible = usePageVisibility()

  const finalizeListenerWithError = useCallback(() => {
    logError('timeout error archiving gallery', 'Houve um problema a preparar o download da galeria. Por favor tenta novamente', true)
    finalizeListener(0)
  }, [])

  const finalizeListener = (progress = 0) => {
    clearTimeout(galleryListenerTimeout.current)
    setDownloadingProgress(progress)
    setDownloadingSnackbarOpen(false)
    setDownloadinGallery(false)
    unsubscribeListener.current()
  }

  const galleryUpdateTimeout = useCallback(() => {
    // if we thought archive was being created but there was no update in the meantime, maybe let's trigger the archive process again
    if (archiveIsBeingCreated.current) {
      // to prevent any bug where we are requesting another archive job when is not needed, we call the gallery here to check the real status
      // It's better to call this unique request than to request another archive job
      getGallery(galleryId)
        .then(gallery => {
          // if real progress has not updated so far from the last stored progress, let's begin the request again
          if (gallery.archiveFile?.progress <= downloadingProgress) {
            archiveIsBeingCreated.current = false
            setDownloadingProgress(0)
            galleryListenerTimeout.current = setTimeout(galleryUpdateTimeout, MAX_TIMEOUT_ARCHIVING_GALLERY_FEEDBACK)
            createGalleryArchive(galleryId)
              .catch(error => {
                logError(error, 'Houve um problema a preparar o download da galeria. Por favor tenta novamente 1', true)
                finalizeListener()
              })
          // otherwise, let's update the downloading progress and continue the timeout
          } else if (gallery.archiveFile?.progress > 0) {
            setDownloadingProgress(gallery.archiveFile?.progress)
            galleryListenerTimeout.current = setTimeout(galleryUpdateTimeout, MAX_TIMEOUT_ARCHIVING_GALLERY_FEEDBACK)
          }
        })
      return
    }

    finalizeListenerWithError()
  }, [finalizeListenerWithError, galleryId, downloadingProgress])

  // let's pause the timeout whenever the page is idle
  // this will prevent the timeout to be called when the page is idle and the galleryChangesListenerCallback listener is not called
  useEffect(() => {
    if (!pageIsVisible) {
      clearTimeout(galleryListenerTimeout.current)
    } else if (downloadingSnackbarOpen) {
      clearTimeout(galleryListenerTimeout.current)
      galleryListenerTimeout.current = setTimeout(galleryUpdateTimeout, MAX_TIMEOUT_ARCHIVING_GALLERY_FEEDBACK)
    }
  }, [downloadingSnackbarOpen, galleryUpdateTimeout, pageIsVisible])

  // this helper function simulates a anchor with the download attribute in order to download the file with JS
  const downloadFile = (uri, name) => {
    const link = document.createElement('a')
    link.setAttribute('download', name)
    link.href = uri
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handleGalleryArchive = () => {
    setDownloadinGallery(true)
    setDownloadingProgress(0)
    archiveIsBeingCreated.current = false

    // check directly with firebase to get most recent data
    getGallery(galleryId)
      .then(async gallery => {
        const galleryPhotosLastModifiedTimestamp = gallery.photosLastModifiedTimestamp
        if (gallery.archiveFile) {
          const archiveFile = gallery.archiveFile
          const photosLastModifiedTimestampReference = archiveFile.photosLastModifiedTimestampReference
          const photosLastModifiedTimestampReferenceIsValid =
            photosLastModifiedTimestampReference === galleryPhotosLastModifiedTimestamp ||
            photosLastModifiedTimestampReference === null
          const downloadURL = archiveFile.downloadURL
          if (downloadURL && photosLastModifiedTimestampReferenceIsValid) {
            downloadFile(downloadURL, `galeria ${gallery.title}`)
            setTimeout(() => setDownloadinGallery(false), 4000) // there's no way to know exactly when the download is done, so we just remove the downloading feedback a bit later
            return
          // if archive data reference is valid and progress is 100, we only need to get the download URL
          } else if (
            photosLastModifiedTimestampReferenceIsValid &&
            archiveFile.progress === 100 &&
            archiveFile.archiveFilePath
          ) {
            // we shouldn't wait for Download URL to be fetched but for the sake of simplicity, let's do it for now
            try {
              const newDownloadURL = await getDownloadURL(galleryId, archiveFile.archiveFilePath, 0)
              downloadFile(newDownloadURL, `galeria ${gallery.title}`)
              setTimeout(() => setDownloadinGallery(false), 4000) // there's no way to know exactly when the download is done, so we just remove the downloading feedback a bit later
              // TODO: save downloadURL for next time
              return
            } catch (e) {
              // If there was any problem with the archive file (for example, it was deleted), we need to create the archive file again
              // Because archiveFile?.progress === 100, a notification saying the download is complete will show up, although in reality the archiving job is being done in the BE.
              // Also, because archiveFile.archiveFilePath is already there, at the end of the archiving process, another error will popup (because the file was not in storage by the time progress reaches 100%)
              // Since this is an edge case, let's keep it simple. If the user clicks again in the download button, everything works fine
              // However, if this keeps causing errors, we need to reset the database archiving state right here
              console.log('There was an error fetching gallery download URL. Triggering creation of archive file again')
            }
          // if archive data reference is valid and progress is below 100, let's check if the download is in progress
          } else if (photosLastModifiedTimestampReferenceIsValid && archiveFile?.progress < 100) {
            const createdTimestamp = archiveFile.createdTimestamp
            if (!createdTimestamp || moment().diff(moment.unix(createdTimestamp), 'minutes') < 60) {
              setDownloadingProgress(archiveFile.progress)
              archiveIsBeingCreated.current = true
            }
          }
        }

        // if feedback does not come in MAX_TIMEOUT_ARCHIVING_GALLERY_FEEDBACK ms, we need to send an timeout error
        const resetFeedbackListenerMaxTimeout = () => {
          clearTimeout(galleryListenerTimeout.current)
          galleryListenerTimeout.current = setTimeout(galleryUpdateTimeout, MAX_TIMEOUT_ARCHIVING_GALLERY_FEEDBACK)
        }

        const galleryChangesListenerCallback = async gallery => {
          resetFeedbackListenerMaxTimeout()

          if (gallery.archiveFile?.error) {
            logError(gallery.archiveFile?.error, 'Houve um problema a preparar o download da galeria. Por favor tenta novamente', true)
            finalizeListener()
            return
          }

          const currentProgress = gallery.archiveFile?.progress || 0
          if (
            currentProgress === 100 &&
            gallery.archiveFile?.archiveFilePath
          ) {
            try {
              const nrOfPhotos = gallery.photosLayout?.length || 0
              const initialDelay = nrOfPhotos * 4
              clearTimeout(galleryListenerTimeout.current) // clear this timeout as this phase can be longer than the timeout, especially if the gallery is really large
              const newDownloadURL =
                await getDownloadURL(galleryId, gallery.archiveFile.archiveFilePath, 10, initialDelay)
              finalizeListener(100)
              notificationSystem.notify({ message: 'Feito! O download vai começar', type: NOTIFICATION_TYPE.SUCCESS })
              downloadFile(newDownloadURL, `galeria ${gallery.title}`)
            } catch (e) {
              // let's wait a bit more and see if the file is there already
              if (e.code === 'storage/object-not-found') {
                logError(e, 'O ficheiro ainda está a ser guardado nos nossos servidores. Por favor espera um pouco, recarrega a página e tenta novamente', true)
                finalizeListener(0)
                return
              }
              throw e
            }
            return
          }

          setDownloadingProgress(currentProgress)
        }

        setDownloadingSnackbarOpen(true)
        resetFeedbackListenerMaxTimeout()
        unsubscribeListener.current = createGalleryChangesListener(galleryId, galleryChangesListenerCallback)

        // if archive is not available or is not valid and is not being created in server, we need to trigger the zip creation again
        if (!archiveIsBeingCreated.current) {
          createGalleryArchive(galleryId)
            .catch(error => {
              logError(error, 'Houve um problema a preparar o download da galeria. Por favor tenta novamente', true)
              finalizeListener()
            })
        }
      })
      .catch(error => {
        logError(error, 'Houve um problema a preparar o download da galeria. Por favor tenta novamente.\nSe o erro persistir, contacta a PLOTU', true)
        setDownloadinGallery(false)
      })
  }

  return [
    downloadingProgress,
    downloadinGallery,
    downloadingSnackbarOpen,
    setDownloadingSnackbarOpen,
    handleGalleryArchive,
  ]
}
