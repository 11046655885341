import { useContext } from 'react'
import { GlobalConfirmationModalContext } from 'services/GlobalConfirmationModalProvider'

export const useGlobalConfirmationModal = () => {
  const { showModal, closeModal } = useContext(GlobalConfirmationModalContext)

  const handleOpenModal = modalContext => {
    return showModal(modalContext || {})
  }

  return [handleOpenModal, closeModal]
}
