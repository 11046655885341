import BookmarkIcon from '@mui/icons-material/Bookmark'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'
import PhotoIcon from '@mui/icons-material/Photo'
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import { Checkbox } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

import { HelpTooltip } from 'components/HelpTooltip'
import { Image } from 'components/Image'
import { LoadingComponentInfo } from 'components/LoadingComponent'
import { MenuWrapper } from 'components/MenuWrapper'
import { Text } from 'components/Text'

import { getLocalStorageBoolean } from 'utils/localStorage'

import './styles.scss'

const WATERMARK_INITIATED_TIME_THRESHOLD = 30000 // 30 seconds in milliseconds

const checkIfWatermarkNeedsToBeCreated = (galleryWatermark, galleryPhoto) => {
  // first check if the whole gallery is applying watermarks but it's taking too much time
  const wholeGalleryWatermarkJobInitiatedMSAgo = Date.now() - galleryWatermark?.initiatedTimestamp
  const wholeGalleryWatermarkJobInitiatedTooLongAgo =
    wholeGalleryWatermarkJobInitiatedMSAgo >= WATERMARK_INITIATED_TIME_THRESHOLD

  // then check if a specific photo is applying watermark but it's taking too much time
  const photoWatermarkJobInitiatedMSAgo = Date.now() - galleryPhoto.watermark?.initiatedTimestamp
  const photoWatermarkJobInitiatedTooLongAgo = photoWatermarkJobInitiatedMSAgo >= WATERMARK_INITIATED_TIME_THRESHOLD

  const watermarkJobInitiatedTooLongAgo =
    wholeGalleryWatermarkJobInitiatedTooLongAgo || photoWatermarkJobInitiatedTooLongAgo
  const watermarkSrc = galleryPhoto.watermark?.src

  const watermarkJobAlreadyTriggered = galleryPhoto.applyingWatermark

  const watermarkHasError =
    galleryWatermark?.id &&
    galleryWatermark?.id === galleryPhoto.watermark?.watermarkId &&
    galleryPhoto.watermark?.error

  return (
    !watermarkJobAlreadyTriggered &&
    watermarkSrc === undefined &&
    watermarkJobInitiatedTooLongAgo &&
    !watermarkHasError // if watermark has an error, let's show it instead
  )
}

const GalleryImageMark = ({ type, shifted }) => {
  return (
    <div>
      <div className={classNames('event-gallery-image__mark', {
        'event-gallery-image__mark--shifted': shifted,
      })}
      >
        <Tooltip title={type === 'marked' ? 'Foto marcada' : 'Foto de capa'} enterDelay={500} leaveDelay={200}>
          {type === 'marked'
            ? (
              <BookmarkIcon fontSize='medium' />
            )
            : (
              <PhotoIcon fontSize='medium' />
            )}
        </Tooltip>
      </div>
    </div>
  )
}

export const GalleryImage = ({
  galleryPhotoKey,
  isMarked,
  isCoverPhoto,
  getImageOptions,
  galleryPhoto,
  disabled,
  uploadingState,
  isSelected,
  isDraggingSelection,
  onSelectPhoto,
  galleryWatermark,
  triggerAddWatermarkToGalleryPhotoJob,
  triggerListenForWatermarkChanges,
  index,
  rectAngles,
}) => {
  const galleryImageRef = useRef()
  const hasTriggeredWatermarkJob = useRef(false)
  const hasTriggeredWatermarkListener = useRef(false)
  const showGalleryIndexes = useRef(false)

  const onCheckboxChange = event => {
    event.stopPropagation()
    event.preventDefault()
    onSelectPhoto(true)
  }

  const onGalleryImageClick = event => {
    onSelectPhoto(event.metaKey || event.ctrlKey)
  }

  const onNameClick = event => {
    event.stopPropagation()
    event.preventDefault()
  }

  const galleryWatermarkId = galleryWatermark?.id
  // if photo has its watermark created and the gallery has a watermark configured, we should use it instead of the original image
  const watermarkSrc = galleryWatermarkId && galleryWatermarkId === galleryPhoto.watermark?.watermarkId
    ? galleryPhoto.watermark?.src
      ? galleryPhoto.watermark?.src + `&watermark-id=${galleryWatermarkId}` // used to disable caching
      : null
    : null
  const photoSrc = watermarkSrc || galleryPhoto.src

  const watermarkHasError =
    galleryWatermarkId && galleryWatermarkId === galleryPhoto.watermark?.watermarkId && galleryPhoto.watermark?.error

  // If the gallery have a configured watermark but the image has not any watermark data, and it's not in the process of creating one,
  // we must trigger the watermark creation job
  useEffect(() => {
    if (!hasTriggeredWatermarkJob.current && !galleryPhoto.uploading) {
      const watermarkNeedsToBeCreated =
        checkIfWatermarkNeedsToBeCreated(galleryWatermark, galleryPhoto)
      if (watermarkNeedsToBeCreated) {
        hasTriggeredWatermarkJob.current = true
        triggerAddWatermarkToGalleryPhotoJob()
      }
    }
  }, [galleryPhoto, galleryWatermark, triggerAddWatermarkToGalleryPhotoJob])

  // If the photo is creating a watermark in the server we don't have the state locally (user might have refreshed the page)
  // we must trigger the watermark listener to update the watermark when its ready
  useEffect(() => {
    if (!hasTriggeredWatermarkListener.current) {
      const watermarkIsBeingCreatedRemotelly = !galleryPhoto.applyingWatermark && galleryPhoto.watermark?.isLoading
      if (watermarkIsBeingCreatedRemotelly) {
        hasTriggeredWatermarkListener.current = true
        triggerListenForWatermarkChanges()
      }
    }
  }, [galleryPhoto, triggerListenForWatermarkChanges])

  useEffect(() => {
    showGalleryIndexes.current = getLocalStorageBoolean('showGalleryIndexes') ?? false
  }, [])

  const applyingWatermark = galleryPhoto.applyingWatermark || galleryPhoto.watermark?.isLoading
  const galleryUploading = galleryPhoto.uploading || applyingWatermark
  const circularLoading = galleryPhoto.uploading && !applyingWatermark
  const linearLoading = !galleryPhoto.uploading && applyingWatermark
  const watermarkProgress = galleryPhoto.watermark?.progress
  const shouldBlurImage = applyingWatermark

  return (
    <div
      ref={galleryImageRef}
      className={
        classNames('event-gallery-image', {
          'event-gallery-image--selected': isSelected,
          'event-gallery-image--non-selectable': isDraggingSelection,
          'event-gallery-image--rect-angles': rectAngles,
        })
      }
      data-photo-key={galleryPhotoKey}
      onClick={onGalleryImageClick}
    >
      {isMarked && (
        <GalleryImageMark type='marked' />
      )}
      {isCoverPhoto && (
        <GalleryImageMark type='coverPhoto' shifted={isMarked} />
      )}
      <MenuWrapper
        editMode={!disabled && !galleryUploading}
        options={getImageOptions(galleryPhotoKey)}
      >
        <Image
          className={classNames('event-gallery-image__image', {
            'event-gallery-image__image--blurred': shouldBlurImage,
            'event-gallery-image__image--rect-angles': rectAngles,
          })}
          src={photoSrc}
          width={galleryPhoto.width}
          height={galleryPhoto.height}
          thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
          lazyLoaded
          generateThumbnailIfError
          draggable='false'
        />
        {uploadingState.hasError && (
          <div className='event-gallery-image__error-tooltip'>
            <HelpTooltip iconId='error' fontSize={24} color='error'>
              <React.Fragment>
                <h2 className='event-gallery-image__error-tooltip__title'>Houve um erro a enviar esta foto!</h2>
                <p>Após as restantes fotos serem enviadas, recarrega a página e tenta novamente</p>
              </React.Fragment>
            </HelpTooltip>
          </div>
        )}
        {watermarkHasError && (
          <div className='event-gallery-image__error-tooltip'>
            <HelpTooltip iconId='error' fontSize={24} color='error'>
              <React.Fragment>
                <h2 className='event-gallery-image__error-tooltip__title'>Houve um erro a aplicar a marca de água a esta foto!</h2>
                <p onClick={triggerAddWatermarkToGalleryPhotoJob}>Clica aqui para tentar novamente</p>
              </React.Fragment>
            </HelpTooltip>
          </div>
        )}
      </MenuWrapper>
      {!disabled && !galleryUploading && (
        <div
          className={classNames('event-gallery-image__checkbox', {
            'event-gallery-image__checkbox--visible': isSelected,
          })}
        >
          <Checkbox
            checked={!!isSelected}
            onChange={onCheckboxChange}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
          />
        </div>
      )}
      <div
        className={classNames('event-gallery-image__bottom-shade-wrapper', {
          'event-gallery-image__bottom-shade-wrapper--rect-angles': rectAngles,
        })}
      >
        <div className='event-gallery-image__bottom-shade'>
          {!galleryUploading && !watermarkHasError && !uploadingState.hasError && (
            <div className='event-gallery-image__name' onClick={onNameClick}>
              <Text type='body2' color='text__white-color' lineLimit={1}>
                {showGalleryIndexes.current === true ? `${index} | ${galleryPhoto.name}` : galleryPhoto.name || ''}
              </Text>
            </div>
          )}
          {!uploadingState.hasError && !watermarkHasError && (
            <LoadingComponentInfo
              linear={linearLoading}
              wrapperClassName={classNames({
                'event-gallery-image__loading': circularLoading,
                'event-gallery-image__linear-loading': linearLoading,
              })}
              size={30}
              isLoading={galleryUploading}
              value={uploadingState.progress}
              label={linearLoading ? `A aplicar marca de água: ${watermarkProgress || 0}%` : ''}
            />
          )}
        </div>
      </div>
    </div>
  )
}
