import EditIcon from '@mui/icons-material/Edit'
import Checkbox from '@mui/material/Checkbox'
import Fab from '@mui/material/Fab'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import devtoolsConfig from './devtoolsConfig.json'
import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { Modal } from 'components/Modal'
import { Select } from 'components/Select'

import { loginUser, logoutUser } from 'services/authenticationService'

import { isAndroid, isAndroidMobile, isIOSWebApp, isStandaloneWebApp } from 'utils/device'
import { getLocalStorageBoolean } from 'utils/localStorage'

import './styles.scss'

const routes = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Search',
    url: '/search',
  },
  {
    name: 'Register Journey',
    url: '/register-journey',
  },
  {
    name: 'User Details',
    url: '/user-details/klc102oFTUggLJ2uz6rxW32Hi1H2',
  },
  {
    name: 'Pack Details',
    url: '/pack-details/packId1',
  },
  {
    name: 'Gallery',
    url: '/gallery/eiqwdcbfgfda23sdyuhdjnsa_default',
  },
  {
    name: 'Back Office',
    url: '/back-office',
  },
  {
    name: 'Access (Sign In & Sign Up)',
    url: '/access/sign-in',
  },
]

const devRoutes = [
  {
    name: 'Static Pages Builder',
    url: '/static-pages-builder',
  },
]

const getDevice = () => {
  let deviceType = 'unkown'

  if (isIOSWebApp()) {
    deviceType = 'IOS'
  }

  if (isAndroid()) {
    deviceType = 'Android'
  }

  if (isAndroidMobile()) {
    deviceType = 'Android Mobile'
  }

  if (isStandaloneWebApp()) {
    deviceType += ' standalone'
  }

  return deviceType
}

const renderRoutes = (routesConfig = routes) => {
  return (
    <ul className='devtools__routes'>
      {routesConfig.map(route => (
        <li key={route.name} className='devtools__routes__item'>
          <Link underlined href={route.url}>{route.name}</Link>
        </li>
      ))}
    </ul>
  )
}

const DevToolsUI = props => {
  const [showUndefinedI18nAsIds, setShowUndefinedI18nAsIds] = useState(getLocalStorageBoolean('showUndefinedI18nAsIds') || false)
  const [showAllI18nAsIds, setShowAllI18nAsIds] = useState(getLocalStorageBoolean('showAllI18nAsIds') || false)
  const [showBreakpoints, setShowBreakpoints] = useState(getLocalStorageBoolean('showBreakpoints') || false)
  const [showGalleryIndexes, setShowGalleryIndexes] = useState(getLocalStorageBoolean('showGalleryIndexes') || false)
  const [menuValue, setMenuValue] = useState(undefined)
  const logedInUser = useSelector(state => state.users.currentUser && state.users.users[state.users.currentUser.uid])
  const [value, setValue] = useState('customer')
  const [userUID, setUserUID] = useState()
  const [environment, setEnvironment] = useState('development')
  const [inputType, setInputType] = useState('uid')
  const [userQuery, setUserQuery] = useState('')
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [userSearchComparator, setUserSearchComparator] = useState('==')
  const [userSearchValue, setUserSearchValue] = useState('')
  const [userSearchMap, setUserSearchMap] = useState('')
  const [imageName, setImageName] = useState()
  const [thumbnailsFunctionUrl, setThumbnailsFunctionUrl] = useState()
  const history = useHistory()

  const handleShowUndefinedI18nAsIdsChange = () => {
    const newValue = !showUndefinedI18nAsIds
    localStorage.setItem('showUndefinedI18nAsIds', newValue)
    setShowUndefinedI18nAsIds(newValue)
  }

  const handleShowAllI18nAsIdsChange = () => {
    const newValue = !showAllI18nAsIds
    localStorage.setItem('showAllI18nAsIds', newValue)
    setShowAllI18nAsIds(newValue)
  }

  const handleShowBreakpointsChange = () => {
    const newValue = !showBreakpoints
    localStorage.setItem('showBreakpoints', newValue)
    setShowBreakpoints(newValue)
  }

  const handleShowGalleryIndexes = () => {
    const newValue = !showGalleryIndexes
    localStorage.setItem('showGalleryIndexes', newValue)
    setShowGalleryIndexes(newValue)
  }

  const handleChange = event => {
    setValue(event.target.value)
    setMenuValue(undefined)
  }

  const handleMenuChange = event => setMenuValue(event?.target.value)

  const handleUserUIDChange = event => {
    setUserUID(event?.target.value)
  }

  const handleUserQueryChange = event => {
    setUserQuery(event?.target.value)
  }

  const handleUserSearchQueryChange = event => {
    setUserSearchQuery(event?.target.value)
  }

  const handleUserSearchComparatorChange = event => {
    const newValue = event?.target.value
    setUserSearchComparator(newValue)
  }

  const handleUserSearchValueChange = event => {
    setUserSearchValue(event?.target.value)
  }

  const handleUserSearchMapChange = event => {
    setUserSearchMap(event?.target.value)
  }

  const calculateStorage = event => {
    const BASE_URL = environment === 'production'
      ? 'https://us-central1-plotu-prod.cloudfunctions.net'
      : 'https://us-central1-lenz-dev.cloudfunctions.net'
    const newCheckoutUrl = new URL(BASE_URL)
    newCheckoutUrl.pathname = 'calculateUserStorage'
    newCheckoutUrl.searchParams.append('uid', userUID)

    window.open(newCheckoutUrl.toString(), '_blank').focus()

    event.stopPropagation()
    event.preventDefault()
  }

  const handleEnvironmentChange = event => {
    setEnvironment(event.target.value)
  }

  const handleInputTypeChange = event => {
    setInputType(event.target.value)
  }

  const handleImageNameChange = event => {
    setImageName(event?.target.value)
  }

  const generateThumbnailsFunctionUrl = event => {
    const triggerUrl = environment === 'production'
      ? 'https://us-central1-plotu-prod.cloudfunctions.net/generateThumbsForUrl'
      : 'https://us-central1-lenz-dev.cloudfunctions.net/generateThumbsForUrl'
    const bucket = environment === 'production'
      ? 'plotu-prod.appspot.com'
      : 'lenz-dev.appspot.com'

    setThumbnailsFunctionUrl(`${triggerUrl}?bucket=${bucket}&name=${imageName}`)

    event.stopPropagation()
    event.preventDefault()
  }

  const getUser = event => {
    const triggerUrl = environment === 'production'
      ? 'https://us-central1-plotu-prod.cloudfunctions.net/getUser'
      : 'https://us-central1-lenz-dev.cloudfunctions.net/getUser'

    window.open(`${triggerUrl}?${inputType}=${userQuery}`, '_blank').focus()

    event.stopPropagation()
    event.preventDefault()
  }

  const searchUsers = event => {
    const BASE_URL = environment === 'production'
      ? 'https://us-central1-plotu-prod.cloudfunctions.net'
      : 'https://us-central1-lenz-dev.cloudfunctions.net'
    const newCheckoutUrl = new URL(BASE_URL)
    newCheckoutUrl.pathname = 'getUsers'
    newCheckoutUrl.searchParams.append('q', userSearchQuery)
    newCheckoutUrl.searchParams.append('c', userSearchComparator)
    newCheckoutUrl.searchParams.append('v', typeof userSearchValue === 'string' || userSearchValue instanceof String ? `"${userSearchValue}"` : userSearchValue)
    newCheckoutUrl.searchParams.append('map', userSearchMap)

    window.open(newCheckoutUrl.toString(), '_blank').focus()

    event.stopPropagation()
    event.preventDefault()
  }

  const getExcedingQuotaUsers = event => {
    const BASE_URL = environment === 'production'
      ? 'https://us-central1-plotu-prod.cloudfunctions.net'
      : 'https://us-central1-lenz-dev.cloudfunctions.net'
    const newCheckoutUrl = new URL(BASE_URL)
    newCheckoutUrl.pathname = 'getAllUsersExceedingQuota'

    window.open(newCheckoutUrl.toString(), '_blank').focus()

    event.stopPropagation()
    event.preventDefault()
  }

  const signout = () => {
    logedInUser && logoutUser()
    props.closeModal()
  }

  const onBtnClick = () => {
    const menuItem = devtoolsConfig
      .find(option => option.value === value)?.menuItems
      ?.find(menuItem => menuItem.value === menuValue)

    const email = menuItem?.email
    const pwdId = menuItem?.pwdId
    const pwd = process.env[pwdId]

    loginUser(email, pwd, true, history)
    props.closeModal()
  }

  const selectedItem = devtoolsConfig.find(option => option.value === value)

  return (
    <article className='devtools__ui'>
      <h2>Dashboard</h2>
      {`Device Type: ${getDevice()}`}
      <nav>
        <h2>Routes</h2>
        {renderRoutes(routes)}
        <h2>Dev Routes</h2>
        {renderRoutes(devRoutes)}
      </nav>
      <h2>Config</h2>
      {/* Move this p to a tooltip */}
      <p>{'If true, this will show the i18n ID\'s of all the requested keys that were not defined. Otherwise, the i18n service will return undefined'}</p>
      <FormControlLabel
        control={<Checkbox checked={showUndefinedI18nAsIds === true} onChange={handleShowUndefinedI18nAsIdsChange} name='gilad' />}
        label="Show not defined translations as ID's"
      />
      <FormControlLabel
        control={<Checkbox checked={showAllI18nAsIds === true} onChange={handleShowAllI18nAsIdsChange} name='gilad' />}
        label="Show all translations as ID's"
      />
      <FormControlLabel
        control={<Checkbox checked={showBreakpoints === true} onChange={handleShowBreakpointsChange} name='show-breakpoints' />}
        label='Show breakpoints'
      />
      <FormControlLabel
        control={<Checkbox checked={showGalleryIndexes === true} onChange={handleShowGalleryIndexes} name='show-galleries-indexes' />}
        label='Show indexes on galleries'
      />
      {
        logedInUser && (
          <div className='devtools__ui__signed-in-with'>
            <h3>Signed in with</h3>
            <p>{`${logedInUser.name} (${logedInUser.type})`}</p>
          </div>
        )
      }
      {logedInUser && (
        <Button
          onClick={signout}
          className='devtools__ui__sign-out-btn'
          variant='contained'
          color='primary'
        >
          Sign out
        </Button>
      )}
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Log in with</FormLabel>
        <RadioGroup aria-label='gender' name='gender1' value={value} onChange={handleChange}>
          {devtoolsConfig.map(option => (
            <FormControlLabel
              key={option.label}
              value={option.value}
              control={<Radio color='primary' />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl className='devtools__ui__menu-items'>
        {selectedItem.menuItems && selectedItem.menuItems.length > 0 && (
          <Select
            label={selectedItem.label}
            labelId='demo-simple-select-label'
            value={menuValue}
            onChange={handleMenuChange}
            menuItems={selectedItem.menuItems}
          />
        )}
      </FormControl>
      {menuValue && (
        <Button
          onClick={onBtnClick}
          className='devtools__ui__sign-in-btn'
          variant='contained'
          color='primary'
        >
          Sign In
        </Button>
      )}
      <h2>Server Functions</h2>
      <p>Environment</p>
      <RadioGroup
        value={environment}
        onChange={handleEnvironmentChange}
      >
        <FormControlLabel
          value='development'
          control={<Radio color='primary' />}
          label='Development (lenz-dev)'
        />
        <FormControlLabel
          value='production'
          control={<Radio color='primary' />}
          label='Production (plotu-prod)'
        />
      </RadioGroup>
      {/* *** USER GETTER FUNCTION *** */}
      <h3>Get User</h3>
      <form
        className='devtools__storage-calculator-form'
        onSubmit={getUser}
      >
        <p>Tipo de input</p>
        <RadioGroup
          value={inputType}
          onChange={handleInputTypeChange}
        >
          <FormControlLabel
            value='uid'
            control={<Radio color='primary' />}
            label='UID'
          />
          <FormControlLabel
            value='name'
            control={<Radio color='primary' />}
            label='Nome'
          />
          <FormControlLabel
            value='username'
            control={<Radio color='primary' />}
            label='Username'
          />
          <FormControlLabel
            value='email'
            control={<Radio color='primary' />}
            label='Email'
          />
        </RadioGroup>
        <input
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          placeholder='Insert user search query'
          value={userQuery}
          onChange={handleUserQueryChange}
          type='search'
          aria-label='search'
        />
        <Button
          type='submit'
          onClick={getUser}
          className='devtools__ui__sign-out-btn devtools__form__button'
          variant='contained'
          color='primary'
        >
          Get user
        </Button>
      </form>
      <h3>Search Users</h3>
      <form
        className='devtools__search-users-form'
        href={searchUsers}
      >
        <input
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          placeholder='User property to search. Ex: "username" or "plan.id" or "storageSize.total"'
          value={userSearchQuery}
          onChange={handleUserSearchQueryChange}
          type='search'
          aria-label='search'
        />
        <select onChange={handleUserSearchComparatorChange} value={userSearchComparator} name='cars' id='cars'>
          <option value='<'>{'Less <'}</option>
          <option value='<='>{'Less or Equal (<=)'}</option>
          <option value='=='>Equal (==)</option>
          <option value='>'>{'Bigger (>)'}</option>
          <option value='>='>{'Bigger or Equal (>=)'}</option>
          <option value='!='>Different (!=)</option>
          <option value='array-contains'>Array contains (array-contains)</option>
          <option value='array-contains-any'>Array contains any (array-contains-any)</option>
          <option value='in'>In (in)</option>
          <option value='not in'>Not In (not in)</option>
        </select>
        <input
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          placeholder='Value to match search'
          value={userSearchValue}
          onChange={handleUserSearchValueChange}
          type='search'
          aria-label='search'
        />
        <input
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          placeholder='Properties to be mapped in results. Ex: "username;email;plan;storageSize"'
          value={userSearchMap}
          onChange={handleUserSearchMapChange}
          type='search'
          aria-label='search'
        />
        <Button
          type='submit'
          onClick={searchUsers}
          className='devtools__search-users-form__button'
          variant='contained'
          color='primary'
        >
          Search user
        </Button>
      </form>
      {/* *** STORAGE CALCULATOR *** */}
      <h3>Calculate User Storage</h3>
      <form
        className='devtools__storage-calculator-form'
        onSubmit={calculateStorage}
      >
        <input
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          placeholder='Insert user ID'
          value={userUID}
          onChange={handleUserUIDChange}
          type='search'
          aria-label='search'
        />
        <Button
          type='submit'
          onClick={calculateStorage}
          className='devtools__ui__sign-out-btn devtools__form__button'
          variant='contained'
          color='primary'
        >
          Calculate
        </Button>
      </form>
      <h3>Get all Users exceding quota</h3>
      <form
        className='devtools__search-users-form'
        href={getExcedingQuotaUsers}
      >
        <Button
          type='submit'
          onClick={getExcedingQuotaUsers}
          className='devtools__search-users-form__button'
          variant='contained'
          color='primary'
        >
          Get users
        </Button>
      </form>

      {/* *** THUMBNAILS URL GENERATOR *** */}
      <h3>Generate URL to create thumbnails for uploaded image</h3>
      <form
        className='devtools__thumbnails-form'
        onSubmit={generateThumbnailsFunctionUrl}
      >
        <input
          placeholder='Insert image name (ex: users/example-image.jpg)'
          value={userUID}
          onChange={handleImageNameChange}
        />
        <Button
          type='submit'
          onClick={generateThumbnailsFunctionUrl}
          className='devtools__ui__sign-out-btn devtools__thumbnails-form__button'
          variant='contained'
          color='primary'
        >
          Generate url to create thumbnails
        </Button>
      </form>
      <a target='_blank' rel='noopener noreferrer' href={thumbnailsFunctionUrl}>{thumbnailsFunctionUrl}</a>
    </article>
  )
}

export const _DevTools = () => {
  const [devToolsIsOpen, setDevToolsIsOpen] = useState(false)

  const openDevTools = () => setDevToolsIsOpen(true)
  const closeDevTools = () => setDevToolsIsOpen(false)

  return (
    <Fragment>
      <Fab className='devtools__fab' onClick={openDevTools} color='primary' aria-label='add'>
        <EditIcon />
      </Fab>
      {(getLocalStorageBoolean('showBreakpoints') || devToolsIsOpen) && <div className='devtools__breakpoints' />}
      <Modal title="PLOTU's Development Tools" closeModal={closeDevTools} open={devToolsIsOpen}>
        <DevToolsUI closeModal={closeDevTools} />
      </Modal>
    </Fragment>
  )
}
