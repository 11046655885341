import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React from 'react'

import StaticPage from 'scenes/StaticPage'

import { Text } from 'components/Text'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import './styles.scss'

const FAQs = () => {
  const faqSections = getConfigValue('faqs.sections')

  return (
    <div className='faqs'>
      <Text as='h1' type='title3'>{t('faqs.title')}</Text>
      {Object.keys(faqSections)
        .map(sectionKey => {
          const section = faqSections[sectionKey]
          return (
            <div key={section.id} className='faqs__section'>
              <Text
                as='h2'
                type='title4'
                color='text__primary-color'
                className='faqs__section__title'
              >
                {t(`faqs.sections.${sectionKey}.title`)}
              </Text>
              {section.faqs.map(faq => {
                return (
                  <Accordion key={faq.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Text bold>
                        {t(`faqs.sections.${sectionKey}.${faq.id}.question`)}
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      {faq.static_page_id
                        ? <StaticPage pageId={faq.static_page_id} />
                        : (
                          <Text className='faqs__faq__answer'>
                            {t(`faqs.sections.${sectionKey}.${faq.id}.answer`)}
                          </Text>
                        )}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </div>
          )
        })}
    </div>
  )
}

export default FAQs
