import DownloadIcon from '@mui/icons-material/Download'
import React from 'react'

import { IconButton } from 'components/Button'
import { LoadingComponent } from 'components/LoadingComponent'

import './styles.scss'

export const DownloadingIcon = ({ onClick, isDownloading }) => {
  return (
    <div className='downloading-icon'>
      <IconButton
        size='small'
        aria-label='download photo'
        onClick={onClick}
      >
        <DownloadIcon fontSize='small' />
      </IconButton>
      <LoadingComponent
        isLoading={isDownloading}
        className='downloading-icon__loading'
        size={30}
      />
    </div>
  )
}
