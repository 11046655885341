import React from 'react'

import { LinkButton } from 'components/Button'

import { t } from 'services/i18n'
import { createAccessLink, subRoutesNames } from 'services/routingService'

import './styles.scss'

export const JoinUs = () => {
  return (
    <div className='professionals-join-us'>
      <h2 className='professionals-join-us__title'>
        {t('professionals.join_us.title')}
      </h2>
      <LinkButton href={createAccessLink(subRoutesNames.ACCESS.SIGN_UP)} variant='contained' color='primary' size='large'>
        {t('professionals.join_us.action_button')}
      </LinkButton>
    </div>
  )
}
