import { getDatabase } from '.'
import moment from 'moment'
import { collection, doc, query, where, limit, orderBy, getDocs, setDoc, deleteDoc, deleteField, updateDoc, getDoc } from 'firebase/firestore'

import { logError } from 'utils/errorCapture'

const getUserEventsRef = userUid => collection(getDatabase(), 'agendas', userUid, 'events')
const getUserEventRef = (userUid, eventId) => doc(getDatabase(), 'agendas', userUid, 'events', eventId)

export const getUserEvents = (userUid, options) => {
  const constraints = []

  if (options?.startDateUnix) {
    constraints.push(where('startDate', '>=', options.startDateUnix))
  }

  if (options?.endDateUnix) {
    constraints.push(where('startDate', '<=', options.endDateUnix))
  }

  if (options?.limit) {
    constraints.push(limit(options.limit))
  }

  if (options?.orderBy) {
    constraints.push(orderBy(options.orderBy, options.orderByDir))
  }

  const q = query(
    getUserEventsRef(userUid),
    ...constraints
  )

  console.info(`Fetching agenda from user ${userUid}. ${options?.startDateUnix ? 'startDate: ' + moment.unix(options?.startDateUnix).format('DD/MM/YYYY') + ' | ' : ''}${options?.endDateUnix ? 'endDate: ' + moment.unix(options?.endDateUnix).format('DD/MM/YYYY') : ''}`)
  return getDocs(q)
    .then(eventsSnapshot => {
      console.info('Fetched events from user:', userUid)

      const events = eventsSnapshot.docs
        ?.reduce((prev, currDoc) => {
          const event = currDoc.data()
          prev[event.id] = event
          return prev
        }, {}) || {}

      return events
    })
    .catch(error => {
      logError(error, `Error fetching events from user ${userUid}: ${error}`)
      throw error
    })
}

export const getUserEvent = (userUid, eventId) => {
  const docRef = getUserEventRef(userUid, eventId)

  return getDoc(docRef)
    .then(event => {
      console.info('Fetched event with id', eventId, 'from user:', userUid)
      if (event.exists()) {
        return event.data()
      } else {
        console.log('No such event!')
        throw Error(`Event with id ${eventId} not found from user ${userUid}`)
      }
    })
    .catch(error => {
      logError(error, `Error fetching event with id ${eventId} from user ${userUid}: ${error}`)
      throw error
    })
}

export const postAgendaEvent = (userUid, event) => setAgendaEvent(userUid, event, false)

export const putAgendaEvent = (userUid, event) => setAgendaEvent(userUid, event, true)

export const setAgendaEvent = (userUid, event, merge = false) => {
  const docRef = getUserEventRef(userUid, event.id)

  return setDoc(docRef, { ...event }, { merge })
    .then(() => {
      console.log(`Event ${event.uid} successfully written!`)
    })
    .catch(error => {
      logError(error, 'Error creating event: ' + error)
      throw error
    })
}

export const deleteAgendaEvent = (userUid, eventId) => {
  const docRef = getUserEventRef(userUid, eventId)

  return deleteDoc(docRef)
    .then(() => {
      console.log(`Event ${eventId} successfully deleted!`)
    })
    .catch(error => {
      logError(error, `Error deleting event ${eventId}: ${error}`)
      throw error
    })
}

export const getPutAgendaEventGalleryBatchData = (userUid, eventId, galleryId, remove) => {
  const eventData = {
    id: eventId,
  }

  if (remove) {
    eventData[`galleries.${galleryId}`] = deleteField()
  } else {
    eventData[`galleries.${galleryId}.id`] = galleryId
  }

  const eventRef = getUserEventRef(userUid, eventId)

  return { eventRef, eventData }
}

export const putAgendaEventGalleryData = (userUid, eventId, gallery) => {
  const { eventRef, eventData } = getPutAgendaEventGalleryBatchData(userUid, eventId, gallery.id)

  return updateDoc(eventRef, { ...eventData })
    .then(() => {
      console.log(`Gallery successfully updated in event ${eventId}!`)
    })
    .catch(error => {
      logError(error, `Error updating gallery in event ${eventId}: ${error}`)
      throw error
    })
}
