import { SORT_QUERY_CACHE, ADD_QUERY_CACHE, REMOVE_QUERY_CACHE, START_SEARCH_QUERY } from './actions'

import { now } from 'utils/date'
import { getQueryResultIndex } from 'utils/search'

const CACHED_QUERIES_MAX_LENGTH = 50

const initialState = {
  loading: false,
  searchQueries: [],
}

const removeFromQueries = (state, query, searchGroup, pageIndex) => {
  const searchQueriesCopy = [...state.searchQueries]
  const toRemoveIndex = getQueryResultIndex(searchQueriesCopy, query, searchGroup, pageIndex)
  searchQueriesCopy.splice(toRemoveIndex, 1)
  return searchQueriesCopy
}

const addToSearchQueries = (state, query, searchGroup, hits, metadata) => {
  return [
    {
      query,
      searchGroup,
      time: now(),
      hits,
      metadata,
    },
    ...state.searchQueries,
  ]
}

const users = (state = initialState, action) => {
  let newSearchQueries = state.searchQueries
  switch (action.type) {
    case START_SEARCH_QUERY:
      return { ...state, loading: true }
    case SORT_QUERY_CACHE: {
      // if searched query already exists, add it to the beginning
      const cachedSearchResultIndex = state.searchQueries
        .findIndex(
          searchQuery =>
            searchQuery.query === action.data.query &&
            searchQuery.searchGroup === action.data.searchGroup &&
            searchQuery.pageIndex === action.data.pageIndex
        )
      if (cachedSearchResultIndex > -1) {
        const hitsCopy = state.searchQueries[cachedSearchResultIndex].hits
        const metadataCopy = state.searchQueries[cachedSearchResultIndex].metadata
        removeFromQueries(state, action.data.query, action.data.searchGroup, action.data.pageIndex)
        newSearchQueries = addToSearchQueries(state, action.data.query, action.data.searchGroup, hitsCopy, metadataCopy)
      }

      return {
        ...state,
        searchQueries: newSearchQueries,
      }
    }
    case ADD_QUERY_CACHE:
      newSearchQueries = addToSearchQueries(
        state,
        action.data.query,
        action.data.searchGroup,
        action.data.hits,
        action.data.metadata
      )

      if (newSearchQueries.length > CACHED_QUERIES_MAX_LENGTH) {
        const deleteCount = newSearchQueries.length - CACHED_QUERIES_MAX_LENGTH
        newSearchQueries.splice(-1, deleteCount)
      }

      return {
        ...state,
        loading: false,
        searchQueries: newSearchQueries,
      }
    case REMOVE_QUERY_CACHE:
      return {
        ...state,
        searchQueries: removeFromQueries(state, action.data.query, action.data.searchGroup, action.data.pageIndex),
      }
    default:
      return state
  }
}

export default users
