import TextField from '@mui/material/TextField'
import moment from 'moment'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from 'components/Button'
import { LoadingComponent } from 'components/LoadingComponent'
import { Text } from 'components/Text'

import { Rating } from '../Rating'

import { Review } from './components/Review'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'

import './styles.scss'

const notificationSystem = getNotificationSystem()

export const ReviewSection = ({
  reviews: propsReviews,
  canAddReview,
  isAdmin,
  currentPack,
  currentUser,
  postReview,
  deleteReview,
  loadingReviews,
}) => {
  const [reviews, setReviews] = useState(propsReviews)
  const [newReview, setNewReview] = useState()
  const [rating, setRating] = useState()

  const createNewReview = () => {
    const newReviewId = uuidv4()
    const newReviewData = {
      id: newReviewId,
      rating,
      review: newReview,
      createdTimestamp: moment().unix(),
      userUid: currentUser.uid,
      user: currentUser,
      uploadStatus: UPLOAD_STATUS.UPLOADING,
    }
    const newReviews = {
      ...reviews,
      [newReviewId]: newReviewData,
    }
    setReviews(newReviews)
    sendReview(newReviewData, newReviews)
  }

  const editReview = (contextReview, editedReview) => {
    const newReviewData = {
      ...contextReview,
      isEdited: true,
      editedTimestamp: moment().unix(),
      ...editedReview,
      uploadStatus: UPLOAD_STATUS.UPLOADING,
    }

    const newReviews = {
      ...reviews,
      [contextReview.id]: newReviewData,
    }
    setReviews(newReviews)
    sendReview(newReviewData, newReviews)
  }

  const sendReview = (newReview, newReviews) => {
    postReview(newReview)
      .then(() => {
        const uploadedReview = {
          ...newReview,
          uploadStatus: UPLOAD_STATUS.UPLOADED,
        }

        const newReviewsCopy = {
          ...newReviews,
          [newReview.id]: uploadedReview,
        }
        setReviews(newReviewsCopy)
      })
  }

  const onReviewReply = (contextReview, reviewReply) => {
    const newReviewData = {
      ...contextReview,
      reviewReply: {
        createdTimestamp: moment().unix(),
        review: reviewReply,
        uploadStatus: UPLOAD_STATUS.UPLOADING,
      },
    }

    const newReviews = {
      ...reviews,
      [contextReview.id]: newReviewData,
    }
    setReviews(newReviews)
    sendReviewReply(newReviewData, newReviews)
  }

  const onReviewReplyEdit = (contextReview, editedReview) => {
    const newReviewData = {
      ...contextReview,
      reviewReply: {
        ...contextReview.reviewReply,
        isEdited: true,
        editedTimestamp: moment().unix(),
        review: editedReview,
        uploadStatus: UPLOAD_STATUS.UPLOADING,
      },
    }

    const newReviews = {
      ...reviews,
      [contextReview.id]: newReviewData,
    }
    setReviews(newReviews)
    sendReviewReply(newReviewData, newReviews)
  }

  const sendReviewReply = (newReview, newReviews) => {
    postReview(newReview)
      .then(() => {
        const uploadedReview = {
          ...newReview,
          reviewReply: {
            ...newReview.reviewReply,
            uploadStatus: UPLOAD_STATUS.UPLOADED,
          },
        }

        const newReviewsCopy = {
          ...newReviews,
          [newReview.id]: uploadedReview,
        }
        setReviews(newReviewsCopy)
      })
  }

  const onRemoveReview = reviewKey => () => {
    const reviewsCopy = { ...reviews }
    delete reviewsCopy[reviewKey]
    setReviews(reviewsCopy)

    deleteReview(reviewKey)
      .then(() => notificationSystem.notify('Review removida com sucesso'))
      .catch(() => notificationSystem.notify('Houve um erro ao remover a review. Por favor tenta novamente'))
  }

  const onRemoveReviewReply = reviewKey => () => {
    const reviewsCopy = { ...reviews }
    reviewsCopy[reviewKey].reviewReply = null
    setReviews(reviewsCopy)

    sendReview(reviewsCopy[reviewKey], reviewsCopy)
  }

  if (loadingReviews) {
    return <LoadingComponent isLoading={loadingReviews} size={40} />
  }

  return (
    <div className='review-section'>
      {reviews && Object.keys(reviews)
      .sort((reviewKey1, reviewKey2) => reviews[reviewKey2]?.createdTimestamp - reviews[reviewKey1]?.createdTimestamp)
      .map(reviewKey => {
        const review = reviews[reviewKey]
        return (
          <Review
            className='review-section__review'
            key={`${review.user.name}-${review.createdTimestamp}`}
            isAdmin={isAdmin}
            isCommentOwner={review.user.uid === currentUser?.uid}
            review={review}
            reviewReply={review.reviewReply}
            currentUser={currentUser}
            currentPack={currentPack}
            onEditReview={editReview}
            onReviewReply={onReviewReply}
            onReviewReplyEdit={onReviewReplyEdit}
            onRemoveReview={onRemoveReview(reviewKey)}
            onRemoveReviewReply={onRemoveReviewReply(reviewKey)}
            uploadingStatus={review.uploadStatus}
            uploadingReplyStatus={review.reviewReply?.uploadStatus}
          />
        )
      })}
      {Object.keys(reviews || {}).length === 0 && <Text>{t('components.review_section.no_reviews')}</Text>}
      {canAddReview && <hr align='left' className='review-section__divider' />}
      {canAddReview && (
        <form className='review-section__form'>
          <Rating
            className='review-section__review-rating'
            name='new_review_rating'
            initialValue={rating}
            onChange={setRating}
            showTextValue
          />
          <TextField
            placeholder='Escreve a tua review'
            className='review-section__review-input'
            multiline
            id='review-input'
            variant='outlined'
            value={newReview}
            onChange={evt => setNewReview(evt.target.value)}
          />
          <div className='review-section__review-submit'>
            <Button
              type='submit'
              variant='outlined'
              color='default'
              href='#message-box'
              onClick={createNewReview}
              disabled={!newReview || rating === undefined}
            >
              {t('components.review_section.send')}
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
