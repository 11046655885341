import {
  REQUEST_GALLERY,
  REQUEST_EXTRA_DATA,
  ADD_GALLERIES,
  ADD_GALLERY,
  ADD_GALLERY_USER,
  ADD_GALLERY_EVENT,
  UPDATE_GALLERY,
  REMOVE_GALLERY,
  FAILED_GALLERY,
  ADD_GALLERY_PHOTOS,
  ADD_GALLERY_PHOTO,
  ADD_GALLERY_COVER_PHOTO,
  REMOVE_GALLERY_PHOTOS,
  SET_GALLERY_PAGES_LOADED,
  SET_GALLERY_SHARING_OPTIONS,
  SET_GALLERY_MARKED_PHOTO_IDS,
} from './actions'

const initialState = {
  data: undefined,
  loading: undefined,
  error: undefined,
  fetchingExtraData: undefined,
}

const galleries = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GALLERY:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_EXTRA_DATA:
      return {
        ...state,
        fetchingExtraData: action.data,
      }
    case ADD_GALLERIES: {
      if (!action.data) {
        return {
          data: state.data,
          loading: false,
        }
      }

      // merge the galleries with the existence ones
      const newGalleries = {}
      Object.values(action.data)
        .forEach(gallery => {
          const currentGalleryState = state.data?.[gallery.id] || {}
          newGalleries[gallery.id] = {
            ...gallery,
            ...currentGalleryState,
          }
        })

      return {
        data: {
          ...state.data,
          ...newGalleries,
        },
        loading: false,
      }
    }
    case ADD_GALLERY:
      if (!action.data || !action.data.id) {
        return {
          data: state.data,
          loading: false,
        }
      }

      return {
        data: {
          ...state.data,
          [action.data.id]: {
            // we need to maintain the rest of the gallery already in the storage because when we
            // fetch a gallery using actions::fetchGallery, addGalleryAction might happen after the extra data
            // actions ran (not sure why because it's done before those actions). This means that addGalleryAction might clear the extra data
            ...(state.data?.[action.data.id] || {}),
            ...action.data,
          },
        },
        loading: false,
      }
    case ADD_GALLERY_USER:
      return {
        data: {
          ...state.data,
          [action.data.galleryId]: {
            ...state.data[action.data.galleryId],
            user: action.data.user,
          },
        },
      }
    case ADD_GALLERY_EVENT:
      return {
        data: {
          ...state.data,
          [action.data.galleryId]: {
            ...state.data[action.data.galleryId],
            event: action.data.event,
          },
        },
      }
    case UPDATE_GALLERY:
      return {
        data: {
          ...state.data,
          [action.data.galleryId]: {
            ...state.data[action.data.galleryId],
            ...action.data.data,
          },
        },
        loading: false,
      }
    case REMOVE_GALLERY: {
      const galleryToRemoveId = action.data.gallery.id
      const galleriesDataCopy = { ...state.data }

      delete galleriesDataCopy[galleryToRemoveId]

      return {
        ...state,
        data: galleriesDataCopy,
      }
    }
    case ADD_GALLERY_PHOTOS: {
      const galleryId = action.data.galleryId
      const updatePhotosLayout = action.data.updatePhotosLayout
      const photos = action.data.photos.reduce((prev, photo) => {
        prev[photo.id] = photo
        return prev
      }, { ...(state.data[galleryId]?.photos || {}) })

      const previousGalleryPhotoKeys = Object.keys(state.data[galleryId]?.photos || {})

      const photosLayout = [...(state.data[galleryId]?.photosLayout || previousGalleryPhotoKeys || [])]
      if (updatePhotosLayout) {
        const toAddPhotosLayout = action.data.photos.map(photo => photo.id)
        photosLayout.push(...toAddPhotosLayout)
      }

      return {
        ...state,
        data: {
          ...state.data,
          [galleryId]: {
            ...state.data[galleryId],
            photosLayout,
            photos,
          },
        },
      }
    }
    case ADD_GALLERY_PHOTO: {
      const galleryToAddPhotoId = action.data.galleryId
      return {
        ...state,
        data: {
          ...state.data,
          [galleryToAddPhotoId]: {
            ...state.data[galleryToAddPhotoId],
            photos: {
              ...state.data[galleryToAddPhotoId].photos,
              [action.data.photo.id]: {
                ...(state.data[galleryToAddPhotoId].photos?.[action.data.photo.id] || {}),
                ...action.data.photo,
              },
            },
          },
        },
      }
    }
    case ADD_GALLERY_COVER_PHOTO: {
      const galleryId = action.data.galleryId
      const coverPhoto = action.data.coverPhoto
      return {
        ...state,
        data: {
          ...state.data,
          [galleryId]: {
            ...state.data[galleryId],
            coverPhoto,
          },
        },
      }
    }
    case REMOVE_GALLERY_PHOTOS: {
      const galleryToRemovePhotosId = action.data.galleryId
      const newPhotos = { ...state.data[galleryToRemovePhotosId].photos || {} }
      action.data.photoIds.forEach(photoId => {
        delete newPhotos[photoId]
      })

      // update photoLayout without deleted photo or create a new photoLayout if it doesn't exist
      const photosLayout = state.data[galleryToRemovePhotosId].photosLayout
        ? state.data[galleryToRemovePhotosId].photosLayout || []
        : Object.keys(newPhotos)

      // if photoId was in the previous photosLayout, remove it.
      // Note: it could happen that the photoId was not there before if the user is deleting a photo from a gallery with photosLayout not set yet
      const photosLayoutDeletedPhotoIndex = photosLayout.indexOf(galleryToRemovePhotosId)
      if (photosLayoutDeletedPhotoIndex > -1) {
        photosLayout.splice(photosLayoutDeletedPhotoIndex, 1)
      }

      return {
        ...state,
        data: {
          ...state.data,
          [galleryToRemovePhotosId]: {
            ...state.data[galleryToRemovePhotosId],
            photos: newPhotos,
          },
        },
      }
    }
    case SET_GALLERY_PAGES_LOADED: {
      const galleryId = action.data.galleryId
      return {
        ...state,
        data: {
          ...state.data,
          [galleryId]: {
            ...state.data[galleryId],
            pagesLoaded: action.data.pagesLoaded,
          },
        },
      }
    }
    case FAILED_GALLERY:
      return {
        ...state,
        loading: false,
        error: action.data,
      }
    case SET_GALLERY_SHARING_OPTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.galleryId]: {
            ...state.data[action.data.galleryId],
            ...action.data.sharingOptions,
          },
        },
      }
    case SET_GALLERY_MARKED_PHOTO_IDS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.data.galleryId]: {
            ...state.data[action.data.galleryId],
            markedPhotos: {
              ...state.data[action.data.galleryId]?.markedPhotos,
              photoIds: action.data.markedPhotoIds,
            },
          },
        },
      }
    default:
      return state
  }
}

export default galleries
