import MuiLoadingButton from '@mui/lab/LoadingButton'
import { Button as MuiButton, IconButton as MuiIconButton } from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import { Link } from 'components/Link'

import cssVariables from 'styles/theme/colors.scss'
import './styles.scss'
import { ThemedComponent } from 'components/ThemedComponent'

const buttonStyling = {
  textTransform: 'none',
  borderRadius: '8px',
}

const DEFAULT_SIZE = 'small'

const customOutlineButtonTheme = {
  primary: {
    main: cssVariables.buttonPrimaryColor,
    contrastText: '#ffffff',
    light: cssVariables.primaryColorLight,
    dark: cssVariables.primaryColorDark,
  },
  secondary: {
    main: cssVariables.buttonSecondaryColor,
    contrastText: '#ffffff',
  },
  danger: {
    main: cssVariables.buttonDangerColor,
    contrastText: '#ffffff',
  },
  neutral: {
    main: cssVariables.grey,
  },
  default: {
    main: cssVariables.buttonDefaultColor,
    contrastText: cssVariables.textLightColor,
  },
}

export const Button = props => {
  const { style: customStyle, className: customClassName, variant, size, ...otherProps } = props
  return (
    <ThemedComponent customTheme={variant !== 'contained' ? customOutlineButtonTheme : undefined}>
      <MuiButton
        style={{
          ...buttonStyling,
          ...(customStyle || {}),
        }}
        className={classNames('button', customClassName, {
          'button--x-large': size === 'x-large',
        })}
        size={DEFAULT_SIZE}
        variant={variant}
        {...otherProps}
      />
    </ThemedComponent>
  )
}

export const IconButton = React.forwardRef((props, ref) => {
  const { children, style: customStyle, bottomText, ...otherProps } = props

  if (bottomText) {
    return (
      <div className='icon-button__bottom-text'>
        <ThemedComponent customTheme={customOutlineButtonTheme}>
          <MuiIconButton
            style={customStyle || {}}
            size={DEFAULT_SIZE}
            {...otherProps}
            ref={ref}
          >
            {children}
          </MuiIconButton>
        </ThemedComponent>
        <div className='icon-button__bottom-text__text'>
          {bottomText}
        </div>
      </div>
    )
  }

  return (
    <ThemedComponent customTheme={customOutlineButtonTheme}>
      <MuiIconButton
        style={customStyle || {}}
        size={DEFAULT_SIZE}
        {...otherProps}
        ref={ref}
      >
        {children}
      </MuiIconButton>
    </ThemedComponent>
  )
})

export const LinkButton = props => {
  const { href, wrapperClassName, style: customStyle, size, variant, ...otherProps } = props

  return (
    <ThemedComponent customTheme={variant !== 'contained' ? customOutlineButtonTheme : undefined}>
      <Link href={props.disabled ? null : href} className={wrapperClassName} disabled={props.disabled}>
        <Button
          style={{
            ...buttonStyling,
            ...(customStyle || {}),
          }}
          className={classNames({
            'button--x-large': size === 'x-large',
          })}
          size={DEFAULT_SIZE}
          variant={variant}
          {...otherProps}
        />
      </Link>
    </ThemedComponent>
  )
}

export const LoadingButton = props => {
  const { loading, loadingPosition = 'start', children, style: customStyle, className: customClassName, size, variant, ...otherProps } = props

  return (
    <ThemedComponent customTheme={variant !== 'contained' ? customOutlineButtonTheme : undefined}>
      <MuiLoadingButton
        loading={loading}
        loadingPosition={loadingPosition}
        color='primary'
        style={{
          ...buttonStyling,
          ...(customStyle || {}),
        }}
        className={classNames(customClassName, {
          'button--x-large': size === 'x-large',
        })}
        size={DEFAULT_SIZE}
        variant={variant}
        {...otherProps}
      >
        {children}
      </MuiLoadingButton>
    </ThemedComponent>
  )
}
