import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  fetchPacks as fetchPacksAction,
  uploadPack as uploadPackAction,
  editPack as editPackAction,
} from 'store/packs/actions'

import {
  fetchGalleries as fetchGalleriesAction,
  fetchGallery as fetchGalleryAction,
  fetchGalleryPhotos as fetchGalleryPhotosAction,
  addGallery as addGalleryAction,
  updateGallery as updateGalleryAction,
  editGalleryEvent as editGalleryEventAction,
  setGalleryPhotos as setGalleryPhotosAction,
  triggerAddWatermarkToGalleryPhotoJob as triggerAddWatermarkToGalleryPhotoJobAction,
  listenForPhotosWatermarkUpdate as listenForPhotosWatermarkUpdateAction,
  removeGallery as removeGalleryAction,
  removeGalleryPhoto as removeGalleryPhotoAction,
  removeGalleryPhotos as removeGalleryPhotosAction,
  setGallerySharingOptions as setGallerySharingOptionsAction,
  setGalleryMarkedPhotos as setGalleryMarkedPhotosAction,
} from 'store/galleries/actions'
import {
  fetchUser as fetchUserAction,
  addUserPack as addUserPackAction,
  addUserHiddenPack as addUserHiddenPackAction,
  hideUserPack as hideUserPackAction,
  removeUserPack as removeUserPackAction,
  showUserHiddenPack as showUserHiddenPackAction,
  updateWalkthroughData as updateWalkthroughDataAction,
  editUserPacks as editUserPacksAction,
  incrementUserGalleryCount as incrementUserGalleryCountAction,
  editUserLayout as editUserLayoutAction,
  addUserPortfolioCards as addUserPortfolioCardsAction,
  removeUserPortfolioCards as removeUserPortfolioCardsAction,
} from 'store/users/actions'

import {
  fetchUserEvent as fetchUserEventAction,
  fetchUserAgendaEvents as fetchUserAgendaEventsAction,
  fetchUserMonthlyAgenda as fetchUserMonthlyAgendaAction,
  fetchUserRecentAgenda as fetchUserRecentAgendaAction,
  addAgendaEvent as addAgendaEventAction,
  editAgendaEvent as editAgendaEventAction,
  setRemovingAgendaEvent as setRemovingAgendaEventAction,
  removeAgendaEvent as removeAgendaEventAction,
  removeGalleryFromAgendaEvent as removeGalleryFromAgendaEventAction,
} from 'store/agendas/actions'

export const useLocalActions = user => {
  const userId = user.uid

  const dispatch = useDispatch()

  const fetchUser = useCallback(
    customUserUid => dispatch(fetchUserAction(customUserUid || userId)),
    [dispatch, userId]
  )

  const fetchUserPacks = useCallback(
    () => dispatch(fetchPacksAction([...user.packsIds, ...user.hiddenPacksIds], user)),
    [dispatch, user]
  )

  const uploadPack = useCallback(
    (newPack, visibility) => dispatch(uploadPackAction(newPack, visibility, user)),
    [dispatch, user]
  )

  const editPack = useCallback(
    (newPack, visibility) => dispatch(editPackAction(newPack, visibility, user)),
    [dispatch, user]
  )

  const addUserPack = useCallback(
    (packId, addToStart, visible, postToServer) =>
      dispatch(addUserPackAction(user, packId, addToStart, visible, postToServer)),
    [dispatch, user]
  )

  const addUserHiddenPack = useCallback(
    packId => dispatch(addUserHiddenPackAction(user, packId)),
    [dispatch, user]
  )

  const hideUserPack = useCallback(
    packId => dispatch(hideUserPackAction(user, packId)),
    [dispatch, user]
  )

  const removeUserPack = useCallback(
    (packId, packVisibility) => dispatch(removeUserPackAction(user, packId, packVisibility)),
    [dispatch, user]
  )

  const showUserHiddenPack = useCallback(
    packId => dispatch(showUserHiddenPackAction(user, packId)),
    [dispatch, user]
  )

  const updateWalkthroughData = useCallback(
    (walkthroughKey, walkthroughData) => dispatch(updateWalkthroughDataAction(user, walkthroughKey, walkthroughData)),
    [dispatch, user]
  )

  const editUserPacks = useCallback(
    packIds => dispatch(editUserPacksAction(user, packIds)),
    [dispatch, user]
  )

  const incrementUserGalleryCount = useCallback(
    (increment, portefolioCount) => dispatch(incrementUserGalleryCountAction(user, increment, portefolioCount)),
    [dispatch, user]
  )

  const editUserLayout = useCallback(
    (layoutBreakpoint, layoutData) => dispatch(editUserLayoutAction(user, layoutBreakpoint, layoutData)),
    [dispatch, user]
  )

  const addUserPortfolioCards = useCallback(
    (portfolioCards, layoutBreakpoint, onProgressChange) =>
      dispatch(addUserPortfolioCardsAction(user, portfolioCards, layoutBreakpoint, false, onProgressChange)),
    [dispatch, user]
  )

  const removeUserPortfolioCards = useCallback(
    photoIds => dispatch(removeUserPortfolioCardsAction(user, photoIds)),
    [dispatch, user]
  )

  const fetchUserEvent = useCallback(
    eventId => {
      dispatch(fetchUserEventAction(userId, eventId))
    },
    [dispatch, userId]
  )

  const fetchUserAgendaEvents = useCallback(
    options => {
      dispatch(fetchUserAgendaEventsAction(userId, options))
    },
    [dispatch, userId]
  )

  const fetchUserMonthlyAgenda = useCallback(
    options =>
      dispatch(fetchUserMonthlyAgendaAction(userId, options)),
    [dispatch, userId]
  )

  const fetchUserRecentAgenda = useCallback(
    options => {
      dispatch(fetchUserRecentAgendaAction(userId, options))
    },
    [dispatch, userId]
  )

  const addAgendaEvent = useCallback(
    event =>
      dispatch(addAgendaEventAction(userId, event)),
    [dispatch, userId]
  )

  const editAgendaEvent = useCallback(
    event =>
      dispatch(editAgendaEventAction(userId, event)),
    [dispatch, userId]
  )

  const setRemovingAgendaEvent = useCallback(
    eventId =>
      dispatch(setRemovingAgendaEventAction(eventId)),
    [dispatch]
  )

  const removeAgendaEvent = useCallback(
    eventId =>
      dispatch(removeAgendaEventAction(userId, eventId)),
    [dispatch, userId]
  )

  const fetchUserGalleries = useCallback(
    (options, fetchAdjacentData) =>
      dispatch(fetchGalleriesAction(userId, options, fetchAdjacentData)),
    [dispatch, userId]
  )

  const fetchGallery = useCallback(
    galleryId =>
      dispatch(fetchGalleryAction(galleryId)),
    [dispatch]
  )

  const fetchGalleryPhotos = useCallback(
    galleryId =>
      dispatch(fetchGalleryPhotosAction(galleryId)),
    [dispatch]
  )

  const addGallery = useCallback(
    (gallery, galleryUser, galleryEvent) =>
      dispatch(addGalleryAction(gallery, galleryUser, galleryEvent)),
    [dispatch]
  )

  const updateGallery = useCallback(
    (galleryId, data, updateLocalState, updateWatermarks) =>
      dispatch(updateGalleryAction(galleryId, data, updateLocalState, updateWatermarks)),
    [dispatch]
  )

  const editGalleryEvent = useCallback(
    (galleryId, eventData, remove) =>
      dispatch(editGalleryEventAction(galleryId, eventData, remove)),
    [dispatch]
  )

  const setGalleryPhotos = useCallback(
    (galleryId, photos, callbackForEachPhoto, watermarkId) =>
      dispatch(setGalleryPhotosAction(galleryId, photos, callbackForEachPhoto, watermarkId)),
    [dispatch]
  )

  const triggerAddWatermarkToGalleryPhotoJob = useCallback(
    (eventGalleryId, watermarkId, galleryPhoto) =>
      dispatch(triggerAddWatermarkToGalleryPhotoJobAction(eventGalleryId, watermarkId, galleryPhoto)),
    [dispatch]
  )

  const listenForWatermarkChanges = useCallback(
    (galleryId, watermarkPhotoIDs, watermarkId) =>
      dispatch(listenForPhotosWatermarkUpdateAction(galleryId, watermarkPhotoIDs, watermarkId)),
    [dispatch]
  )

  const setGalleryMarkedPhotos = useCallback(
    (galleryId, markedPhotos) =>
      dispatch(setGalleryMarkedPhotosAction(galleryId, markedPhotos)),
    [dispatch]
  )

  const removeGallery = useCallback(
    gallery => {
      // if gallery has event associated, remove the gallery from the event data in local storage
      // The database logic will be handled in the server side function
      if (gallery?.eventId) {
        dispatch(removeGalleryFromAgendaEventAction(gallery.eventId, gallery.id))
      }

      return dispatch(removeGalleryAction(gallery, user))
    },
    [dispatch, user]
  )

  const removeGalleryPhoto = useCallback(
    (galleryId, photo, isMarkedPhoto) =>
      dispatch(removeGalleryPhotoAction(galleryId, photo, isMarkedPhoto)),
    [dispatch]
  )

  const removeGalleryPhotos = useCallback(
    (galleryId, photos, galleryMarkedPhotos) =>
      dispatch(removeGalleryPhotosAction(galleryId, photos, galleryMarkedPhotos)),
    [dispatch]
  )

  const setGallerySharingOptions = useCallback(
    (galleryId, sharingOptions) =>
      dispatch(setGallerySharingOptionsAction(galleryId, sharingOptions)),
    [dispatch]
  )

  return {
    fetchUser,
    fetchUserPacks,
    uploadPack,
    editPack,
    addUserPack,
    addUserHiddenPack,
    hideUserPack,
    removeUserPack,
    showUserHiddenPack,
    updateWalkthroughData,
    editUserPacks,
    incrementUserGalleryCount,
    editUserLayout,
    addUserPortfolioCards,
    removeUserPortfolioCards,
    fetchUserEvent,
    fetchUserAgendaEvents,
    fetchUserMonthlyAgenda,
    fetchUserRecentAgenda,
    addAgendaEvent,
    editAgendaEvent,
    setRemovingAgendaEvent,
    removeAgendaEvent,
    fetchUserGalleries,
    fetchGallery,
    fetchGalleryPhotos,
    addGallery,
    updateGallery,
    editGalleryEvent,
    setGalleryPhotos,
    triggerAddWatermarkToGalleryPhotoJob,
    listenForWatermarkChanges,
    setGalleryMarkedPhotos,
    removeGallery,
    removeGalleryPhoto,
    removeGalleryPhotos,
    setGallerySharingOptions,
  }
}
