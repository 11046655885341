import CheckIcon from '@mui/icons-material/Check'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import SaveAltOutlined from '@mui/icons-material/SaveAltOutlined'
import Alert from '@mui/material/Alert'
import ButtonGroup from '@mui/material/ButtonGroup'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { PaymentSuccessModal } from 'scenes/UserSettings/components/AccountSettings/components/PaymentSuccessModal'

import { Button, LinkButton } from 'components/Button'
import { Grid } from 'components/Grid'
import { UserType } from 'components/ImageInputFormStep/components/UserType'
import { LoadingComponent } from 'components/LoadingComponent'
import { SimpleMessageModal } from 'components/Modal'
import { PlanBadge } from 'components/PlanBadge'
import { PremiumPlanCard } from 'components/PremiumPlanCard'
import { ReauthencationModal } from 'components/ReauthencationModal'
import { Text } from 'components/Text'
import { TextField } from 'components/TextField'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useDebounce } from 'hooks/useDebounce'
import { useModal } from 'hooks/useModal'
import { useUrlQuery } from 'hooks/useUrlQuery'

import { PLAN_PERIOD } from 'model/Pricing'
import { USER_TYPE } from 'model/User'

import { deleteUser, verifyAndUpdateUserEmail, verifyEmail, authUserEmailIsVerified, getAuthUserEmail, listenToVerifiedEmail } from 'services/authenticationService'
import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'
import { goToCheckout, goToPortal } from 'services/paymentsService'
import { createHomeLink, createUserSettingsLink, subRoutesNames, createPricingLink } from 'services/routingService'
import { deletePackObjects, deleteUserObject } from 'services/searchService'
import { checkIfUsernameExists } from 'services/serverBridge'

import { logError } from 'utils/errorCapture'
import { getErrorMessage } from 'utils/signUp'

import './styles.scss'
import { VerifyNewEmailModal } from './components/VerifyNewEmailModal'
import { VerifyEmailModal } from './components/VerifyEmailModal'
import { Tooltip } from '@mui/material'
import { useCollapsibleAlert } from 'hooks/useCollapsibleAlert'

const STATUS = {
  INITIAL: 0,
  LOADING: 1,
  FAILED: 2,
  DONE: 3,
}

const USER_LOADING_STATUS = {
  NOT_LOADING: 0,
  SILENT_LOADING: 1,
  LOADING: 2,
}

const REAUTHENTICATION_MODAL_CALLBACK_ACTION = {
  DELETE_ACCOUNT: 0,
  UPDATE_EMAIL: 1,
  UPDATE_ACCOUNT_INFO: 2,
}

const ALERT_TIMEOUT_IN_MS = 3500

const getUserType = type => {
  if (type === undefined) {
    return undefined
  }

  return type === USER_TYPE.PROFESSIONAL ? 1 : 0
}

const notificationSystem = getNotificationSystem()

export const AccountSettings = props => {
  const paramPlanId = useUrlQuery('planId')
  const paramPriceId = useUrlQuery('priceId')
  const premiumPlanKeys = getConfigValue('general.pricing.plan_keys')
  const premiumPlanCards = getConfigValue('general.pricing.premium_plan_cards')
  const featuresByPlan = getConfigValue('user_settings.account_settings.features_by_plan')
  const [ userPlan, setUserPlan ] = useState(props.user.plan || { id: premiumPlanKeys[0] })
  const [ username, setUsername ] = useState(props.user.username || '')
  const [ email, setEmail ] = useState(props.user.email || '')
  const [ marketingConsent, setMarketingConsent ] = useState(props.user.marketingConsent || true)
  const [ userType, setUserType ] = useState(getUserType(props.user.type))
  const [ infoStatus, setInfoStatus ] = useState(STATUS.INITIAL)
  const [ emailChangeIsLoading, setEmailChangeIsLoading ] = useState(false)
  const [ usernameError, setUsernameError ] = useState()
  const [ emailError, setEmailError ] = useState()
  const [ plansStatus, setPlansStatus ] = useState({
    status: STATUS.INITIAL,
    index: 0,
  })
  const [ removeAccountStatus, setRemoveAccountStatus ] = useState(STATUS.INITIAL)
  const [ removeAccountModalIsOpen, setRemoveAccountModalIsOpen ] = useState(false)
  const [ paymentSuccessModalIsOpen, setPaymentSuccessModalIsOpen ] = useState(paramPlanId && paramPriceId)
  const [
    reauthenticationModalIsOpen,
    openReauthenticationModal,
    closeReauthenticationModal,
    reauthenticationModalContext,
  ] = useModal()
  const [
    changeEmailModalIsOpen,
    openChangeEmailModal,
    closeChangeEmailModal,
  ] = useModal()
  const [
    verifyEmailModalIsOpen,
    openVerifyEmailModal,
    closeVerifyEmailModal,
  ] = useModal()
  const [ userAlreadyUsedAlertIsOpen, setUserAlreadyUsedAlertIsOpen ] = useState() // false => username is valid || true => username is invalid || undefined => username is not changed
  const [
    searchingForUsernameExistenceStatus,
    setSearchingForUsernameExistenceStatus,
  ] = useState(USER_LOADING_STATUS.NOT_LOADING)
  const [
    planPeriod,
    setPlanPeriod,
  ] = useState(PLAN_PERIOD.MONTHLY)
  const [userEmailIsVerified, setUserEmailIsVerified] = useState(false)
  const [emailChangeAlertIsOpen, openEmailChangeAlert] = useCollapsibleAlert()
  const loadingTimeout = useRef()
  const debouncedSearchTerm = useDebounce(username, 250)
  const history = useHistory()
  const isSameUsername = props.user.username === username
  const { isSmallTabletAndUp } = useBreakpoints()
  const authUserEmail = getAuthUserEmail()
  const currentEmailIsAuthUserEmail = email === authUserEmail

  useEffect(() => {
    const { username, email, type, plan, marketingConsent } = props.user
    setUserPlan(plan || { id: premiumPlanKeys[0] })
    setUsername(username || '')
    setEmail(email || '')
    setUserType(getUserType(type))
    setMarketingConsent(marketingConsent)
  }, [premiumPlanKeys, props.user])

  useEffect(() => {
    if (debouncedSearchTerm && props.user.username !== debouncedSearchTerm) {
      clearTimeout(loadingTimeout.current)
      setSearchingForUsernameExistenceStatus(USER_LOADING_STATUS.SILENT_LOADING)
      loadingTimeout.current = setTimeout(() => {
        setSearchingForUsernameExistenceStatus(USER_LOADING_STATUS.LOADING)
      }, 500)
      checkIfUsernameExists(debouncedSearchTerm)
        .then(usernameExists => {
          clearTimeout(loadingTimeout.current)
          setUserAlreadyUsedAlertIsOpen(usernameExists)
          setSearchingForUsernameExistenceStatus(USER_LOADING_STATUS.NOT_LOADING)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, props.user.username, setUserAlreadyUsedAlertIsOpen]) // must be reactive to only this properties, oteherwise an infinite render loop could start

  useEffect(() => {
    const userEmailIsVerified = authUserEmailIsVerified(email) && currentEmailIsAuthUserEmail
    setUserEmailIsVerified(userEmailIsVerified)
  }, [email, currentEmailIsAuthUserEmail])

  const onUsernameChange = event => {
    if (usernameError || infoStatus === STATUS.FAILED) {
      setUsernameError(undefined)
      setInfoStatus(STATUS.INITIAL)
    }
    setUserAlreadyUsedAlertIsOpen(undefined)
    setUsername(event.target.value)
  }

  const onEmailChange = event => {
    if (emailError || infoStatus === STATUS.FAILED) {
      setEmailError(undefined)
      setInfoStatus(STATUS.INITIAL)
    }

    setEmail(event.target.value)
  }

  const onMarketingConsentChange = event => {
    setMarketingConsent(event.target.checked)
  }

  const onSaveAccountInfo = () => {
    // edge case: ignore input for half a second while silent loading
    // this is just to prevent save button to be disabled while loading, and flickering in quick loading times
    // to be sure nothing breaks, the save button input must be protected
    const checkingUsernameSilently = searchingForUsernameExistenceStatus === USER_LOADING_STATUS.SILENT_LOADING
    const notCheckingUsernameYet = username !== debouncedSearchTerm
    if (checkingUsernameSilently || notCheckingUsernameYet) {
      return
    }

    if (!username) {
      setUsernameError(t('profile_settings.account_settings.invalidUsernameWarning'))
      setInfoStatus(STATUS.FAILED)
      return
    }

    setInfoStatus(STATUS.LOADING)

    return props.editUserInfo({
      username,
      type: userType === 1 ? USER_TYPE.PROFESSIONAL : USER_TYPE.CLIENT,
      marketingConsent,
    })
      .then(() => {
        setInfoStatus(STATUS.INITIAL)
        notificationSystem.notify(t('profile_settings.account_settings.generic_success_info'))
      })
      .catch(err => {
        if (err.code === 'auth/requires-recent-login') {
          setInfoStatus(STATUS.INITIAL)
          openReauthenticationModal(REAUTHENTICATION_MODAL_CALLBACK_ACTION.UPDATE_ACCOUNT_INFO)
          return
        }

        setEmailError(getErrorMessage(err, t('profile_settings.account_settings.generic_error_message')))

        logError(err)

        setInfoStatus(STATUS.FAILED)
        setTimeout(() => {
          setInfoStatus(STATUS.INITIAL)
        }, ALERT_TIMEOUT_IN_MS)
      })
  }

  const onSaveNewEmail = () => {
    setEmailChangeIsLoading(true)

    // first try to update user email in authentication service
    // only if this is successfull, we update the user data
    // this is because the authentication service might throw an error if the user is authenticated too long ago
    return verifyAndUpdateUserEmail(email)
      .then(() => {
        openChangeEmailModal()
        listenToVerifiedEmail(email)
          .then(() => {
            closeChangeEmailModal()
            // we try to edit the user email in database right after the email is confirmed
            // however, this might not happen since the page will reload after the auth user is revoked.
            // To avoid inconsistencies, we also update the user email, if necessary, when initializing the authentication service
            props.editUserInfo({
              email,
            })
              .then(() => {
                notificationSystem.notify(t('profile_settings.account_settings.email_change_success'))
              })
              .finally(() => {
                // even if it fails, let's reload and try again (authentication service has logic to update the email)
                window.location.reload()
              })
          })
      })
      .catch(err => {
        if (err.code === 'auth/requires-recent-login') {
          openReauthenticationModal(REAUTHENTICATION_MODAL_CALLBACK_ACTION.UPDATE_EMAIL)
          return
        }

        setEmailError(getErrorMessage(err, t('profile_settings.account_settings.generic_error_message')))

        logError(err)
        openEmailChangeAlert()
      })
      .finally(() => setEmailChangeIsLoading(false))
  }

  const onVerifyEmailClick = () => {
    verifyEmail()
      .then(() => {
        openVerifyEmailModal()
        listenToVerifiedEmail(email)
          .then(() => {
            closeVerifyEmailModal()
            setUserEmailIsVerified(true)
            notificationSystem.notify(t('profile_settings.account_settings.email_verify_success'))
          })
      })
      .catch(err => {
        if (err.code === 'auth/too-many-requests') {
          alert('Erro: Demasiados pedidos')
          return
        }
      })
  }

  const onPlanChoose = newPlanId => {
    // if current user has already a configured plan, we want them to go to session page so that the user can edit its plan
    // Unless user has free plan. In that case we want to create a new checkout session (in this case, we must pass the customer id to the checkout page so that it's not created a new user in the billing system)
    if (props.user.plan?.stripeUserId && props.user.plan?.id !== premiumPlanKeys[0]) {
      onPlanEdit(newPlanId)
      return
    }

    const selectedPriceId = planPeriod === PLAN_PERIOD.MONTHLY
      ? premiumPlanCards[newPlanId].stripe_price_ids[0]
      : premiumPlanCards[newPlanId].stripe_price_ids[1]

    goToCheckout({
      planId: premiumPlanCards[newPlanId].id,
      priceId: selectedPriceId,
      successUrl: createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT),
      cancelUrl: createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT),
      clientId: props.user.uid,
      clientEmail: props.user.email,
      customerId: props.user.plan?.stripeUserId,
      hasTrial: premiumPlanCards[newPlanId].prices[selectedPriceId]?.hasTrial && !props.user.plan?.stripeUserId,
    })

    setPlansStatus({
      status: STATUS.LOADING,
      planId: newPlanId,
    })
  }

  const onPlanEdit = newPlanId => {
    goToPortal({
      cancelUrl: createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT),
      stripeUserId: props.user.plan?.stripeUserId,
    })

    setPlansStatus({
      status: STATUS.LOADING,
      planId: newPlanId,
    })
  }

  const onRemoveAccountClick = () => {
    setRemoveAccountModalIsOpen(true)
  }

  const closeRemoveAccountModal = () => {
    setRemoveAccountModalIsOpen(false)
  }

  const onCloseReauthenticationModal = () => {
    closeReauthenticationModal()
    setInfoStatus(STATUS.INITIAL)
  }

  // if success, we should update the plan in the local user store, so that it maps the remote server
  const closePaymentSuccessModal = newPlan => {
    if (newPlan) {
      props.updateUserPlan({
        plan: newPlan,
      })
    }
    setPaymentSuccessModalIsOpen(false)
    history.replace(createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT, false))
  }

  const onPlanPeriodChange = newPlanPeriod => () => setPlanPeriod(newPlanPeriod)

  const onReauthenticateSuccess = () => {
    notificationSystem.notify(t('profile_settings.account_settings.reauthentication_success'))
    // reauthenticationModal context saves the action that was being done by the user before reauthenticationModal was prompt
    // Therefore, we should do it now
    switch (reauthenticationModalContext) {
      case REAUTHENTICATION_MODAL_CALLBACK_ACTION.DELETE_ACCOUNT:
        removeAccount()
        break
      case REAUTHENTICATION_MODAL_CALLBACK_ACTION.UPDATE_EMAIL:
        onSaveNewEmail()
        break
      case REAUTHENTICATION_MODAL_CALLBACK_ACTION.UPDATE_ACCOUNT_INFO:
        onSaveAccountInfo()
        break
      default:
        setInfoStatus(STATUS.INITIAL)
        break
    }
    // call directly closeReauthenticationModal after using its context:
    // - to avoid setting infoStatus to INITIAL (infoStatus logic should be handled by the callback action)
    // - to avoid clearing the context before using it
    closeReauthenticationModal()
  }

  const removeAccount = () => {
    setRemoveAccountStatus(STATUS.LOADING)
    deleteUser()
      .then(() => {
        const userPackIDs = [
          ...(props.user.packs?.cards || []).map(card => card.id),
          ...(props.user.packs?.hiddenCards || []).map(card => card.id),
        ]
        return Promise.all([
          deleteUserObject(props.user),
          deletePackObjects(userPackIDs),
        ])
      })
      .then(() => {
        history.push(createHomeLink(false))
      })
      .catch(err => {
        if (err.code === 'auth/requires-recent-login') {
          setRemoveAccountStatus(STATUS.INITIAL)
          openReauthenticationModal(REAUTHENTICATION_MODAL_CALLBACK_ACTION.DELETE_ACCOUNT)
          return
        }

        logError(err)
        setRemoveAccountStatus(STATUS.FAILED)
        setTimeout(() => {
          setRemoveAccountStatus(STATUS.INITIAL)
        }, ALERT_TIMEOUT_IN_MS)
      })
  }

  const getPlanSubTitle = premiumCard => {
    const subtitleKey = `profile_settings.account_settings.premium_plan_picker.${premiumCard.i18nKey}.sub_title`
    return t(subtitleKey)
  }

  return (
    <div className='account-settings'>
      <Text
        className='account-settings__title'
        as='h2'
        type='title3'
      >
        {t('profile_settings.account_settings.title')}
      </Text>
      <div className='account-settings__content'>
        <div className='account-settings__section'>
          <Text
            as='h4'
            type='subtitle'
          >
            {t('profile_settings.account_settings.account_info_section')}
          </Text>
          <UserType
            className='account-settings__user-type'
            content={[
              {
                title: t('profile_settings.account_settings.account_type_client'),
              }, {
                title: t('profile_settings.account_settings.account_type_pro'),
              },
            ]}
            onChange={setUserType}
            initialValue={userType}
          />
          <div className='account-settings__username'>
            <TextField
              label={t('profile_settings.account_settings.username')}
              className='account-settings__username__text-field'
              value={username}
              onChange={onUsernameChange}
              error={Boolean(usernameError)}
            />
            <LoadingComponent
              className='account-settings__username__loading'
              isLoading={searchingForUsernameExistenceStatus === USER_LOADING_STATUS.LOADING}
              size={20}
            />
            {
              userAlreadyUsedAlertIsOpen === false &&
              searchingForUsernameExistenceStatus !== USER_LOADING_STATUS.LOADING &&
              !isSameUsername &&
                <CheckIcon className='account-settings__username__is-available-icon' />
            }
          </div>
          <Collapse in={userAlreadyUsedAlertIsOpen}>
            <Alert className='account-settings__username-alert' severity='warning'>{t('profile_settings.account_settings.alreadyUsedUsernameWarning')}</Alert>
          </Collapse>
          <FormControlLabel
            control={<Checkbox onChange={onMarketingConsentChange} checked={marketingConsent} />}
            label={t('profile_settings.account_settings.marketing_consent')}
          />
          <div className='account-settings__action-button'>
            <Button
              color='primary'
              variant='contained'
              startIcon={<SaveAltOutlined />}
              onClick={onSaveAccountInfo}
              disabled={
                infoStatus === STATUS.LOADING ||
                infoStatus === STATUS.DONE ||
                userAlreadyUsedAlertIsOpen ||
                searchingForUsernameExistenceStatus === USER_LOADING_STATUS.LOADING
              }
            >
              {t('profile_settings.account_settings.save_account_info')}
            </Button>
            <LoadingComponent
              isLoading={infoStatus === STATUS.LOADING}
              size={30}
              className='account-settings__action-button-loading'
            />
          </div>
          <Collapse className='account-settings__alert' in={infoStatus === STATUS.FAILED || plansStatus?.status === STATUS.FAILED}>
            <Alert severity='error'>{emailError || usernameError || t('profile_settings.account_settings.info_error')}</Alert>
          </Collapse>
        </div>
        <div className='account-settings__section'>
          <Text
            as='h4'
            type='subtitle'
          >
            {t('profile_settings.account_settings.email_section')}
          </Text>
          <div className='account-settings__email'>
            <TextField
              className='account-settings__email__input'
              label={t('profile_settings.account_settings.email')}
              value={email}
              type='email'
              onChange={onEmailChange}
              error={Boolean(emailError)}
            />
            {userEmailIsVerified && (
              <Tooltip arrow disableInteractive title='Email verificado' placement='right'>
                <label className='account-settings__email__verified-ticker'>
                  <div className='account-settings__email__verified-ticker__tick-mark' />
                </label>
              </Tooltip>
            )}
            {!userEmailIsVerified && currentEmailIsAuthUserEmail &&
              <Button onClick={onVerifyEmailClick}>Verificar email</Button>}
          </div>
          <div className='account-settings__action-button'>
            <Button
              color='primary'
              variant='contained'
              startIcon={<SaveAltOutlined />}
              onClick={onSaveNewEmail}
              disabled={emailChangeIsLoading || currentEmailIsAuthUserEmail}
            >
              {t('profile_settings.account_settings.save_email')}
            </Button>
            <LoadingComponent
              isLoading={emailChangeIsLoading}
              size={30}
              className='account-settings__action-button-loading'
            />
          </div>
          <Collapse className='account-settings__alert' in={emailChangeAlertIsOpen}>
            <Alert severity='error'>{emailError || t('profile_settings.account_settings.info_error')}</Alert>
          </Collapse>
        </div>
        {userType === 1 && (
          <div className='account-settings__section'>
            <Text
              className='account-settings__title'
              as='h2'
              type='title3'
            >
              {userPlan.id
                ? t('profile_settings.account_settings.premium_plan_picker.title_with_premium')
                : t('profile_settings.account_settings.premium_plan_picker.title')}
            </Text>

            {userPlan.id && (
              <div>
                <Text
                  as='h4'
                  type='subtitle'
                >
                  {t('profile_settings.account_settings.premium_plan_picker.user_account_premium')}
                </Text>

                <PlanBadge className='account-settings__plan-badge' planId={userPlan.id} />

                {props.user.plan?.id && (
                  <Button
                    className='account-settings__edit-plan'
                    onClick={onPlanEdit}
                    color='default'
                    variant='outlined'
                  >
                    {props.user.plan.id === premiumPlanKeys[0] ? t('profile_settings.account_settings.premium_plan_picker.review_payments_account') : t('profile_settings.account_settings.premium_plan_picker.edit_plan')}
                  </Button>
                )}

                {userPlan.expiringDate && (
                  <div className='account-settings__expire-label'>
                    {t('profile_settings.account_settings.premium_plan_picker.expiring_date', {
                      relative_expiring_date: moment.unix(userPlan.expiringDate).fromNow(),
                      expiring_date: moment.unix(userPlan.expiringDate).format('DD [de] MMMM'),
                    })}
                  </div>
                )}
              </div>
            )}
            <ButtonGroup className='account-settings__plan-period-picker' aria-label='outlined primary button group'>
              <Button onClick={onPlanPeriodChange(PLAN_PERIOD.MONTHLY)} variant={planPeriod === PLAN_PERIOD.MONTHLY ? 'contained' : 'outlined'}>Mensal</Button>
              <Button onClick={onPlanPeriodChange(PLAN_PERIOD.YEARLY)} variant={planPeriod === PLAN_PERIOD.YEARLY ? 'contained' : 'outlined'}>Anual</Button>
            </ButtonGroup>
            <Grid
              spacing={isSmallTabletAndUp ? 10 : 20}
              xs={12}
              md={4}
              className='account-settings__plan-card-list'
            >
              {premiumPlanKeys.map(planKey => {
                const premiumCard = premiumPlanCards[planKey]
                const premiumCardFeatures = featuresByPlan[planKey]
                const periodPrice = Object.values(premiumCard.prices)
                  .find(price => planPeriod === PLAN_PERIOD.MONTHLY ? price.interval === 'monthly' || price.interval === 'once' : price.interval === 'anual' || price.interval === 'once')
                return (
                  <PremiumPlanCard
                    key={premiumCard.id}
                    id={premiumCard.id}
                    title={t(`general.premium_packs.${premiumCard.i18nKey}.title`)}
                    description={t(`profile_settings.account_settings.premium_plan_picker.${premiumCard.i18nKey}.description`)}
                    subTitle={getPlanSubTitle(premiumCard)}
                    periodPrice={periodPrice?.price}
                    periodPriceWithoutDiscount={periodPrice?.price_without_discount}
                    planPeriod={planPeriod}
                    features={premiumCardFeatures
                      .filter(featureKey => Boolean(premiumCard.available_features[featureKey]))
                      .map(featureKey => t(`profile_settings.account_settings.premium_plan_picker.${premiumCard.i18nKey}.features.${featureKey}`))}
                    moreButtonLabel={t(`profile_settings.account_settings.premium_plan_picker.${premiumCard.i18nKey}.more`)}
                    pickButtonLabel={t(`profile_settings.account_settings.premium_plan_picker.${premiumCard.i18nKey}.pick`)}
                    selected={userPlan.id === premiumCard.id}
                    uploading={
                      plansStatus &&
                      plansStatus.planId === premiumCard.id &&
                      plansStatus.status === STATUS.LOADING
                    }
                    disabled={premiumCard.soon}
                    onPlanChoose={onPlanChoose}
                  />
                )
              })}
            </Grid>
          </div>
        )}
        {userType === 1 && (
          <LinkButton
            className='account-settings__plans-more-info'
            href={createPricingLink()}
            variant='outlined'
            color='default'
          >
            {t('profile_settings.account_settings.premium_plan_picker.more_info_button')}
          </LinkButton>
        )}
        <div className='account-settings__section'>
          <Text
            className='account-settings__title'
            as='h2'
            type='title3'
          >
            {t('profile_settings.account_settings.delete_account_section.title')}
          </Text>
          <Text as='h4' type='subtitle'>
            {t('profile_settings.account_settings.delete_account_section.sub_title')}
          </Text>
          <div className='account-settings__action-button'>
            <Button
              color='danger'
              variant='contained'
              startIcon={<DeleteForeverOutlined />}
              onClick={onRemoveAccountClick}
              disabled={removeAccountStatus === STATUS.LOADING || removeAccountStatus === STATUS.DONE}
            >
              {t('profile_settings.account_settings.delete_account_section.delete_account_button')}
            </Button>
            <LoadingComponent
              isLoading={removeAccountStatus === STATUS.LOADING}
              size={30}
              className='account-settings__action-button-loading'
            />
          </div>
          <Collapse className='account-settings__alert' in={removeAccountStatus === STATUS.FAILED}>
            <Alert severity='error'>{t('profile_settings.account_settings.error')}</Alert>
          </Collapse>
          <SimpleMessageModal
            open={removeAccountModalIsOpen}
            closeModal={closeRemoveAccountModal}
            modalTitle={t('profile_settings.account_settings.delete_acount_modal.modal_title')}
            title={t('profile_settings.account_settings.delete_acount_modal.title')}
            message={t('profile_settings.account_settings.delete_acount_modal.message')}
            actionButtonText={t('profile_settings.account_settings.delete_acount_modal.action_buttons.submit')}
            secondaryActionButtonVariant='outlined'
            secondaryActionButtonText={t('profile_settings.account_settings.delete_acount_modal.action_buttons.cancel')}
            actionButtonColor='danger'
            secondaryActionButtonColor='default'
            onActionButton={removeAccount}
            onSecondaryActionButton={closeRemoveAccountModal}
            danger
          />
        </div>
      </div>
      <ReauthencationModal
        open={reauthenticationModalIsOpen}
        closeModal={onCloseReauthenticationModal}
        onReauthenticateSuccess={onReauthenticateSuccess}
        currentEmail={props.user.email}
        history={history}
      />
      <PaymentSuccessModal
        open={paymentSuccessModalIsOpen}
        closeModal={closePaymentSuccessModal}
        userId={props.user.uid}
        planId={paramPlanId}
        priceId={paramPriceId}
      />
      <VerifyNewEmailModal
        open={changeEmailModalIsOpen}
        closeModal={closeChangeEmailModal}
      />
      <VerifyEmailModal
        open={verifyEmailModalIsOpen}
        closeModal={closeVerifyEmailModal}
      />
    </div>
  )
}
