import {
  REQUEST_AGENDA_EVENTS,
  ADD_AGENDA_EVENTS,
  ADD_AGENDA_EVENT,
  SET_REMOVING_AGENDA_EVENT,
  REMOVE_AGENDA_EVENT,
  EDIT_AGENDA_EVENT,
  EDIT_AGENDA_EVENT_GALLERY_DATA,
  ADD_CALENDAR_EVENTS,
  ADD_RECENT_EVENTS,
  UPDATE_CALENDAR_EVENTS_CACHE,
} from './actions'

const MAX_CALENDAR_CACHE = 10

const initialState = {
  events: {
    data: undefined,
    loading: undefined,
  },
  calendar: {
    data: {}, // every month as an object with the event keys associated
    cacheFifo: [], // every month key that is still cached in the store
    loading: undefined,
  },
  recentEvents: {
    data: [], // every recent event keys
    loading: undefined,
  },
}

const agendas = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AGENDA_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
        },
      }
    case ADD_AGENDA_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          data: {
            ...state.events.data,
            ...action.data,
          },
          loading: false,
        },
      }
    case ADD_AGENDA_EVENT: {
      const newEvent = action.data

      return {
        ...state,
        events: {
          ...state.events,
          data: {
            ...state.events.data,
            [newEvent.id]: newEvent,
          },
        },
      }
    }
    case SET_REMOVING_AGENDA_EVENT: {
      const eventId = action.data.eventId

      return {
        ...state,
        events: {
          ...state.events,
          data: {
            ...state.events.data,
            [eventId]: {
              ...state.events.data[eventId],
              deleting: true,
            },
          },
        },
      }
    }
    case REMOVE_AGENDA_EVENT: {
      const eventId = action.data.eventId
      const eventsDataCopy = { ...state.events.data }

      // remove only the base event data.
      // if calendar or recentEvents have any key that points to events.data and is undefined, it is their duty to handle it, so that it can be more efficient
      delete eventsDataCopy[eventId]

      return {
        ...state,
        events: {
          ...state.events,
          data: eventsDataCopy,
        },
      }
    }
    case EDIT_AGENDA_EVENT: {
      const event = action.data

      return {
        ...state,
        events: {
          ...state.events,
          data: {
            ...state.events.data,
            [event.id]: {
              ...state.events.data[event.id],
              ...event,
            },
          },
        },
      }
    }
    case EDIT_AGENDA_EVENT_GALLERY_DATA: {
      const galleryEventId = action.data.galleryEventId
      const galleryId = action.data.galleryId
      const shouldDelete = action.data.shouldDelete

      const newGalleries = {
        ...state.events.data[galleryEventId].galleries,
        [galleryId]: {
          id: galleryId,
        },
      }

      if (shouldDelete) {
        delete newGalleries[galleryId]
      }

      return {
        ...state,
        events: {
          ...state.events,
          data: {
            ...state.events.data,
            [galleryEventId]: {
              ...state.events.data[galleryEventId],
              galleries: newGalleries,
            },
          },
        },
      }
    }
    case ADD_CALENDAR_EVENTS: {
      const calendarCacheEntryKey = action.data.calendarCacheEntryKey
      const calendarEventKeys = Array.from(new Set([
        ...(state.calendar.data[calendarCacheEntryKey] || []),
        ...Object.keys(action.data.events),
      ]))

      const eventsData = { ...state.events.data }

      const calendarData = {
        ...state.calendar.data,
        [calendarCacheEntryKey]: calendarEventKeys,
      }

      const calendarCacheFifo = Array.from(new Set([calendarCacheEntryKey, ...state.calendar.cacheFifo]))

      if (calendarCacheFifo.length > MAX_CALENDAR_CACHE) {
        const calendarEntryKeyToDelete = calendarCacheFifo[calendarCacheFifo.length - 1]
        const eventKeysToDelete = calendarData[calendarEntryKeyToDelete]

        delete calendarData[calendarEntryKeyToDelete]
        calendarCacheFifo.pop()

        eventKeysToDelete.forEach(eventKey => {
          // first check if event key is not in another cache entry (other month)
          const eventIsInAnotherEntry = Object.keys(calendarData)
            .some(calendarDataEntryKey => {
              const thisEntryKeyEvents = calendarData[calendarDataEntryKey]
              return thisEntryKeyEvents.includes(eventKey)
            })

          // before deleting event data entry, also check if recent events don't have it stored
          const eventIsInRecentEvents = state.recentEvents.data.includes(calendarEntryKeyToDelete)

          if (!eventIsInAnotherEntry && !eventIsInRecentEvents) {
            delete eventsData[eventKey]
          }
        })
      }

      return {
        ...state,
        events: {
          data: eventsData,
          loading: false,
        },
        calendar: {
          data: calendarData,
          cacheFifo: calendarCacheFifo,
        },
      }
    }
    case UPDATE_CALENDAR_EVENTS_CACHE: {
      const calendarCacheEntryKeyToUpdate = action.data.calendarCacheEntryKey

      const newCalendarCacheFifo = [...state.calendar.cacheFifo]

      if (!newCalendarCacheFifo.includes(calendarCacheEntryKeyToUpdate)) {
        return state
      }

      const keyIndex = newCalendarCacheFifo
        .findIndex(calendarEntryKey => calendarEntryKey === calendarCacheEntryKeyToUpdate)

      // remove old item
      newCalendarCacheFifo.splice(keyIndex, 1)
      // and replace it in from of the cache list
      newCalendarCacheFifo.unshift(calendarCacheEntryKeyToUpdate)

      return {
        ...state,
        calendar: {
          ...state.calendar,
          cacheFifo: newCalendarCacheFifo,
        },
      }
    }
    case ADD_RECENT_EVENTS: {
      const recentEventKeys = Array.from(new Set([...state.recentEvents.data, ...Object.keys(action.data)]))

      return {
        ...state,
        events: {
          data: {
            ...state.events.data,
            ...action.data,
          },
          loading: false,
        },
        recentEvents: {
          data: recentEventKeys,
          loading: false,
        },
      }
    }
    default:
      return state
  }
}

export default agendas
