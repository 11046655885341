import { useState, useRef } from 'react'

const DEFAULT_ALERT_TIMEOUT_IN_MS = 3500

export const useAlert = ({ timeout = DEFAULT_ALERT_TIMEOUT_IN_MS } = {}) => {
  const [ alertIsOpen, setAlertIsOpen ] = useState(false)
  const alertTimeoutRef = useRef()

  const openAlert = () => {
    alertTimeoutRef.current && clearTimeout(alertTimeoutRef.current)
    setAlertIsOpen(true)
    alertTimeoutRef.current = setTimeout(() => {
      setAlertIsOpen(false)
    }, timeout)
  }

  return [alertIsOpen, openAlert]
}
