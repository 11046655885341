import classNames from 'classnames'
import React from 'react'

import './styles.scss'

// gapSize should be: x0, x0pt5, x1, x1pt5 and so on
// x1 is 8px
const getGapSize = (gapSize = 'x0') => {
  const [x, pt] = gapSize.split('x')[1].split('pt')
  return (x * 8) + (pt ? 4 : 0)
}

export const Stack = ({ children, vertical, gap, className }) => {
  return (
    <div
      className={classNames('stack', {
        'stack--vertical': vertical,
      }, className)}
      style={{ '--stack-gap': `${getGapSize(gap)}px` }}
    >
      {children}
    </div>
  )
}
