const config = [
  {
    componentId: 'ImageInputFormStep',
    id: 'register-journey-step1',
    componentContent: {
      id: 'register-journey__first-step',
      title: 'Bem-vindo(a) à PLOTU!',
      subTitle: 'Antes de começarmos precisamos de te conhecer.',
      inputs: [
        {
          title: 'Escolhe a tua foto de perfil',
          id: 'avatar',
          type: 'avatar-picker',
        },
        {
          title: 'Como queres ser conhecido na PLOTU?',
          label: 'Nome',
          id: 'name',
          type: 'text',
        },
      ],
      submitButton: {
        text: 'Continuar',
        goToId: 'register-journey-step2',
      },
      image: {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fregister%2Fregister_journey_1.jpg?alt=media',
        alt: 'Telemóvel com galeria PLOTU',
      },
    },
  }, {
    componentId: 'ImageInputFormStep',
    id: 'register-journey-step2',
    componentContent: {
      id: 'register-journey__second-step',
      title: 'Queremos saber o que te traz à PLOTU',
      inputs: [
        {
          id: 'user-type',
          type: 'user-type',
          content: [
            {
              title: 'Sou um cliente e quero marcar uma sessão fotográfica',
              description: 'Encontra aqui todo o tipo de fotógrafos. Queremos ajudar-te a encontrar o fotógrafo perfeito para o teu projeto pessoal ou empresarial.',
            }, {
              title: 'Sou um fotógrafo e estou disponível para realizar trabalhos de fotografia e/ou vídeo',
              description: 'Explora as nossas ferramentas de gestão, expõe os teus serviços e promove o teu trabalho com a criação de packs.',
            },
          ],
        },
      ],
      backButton: {
        text: 'Anterior',
        goToId: 'register-journey__first-step',
      },
      submitButton: {
        text: 'Continuar',
        handleGoToId: [
          [0, 1, 'register-journey-professionals-step1'],
          [0, 0, 'register-journey-client-step1'],
        ],
      },
      image: {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fregister%2Fregister_journey_2.jpg?alt=media',
        alt: 'Computador com galeria PLOTU',
      },
    },
  }, {
    componentId: 'ImageInputFormStep',
    id: 'register-journey-professionals-step1',
    componentContent: {
      id: 'register-journey__professionals__first-step',
      title: 'Fala um pouco mais sobre ti para percebermos que tipo de fotógrafo és tu',
      inputs: [
        {
          title: 'Qual a tua localização?',
          label: 'Localidade',
          id: 'location',
          type: 'text',
        },
        {
          title: 'Quais os serviços que realizas?',
          label: 'Fotografia, Vídeo, Edição Fotografia, Edição Vídeo',
          id: 'professional-services',
          type: 'multiple-input-list',
          freeSolo: false,
          options: ['Fotografia', 'Vídeo', 'Edição Fotografia', 'Edição Vídeo', 'Álbuns'],
        },
        {
          title: 'Qual o teu estilo de fotografia/vídeo?',
          label: 'Casamentos, Família, Batizados, Eventos, Maternidade/Grávida, outros',
          id: 'professional-services-categories',
          type: 'multiple-input-list',
          disableCloseOnSelect: true,
          options: [
            'Animais',
            'Arquitetura',
            'Batizados',
            'Bebés',
            'Casais',
            'Casamentos',
            'Comida',
            'Crianças',
            'Desporto',
            'Documentário',
            'Espaços',
            'Eventos',
            'Família',
            'Fotografia Artística',
            'Fotografia Comercial',
            'Fotografia Corporativa',
            'Fotojornalismo',
            'Hotelaria',
            'Imóveis',
            'Influencers',
            'Livro de Fotografias',
            'Maternidade/Gravidez',
            'Moda',
            'Produto',
            'Publicidade',
            'Recém-nascidos',
            'Reportagem',
            'Retrato',
          ],
        },
      ],
      backButton: {
        text: 'Anterior',
        goToId: 'register-journey-step2',
      },
      submitButton: {
        text: 'Continuar',
        goToId: 'register-journey-professionals-step3',
      },
      skipButton: {
        text: 'Ignorar passo',
        goToId: 'register-journey-professionals-step3',
      },
      image: {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fregister%2Ffoto_registo.jpg?alt=media',
        alt: 'Computador com galeria PLOTU',
      },
    },
  }, {
    componentId: 'ImageInputFormStep',
    id: 'register-journey-professionals-step3',
    componentContent: {
      id: 'register-journey__professionals__second-step',
      title: 'Chegámos ao fim!',
      subTitle: 'Convidamos-te agora a explorar todas as funcionalidades que a PLOTU tem para ti e que te vão ajudar a alcançar todos os teus objetivos. Chegou o momento de brilhares!',
      backButton: {
        text: 'Anterior',
        goToId: 'register-journey-professionals-step1',
      },
      submitButton: {
        text: 'Segue para o teu perfil',
        goToHref: 'USER_DETAILS::{"id": "$this"}',
      },
      image: {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fregister%2Fchris-reyem-yXWPnzoYswI-unsplash.jpg?alt=media&token=12d15c00-361f-4cd1-b71f-1fbdec9c584a',
        alt: 'Fotógrafo a tirar fotografia',
      },
    },
  }, {
    componentId: 'ImageInputFormStep',
    id: 'register-journey-client-step1',
    componentContent: {
      id: 'register-journey__clients__first-step',
      subTitle: 'Bem-Vindo(a) à PLOTU. Chegaste ao sítio certo para encontrares o fotógrafo ideal!',
      backButton: {
        text: 'Anterior',
        goToId: 'register-journey-step2',
      },
      submitButton: {
        text: 'Vamos Começar',
        goToHref: 'HOME',
      },
      image: {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fregister%2Fchris-reyem-yXWPnzoYswI-unsplash.jpg?alt=media&token=12d15c00-361f-4cd1-b71f-1fbdec9c584a',
        alt: 'Fotógrafo a tirar fotografia',
      },
    },
  },
]

export default config
