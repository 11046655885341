import { useHistory } from 'react-router-dom'

import { useLocalActions } from 'scenes/BackOffice/actions/useLocalActions'

import { BACK_OFFICE_PAGES_CONFIG } from '../constants/config'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { ACTION, useWalkthrough } from 'hooks/useWalkthrough'

import { changeRoute, subRoutesNames, createBackOfficeLink } from 'services/routingService'

export const WALKTHROUGH_KEY = 'walkthrough-backoffice'
const BACK_OFFICE_PAGES_URLS = subRoutesNames.BACK_OFFICE

export const useBackofficeWalkthrough = (walkthroughData, logedInUser) => {
  const history = useHistory()
  const changeLocation = url => changeRoute(history, url)
  const { isSmallTabletAndUp } = useBreakpoints()
  const isMobileOrSmallTablet = !isSmallTabletAndUp

  const {
    updateWalkthroughData,
  } = useLocalActions(logedInUser)

  const walkthroughSteps = [
    {
      target: '.backoffice__content',
      title: 'Bem-vindo ao PLOTUStudio!',
      content: 'Aqui podes gerir e alavancar o teu negócio.\nCarrega em "Próximo" para uma visita guiada de 2 minutos',
      disableBeacon: true,
      isFixed: true,
      placement: 'center',
    },
    {
      target: '.side-bar__list',
      content: 'Começemos pelo menu. Usa-o para explorar o PLOTUStudio',
    },
    {
      target: `#${BACK_OFFICE_PAGES_URLS.DASHBOARD}-menu-item`,
      content: 'Acede ao Dashboard',
      next: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[0].url),
      location: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[0].url),
    },
    {
      target: isMobileOrSmallTablet ? '.backoffice__content' : '.dashboard',
      content: 'Usa o Dashboard para obteres uma visão geral do PLOTUStudio.\nPodes ver rapidamente se tens eventos nos próximos dias, qual o espaço ocupado na PLOTU, e mais.',
      isFixed: isMobileOrSmallTablet,
      placement: isMobileOrSmallTablet ? 'center' : undefined,
    },
    {
      target: `#${BACK_OFFICE_PAGES_URLS.EVENTS}-menu-item`,
      content: 'Clica no icon do calendário para acederes aos Eventos',
      prev: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[0].url),
      next: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[1].url),
      location: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[1].url),
    },
    {
      target: isMobileOrSmallTablet ? '.backoffice__content' : '.events',
      content: 'Aqui podes gerir todos os teus eventos e sessões que tens com os teus clientes.\nPodes associar um cliente a um evento e, após a sessão terminar, associar uma galeria com todos os resultados, pronta a partilhar com o cliente.',
      isFixed: isMobileOrSmallTablet,
      placement: isMobileOrSmallTablet ? 'center' : undefined,
    },
    {
      target: '#calendar-cta',
      content: 'Acede aqui à vista Calendário para teres uma visão mensal de todos os teus eventos',
      next: () => changeLocation(createBackOfficeLink(BACK_OFFICE_PAGES_URLS.CALENDAR)),
    },
    {
      target: isMobileOrSmallTablet ? '.backoffice__content' : '.calendar',
      content: 'No calendário podes também criar e gerir os teus eventos.',
      isFixed: isMobileOrSmallTablet,
      placement: isMobileOrSmallTablet ? 'center' : undefined,
      prev: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[1].url),
    },
    {
      target: `#${BACK_OFFICE_PAGES_URLS.PACKS}-menu-item`,
      content: 'Acede aos Packs',
      next: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[2].url),
      location: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[2].url),
      prev: () => changeLocation(createBackOfficeLink(BACK_OFFICE_PAGES_URLS.CALENDAR)),
    },
    {
      target: isMobileOrSmallTablet ? '.backoffice__content' : '.packs',
      content: 'Aqui podes ver e gerir todos os teus Packs. Cria, edita ou esconde-os do teu perfil temporariamente.',
      isFixed: isMobileOrSmallTablet,
      placement: isMobileOrSmallTablet ? 'center' : undefined,
    },
    {
      target: `#${BACK_OFFICE_PAGES_URLS.LAYOUT}-menu-item`,
      content: 'Acede à sub-página Layouts',
      next: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[3].url),
      location: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[3].url),
      prev: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[2].url),
    },
    {
      target: isMobileOrSmallTablet ? '.backoffice__content' : '.layout',
      content: 'Assim que tiveres adicionado fotografias ao teu portefólio, podes edita-lo aqui. Podes apagar e adicionar fotografias ou movê-las no layout que desejares.',
      isFixed: isMobileOrSmallTablet,
      placement: isMobileOrSmallTablet ? 'center' : undefined,
    },
    {
      target: '#layout-change-option',
      content: 'Para moveres as fotografias do layout atual clica em "Editar Layout"',
    },
    {
      target: '#layout-settings-option',
      content: 'Escolhe aqui qual o tipo de layout que pretendes modificar',
    },
    {
      target: `#${BACK_OFFICE_PAGES_URLS.GALLERIES}-menu-item`,
      content: 'Acede à ultima sub-página, as Galerias',
      next: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[4].url),
      prev: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[3].url),
      location: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[4].url),
    },
    {
      target: isMobileOrSmallTablet ? '.backoffice__content' : '.galleries',
      content: 'Nesta sub-página podes ver e gerir todas as tuas Galerias. Todas as galerias vão estar aqui, estejam ou não associadas a um evento.\nAqui, para além de ver as tuas galerias atuais, podes criar, editar, eliminar ou gerir quais as Galerias partilhadas no teu perfil',
      isFixed: isMobileOrSmallTablet,
      placement: isMobileOrSmallTablet ? 'center' : undefined,
    },
    {
      target: '.backoffice__content',
      title: 'Feito',
      content: 'Já está! Agora aproveita e completa o teu perfil',
      isFixed: isMobileOrSmallTablet,
      placement: 'center',
      prev: () => changeLocation(BACK_OFFICE_PAGES_CONFIG[5].url),
    },
  ]

  const handleJoyrideCustomCallback = (data, customAction, newIndex) => {
    const { action, index, lifecycle } = data

    if (customAction === ACTION.NEXT || customAction === ACTION.PREV) {
      updateWalkthroughData(WALKTHROUGH_KEY, {
        index: newIndex,
      })
    } else if (customAction === ACTION.SKIPPED || customAction === ACTION.FINISHED) {
      updateWalkthroughData(WALKTHROUGH_KEY, {
        done: true,
        skiped: customAction === ACTION.SKIPPED,
        finished: customAction === ACTION.FINISHED,
      })
    }

    walkthroughSteps.forEach((step, stepIndex) => {
      if (action === 'next' && step.next && stepIndex === index && lifecycle === 'complete') {
        return step.next()
      }

      if (action === 'prev' && step.prev && stepIndex === index && lifecycle === 'complete') {
        return step.prev()
      }

      if (action === 'update' && step.location && stepIndex === index && lifecycle === 'tooltip') {
        return step.location()
      }
    })

    // if last step pops up automatically, let's ignore it.
    // This can occur in an edge case, when user leave the app in the last step
    if (action === 'next' && lifecycle === 'init' && index === walkthroughSteps.length - 1) {
      updateWalkthroughData(WALKTHROUGH_KEY, {
        index: walkthroughSteps.length,
        done: true,
        skiped: false,
        finished: true,
      })
    }
  }

  const [walkthroughStepIndex, walkthroughIsRunning, walkthroughIsContinuous, handleJoyrideCallback] =
    useWalkthrough(walkthroughData, handleJoyrideCustomCallback, !logedInUser.username)

  return {
    walkthroughStepIndex,
    walkthroughIsRunning,
    walkthroughIsContinuous,
    handleJoyrideCallback,
    walkthroughSteps,
  }
}
