import classNames from 'classnames'
import React from 'react'

import { Text } from 'components/Text'

import './styles.scss'

export const Badge = ({ children, text, small, className, outlined }) => {
  return (
    <div className={classNames('badge', {
      'badge--small': small,
      'badge--outlined': outlined,
    }, className)}
    >
      <Text
        color={outlined ? 'text__primary-color' : 'text__default-color'}
        type={small ? 'body2' : 'body'}
      >
        {text ?? children}
      </Text>
    </div>
  )
}
