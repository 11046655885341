import { Icon } from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import { LoadingComponent } from 'components/LoadingComponent'
import { StorageDoughnut } from 'components/StorageDoughnut'

import './styles.scss'

export const StorageLimitWidget = props => {
  return (
    <div className='storage-widget'>
      <div className='storage-widget__content'>
        {props.iconId && (
          <div
            style={props.iconColor ? { '--storage-widget-icon-color': props.iconColor } : {}}
            className={classNames('storage-widget__icon-wrapper', {
              'storage-widget__icon-wrapper--small': props.smallIcon,
            })}
          >
            <Icon>{props.iconId}</Icon>
          </div>
        )}
        <div className='storage-widget__content__title-value'>
          <h5 className='storage-widget__content__title'>{props.title}</h5>
          <div className='storage-widget__content__value'>{props.value}</div>
        </div>
      </div>
      <StorageDoughnut isLoading={props.isLoading} storage={props.storage} />
      <LoadingComponent
        isLoading={props.isLoading}
        size={60}
        className='storage-widget__loading'
      />
    </div>
  )
}
