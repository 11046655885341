import moment from 'moment'
import React, { useState } from 'react'

import { SimpleMessageModal } from 'components/Modal'
import { UploadFeedback } from 'components/UploadFeedback'

import { Comment } from '../../../Comment'
import { currentDateText } from '../../../../utils/date'

import { useModal } from 'hooks/useModal'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import './styles.scss'

export const Review = props => {
  const {
    isAdmin,
    review,
    reviewReply,
    isCommentOwner,
    currentPack,
    onRemoveReview,
    onRemoveReviewReply,
    uploadingStatus,
    uploadingReplyStatus,
    onEditReview,
    onReviewReply,
    onReviewReplyEdit,
  } = props
  const [isReplying, setIsReplying] = useState(false)
  const [editedRating, setEditedRating] = useState(review.rating)
  const [modalIsOpen, openModal, closeModal, modalContext] = useModal()

  const onEdit = editedReview => {
    // TODO: Save comment history on server
    onEditReview(review, { review: editedReview, rating: editedRating })
  }

  const onReply = reply => {
    if (reviewReply?.createdTimestamp) {
      onReviewReplyEdit(review, reply)
    } else {
      onReviewReply(review, reply)
    }
  }

  const onRatingChange = newRating => {
    setEditedRating(newRating)
  }

  const onClickRemoveReview = () => openModal(0)
  const onClickRemoveReviewReply = () => openModal(1)

  const removeReview = () => {
    if (modalContext === 0) {
      onRemoveReview()
    } else if (modalContext === 1) {
      onRemoveReviewReply()
    }
    closeModal()
  }

  const hasReply = !!reviewReply

  return (
    <div className={`review${props.className ? ' ' + props.className : ''}`}>
      <Comment
        date={review.createdTimestamp && currentDateText(moment.unix(review.createdTimestamp))}
        rating={review.rating}
        comment={review.review}
        user={{
          uid: review.user.uid,
          name: review.user.name,
          avatar: review.user.avatar,
        }}
        canReply={isAdmin && !hasReply}
        canEdit={isCommentOwner}
        canSetRating
        onClickReply={() => setIsReplying(true)}
        onEdit={onEdit}
        onRatingChange={onRatingChange}
        onClickRemove={onClickRemoveReview}
      />
      {uploadingStatus && (
        <div className='review__upload-feedback'>
          <UploadFeedback
            uploading={uploadingStatus === UPLOAD_STATUS.UPLOADING}
            uploaded={uploadingStatus === UPLOAD_STATUS.UPLOADED}
          />
        </div>
      )}
      {(hasReply || isReplying) && (
        <div className='review__reply'>
          <Comment
            date={reviewReply?.createdTimestamp && currentDateText(moment.unix(reviewReply.createdTimestamp))}
            comment={reviewReply?.review || ''}
            user={{
              uid: currentPack.user?.uid,
              name: currentPack.user?.name,
              avatar: currentPack.user?.avatar,
            }}
            isEdited={reviewReply?.isEdited}
            onEdit={onReply}
            canEdit={isAdmin}
            editMode={!hasReply && isReplying}
            onEditCancel={() => setIsReplying(false)}
            onClickRemove={onClickRemoveReviewReply}
          />
          {uploadingReplyStatus && (
            <div className='review__upload-feedback'>
              <UploadFeedback
                uploading={uploadingReplyStatus === UPLOAD_STATUS.UPLOADING}
                uploaded={uploadingReplyStatus === UPLOAD_STATUS.UPLOADED}
              />
            </div>
          )}
        </div>
      )}
      <SimpleMessageModal
        open={modalIsOpen}
        closeModal={closeModal}
        modalTitle='Eliminar comentário'
        message='Tens a certeza que pretendes eliminar o teu comentário?'
        actionButtonText='Eliminar'
        secondaryActionButtonText='Cancelar'
        actionButtonColor='danger'
        secondaryActionButtonVariant='outlined'
        secondaryActionButtonColor='default'
        onActionButton={removeReview}
        onSecondaryActionButton={closeModal}
      />
    </div>
  )
}
