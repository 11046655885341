import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { Text } from 'components/Text'

import './styles.scss'

export const UserType = props => {
  const [ checkedIndex, setCheckedIndex ] = useState(props.initialValue ?? -1)

  useEffect(() => {
    if (props.initialValue !== checkedIndex) {
      setCheckedIndex(props.initialValue ?? -1)
    }
  }, [checkedIndex, props.initialValue])

  const onInputChange = index => () => {
    setCheckedIndex(index)
    props.onChange && props.onChange(index)
  }

  return (
    <div className={classNames('user-type', props.className)}>
      {props.content.map((item, index) => {
        return (
          <div
            key={item.title}
            className={classNames('user-type__item', {
              'user-type__item--active': checkedIndex === index,
            })}
            onClick={onInputChange(index)}
          >
            <div>
              <Text as='h3' type='title4' className='user-type__item__title'>{item.title}</Text>
              <Text type='body' className='user-type__item__content'>{item.description}</Text>
            </div>
            <div className='user-type__input-wrapper'>
              <input
                onChange={onInputChange(index)}
                className='user-type__input'
                type='checkbox'
                id={`_checkbox${index}`}
                checked={checkedIndex === index}
              />
              <label className='user-type__ticker' htmlFor={`_checkbox${index}`}>
                <div className='user-type__ticker__tick-mark' />
              </label>
            </div>
          </div>
        )
      })}
    </div>
  )
}
