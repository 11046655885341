import { useState } from 'react'

export const useModal = () => {
  const [modalContext, setModalContext] = useState()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = context => {
    if (context !== undefined) {
      setModalContext(context)
    }
    setModalIsOpen(true)
  }

  const closeModal = () => {
    if (modalContext) {
      setModalContext(undefined)
    }
    setModalIsOpen(false)
  }

  return [modalIsOpen, openModal, closeModal, modalContext]
}
