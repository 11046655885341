import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { NOTIFICATION_TYPE } from 'components/GlobalNotifications'
import { Image } from 'components/Image'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { MFASignInForm } from 'components/MFASignInForm'
import { SignUpForm } from 'components/SignUpForm'

import { userExists, loginUser, registerUser, requestPasswordReset } from 'services/authenticationService'
import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'
import { createHomeLink } from 'services/routingService'

import { getErrorMessage, getErrorType } from 'utils/signUp'

import './styles.scss'
import { logError } from 'utils/errorCapture'
import { resetMFA } from 'services/firebaseServer'

const signUpImage = getConfigValue('sign_up.image')

const notificationSystem = getNotificationSystem()

const SIGN_UP_STEP = {
  EmailInput: 'EmailInput',
  SignIn: 'SignIn',
  Register: 'Register',
  RecoverPassword: 'RecoverPassword',
  RecoverPasswordSent: 'RecoverPasswordSent',
  MFAAuthentication: 'MFAAuthentication',
  ResetMFA: 'ResetMFA',
}

export const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState()
  const [name, setName] = useState()
  const [nameError, setNameError] = useState()
  const [password, setPasword] = useState()
  const [passwordError, setPaswordError] = useState()
  const [mfaRecoveryCode, setMfaRecoveryCode] = useState()
  const [mfaRecoveryCodeError, setMfaRecoveryCodeError] = useState()
  const [signUpStep, setSignUpStep] = useState(SIGN_UP_STEP.EmailInput)
  const [persistState, setPersistState] = useState(true)
  const [ MFAErrorContext, setMFAErrorContext ] = useState()
  const hasUserLogedIn = useSelector(state => state.users.currentUser)
  const notified = useRef(false)
  const history = useHistory()

  useEffect(() => {
    // redirect to home page in case user is already signed in
    // the "!email" is preventing redirecting to home page after the user signs in
    if (hasUserLogedIn && !notified.current && !email) {
      notified.current = true
      notificationSystem.notify({
        message: 'Utilizador já está autenticado!',
        type: NOTIFICATION_TYPE.SUCCESS,
      })
      history.push(createHomeLink())
    }
  }, [hasUserLogedIn, history, email])

  const onEmailChange = event => {
    if (emailError) {
      setEmailError(null)
    }

    // if user had already inserted an email, is in register phase and changes the email, let's reset the flow to check again if the new email exists
    if (signUpStep === SIGN_UP_STEP.Register) {
      resetFlow()
    }

    setEmail(event.target.value)
  }

  const onNameChange = event => {
    if (nameError) {
      setNameError(null)
    }
    setName(event.target.value)
  }

  const onPasswordChange = event => {
    if (passwordError) {
      setPaswordError(null)
    }
    setPasword(event.target.value)
  }

  const onMfaRecoveryCodeChange = event => {
    if (mfaRecoveryCodeError) {
      setMfaRecoveryCodeError(null)
    }
    setMfaRecoveryCode(event.target.value)
  }

  const resetErrors = () => {
    setNameError(null)
    setEmailError(null)
    setPaswordError(null)
    setMFAErrorContext(undefined)
  }

  const onSendPasswordResetEmail = event => {
    event.preventDefault()
    event.stopPropagation()

    if (!email) {
      setEmailError(t('sign_up.error.invalid_email'))
      return
    }

    setIsLoading(true)
    requestPasswordReset(email)
      .then(() => {
        setIsLoading(false)
        setSignUpStep(SIGN_UP_STEP.RecoverPasswordSent)
      })
      .catch(setErrors)
    return
  }

  const onResetMfa = event => {
    event.preventDefault()
    event.stopPropagation()

    if (!mfaRecoveryCode) {
      setMfaRecoveryCodeError(t('sign_up.error.invalid_recover_code'))
      return
    }

    setIsLoading(true)

    return resetMFA(email, password, mfaRecoveryCode)
      .then(() => {
        setIsLoading(false)
        setSignUpStep(SIGN_UP_STEP.EmailInput)
        notificationSystem.notify({
          message: 'Autenticação Multifator restabelecida. Podes tentar novamente.',
          type: NOTIFICATION_TYPE.SUCCESS,
        })
      })
      .catch(setErrors)
  }

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    resetErrors()

    if (signUpStep === SIGN_UP_STEP.EmailInput) {
      if (!email) {
        setEmailError(t('sign_up.error.invalid_email'))
        return
      }

      setIsLoading(true)
      userExists(email)
        .then(value => {
          setIsLoading(false)
          if (value) {
            setSignUpStep(SIGN_UP_STEP.SignIn)
          } else {
            setSignUpStep(SIGN_UP_STEP.Register)
          }
        })
        .catch(setErrors)
      return
    }

    if (signUpStep === SIGN_UP_STEP.Register && !name) {
      setNameError(t('sign_up.error.invalid_name'))
      return
    }

    if ((signUpStep === SIGN_UP_STEP.SignIn || signUpStep === SIGN_UP_STEP.Register) && !email) {
      setEmailError(t('sign_up.error.invalid_email'))
      return
    }

    if ((signUpStep === SIGN_UP_STEP.SignIn || signUpStep === SIGN_UP_STEP.Register) && !password) {
      setPaswordError(t('sign_up.error.invalid_password'))
      return
    }

    setIsLoading(true)
    if (signUpStep === SIGN_UP_STEP.Register) {
      register()
    } else {
      login()
    }
  }

  const resetFlow = () => {
    resetErrors()
    setIsLoading(false)
    setSignUpStep(SIGN_UP_STEP.EmailInput)
  }

  const onProblemsSigningIn = () => {
    setSignUpStep(SIGN_UP_STEP.RecoverPassword)
  }

  const onProblemsWithMFA = () => {
    setSignUpStep(SIGN_UP_STEP.ResetMFA)
  }

  const register = () => {
    registerUser(email, password, name, history)
      .catch(setErrors)
  }

  const login = () => {
    loginUser(email, password, persistState, history)
      .catch(error => {
        if (error.code === 'auth/multi-factor-auth-required') {
          setIsLoading(false)
          setSignUpStep(SIGN_UP_STEP.MFAAuthentication)
          setMFAErrorContext(error)
          return
        }

        setErrors(error)
      })
  }

  const onPersistStateToggle = () => {
    setPersistState(!persistState)
  }

  const setErrors = error => {
    // list of errors: https://firebase.google.com/docs/auth/admin/errors

    setIsLoading(false)
    logError(error)

    const type = getErrorType(error)
    const errorMessage = getErrorMessage(error)
    if (type === 'password') {
      setPaswordError(errorMessage)
    } else if (type === 'name') {
      setNameError(errorMessage)
    } else {
      setEmailError(errorMessage)
    }
  }

  const getSignUpFormData = () => {
    const emailTextField = {
      id: 'email-text-field',
      onChange: onEmailChange,
      error: emailError,
      helperText: emailError,
      label: t('sign_up.email_input'),
      type: 'email',
      autoComplete: 'email',
    }

    const nameTextField = {
      id: 'name-text-field',
      onChange: onNameChange,
      error: nameError,
      helperText: nameError,
      label: t('sign_up.name_input'),
      type: 'text',
      autoComplete: 'cc-name',
    }

    const passwordTextField = {
      id: 'password-text-field',
      onChange: onPasswordChange,
      error: passwordError,
      helperText: passwordError,
      label: t('sign_up.password_input'),
      type: 'password',
    }

    const mfaRecoveryCodeTextField = {
      id: 'mfa-recovery-code-text-field',
      onChange: onMfaRecoveryCodeChange,
      error: mfaRecoveryCodeError,
      helperText: mfaRecoveryCodeError,
      label: t('sign_up.mfa_recovery_code_input'),
      type: 'text',
    }

    if (signUpStep === SIGN_UP_STEP.ResetMFA) {
      return {
        title: t('sign_up.reset_mfa.title'),
        text: t('sign_up.reset_mfa.reset_instructions'),
        textFields: [mfaRecoveryCodeTextField],
        onSubmit: onResetMfa,
        secondaryActionButton: {
          onClick: resetFlow,
          label: t('sign_up.action_button.back'),
        },
        actionButtonText: t('sign_up.action_button.reset_mfa'),
      }
    }

    if (signUpStep === SIGN_UP_STEP.RecoverPassword) {
      return {
        title: t('sign_up.recover_password.title'),
        text: t('sign_up.recover_password.recover_instructions'),
        textFields: [emailTextField],
        onSubmit: onSendPasswordResetEmail,
        secondaryActionButton: {
          onClick: resetFlow,
          label: t('sign_up.action_button.back'),
        },
        actionButtonText: t('sign_up.action_button.recover_password'),
      }
    }

    if (signUpStep === SIGN_UP_STEP.RecoverPasswordSent) {
      return {
        title: t('sign_up.password_recover_email_sent.title'),
        text: t('sign_up.password_recover_email_sent.text', { email }),
        onSubmit: resetFlow,
        actionButtonText: t('sign_up.password_recover_email_sent.submit_text'),
      }
    }

    if (signUpStep === SIGN_UP_STEP.EmailInput) {
      return {
        title: t('sign_up.title'),
        textFields: [emailTextField],
        onSubmit,
        actionButtonText: t('sign_up.action_button.next'),
      }
    }

    if (signUpStep === SIGN_UP_STEP.SignIn) {
      return {
        title: t('sign_up.title'),
        textFields: [
          emailTextField,
          passwordTextField,
        ],
        secondaryActionButton: {
          onClick: resetFlow,
          label: t('sign_up.action_button.back'),
        },
        onSubmit,
        actionButtonText: t('sign_up.action_button.sign_in'),
        helperLink: {
          onClick: onProblemsSigningIn,
          text: t('sign_up.action_button.trouble_signin_in'),
        },
      }
    }

    if (signUpStep === SIGN_UP_STEP.Register) {
      return {
        title: t('sign_up.title'),
        textFields: [
          emailTextField,
          nameTextField,
          passwordTextField,
        ],
        description: <Text>{t('sign_up.terms_conditions_privacy_policy')}</Text>,
        secondaryActionButton: {
          onClick: resetFlow,
          label: t('sign_up.action_button.back'),
        },
        actionButtonText: t('sign_up.action_button.register'),
        onSubmit,
      }
    }
  }

  return (
    <div className='sign-up'>
      <Logo className='sign-up__logo' />
      <div className='sign-up__content'>
        <div className='sign-up__content--left'>
          <Image
            className='sign-up__content__image'
            src={signUpImage.src}
            alt={signUpImage.alt}
            thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
          />
        </div>
        <div className='sign-up__content--right'>
          {signUpStep !== SIGN_UP_STEP.MFAAuthentication && (
            <div className='sign-up__form-wrapper'>
              <SignUpForm
                {...getSignUpFormData()}
                persistState={persistState}
                onPersistStateToggle={onPersistStateToggle}
                isLoading={isLoading}
                isRegister={signUpStep === SIGN_UP_STEP.Register}
                isSignIn={signUpStep === SIGN_UP_STEP.SignIn}
              />
            </div>
          )}
          {signUpStep === SIGN_UP_STEP.MFAAuthentication && (
            <div className='sign-up__form-wrapper'>
              <MFASignInForm
                signInError={MFAErrorContext}
                secondaryActionButton={{
                  onClick: resetFlow,
                  label: t('sign_up.action_button.back'),
                }}
                helperLink={{
                  onClick: onProblemsWithMFA,
                  text: t('sign_up.action_button.trouble_with_mfa'),
                }}
                history={history}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SignUp
