import { useSelector } from 'react-redux'

import { getUserGalleriesFromState } from '../utils/userGalleriesUtils'
import { getUserPacksFromState } from '../utils/userPackUtils'

import { portfolioDataByBreakpointSelector } from 'store/users/selectors'

const findUserByUsername = (users, username) => {
  const userKey = Object.keys(users).find(userKey => users[userKey].username === username)
  return users[userKey]
}

export const useUserDetailsSelector = ({ userId, username, breakpoint }) => {
  const loading = useSelector(state => state.users.loading)
  const user = useSelector(state => state.users.users[userId] || findUserByUsername(state.users.users, username))
  const currentUser = useSelector(state => state.users.users[state.users.currentUser?.uid])
  const isOwnerUser = (userId !== undefined && currentUser?.uid === userId) ||
    (username !== undefined && currentUser?.username === username)
  const userPorfolio = useSelector(portfolioDataByBreakpointSelector(breakpoint, userId))
  const userPacks = useSelector(state => getUserPacksFromState(state, user))
  const userGalleries = useSelector(state => getUserGalleriesFromState(state, user))

  const userPacksLoading = useSelector(state => state.packs.loading)

  return {
    loading,
    user,
    isOwnerUser,
    userPorfolio,
    userPacks,
    userGalleries,
    userPacksLoading,
  }
}
