export const MOCK_CUSTOMERS = {
  exhaustiveNbHits: true,
  hits: [
    {
      name: 'Customer1',
      userUid: 'user-uid-mock',
      email: 'customer1@email.com',
      photographersWithJobs: ['photographer1'],
      imgSrc: 'https://media.wired.com/photos/5cdefc28b2569892c06b2ae4/master/w_2560%2Cc_limit/Culture-Grumpy-Cat-487386121-2.jpg',
      objectID: '1248020',
    },
  ],
  hitsPerPage: 20,
  index: 'dev_Customers',
  nbHits: 0,
  nbPages: 0,
  page: 0,
  params: 'query=ph&',
  processingTimeMS: 1,
  query: 'ph',
}
