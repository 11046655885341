import { Select, Alert as MUIAlert, Checkbox, Collapse, FormControl, FormControlLabel, MenuItem, Tooltip, Slider } from '@mui/material'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import React, { Fragment, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Alert } from 'components/Alert'
import { ImageListInput } from 'components/ImageListInput'
import { ModalSection } from 'components/Modal'
import { ModalActionButtons } from 'components/ModalActionButtons'
import { Text } from 'components/Text'

import { useAlert } from 'hooks/useAlert'

import { t } from 'services/i18n'

import './styles.scss'

const PositionItem = ({ label, onClick, selected }) => {
  return (
    <Tooltip enterDelay={900} title={label}>
      <div
        onClick={onClick}
        className={classNames('watermark-input__options__scheme-item', {
          'watermark-input__options__scheme-item--selected': selected,
        })}
      />
    </Tooltip>
  )
}

const positions = [
  { key: 'top_left', label: 'Canto superior esquerdo' },
  { key: 'top_center', label: 'Centro superior' },
  { key: 'top_right', label: 'Canto superior direito' },
  { key: 'center_left', label: 'Centro esquerdo' },
  { key: 'center', label: 'Centro' },
  { key: 'center_right', label: 'Centro direito' },
  { key: 'bottom_left', label: 'Canto inferior esquerdo' },
  { key: 'bottom_center', label: 'Centro inferior' },
  { key: 'bottom_right', label: 'Canto inferior direito' },
]

const getBackgroundSize = (size, watermarkImage) => {
  if (size === 'expanded') {
    return 'contain'
  }
  if (size === 'original') {
    if (watermarkImage.width > 400 || watermarkImage.height > 260) {
      return 'contain'
    }

    return '100%'
  }

  if (size === 'small') {
    return '25%'
  }

  if (size === 'medium') {
    return '50%'
  }

  if (size === 'big') {
    return '75%'
  }
}

export const WatermarkInput = props => {
  const [ name, setName ] = useState(props.initialWatermark?.name)
  const [ positionKey, setPositionKey ] = useState(props.initialWatermark?.options?.position || 'center')
  const [ positionRepeat, setPositionRepeat ] = useState(props.initialWatermark?.options?.repeat ?? false)
  const [ size, setSize ] = useState(props.initialWatermark?.options?.size ?? 'original')
  const [ opacity, setOpacity ] = useState(props.initialWatermark?.options?.opacity ?? 100)
  const [ watermarkImage, setWatermarkImage ] = useState(props.initialWatermark?.image)
  const [ alertIsOpen, openAlert ] = useAlert()

  const galleryCount = Object.keys(props.initialWatermark?.galleries || {}).length

  const editingWatermark = !!props.initialWatermark

  const onNameChange = event =>
    setName(event.target.value)

  const onImagesChange = newWatermarkImage => {
    setWatermarkImage(newWatermarkImage)
  }

  const addWatermark = () => {
    const newWatermark = {
      id: props.initialWatermark?.id || uuidv4(),
      name,
      image: watermarkImage,
      options: {
        position: positionKey,
        repeat: positionRepeat,
        size,
        opacity,
      },
    }

    if (!name) {
      openAlert()
      return
    }

    props.onAddWatermark && props.onAddWatermark(newWatermark)
  }

  const onPositionChange = newPositionKey => () => {
    setPositionKey(newPositionKey)
  }

  const onPositionRepeatChange = event => {
    setPositionRepeat(event.target.checked)
  }

  const onSizeChange = event => {
    setSize(event.target.value)
  }

  const handleChangeOpacity = (_event, newValue) => {
    if (typeof newValue === 'number') {
      setOpacity(newValue)
    }
  }

  return (
    <Fragment>
      <ModalSection title='Nome'>
        <TextField
          className='watermark-input__title'
          value={name}
          onChange={onNameChange}
        />
        <Collapse className='appointment__alert' in={alertIsOpen}>
          <MUIAlert severity='warning'>{t('profile_settings.personalisation_settings.watermark.input.title_missing')}</MUIAlert>
        </Collapse>
      </ModalSection>

      <ModalSection title='Imagem' subTitle={!props.initialWatermark && t('profile_settings.personalisation_settings.watermark.input.image_helper')}>
        <ImageListInput
          preventDeleteOption={editingWatermark}
          initialImages={watermarkImage ? [watermarkImage] : undefined}
          onChange={onImagesChange}
          onMainImageIdChange={() => {}}
          acceptOptions='.png'
          imageWrapperClassName='watermark-input__image-wrapper'
        />
      </ModalSection>

      <ModalSection title='Opções' last>
        <div className='watermark-input__options__position'>
          <div className='watermark-input__options__scheme-wrapper'>
            <Text type='body' color='text__secondary-color'>Posição da marca de água</Text>
            <div className='watermark-input__options__scheme'>
              {positions
                .map(position => (
                  <PositionItem
                    key={position.key}
                    label={position.label}
                    onClick={onPositionChange(position.key)}
                    selected={positionKey === position.key}
                  />
                ))}
            </div>
            <div className='watermark-input__options__extra-positions'>
              <FormControlLabel
                onChange={onPositionRepeatChange}
                checked={positionRepeat}
                value='repeat'
                control={<Checkbox />}
                label='Repetida'
              />
            </div>
            <div className='watermark-input__options__size-select-wrapper'>
              <Text type='body' color='text__secondary-color'>Tamanho</Text>
              <FormControl variant='standard' className='watermark-input__options__size-select'>
                <Select
                  value={size}
                  onChange={onSizeChange}
                >
                  <MenuItem value='original'>Original</MenuItem>
                  <MenuItem value='small'>Pequeno</MenuItem>
                  <MenuItem value='medium'>Médio</MenuItem>
                  <MenuItem value='big'>Grande</MenuItem>
                  <MenuItem value='expanded'>Expandir</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='watermark-input__options__opacity-slider'>
              <Text type='body' color='text__secondary-color'>Opacidade</Text>
              <Slider
                aria-label='Opacidade'
                value={opacity}
                valueLabelDisplay='auto'
                step={10}
                marks
                min={10}
                max={100}
                size='small'
                onChange={handleChangeOpacity}
              />
            </div>
          </div>
          <div className='watermark-input__options__position-example'>
            {watermarkImage && (
              <div
                style={{
                  backgroundImage: `url("${watermarkImage.src}")`,
                  backgroundSize: getBackgroundSize(size, watermarkImage),
                  opacity: opacity / 100,
                }}
                className={
                  classNames('watermark-input__options__position-example__image-wrapper', `watermark-input__options__position-example__image-wrapper--${positionKey}`, {
                    'watermark-input__options__position-example__image-wrapper--repeat': positionRepeat,
                  })
                }
              />
            )}
          </div>
        </div>
        {galleryCount > 0 && (
          <Alert variant='text' severity='info'>
            <Text type='body2' color='text__secondary-color'>
              As alterações feitas não terão efeito em fotografias onde esta marca de água já está aplicada
            </Text>
          </Alert>
        )}
      </ModalSection>

      <ModalSection footer>
        <ModalActionButtons
          secondaryButton={{
            action: props.onCancel,
            label: t('profile_settings.personalisation_settings.watermark.input.cancel'),
          }}
          primaryButton={{
            action: addWatermark,
            label: editingWatermark ? t('profile_settings.personalisation_settings.watermark.input.edit_watermark_cta') : t('profile_settings.personalisation_settings.watermark.input.add_watermark_cta'),
          }}
        />
      </ModalSection>
    </Fragment>
  )
}
