import { useEffect } from 'react'

const DEFAULT_TITLE = 'PLOTU: Galerias de Fotos Online para Fotógrafos Profissionais e Amadores'

export const useDocumentTitle = (title = DEFAULT_TITLE) => {
  useEffect(() => {
    document.title = title

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [title])
}
