import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getConfigValue } from 'services/configService'

export const initializeErrorLoggingService = () => {
  Sentry.init({
    dsn: getConfigValue('general.endpoints.sentry.dsn'),
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_STAGE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

