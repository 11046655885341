import { useEffect, useState } from 'react'

export const useOnClickOutside = (ref, handler, listening) => {
  const [ touchMoving, setTouchMoving ] = useState(false)

  const setMoving = () => {
    setTouchMoving(true)
  }

  useEffect(
    () => {
      const listener = event => {
        event.stopPropagation()
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        listening !== false && handler(event)
      }

      const removeEventListeners = () => {
        document.removeEventListener('click', listener)
        window.removeEventListener('mouseUp', listener)
        window.addEventListener('touchend', listener)
        window.removeEventListener('touchmove', setMoving)
      }

      if (listening === false || !ref.current) {
        removeEventListeners()
        return
      }

      document.addEventListener('click', listener)
      window.addEventListener('mouseUp', listener)
      window.addEventListener('touchend', listener)
      window.addEventListener('touchmove', setMoving)

      return removeEventListeners
    },

    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, touchMoving, listening]
  )
}
