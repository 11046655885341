const agendas = {
  'klc102oFTUggLJ2uz6rxW32Hi1H2': {
    workingHours: {
      startWorkingHours: '09:00',
      endWorkingHours: '21:00',
    },
    events: {
      'kdfdfdsf30e': {
        id: 'kdfdfdsf30e',
        eventType: 'EVENT',
        startDate: '2021-01-20T09:45',
        endDate: '2021-01-28T11:00',
        packId: 'packId1',
        title: 'long meeting janeiro',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'kdfdf30e': {
        id: 'kdfdf30e',
        eventType: 'EVENT',
        startDate: '2021-02-20T09:45',
        endDate: '2021-02-28T11:00',
        packId: 'packId1',
        title: 'long meeting fevereiro',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'kdfdf232330e': {
        id: 'kdfdf232330e',
        eventType: 'EVENT',
        startDate: '2021-02-20T09:45',
        endDate: '2021-03-04T11:00',
        packId: 'packId1',
        title: 'Meeting until this month',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'fdfgkemfvkl': {
        id: 'fdfgkemfvkl',
        eventType: 'EVENT',
        startDate: '2021-02-28T09:45',
        endDate: '2021-02-28T11:00',
        packId: 'packId1',
        title: 'Meeting past month',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },

      'eiqwdcbfgfyuhdjnsa': {
        id: 'eiqwdcbfgfyuhdjnsa',
        eventType: 'EVENT',
        startDate: '2021-03-12T09:45',
        endDate: '2021-03-14T11:00',
        packId: 'packId1',
        title: 'Meeting',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'adskjmewidjkmsajndfs': {
        id: 'adskjmewidjkmsajndfs',
        eventType: 'EVENT',
        startDate: '2021-03-14T23:30',
        endDate: '2021-03-15T23:45',
        title: 'Sessão Fotográfica',
        packId: 'packId2',
        location: {
          address: 'Quinta das Conchas, Lisboa',
        },
        user: {
          name: 'user name',
        },
      },
      'eiqwdcbfgfyuhdjnsa2': {
        id: 'eiqwdcbfgfyuhdjnsa2',
        eventType: 'EVENT',
        startDate: '2021-03-15T09:45',
        endDate: '2021-03-16T11:00',
        packId: 'packId1',
        title: 'Sessão Fotográfica e Filme',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'eiqwdcbfgfyuhdjnsa223': {
        id: 'eiqwdcbfgfyuhdjnsa223',
        eventType: 'EVENT',
        startDate: '2021-03-15T16:45',
        endDate: '2021-03-15T17:00',
        packId: 'packId1',
        title: 'Sessão Filme',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'eiqwdcbfgfyuhdjnsa2jn': {
        id: 'eiqwdcbfgfyuhdjnsa2jn',
        eventType: 'EVENT',
        startDate: '2021-03-15T09:45',
        endDate: '2021-03-15T11:00',
        packId: 'packId1',
        title: 'Sessão tudo',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'hjdkalsfgjh': {
        id: 'hjdkalsfgjh',
        eventType: 'EVENT',
        startDate: '2021-03-12T09:45',
        endDate: '2021-03-13T11:00',
        packId: 'packId1',
        title: 'Meeting 2',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
      },
      'eiqwdcbfgfda23sdyuhdjnsa': {
        id: 'eiqwdcbfgfda23sdyuhdjnsa',
        eventType: 'EVENT',
        startDate: '2021-03-12T09:45',
        endDate: '2021-03-13T11:00',
        packId: 'packId1',
        title: 'Meeting 2',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
        galleries: {
          'eiqwdcbfgfda23sdyuhdjnsa_default': {
            default: true,
            id: 'eiqwdcbfgfda23sdyuhdjnsa_default',
          },
        },
      },
      'eiqwdcb23432fgfda23sdyuhdjnsa': {
        id: 'eiqwdcb23432fgfda23sdyuhdjnsa',
        eventType: 'EVENT',
        startDate: '2021-03-30T09:45',
        endDate: '2021-04-04T11:00',
        packId: 'packId1',
        title: 'April meeting 2',
        userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
        location: {
          address: 'Quinta das Conchas, Lisboa',
        },
        description: 'Lorem ipsum description very interesting',
        galleries: {
          'eiqwdcbfgfda23sdyuhdjnsa_default': {
            default: true,
            id: 'eiqwdcbfgfda23sdyuhdjnsa_default',
          },
          'fhbjfuhgjfnkjbdhnk': {
            id: 'fhbjfuhgjfnkjbdhnk',
          },
        },
      },
    },
  },
}

export default agendas
