import classNames from 'classnames'
import React from 'react'

import { Link } from 'components/Link'

import logoSimple from 'assets/logo-simple.png'
import plotuStudioLogoAlt from 'assets/plotu-studio-logo-alt.png'
import plotuStudioLogoPrimary from 'assets/plotu-studio-logo-primary.png'
import plotuStudioLogo from 'assets/plotu-studio-logo.png'

import { t } from 'services/i18n'
import { createBackOfficeLink } from 'services/routingService'

import './styles.scss'

export const BackOfficeLogo = props => {
  if (props.alt) {
    return (
      <img className={props.className} src={plotuStudioLogo} alt='logo' />
    )
  }

  if (props.primary) {
    return (
      <img className={classNames('back-office-logo', props.className)} src={plotuStudioLogoPrimary} alt='logo' />
    )
  }

  return (
    <img className={classNames('back-office-logo', props.className)} src={plotuStudioLogoAlt} alt='logo' />
  )
}

export const BackOfficeLogoLink = props => {
  const { className: customClassname, ...otherProps } = props
  return (
    <Link className={classNames('back-office-logo-link', customClassname)} href={createBackOfficeLink()} {...otherProps}>
      <img className='back-office-logo-link__image' src={logoSimple} alt='logo' />
      <span>{t('components.back_office_logo.title')}</span>
    </Link>
  )
}
