import classNames from 'classnames'
import React from 'react'

import { Link } from 'components/Link'

import logoSimple from 'assets/logo-simple.png'

import { createHomeLink } from 'services/routingService'

import './styles.scss'

export const Logo = props => {
  return (
    <Link
      href={createHomeLink(false, ['q', 'v'])}
      className={classNames(props.className, {
        'logo--light': props.light,
      })}
    >
      <img src={logoSimple} alt='logo' />
    </Link>
  )
}
