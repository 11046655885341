import React from 'react'

import { Link } from 'components/Link'
import { Text } from 'components/Text'

import './styles.scss'

export const Breadcrumb = props => {
  return (
    <div className='breadcrumb'>
      {props.items.map((item, index) => {
        const preChar = index === 0 ? '' : '/  '
        const title = item.title
        const itemName = preChar + title

        if (item.url && title) {
          return (
            <Link key={item.title} href={item.url}>
              <Text as={index === 0 ? 'h1' : 'h5'} type={index === 0 ? 'title3' : 'subtitle'}>{itemName}</Text>
            </Link>
          )
        }

        return title
          ? <Text key={item.title} as={index === 0 ? 'h1' : 'h5'} type={index === 0 ? 'title3' : 'subtitle'}>{itemName}</Text>
          : null
      })}
    </div>
  )
}
