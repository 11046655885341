import MUIGrid from '@mui/material/Grid'
import classNames from 'classnames'
import React, { Children } from 'react'

import { useBreakpoints } from 'hooks/useBreakpoints'

import './styles.scss'

const getNearestBreakpoint = (xs, sm, md, lg, xl) => {
  if (xl) {
    return xl
  }

  if (lg) {
    return lg
  }

  if (md) {
    return md
  }

  if (sm) {
    return sm
  }

  if (xs) {
    return xs
  }
}

const getItemsPerRow = (breakpoints, xs, sm, md, lg, xl) => {
  const { isMobile, isSmallTablet, isTablet, isDesktop, isLargeDesktopAndUp } = breakpoints

  if (isMobile) {
    return 12 / xs
  }

  if (isSmallTablet) {
    return 12 / getNearestBreakpoint(xs, sm)
  }

  if (isTablet) {
    return 12 / getNearestBreakpoint(xs, sm, md)
  }

  if (isDesktop) {
    return 12 / getNearestBreakpoint(xs, sm, md, lg)
  }

  if (isLargeDesktopAndUp) {
    return 12 / getNearestBreakpoint(xs, sm, md, lg, xl)
  }
}

const renderGridItem = (spacing, itemsPerRow, xs, sm, md, lg, xl, className) => (item, index) => {

  // using custom spacing system as the default one is not so well implemented
  //  it causes width to be gigger than parent and ads padding where it is not needed
  // Also, using JS to set paddingTop and paddingLeft extra checks because we cannot use
  //  itemsPerRow CSS variable inside selectors (such as nth-child)
  const style = spacing
    ? {
      '--grid-item-spacing': `${spacing}px`,
      paddingTop: index >= itemsPerRow ? `${spacing}px` : 0,
    }
    : {}

  if (spacing && index % itemsPerRow === 0) {
    style.paddingLeft = 0
  }

  return (
    <MUIGrid
      className={classNames(className, {
        'grid__item--with-spacing': spacing && itemsPerRow > 1,
      })}
      style={style}
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      {item}
    </MUIGrid>
  )
}

/**
 * source: https://material-ui.com/api/grid/
 *
 * Breakpoints: xs, sm, md, lg, xl
 * values: false, 'auto', true ( == 'auto'), 1 - 12
 *
 * The Auto-layout makes the items equitably share the available space.
 * That also means you can set the width of one item and the others will automatically resize around it.
 * To achieve this, just pass the breakpoint to have auto-layout as true (ex: <Grid xs>)
 */
export const Grid = props => {
  const { xs, sm, md, lg, xl, spacing, itemClassName, ...otherProps } = props

  const breakpoints = useBreakpoints()

  const itemsPerRow = getItemsPerRow(breakpoints, xs, sm, md, lg, xl)

  return (
    <MUIGrid container {...otherProps}>
      {props.items
        ? props.items.map(renderGridItem(spacing, itemsPerRow, xs, sm, md, lg, xl, itemClassName))
        : Children.map(props.children, renderGridItem(spacing, itemsPerRow, xs, sm, md, lg, xl, itemClassName))}
    </MUIGrid>
  )
}
