import classNames from 'classnames'
import React from 'react'

import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { LoadingComponent } from 'components/LoadingComponent'
import { Text } from 'components/Text'
import { TextField } from 'components/TextField'

import { t } from 'services/i18n'

import './styles.scss'
import { Checkbox, FormControlLabel } from '@mui/material'

export const SignUpForm = ({
  title,
  text,
  onSubmit,
  textFields,
  isLoading,
  secondaryActionButton,
  actionButtonText,
  helperLink,
  description,
  persistState,
  onPersistStateToggle,
  isRegister,
  isSignIn,
}) => {
  return (
    <div className='sign-up-form'>
      {title && <Text as='h1' type='title4'>{title}</Text>}
      {text && <Text>{text}</Text>}
      <form className='sign-up-form__form' onSubmit={onSubmit}>
        <div className='sign-up-form__form__content'>
          {textFields && textFields.map(textField => (
            <div key={textField.id} className='sign-up-form__form__text-field'>
              <TextField
                value={textField.value}
                disabled={textField.disabled}
                onChange={textField.onChange}
                error={textField.error}
                helperText={textField.helperText}
                fullWidth
                label={textField.label}
                type={textField.type}
                autoComplete={textField.autoComplete}
              />
            </div>
          ))}
          <LoadingComponent
            isLoading={isLoading}
            size={40}
            className='sign-up-form__loading'
            id='loader'
          />
        </div>
        {description && <p className='sign-up-form__description'>{description}</p>}
        <div
          className={classNames('sign-up-form__form__action-buttons', {
            'sign-up-form__form__action-buttons--multiple': secondaryActionButton,
          })}
        >
          {secondaryActionButton && (
            <Button
              variant='outlined'
              color='default'
              onClick={secondaryActionButton.onClick}
            >
              {secondaryActionButton.label}
            </Button>
          )}
          {actionButtonText && (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={onSubmit}
              disabled={isLoading}
            >
              {actionButtonText}
            </Button>
          )}
        </div>
        {(isSignIn || isRegister) && (
          <div className='sign-up-form__persist-state'>
            <FormControlLabel
              className='sign-up-form__persist-state__label'
              control={(
                <Checkbox checked={persistState === true} onChange={onPersistStateToggle} name='persist-state' />
              )}
              label={t('sign_up.persist-state.title')}
            />
            <Text type='body2' color='text__grey-color'>
              {t('sign_up.persist-state.description')}
            </Text>
          </div>
        )}
      </form>
      {helperLink && (
        <Link
          className='sign-up-form__form__trouble-link'
          underlined
          onClick={helperLink.onClick}
        >
          <Text type='body2' color='text__enhanced-grey-color'>
            {helperLink.text}
          </Text>
        </Link>
      )}
    </div>
  )
}
