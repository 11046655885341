import { useLocation } from 'react-router-dom'

const getQuery = (query, param) => {
  const urlQuery = query.get(param)
  return urlQuery === '' ? true : urlQuery
}

export const useUrlQuery = params => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  if (Array.isArray(params)){
    return params.map(param => getQuery(query, param))
  }
  return getQuery(query, params)
}
