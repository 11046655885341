import { InfoOutlined } from '@mui/icons-material'
import { Alert as MUIAlert } from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import './styles.scss'

export const Alert = props => {
  if (props.variant === 'text') {
    return (
      <div className={classNames('alert__text-variant', props.className)}>
        {props.severity && (
          <InfoOutlined
            className='help-tooltip__button'
            fontSize='small'
          />
        )}
        {props.children}
      </div>
    )
  }

  return (
    <MUIAlert {...props}>
      {props.children}
    </MUIAlert>
  )
}
