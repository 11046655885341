import { useEffect, useState, useCallback } from 'react'
import throttle from 'underscore/modules/throttle'

export const getScrollTop = elmt => {
  const globalScrollOffset = document.querySelector('html').scrollTop
  return elmt ? elmt.scrollTop : globalScrollOffset
}

export const useScrollOffset = initialNode => {
  const [elmt, setElmt] = useState(initialNode)
  const [offset, setOffset] = useState(getScrollTop(elmt))

  // useCallback is used to get a ref that was later defined, or after the initial render
  // see more here: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  // If parameter initialNode is not set in the first call, this function should be used like: ref={measuredRef}
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setElmt(node)
      setOffset(node.scrollTop)
    }
  }, [])

  useEffect(() => {
    const scrollListener = () => setOffset(getScrollTop(elmt))

    const throttleScrollListener = throttle(scrollListener, 500)

    if (elmt) {
      elmt.addEventListener('scroll', scrollListener)
    } else {
      window.addEventListener('scroll', scrollListener)
    }

    return () => {
      const scroller = elmt ?? window
      scroller.removeEventListener('scroll', throttleScrollListener)
    }
  }, [elmt])

  return [offset, measuredRef]
}
