import { routes, routesNames, subRoutesNames } from './routes'

/**
 * Function to create links given the following parameters
 * @param { string } routeName as set in ./routes constants
 * @param { {[routeParameter]: routeParameterValue} } routeOptions used to populate route dynamic variables
 * @param { boolean } preserveParameters used to preserve existant url parameters
 * @param { {[routeQueryParameter]: routeQueryParameterValue} } queryParameters used to add specific url query parameters
 * @param { string[] } ignoredParameters used to ignore specific url parameters
 *
 * @example
 *  given the following active url: 'https://www.plotu.com/home?parameter1=paramter1value&parameter2=paramter2value'
 *  const result = createLink('USER_DETAILS', {id: 'user-example-id'}, true, {customParam: customParamVal} ['paramter1value'])
 *  result ==> '/user-details/user-example-id?parameter2=paramter2value&customParam=customParamVal
 */
export const createLink = (
  routeName,
  routeOptions,
  preserveParameters = true,
  queryParameters,
  ignoredParameters,
  fullLink
) => {
  const routeUrl = fullLink
    ? window.location.origin + routes[routeName]
    : routes[routeName]

  let parsedRoute = routeUrl === '/'
    ? '/'
    : routeUrl.split('/:')[0]

  if (routeOptions) {
    parsedRoute = Object.keys(routeOptions)
      .reduce((prev, routeParameter) => {
        if (routeOptions[routeParameter] === undefined) {
          return prev
        }

        return prev + '/' + routeOptions[routeParameter]
      }, parsedRoute)
  }

  if (preserveParameters || queryParameters || ignoredParameters) {
    return setUrlParameters(parsedRoute, preserveParameters, queryParameters, ignoredParameters)
  }

  return parsedRoute
}

// TODO: use object properties instead

export const createBlogLink = ({
  slug,
  preserveParameters = true,
  removedParameters,
  queryParameters = undefined,
} = {}) =>
  createLink(routesNames.BLOG, { slug }, preserveParameters, queryParameters, removedParameters)

export const createPricingLink = (preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.PRICING, undefined, preserveParameters, undefined, ignoredParameters)

export const createProfessionalsLink = (preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.PROFESSIONALS, undefined, preserveParameters, undefined, ignoredParameters)

export const createHomeLink = (preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.HOME, undefined, preserveParameters, undefined, ignoredParameters)

export const createUserLink = (userId, queryParameters, preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.USER_DETAILS, { id: userId }, preserveParameters, queryParameters, ignoredParameters)

export const createUserNameLink = (username, tabId, queryParameters, preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.USER, { username, tabId }, preserveParameters, queryParameters, ignoredParameters)

export const createPackLink = (packId, queryParameters, preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.PACK_DETAILS, { id: packId }, preserveParameters, queryParameters, ignoredParameters)

export const createSearchLink = (
  searchCategory,
  querySearch,
  pageIndex,
  viewType,
  preserveParameters = true,
  ignoredParameters
) => {
  const queryParameters = {}
  if (querySearch) {
    queryParameters.q = querySearch
  }
  if (pageIndex !== undefined) {
    queryParameters.i = pageIndex
  }
  if (viewType) {
    queryParameters.v = viewType
  }
  return createLink(routesNames.SEARCH, { searchCategory }, preserveParameters, queryParameters, ignoredParameters)
}

export const createBackOfficeLink = (
  backOfficePage = subRoutesNames.BACK_OFFICE.DASHBOARD,
  backOfficeSubPage,
  preserveParameters = true,
  ignoredParameters,
  queryParameters
) => {
  const urlSubRoutes = {
    page: backOfficePage,
  }

  if (backOfficeSubPage) {
    urlSubRoutes.subPage = backOfficeSubPage
  }

  return createLink(routesNames.BACK_OFFICE, urlSubRoutes, preserveParameters, queryParameters, ignoredParameters)
}

export const createUserSettingsLink = (
  userSettingsPage = subRoutesNames.USER_SETTINGS.PROFILE,
  preserveParameters = true,
  ignoredParameters,
  queryParameters
) =>
  createLink(
    routesNames.USER_SETTINGS,
    { page: userSettingsPage },
    preserveParameters,
    queryParameters,
    ignoredParameters
  )

export const createRegisterJourneyLink = (
  registerJourneyId,
  queryParameters,
  preserveParameters = true,
  ignoredParameters
) =>
  createLink(
    routesNames.REGISTER_JOURNEY,
    { id: registerJourneyId },
    preserveParameters,
    queryParameters,
    ignoredParameters
  )

export const createAccessLink = (accessType, queryParameters, preserveParameters = true, ignoredParameters) =>
  createLink(routesNames.ACCESS, { type: accessType }, preserveParameters, queryParameters, ignoredParameters)

export const createGalleryLink = (galleryId, queryParameters, preserveParameters = true, ignoredParameters, fullLink) =>
  createLink(routesNames.GALLERY, { id: galleryId }, preserveParameters, queryParameters, ignoredParameters, fullLink)

export const getCurrentUrlName = () => {
  const routeIndex = Object.values(routes)
    .findIndex(route => route === window.location.pathname)

  if (routeIndex === -1) {
    return routesNames.HOME
  }

  return Object.keys(routes)[routeIndex]
}

const addQueryParameter = (currentParameters, key, value) => {
  if (value === undefined || value === false) {
    return currentParameters
  }

  if (value === true) {
    return `${currentParameters}${key}&`
  }

  if (value !== undefined) {
    return `${currentParameters}${key}=${value}&`
  }

  return currentParameters
}

/**
 * Updates a query string from an URL query string part
 * @param {string} currentParameters
 * @param {string} key query string key to whose value will be replaced
 * @param {string} value query string value to replace
 *
 * @example updateQueryParameter('?parameter1=value1&parameter2=value2&', 'parameter1', 'value1updated')
 *  ==> '?parameter1=value1updated&parameter2=value2&'
 */
const updateQueryParameter = (currentParameters, key, value) => {
  const startIndex = currentParameters.indexOf(`${key}=`)
  const endIndex = currentParameters.indexOf('&', startIndex)
  const regex = new RegExp(currentParameters.substring(startIndex, endIndex))
  return currentParameters.replace(regex, `${key}=${value}`)
}

/**
 * Util function to handle URL query parameters
 */
export const setUrlParameters = (
  pathname = window.location.pathname,
  preserveParameters,
  parameters,
  removedParameters
) => {
  let newParsedQueryString = ''
  const actualQueryString = window.location.search

  if (preserveParameters) {
    newParsedQueryString = actualQueryString
  }

  if (parameters) {
    const newParameteresEntries = Array.from(Object.entries(parameters))

    newParsedQueryString = newParameteresEntries.reduce((prev, entry) => {
      if (prev.includes(`${entry[0]}=`)) {
        return updateQueryParameter(prev, entry[0], entry[1])
      }

      return addQueryParameter(prev, entry[0], entry[1])
    }, newParsedQueryString.length === 0 ? '?' : `${newParsedQueryString}&`)

    if (newParsedQueryString !== '?' && newParsedQueryString !== '&') {
      newParsedQueryString = newParsedQueryString.substring(0, newParsedQueryString.length - 1)
    }
  }

  if (removedParameters) {
    const urlParams = new URLSearchParams(newParsedQueryString)
    const entries = Array.from(urlParams.entries())

    const parsedQueryString = entries.reduce((prev, entry) => {
      const parsedValue = entry[1] === '' ? true : entry[1]
      if (!removedParameters.includes(entry[0])) {
        return addQueryParameter(prev, entry[0], parsedValue)
      }
      return prev
    }, '?')

    newParsedQueryString = parsedQueryString.substring(0, parsedQueryString.length - 1)
  }

  return pathname + newParsedQueryString
}

/**
 * This functions is used when a link must be constructed from configuration files
 * @param {string} urlKey this urlKey should be a key pre structured in the following way:
 *  <Route ID (as in ./routes)>::{[parameterKey]: parameterValue}::preserveParameters=boolean::queryParameters={[queryParameterKey]: queryParameterValue}::ignoredParameters=[string]
 *
 * To ignore a parameter in the middle of the url key, add "_" in its place (example 3)
 *
 * @example
 *  const urlKey = BACK_OFFICE::{}::preserveParameters=false
 *  const urlKey = USER_DETAILS::{"id":"$this"}::preserveParameters=true::queryParameters={"customParam": "customParamVal", "customParam2": "customParamVal2"}::ignoredParameters=["ignoredParameter1"]
 *  const urlKey = USER_DETAILS::{"id":"$this"}::_::queryParameters={"customParam": "customParamVal"}
 */
export const dynamicLinkFactory = (urlKey, state) => {
  const splittedUrlKey = urlKey.split('::')
  const routeID = splittedUrlKey[0]

  if (!routesNames[routeID]) {
    return '/'
  }

  const routeOptions = splittedUrlKey[1] && JSON.parse(splittedUrlKey[1] || {})

  // this will populate $this dinamically accordingly with the routeID in the urlKey
  const parsedRouteOptions = routeOptions && Object.keys(routeOptions)
    .reduce((aggregatedValue, parameterKey) => {
      if (routeOptions[parameterKey] === '$this') {
        switch (routeID) {
          case routesNames.USER_DETAILS: {
            const contextUserUid = state.users.currentUser?.uid
            aggregatedValue[parameterKey] = contextUserUid
            return aggregatedValue
          }
          default:
            return aggregatedValue
        }
      }
      aggregatedValue[parameterKey] = routeOptions[parameterKey]
      return aggregatedValue
    }, {})

  const preserveParameters = splittedUrlKey[2] && splittedUrlKey[2] !== '_' && JSON.parse(splittedUrlKey[2].split('=')[1])

  const queryParameters = splittedUrlKey[3] && splittedUrlKey[3] !== '_' && JSON.parse(splittedUrlKey[3].split('=')[1])

  const ignoredParameters = splittedUrlKey[4] && splittedUrlKey[4] !== '_' && JSON.parse(splittedUrlKey[4].split('=')[1])

  return createLink(routeID, parsedRouteOptions, preserveParameters, queryParameters, ignoredParameters)
}
