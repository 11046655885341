import { Skeleton } from '@mui/material'
import React from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { List } from 'components/List/index.js'
import { Text } from 'components/Text'

import { t } from 'services/i18n'
import { subRoutesNames, createBackOfficeLink } from 'services/routingService'

import './styles.scss'

export const GalleryCardSkeleton = () => (
  <div>
    <Skeleton className='gallery-card-skeleton__image' animation='wave' variant='rectangular' width='100%' height={300} />
    <Skeleton animation='wave' variant='text' width={160} />
    <Skeleton animation='wave' variant='text' width={140} />
  </div>
)

const GalleryImage = ({ src }) => {
  return (
    <Image
      src={src}
      className='gallery-card__photo'
      width={910}
      height={1365}
      maxHeight={300}
      thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
      generateThumbnailIfError
    />
  )
}

const ImageWrapper = ({ onClick, src, id, srcContext }) => {
  if (onClick) {
    return (
      <div onClick={onClick}>
        <GalleryImage src={src} />
      </div>
    )
  } else {
    return (
      <Link href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES, id, true, undefined, { srcContext })}>
        <GalleryImage src={src} />
      </Link>
    )
  }
}

export const GalleryCard = props => {
  const { id, coverImageSrc, title, imagesCount, sharingOptions, galleryEvent, srcContext, onClick } = props
  const isShared = sharingOptions?.shared || false
  return (
    <div className='gallery-card'>
      <ImageWrapper
        id={id}
        src={coverImageSrc}
        srcContext={srcContext}
        onClick={onClick}
      />
      <div className='gallery-card__info'>
        <Link
          href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES, id, true, undefined, { srcContext })}
        >
          <Text className='gallery-card__info__title' type='subtitle'>{title}</Text>
        </Link>

        <List withSeparator>
          {imagesCount ? <Text color='text__grey-color' type='body2'>{t('back_office.event.gallery.images_count', { smart_count: imagesCount })}</Text> : null}
          <Text color='text__grey-color' type='body2'>
            {isShared ? t('general.gallery_sharing_type.public') : t('general.gallery_sharing_type.private')}
          </Text>
        </List>
        {galleryEvent && (
          <Link
            className='gallery-card__event'
            underlined
            href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, galleryEvent.eventId)}
          >
            {galleryEvent.title}
          </Link>
        )}
      </div>
    </div>
  )
}
