import { memoize } from 'underscore'

import { loadScript } from 'utils/scripts'

const BASE_URL = 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json'

var autoCompleteService

export const initializeLocationService = () => {
  if (typeof window !== 'undefined') {
    if (!document.querySelector('#google-maps')) {
      return loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=pt-PT&components=country:fr`,
        document.querySelector('head'),
        'google-maps'
      )
       .then(() => {
         autoCompleteService = new window.google.maps.places.AutocompleteService()
         return autoCompleteService
       })
    }

    return Promise.resolve(autoCompleteService)
  }
}

export const getAutoCompleteService = () => {
  return autoCompleteService
}

const memoizedAutoCompleteSuggestion = memoize(request => {
  return new Promise(resolve => {
    if (!autoCompleteService || request === '') {
      resolve()
    }

    autoCompleteService.getPlacePredictions({ input: request,
      componentRestrictions: {
        country: 'pt',
      } }, resolve)
  })
})

export const getAutoCompleteSuggestion = memoizedAutoCompleteSuggestion

export const autoCompleteSuggestion = _searchQuery => {
  const params = {
    'input': 'juncal',
    'key': process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    'language': 'pt-PT',
    'locationbias': 'ipbias',
    'inputtype': 'textquery',
    'fields': ['name', 'geometry', 'types', 'formatted_address'],
  }

  const parsedParams = Object.keys(params).reduce((prev, currKey) => {
    const keyValue = params[currKey]
    const paramValue = Array.isArray(keyValue) ? keyValue.join(',') : keyValue

    return prev === '?'
      ? `${prev}${currKey}=${paramValue}`
      : `${prev}&${currKey}=${paramValue}`

  }, '?')

  return fetch(`${BASE_URL}${parsedParams}`)
}
