import React, { useState } from 'react'

import { Modal } from 'components/Modal'
import { SignUpForm } from 'components/SignUpForm'

import { t } from 'services/i18n'
import { reauthenticateUser } from 'services/authenticationService'

import './styles.scss'
import { getErrorMessage } from 'utils/signUp'
import { MFASignInForm } from 'components/MFASignInForm'

export const ReauthencationModal = ({ open, closeModal, onReauthenticateSuccess, currentEmail, history }) => {
  const [, setEmail] = useState()
  const [emailError, setEmailError] = useState()
  const [password, setPasword] = useState()
  const [passwordError, setPasswordError] = useState()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ signingInWithMFA, setSigningInWithMFA ] = useState(false)
  const [ MFAErrorContext, setMFAErrorContext ] = useState()

  const onEmailChange = event => {
    if (emailError) {
      setEmailError(null)
    }
    setEmail(event.target.value)
  }

  const onPasswordChange = event => {
    if (passwordError) {
      setPasswordError(null)
    }
    setPasword(event.target.value)
  }

  const emailTextField = {
    id: 'email-text-field',
    value: currentEmail,
    disabled: true,
    onChange: onEmailChange,
    error: emailError,
    helperText: emailError,
    label: t('sign_up.email_input'),
    type: 'email',
    autoComplete: 'email',
  }

  const passwordTextField = {
    id: 'password-text-field',
    onChange: onPasswordChange,
    error: passwordError,
    helperText: passwordError,
    label: t('sign_up.password_input'),
    type: 'password',
  }

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    setPasswordError(null)

    if (!password) {
      setPasswordError(t('sign_up.error.invalid_password'))
      return
    }

    setIsLoading(true)

    reauthenticateUser(password)
      .then(() => {
        onReauthenticateSuccess()
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        if (error.code === 'auth/multi-factor-auth-required') {
          setSigningInWithMFA(true)
          setMFAErrorContext(error)
          return
        }

        console.error('Reauthentication error:', error)
        setPasswordError(getErrorMessage(error))
      })
  }

  const onMFASuccess = () => {
    setSigningInWithMFA(false)
    onReauthenticateSuccess()
  }

  return (
    <Modal
      open={open}
      title={t('components.reauthentication_modal.modal_title')}
      closeModal={closeModal}
    >
      <h2>{t('components.reauthentication_modal.title')}</h2>
      <p>{t('components.reauthentication_modal.text')}</p>
      <div className='reauthentication-modal__sign-up-form'>
        {!signingInWithMFA && (
          <SignUpForm
            textFields={[
              emailTextField,
              passwordTextField,
            ]}
            secondaryActionButton={{
              onClick: closeModal,
              label: t('components.reauthentication_modal.action_buttons.cancel'),
            }}
            onSubmit={onSubmit}
            actionButtonText={t('components.reauthentication_modal.action_buttons.submit')}
            isLoading={isLoading}
          />
        )}
        {signingInWithMFA && (
          <MFASignInForm
            signInError={MFAErrorContext}
            onSuccess={onMFASuccess}
            reauthenticationFlow
            history={history}
          />
        )}
      </div>
    </Modal>
  )
}
