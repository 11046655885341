import React from 'react'

import { useDynamicStaticPage } from 'hooks/useDynamicStaticPage'

const StaticPage = props => {
  const [ pageHtml ] = useDynamicStaticPage(props.pageId)

  return <div dangerouslySetInnerHTML={pageHtml} />
}

export default StaticPage
