import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MUISelect from '@mui/material/Select'
import React, { Fragment } from 'react'

import './styles.scss'

export const Select = props => {
  const onChange = event => {
    props.onChange(event)
  }

  return (
    <Fragment>
      <InputLabel id='demo-simple-select-label'>
        {`${props.label} options`}
      </InputLabel>
      <MUISelect
        labelId={props.labelId}
        value={props.value}
        onChange={onChange}
      >
        {props.menuItems.map(menuItem => (
          <MenuItem key={menuItem.value} native value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        ))}
      </MUISelect>
    </Fragment>
  )
}
