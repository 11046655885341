import React, { useEffect, useState } from 'react'

import { Button } from 'components/Button'
import { KeyValueInput } from 'components/KeyValueInput'
import { MultipleInputList } from 'components/MultipleInputList'
import { Text } from 'components/Text'
import { TextField } from 'components/TextField'

import { USER_TYPE } from 'model/User'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import './styles.scss'

const UserInfoInputFooter = ({ onSaveUserInfo, onCancelClick }) => {
  return (
    <div className='user-info-input__actions'>
      {onCancelClick && (
        <Button
          className='user-info-input__actions__button'
          variant='outlined'
          color='default'
          onClick={onCancelClick}
        >
          {t('components.user_info_input.cancel')}
        </Button>
      )}
      <Button
        className='user-info-input__actions__button'
        color='primary'
        variant='contained'
        onClick={onSaveUserInfo}
      >
        {t('components.user_info_input.save')}
      </Button>
    </div>
  )
}

export const UserInfoInput = props => {
  const [ name, setName ] = useState(props.initialName || '')
  const [ description, setDescription ] = useState(props.initialDescription || '')
  const [ location, setLocation ] = useState(props.initialLocation || {})
  const [ externalLinks, setExternalLinks ] = useState(props.initialExternalLinks || [])
  const [ services, setServices ] = useState(props.initialServices || [])
  const [ categories, setCategories ] = useState(props.initialCategories || [])

  useEffect(() => {
    setName(props.initialName || '')
    setDescription(props.initialDescription || '')
    setLocation(props.initialLocation || {})
    setExternalLinks(props.initialExternalLinks || [])
    setServices(props.initialServices || [])
    setCategories(props.initialCategories || [])
  }, [
    props.initialName,
    props.initialDescription,
    props.initialLocation,
    props.initialExternalLinks,
    props.initialServices,
    props.initialCategories,
  ])

  const onServicesChange = services => {
    setServices(services.map(service => ({ name: service })))
  }

  const onCategoriesChange = categories => {
    setCategories(categories.map(category => ({ name: category })))
  }

  const onExternalLinksChange = keyValues => {
    setExternalLinks(keyValues.map(keyValue => ({ name: keyValue.key, url: keyValue.value })))
  }

  const onLocationChange = event => {
    const newLocation = event.target.value
    setLocation({
      address: newLocation,
    })
  }

  const onSaveUserInfo = () => {
    props.onSaveUserInfo({
      name,
      description,
      location,
      externalLinks,
      services,
      categories,
    })
  }

  if (props.userType === USER_TYPE.CLIENT) {
    return (
      <div className='user-info-input'>
        <TextField
          className='user-info-input__text-field'
          label={t('components.user_info_input.name')}
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <UserInfoInputFooter
          onCancelClick={props.onCancelClick}
          onSaveUserInfo={onSaveUserInfo}
        />
      </div>
    )
  }

  return (
    <div className='user-info-input'>
      <TextField
        className='user-info-input__text-field'
        label={t('components.user_info_input.name')}
        value={name}
        onChange={event => setName(event.target.value)}
      />
      <div className='user-info-input__input-section'>
        <TextField
          multiline
          className='user-info-input__text-field'
          label={t('components.user_info_input.description')}
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </div>
      <div className='user-info-input__input-section'>
        <TextField
          className='user-info-input__text-field'
          label={t('components.user_info_input.localization')}
          value={location.address || ''}
          onChange={onLocationChange}
        />
      </div>
      <div className='user-info-input__input-section'>
        <Text as='h4' type='subtitle'>
          {t('components.user_info_input.external_links.title')}
        </Text>
        <KeyValueInput
          keyInputLabel={t('components.user_info_input.external_links.key')}
          valueInputLabel={t('components.user_info_input.external_links.value')}
          moreButtonLabel={t('components.user_info_input.external_links.more')}
          initialKeyValues={externalLinks.map(externalLink => ({ key: externalLink.name, value: externalLink.url }))}
          onChange={onExternalLinksChange}
        />
      </div>
      <div className='user-info-input__input-section'>
        <Text className='user-info-input__subtitle' as='h4' type='subtitle'>
          {t('components.user_info_input.services.title')}
        </Text>
        <MultipleInputList
          freeSolo={false}
          inputLabel={t('components.user_info_input.services.label')}
          initialValues={services.map(service => service.name)}
          onChange={onServicesChange}
          options={getConfigValue('general.services.options')?.map(option => t(option))}
        />
      </div>
      <div className='user-info-input__input-section'>
        <Text className='user-info-input__subtitle' as='h4' type='subtitle'>
          {t('components.user_info_input.categories.title')}
        </Text>
        <MultipleInputList
          disableCloseOnSelect
          inputLabel={t('components.user_info_input.categories.label')}
          initialValues={categories.map(category => category.name)}
          onChange={onCategoriesChange}
          options={getConfigValue('general.categories.options')?.map(option => t(option))}
        />
      </div>
      <UserInfoInputFooter
        onCancelClick={props.onCancelClick}
        onSaveUserInfo={onSaveUserInfo}
      />
    </div>
  )
}
