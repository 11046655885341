import Skeleton from '@mui/material/Skeleton'
import React from 'react'

import { Icon } from 'components/Icon'
import { Link } from 'components/Link'

import './styles.scss'

export const ContentRowSkeleton = () => (
  <div className='content-row'>
    <Skeleton animation='wave' variant='rectangular' width={40} height={40} />
    <div style={{ marginLeft: '20px' }}>
      <Skeleton animation='wave' width={250} height={20} />
      <Skeleton animation='wave' width={250} height={20} />
    </div>
  </div>
)

export const ContentRow = ({ value, category, extra, iconName, href, onClick }) => {
  return (
    <div className='content-row'>
      <Icon className='content-row__icon'>
        {iconName}
      </Icon>
      <Link onClick={onClick} href={href} underlined>
        <div className='content-row__info'>
          <div className='content-row__info--value'>
            {value}
          </div>
          {extra && (
            <div className='content-row__info--extra'>
              {extra}
            </div>
          )}
          <div className='content-row__info--category'>
            {category}
          </div>
        </div>
      </Link>
    </div>
  )
}
