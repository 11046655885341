import classNames from 'classnames'
import moment from 'moment'
import React from 'react'

import './styles.scss'

export const CalendarRowMobile = ({ dates = [], onCalendarDayClick, selectedCurrentDate, currentMonthDate }) => {
  return (
    <tr className='calendar-row-mobile'>
      {dates.map(({ date, events }) => {
        const isCurrentMonth = date.clone().startOf('month').diff(currentMonthDate.clone().startOf('month'), 'month') === 0
        const isToday = date.clone().isSame(moment(), 'day')
        const isSelectedDate = selectedCurrentDate && date.isSame(selectedCurrentDate, 'day')
        return (
          <td
            key={date.unix()}
            onClick={onCalendarDayClick(date)}
            className={classNames('calendar-row-mobile__day', {
              'calendar-row-mobile__day--adjacent-month': !isCurrentMonth,
            })}
          >
            <div className={classNames('calendar-row-mobile__day__label', {
              'calendar-row-mobile__day__label--is-today': isToday,
              'calendar-row-mobile__day__label--selected': isSelectedDate && !isToday,
            })}
            >
              {date.format('D')}
            </div>
            {events.length > 0 && <div className='calendar-row-mobile__day__has-event' />}
          </td>
        )
      })}
    </tr>
  )
}
