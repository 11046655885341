import MaterialUIRating from '@mui/material/Rating'
import React, { useEffect, useState } from 'react'

import './styles.scss'

export const Rating = props => {
  const [value, setValue] = useState(props.initialValue)
  const [hoverValue, setHoverValue] = useState(props.initialValue)

  useEffect(() => {
    setValue(props.initialValue)
  }, [props.initialValue])

  const {
    onChange,
    showTextValue,
    readOnly,
    name,
    precision,
    className,
    size,
    countValue,
  } = props

  const onRatingChange = (_event, newRating) => {
    setValue(newRating)
    onChange && onChange(newRating)
  }

  const onRatingHover = (_event, newHover) => setHoverValue(newHover)

  return (
    <div className={`rating${className ? ' ' + className : ''}`}>
      <MaterialUIRating
        name={name}
        readOnly={readOnly}
        precision={precision || 0.5}
        value={value}
        onChange={onRatingChange}
        onChangeActive={onRatingHover}
        size={size} // small, undefined or large
      />
      {showTextValue && <span className='rating__raw-value'>{hoverValue !== -1 ? hoverValue : value}</span>}
      {countValue && <span className='rating__count'>{countValue}</span>}
    </div>
  )
}
