export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) { return '0 Bytes' }

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const convertToBytes = (value, size) => {
  const k = 1000
  const sizes = {
    'bytes': 1,
    'kb': k,
    'mb': Math.pow(k, 2),
    'gb': Math.pow(k, 3),
    'tb': Math.pow(k, 4),
  }

  if (!sizes[size.toLowerCase()]) {
    throw new Error('Size not valid. Must be one of [Bytes, kB, MB, GB, TB]')
  }

  return Number.parseInt(value) * sizes[size.toLowerCase()]
}
