import { CheckOutlined } from '@mui/icons-material'
import ButtonGroup from '@mui/material/ButtonGroup'
import classNames from 'classnames'
import React, { useState, Fragment } from 'react'
import { useSelector } from 'react-redux'

import { LinkButton } from 'components/Button'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { PriceLabel } from 'components/PriceLabel'
import { Text } from 'components/Text'

import { PLAN_PERIOD } from 'model/Pricing'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { dynamicLinkFactory } from 'services/routingService'

import './styles.scss'

const Pricing = () => {
  const pricingPlanKeys = getConfigValue('general.pricing.plan_keys')
  const pricingPlanFeatures = getConfigValue('pricing.features')
  const premiumCards = getConfigValue('general.pricing.premium_plan_cards')
  const isLogedIn = useSelector(state => state.users.currentUser)

  const [
    planPeriod,
    setPlanPeriod,
  ] = useState(PLAN_PERIOD.MONTHLY)

  const onPlanPeriodChange = newPlanPeriod => () => setPlanPeriod(newPlanPeriod)

  return (
    <div className='pricing'>
      <Text as='h2' type='title2' className='pricing__title'>{t('pricing.title')}</Text>
      <div className='pricing__scroll-wrapper'>
        <div className='pricing__content'>
          <ButtonGroup gap aria-label='outlined primary button group'>
            <Button onClick={onPlanPeriodChange(PLAN_PERIOD.MONTHLY)} variant={planPeriod === PLAN_PERIOD.MONTHLY ? 'contained' : 'outlined'}>Mensal</Button>
            <Button onClick={onPlanPeriodChange(PLAN_PERIOD.YEARLY)} variant={planPeriod === PLAN_PERIOD.YEARLY ? 'contained' : 'outlined'}>Anual</Button>
          </ButtonGroup>
          <Grid xs={12}>
            <Grid xs={3}>
              <div className='pricing__plan' />
              {pricingPlanKeys.map(planKey => {
                const planCard = premiumCards[planKey]
                const actionButtonHrefKey = getConfigValue(`pricing.premium_plan_cards.${planKey}.action_button.${isLogedIn ? 'dynamic_href_logedin' : 'dynamic_href'}`)
                const actionButtonHref = actionButtonHrefKey && dynamicLinkFactory(actionButtonHrefKey)
                const periodPrice = Object.values(planCard.prices)
                  .find(price => planPeriod === PLAN_PERIOD.MONTHLY ? price.interval === 'monthly' || price.interval === 'once' : price.interval === 'anual' || price.interval === 'once')
                return (
                  <div
                    key={planKey}
                    className={classNames('pricing__plan', {
                      'pricing__plan--highlighted': planCard.highlighted,
                    })}
                  >
                    <Text type='subtitle' bold>{t(`general.premium_packs.${planCard.i18nKey}.title`)}</Text>
                    <Text type='body2' className='pricing__plan__description'>{t(`pricing.plans.${planCard.i18nKey}.description`)}</Text>
                    <PriceLabel
                      periodPriceWithoutDiscount={periodPrice.price_without_discount}
                      periodPrice={periodPrice.price}
                      planPeriod={planPeriod}
                    />
                    <LinkButton
                      className='pricing__plan__action'
                      href={actionButtonHref}
                      variant={planCard.highlighted ? 'contained' : 'outlined'}
                      color={planCard.soon ? 'default' : 'primary'}
                      disabled={planCard.soon}
                    >
                      {t(`pricing.plans.${planCard.i18nKey}.action_button`)}
                    </LinkButton>
                  </div>
                )
              })}
            </Grid>
            {pricingPlanFeatures.map((feature, featureIndex) => {
              const isLastFeature = featureIndex === pricingPlanFeatures.length - 1
              if (feature.section) {
                return (
                  <Fragment key={feature.section_name_key}>
                    <div className='pricing__table__section'>
                      <Text className='pricing__table__section-name'>
                        {t(`pricing.sections.${feature.section_name_key}`)}
                      </Text>
                    </div>
                    {feature.feature_keys.map((featureKey, featureKeyIndex) => {
                      const isLastFeatureKey = isLastFeature && featureKeyIndex === feature.feature_keys.length - 1
                      const currFeatureKey = featureKey?.key ?? featureKey
                      const isTitle = featureKey?.title ?? false

                      return (
                        <Grid key={currFeatureKey} className='pricing__table__row' xs={3}>
                          {
                            isTitle
                              ? (
                                <Text bold className='pricing__table__block-title'>
                                  {t(`pricing.features.${currFeatureKey}`)}
                                </Text>
                              )
                              : (
                                <Text className='pricing__table__block-sub-title'>
                                  {t(`pricing.features.${currFeatureKey}`)}
                                </Text>
                              )
                          }
                          {pricingPlanKeys.map(planKey => {
                            const planCard = premiumCards[planKey]
                            const pricingPlanFeature = planCard.available_features[currFeatureKey]
                            const value = pricingPlanFeature === true || pricingPlanFeature?.available
                              ? <CheckOutlined className='pricing__table__block-icon' />
                              : pricingPlanFeature?.value || t(pricingPlanFeature?.i18nKey)
                            const secondaryValue = pricingPlanFeature?.secondary_text_key &&
                            t(pricingPlanFeature.secondary_text_key)
                            return (
                              <Text
                                key={planKey}
                                className={classNames('pricing__table__block', {
                                  'pricing__table__block--highlighted': planCard.highlighted,
                                  'pricing__table__block--last-highlighted': planCard.highlighted && isLastFeatureKey,
                                })}
                              >
                                {value}
                                {secondaryValue && <div>{secondaryValue}</div>}
                              </Text>
                            )
                          })}
                        </Grid>
                      )
                    })}
                  </Fragment>
                )
              }
              return null
            })}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Pricing
