import React from 'react'

import { ContainedImage } from './components/ContainedImage'
import { ImageWithGradient } from './components/ImageWithGradient'
import { SimpleImage } from './components/SimpleImage'
import { SizedImage } from './components/SizedImage'

export const Image = props => {
  const { sized, ...otherProps } = props

  if (props.gradient) {
    return <ImageWithGradient {...props} />
  }

  if ((props.width || props.height) && sized) {
    return <SizedImage {...otherProps} />
  }

  if (props.width && props.height) {
    return <ContainedImage {...props} />
  }

  return <SimpleImage {...props} />
}
