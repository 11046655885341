import { MOCK_CATEGORIES } from './__mocks__/categories'
import { MOCK_CUSTOMERS } from './__mocks__/customers'
import { MOCK_PACKS } from './__mocks__/packs'
import { MOCK_PHOTOGRAPHER } from './__mocks__/photographers'

const DEFAULT_TIME_TO_RESOLVE = 1200

const resolvePromiseAfterMs = (valueToResolve, timeToResolve = DEFAULT_TIME_TO_RESOLVE) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(valueToResolve)
    }, timeToResolve)
  })
}

export const initializeSearchService = resolvePromiseAfterMs

export const savePackObject = resolvePromiseAfterMs

export const saveUserObject = resolvePromiseAfterMs

export const saveCategoriesObjects = resolvePromiseAfterMs

export const updatePackObject = resolvePromiseAfterMs

export const updateUserObject = resolvePromiseAfterMs

export const deleteUserObject = resolvePromiseAfterMs

export const deletePackObject = resolvePromiseAfterMs

export const deletePackObjects = resolvePromiseAfterMs

export const searchPackIndex = () => resolvePromiseAfterMs(MOCK_PACKS.hits)

export const searchUsersIndex = () => resolvePromiseAfterMs(MOCK_PHOTOGRAPHER.hits)

export const searchCategoriesIndex = () => resolvePromiseAfterMs(MOCK_CATEGORIES.hits)

const SEARCH_ALL_MOCK = [
  MOCK_PACKS,
  MOCK_PHOTOGRAPHER,
  MOCK_CATEGORIES,
  MOCK_CUSTOMERS,
]

export const searchAll = () => resolvePromiseAfterMs(SEARCH_ALL_MOCK)
