export const USER_TYPE = {
  PROFESSIONAL: 'PROFESSIONAL',
  CLIENT: 'CLIENT',
}

export class User {
  constructor (user) {
    const {
      uid,
      name,
      email,
      username,
      avatar,
      externalLinks,
      description,
      services,
      categories,
      portfolio,
      packs,
      galleries,
      location,
      type,
      creationDate,
      plan,
      storageSize,
      marketingConsent,
      walkthroughData,
      watermarks,
    } = user

    this.uid = uid
    this.name = name
    this.email = email
    this.username = username
    this.avatar = avatar
    this.externalLinks = externalLinks
    this.description = description
    this.services = services
    this.categories = categories
    this.portfolio = portfolio
    this.packs = packs
    this.galleries = galleries
    this.location = location
    this.type = type
    this.creationDate = creationDate
    this.plan = plan
    this.storageSize = storageSize
    this.marketingConsent = marketingConsent
    this.walkthroughData = walkthroughData
    this.watermarks = watermarks
  }

  static userFromAuthenticationServiceFactory (authenticationUser) {
    return new User({
      uid: authenticationUser.uid,
      name: authenticationUser.displayName,
      email: authenticationUser.email,
      username: authenticationUser.username,
      avatar: authenticationUser.photoURL || undefined, // in case it's null, we should not set it
    })
  }

  get packsIds () {
    return this.packs?.cards.map(card => card.id) || []
  }

  get hiddenPacksIds () {
    const hiddenCards = this.packs?.hiddenCards || []
    return hiddenCards.map(card => card.id)
  }

  get isPro () {
    return this.type === USER_TYPE.PROFESSIONAL
  }

  get galleryCount () {
    return this.galleries.galleryCount
  }

  get portefolioGalleryCount () {
    return this.galleries.portefolioGalleryCount
  }

  setInfo ({
    name,
    email,
    username,
    description,
    externalLinks,
    services,
    categories,
    location,
    type,
    avatar,
    plan,
    storageSize,
    marketingConsent,
    walkthroughData,
    watermarks,
  }) {
    this.name = name ?? this.name
    this.email = email ?? this.email
    this.username = username ?? this.username
    this.description = description ?? this.description
    this.externalLinks = externalLinks ?? this.externalLinks
    this.services = services ?? this.services
    this.categories = categories ?? this.categories
    this.location = location ?? this.location
    this.type = type ?? this.type
    this.avatar = avatar ?? this.avatar
    this.plan = plan ?? this.plan
    this.storageSize = storageSize ?? this.storageSize
    this.marketingConsent = marketingConsent ?? this.marketingConsent
    this.walkthroughData = walkthroughData ?? this.walkthroughData
    this.watermarks = watermarks ?? this.watermarks
  }

  setAvatar (avatar) {
    this.avatar = avatar
  }

  addUserPack (packId, addToStart = false) {
    if (!this.packs) {
      this.packs = {
        cards: [],
        hiddenCards: [],
      }
    }

    if (addToStart) {
      this.packs.cards = [
        { id: packId },
        ...this.packs.cards,
      ]
    } else {
      this.packs.cards = [
        ...this.packs.cards,
        { id: packId },
      ]
    }
  }

  removeUserPack (packId) {
    const packIndex = this.packs.cards.findIndex(pack => pack.id === packId)
    if (packIndex > -1) {
      this.packs.cards.splice(packIndex, 1)
    }
  }

  addUserHiddenPack (hiddenPackId) {
    if (!this.packs) {
      this.packs = {
        cards: [],
        hiddenCards: [],
      }
    }

    this.packs.hiddenCards = [
      ...this.packs.hiddenCards || [],
      { id: hiddenPackId },
    ]
  }

  addToGalleryCount (count, isPortefolioGallery) {
    if (!this.galleries) {
      this.galleries = {
        galleryCount: 0,
        portefolioGalleryCount: 0,
      }
    }

    if (isPortefolioGallery) {
      this.galleries.portefolioGalleryCount += count
    } else {
      this.galleries.galleryCount += count
    }
  }

  incrementGalleryCount (isPortefolioGallery) {
    this.addToGalleryCount(1, isPortefolioGallery)
  }

  decrementGalleryCount (isPortefolioGallery) {
    this.addToGalleryCount(-1, isPortefolioGallery)
  }

  removeUserHiddenPack (hiddenPackId) {
    const hiddenPackIndex = this.packs.hiddenCards.findIndex(pack => pack.id === hiddenPackId)
    if (hiddenPackIndex > -1) {
      this.packs.hiddenCards.splice(hiddenPackIndex, 1)
    }
  }

  addToUserPortfolio (images, addToStart = false) {
    if (this.portfolio === undefined) {
      this.portfolio = {
        cards: [],
      }
    }

    const newImages = (Array.isArray(images) ? images : [images])
    const newImagesObj = newImages
      .reduce((prev, currImage) => {
        prev[currImage.id] = currImage
        return prev
      }, {})

    const newLayoutCardsToAdd = newImages
      .map(portfolioCard => ({
        id: portfolioCard.id,
        colsWidth: portfolioCard.colsWidth,
        maxHeight: portfolioCard.maxHeight,
      }))

    // Add the new images to all layouts that are available
    const layoutDataCopy = { ...this.portfolio.layoutsData }
    Object.keys(this.portfolio.layoutsData || {})
      .forEach(layoutKey => {
        const layoutDataCards = layoutDataCopy[layoutKey].cards
        if (addToStart) {
          layoutDataCopy[layoutKey] = {
            ...layoutDataCopy[layoutKey],
            cards: [...newLayoutCardsToAdd, ...layoutDataCards],
          }
        } else {
          layoutDataCopy[layoutKey] = {
            ...layoutDataCopy[layoutKey],
            cards: [...layoutDataCards, ...newLayoutCardsToAdd],
          }
        }
      })
    this.portfolio.layoutsData = layoutDataCopy

    // Add the new images to portfolio cards data
    if (addToStart) {
      this.portfolio.cards = {
        ...newImagesObj,
        ...this.portfolio.cards,
      }
    } else {
      this.portfolio.cards = {
        ...this.portfolio.cards,
        ...newImagesObj,
      }
    }
  }

  removeFromUserPortfolio (imageIds) {
    if (this.portfolio === undefined) {
      return
    }

    const toRemoveImageIds = (Array.isArray(imageIds) ? imageIds : [imageIds])

    Object.keys(this.portfolio.layoutsData || {})
      .forEach(layoutKey => {
        const layoutData = this.portfolio.layoutsData[layoutKey].cards
        this.portfolio.layoutsData[layoutKey].cards = layoutData
          .filter(image => !toRemoveImageIds.includes(image.id))
      })

    toRemoveImageIds.forEach(toRemoveImageIds => {
      delete this.portfolio.cards[toRemoveImageIds]
    })
  }

  setPortfolio (portfolio = {}) {
    this.portfolio = portfolio
  }

  setPackIds ({ packIds = [], hiddenPackIds = [] }) {
    this.packs = {
      cards: packIds.map(id => ({
        id,
      })),
      hiddenCards: hiddenPackIds.map(id => ({
        id,
      })),
    }
  }

  setWalkthroughData (walkthroughKey, walkthroughData) {
    const currentWalkthroughData = this.walkthroughData || {}
    this.walkthroughData = {
      ...currentWalkthroughData,
      [walkthroughKey]: {
        ...(currentWalkthroughData[walkthroughKey] || {}),
        ...walkthroughData,
      },
    }
  }

  addUserWatermark (watermarkId) {
    this.watermarks = [
      ...(this.watermarks || []),
      watermarkId,
    ]
  }

  removeUserWatermark (watermarkId) {
    const watermarkIndex = this.watermarks.findIndex(watermark => watermark === watermarkId)
    if (watermarkIndex > -1) {
      this.watermarks.splice(watermarkIndex, 1)
    }
  }
}
