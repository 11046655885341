import moment from 'moment'

export const MAX_ITEMS_BY_DAY = 3

export const EVENT_SHIFTING = {
  NOT_SHIFTING: undefined,
  SHIFTING_ALL: 0,
  SHIFTING_LEFT: 1,
  SHIFTING_RIGHT: 2,
}

const CALENDAR_SIZE_IN_DAYS = 42

const daysEventsSlots = new Array(MAX_ITEMS_BY_DAY).fill(true)
  .reduce((prev, _curr, index) => ([...prev, MAX_ITEMS_BY_DAY - 1 - index]), []) // should be something like [2, 1, 0]

const getEventRowIndex = eventsSameDay => {
  // find the event row index
  const rowIndexesInDay = (eventsSameDay || []).map(event => event.rowIndex)
  // to find the row index of the new event, all rows must be checked if they are already occupied
  return daysEventsSlots // should be something like [2, 1, 0]
    .reduce((prev, curr) => rowIndexesInDay.includes(curr) ? prev : curr, MAX_ITEMS_BY_DAY)
}

const getEventFirstDayInUI = (startDate, calendarFirstDateInUI) => {
  const eventDiffWithFirstDayInUI = startDate.diff(calendarFirstDateInUI, 'days')
  return eventDiffWithFirstDayInUI === 0
    ? 0
    : eventDiffWithFirstDayInUI + 1
}

export const getCalendarData = (calendarData, currentMonthDate) => {
  const monthDays = new Array(CALENDAR_SIZE_IN_DAYS)

  const startOfMonth = currentMonthDate.clone().startOf('month')
  const startOfMonthDayOfWeek = startOfMonth.day() // day of week of the first day of the current month (0 === Sunday)

  const firstDayOfLastMonthInUI = currentMonthDate.clone()
    .subtract(1, 'month').endOf('month')
    .subtract(startOfMonthDayOfWeek - 1, 'days')

  const firstDateInUI = firstDayOfLastMonthInUI.clone()
  // calculate the days that should be displayed
  for (let index = 0; index < monthDays.length; index++) {
    // otherwise, populate with the current month
    monthDays[index] = {
      date: firstDateInUI.clone().startOf('day'),
      events: [],
    }
    firstDateInUI.add(1, 'days')
  }

  const calendarFirstDateInUI = firstDayOfLastMonthInUI.clone()

  const calendarLastDateInUI = monthDays[monthDays.length - 1].date.clone()

  // add to month days, all the events
  Object.keys(calendarData || {})
    // first sort, by date
    // secondly, sort by length of event. Bigger events will appear in the top
    .sort((eventAKey, eventBKey) => { // events must be sorted in order to be rendered accordingly
      const eventANrOfDays = moment(calendarData[eventAKey].endDate).diff(moment.unix(calendarData[eventAKey].startDate), 'days')
      const eventBNrOfDays = moment(calendarData[eventBKey].endDate).diff(moment.unix(calendarData[eventBKey].startDate), 'days')
      return eventAKey - eventBKey || eventBNrOfDays - eventANrOfDays
    })
    .forEach(eventKey => {
      const event = calendarData[eventKey]
      const startDate = moment.unix(event.startDate)
      const endDate = moment.unix(event.endDate)

      if (endDate.isBefore(calendarFirstDateInUI, 'days') || startDate.isAfter(calendarLastDateInUI, 'days')) {
        return
      }

      if (startDate.isSame(endDate, 'days')) {
        const eventFirstIndexInUI = getEventFirstDayInUI(startDate, calendarFirstDateInUI)
        const sameDayEvents = monthDays[eventFirstIndexInUI].events
        const newRowIndex = getEventRowIndex(sameDayEvents)

        sameDayEvents.push({
          ...event,
          rowIndex: newRowIndex,
        })
      } else {
        const eventStartDateInUI = startDate.clone()
        const eventLengthInDays = endDate.diff(startDate, 'days') + 1
        let eventDayIndex = 0 // index of the first day of the event that will be displayed in the UI

        while (eventStartDateInUI.isBefore(calendarFirstDateInUI, 'days')) {
          eventStartDateInUI.add(1, 'days')
          eventDayIndex++
        }

        // UI calendar index in where the event will start to be render.
        const eventFirstIndexInUI = getEventFirstDayInUI(eventStartDateInUI, calendarFirstDateInUI)
        const eventNrOfDaysInUI = endDate.diff(eventStartDateInUI, 'days') + 1
        const newRowIndex = getEventRowIndex(monthDays[eventFirstIndexInUI].events)

        let index = eventFirstIndexInUI
        while (index < CALENDAR_SIZE_IN_DAYS && index < eventNrOfDaysInUI + eventFirstIndexInUI) {
          const currentMonthDay = monthDays[index]

          currentMonthDay.events.push({
            ...event,
            rowIndex: newRowIndex,
            shifting: eventDayIndex === 0
              ? EVENT_SHIFTING.SHIFTING_RIGHT
              : eventDayIndex === eventLengthInDays - 1
                ? EVENT_SHIFTING.SHIFTING_LEFT
                : EVENT_SHIFTING.SHIFTING_ALL,
          })
          index++
          eventDayIndex++
        }
      }
    })

  return monthDays
}
