import { useEffect, useState } from 'react'

export const useHeaderIsHidden = () => {
  const [headerIsHidden, setHeaderIsHidden] = useState(false)

  const callback = mutationList => {
    mutationList.forEach(function(mutation) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        setHeaderIsHidden(!mutation.target?.className.includes('header--hidden'))
      }
    })
  }

  useEffect(() => {
    const header = document.querySelector('.header')

    setHeaderIsHidden(!header?.className.includes('header--hidden'))

    const options = {
      attributes: true,
    }

    const observer = new MutationObserver(callback)
    observer.observe(header, options)

    return () => observer.disconnect()
  }, [])

  return headerIsHidden
}
