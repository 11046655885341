import Grid from '@mui/material/Grid'
import React from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'

import IGLogo from 'assets/Instagram_Glyph_Gradient_RGB.png'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { createHomeLink } from 'services/routingService'

import './styles.scss'

const SOCIAL_NETWORK_LOGO_SIZE = 25

const renderSocialNetworkLogo = (src, url, alt) => {
  return (
    <a
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      className='footer__bottom__social-network'
    >
      <Image
        width={SOCIAL_NETWORK_LOGO_SIZE}
        height={SOCIAL_NETWORK_LOGO_SIZE}
        sized
        alt={alt}
        src={src}
      />
    </a>
  )
}

export const Footer = () => {
  const linkGroups = getConfigValue('footer.linkGroups')
  const socialLinks = getConfigValue('footer.social')

  return (
    <footer className='footer'>
      <Grid container spacing={3} className='footer__grid'>
        {linkGroups.map(linkGroup => (
          <Grid key={linkGroup.title} className='footer__links-group-wrapper' item xs={12} sm={6} md>
            <div className='footer__links-title'>
              {t(linkGroup.title)}
            </div>
            <ol className='footer__link-list'>
              {linkGroup.links.map(link => (
                <li key={link.text} className='footer__link-list__item'>
                  <Link href={link.href} target={link.target}>
                    <Text type='body' color='text__light-color'>{t(link.text)}</Text>
                  </Link>
                </li>
              ))}
            </ol>
          </Grid>
        ))}
      </Grid>
      <div className='footer__bottom'>
        <Logo light className='footer__bottom__logo' />
        <div className='footer__bottom__copyright'>
          &copy;
          {` ${new Date().getFullYear()} Copyright:`}
          &nbsp;
          <Link href={createHomeLink(true, ['q', 'v'])}>PLOTU</Link>
        </div>
        <div className='footer__bottom__social-networks'>
          {renderSocialNetworkLogo(IGLogo, socialLinks.instagram.url, 'Instagram PLOTU\'s page')}
        </div>
      </div>
    </footer>
  )
}
