import {
  getProducts as mockGetProducts,
  getPrices as mockGetPrices,
} from './mockPaymentsService'
import {
  getProducts as stripeGetProducts,
  getPrices as stripeGetPrices,
} from './stripePaymentsService'

import { isMock, isProduction } from 'utils/envs'

export const getProducts = options => {
  if (isMock()) {
    return mockGetProducts(options)
  }

  return stripeGetProducts(options)
}

export const getPrices = options => {
  if (isMock()) {
    return mockGetPrices(options)
  }

  return stripeGetPrices(options)
}

export const goToCheckout = ({
  planId,
  priceId,
  successUrl,
  cancelUrl,
  clientId,
  customerId,
  clientEmail,
  couponId,
  hasTrial,
}) => {
  const BASE_URL = isProduction() ? 'https://us-central1-plotu-prod.cloudfunctions.net' : 'https://us-central1-lenz-dev.cloudfunctions.net'
  const newCheckoutUrl = new URL(BASE_URL)
  newCheckoutUrl.pathname = 'createCheckoutSession'
  newCheckoutUrl.searchParams.append('planId', planId)
  newCheckoutUrl.searchParams.append('priceId', priceId)
  newCheckoutUrl.searchParams.append('successUrl', successUrl)
  newCheckoutUrl.searchParams.append('cancelUrl', cancelUrl)
  newCheckoutUrl.searchParams.append('clientId', clientId)
  newCheckoutUrl.searchParams.append('clientEmail', clientEmail)

  if (customerId) {
    newCheckoutUrl.searchParams.append('customerId', customerId)
  }

  if (hasTrial) {
    newCheckoutUrl.searchParams.append('hasTrial', hasTrial)
  }

  if (couponId) {
    newCheckoutUrl.searchParams.append('couponId', couponId)
  }

  window.location.href = newCheckoutUrl.toString()
}

export const goToPortal = ({ cancelUrl, stripeUserId }) => {
  const BASE_URL = isProduction() ? 'https://us-central1-plotu-prod.cloudfunctions.net' : 'https://us-central1-lenz-dev.cloudfunctions.net'
  const newCheckoutUrl = new URL(BASE_URL)
  newCheckoutUrl.pathname = 'createPortalSession'
  newCheckoutUrl.searchParams.append('cancelUrl', cancelUrl)
  newCheckoutUrl.searchParams.append('userId', stripeUserId)

  window.location.href = newCheckoutUrl.toString()
}
