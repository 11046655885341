import moment from 'moment'
import React from 'react'

import { Text } from 'components/Text'

import { t } from 'services/i18n'

const MAX_DAYS_FURTHER = 5

const getNextMostCloseEvent = (calendarData, maxDaysFurther = MAX_DAYS_FURTHER) => {
  return Object.keys(calendarData)
    .map(key => calendarData[key])
    .sort((event1, event2) => moment(event1.startDate).isBefore(moment(event2.startDate)))
    .filter(event =>
      moment(event.startDate).isSameOrAfter(moment()) &&
      moment(event.startDate).isSameOrBefore(moment().add(maxDaysFurther, 'days')))[0]
}

export const WelcomeComponent = props => {
  const calendarData = props.calendarData || {}
  const nextClosestEvent = getNextMostCloseEvent(calendarData)
  const nextClosestEventStartDate = nextClosestEvent && moment(nextClosestEvent.startDate)
  const nextClosestEventIsToday = nextClosestEventStartDate && nextClosestEventStartDate.isSame(moment(), 'day')

  return (
    <div>
      <Text type='subtitle2'>{t('back_office.dashboard.welcome_component.title', { name: props.user?.name })}</Text>
      <p>{t('back_office.dashboard.welcome_component.welcome_back')}</p>
      {nextClosestEvent && !nextClosestEventIsToday && (
        <p>
          {t('back_office.dashboard.welcome_component.do_not_forget_next_event', {
            'week_day': nextClosestEventStartDate.format('dddd'),
            'month_day': nextClosestEventStartDate.format('DD'),
          })}
        </p>
      )}
      {nextClosestEvent && nextClosestEventIsToday && (
        <p>
          {t('back_office.dashboard.welcome_component.do_not_forget_next_event_today', {
            'hours': nextClosestEventStartDate.format('HH[h]mm'),
          })}
        </p>
      )}
    </div>
  )
}
