import React, { useCallback, useRef, useState } from 'react'
import { CodeInput } from 'components/CodeInput'
import { logError } from 'utils/errorCapture'
import { useAlert } from 'hooks/useAlert'
import { Alert, Collapse } from '@mui/material'
import SmartphoneIcon from '@mui/icons-material/Smartphone'

import { t } from 'services/i18n'
import { getMFResolversAfterSignIn, verifyMFAAfterSignIn } from 'services/authenticationService'
import { Text } from 'components/Text'

import './styles.scss'
import { Button, LoadingButton } from 'components/Button'
import classNames from 'classnames'
import { Link } from 'components/Link'

export const MFASignInForm = ({
  onSuccess,
  signInError,
  secondaryActionButton,
  reauthenticationFlow,
  helperLink,
  history,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ errorContext, setErrorContext ] = useState('')
  const [ errorAlertIsOpen, openErrorAlert ] = useAlert()
  const TOTPVerificationCode = useRef('')

  const onChangeCode = useCallback(code => {
    TOTPVerificationCode.current = code
  }, [])

  const handleVerifyTOTPCode = async () => {
    const code = TOTPVerificationCode.current
    if (!code || code === '' || code.length < 6) {
      setErrorContext(t('profile_settings.account_settings.totp_modal.errors.invalid-verification-code'))
      openErrorAlert()
      return
    }

    await getMFResolversAfterSignIn(signInError)

    setIsLoading(true)
    verifyMFAAfterSignIn(0, signInError, code, reauthenticationFlow, history)
      .then(() => {
        onSuccess?.()
      })
      .catch(error => {
        logError(error)

        if (error.code === 'auth/invalid-verification-code') {
          setErrorContext(t('profile_settings.account_settings.totp_modal.errors.invalid-verification-code'))
        } else if (error.code === 'auth/too-many-enrollment-attempts') {
          setErrorContext(t('profile_settings.account_settings.totp_modal.errors.too-many-enrollment-attempts'))
        } else {
          setErrorContext(t('profile_settings.account_settings.totp_modal.errors.generic'))
        }
        openErrorAlert()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='mfa-sign-in-form'>
      <div className='mfa-sign-in-form__header'>
        <SmartphoneIcon />
        <Text type='title4'>{t('components.mfa_sign_in_form.form_label')}</Text>
      </div>
      <CodeInput
        autoSubmit
        onSubmit={handleVerifyTOTPCode}
        onChangeCode={onChangeCode}
        disabled={isLoading}
      />
      <Text type='body2' color='text__grey-color'>{t('components.mfa_sign_in_form.help')}</Text>
      <div
        className={classNames('mfa-sign-in-form__action-buttons', {
          'mfa-sign-in-form__action-buttons--multiple': secondaryActionButton,
        })}
      >
        {secondaryActionButton && (
          <Button
            variant='outlined'
            color='default'
            onClick={secondaryActionButton.onClick}
          >
            {secondaryActionButton.label}
          </Button>
        )}
        <LoadingButton
          variant='contained'
          loading={isLoading}
          onClick={handleVerifyTOTPCode}
        >
          {t('components.mfa_sign_in_form.verify_button')}
        </LoadingButton>
      </div>
      {helperLink && (
        <Link
          className='mfa-sign-in-form__trouble-link'
          underlined
          onClick={helperLink.onClick}
        >
          <Text type='body2' color='text__enhanced-grey-color'>
            {helperLink.text}
          </Text>
        </Link>
      )}
      <Collapse in={errorAlertIsOpen}>
        <Alert severity='error'>
          {errorContext}
        </Alert>
      </Collapse>
    </div>
  )
}
