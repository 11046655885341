import { useMemo } from 'react'

export const useBrowserIsWebview = () => {
  const browserProps = useMemo(() => {
    const standalone = window.navigator.standalone
    const userAgent = window.navigator?.userAgent.toLowerCase() || ''
    const safari = /safari/.test(userAgent)
    const ios = /iphone|ipod|ipad/.test(userAgent)

    if (ios) {
      if (!standalone && safari) {
        // Safari
        return {
          os: 'IOS',
          browserType: 'native',
          browserIsWebview: false,
        }
      } else if (!standalone && !safari) {
        // iOS webview
        return {
          os: 'IOS',
          browserType: 'webview',
          userAgent,
          browserIsWebview: true,
        }
      }
    } else {
      if (userAgent.includes('wv')) {
        // Android webview
        return {
          os: 'Android',
          browserType: 'webview',
          browserIsWebview: true,
        }
      } else {
        // Chrome
        return {
          os: 'Android',
          browserType: 'native',
          browserIsWebview: false,
        }
      }
    }
  }, [])

  return browserProps || {}
}
