import React, { useState } from 'react'

import { NOTIFICATION_TYPE } from 'components/GlobalNotifications'
import { BackdropLoading } from 'components/LoadingComponent'
import { PhotoPicker } from 'components/PhotoPicker'
import { Text } from 'components/Text'
import { UserInfoInput } from 'components/UserInfoInput'

import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'

import './styles.scss'

let userAvatar

const notificationSystem = getNotificationSystem()

export const ProfileSettings = ({ user, onUserInfoSave, editUserAndAvatar }) => {
  const [uploading, setUploading] = useState(false)

  const onUserAvatarChange = newAvatar => {
    userAvatar = newAvatar
  }

  const onSaveUserInfo = newUserInfo => {
    setUploading(true)

    if (userAvatar !== undefined) {
      return editUserAndAvatar(newUserInfo, userAvatar)
        .then(() => {
          notificationSystem.notify({ message: t('profile_settings.profile_settings.alert.success'), type: NOTIFICATION_TYPE.SUCCESS })
          setUploading(false)
        })
    }

    onUserInfoSave(newUserInfo)
      .then(() => {
        notificationSystem.notify({ message: t('profile_settings.profile_settings.alert.success'), type: NOTIFICATION_TYPE.SUCCESS })
        setUploading(false)
      })
  }

  return (
    <div>
      <Text
        className='profile-settings__title'
        as='h2'
        type='title3'
      >
        {t('profile_settings.profile_settings.title')}
      </Text>
      <PhotoPicker
        src={user.avatar}
        onImageChange={onUserAvatarChange}
      />
      <div className='profile-settings__user-info'>
        <UserInfoInput
          userType={user.type}
          initialName={user.name}
          initialDescription={user.description}
          initialServices={user.services}
          initialCategories={user.categories}
          initialExternalLinks={user.externalLinks}
          initialLocation={user.location}
          onSaveUserInfo={onSaveUserInfo}
        />
      </div>
      <BackdropLoading isLoading={uploading} />
    </div>
  )
}
