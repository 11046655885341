import React, { useEffect } from 'react'
import Joyride from 'react-joyride'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { ErrorBoundary } from 'components/ErrorBoundary'

import { Calendar } from './scenes/Calendar'
import { Dashboard } from './scenes/Dashboard'
import { Event } from './scenes/Event'
import { EventGallery } from './scenes/EventGallery'
import { Events } from './scenes/Events'
import { Galleries } from './scenes/Galleries'
import { Layout } from './scenes/Layout'
import { Packs } from './scenes/Packs'

import { BackOfficeFooterMenu } from './components/BackOfficeFooterMenu'
import { SideMenu } from './components/SideMenu'

import { useLocalActions } from './actions/useLocalActions.js'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { currentLogedInUser } from 'store/users/selectors'

import { subRoutesNames } from 'services/routingService'

import cssVariables from 'styles/theme/colors.scss'
import './styles.scss'
import { useBackofficeWalkthrough, WALKTHROUGH_KEY } from './hooks/useBackofficeWalkthrough'
import { BACK_OFFICE_PAGES_CONFIG } from './constants/config'

const BACK_OFFICE_PAGES_URLS = subRoutesNames.BACK_OFFICE

const renderBackOfficePage = (
  backOfficePage,
  backOfficeSubPage
) => {
  switch (backOfficePage) {
    case BACK_OFFICE_PAGES_URLS.CALENDAR:
      return <Calendar />
    case BACK_OFFICE_PAGES_URLS.EVENTS:
      if (backOfficeSubPage) {
        return <Event />
      }
      return <Events />
    case BACK_OFFICE_PAGES_URLS.GALLERIES:
      if (backOfficeSubPage) {
        return <EventGallery />
      }
      return <Galleries />
    case BACK_OFFICE_PAGES_URLS.PACKS:
      return <Packs />
    case BACK_OFFICE_PAGES_URLS.LAYOUT:
      return <Layout />
    default:
      return <Dashboard />
  }
}

const BackOffice = () => {
  const { page: backOfficePage } = useParams()
  const { subPage: backOfficeSubPage } = useParams()
  const { isMobile, isSmallTablet, isTabletAndUp } = useBreakpoints()
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(currentLogedInUser)
  const history = useHistory()

  const walkthroughData = logedInUser.walkthroughData?.[WALKTHROUGH_KEY] || {}
  const {
    walkthroughStepIndex,
    walkthroughIsRunning,
    walkthroughIsContinuous,
    handleJoyrideCallback,
    walkthroughSteps,
  } = useBackofficeWalkthrough(walkthroughData, logedInUser)

  const {
    fetchUser,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    // logedInUserId as a dependecy to perform only one request
    logedInUserId && fetchUser()
  }, [fetchUser, logedInUserId])

  return (
    <article className='backoffice'>
      <ErrorBoundary silenceError>
        <Joyride
          disableScrolling
          showProgress
          steps={walkthroughSteps}
          continuous={walkthroughIsContinuous}
          locale={{
            back: 'Anterior', close: 'Fechar', last: 'Fim', next: 'Próximo', open: 'Abrir', skip: 'Ignorar',
          }}
          styles={{
            options: {
              primaryColor: cssVariables.primaryColor,
              width: 480,
              zIndex: 1000,
            },
          }}
          callback={handleJoyrideCallback}
          run={walkthroughIsRunning}
          stepIndex={walkthroughStepIndex}
          showSkipButton
        />
      </ErrorBoundary>
      {isTabletAndUp && (
        <SideMenu
          menuItems={BACK_OFFICE_PAGES_CONFIG}
          activeId={backOfficePage}
        />
      )}
      <div className='backoffice__content'>
        <ErrorBoundary>
          {renderBackOfficePage(
            backOfficePage,
            backOfficeSubPage
          )}
        </ErrorBoundary>
      </div>
      {(isMobile || isSmallTablet) && (
        <BackOfficeFooterMenu
          list={BACK_OFFICE_PAGES_CONFIG}
          history={history}
          activeId={backOfficePage}
        />
      )}
    </article>
  )
}

export default BackOffice
