import classNames from 'classnames'
import React, { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import './styles.scss'

export const Link = React.forwardRef((props, ref) => {
  const [isHover, setIsHover] = useState(false)
  const { className, underlined, href, external, target, disabled, colored, ...otherProps } = props

  if (external || !href?.startsWith('/') || target) {
    return (
      <a
        ref={ref}
        href={href}
        className={classNames(
          'link',
          {
            'link--hover-underlined': underlined && isHover,
            'link--underlined': underlined,
            'link--disabled': disabled,
            'link--colored': colored,
          },
          className
        )}
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
        target={target || '_blank'}
        {...otherProps}
      >
        {props.children}
      </a>
    )
  }

  return (
    <ReactRouterLink
      ref={ref}
      to={href}
      className={classNames(
        'link',
        {
          'link--hover-underlined': underlined && isHover,
          'link--underlined': underlined,
          'link--colored': colored,
        },
        className
      )}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      {...otherProps}
    >
      {props.children}
    </ReactRouterLink>
  )
})
