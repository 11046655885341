import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { Text } from 'components/Text'
import UserCircular from 'components/UserCircular'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { createPackLink, createUserLink } from 'services/routingService'

import './styles.scss'

export const PackPinLiteSkeleton = props => (
  <div className={classNames('pin-lite', props.className)}>
    <Skeleton className='pin-lite__skeleton' animation='wave' variant='rectangular' width='100%' height={376} />
  </div>
)

export const PackPinLite = props => {
  const WrapperElmt = props.onClick ? 'div' : Link
  const { isTabletAndUp } = useBreakpoints()

  return (
    <article className={classNames('pin-lite', props.className)}>
      <WrapperElmt
        className={classNames('pin-lite__wrapper', {
          'pin-lite__header__wrapper--no-image': props.headerImageSrc === undefined || props.headerImageSrc === '',
        })}
        onClick={props.onClick}
        href={!props.onClick && createPackLink(props.packId)}
      >
        <Image
          height={isTabletAndUp ? 340 : 280}
          sized
          className='pin-lite__image'
          alt='pin header'
          src={props.headerImageSrc}
          thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
          generateThumbnailIfError
        />
      </WrapperElmt>
      <div className='pin-lite__content'>
        {props.userUid && (
          <Link underlined href={createUserLink(props.userUid)}>
            <UserCircular
              className='pin-lite__content__user'
              avatar={props.footerImgSrc}
              withFallback
              name={props.name}
              vertical
            />
          </Link>
        )}
        <div className='pin-lite__content__footer'>
          <Text bold lineLimit={1} className='pin-lite__content__title'>{props.title}</Text>
          {props.price && (
            <p className='pin-lite__content__price'>
              {props.price.priceFrom && ' Desde '}
              <b className='pin-lite__content__price__value'>{props.price.value}</b>
              €
            </p>
          )}
        </div>
      </div>
    </article>
  )
}
