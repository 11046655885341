import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import React, { Fragment, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from 'components/Button'
import { ImageListInput } from 'components/ImageListInput'
import { KeyValueInput } from 'components/KeyValueInput'
import { MultipleInputList } from 'components/MultipleInputList'
import { Text } from 'components/Text'

import { InformationsInput } from './components/InformationsInput'

import { useAlert } from 'hooks/useAlert'
import { useRestrictedQuota } from 'hooks/useRestrictedQuota'

import { Pack } from 'model/Pack'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import { getImagesTotalSize } from 'utils/images'

import './styles.scss'

const mapFaqsToKeyValue = (data = []) =>
  data.map(item => ({ key: item.question, value: item.answer }))

const mapQuickInfo = (data = []) =>
  data.map(item => ({ icon: item.iconId, title: item.title, description: item.body }))

export const PackInput = props => {
  const [ title, setTitle ] = useState(props.initialPack?.title || '')
  const [ visibility, setVisibility ] = useState(props.initialPackVisibility ?? true)
  const [ categories, setCategories ] = useState(props.initialPack?.categories || [])
  const [ price, setPrice ] = useState(props.initialPack?.price || {})
  const [ quickInfo, setQuickInfo ] = useState(mapQuickInfo(props.initialPack?.quickInfo) || [])
  const [ images, setImages ] = useState(props.initialPack?.images || [])
  const [ mainImageId, setMainImageId ] = useState(props.initialPack?.mainImageId || null)
  const [ faqs, setFaqs ] = useState(mapFaqsToKeyValue(props.initialPack?.faqs) || [])
  const [ description, setDescription ] = useState(props.initialPack?.description || '')
  const titleRef = useRef()
  const [ alertIsOpen, openAlert ] = useAlert()
  // Caution with this images variable, since it also has the previously images saved in the pack.
  // However, since those previous images do not have any size or file.size, it works
  const [hasQuota, openUpsell] = useRestrictedQuota(getImagesTotalSize(images))

  const onTitleChange = event =>
    setTitle(event.target.value)

  const onVisibilityChange = event =>
    setVisibility(event.target.checked)

  const onCategoriesChange = value => {
    setCategories(value)
  }

  const publishPack = () => {
    if (!hasQuota) {
      openUpsell()
      return
    }

    const media = { images, mainImageId }
    const newPack = new Pack({
      id: props.initialPack?.id || uuidv4(),
      title,
      price,
      description,
      categories,
      quickInfo: quickInfo.map(quickInfo => ({
        ...quickInfo,
        iconId: quickInfo.icon,
      })),
      faqs: faqs.map(faq => ({
        question: faq.key,
        answer: faq.value,
      })),
      media,
      userUid: props.user?.uid,
      createdTimestamp: props.initialPack?.createdTimestamp || Date.now(),
      lastModifiedTimestamp: Date.now(),
      visibility,
    })
    if (!title) {
      openAlert()
      titleRef.current.scrollIntoView()
      return
    }

    props.savePack && props.savePack(newPack, visibility)
  }

  const onDescriptionChange = description => {
    setDescription(description)
  }

  const onQuickInfoChange = quickInfo => {
    setQuickInfo(quickInfo)
  }

  const onPriceChange = priceInfo => {
    setPrice(priceInfo)
  }

  const onFaqsChange = faqs => {
    setFaqs(faqs)
  }

  const onImagesChange = images => {
    setImages(images)
  }

  const onMainImageIdChange = mainImageId => {
    setMainImageId(mainImageId)
  }

  return (
    <Fragment>
      <div className='pack-input__section pack-input__section--no-margin'>
        <Text type='subtitle' className='pack-input__section__title'>{t('components.pack_input.title_and_categories.title')}</Text>
        <Text type='body2' color='text__secondary-color' className='pack-input__section__sub-title'>{t('components.pack_input.title_and_categories.sub-title')}</Text>
        <TextField
          className='pack-input__title'
          id='pack-input__title'
          value={title}
          onChange={onTitleChange}
          ref={titleRef}
        />
        <Collapse className='appointment__alert' in={alertIsOpen}>
          <Alert severity='warning'>{t('components.pack_input.no_title_on_pack_alert')}</Alert>
        </Collapse>
        <Text type='body2' color='text__secondary-color' className='pack-input__section__sub-title'>{t('components.pack_input.title_and_categories.sub-title-2')}</Text>
        <div className='pack-input__categories'>
          <MultipleInputList
            initialValues={categories}
            inputLabel={t('components.pack_input.categories.title')}
            onChange={onCategoriesChange}
            freeSolo
            options={getConfigValue('general.categories.options')?.map(option => t(option))}
          />
        </div>
        <div className='pack-input__visibility-checkbox'>
          <FormControlLabel
            control={(
              <Switch
                checked={visibility}
                onChange={onVisibilityChange}
                name='pack-visibility-checkbox'
                color='primary'
                inputProps={{ 'aria-label': 'pack visibility checkbox' }}
              />
            )}
            label={t('components.pack_input.visible')}
          />
        </div>
      </div>

      <div className='pack-input__section'>
        <Text type='subtitle' className='pack-input__section__title'>{t('components.pack_input.info_input.title')}</Text>
        <Text type='body2' color='text__secondary-color' className='pack-input__section__sub-title'>{t('components.pack_input.info_input.sub-title')}</Text>
        <InformationsInput
          initialInfo={description}
          initialQuickInfo={quickInfo}
          initialPrice={price}
          onChange={onDescriptionChange}
          onPriceChange={onPriceChange}
          onQuickInfoChange={onQuickInfoChange}
        />
      </div>

      <div className='pack-input__section'>
        <Text type='subtitle' className='pack-input__section__title'>{t('components.pack_input.faq.title')}</Text>
        <Text type='body2' color='text__secondary-color' className='pack-input__section__sub-title'>{t('components.pack_input.faq.sub-title')}</Text>
        <KeyValueInput
          keyInputLabel='Pergunta'
          valueInputLabel='Insere a resposta'
          moreButtonLabel='FAQ'
          initialKeyValues={faqs}
          onChange={onFaqsChange}
        />
      </div>
      <div className='pack-input__section pack-input__section--last'>
        <Text type='subtitle' className='pack-input__section__title'>{t('components.pack_input.photos.title')}</Text>
        <Text type='body2' color='text__secondary-color' className='pack-input__section__sub-title'>{t('components.pack_input.photos.sub-title')}</Text>
        <ImageListInput
          initialImages={images}
          initialMainImageId={mainImageId}
          multiple
          onChange={onImagesChange}
          onMainImageIdChange={onMainImageIdChange}
        />
      </div>
      <div className='pack-input__footer'>
        <div className='pack-input__footer__action-buttons'>
          <Button
            variant='outlined'
            onClick={props.onCancel}
          >
            {t('components.pack_input.action_buttons.cancel')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={publishPack}
          >
            {t('components.pack_input.action_buttons.save')}
          </Button>
        </div>
      </div>
    </Fragment>
  )
}
