import React from 'react'

import { LinkButton } from 'components/Button'

import { createHomeLink } from 'services/routingService'

import './styles.scss'

export const UserNotFoundError = () => {
  return (
    <div className='user-not-found-error'>
      <h1>Utilizador não encontrado</h1>
      <p>Oops, parece que o utilizador que estás a tentar encontrar não existe</p>
      <LinkButton
        href={createHomeLink()}
      >
        Página inicial
      </LinkButton>
    </div>
  )
}
