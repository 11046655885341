import React from 'react'

import { ContentRow } from '../ContentRow'

import { t } from 'services/i18n'

import './styles.scss'

export const LastSearches = () => {
  const lastSearches = []

  if (!lastSearches?.length) {
    return null
  }

  return (
    <div className='last-searches'>
      {t('components.search.last_searches.title')}
      <button className='last-searches__clean-search-history'>{t('components.search.last_searches.clean')}</button>
      <div className='last-searches__content'>
        <ol>
          <li>
            {[].map(search => <ContentRow key={search.id} value={search} />)}
          </li>
        </ol>
      </div>
    </div>
  )
}
