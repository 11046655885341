import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React from 'react'
import { FaRegHeart, FaHeart } from 'react-icons/fa'
import { MdRemoveRedEye, MdInsertComment } from 'react-icons/md'

import { IconLabel } from 'components/IconLabel'
import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { Text } from 'components/Text'
import UserCircular from 'components/UserCircular'

import { createPackLink, createUserLink } from 'services/routingService'

import './styles.scss'

export const PackPinSkeleton = () => (
  <div className='pin'>
    <Skeleton animation='wave' variant='rectangular' width='100%' height={220} />
    <Skeleton animation='wave' variant='text' width={140} />
    <div className='pin__skeleton__user' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Skeleton animation='wave' variant='circular' width={40} height={40} />
      <Skeleton animation='wave' variant='text' width={140} height={20} />
    </div>
  </div>
)

export const PackPin = props => {
  const WrapperElmt = props.onClick ? 'div' : Link

  return (
    <article className={classNames('pin', props.className)}>
      <header className='pin__header'>
        <WrapperElmt
          className={classNames('pin__header__wrapper', {
            'pin__header__wrapper--no-image': props.headerImageSrc === undefined || props.headerImageSrc === '',
          })}
          onClick={props.onClick}
          href={!props.onClick && createPackLink(props.packId)}
        >
          {props.headerLabel && <div className={`pin__header__label pin__header__label--${props.headerLabel}`}>{props.headerLabel}</div>}
          <Image
            width={props.headerImageSize.imgWidth}
            height={props.headerImageSize.imgHeight}
            maxHeight={500}
            minHeight={200}
            className='pin__header__image'
            alt='pin header'
            src={props.headerImageSrc}
            thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
            generateThumbnailIfError
          />
          {!props.noInfo && (
            <div className='pin__header__interaction'>
              <IconLabel icon={MdInsertComment} label='10' inverted />
              <IconLabel icon={MdRemoveRedEye} label='10' inverted />
              <IconLabel
                className={classNames('pin__header__interaction--heart', { 'pin__header__interaction--fill-red': props.like })}
                icon={props.like ? FaHeart : FaRegHeart}
              />
            </div>
          )}
        </WrapperElmt>
      </header>
      <WrapperElmt
        className={classNames('pin__title-wrapper-anchor', {
          'pin__title-wrapper-anchor--with-top-margin': !props.userUid,
        })}
        underlined={!props.onClick}
        href={!props.onClick && createPackLink(props.packId)}
      >
        <Text bold>{props.title}</Text>
        {props.price && (
          <p className='pin__price'>
            {props.price.priceFrom && ' Desde '}
            <b className='pin__price__value'>{props.price.value}</b>
            €
          </p>
        )}
      </WrapperElmt>
      {props.userUid && (
        <Link underlined href={createUserLink(props.userUid)}>
          <UserCircular
            className='pin__user'
            avatar={props.footerImgSrc}
            withFallback
            name={props.name}
          />
        </Link>
      )}
      {props.description && (
        <Text
          as='p'
          type='body2'
          className='pin__description'
          color='text__secondary-color'
          lineLimit={2}
        >
          {props.description}
        </Text>
      )}
    </article>
  )
}
