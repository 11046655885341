import { v4 as uuidv4 } from 'uuid'

import { UPLOAD_STATUS } from 'model/UploadStatus'
import { User } from 'model/User'

import { createUserChangeListener } from 'services/firebaseServer'
import {
  updatePackObject,
} from 'services/searchService'

import {
  getUser,
  getUserByUsername,
  postUser,
  editUser,
  postUserImage,
  postPortfolioImage,
  deleteImages,
  incrementUserGallery,
  putUserPortfolio,
  postUserPortfolioImage,
} from 'services/serverBridge'

export const REQUEST_USER = 'REQUEST_USER'
export const RECEIVE_USER = 'RECEIVE_USER'
export const EDIT_USER = 'EDIT_USER'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_USER_STORAGE_SIZE = 'SET_USER_STORAGE_SIZE'
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER'

function requestUserAction() {
  return {
    type: REQUEST_USER,
  }
}

function receiveUserAction(user) {
  return {
    type: RECEIVE_USER,
    data: user,
  }
}

function editUserAction(user, resetData = false) {
  return {
    type: EDIT_USER,
    data: {
      user,
      resetData,
    },
  }
}

function setCurrentUserAction(user) {
  const newUser = User.userFromAuthenticationServiceFactory(user)
  return {
    type: SET_CURRENT_USER,
    data: {
      user: newUser,
    },
  }
}

function setUserStorageSizeAction(userUid, storageSize) {
  return {
    type: SET_USER_STORAGE_SIZE,
    data: {
      userUid,
      storageSize,
    },
  }
}

function removeCurrentUserAction() {
  return {
    type: REMOVE_CURRENT_USER,
  }
}

export const setCurrentUser = setCurrentUserAction

export const setUserStorageSize = setUserStorageSizeAction

export const removeCurrentUser = removeCurrentUserAction

export const fetchUser = (userId, cached) => {
  return (dispatch, getState) => {
    // TODO: Add proper caching system with FIFO queue and cache timeout
    // Do not forget to force current user fetch when the user state is not completed yet
    const users = getState().users.users
    if (users[userId] && cached) {
      dispatch(receiveUserAction(users[userId]))
      return
    }

    dispatch(requestUserAction())
    return getUser(userId)
      .then(user => {
        if (user?.uid) {
          dispatch(receiveUserAction(user))
          return user
        } else {
          throw new Error('User not found')
        }
      })
  }
}

export const fetchUserByUsername = (username, cached) => {
  return (dispatch, getState) => {
    // TODO: Add proper caching system with FIFO queue and cache timeout
    // Do not forget to force current user fetch when the user state is not completed yet
    if (cached) {
      const users = getState().users.users
      const usernameUserKey = Object.keys(users).find(userKey => users[userKey].username === username)
      if (usernameUserKey) {
        dispatch(receiveUserAction(users[usernameUserKey]))
        return
      }
    }

    dispatch(requestUserAction())
    return getUserByUsername(username)
      .then(user => {
        if (user?.uid) {
          dispatch(receiveUserAction(user))
        } else {
          throw new Error('User not found')
        }
      })
  }
}

// Although these actions could be optimized in order to not update the whole user,
// for now it's ok since the operations to set the user are not frequent

export const addUser = user => {
  return dispatch => {
    const newUser = new User(user)
    dispatch(editUserAction(newUser))
    return postUser(newUser)
  }
}

export const addUserPack = (user, packId, addToStart, visible, postToServer = true) => {
  return dispatch => {
    const editedUser = new User(user)
    if (visible) {
      editedUser.addUserPack(packId, addToStart)
    } else {
      editedUser.addUserHiddenPack(packId)
    }

    dispatch(editUserAction(editedUser))

    if (!postToServer) {
      return () => editUser(editedUser)
    }

    return editUser(editedUser)
      .then(() => editedUser)
  }
}

export const addUserHiddenPack = (user, packId) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.addUserHiddenPack(packId)
    dispatch(editUserAction(editedUser))
    return editUser(editedUser)
  }
}

export const hideUserPack = (user, packId) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.removeUserPack(packId)
    editedUser.addUserHiddenPack(packId)
    dispatch(editUserAction(editedUser))

    return Promise.all([
      editUser(editedUser),
      updatePackObject({
        id: packId,
        visibility: false,
      }),
    ])
  }
}

export const removeUserPack = (user, packId, packVisibility) => {
  return dispatch => {
    const editedUser = new User(user)
    if (packVisibility) {
      editedUser.removeUserPack(packId)
    } else {
      editedUser.removeUserHiddenPack(packId)
    }
    dispatch(editUserAction(editedUser))

    return editUser(editedUser)
  }
}

export const showUserHiddenPack = (user, packId) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.removeUserHiddenPack(packId)
    editedUser.addUserPack(packId)
    dispatch(editUserAction(editedUser))

    return Promise.all([
      editUser(editedUser),
      updatePackObject({
        id: packId,
        visibility: true,
      }),
    ])
  }
}

export const editUserLayout = (user, layoutBreakpoint, layoutData) => {
  return dispatch => {
    const userPortfolioLayouts = user.portfolio?.layouts || {}
    const userPortfolioLayoutsData = user.portfolio?.layoutsData || {}

    const currentRemoteLayoutId = Object.keys(userPortfolioLayouts || {})
      .find(layoutKey => userPortfolioLayouts[layoutKey].breakpoints?.includes(layoutBreakpoint))

    const layoutsData = { ...userPortfolioLayoutsData || {} }

    const layouts = { ...userPortfolioLayouts || {} }

    // for retrocompatibility (when there were no layouts), if default layout is not created yet, create it
    if (!userPortfolioLayouts.default) {
      layouts['default'] = {
        name: 'Default Layout',
        id: 'default',
      }

      layoutsData['default'] = {
        cards: layoutData,
      }
    }

    // if layout info is not created yet (for instance, if the portfolio cards were first added in the UserDetails page), let's create it first
    if (!currentRemoteLayoutId && layoutBreakpoint) {
      const newLayoutId = uuidv4()

      layouts[newLayoutId] = {
        name: `${layoutBreakpoint} Default Layout`,
        breakpoints: [layoutBreakpoint],
        id: newLayoutId,
      }

      layoutsData[newLayoutId] = {
        cards: layoutData,
      }
    } else {
      layoutsData[currentRemoteLayoutId] = {
        cards: layoutData,
      }
    }

    const editedUser = new User(user)
    editedUser.setPortfolio({
      layouts,
      layoutsData,
      cards: user.portfolio?.cards || {},
    })
    dispatch(editUserAction(editedUser))
    return editUser(editedUser)
  }
}

export const addUserPortfolioCards = (user, portfolioCards, layoutBreakpoint, addToStart = false) => {
  return async dispatch => {
    const userPortfolioLayouts = user.portfolio?.layouts || {}
    const userPortfolioLayoutsData = user.portfolio?.layoutsData || {}
    const currentRemoteLayoutId = Object.keys(userPortfolioLayouts)
      .find(layoutKey => userPortfolioLayouts[layoutKey].breakpoints?.includes(layoutBreakpoint))

    const layouts = { ...userPortfolioLayouts || {} }
    const layoutsData = { ...userPortfolioLayoutsData || {} }

    let shouldPopulatePortfolio = false

    // if user has not a default layout, let's add it to user's layouts
    if (!userPortfolioLayouts.default) {
      layouts['default'] = {
        name: 'Default Layout',
        id: 'default',
      }

      // cards will be added later with addToUserPortfolio function
      layoutsData['default'] = { cards: [] }
      shouldPopulatePortfolio = true
    }

    // if user has not a layout for the specified layoutBreakpoint, a new layout should be created
    if (!currentRemoteLayoutId && layoutBreakpoint) {
      const newLayoutId = uuidv4()

      layouts[newLayoutId] = {
        name: `${layoutBreakpoint} Default Layout`,
        breakpoints: [layoutBreakpoint],
        id: newLayoutId,
      }

      // cards will be added later with addToUserPortfolio function
      layoutsData[newLayoutId] = { cards: [] }
      shouldPopulatePortfolio = true
    }

    const userPorfolio = {
      layouts,
      layoutsData,
      cards: user.portfolio?.cards || {},
    }

    // if portfolio data is not set yet (for instance, a new layout is being added or it's the first time adding a photo to portfolio)
    // we should initiate the portfolio data data first
    // we could move this to postUserPortfolioImage but since this should happen only a few times, I don't think it's worth it to run this logic in each image upload
    if (shouldPopulatePortfolio) {
      await putUserPortfolio(user.uid, userPorfolio.cards, userPorfolio.layoutsData, userPorfolio.layouts)
    }

    /**
     * Algorithm explanation:
     * Firstly, we need to update the store as soon as possible with the new local photos
     * After that, we want to send the photos, one by one, to the server and update it in the local store with the new src once they got uploaded.
     */

    const editedUser = new User({ ...user })
    editedUser.setPortfolio({ ...userPorfolio })
    const uploadingPortfolioCards = portfolioCards.map(card => ({ ...card, uploadStatus: UPLOAD_STATUS.UPLOADING }))
    editedUser.addToUserPortfolio(uploadingPortfolioCards, addToStart) // add before uploading images to set UI immediately
    dispatch(editUserAction(editedUser))

    // listen for portfolio changes to check when the photos thumbnails are created
    const creatingThumbnailsImageIDs = portfolioCards.map(card => card.id)
    const unsubscribe = createUserChangeListener(user.uid, data => {
      creatingThumbnailsImageIDs.forEach((creatingThumbnailsImageID, index) => {
        const image = data.portfolio.cards[creatingThumbnailsImageID]
        const imageThumbs = image?.thumbnails || {}
        if (imageThumbs.s && imageThumbs.m && !imageThumbs.loading) {
          // sync new image src and thumbnails to store, only after every thumbnail is uploaded to the database, so that we don't download the full res image
          editedUser.portfolio.cards[creatingThumbnailsImageID] = image
          dispatch(editUserAction(editedUser))

          // remove the ID from the creatingThumbnailsImageIDs array
          creatingThumbnailsImageIDs.splice(index, 1)
        }
      })

      // unsubcribe the listener if all thumbnails of every image were uploaded
      if (creatingThumbnailsImageIDs.length <= 0) {
        unsubscribe()
      }
    })

    portfolioCards.forEach(item =>
      postPortfolioImage(item, user.uid)
        .then(imageSrc => {
          const parsedImage = {
            src: imageSrc,
            width: item.width,
            height: item.height,
            id: item.id,
            thumbnails: item.thumbnails,
          }

          // send image to Database
          return postUserPortfolioImage(user.uid, parsedImage)
            .then(() => {
              // set uploaded state as soon as possible to remove the uploading UI feedback
              // this can only be done here since only in postUserPortfolioImage, porfolio.layoutsData & cards are updated
              editedUser.portfolio.cards[item.id].uploadStatus = UPLOAD_STATUS.UPLOADED
              dispatch(editUserAction(editedUser))
            })
            .catch(() => {
              // remove photo from creatingThumbnailsImageIDs so that we're not waiting for it to update in the database
              const index = creatingThumbnailsImageIDs.indexOf(item.id)
              if (index > -1) {
                creatingThumbnailsImageIDs.splice(index, 1)
              }
            })
        }))
  }
}

export const removeUserPortfolioCards = (user, photoId) => {
  return dispatch => {
    const toRemoveImagesSrc = user.portfolio.cards[photoId].src
    const editedUser = new User(user)
    editedUser.removeFromUserPortfolio(photoId)

    dispatch(editUserAction(editedUser))

    const cards = editedUser.portfolio.cards
    const layoutsData = editedUser.portfolio.layoutsData
    return putUserPortfolio(user.uid, cards, layoutsData)
      .then(() => {
        deleteImages([toRemoveImagesSrc])
      })
  }
}

export const editUserPacks = (user, packIds) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.setPackIds({ packIds })
    dispatch(editUserAction(editedUser))
    return editUser(editedUser)
  }
}

export const incrementUserGalleryCountLocally = (user, increment = true, portefolioCount = false) => {
  return dispatch => {
    const editedUser = new User(user)
    if (increment) {
      editedUser.incrementGalleryCount(portefolioCount)
    } else {
      editedUser.decrementGalleryCount(portefolioCount)
    }
    dispatch(editUserAction(editedUser))
    return editedUser
  }
}

export const incrementUserGalleryCount = (user, increment = true, portefolioCount = false) => {
  return dispatch => {
    const editedUser = dispatch(incrementUserGalleryCountLocally(user, increment, portefolioCount))
    return incrementUserGallery(editedUser, increment, portefolioCount)
  }
}

export const editUserInfo = (user, info, resetData = false) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.setInfo({ ...info })
    dispatch(editUserAction(editedUser, resetData))
    return editUser(editedUser)
  }
}

export const updateWalkthroughData = (user, walkthroughKey, walkthroughData) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.setWalkthroughData(walkthroughKey, walkthroughData)
    dispatch(editUserAction(editedUser, false))
    return editUser(editedUser, true, false)
  }
}

export const updateUserPlan = (user, info) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.setInfo({ ...info })
    dispatch(editUserAction(editedUser))
  }
}

export const editUserAvatar = (user, avatarFile, resetData = false) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.setAvatar(avatarFile.src || '')
    dispatch(editUserAction(editedUser, resetData))

    if (!avatarFile.file && !avatarFile.blob) {
      return editUser(editedUser)
    }

    return postUserImage(avatarFile, user.uid)
      .then(imgSrc => {
        editedUser.setAvatar(imgSrc)
        dispatch(editUserAction(editedUser, resetData))
        editUser(editedUser)
      })
  }
}

export const editUserAndAvatar = (user, info, avatarFile, resetData = false) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.setInfo({ ...info })
    if (avatarFile !== undefined) {
      editedUser.setAvatar(avatarFile.src || '')
    }

    dispatch(editUserAction(editedUser, resetData))

    // if avatar is one of the generic avatars, we just upload the user data
    if (!avatarFile?.file && !avatarFile?.blob) {
      return editUser(editedUser)
    }

    return postUserImage(avatarFile, user.uid)
      .then(imgSrc => {
        editedUser.setAvatar(imgSrc)
        dispatch(editUserAction(editedUser, resetData))
        return editUser(editedUser)
      })
  }
}

export const addUserWatermark = (user, watermark) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.addUserWatermark(watermark.id)

    dispatch(editUserAction(editedUser))
  }
}

export const removeUserWatermark = (user, watermark) => {
  return dispatch => {
    const editedUser = new User(user)
    editedUser.removeUserWatermark(watermark.id)

    dispatch(editUserAction(editedUser))
  }
}
