import React from 'react'

import { Grid } from 'components/Grid'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import './styles.scss'

const StartEaseItem = ({ title, subTitle, iconId }) => (
  <div className='start-ease__item'>
    <Icon fontSize='large' className='start-ease__item__icon'>{iconId}</Icon>
    <Text as='h3' type='title3' color='text__default-color'>
      {title}
    </Text>
    <Text className='start-ease__item__sub-title' color='text__primary-color'>
      {subTitle}
    </Text>
  </div>
)

export const StartEase = () => {
  const startEaseItems = (getConfigValue('professionals.start_ease.items') || [])
    .map(startEaseItem => ({
      title: t(startEaseItem.title),
      subTitle: t(startEaseItem.sub_title),
      iconId: startEaseItem.icon_id,
    }))

  return (
    <div className='start-ease'>
      <h2 className='start-ease__title'>{t('professionals.start_ease.title')}</h2>
      <Grid className='start-ease__grid' xs={12} md={6}>
        {startEaseItems
          .map(startEaseItem => (
            <StartEaseItem
              key={startEaseItem.title}
              title={startEaseItem.title}
              subTitle={startEaseItem.subTitle}
              iconId={startEaseItem.iconId}
            />
          ))}
      </Grid>
    </div>
  )
}
