import {
  searchAll,
  searchUsersIndex,
  searchPackIndex,
  searchCategoriesIndex,
} from 'services/searchService'

import { isGreaterThan, now } from 'utils/date'
import { getQueryResult, SEARCH_GROUP_IDS, parseResults } from 'utils/search'

export const SORT_QUERY_CACHE = 'SORT_QUERY_CACHE'
export const START_SEARCH_QUERY = 'START_SEARCH_QUERY'
export const ADD_QUERY_CACHE = 'ADD_QUERY_CACHE'
export const REMOVE_QUERY_CACHE = 'REMOVE_QUERY_CACHE'

const FIVE_MINUTES_IN_MS = 300000

function startFetchingQueryAction() {
  return {
    type: START_SEARCH_QUERY,
  }
}

function addQueryToCacheAction(query, searchGroup, hits, metadata) {
  return {
    type: ADD_QUERY_CACHE,
    data: { query, searchGroup, hits, metadata },
  }
}

function removeQueryFromCacheAction (query, searchGroup) {
  return {
    type: REMOVE_QUERY_CACHE,
    data: { query, searchGroup },
  }
}

function sortQueryCacheAction(query, searchGroup) {
  return {
    type: SORT_QUERY_CACHE,
    data: { query, searchGroup },
  }
}

const searchResults = (searchGroup, searchQuery, pageIndex) => {
  if (searchGroup === SEARCH_GROUP_IDS.ALL) {
    return searchAll(searchQuery, pageIndex)
  } else {
    switch (searchGroup) {
      case SEARCH_GROUP_IDS.PACKS:
        return searchPackIndex(searchQuery, pageIndex)
      case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
        return searchUsersIndex(searchQuery, pageIndex, true)
      case SEARCH_GROUP_IDS.CUSTOMERS:
        return searchUsersIndex(searchQuery, pageIndex, false)
      case SEARCH_GROUP_IDS.CATEGORIES:
        return searchCategoriesIndex(searchQuery, pageIndex)
      default:
        return Promise.resolve([])
    }
  }
}

export const searchQuery = (query, searchGroup = SEARCH_GROUP_IDS.ALL, pageIndex) => {
  return (dispatch, getState) => {
    const cachedQueryResult = getQueryResult(
      getState().search.searchQueries, query, searchGroup, pageIndex
    )

    if (cachedQueryResult) {
      // if cached value is newer than 5 minutes, return it
      if (isGreaterThan(cachedQueryResult.cachedTime, now(), FIVE_MINUTES_IN_MS)) {
        dispatch(sortQueryCacheAction(query, searchGroup, pageIndex))
        return Promise.resolve(cachedQueryResult.hits)
      } else {
        dispatch(removeQueryFromCacheAction(query, searchGroup, pageIndex))
      }
    }

    dispatch(startFetchingQueryAction())
    return searchResults(searchGroup, query, pageIndex)
      .then(results => {
        const { parsedResults, metadata } = parseResults(results, searchGroup)
        dispatch(addQueryToCacheAction(query, searchGroup, parsedResults, metadata))
        return parsedResults
      })
  }
}
