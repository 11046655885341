import { REQUEST_FEED, RECEIVE_FEED } from './actions'

const feed = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_FEED:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_FEED:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    default:
      return state
  }
}

export default feed
