import moment from 'moment'
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  deleteUser as deleteUserFirebase,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateProfile,
  browserSessionPersistence,
  setPersistence,
  browserLocalPersistence,
  multiFactor,
  TotpMultiFactorGenerator,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
  reload,
  fetchSignInMethodsForEmail,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
} from 'firebase/auth'

import { store } from 'store/root/store'
import { setCurrentUser, setUserStorageSize, addUser, fetchUser, removeCurrentUser, editUserInfo } from 'store/users/actions'

import { createUserStorageChangeListener, getAuth } from 'services/firebaseServer'
import { logSignUp, logLogin, EVENT_KEYS, logEvent } from 'services/loggingService'
import { changeRoute, createRegisterJourneyLink, createAccessLink, subRoutesNames, createUserLink } from 'services/routingService'

import { isMock } from 'utils/envs'
import { logError } from 'utils/errorCapture'
import { getLocalStorageJSON, setLocalStorage, removeLocalStorage } from 'utils/localStorage'
import { generateNewUsername } from 'utils/signUp'

const LS_USER = 'session-id'
let unsubscribeUserStorageChangeListener

const FIVE_MINUTES_IN_MS = 300000

export const initializeAuthenticationService = () => {
  const auth = getAuth()

  if (isMock()) {
    return
  }

  const currentUser = auth.currentUser || getLocalStorageJSON(LS_USER)

  // if there are info already in local storage, let's update the UI ASAP while fetching the rest of user data
  if (currentUser) {
    // User is signed in
    store.dispatch(setCurrentUser(currentUser))
  }

  onAuthStateChanged(auth, user => {
    if (user) {
      const isNewUser = moment(user.metadata.creationTime)
        .isAfter(moment().subtract(1, 'minutes'), 'minutes')

      // User is signed in
      setLocalStorage(LS_USER, JSON.stringify({
        email: user.email,
        displayName: user.displayName,
        uid: user.uid,
      }))

      if (!isNewUser) {
        store.dispatch(setCurrentUser(user))
      }

      // create a subscription to firestore so that user storage size is updated whenenver is changed
      const onUserStorageChange = (updatedStorageSize, doc) => {
        const sourceIsServer = !doc.metadata.hasPendingWrites
        if (sourceIsServer) {
          store.dispatch(setUserStorageSize(user.uid, updatedStorageSize))
        }
      }
      unsubscribeUserStorageChangeListener = createUserStorageChangeListener(user.uid, onUserStorageChange)
      store.dispatch(fetchUser(user.uid))
        .then(databaseUser => {
          if (user.emailVerified && user.email !== databaseUser.email) {
            // update database email
            store.dispatch(editUserInfo(databaseUser, {
              email: user.email,
            }))
          }
        })
    } else {
      // User is signed out
      removeLocalStorage(LS_USER)
      store.dispatch(removeCurrentUser())
      if (unsubscribeUserStorageChangeListener) {
        unsubscribeUserStorageChangeListener()
      }
    }
  }, error => {
    logError(error)
  })
}

export const logoutUser = () => {
  store.dispatch(removeCurrentUser())
  return signOut(getAuth())
    .then(() => {
      // Sign-out successful
    })
    .catch(err => {
      logError(err)
    })
}

export const loginUser = (email, password, persisted, history) => {
  const authPersistence = persisted ? browserLocalPersistence : browserSessionPersistence

  const auth = getAuth()
  return setPersistence(auth, authPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password)
        .then(authResult => {
          const user = authResult.user
          logLogin(user.providerData[0]?.providerId, user.uid)
          const newRoute = createUserLink(user.uid)
          changeRoute(history, newRoute)
        })
        .catch(error => {
          if (error.code !== 'auth/multi-factor-auth-required') {
            logError(error)
          }
          throw error
        })
    })
}

export const registerUser = (email, password, name, history) => {
  return createUserWithEmailAndPassword(getAuth(), email, password)
      .then(async userCredential => {
        // Signed in
        const user = userCredential.user

        updateProfile(user, {
          displayName: name,
        }).catch(error => {
          logError(error, 'Error updating user name after registering')
        })

        verifyEmail()

        const newUser = {
          ...user,
          creationDate: moment(user.metadata.creationTime).unix(),
          name,
          username: await generateNewUsername(name, user.uid),
        }

        store.dispatch(setCurrentUser(newUser))
        return addUser(newUser)(store.dispatch)
          .then(() => {
            logSignUp(user.providerData[0]?.providerId, user.uid)
            changeRoute(history, createRegisterJourneyLink())
            return
          })
          // If creating user data after register throws any error, the new user must be removed and user must try again
          .catch(error => {
            logError(error, 'Error adding user after register')

            deleteUser()
              .then(() => {
                window.alert('Houve um problema ao criar o teu utilizador. Vamos voltar a carregar a página para tentares novamente')
                window.location.replace(createAccessLink(subRoutesNames.ACCESS.SIGN_UP))
              })
          })
      })
      .catch(error => {
        logError(error)
        throw error
      })
}

export const userExists = userEmail => {
  const auth = getAuth()
  return fetchSignInMethodsForEmail(auth, userEmail)
    .then(validMethods => {
      return validMethods?.length > 0
    })
}

export const requestPasswordReset = email => {
  const auth = getAuth()
  if (isMock()) {
    return new Promise(resolve => {
      setTimeout(() => resolve(), 1000)
    })
  }

  let userEmail = email

  if (!email) {
    userEmail = auth.currentUser.email
  }

  return sendPasswordResetEmail(auth, userEmail)
    .catch(error => {
      logError(error)
      throw error
    })
}

export const deleteUser = () => {
  if (isMock()) {
    return new Promise(resolve => {
      setTimeout(() => resolve(), 1000)
    })
  }

  const user = getAuth().currentUser
  return deleteUserFirebase(user)  // this function will trigger a server lambda function to remove all user data
    .then(() => {
      logEvent(EVENT_KEYS.DELETE_ACCOUNT, {
        userUid: user.uid,
      })
    })
    .catch(error => {
      logError(error)
      throw error
    })
}

export const verifyAndUpdateUserEmail = newEmail => {
  if (isMock()) {
    return new Promise(resolve => {
      setTimeout(() => resolve(), 1000)
    })
  }

  const user = getAuth().currentUser

  return verifyBeforeUpdateEmail(user, newEmail)
    .catch(error => {
      logError(error)
      throw error
    })
}

export const reauthenticateUser = password => {
  const user = getAuth().currentUser

  const authCredential = EmailAuthProvider.credential(user.email, password)

  return reauthenticateWithCredential(user, authCredential)
    .catch(error => {
      if (error.code !== 'auth/multi-factor-auth-required') {
        logError(error)
      }

      throw error
    })
}

export const verifyEmail = () => {
  const auth = getAuth()
  return sendEmailVerification(auth.currentUser)
    .then(val => {
      console.log('Email-verification email sent:', val)
    })
    .catch(error => {
      console.log('Error sending email-verification email:', error)
      throw error
    })
}

export const listenToVerifiedEmail = emailToListen => {
  const auth = getAuth()

  return new Promise(resolve => {
    const unsubscribeSetInterval = setInterval(async () => {
      await reload(auth.currentUser)

      if (auth.currentUser) {
        const authUserEmail = auth.currentUser.email
        const emailIsTheSame = authUserEmail === emailToListen
        const emailIsVerified = auth.currentUser.emailVerified

        if (emailIsVerified && emailIsTheSame) {
          clearInterval(unsubscribeSetInterval)
          resolve(true)
          return
        }
      }
    }, 5000)
  })
}

export const authUserEmailIsVerified = email => {
  const auth = getAuth()
  if (auth.currentUser) {
    return auth.currentUser.emailVerified && email === auth.currentUser.email
  }

  return false
}

export const authUserSignedInRecently = () => {
  const auth = getAuth()
  if (auth.currentUser) {
    const currentEpochInMs = new Date().getTime()
    const lastLoginEpochInMs = auth.currentUser.metadata.lastLoginAt

    return (currentEpochInMs - lastLoginEpochInMs) <= FIVE_MINUTES_IN_MS
  }

  return false
}

export const getAuthUserEmail = () => {
  const auth = getAuth()
  return auth.currentUser?.email
}

export const configureMultiFactorAuth = async () => {
  const currentUser = getAuth().currentUser
  const multiFactorSession = await multiFactor(currentUser).getSession()
  const totpSecret = await TotpMultiFactorGenerator.generateSecret(
    multiFactorSession
  )

  const totpUri = totpSecret.generateQrCodeUrl(
    currentUser.email,
    'PLOTU'
  )

  const secretKey = totpSecret.secretKey

  return {
    totpSecret,
    totpUri,
    secretKey,
  }
}

export const verifyTOTPCode = (totpSecret, verificationCode) => {
  const currentUser = getAuth().currentUser

  // Finalize the enrollment.
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
    totpSecret,
    verificationCode
  )

  return multiFactor(currentUser)
    .enroll(multiFactorAssertion, 'TOTP MFA')
    .catch(function (error) {
      logError(`Error finishing second factor enrollment. ${error}`)
      throw error
    })
}

export const getMultiFactorAuths = () => {
  const currentUser = getAuth().currentUser
  if (currentUser) {
    return multiFactor(currentUser).enrolledFactors
  }
  return []
}

/**
 * When we support multiple MFA's, this function should be used to get a list of resolvers that a user can choose when signin in
 */
export const getMFResolversAfterSignIn = async error => {
  const mfaResolver = getMultiFactorResolver(getAuth(), error)
  const enrolledFactors = mfaResolver.hints.map(info => info.displayName)
  return enrolledFactors
}

export const verifyMFAAfterSignIn = async (selectedIndex, error, code, reauthenticationFlow, history) => {
  const mfaResolver = getMultiFactorResolver(getAuth(), error)

  switch (mfaResolver.hints[selectedIndex].factorId) {
    case TotpMultiFactorGenerator.FACTOR_ID: {
      const otpFromAuthenticator = code
      const multiFactorAssertion =
            TotpMultiFactorGenerator.assertionForSignIn(
              mfaResolver.hints[selectedIndex].uid,
              otpFromAuthenticator
            )
      try {
        const authResult = await mfaResolver.resolveSignIn(
          multiFactorAssertion
        )

        // Successfully signed in!

        if (reauthenticationFlow) {
          return Promise.resolve()
        }

        const user = authResult.user
        logLogin(user.providerData[0]?.providerId, user.uid)
        changeRoute(history, createUserLink(user.uid))

        return
      } catch (error) {
        // Invalid or expired OTP.
        return Promise.reject(error)
      }
    }
    case PhoneMultiFactorGenerator.FACTOR_ID:
      // Handle SMS second factor.
      break
    default:
      // Unsupported second factor?
      break
  }

  return Promise.reject(new Error('Unsupported MFA'))
}

export const unenrollMultiFactorAuth = async enrollmentUid => {
  const currentUser = getAuth().currentUser
  // Check if the user is enrolled with the provided enrollment uid
  const enrolledFactors = multiFactor(currentUser).enrolledFactors
  const enrollment = enrolledFactors.find(enrollment => enrollment.uid === enrollmentUid)

  if (enrollment) {
    await multiFactor(currentUser).unenroll(enrollmentUid)
  }
}
