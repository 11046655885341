import { packs, users, agendas, galleries } from './mocks'
import moment from 'moment'

export const getFeed = (size = 5) => {
  return new Promise(resolve => {
    setTimeout(() => {
      const timeSortedPacks = packs
        .sort((pack1, pack2) => pack1.createdTimestamp - pack2.createdTimestamp)

      const feed = timeSortedPacks
        .slice(0, size)
        .map(pack => ({
          id: pack.id,
          user: users.find(user => user.uid === pack.userUid),
          pack,
        }))

      resolve(feed)
    }, 500)
  })
}

const getPackWithUser = pack => {
  const userUid = pack.userUid
  const user = users.find(user => user.uid === userUid)

  const packReviews = pack.reviews
    ?.map(review => {
      const reviewUser = users.find(user => user.uid === review.userUid)
      return {
        ...review,
        user: reviewUser,
      }
    })

  return {
    ...pack,
    reviews: packReviews,
    user: {
      uid: user.uid,
      name: user.name,
      avatar: user.avatar,
      rating: user.rating,
      shortDescription: user.shortDescription,
    },
  }
}

export const getPack = packId => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      const pack = packs.find(pack => pack.id === packId)
      if (pack === undefined) {
        return resolve(undefined)
      }
      resolve(getPackWithUser(pack))
    }, 500)
  })
}

export const getPacks = _packIds => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(packs.map(getPackWithUser))
    }, 500)
  })
}

export const postPack = _packData => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const putPack = _packData => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const deletePack = _packData => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const postReview = _packData => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const deleteReview = _packData => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

// TODO: this should not retrieve agenda data
export const getUser = userUid => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(users.find(user => user.uid === userUid))
    }, 1000)
  })
}

export const getUserByUsername = username => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(users.find(user => user.username === username))
    }, 1000)
  })
}

export const checkIfUsernameExists = username => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(users.find(user => user.username === username))
    }, 1000)
  })
}

export const postUser = _user => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const editUser = _user => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const putUserPortfolio = _user => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const postUserPortfolioImage = _user => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const incrementUserGallery = _user => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1200)
  })
}

export const getUserEvents = userUid =>
  new Promise(resolve => {
    setTimeout(() => {
      const userEvents = { ...agendas[userUid].events }

      // map text dates to unix
      Object.keys(userEvents).forEach(eventKey => {
        const event = userEvents[eventKey]
        event.startDate = moment(event.startDate).unix()
        event.endDate = moment(event.endDate).unix()
      })

      resolve(userEvents)
    }, 500)
  })

export const getUserEvent = (userUid, eventId) =>
  new Promise(resolve => {
    setTimeout(() => {
      const event = agendas[userUid].events[eventId]
      event.startDate = moment(event.startDate).unix()
      event.endDate = moment(event.endDate).unix()

      resolve(event)
    }, 500)
  })

export const postAgendaEvent = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 500)
  })

export const deleteAgendaEvent = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 500)
  })

export const putAgendaEvent = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 500)
  })

export const getUserWorkingHours = userUid =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(agendas[userUid]?.workingHours)
    }, 750)
  })

export const setUserWorkingHours = _userUid =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const getUserGalleries = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(galleries.map(gallery => ({
        ...gallery,
        user: users[gallery.userUid],
      })))
    }, 750)
  })

export const getGallery = galleryId =>
  new Promise(resolve => {
    setTimeout(() => {
      const gallery = {
        ...galleries[galleryId],
        user: users[galleries[galleryId].userUid],
      }
      resolve(gallery)
    }, 750)
  })

export const getGalleryPhoto = galleryId =>
  new Promise(resolve => {
    setTimeout(() => {
      const photo = galleries[galleryId]?.photos[0]
      resolve(photo)
    }, 750)
  })

export const getGalleryPhotos = galleryId =>
  new Promise(resolve => {
    setTimeout(() => {
      const photo = galleries[galleryId]?.photos
      resolve(photo)
    }, 750)
  })

export const getGalleryMarkedPhotosIds = galleryId =>
  new Promise(resolve => {
    setTimeout(() => {
      const photo = galleries[galleryId]?.photos
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: true,
        }
      }, {})
      resolve(photo)
    }, 750)
  })

export const createGalleryArchive = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const createGalleryChangesListener = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const postGallery = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const putGallery = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const postGalleryPhoto = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const deleteGallery = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const deleteGalleryPhotos = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const deleteGalleryPhoto = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const postGallerySharingOptions = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const postGalleryMarkedPhotoIds = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const postUserImage = file =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(file.src)
    }, 750)
  })

export const postPackImage = file =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(file.src)
    }, 750)
  })

export const postPackImages = file =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(file.src)
    }, 750)
  })

export const postPortfolioImage = file =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(file.src)
    }, 750)
  })

export const postPortfolioImages = file =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(file.src)
    }, 750)
  })

export const deleteImage = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })

export const deleteImages = () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 750)
  })
