import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

import { IconButton } from 'components/Button'
import { Image } from 'components/Image'
import { LoadingWrapper } from 'components/LoadingComponent'

import { DownloadingIcon } from '../DownloadingIcon'

import { getImageV2DataFromSrc } from 'services/firebaseServer'

import './styles.scss'

const WATERMARK_INITIATED_TIME_THRESHOLD = 30000 // 30 seconds in milliseconds

const checkIfWatermarkNeedsToBeCreated = (galleryWatermark, galleryPhoto) => {
  // first check if the whole gallery is applying watermarks but it's taking too much time
  const wholeGalleryWatermarkJobInitiatedMSAgo = Date.now() - galleryWatermark?.initiatedTimestamp
  const wholeGalleryWatermarkJobInitiatedTooLongAgo =
    wholeGalleryWatermarkJobInitiatedMSAgo >= WATERMARK_INITIATED_TIME_THRESHOLD

  // then check if a specific photo is applying watermark but it's taking too much time
  const photoWatermarkJobInitiatedMSAgo = Date.now() - galleryPhoto.watermark?.initiatedTimestamp
  const photoWatermarkJobInitiatedTooLongAgo = photoWatermarkJobInitiatedMSAgo >= WATERMARK_INITIATED_TIME_THRESHOLD

  const watermarkJobInitiatedTooLongAgo =
    wholeGalleryWatermarkJobInitiatedTooLongAgo || photoWatermarkJobInitiatedTooLongAgo
  const watermarkSrc = galleryPhoto.watermark?.src

  return (
    watermarkSrc === undefined &&
    watermarkJobInitiatedTooLongAgo
  )
}

export const GalleryImage = ({
  galleryPhoto,
  isMarked,
  gallery,
  isUploading,
  isDownloading,
  downloadPhoto,
  onPhotoMarkClick,
  openCarousel,
  triggerAddWatermarkToGalleryPhotoJob,
  triggerListenForWatermarkChanges,
  rectAngles,
}) => {
  const hasTriggeredWatermarkJob = useRef(false)
  const hasTriggeredWatermarkListener = useRef(false)

  // if photo has its watermark created and the gallery has a watermark configured, we should use it instead of the original image
  const photoSrc = gallery.watermark?.id && (gallery.watermark?.id === galleryPhoto.watermark?.watermarkId)
    ? galleryPhoto.watermark?.src
    : galleryPhoto.src
  const galleryWatermark = gallery.watermark
  const applyingWatermark = galleryPhoto.applyingWatermark || galleryPhoto.watermark?.isLoading
  const shouldBlurImage = applyingWatermark

  // If the gallery have a configured watermark but the image has not any watermark data, and it's not in the process of creating one,
  // we must trigger the watermark creation job
  useEffect(() => {
    if (!hasTriggeredWatermarkJob.current && !galleryPhoto.uploading) {
      const watermarkNeedsToBeCreated =
        checkIfWatermarkNeedsToBeCreated(galleryWatermark, galleryPhoto)
      if (watermarkNeedsToBeCreated) {
        hasTriggeredWatermarkJob.current = true // set this ASAP to avoid multiple requests
        // first confirm with database that this watermark is missing
        getImageV2DataFromSrc(galleryPhoto.src)
          .then(parentWatermarkPhotoData => {
            const remoteWatermarkNeedsToBeCreated =
              checkIfWatermarkNeedsToBeCreated(galleryWatermark, parentWatermarkPhotoData)
            if (remoteWatermarkNeedsToBeCreated) {
              triggerAddWatermarkToGalleryPhotoJob()
            }
          })
      }
    }
  }, [triggerAddWatermarkToGalleryPhotoJob, galleryPhoto.src, galleryWatermark, galleryPhoto])

  // If the photo is creating a watermark in the server we don't have the state locally (user might have refreshed the page)
  // we must trigger the watermark listener to update the watermark when its ready
  useEffect(() => {
    if (!hasTriggeredWatermarkListener.current) {
      const watermarkIsBeingCreatedRemotelly = !galleryPhoto.applyingWatermark && galleryPhoto.watermark?.isLoading
      if (watermarkIsBeingCreatedRemotelly) {
        hasTriggeredWatermarkListener.current = true
        triggerListenForWatermarkChanges()
      }
    }
  }, [galleryPhoto, triggerListenForWatermarkChanges])

  const onContextMenu = event => {
    if (gallery?.downloadIsAllowed) {
      return true
    }
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    return false
  }

  return (
    <div>
      <div className='gallery-image__action-buttons'>
        {gallery?.markedPhotos?.markedPhotosAreAllowed && (
          <div className='gallery-image__mark'>
            <IconButton
              size='small'
              aria-label='mark photo'
              onClick={onPhotoMarkClick(galleryPhoto, isMarked)}
            >
              {isUploading && (isMarked ? <BookmarkIcon fontSize='small' /> : <BookmarkBorderIcon fontSize='medium' />)}
              {isMarked && !isUploading && <BookmarkIcon fontSize='small' />}
              {!isMarked && !isUploading && <BookmarkBorderIcon fontSize='small' />}
            </IconButton>
            <LoadingWrapper className='gallery-image__loading' size={34} isLoading={isUploading} delay={400} />
          </div>
        )}
      </div>
      <Image
        className={classNames('gallery-image__image', {
          'gallery-image__image--blurred': shouldBlurImage,
          'gallery-image__image--non-selectable': !gallery?.downloadIsAllowed,
          'gallery-image__image--rect-angles': rectAngles,
        })}
        src={photoSrc}
        width={galleryPhoto.width}
        height={galleryPhoto.height}
        thumbnailsSpecs={[
          { size: 's', media: '(max-width: 600px)' },
          { size: 'm', media: '(min-width: 600px) and (max-width: 1920px)' },
          { size: 'l', media: '(min-width: 1920px)' },
        ]}
        onClick={openCarousel(galleryPhoto.id)}
        onContextMenu={onContextMenu}
        draggable={gallery?.downloadIsAllowed}
        lazyLoaded
        generateThumbnailIfError
      />
      <div
        className={classNames('gallery-image__bottom-action-buttons', {
          'gallery-image__bottom-action-buttons--visible': isDownloading,
        })}
      >
        {gallery?.downloadIsAllowed && (
          <DownloadingIcon
            onClick={() => downloadPhoto(galleryPhoto)}
            isDownloading={isDownloading}
          />
        )}
      </div>
    </div>
  )
}
