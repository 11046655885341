import { Grid } from '@mui/material'
import React from 'react'

import { MansoryCardsLayout } from 'components/MansoryCardsLayout'

import { CategorySearchResultItem, CategorySearchResultItemSkeleton } from '../CategorySearchResultItem'
import { PackSearchResultItem, PackSearchResultItemSkeleton } from '../PackSearchResultItem'
import { PhotographerSearchResultItem, PhotographerSearchResultItemSkeleton } from '../PhotographerSearchResultItem'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { SEARCH_GROUP_IDS } from 'utils/search'

import './styles.scss'

const renderSearchResultItem = (hit, selectedViewId) => {
  switch (hit.searchGroup.id) {
    case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
      return (
        <PhotographerSearchResultItem
          selectedViewId={selectedViewId}
          id={hit.objectID}
          name={hit.name}
          location={hit.location?.address}
          className='search-results__item'
          categories={hit.categories}
          description={hit.description}
          avatar={hit.imgSrc}
        />
      )
    case SEARCH_GROUP_IDS.PACKS:
      return (
        <PackSearchResultItem
          selectedViewId={selectedViewId}
          title={hit.title}
          id={hit.objectID}
          categories={hit.categories}
          description={hit.description}
          user={hit.user}
          image={hit.image}
          className='search-results__item'
        />
      )
    case SEARCH_GROUP_IDS.CATEGORIES:
      return (
        <CategorySearchResultItem
          selectedViewId={selectedViewId}
          title={hit.name}
          className='search-results__item'
        />
      )
    default:
      return null
  }
}

const renderSearchResultItemSkeleton = (searchGroup, selectedViewId) => {
  switch (searchGroup) {
    case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
      return (
        <div className='search-results__item--skeleton'>
          <PhotographerSearchResultItemSkeleton selectedViewId={selectedViewId} />
        </div>
      )
    case SEARCH_GROUP_IDS.PACKS:
      return (
        <div className='search-results__item--skeleton'>
          <PackSearchResultItemSkeleton selectedViewId={selectedViewId} />
        </div>
      )
    case SEARCH_GROUP_IDS.CATEGORIES:
      return (
        <div className='search-results__item--skeleton'>
          <CategorySearchResultItemSkeleton selectedViewId={selectedViewId} />
        </div>
      )
    default:
      return null
  }
}

const getSkeletonHits = searchGroup => {
  switch (searchGroup) {
    case SEARCH_GROUP_IDS.ALL:
      return [
        SEARCH_GROUP_IDS.PACKS,
        SEARCH_GROUP_IDS.PACKS,
        SEARCH_GROUP_IDS.PACKS,
        SEARCH_GROUP_IDS.PHOTOGRAPHERS,
        SEARCH_GROUP_IDS.PHOTOGRAPHERS,
      ]
    case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
      return [SEARCH_GROUP_IDS.PHOTOGRAPHERS, SEARCH_GROUP_IDS.PHOTOGRAPHERS, SEARCH_GROUP_IDS.PHOTOGRAPHERS]
    case SEARCH_GROUP_IDS.PACKS:
      return [SEARCH_GROUP_IDS.PACKS, SEARCH_GROUP_IDS.PACKS, SEARCH_GROUP_IDS.PACKS]
    case SEARCH_GROUP_IDS.CATEGORIES:
      return [SEARCH_GROUP_IDS.CATEGORIES, SEARCH_GROUP_IDS.CATEGORIES, SEARCH_GROUP_IDS.CATEGORIES]
    default:
      return null
  }
}

const renderSkeletons = (selectedViewId, searchUrlGroup) => {
  if (selectedViewId === 'gallery') {
    return (
      <Grid container alignItems='center' justify='flex-start'>
        {getSkeletonHits(searchUrlGroup)
          .map(searchGroup => {
            return (
              <Grid key={searchGroup} container xs={6} md={4} xl={3}>
                {renderSearchResultItemSkeleton(searchGroup, selectedViewId)}
              </Grid>
            )
          })}
      </Grid>
    )
  }

  return getSkeletonHits(searchUrlGroup)
    .map(searchGroup => renderSearchResultItemSkeleton(searchGroup, selectedViewId))
}

const renderContent = (selectedViewId, results, breakpoints) => {
  const { isTablet, isDesktop, isLargeDesktopAndUp } = breakpoints
  let width = '50%'
  if (isTablet) {
    width = '33%'
  }
  if (isDesktop) {
    width = '25%'
  }
  if (isLargeDesktopAndUp) {
    width = '20%'
  }

  if (selectedViewId === 'gallery') {
    return (
      // <Grid container alignItems='center' justify='flex-start'>
      //   {results.map(result => {
      //     return <Grid container xs={6} md={4} xl={3} padding={20}>
      //       {renderSearchResultItem(result, selectedViewId)}
      //     </Grid>
      //   })}
      // </Grid>
      <MansoryCardsLayout
        withPercentage
        width={width}
      >
        {results.map(result => renderSearchResultItem(result, selectedViewId))}
      </MansoryCardsLayout>
    )
  }

  return results.map(hit => renderSearchResultItem(hit, selectedViewId))
}

export const SearchResults = props => {
  const breakpoints = useBreakpoints()

  return (
    <div className='search-results'>
      {props.isLoading && renderSkeletons(props.selectedViewId, props.searchUrlGroup)}
      {!props.isLoading && renderContent(props.selectedViewId, props.results, breakpoints)}
      {!props.isLoading && props.results.length === 0 && 'Sem resultados'}
    </div>
  )
}
