const DEFAULT_TIME_TO_RESOLVE = 1200

const resolvePromiseAfterMs = (valueToResolve, timeToResolve = DEFAULT_TIME_TO_RESOLVE) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(valueToResolve)
    }, timeToResolve)
  })
}

export const logEvent = resolvePromiseAfterMs
