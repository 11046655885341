import algoliasearch from 'algoliasearch'

import { getConfigValue } from 'services/configService'

import { logError } from 'utils/errorCapture'

var client
var packIndex
var usersIndex
var categoriesIndex

const SEARCH_INDEXES = {
  'PACKS': 'Packs',
  'USERS': 'Users',
  'CATEGORIES': 'Categories',
}

const getSearchIndex = (searchIndex, environment) => `${environment ? `${environment}_` : ''}${searchIndex}`

export const initializeSearchService = () => {
  client = algoliasearch(getConfigValue('general.endpoints.algolia.application_id'), process.env.REACT_APP_ALGOLIA_API_KEY)
  packIndex = client.initIndex(getSearchIndex(SEARCH_INDEXES.PACKS))
  usersIndex = client.initIndex(getSearchIndex(SEARCH_INDEXES.USERS))
  categoriesIndex = client.initIndex(getSearchIndex(SEARCH_INDEXES.CATEGORIES))
}

export const savePackObject = packData => {
  const packImage = packData.packInfo.media
    ? packData.packInfo.media.images.find(image => image.id === packData.packInfo.media.mainImageId) || packData.packInfo.media.images[0] || ''
    : ''

  const packObject = {
    objectID: packData.id,
    title: packData.packInfo.title,
    description: packData.packInfo.description,
    categories: packData.packInfo.categories,
    user: {
      id: packData.user?.uid,
      name: packData.user?.name,
      avatar: packData.user?.avatar,
    },
    image: packImage,
    visibility: packData.visibility || false,
  }

  return saveObject(packIndex, packObject)
}
export const saveUserObject = userData => {
  const userObject = {
    objectID: userData.uid,
    name: userData.name,
    imgSrc: userData.avatar,
    type: userData.type || 'CUSTOMER',
    categories: userData.categories?.length && userData.categories.map(category => category.name),
    location: userData.location,
    description: userData.description,
  }

  return saveObject(usersIndex, userObject)
}

export const saveCategoriesObjects = data => saveObjects(categoriesIndex, data)

const getPackObject = packData => {
  const packImage = packData.packInfo?.media
    ? packData.packInfo.media.images.find(image => image.id === packData.packInfo.media.mainImageId) || packData.packInfo.media.images[0] || ''
    : undefined

  const packObject = {
    objectID: packData.id,
    title: packData.packInfo?.title,
    description: packData.packInfo?.description,
    categories: packData.packInfo?.categories,
    user: packData.user
      ? {
        id: packData.user?.uid,
        name: packData.user?.name,
        avatar: packData.user?.avatar,
      }
      : undefined,
    image: packImage,
    visibility: packData.visibility,
  }

  return packObject
}

export const updatePackObject = packData => {
  if (Array.isArray(packData)) {
    const packObjects = packData.map(pack => getPackObject(pack))
    return updateObjects(packIndex, packObjects)
  }

  return updateObject(packIndex, getPackObject(packData))
}

export const updateUserObject = userData => {
  const userObject = {
    objectID: userData.uid,
    name: userData.name,
    imgSrc: userData.avatar,
    type: userData.type || 'CUSTOMER',
    categories: userData.categories?.length && userData.categories.map(category => category.name),
    location: userData.location,
    description: userData.description,
  }

  return updateObject(usersIndex, userObject)
}

export const deleteUserObject = userData => deleteObject(usersIndex, userData.uid)
export const deletePackObject = packData => deleteObject(packIndex, packData.id)
export const deletePackObjects = packIds => deleteObjects(packIndex, packIds)

export const searchPackIndex = (searchQuery, pageIndex) => {
  const options = {
    filters: 'visibility:true',
    page: pageIndex,
  }

  return searchIndex(packIndex, searchQuery, options, pageIndex)
}
export const searchUsersIndex = (searchQuery, pageIndex, isProfessionalUser = true) => {
  const options = {
    filters: isProfessionalUser ? 'type:PROFESSIONAL' : 'type:CLIENT',
    page: pageIndex,
  }

  return searchIndex(usersIndex, searchQuery, options)
}

export const searchCategoriesIndex = (searchQuery, pageIndex) => {
  const options = {
    page: pageIndex,
  }

  return searchIndex(categoriesIndex, searchQuery, options)
}

export const searchAll = (searchQuery, pageIndex) => {
  return searchIndexes([
    {
      index: getSearchIndex(SEARCH_INDEXES.PACKS),
      query: searchQuery,
      options: { filters: 'visibility:true', page: pageIndex },
    }, {
      index: getSearchIndex(SEARCH_INDEXES.USERS),
      query: searchQuery,
      options: { filters: 'type:PROFESSIONAL', page: pageIndex },
    }, {
      index: getSearchIndex(SEARCH_INDEXES.CATEGORIES),
      query: searchQuery,
      options: { page: pageIndex },
    },
  ])
}

const searchIndex = (index, searchQuery, options = {}) =>
  index
    .search(searchQuery, options)
    .then(results => {
      return {
        hits: results.hits,
        pageIndex: results.page,
        totalPagesCount: results.nbPages,
        totalHitsCount: results.nbHits,
        hitsPerPage: results.hitsPerPage,
      }
    })
    .catch(error => {
      console.error(`Error searching query: ${searchQuery} in index ${index}: ${error}`)
    })

const searchIndexes = (queriesData = []) => {
  const queries = queriesData.map(data => {
    return {
      indexName: data.index,
      query: data.query,
      ...(data.options || {}),
    }
  })

  return client.multipleQueries(queries)
    .then(({ results }) => {
      return results.map(queryResults => ({
        hits: queryResults.hits,
        index: queryResults.index,
        pageIndex: queryResults.page,
        totalPagesCount: queryResults.nbPages,
        totalHitsCount: queryResults.nbHits,
        hitsPerPage: queryResults.hitsPerPage,
      }))
    })
}

const saveObjects = (index, data) => {
  return index.saveObjects(data, {
    autoGenerateObjectIdIfNotExist: true,
  })
    .then(({ objectIDs }) => objectIDs)
    .catch(logError)
}

const saveObject = (index, data) => {
  return index.saveObject(data)
    .then(({ objectID }) => objectID)
    .catch(logError)
}

const updateObject = (index, data) => {
  return index
    .partialUpdateObject(data, {
      createIfNotExists: true,
    })
    .catch(logError)
}

const updateObjects = (index, data) => {
  return index
    .partialUpdateObjects(data, {
      createIfNotExists: true,
    })
    .catch(logError)
}

const deleteObject = (index, objectID) => {
  return index.deleteObject(objectID)
    .catch(logError)
}

const deleteObjects = (index, objectIDs) => {
  return index.deleteObjects(objectIDs)
    .catch(logError)
}
