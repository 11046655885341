import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

export const SEARCH_GROUP_IDS = {
  ALL: 'ALL',
  PHOTOGRAPHERS: 'PHOTOGRAPHERS',
  CUSTOMERS: 'CUSTOMERS',
  LOCALS: 'LOCALS',
  CATEGORIES: 'CATEGORIES',
  PACKS: 'PACKS',
}

export const AVAILABLE_SEARCH_GROUPS_DEFAULT = [
  { id: SEARCH_GROUP_IDS.ALL, name: 'Todos', separated: true },
  { id: SEARCH_GROUP_IDS.PACKS, name: 'Packs' },
  { id: SEARCH_GROUP_IDS.PHOTOGRAPHERS, name: 'Professionals' },
  { id: SEARCH_GROUP_IDS.CATEGORIES, name: 'Categorias' },
]

export const getQueryResult = (searchQueries, query, searchGroup, pageIndex) => {
  return searchQueries
    .find(searchQuery =>
      searchQuery.query === query &&
      searchQuery.searchGroup === searchGroup &&
      searchQuery.metadata?.pageIndex === pageIndex)
}

export const getQueryResultIndex = (searchQueries, query, searchGroup, pageIndex) => {
  return searchQueries
    .findIndex(searchQuery =>
      searchQuery.query === query &&
      searchQuery.searchGroup === searchGroup &&
      searchQuery.metadata?.pageIndex === pageIndex)
}

export const getAvailableSearchGroups = () => {
  const configAvailableSearchGroups = getConfigValue('search.search-groups.list') || AVAILABLE_SEARCH_GROUPS_DEFAULT
  return configAvailableSearchGroups
    .map(group => ({ ...group, name: t(group.name) }))
}

const getGroupIconName = searchGroup => {
  switch (searchGroup) {
    case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
      return 'camera'
    case SEARCH_GROUP_IDS.CATEGORIES:
      return 'char_#'
    case SEARCH_GROUP_IDS.PACKS:
      return 'local_offer'
    default:
      return 'circle'
  }
}

const getSearchGroupFromIndex = groupIndex => {
  let rootIndex = groupIndex.includes('_') ? groupIndex.split('_')[1] : groupIndex

  // TODO: improve the following hack
  // Hack to match search groups with algolia Users index
  if (rootIndex === 'Users') {
    rootIndex = 'Photographers'
  }

  return getAvailableSearchGroups()
    .find(searchGroup => searchGroup.id === SEARCH_GROUP_IDS[rootIndex.toUpperCase()])
}

const populateResults = (searchGroup, hit) => {
  return {
    ...hit,
    searchGroup,
    iconName: getGroupIconName(searchGroup.id),
  }
}

export const parseResults = (results, searchGroupId = SEARCH_GROUP_IDS.ALL) => {
  if (searchGroupId === SEARCH_GROUP_IDS.ALL) {
    const metadata = {
      pageIndex: results?.[0]?.pageIndex,
      totalPagesCount: Math.max(...(results?.map(result => result.totalPagesCount) || [])),
    }

    const parsedResults = results?.flatMap(resultGroup => {
      const searchGroup = getSearchGroupFromIndex(resultGroup.index) || {}
      return resultGroup.hits.map(hit => populateResults(searchGroup, hit))
    }) || []

    return {
      parsedResults,
      metadata,
    }
  }

  const { hits, ...metadata } = results || {}

  const searchGroup = getAvailableSearchGroups()
    .find(searchGroup => searchGroup.id === searchGroupId) || { id: searchGroupId }

  return {
    parsedResults: hits?.map(hit => populateResults(searchGroup, hit)) || [],
    metadata,
  }
}
