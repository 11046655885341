import classNames from 'classnames'
import React from 'react'

import { LinkButton } from 'components/Button'
import { Image } from 'components/Image'
import { Text } from 'components/Text'

import './styles.scss'

export const HomeContainer = ({ title, subtitle, text, buttonLabel, imageSrc, color, cutted, href }) => {
  return (
    <div
      className={classNames('home-container', {
        'home-container--secondary-color': color === 'secondary',
      })}
    >
      <div className='home-container__content-wrapper'>
        <div className='home-container__content'>
          <Text
            as='h2'
            type='title'
            className={ classNames('home-container__content__title', {
              'home-container__content__title--with-subtitle': !!subtitle,
            })}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              className='home-container__content__subtitle'
              as='h4'
              type='title2'
            >
              {subtitle}
            </Text>
          )}
          <Text
            className='home-container__content__text'
            as='p'
            type='title3'
          >
            {text}
          </Text>
          <LinkButton
            color='default'
            variant='outlined'
            size='x-large'
            href={href}
          >
            {buttonLabel}
          </LinkButton>
        </div>
      </div>
      <Image
        className={classNames('home-container__image', {
          'home-container__image--cutted': cutted,
        })}
        src={imageSrc}
        thumbnailsSpecs={[
          { size: 's', media: '(max-width: 600px)' },
          { size: 'm', media: '(min-width: 600px)' },
        ]}
      />
    </div>
  )
}
