import {
  REQUEST_WATERMARKS,
  RECEIVE_WATERMARKS_DATA,
  ADD_WATERMARK_DATA,
  REMOVE_WATERMARK_DATA,
  EDIT_WATERMARK_GALLERY_DATA,
} from './actions'

const initialState = {
  loading: undefined,
  data: undefined,
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WATERMARKS:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_WATERMARKS_DATA:
      return {
        ...state,
        loading: false,
        data: action.data.watermarks,
      }
    case ADD_WATERMARK_DATA:
      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.data.watermark.id]: action.data.watermark,
        },
      }
    case REMOVE_WATERMARK_DATA: {
      const watermarksDataCopy = { ...state.data }

      delete watermarksDataCopy[action.data.watermarkId]

      return {
        ...state,
        data: watermarksDataCopy,
      }
    }
    case EDIT_WATERMARK_GALLERY_DATA: {
      const watermarkDataCopy = { ...state.data[action.data.watermarkId] }

      if (action.data.remove) {
        delete watermarkDataCopy.galleries?.[action.data.galleryId]
      } else {
        watermarkDataCopy.galleries = {
          ...(watermarkDataCopy.galleries || {}),
          [action.data.galleryId]: true,
        }
      }

      return {
        ...state,
        data: {
          ...(state.data || {}),
          [action.data.watermarkId]: watermarkDataCopy,
        },
      }
    }
    default:
      return state
  }
}

export default users
