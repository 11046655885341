import classNames from 'classnames'
import React from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'

import './styles.scss'

const renderBlock = (block, closeSearchBar) => {
  if (block.href) {
    return (
      <Link onClick={closeSearchBar} href={block.href} className='block-list__block'>
        {block.image && (
          <Image
            className='block-list__block__image'
            alt={block.image.alt}
            src={block.image.src}
            thumbnailsSpecs={[{ size: 'xs', media: '(min-width: 0px)' }]}
          />
        )}
        <div className='block-list__block__name-background' />
        <div className='block-list__block__name'>
          {block.name}
        </div>
      </Link>
    )
  }

  return (
    <div onClick={closeSearchBar} className='block-list__block'>
      {block.image && (
        <Image
          className='block-list__block__image'
          alt={block.image.alt}
          src={block.image.src}
          thumbnailsSpecs={[{ size: 'xs', media: '(min-width: 0px)' }]}
        />
      )}
      <div className='block-list__block__name-background' />
      <div className='block-list__block__name'>
        {block.name}
      </div>
    </div>
  )
}

export const BlocksList = props => {
  const { itemWidth, closeSearchBar, ...otherProps } = props

  const itemStyle = {
    width: `${itemWidth}px`,
  }

  if (props.fill) {
    itemStyle.width = `calc(100% / ${props.list.length} - ${props.itemPadding || 10}px)`
  }

  return (
    <ol
      className='block-list'
      style={{
        '--item-left-margin': `${props.itemPadding || 10}px`,
        '--item-height': props.itemHeight ? `${props.itemHeight}px` : '100%',
      }}
      {...otherProps}
    >
      {props.list.map((block, idx) => {
        return (
          <li
            id={idx}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            style={itemStyle}
            className={classNames('block-list__item', {
              'block-list__item--fill': props.fill,
            })}
          >
            {renderBlock(block, closeSearchBar)}
          </li>
        )
      })}
    </ol>
  )
}
