export const loadScript = (src, position, id) => {
  if (!position) {
    return Promise.reject('position argument must be given')
  }

  return new Promise(resolve => {
    const script = document.createElement('script')
    script.setAttribute('async', '')
    script.setAttribute('id', id)
    script.src = src
    position.appendChild(script)
    script.onload = resolve
  })
}
