import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import moment from 'moment'
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from 'underscore'

import { Breadcrumb } from 'components/Breadcrumb/index.js'
import { Button, IconButton } from 'components/Button'
import { List } from 'components/List/index.js'
import { Modal, SimpleMessageModal } from 'components/Modal'
import { Text } from 'components/Text'

import { useLocalActions } from '../../actions/useLocalActions.js'
import { EventEdit } from '../../components/EventEdit'

import { AppointmentQuickView } from './components/AppointmentQuickView'
import { CalendarRow } from './components/CalendarRow'
import { CalendarRowMobile } from './components/CalendarRowMobile'

import { useBreakpoints } from 'hooks/useBreakpoints/index.js'
import { useModal } from 'hooks/useModal/index.js'
import { useUrlQuery } from 'hooks/useUrlQuery'

import { calendarEventsSelector } from 'store/agendas/selectors.js'
import { allPacksAreLoaded } from 'store/packs/selectors'
import { currentUserPacksSelector } from 'store/users/selectors'

import { t } from 'services/i18n'
import { createBackOfficeLink } from 'services/routingService/linkFactory.js'
import { subRoutesNames } from 'services/routingService/routes.js'

import { getCalendarData } from './utils/calendarEventsUtils'

import './styles.scss'

const WEEK_DAYS = [
  t('back_office.calendar.week_days.sunday'),
  t('back_office.calendar.week_days.monday'),
  t('back_office.calendar.week_days.tuesday'),
  t('back_office.calendar.week_days.wednesday'),
  t('back_office.calendar.week_days.thursday'),
  t('back_office.calendar.week_days.friday'),
  t('back_office.calendar.week_days.saturday'),
]

let appointmentModalContext

export const Calendar = React.memo(() => {
  const queryMonth = useUrlQuery('m')
  const queryYear = useUrlQuery('y')
  const eventIdFromQuery = useUrlQuery('event')
  const history = useHistory()
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(
    state => logedInUserId !== undefined && state.users.users[state.users.currentUser.uid]
  )

  const { isMobileAndUp, isTabletAndUp } = useBreakpoints()

  const userPacksIds = logedInUser.packsIds
  const userPacksAreLoaded = useSelector(allPacksAreLoaded(userPacksIds))
  const userPacksAreLoading = useSelector(state => state.packs?.loading)
  const userPacks = useSelector(currentUserPacksSelector)
  const appointmentEventContextUser = useSelector(state =>
    appointmentModalContext &&
    (state.users.users[appointmentModalContext.user?.uid || appointmentModalContext.calendarEvent?.user?.uid]))

  const initialDate = queryMonth && queryYear ? moment().year(queryYear).month(queryMonth) : moment()
  const [ currentMonthDate, setCurrentMonthDate ] = useState(initialDate)
  const [ selectedCurrentDate, setSelectedCurrentDate ] = useState(moment())
  const [ appointmentQuickViewOpen, setAppointmentQuickViewOpen ] = useState(false)
  const [ appointmentModalOpen, setAppointmentModalOpen ] = useState(false)
  const [ isLoadingAgenda, setIsLoadingAgenda ] = useState(true)
  const [ removeEventModalIsOpen, openRemoveEventModal, closeRemoveEventModal ] = useModal()
  const rowEventsRefs = useRef({})

  const calendarLite = isMobileAndUp && !isTabletAndUp

  const cacheKey = currentMonthDate.startOf('month').format('MM-YYYY')
  const calendarData = useSelector(calendarEventsSelector(cacheKey))

  const monthDays = useMemo(
    () => getCalendarData(calendarData, currentMonthDate.clone()),
    [calendarData, currentMonthDate]
  )

  const {
    addAgendaEvent,
    fetchUserPacks: fetchUserPacksAction,
    fetchUser,
    fetchUserMonthlyAgenda,
    removeAgendaEvent,
  } = useLocalActions(logedInUser)

  // this cannot be inside useEffect. Otherwise, it fetches data too many times
  const startDateUnix = monthDays[0]?.date.unix()
  const endDateUnix = monthDays[monthDays.length - 1]?.date.unix()

  // use debounce so that user can go through months quickly without creating new requests
  // useCallback is needed so that useEffect is not being updated in every render
  const debounceFetchUserMonthlyAgenda =
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(
    debounce(async options => {
      await fetchUserMonthlyAgenda(options)
      setIsLoadingAgenda(false)
    }, 300),
    [fetchUserMonthlyAgenda]
  )

  useEffect(() => {
    // logedInUserId as a dependecy to perform only one request
    if (logedInUserId) {
      const options = { startDateUnix, endDateUnix, cacheKey }
      setIsLoadingAgenda(true)
      debounceFetchUserMonthlyAgenda(options)
    }
  }, [debounceFetchUserMonthlyAgenda, logedInUserId, startDateUnix, endDateUnix, cacheKey])

  const fetchUserPacksIfNecessary = useCallback(() => {
    if (!userPacksAreLoaded && !userPacksAreLoading) {
      fetchUserPacksAction()
    }
  }, [fetchUserPacksAction, userPacksAreLoaded, userPacksAreLoading])

  const onEventClickBase = useCallback((calendarEvent, event) => {
    fetchUserPacksIfNecessary() // needed inside Appointment component
    calendarEvent.user?.uid && fetchUser(calendarEvent.user.uid) // needed inside Appointment component
    event && event.stopPropagation()
  }, [fetchUser, fetchUserPacksIfNecessary])

  const onCalendarDayClick = useCallback(date => () => {
    if (selectedCurrentDate?.isSame(date, 'days') && !calendarLite) {
      return setSelectedCurrentDate(undefined)
    }
    setSelectedCurrentDate(date)
  }, [calendarLite, selectedCurrentDate])

  // open quick view for a specific event set on url query parameters
  useEffect(() => {
    if (!isLoadingAgenda && calendarData?.length > 0 && eventIdFromQuery) {
      const calendarEvent = calendarData.find(event => event.id === eventIdFromQuery)
      const calendarEventRef = rowEventsRefs.current[calendarEvent.id]

      // if mobile calendar, rowEventsRefs do not exist
      if (!calendarEventRef) {
        // remove event query parameter to avoid rendering infinite loop
        // TODO: Add way to share calendar link
        history.replace(createBackOfficeLink(subRoutesNames.BACK_OFFICE.CALENDAR, null, true, ['event']))
        onCalendarDayClick(moment.unix(calendarEvent.startDate))()
        return
      }

      appointmentModalContext = {
        calendarEvent,
        calendarEventRef,
      }
      onEventClickBase(calendarEvent, null)
      calendarEventRef.scrollIntoView()
      setAppointmentQuickViewOpen(true)
      // remove event query parameter to avoid quick view being always open
      history.replace(createBackOfficeLink(subRoutesNames.BACK_OFFICE.CALENDAR, null, true, ['event']))
    }
  }, [eventIdFromQuery, isLoadingAgenda, calendarData, onEventClickBase, history, onCalendarDayClick])

  const changeCurrentMonthDate = date => {
    const month = date.month()
    const year = date.year()
    history.replace(createBackOfficeLink(subRoutesNames.BACK_OFFICE.CALENDAR, null, false, null, { m: month, y: year }))
    setCurrentMonthDate(date)
  }

  const onTodayClick = () => {
    setSelectedCurrentDate(moment())
    changeCurrentMonthDate(moment())
  }

  const onNextMonthClick = () => changeCurrentMonthDate(currentMonthDate.clone().add(1, 'month'))

  const onPreviousMonthClick = () => changeCurrentMonthDate(currentMonthDate.clone().subtract(1, 'month'))

  const onCalendarDayDoubleClick = date => () => {
    appointmentModalContext = {
      startDate: date ? date.unix() : moment().unix(),
    }

    setAppointmentModalOpen(true)
  }

  const onEventDoubleClick = calendarEvent => event => {
    appointmentModalContext = calendarEvent
    onEventClickBase(calendarEvent, event)
    setAppointmentModalOpen(true)
  }

  const onEventClick = (event, calendarEvent, calendarEventRef) => {
    appointmentModalContext = {
      calendarEvent,
      calendarEventRef,
    }
    onEventClickBase(calendarEvent, event)
    setAppointmentQuickViewOpen(true)
  }

  const closeAppointmentModal = () => {
    appointmentModalContext = null
    setAppointmentModalOpen(false)
  }

  const closeAppointmentQuickView = () => {
    setAppointmentQuickViewOpen(false)
    setTimeout(() => { appointmentModalContext = null }, 0)
  }

  const onEditAppointment = () => {
    appointmentModalContext = appointmentModalContext.calendarEvent
    setAppointmentQuickViewOpen(false)
    setAppointmentModalOpen(true)
  }

  const onSaveAppointment = newEvent => {
    closeAppointmentModal()
    addAgendaEvent(newEvent)
    appointmentModalContext = null
  }

  const onDeleteAppointment = () => {
    removeAgendaEvent(appointmentModalContext.id)
    closeRemoveEventModal()
    closeAppointmentModal()
  }

  const rowsDaysRange = [[0, 7], [7, 14], [14, 21], [21, 28], [28, 35], [35, 42]]

  const selectedDateEvents = monthDays
    .find(monthDay => monthDay.date.isSame(selectedCurrentDate, 'day'))?.events || []

  return (
    <div className='calendar'>
      <Breadcrumb
        items={[
          {
            title: t('back_office.events.title'),
            url: createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS),
          }, {
            title: t('back_office.calendar.title'),
          },
        ]}
      />
      <div className='calendar__header'>
        <Button variant='outlined' onClick={onTodayClick}>
          {t('back_office.calendar.header.today_button')}
        </Button>
        <IconButton onClick={onPreviousMonthClick}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Text className='calendar__header__current-month'>{currentMonthDate.format(t('back_office.calendar.header.current_date'))}</Text>
        <IconButton onClick={onNextMonthClick}>
          <KeyboardArrowRightIcon />
        </IconButton>
        <IconButton onClick={onCalendarDayDoubleClick(selectedCurrentDate)} className='calendar__header__add-event'>
          <AddBoxOutlined />
        </IconButton>
      </div>
      <table className='calendar__table'>
        <thead>
          <tr className='calendar__table-header'>
            {WEEK_DAYS.map(weekDay => {
              return calendarLite ? <th>{weekDay[0].toUpperCase()}</th> : <th>{weekDay}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {rowsDaysRange.map(rowDaysRange => {
            const rowInitialDay = rowDaysRange[0]
            const rowFinalDay = rowDaysRange[1]

            if (calendarLite) {
              return (
                <CalendarRowMobile
                  key={`${rowInitialDay}-${rowFinalDay}`}
                  dates={monthDays.slice(rowInitialDay, rowFinalDay)}
                  onCalendarDayClick={onCalendarDayClick}
                  selectedCurrentDate={selectedCurrentDate}
                  currentMonthDate={currentMonthDate}
                />
              )
            }

            return (
              <CalendarRow
                key={`${rowInitialDay}-${rowFinalDay}`}
                dates={monthDays.slice(rowInitialDay, rowFinalDay)}
                onCalendarDayClick={onCalendarDayClick}
                onCalendarDayDoubleClick={onCalendarDayDoubleClick}
                selectedCurrentDate={selectedCurrentDate}
                currentMonthDate={currentMonthDate}
                onEventClick={onEventClick}
                onEventDoubleClick={onEventDoubleClick}
                rowEventsRefs={rowEventsRefs}
              />
            )
          })}
        </tbody>
      </table>
      {calendarLite && (
        <List vertical className='calendar__events'>
          {selectedDateEvents
          .sort((event, event2) => event.startDate - event2.startDate)
          .map(event => {
            const startDate = moment.unix(event.startDate)
            const endDate = moment.unix(event.endDate)
            const duration = endDate.diff(startDate, 'hours') + ' h'
            return (
              <div key={event.id} onClick={onEventDoubleClick(event)} className='calendar__event'>
                <div className='calendar__event__date'>
                  {event.startDate && <div>{startDate.format('HH:mm')}</div>}
                  {duration && <div>{duration}</div>}
                </div>
                <div className='calendar__event__data'>
                  <Text className='calendar__event__title' bold>{event.title}</Text>
                  {event.location?.address && <h5 className='calendar__event__address'>{event.location.address}</h5>}
                </div>
              </div>
            )
          })}
        </List>
      )}
      <Modal
        open={appointmentModalOpen}
        closeModal={closeAppointmentModal}
        title={appointmentModalContext?.title ? t('back_office.calendar.appointment.edit_title') : t('back_office.calendar.appointment.title')}
      >
        <EventEdit
          initialAppointment={appointmentModalContext}
          eventUser={appointmentEventContextUser}
          onCancel={closeAppointmentModal}
          onRemove={openRemoveEventModal}
          onSaveAppointment={onSaveAppointment}
          userPacks={userPacks}
          userPacksLoading={userPacksAreLoading}
          fetchUserPacksIfNecessary={fetchUserPacksIfNecessary}
        />
      </Modal>
      <SimpleMessageModal
        open={removeEventModalIsOpen}
        closeModal={closeRemoveEventModal}
        title={t('back_office.calendar.remove_event_confirmation_modal.title')}
        message={t('back_office.calendar.remove_event_confirmation_modal.confirmation', {
          event_title: appointmentModalContext?.title,
        })}
        actionButtonText={t('back_office.calendar.remove_event_confirmation_modal.action_button')}
        secondaryActionButtonText={t('back_office.calendar.remove_event_confirmation_modal.secondary_action_button')}
        actionButtonColor='danger'
        secondaryActionButtonColor='default'
        onActionButton={onDeleteAppointment}
        onSecondaryActionButton={closeRemoveEventModal}
      />
      <AppointmentQuickView
        open={appointmentQuickViewOpen}
        anchorEl={appointmentModalContext?.calendarEventRef}
        appointment={appointmentModalContext?.calendarEvent || {}}
        eventUser={appointmentEventContextUser}
        userPacks={userPacks}
        closeAppointmentQuickView={closeAppointmentQuickView}
        editAppointment={onEditAppointment}
      />
    </div>
  )
})
