import * as Sentry from '@sentry/react'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Error } from 'scenes/Error'

import { EVENT_KEYS, logEvent } from 'services/loggingService'

// https://reactjs.org/docs/error-boundaries.html

class ErrorBoundaryComponent extends React.Component {
  state = {
    hasError: false,
    error: undefined,
    currentPathname: window.location.pathname,
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  componentDidMount() {
    // This will catch errors thrown in a imperative way,
    // whereas getDerivedStateFromError/componentDidCatch will catch the errors thrown in a declarative way (inside React components)
    // This serves only a logger purpose. It cannot set any error UI since it's also catching errors sent in UI which is not allowing to have a tree layout error boundary
    window.onerror = (message, file, line, column, errorObject) => {
      logEvent(EVENT_KEYS.ERROR, {
        message,
        file,
        line,
        column,
        errorObject,
      })

      Sentry.captureException(errorObject)
      Sentry.captureMessage(message)
    }

    // This will only catch errors thrown inside Promises, that were not handled
    window.onunhandledrejection = ({ reason, timeStamp, type }) => {
      logEvent(EVENT_KEYS.ERROR, {
        name: reason.name,
        message: reason.message,
        stack: reason.stack,
        timeStamp,
        type,
      })

      Sentry.captureException(reason)
      Sentry.captureMessage(reason.message)
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.location.pathname !== state.currentPathname) {
      return {
        hasError: false,
        error: undefined,
        currentPathname: window.location.pathname,
      }
    }

    return null
  }

  render() {
    if (this.state.hasError) {
      if (this.props.silenceError) {
        return null
      }

      return <Error error={this.state.error} />
    }

    return this.props.children
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent)
