import { useState } from 'react'

const ALERT_TIMEOUT_IN_MS = 3500

export const useCollapsibleAlert = (duration = ALERT_TIMEOUT_IN_MS) => {
  const [alertContext, setAlertContext] = useState()
  const [alertIsOpen, setAlertIsOpen] = useState(false)

  const openAlert = context => {
    if (context) {
      setAlertContext(context)
    }
    setAlertIsOpen(true)
    setTimeout(() => {
      setAlertIsOpen(false)
    }, duration)
  }

  const closeAlert = () => {
    if (alertContext) {
      setAlertContext(undefined)
    }
    setAlertIsOpen(false)
  }

  return [alertIsOpen, openAlert, alertContext, closeAlert]
}
