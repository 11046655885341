import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import classNames from 'classnames'
import React, { Fragment } from 'react'

import { BackOfficeLogo } from 'components/BackOfficeLogo'
import { Link } from 'components/Link'
import UserCircular from 'components/UserCircular'

import { t } from 'services/i18n'

import './styles.scss'

export const HeaderMenuItem = ({ route, logedInUser, closeUserBtnMenu, onLogoutUser }) => {

  const renderItemContent = () => {
    if (route.id === 'logout') {
      return (
        <Fragment>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          {t(route.text)}
        </Fragment>
      )
    }

    if (route.id === 'user-settings') {
      return (
        <Fragment>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          {t(route.text)}
        </Fragment>
      )
    }

    if (route.id === 'profile') {
      return (
        <Fragment>
          <div className='header-menu-item__user-logo'>
            <UserCircular
              size={30}
              noInfo
              withFallback={true}
              {...logedInUser}
            />
          </div>
          { t(route.text) }
        </Fragment>
      )
    }

    if (route.id === 'back-office') {
      return (
        <BackOfficeLogo className='header-menu-item__back-office-logo' primary />
      )
    }

    return t(route.text)
  }

  if (route.type === 'divider') {
    return <Divider className='header-menu-item header-menu-item__divider' />
  }

  const canBeRendered = route.restrictedToProfessionals ? logedInUser.isPro : true
  if (route.url && canBeRendered) {
    return (
      <Link className='header-menu-item__link' href={route.url}>
        <MenuItem
          className={classNames('header-menu-item', {
            'header-menu-item--back-office-logo': route.id === 'back-office',
          })}
          onClick={closeUserBtnMenu}
        >
          {renderItemContent()}
        </MenuItem>
      </Link>
    )
  } else if (canBeRendered) {
    return (
      <MenuItem
        className='header-menu-item'
        onClick={route.id === 'logout' ? onLogoutUser : closeUserBtnMenu}
      >
        {renderItemContent()}
      </MenuItem>
    )
  }

  return null
}
