import classNames from 'classnames'
import React, { useRef } from 'react'

import { Button, LinkButton } from 'components/Button'
import { MansoryCardsLayout } from 'components/MansoryCardsLayout'
import { Text } from 'components/Text'

import { t } from 'services/i18n'

import './styles.scss'

const AddCard = ({ openModal, tabKey, isFirstCard, href }) => {
  const data = {
    title: t(`user_details.add_pin.${tabKey}${isFirstCard ? '.first_card' : ''}.title`),
    text: t(`user_details.add_pin.${tabKey}${isFirstCard ? '.first_card' : ''}.text`),
    buttonText: t(`user_details.add_pin.${tabKey}${isFirstCard ? '.first_card' : ''}.button_text`),
  }

  return (
    <div className='cards-layout__add-card'>
      <Text className='cards-layout__add-card__title' as='h2' type='title3'>{data.title}</Text>
      {data.text && <Text className='cards-layout__add-card__text' as='p' type='body'>{data.text}</Text>}
      {href && (
        <LinkButton
          variant='contained'
          color='primary'
          href={href}
        >
          {data.buttonText}
        </LinkButton>
      )}
      {!href && (
        <Button
          variant='contained'
          color='primary'
          onClick={openModal}
        >
          {data.buttonText}
        </Button>
      )}
    </div>
  )
}

/**
 * CardsLayout is a wrapper component of MansoryCardsLayout with high knowledge of UserDetails structure, including its component structure
 */
export const CardsLayout = props => {
  const masonryRef = useRef()

  return (
    <MansoryCardsLayout
      withPercentage
      itemsWidthInColumns={props.itemsWidthInColumns}
      ref={masonryRef}
      layoutID={props.layoutID}
      animate
    >
      {props.children}
      {props.isOwnerUser && (
        <div
          mansoryItemClassName={classNames('cards-layout__add-card-wrapper', {
            'cards-layout__add-card-wrapper--first-card': props.children.length === 0,
          })}
        >
          <AddCard
            openModal={props.openModal}
            tabKey={props.tabKey}
            isFirstCard={props.children.length === 0}
            href={props.addCardHref}
          />
        </div>
      )}
    </MansoryCardsLayout>
  )
}
