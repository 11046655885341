import { useLocation } from 'react-router-dom'
import { routes, routesNames } from 'services/routingService'

export const useRouteNameFromURL = () => {
  const location = useLocation()
  const splitedPathname = location.pathname.split('/')
  const urlRoutePath = splitedPathname[1] // e.g.: back-office

  const routingServiceRoutesEntries = Object.entries(routes)

  const routingServiceRouteIndex = routingServiceRoutesEntries
    .findIndex(([_routeKey, route]) => {
      const splittedRoute = route.split('/') // e.g.: ['', 'back-office', ':page?', ':subPage?']
      const routintServiceRoutePath = splittedRoute[1] // back-office
      return routintServiceRoutePath === urlRoutePath
    })

  if (routingServiceRouteIndex >= 0) {
    const routingServiceKey = routingServiceRoutesEntries[routingServiceRouteIndex][0]
    return routesNames[routingServiceKey]
  }

  return null
}
