import React from 'react'

import { Button } from 'components/Button'
import { List } from 'components/List'
import { LoadingWrapper } from 'components/LoadingComponent'

import { ContentRow, ContentRowSkeleton } from '../ContentRow'
import { getSearchResultHref } from '../../utils'

import { SEARCH_GROUP_IDS } from 'utils/search'

import './styles.scss'

const renderSearchResultsGroups = (availableSearchGroups, selectedSearchGroup, setSelectedSearchGroup) => {
  const separatedIndexes = availableSearchGroups
    .map((searchGroup, idx) => searchGroup.separated ? idx : -1)
    .filter(searchGroup => searchGroup !== -1)

  return (
    <List
      padding={10}
      separatedIndexes={separatedIndexes}
      separator='|'
      className='search-results__header__search-group-list'
      separatorClassName='search-results__header__group-separator'
    >
      {availableSearchGroups.map(group => (
        <Button
          key={group.id}
          onClick={() => setSelectedSearchGroup(group.id)}
          variant='outlined'
          color={selectedSearchGroup === group.id ? 'primary' : 'default'}
        >
          {group.name}
        </Button>
      ))}
    </List>
  )
}

const renderSearchResultsContent = (results, isSearching, closeSearchBar) => {
  return (
    <LoadingWrapper
      delay={500}
      isLoading={isSearching}
      skeleton={(
        <div className='search-results__content-row'>
          <ContentRowSkeleton />
        </div>
      )}
    >
      {results.map(result => {
        return (
          <div key={result.objectID} className='search-results__content-row'>
            <ContentRow
              onClick={closeSearchBar}
              href={getSearchResultHref(result.searchGroup.id, result.objectID)}
              value={result.title || result.name}
              category={result.searchGroup.name}
              extra={result.searchGroup.id === SEARCH_GROUP_IDS.PACKS ? result.user.name : undefined}
              iconName={result.iconName}
            />
          </div>
        )
      })}
      {results.length === 0 && 'No results'}
    </LoadingWrapper>
  )
}

export const SearchResults = ({
  results,
  isSearching,
  availableSearchGroups,
  selectedSearchGroup,
  setSelectedSearchGroup,
  closeSearchBar,
}) => {
  return (
    <div className='search-results'>
      <div className='search-results__header'>
        {renderSearchResultsGroups(availableSearchGroups, selectedSearchGroup, setSelectedSearchGroup)}
      </div>
      <div className='search-results__content'>
        {renderSearchResultsContent(results, isSearching, closeSearchBar)}
      </div>
    </div>
  )
}
