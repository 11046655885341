import {
  MapOutlined as MapOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  AppsOutlined as AppsOutlinedIcon,
} from '@mui/icons-material'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { EventDate } from 'scenes/BackOffice/components/EventDate'
import { GenericAddButtonList } from 'scenes/BackOffice/components/GenericAddButton/index.js'

import { Breadcrumb } from 'components/Breadcrumb/index.js'
import { Grid } from 'components/Grid'
import { IconLabel } from 'components/IconLabel/index.js'
import { Link } from 'components/Link'
import { MenuWrapper } from 'components/MenuWrapper'
import { Modal, SimpleMessageModal } from 'components/Modal'
import { Text } from 'components/Text'
import UserCircular from 'components/UserCircular'

import { useLocalActions } from '../../actions/useLocalActions.js'
import { EventEdit } from '../../components/EventEdit'
import { GalleryCard, GalleryCardSkeleton } from '../../components/GalleryCard'
import { GalleryEdit } from '../../components/GalleryEdit'

import { AddExistingGalleryModal } from './components/AddExistingGalleryModal/index.js'
import { NewGalleryButton } from './components/NewGalleryButton/index.js'

import { useModal } from 'hooks/useModal'
import { ACTION_KEYS, useRestrictedAction } from 'hooks/useRestrictedAction'

import { allPacksAreLoaded } from 'store/packs/selectors'
import { currentUserPacksSelector } from 'store/users/selectors'

import { t } from 'services/i18n'
import { subRoutesNames, createBackOfficeLink, createUserLink, createPackLink } from 'services/routingService'

import './styles.scss'

export const Event = () => {
  const { subPage: eventId } = useParams()
  const agendaEvents = useSelector(state => state.agendas.events.data)
  const event = agendaEvents?.[eventId] || {}
  const history = useHistory()

  const [ removeEventModalIsOpen, openRemoveEventModal, closeRemoveEventModal ] = useModal()
  const [ existingGalleryModalIsOpen, openExistingGalleryModal, closeExistingGalleryModal ] = useModal()
  const [ eventEditModalIsOpen, setEventEditModalIsOpen ] = useState(false)
  const [ addGalleryModalIsOpen, setAddGalleryModalIsOpen ] = useState(false)

  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(
    state => logedInUserId !== undefined && state.users.users[state.users.currentUser?.uid]
  )
  const userPacksIds = logedInUser.packsIds
  const userPacksAreLoaded = useSelector(allPacksAreLoaded(userPacksIds))
  const userPacksAreLoading = useSelector(state => state.packs?.loading)
  const userPacks = useSelector(currentUserPacksSelector)
  const eventUser = useSelector(state => event.user?.uid && state.users.users[event.user.uid])
  const galleriesLoadingState = useSelector(state => state.galleries.loading)
  const galleriesAreLoading = galleriesLoadingState ?? true
  const galleries = useSelector(state => state.galleries.data)
  const eventGalleries = Object.keys(event.galleries || {})
    .map(eventGalleryKey => galleries?.[eventGalleryKey])
    .filter(gallery => !!gallery)

  const isRestrictedPredicate = useCallback(userPlanIsLowerThanRequired => {
    return !!(userPlanIsLowerThanRequired && logedInUser.galleries?.galleryCount >= 2)
  }, [logedInUser])

  const [hasPermission, showUpsell] =
    useRestrictedAction(logedInUser, ACTION_KEYS.GALLERIES, { isRestrictedPredicate })

  const {
    editAgendaEvent,
    fetchUserPacks: fetchUserPacksAction,
    fetchUser,
    addGallery: addGalleryAction,
    fetchUserEvent,
    setRemovingAgendaEvent,
    removeAgendaEvent,
    removeGallery,
    fetchUserGalleries,
    editGalleryEvent,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    if (!userPacksAreLoaded && !userPacksAreLoading) {
      fetchUserPacksAction()
    }
  }, [event.id, fetchUserPacksAction, userPacksAreLoaded, userPacksAreLoading])

  useEffect(() => {
    const numberOfEventGalleries = Object.keys(event.galleries || {}).length
    if (
      numberOfEventGalleries > 0 &&
      galleriesLoadingState === undefined &&
      eventGalleries.length !== numberOfEventGalleries
    ) {
      fetchUserGalleries({
        eventId,
      }, true)
    }
  }, [event.galleries, eventGalleries.length, eventId, fetchUserGalleries, galleriesLoadingState])

  useEffect(() => {
    if (!eventUser && event.user?.uid) {
      fetchUser(event.user.uid)
    }
    if (event.id === undefined) {
      fetchUserEvent(eventId)
    }
  }, [eventUser, fetchUser, event.id, fetchUserEvent, eventId, event.user])

  const openEventEditModal = () => setEventEditModalIsOpen(true)
  const closeEventEditModal = () => setEventEditModalIsOpen(false)

  const onSaveAppointment = newEvent => {
    closeEventEditModal()
    editAgendaEvent(newEvent)
  }

  const onDeleteAppointment = () => {
    const removeGalleriesPromises = eventGalleries.map(eventGallery => {
      return removeGallery({ id: eventGallery.id })
    })

    setRemovingAgendaEvent(event.id)
    Promise.all(removeGalleriesPromises)
      .then(() => removeAgendaEvent(event.id))
    closeRemoveEventModal()
    closeEventEditModal()
    history.push(createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS))
  }

  const openAddGalleryModal = () => {
    if (hasPermission) {
      setAddGalleryModalIsOpen(true)
    } else {
      showUpsell()
    }
  }
  const closeAddGalleryModal = () => setAddGalleryModalIsOpen(false)

  const handleOpenAddExistingGalleryModal = () => {
    fetchUserGalleries(undefined, true)
    openExistingGalleryModal()
  }

  const createGallery = newGallery => {
    closeAddGalleryModal()

    // only after gallery is created, we should add it to agenda to avoid async errors
    addGalleryAction({
      ...newGallery,
      eventId: event.id,
      userUid: logedInUserId ?? null,
    }, logedInUser, event)
  }

  const associateExistingGallery = galleryId => {
    closeExistingGalleryModal()
    editGalleryEvent(galleryId, event)
  }

  const getGalleryOptions = galleryId => {
    return [
      {
        text: 'Desassociar galeria',
        iconId:  'playlist_remove',
        onClick: () => editGalleryEvent(galleryId, event, true),
      },
    ]
  }

  const eventStartDate = moment.unix(event.startDate)
  const eventMonth = eventStartDate.month()
  const eventYear = eventStartDate.year()
  const eventPack = userPacks?.find(pack => pack.id === event.packId)

  return (
    <div className='event'>
      <Breadcrumb
        items={[
          {
            title: t('back_office.events.title'),
            url: createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS),
          },
          {
            title: event.title,
          },
        ]}
      />
      <GenericAddButtonList
        list={[
          {
            key: 'open-in-calendar-cta',
            label: 'Ver no calendário',
            startIcon: true,
            iconId: 'date_range',
            href:
              createBackOfficeLink(
                subRoutesNames.BACK_OFFICE.CALENDAR,
                null,
                false,
                null,
                { m: eventMonth, y: eventYear, event: event.id }
              ),
          }, {
            key: 'edit-event-cta',
            label: 'Editar Evento',
            onClick: openEventEditModal,
            iconId: 'edit',
          },
        ]}
      />

      <div className='event__main-info'>
        <UserCircular
          className='event__main-info__user-circular'
          withFallback
          avatar={eventUser?.avatar}
          name={eventUser?.name || event.user?.name || '[Sem Cliente]'}
          href={event.user?.uid ? createUserLink(event.user?.uid) : undefined}
          size={28}
        />

        <EventDate
          startDate={moment.unix(event.startDate)}
          endDate={moment.unix(event.endDate)}
        />

        {eventPack && (
          <Link underlined href={createPackLink(eventPack.id)}>
            <IconLabel
              className='event__main-info__icon-info'
              icon={AppsOutlinedIcon}
              label={eventPack.packInfo.title}
            />
          </Link>
        )}

        {event.location?.address && (
          <IconLabel
            className='event__main-info__icon-info'
            icon={MapOutlinedIcon}
            label={event.location.address}
          />
        )}

        {event.description && (
          <IconLabel
            className='event__main-info__icon-info'
            icon={DescriptionOutlinedIcon}
            label={event.description}
          />
        )}
      </div>

      <div className='event__galleries__wrapper'>
        <Text type='title2' as='h2' className='event__galleries__title'>Galerias</Text>
        <div className='event__galleries__add-gallery'>
          <NewGalleryButton
            label='Adicionar Galeria'
            openAddGalleryModal={openAddGalleryModal}
            openAddExistingGalleryModal={handleOpenAddExistingGalleryModal}
          />
        </div>
        {Object.keys(eventGalleries).length === 0 && !galleriesAreLoading && (
          <div>
            <Text type='body' as='p' className='event__galleries__first-gallery__label'>Ainda não tens nenhuma galeria associada a este evento.</Text>
            <NewGalleryButton
              label='Adiciona a tua primeira galeria'
              openAddGalleryModal={openAddGalleryModal}
              openAddExistingGalleryModal={handleOpenAddExistingGalleryModal}
            />
          </div>
        )}
        {Object.keys(eventGalleries).length === 0 && galleriesAreLoading && (
          <Grid spacing={5} xs={6} sm={4} md={3} xl={2}>
            {Object.keys(event.galleries || {})
              .map(eventGallery => <GalleryCardSkeleton key={eventGallery.id} />)}
          </Grid>
        )}
        <Grid spacing={5} xs={6} sm={4} md={3} xl={2}>
          {eventGalleries
            .sort((gallery1, gallery2) => gallery2.creationDate - gallery1.creationDate)
            .map((gallery => {
              const defaultCoverPhotoId = (gallery.photosLayout || [])[0]
              const coverPhotosrc = gallery.coverPhoto?.src || (gallery.photos || {})[defaultCoverPhotoId]?.src
              const menuOptions = getGalleryOptions(gallery.id)
              return (
                <MenuWrapper
                  key={gallery.id}
                  editMode={menuOptions.length > 0}
                  options={menuOptions}
                >
                  <GalleryCard
                    id={gallery.id}
                    coverImageSrc={coverPhotosrc}
                    title={gallery.title}
                    imagesCount={(gallery.photosLayout || []).length}
                    sharingOptions={gallery.sharingOptions}
                    srcContext='event-page'
                  />
                </MenuWrapper>
              )
            }))}
        </Grid>
      </div>
      <Modal
        open={eventEditModalIsOpen}
        closeModal={closeEventEditModal}
        title={event ? t('back_office.calendar.appointment.edit_title') : t('back_office.calendar.appointment.title')}
      >
        <EventEdit
          initialAppointment={event}
          eventUser={eventUser}
          onRemove={openRemoveEventModal}
          onCancel={closeEventEditModal}
          onSaveAppointment={onSaveAppointment}
          userPacks={userPacks}
          userPacksLoading={userPacksAreLoading}
        />
      </Modal>
      <Modal
        open={addGalleryModalIsOpen}
        closeModal={closeAddGalleryModal}
        title={t('back_office.event.add_gallery.title')}
      >
        <GalleryEdit
          onCancel={closeAddGalleryModal}
          onSaveGallery={createGallery}
          logedInUser={logedInUser}
        />
      </Modal>
      <Modal
        open={existingGalleryModalIsOpen}
        closeModal={closeExistingGalleryModal}
        title={t('back_office.event.add_existing_gallery.title')}
      >
        <AddExistingGalleryModal
          galleries={Object.values(galleries || {})}
          isLoading={galleriesAreLoading}
          associateExistingGallery={associateExistingGallery}
        />
      </Modal>
      <SimpleMessageModal
        open={removeEventModalIsOpen}
        closeModal={closeRemoveEventModal}
        title={t('back_office.event.remove_event_confirmation_modal.title')}
        message={eventGalleries.length > 0
          ? t('back_office.event.remove_event_confirmation_modal.confirmation--with-galleries', {
            event_title: event?.title,
          })
          : t('back_office.event.remove_event_confirmation_modal.confirmation', {
            event_title: event?.title,
          })}
        actionButtonText={t('back_office.event.remove_event_confirmation_modal.action_button')}
        secondaryActionButtonText={t('back_office.event.remove_event_confirmation_modal.secondary_action_button')}
        actionButtonColor='danger'
        secondaryActionButtonColor='default'
        onActionButton={onDeleteAppointment}
        onSecondaryActionButton={closeRemoveEventModal}
      />
    </div>
  )
}
