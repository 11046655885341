import { doc, getDoc, setDoc } from '@firebase/firestore'
import { getDatabase } from 'services/firebaseServer'

import { logError } from 'utils/errorCapture'

export const fetchFeatureByUser = (featureKey, userUid) => {
  const featureNotificationDocRef = doc(getDatabase(), 'features', featureKey, 'users', userUid)

  console.info(`Fetching feature notification ${featureKey} from user ${userUid}`)
  return getDoc(featureNotificationDocRef)
    .then(featureNotification => {
      console.info(`Fetched feature notification ${featureKey} from user ${userUid}`)
      if (featureNotification.exists()) {
        return featureNotification.data()
      } else {
        return {}
      }
    })
    .catch(error => {
      logError(error, `Error fetching feature notification ${featureKey} from user ${userUid}: ${error}`)
      throw error
    })
}

export const postFeatureNotificationByUser = (featureKey, userUid) => {
  const featureNotificationDocRef = doc(getDatabase(), 'features', featureKey, 'users', userUid)

  console.info(`Updating feature notification ${featureKey} from user ${userUid}`)
  return setDoc(featureNotificationDocRef, { dismissed: true })
    .then(() => {
      console.info(`Updated feature notification ${featureKey} from user ${userUid}`)
    })
    .catch(error => {
      logError(error, `Error updating feature notification ${featureKey} from user ${userUid}: ${error}`)
      throw error
    })
}
