import { Pack } from 'model/Pack'

import { allPacksAreLoaded } from 'store/packs/selectors'

export const getUserPacksFromState = (state, user) => {
  const userPacksIds = user?.packs?.cards.map(card => card.id) || []

  const allUserPacksAreLoaded = allPacksAreLoaded(userPacksIds)(state)

  if ((user && !state.packs.data) || !allUserPacksAreLoaded) {
    return userPacksIds
      .map(userPackId => new Pack({
        id: userPackId,
      }))
  }

  return userPacksIds
    .map(userPackId => state.packs.data[userPackId])
}
