import { Skeleton } from '@mui/material'
import React from 'react'

import { GalleryMasonryLayout } from 'components/GalleryMasonryLayout'

import { useBreakpoints } from 'hooks/useBreakpoints'

import './styles.scss'
import classNames from 'classnames'

export const GalleryPhotoSkeleton = React.forwardRef((props, ref) => {
  return (
    <Skeleton
      ref={ref}
      className={classNames('gallery-photos-skeleton--photo-skeleton', {
        'gallery-photos-skeleton--rect-angles': props.rectAngles,
      })}
      animation='wave'
      variant='rectangular'
      height={0}
      style={{ paddingTop: '65%' }}
    />
  )
})

export const GalleryPhotosSkeleton = () => {
  const { isDesktopAndUp } = useBreakpoints()

  return (
    <GalleryMasonryLayout>
      <Skeleton className='gallery-photos-skeleton--photo-skeleton' animation='wave' variant='rectangular' width='49%' height={isDesktopAndUp ? 360 : 260} />
      <Skeleton className='gallery-photos-skeleton--photo-skeleton' animation='wave' variant='rectangular' width='49%' height={isDesktopAndUp ? 360 : 260} />
      <Skeleton className='gallery-photos-skeleton--photo-skeleton' animation='wave' variant='rectangular' width='49%' height={isDesktopAndUp ? 340 : 240} />
      <Skeleton className='gallery-photos-skeleton--photo-skeleton' animation='wave' variant='rectangular' width='49%' height={isDesktopAndUp ? 590 : 490} />
      <Skeleton className='gallery-photos-skeleton--photo-skeleton' animation='wave' variant='rectangular' width='49%' height={isDesktopAndUp ? 340 : 240} />
    </GalleryMasonryLayout>
  )
}
