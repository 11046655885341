import classNames from 'classnames'
import React from 'react'

import { SimpleImage } from 'components/Image/components/SimpleImage'

import './styles.scss'

/**
 * Image type to be used when we want the image to have a pre defined size
 * In this type, the wrapper of the image will be rendered with the specified size, saving the correct space in the UI for the image render
 * If one of the sizes is not given, it will default to 100%
 * The height and width of the image will be the same as the parent once is loaded
 */
export const SizedImage = props => {
  const { alt, wrapperClassName, ...otherProps } = props

  return (
    <div
      className={classNames('sized-image', wrapperClassName)}
      style={{
        '--image-height': props.height ? `${props.height}px` : '100%',
        '--image-width': props.width ? `${props.width}px` : '100%',
      }}
    >
      <SimpleImage
        className={props.className && props.className}
        alt={alt}
        {...otherProps}
      />
    </div>
  )
}
