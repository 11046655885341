import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import { Modal } from 'components/Modal'
import { ShareLink } from 'components/ShareLink'

import { createGalleryLink } from 'services/routingService'

export const ShareModal = ({ closeModal, open, imageId }) => {
  const { id } = useParams()

  return (
    <Modal width={440} title='Partilhar foto' closeModal={closeModal} open={open}>
      Partilha esta foto através deste link
      <ShareLink linkToShare={createGalleryLink(id, { photo: imageId }, true, null, true)} />
    </Modal>
  )
}
