import classNames from 'classnames'
import Masonry from 'masonry-layout'
import React, { Children, useContext, useEffect, useState } from 'react'

import { WindowSizeContext } from 'hooks/useWindowSize'

import './styles.scss'

const getStyle = props => {
  const style = {}

  if (props.width) {
    style['--card-width'] = props.width
    style['--card-width--mobile'] = props.width
  }

  if (props.widthMobile) {
    style['--card-width--mobile'] = props.widthMobile
  }

  if (props.columns) {
    style['--card-width'] = 100 / props.columns + '%'
    style['--card-width--mobile'] = 100 / props.columns + '%'
  }

  return style
}

export const MansoryCardsLayout = React.forwardRef((props, ref) => {
  const [currentLayoutID, setCurrentLayoutID] = useState(props.layoutID)
  const [windowSize] = useContext(WindowSizeContext)
  const itemsWidthInColumnsString = (props.itemsWidthInColumns || []).join('-')

  useEffect(() => {
    setCurrentLayoutID(props.layoutID)
  }, [props.layoutID])

  useEffect(() => {
    const isFirstTime = !ref?.current && props.children && props.children.length > 0
    const layoutHasChanged = ref?.current && props.layoutID !== currentLayoutID
    if (isFirstTime || layoutHasChanged || !props.animate) {
      const itemSelector = props.itemSelector ? `.${props.itemSelector}` : '.mansory-cards-layout__card'
      const masonry = new Masonry('.mansory-cards-layout', {
        itemSelector,
        columnWidth: props.itemSelector ? `.${props.itemSelector}__sizer` : '.mansory-cards-layout__sizer',
        percentPosition: props.withPercentage,
        horizontalOrder: props.horizontalOrder ?? true,
      })

      ref && (ref.current = masonry)
    }
  }, [
    currentLayoutID,
    props.children,
    props.itemSelector,
    props.layoutID,
    props.withPercentage,
    props.animate,
    ref,
    props.horizontalOrder,
  ])

  useEffect(() => {
    if (props.layoutID === currentLayoutID && props.animate) {
      ref?.current && ref.current.layout()
      // This is a hack to update when children width dimensions are changed after the columns value is changed
      // Those updates will not trigger any update here in this component since the dimensions will be updated only after the layout is updated
      // Last time this was changed (from 0 to 250) was due to bug where user details Pack images were overlaping the masonry body space because it was not updating
      setTimeout(() => ref?.current && ref.current.layout(), 250)
    }
  }, [
    props.children,
    props.itemSelector,
    props.withPercentage,
    props.animate,
    windowSize,
    props.columns,
    ref,
    itemsWidthInColumnsString,
    props.layoutID,
    currentLayoutID,
  ])

  useEffect(() => {
    if (props.layoutID === currentLayoutID && props.animate) {
      ref?.current && ref.current.reloadItems()
    }
  }, [currentLayoutID, props.children, props.layoutID, props.animate, ref])

  return (
    <div style={getStyle(props)} className='mansory-cards-layout'>
      {/* .user-details__tabs-content-sizer empty element, only used for element sizing */}
      <div className={props.itemSelector ? `${props.itemSelector}__sizer` : 'mansory-cards-layout__sizer'} />
      {Children.map(props.children, (child, index) => {
        return props.itemSelector
          ? child
          : (
            <div
              className={classNames(
                'mansory-cards-layout__card',
                {
                  [`mansory-cards-layout__card--cols${props.itemsWidthInColumns?.[index] || 1}`]: props.itemsWidthInColumns,
                },
                child?.props.mansoryItemClassName
              )}
            >
              {child}
            </div>
          )
      })}
    </div>
  )
})
