import MoreVertIcon from '@mui/icons-material/MoreVert'
import Icon from '@mui/material/Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import classNames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'

import { IconButton } from 'components/Button'

import './styles.scss'

const ITEM_HEIGHT = 60

const editMenuUI = (menuBtnRef, menuIsOpen, handleClick, handleClose, options, hidden) => {
  const onItemClick = idx => event => {
    event.stopPropagation()
    handleClose()
    options[idx].onClick && options[idx].onClick(event)
  }

  return (
    <div className={classNames('menu-wrapper__edit-menu', {
      'menu-wrapper__edit-menu--hidden': hidden,
    })}
    >
      <IconButton
        className='menu-wrapper__menu-button'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        ref={menuBtnRef}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={menuBtnRef.current}
        open={menuIsOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '26ch',
          },
        }}
      >
        {options.map((option, optionIdx) => {
          return (
            <MenuItem
              key={option}
              selected={option.text === 'Pyxis'}
              onClick={onItemClick(optionIdx)}
              disabled={options.disabled}
            >
              {option.iconId && (
                <ListItemIcon>
                  <Icon fontSize='small'>
                    {option.iconId}
                  </Icon>
                </ListItemIcon>
              )}
              <ListItemText primary={option.text} />
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export const MenuWrapper = props => {
  const [ menuIsOpen, setMenuIsOpen ] = useState(props.open ?? false)
  const menuBtnRef = useRef()

  useEffect(() => {
    setMenuIsOpen(props.open ?? false)
  }, [props.open])

  const handleCloseMenu = () => {
    // if state is controlled by parent, call parent onCloseMenu and do not set state directly
    if (props.open && props.onCloseMenu) {
      props.onCloseMenu()
    } else {
      setMenuIsOpen(false)
    }
  }

  const handleClickMenu = event => {
    setMenuIsOpen(prevMenuIsOpen => !prevMenuIsOpen)
    event.stopPropagation()
  }

  return (
    <div className='menu-wrapper'>
      {props.editMode &&
        editMenuUI(menuBtnRef, menuIsOpen, handleClickMenu, handleCloseMenu, props.options, props.withoutMenuButton)}
      {props.children}
    </div>
  )
}
