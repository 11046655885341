import React from 'react'

import { Text } from 'components/Text'

import { InformationTab } from './components/InformationTab'

import './styles.scss'

export const InformationSection = props => {
  return (
    <div className='information-section'>
      {props.information && (
        <Text className='information-section__information'>{props.information}</Text>
      )}
      <div className='information-section__faqs'>
        {props.quickInfo?.map(infoTab => {
          return (
            <InformationTab
              key={infoTab.title}
              iconId={infoTab.iconId}
              title={infoTab.title}
              body={infoTab.body}
            />
          )
        })}
      </div>
      {props.price && props.price.value && (
        <div>
          <Text as='h3' type='title4' className='information-section__price-label'>Preço</Text>
          <Text as='p' className='information-section__price'>
            {props.price.priceFrom && 'Desde '}
            {`${props.price.value}€`}
          </Text>
        </div>
      )}
    </div>
  )
}
