import React from 'react'

import { LinkButton } from 'components/Button'
import { Image } from 'components/Image'

import './styles.scss'

export const TitleImageSideBySide = ({ title, subTitle, image, actionButton }) => {
  return (
    <div className='title-image-side-by-side'>
      <div className='title-image-side-by-side__titles'>
        <h2 className='title-image-side-by-side__title'>{title}</h2>
        <h4 className='title-image-side-by-side__sub-title'>{subTitle}</h4>
        {actionButton && (
          <LinkButton
            className='title-image-side-by-side__action-button'
            href={actionButton.href}
            variant={actionButton.variant || 'contained'}
            color={actionButton.color}
          >
            {actionButton.text}
          </LinkButton>
        )}
      </div>

      {image && (
        <Image
          className='title-image-side-by-side__image'
          alt={image.alt}
          src={image.src}
          width={image.width}
          height={image.height}
          thumbnailsSpecs={[
            { size: 's', media: '(max-width: 600px)' },
            { size: 'm', media: '(min-width: 600px)' },
          ]}
        />
      )}
    </div>
  )
}
