import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { formatBytes } from 'utils/storageData'

import cssVariables from 'styles/theme/colors.scss'

const getData = storage => {
  return {
    labels: ['Packs', 'Portefólio', 'Galerias', 'Outros'],
    datasets: [
      {
        label: 'Tamanho Armazenamento',
        data: [
          storage.packs,
          storage.portfolio,
          storage.galleries,
          storage.other,
        ],
        backgroundColor: [
          cssVariables.primaryColor,
          cssVariables.secondaryColor,
          cssVariables.thirdColor,
          cssVariables.lightGrey,
        ],
      },
    ],
  }
}

const loadingData = {
  labels: ['Packs', 'Portefólio', 'Galerias', 'Outros'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [1, 1, 1, 1],
      backgroundColor: [cssVariables.lightGrey],
    },
  ],
}

export const StorageDoughnut = ({ isLoading, storage, legendPosition = 'bottom', aspectRatio = 1.8 }) => {

  return (
    <Doughnut
      type='doughnut'
      data={isLoading ? loadingData : getData(storage)}
      updateMode='active'
      options={{
        aspectRatio,
        cutout: '70%',
        responsive: true,
        animation: {
          duration: isLoading ? 0 : 600,
        },
        plugins: {
          legend: {
            position: legendPosition,
          },
          tooltip: {
            callbacks: {
              label: context => `${context.label} ${formatBytes(context.raw)}`,
            },
          },
        },
      }}
    />
  )
}
