import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { fetchFeatureByUser, postFeatureNotificationByUser } from 'services/firebaseServer/featuresNotifications'

export const useNewFeatureNotification = (featureKey, userUid) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data, error, isLoading } = useQuery({
    queryKey: [`feature-notification-${featureKey}`],
    queryFn: () => fetchFeatureByUser(featureKey, userUid),
    retry: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (!isLoading && error === null && data?.dismissed !== true) {
      setIsOpen(true)
    }
  }, [data, error, isLoading])

  const mutation = useMutation({
    mutationFn: () => postFeatureNotificationByUser(featureKey, userUid),
  })

  const onFeatureNotificationDismiss = () => {
    setIsOpen(false)
    mutation.mutate()
  }

  return [isOpen, onFeatureNotificationDismiss]
}
