import moment from 'moment'

// use reduce to populate all arrays instead of 3 different maps
export const getEventsByCategory = agendaEvents =>
  Object.keys(agendaEvents)
    .reduce((prev, currEventKey) => {
      const event = agendaEvents[currEventKey]
      const currentUnix = moment().unix()

      if (event.startDate < currentUnix && event.endDate > currentUnix) {
        return {
          ...prev,
          onGoing: [...prev.onGoing || [], event],
        }
      }

      if (event.startDate > currentUnix) {
        return {
          ...prev,
          coming: [...prev.coming || [], event],
        }
      }

      if (event.endDate < currentUnix) {
        return {
          ...prev,
          past: [...prev.past || [], event],
        }
      }

      return prev
    }, {})
