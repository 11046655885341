import React from 'react'
import CopyIcon from '@mui/icons-material/CopyAll'
import DownloadIcon from '@mui/icons-material/Download'
import { t } from 'services/i18n'

import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { Button, IconButton } from 'components/Button'
import { Alert } from 'components/Alert'

import './styles.scss'

export const RecoveryCodeModal = ({
  open,
  closeModal,
  recoveryCode,
}) => {
  const copyCode = () => {
    navigator.clipboard.writeText(recoveryCode)
  }

  const downloadCode = () => {
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(recoveryCode))
    element.setAttribute('download', 'plotu mfa recovery code.txt')

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  const title = t('profile_settings.password_settings.new_recovery_code_modal.title')

  return (
    <Modal title={title} open={open} closeModal={closeModal} danger>
      <div className='recovery-modal__content'>
        <Text type='body' className='recovery-modal__message'>{t('profile_settings.password_settings.new_recovery_code_modal.description')}</Text>
        <Text type='body2' className='recovery-modal__message'>{t('profile_settings.password_settings.new_recovery_code_modal.where_to_save')}</Text>
        <Alert severity='warning'>
          <Text type='body2'>{t('profile_settings.password_settings.new_recovery_code_modal.alert')}</Text>
        </Alert>

        <div className='recovery-modal__code-wrapper'>
          <Text type='title3'>{title}</Text>
          <div className='recovery-modal__code'>
            <Text className='recovery-modal__code-box' type='body2'>{recoveryCode}</Text>
            <div className='recovery-modal__code-actions'>
              <IconButton
                onClick={downloadCode}
                className='recovery-modal__code-action'
                color='default'
                size='big'
                aria-label='Download Code'
                bottomText='Download'
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                onClick={copyCode}
                className='recovery-modal__code-action'
                color='default'
                size='big'
                aria-label='Copiar Code'
                bottomText='Copiar Código'
              >
                <CopyIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className='recovery-modal__cta'>
          <Button
            color='primary'
            variant='contained'
            onClick={closeModal}
          >
            {t('profile_settings.password_settings.new_recovery_code_modal.cta')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
