import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React from 'react'
import { MdLocationOn } from 'react-icons/md'

import { Categories } from 'components/Categories'
import { IconLabel } from 'components/IconLabel'
import { Link } from 'components/Link'
import UserCircular from 'components/UserCircular'

import { createUserLink } from 'services/routingService'

import './styles.scss'

export const PhotographerSearchResultItemSkeleton = props => {
  return (
    <div className={classNames('photographer-search-result-item', {
      'photographer-search-result-item--gallery': props.selectedViewId === 'gallery',
    })}
    >
      <Skeleton animation='wave' variant='circular' width={80} height={80} />
      <div className={classNames('photographer-search-result-item__content', 'photographer-search-result-item__content--skeleton', {
        'photographer-search-result-item__content--gallery': props.selectedViewId === 'gallery',
      })}
      >
        <Skeleton animation='wave' variant='text' width={140} />
        <Skeleton animation='wave' variant='text' width={140} />
        <Skeleton animation='wave' variant='text' width={80} />
      </div>
    </div>
  )
}

export const PhotographerSearchResultItem = props => {
  return (
    <div className={classNames('photographer-search-result-item', {
      'photographer-search-result-item--gallery': props.selectedViewId === 'gallery',
    }, props.className)}
    >
      <Link className='photographer-search-result-item__user-circular' href={createUserLink(props.id || 'wrong-id', undefined, true, ['q', 'v'])}>
        <UserCircular size={100} noInfo withFallback avatar={props.avatar} />
      </Link>
      <div className={classNames('photographer-search-result-item__content', {
        'photographer-search-result-item__content--gallery': props.selectedViewId === 'gallery',
      })}
      >
        <Link href={createUserLink(props.id || 'wrong-id', undefined, true, ['q', 'v'])} underlined>
          <h2
            className={classNames('photographer-search-result-item__content__title', {
              'photographer-search-result-item__content__title--center': props.selectedViewId === 'gallery',
            })}
          >
            {props.name}
          </h2>
        </Link>
        {props.location && <IconLabel className='photographer-search-result-item__content__local' icon={MdLocationOn} label={props.location} noLeftMargin />}
        <Categories
          variant='dots'
          listClassName='photographer-search-result-item__content__categories'
          itemClassName='photographer-search-result-item__content__categories__item'
          categories={props.categories}
        />
      </div>
    </div>
  )
}
