import React, { useEffect, useCallback } from 'react'

import { Notification } from 'components/Notification'

import { useNotification, INTERACTION_TYPES } from 'hooks/useNotification'

import { getNotificationSystem } from 'services/notificationSystem'

export const NOTIFICATION_LISTENER_ID = 'global-notification-listener-id'
export const NOTIFICATION_TYPE = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
}

const NOTIFICATION_HEIGHT = 48
const TOP_MARGIN = 40

export const GlobalNotifications = () => {
  const [notifications, showNotification, onInteraction, onCloseNotification] = useNotification()

  const onNotification = useCallback(notification => {
    showNotification(notification)
  }, [showNotification])

  useEffect(() => {
    const notificationSystem = getNotificationSystem()
    notificationSystem.subscribe(NOTIFICATION_LISTENER_ID, onNotification)

    return () => notificationSystem.unSubscribe(onNotification)
  }, [onNotification])

  const onNotificationInteraction = type => () => {
    onInteraction(type)
  }

  const onClose = notificationId => () => onCloseNotification(notificationId)

  return notifications.map((notification, index) => {
    return (
      <div
        key={notification?.id}
        onMouseDown={onNotificationInteraction(INTERACTION_TYPES.MOUSE_DOWN)}
        onMouseUp={onNotificationInteraction(INTERACTION_TYPES.MOUSE_UP)}
        onMouseEnter={onNotificationInteraction(INTERACTION_TYPES.MOUSE_ENTER)}
        onMouseLeave={onNotificationInteraction(INTERACTION_TYPES.MOUSE_LEAVE)}
      >
        <Notification
          type={notification?.type}
          style={{
            transform: `translateY(${((NOTIFICATION_HEIGHT + 5) * index) + TOP_MARGIN}px)`,
          }}
          open
          handleClose={onClose(notification.id)}
          message={notification.message}
        />
      </div>
    )
  })
}
