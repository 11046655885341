import React, { Fragment } from 'react'

import { Button } from 'components/Button'
import { Text } from 'components/Text'

import { ComponentGetter } from '../../../../components/ComponentGetter'

import './styles.scss'

/**
 * This component should maintain the abstraction of all the dynamic components used by connecting them to the PackDetails scene
 */
export const DynamicComponentWrapper = ({ componentTitle, componentId, componentData, hasMore, onHasMoreBtnClick }) => {
  return (
    <Fragment>
      <Text as='h3' type='title4' className='dynamic-component-wrapper__title'>{componentTitle}</Text>
      <ComponentGetter componentId={componentId} componentContent={componentData} />
      {hasMore && (
        <Button onClick={onHasMoreBtnClick} variant='outlined' color='default'>
          {hasMore.moreBtnText}
        </Button>
      )}
    </Fragment>
  )
}
