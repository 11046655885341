import { useState, useRef } from 'react'

import { UPLOAD_STATUS } from 'model/UploadStatus'

const DEFAULT_DURATION_TO_RESET_AFTER_UPLOADING = 500

export const useUploading = ({ initialDelay } = {}) => {
  const [ uploadingStatus, setUploadingStatus ] = useState(UPLOAD_STATUS.NOT_UPLOADED)
  const [uploadingProgress, setUploadingProgress ] = useState(0)
  const [hasError, setHasError ] = useState(0)
  const [uploadingLabel, setUploadingLabel ] = useState()
  const setUploadingTimeout = useRef()

  const startUploading = () => {
    if (initialDelay) {
      setUploadingTimeout.current = setTimeout(() => {
        setUploadingStatus(UPLOAD_STATUS.UPLOADING)
      }, initialDelay)
    } else {
      setUploadingStatus(UPLOAD_STATUS.UPLOADING)
    }
  }

  const finishUploading = () => {
    if (setUploadingTimeout.current) {
      clearTimeout(setUploadingTimeout.current)
    }

    setUploadingStatus(prevStatus => {
      if (prevStatus === UPLOAD_STATUS.UPLOADING) {
        setUploadingStatus(UPLOAD_STATUS.UPLOADED)

        setTimeout(() => {
          setUploadingStatus(UPLOAD_STATUS.NOT_UPLOADED)
        }, DEFAULT_DURATION_TO_RESET_AFTER_UPLOADING)
      }
    })
  }

  return [
    uploadingStatus,
    startUploading,
    finishUploading,
    uploadingProgress,
    setUploadingProgress,
    hasError,
    setHasError,
    uploadingLabel,
    setUploadingLabel,
  ]
}
