import ArrowRight from '@mui/icons-material/ArrowForwardIos'
import Clear from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import classNames from 'classnames'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { BlocksList } from 'components/BlocksList'
import { IconButton } from 'components/Button'

import { LastSearches } from '../LastSearches'
import { SearchResults } from '../SearchResults'
import { searchResults, getRecommendedCategories } from '../../utils'

import { useDebounce } from 'hooks/useDebounce'
import { useScrollOffset, getScrollTop } from 'hooks/useScrollOffset'
import { WindowSizeContext } from 'hooks/useWindowSize'

import { t } from 'services/i18n'
import { createSearchLink } from 'services/routingService'

import { getAvailableSearchGroups, SEARCH_GROUP_IDS, parseResults } from 'utils/search'

import './styles.scss'

const CLOSE_SEARCH_BAR_SCROLL_THRESHOLD = 150

/**
 * Helper function to know if user scrolled beyound a specific threshold, when search bar is open
 */
const isBeyoundThreshold = (scrollOffset, searchBarOpenScollOffset) => {
  return scrollOffset > CLOSE_SEARCH_BAR_SCROLL_THRESHOLD + searchBarOpenScollOffset.current ||
    scrollOffset < searchBarOpenScollOffset.current - CLOSE_SEARCH_BAR_SCROLL_THRESHOLD
}

export const HeaderSearchBar = () => {
  const [ searchValue, setSearchValue ] = useState()
  const [ results, setResults ] = useState([])
  const [ isSearching, setIsSearching ] = useState(false)
  const [ selectedSearchGroup, setSelectedSearchGroup ] = useState(SEARCH_GROUP_IDS.ALL)
  const searchBarOpenScollOffset = useRef(null)
  const searchBarRef = useRef(null)
  const searchBarWrapperRef = useRef(null)
  const searchBarInputRef = useRef(null)
  const [ searchInputLeftOffset, setSearchInputLeftOffset ] = useState(0)
  const [ searchBarIsOpen, setSearchBarIsOpen ] = useState(false)
  const [ scrollOffset ] = useScrollOffset()
  const [windowSize] = useContext(WindowSizeContext)
  const history = useHistory()

  const debouncedSearchTerm = useDebounce(searchValue, 250)

  useEffect(() => {
    if (searchBarIsOpen) {
      const searchBarLeftOffset = searchBarWrapperRef.current.getBoundingClientRect().x
      setSearchInputLeftOffset(`${searchBarLeftOffset}px`)
    }
  }, [searchBarIsOpen, windowSize])

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true)
      searchResults(selectedSearchGroup, debouncedSearchTerm)
        .then(results => {
          setIsSearching(false)
          const { parsedResults } = parseResults(results, selectedSearchGroup)
          setResults(parsedResults)
        })
    } else {
      setResults([])
    }
  }, [debouncedSearchTerm, selectedSearchGroup])

  const openSearchBarAnimation = () => {
    searchBarOpenScollOffset.current = getScrollTop()
    const searchBarLeftOffset = searchBarWrapperRef.current.getBoundingClientRect().x
    setSearchInputLeftOffset(`${searchBarLeftOffset}px`)
    setSearchBarIsOpen(true)
    searchBarInputRef.current.focus()
  }

  const openSearchBarIfNotOpen = () => {
    if (!searchBarIsOpen) {
      openSearchBarAnimation()
    }
  }

  const onSubmit = event => {
    closeSearchBar()

    history.push(createSearchLink(selectedSearchGroup, searchValue, undefined, undefined, false))

    event.preventDefault()
    event.stopPropagation()
  }

  const closeSearchBar = () => setSearchBarIsOpen(false)

  const clearSearchInput = () => setSearchValue('')

  const onSearchValueChange = event => {
    openSearchBarIfNotOpen()
    const searchValue = event.target.value
    setSearchValue(searchValue)
  }

  if (searchBarIsOpen && isBeyoundThreshold(scrollOffset, searchBarOpenScollOffset)) {
    setSearchBarIsOpen(false)
  }

  return (
    <div
      ref={searchBarWrapperRef}
      style={{
        '--search-input-left-offset': searchInputLeftOffset,
      }}
      className={classNames('header-search-bar__wrapper', {
        'header-search-bar__wrapper--open': searchBarIsOpen,
      })}
    >
      <div
        ref={searchBarRef}
        onClick={openSearchBarIfNotOpen}
        className={classNames('header-search-bar', {
          'header-search-bar--open': searchBarIsOpen,
        })}
      >
        <div className='header-search-bar__icon'>
          {searchBarIsOpen
            ? (
              <IconButton
                aria-label='Close Search bar'
                onClick={closeSearchBar}
                color='inherit'
              >
                <Clear />
              </IconButton>
            )
            : <SearchIcon />}
        </div>
        <form className='header-search-bar__input-form' onSubmit={onSubmit}>
          <InputBase
            tabIndex='0'
            onFocus={openSearchBarIfNotOpen}
            inputRef={searchBarInputRef}
            onChange={onSearchValueChange}
            placeholder={t('components.search.input_placeholder')}
            classes={{
              root: 'header-search-bar__input-root',
              input: classNames('header-search-bar__input', {
                'header-search-bar__input--open': searchBarIsOpen,
              }),
            }}
            type='search'
            inputProps={{ 'aria-label': 'search' }}
            value={searchValue}
          />
          {searchBarIsOpen
            ? (
              <div className='header-search-bar__input__action-buttons'>
                {searchValue?.length > 0 && (
                  <IconButton
                    className='header-search-bar__input__action-buttons--close-button'
                    onClick={clearSearchInput}
                    aria-label='Clean Search input'
                    color='inherit'
                  >
                    <Clear />
                  </IconButton>
                )}
                <IconButton
                  className='header-search-bar__input__action-buttons--submit-button'
                  type='submit'
                  onClick={onSubmit}
                  aria-label='Search keyword'
                  color='inherit'
                >
                  <ArrowRight />
                </IconButton>
              </div>
            )
            : null}
        </form>
        <div className={classNames('header-search-bar__input-line', {
          'header-search-bar__input-line--open': searchBarIsOpen,
        })}
        >
          &nbsp;
        </div>
      </div>
      <div
        onFocus={openSearchBarIfNotOpen}
        className={classNames('header-search-bar__content', {
          'header-search-bar__content--open': searchBarIsOpen,
        })}
        aria-hidden={!searchBarIsOpen}
      >
        {debouncedSearchTerm?.length
          ? (
            <SearchResults
              results={results}
              isSearching={isSearching}
              availableSearchGroups={getAvailableSearchGroups()}
              selectedSearchGroup={selectedSearchGroup}
              setSelectedSearchGroup={setSelectedSearchGroup}
              closeSearchBar={closeSearchBar}
            />
          )
          : <LastSearches />}
        <div className='header-search-bar__recommended-categories'>
          <h3>{t('components.search.recomended_categories')}</h3>
          <div className='header-search-bar__blocks-list-wrapper'>
            <BlocksList
              itemWidth={150}
              list={getRecommendedCategories()}
              aria-hidden={!searchBarIsOpen}
              closeSearchBar={closeSearchBar}
            />
          </div>
        </div>
      </div>
      <div
        onClick={closeSearchBar}
        className={classNames({
          'header-search-bar__overlay': searchBarIsOpen,
        })}
      />
    </div>
  )
}
