import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import { initializeAuthenticationService } from './services/authenticationService'
import { initializeSearchService } from './services/searchService'
import { initializeRemoteServerService } from './services/serverBridge'
import { initializeErrorLoggingService } from './services/errorLoggingService'
import { initiateBotPreventionService } from 'services/botPrevention'

import './index.scss'

// TODO: initialize using singletons
initializeRemoteServerService()
initializeAuthenticationService()
initializeSearchService()
initializeErrorLoggingService()
initiateBotPreventionService()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
