import { useParams } from 'react-router-dom'

import { useUrlQuery } from 'hooks/useUrlQuery'

import { subRoutesNames } from 'services/routingService'

const BACK_OFFICE_PAGES_URLS = subRoutesNames.BACK_OFFICE

export const useMenuItemActive = (menuItems, activeId) => {
  const { subPage } = useParams()
  const srcContextQuery = useUrlQuery('srcContext')
  const srcContextIsEvent = srcContextQuery === 'event-page'

  // custom logic to set menu item active for Events item when a gallery is open from the Events page context
  if (activeId === BACK_OFFICE_PAGES_URLS.GALLERIES && subPage && srcContextIsEvent) {
    return BACK_OFFICE_PAGES_URLS.EVENTS
  }

  const menuItemWithActiveIdAsSubRoute = menuItems.find(menuItem => {
    const menuItemSubRoutes = menuItem?.subRoutes || []
    return menuItemSubRoutes.includes(activeId)
  })

  if (menuItemWithActiveIdAsSubRoute) {
    return menuItemWithActiveIdAsSubRoute.id
  }

  return activeId
}
