import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createAccessLink, subRoutesNames } from 'services/routingService'

export const SignInRequirement = props => {
  const hasUserLogedIn = useSelector(state => state.users.currentUser)
  const history = useHistory()

  if (props.requireSignIn && !hasUserLogedIn) {
    history.push(createAccessLink(subRoutesNames.ACCESS.SIGN_IN))
    return null
  }

  return props.children
}
