import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState, useRef, useCallback } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'

import { Button } from 'components/Button'
import { Categories } from 'components/Categories'
import { COMPONENTS_IDS } from 'components/ComponentGetter'
import { Image } from 'components/Image'
import { LoadingWrapper } from 'components/LoadingComponent'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { TextWithShowMore } from 'components/TextWithShowMore'
import UserCircular from 'components/UserCircular'

import { Carousel } from './components/Carousel'
import { DynamicComponentWrapper } from './components/DynamicComponentWrapper'
import { SimpleMessage } from './components/SimpleMessage'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useModal } from 'hooks/useModal'
import { useStopScroll } from 'hooks/useStopScroll'

import { Pack } from 'model/Pack'

import {
  fetchPack as fetchPackAction,
  fetchPackReviews as fetchPackReviewsAction,
  postPackReview as postPackReviewAction,
  deletePackReview as deletePackReviewAction,
} from 'store/packs/actions'

import { PackNotFoundError, PackNotLoadedError } from 'services/errorHandling'
import { t } from 'services/i18n'
import { EVENT_KEYS, logEvent } from 'services/loggingService'
import { createUserLink } from 'services/routingService'

import { PackDetailsSkeleton } from './index.skeleton'

import './styles.scss'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const getPackSelector = packId => state => {
  if (state.packs.data) {
    const pack = state.packs.data[packId]
    return pack && new Pack(pack)
  }
}

// const TABS = ['INFORMATION', 'REVIEWS', 'MEDIA']
const TABS = ['INFORMATION']

const MAX_IMAGES = 5

const getTabContent = (tab, packData, _logedInUser, postReview, deleteReview, loadingReviews) => {
  const components = [
    {
      id: COMPONENTS_IDS.InformationSection,
      data: {
        information: packData.information,
        price: packData.price,
        quickInfo: packData.quickInfo,
      },
    },
  ]

  switch (tab) {
    case 'INFORMATION':
      if (packData.faqs && packData.faqs.length > 0) {
        components.push({
          id: COMPONENTS_IDS.FaqSection,
          title: 'FAQS',
          data: {
            faqs: packData.faqs,
          },
        })
      }

      components.push({
        id: COMPONENTS_IDS.ReviewSection,
        title: 'Comentários',
        data: {
          reviews: packData.reviews,
          postReview,
          deleteReview,
          loadingReviews,
        },
      })

      return {
        tab: {
          label: 'Informação',
          title: 'Informação',
        },
        components,
      }
    case 'REVIEWS':
      if (packData.reviews && Object.keys(packData.reviews)?.length <= 0) {
        return {}
      }

      return {
        tab: {
          label: 'Reviews',
          title: 'Reviews',
        },
        components: [
          {
            id: COMPONENTS_IDS.ReviewSection,
            data: {
              reviews: packData.reviews,
              postReview,
              deleteReview,
              loadingReviews,
            },
          },
        ],
      }
    default:
      return {}
  }
}

const PackDetails = props => {
  const { id: packId } = useParams()
  const pack = useSelector(getPackSelector(packId)) // Pack instance
  const loading = useSelector(state => state.packs.loading)
  const currentUser = useSelector(state => state.users.users[state.users.currentUser?.uid])
  const currentUserIsOwner = currentUser?.uid === pack?.userUid

  const [tabIndex, setTabIndex] = useState(0)
  const [imagesModalIsOpen, openImagesModal, closeImagesModal, imagesModalContext] = useModal()
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false)

  useStopScroll(imagesModalIsOpen)

  const tabsElmtRef = useRef(null)
  const mainInfoElmtRef = useRef(null)

  const { isMobile } = useBreakpoints()
  const dispatch = useDispatch()

  const postReview = useCallback(
    review => dispatch(postPackReviewAction(packId, review)),
    [dispatch, packId]
  )

  const deleteReview = useCallback(
    reviewId => dispatch(deletePackReviewAction(packId, reviewId)),
    [dispatch, packId]
  )

  const handleChange = (_event, newValue) => {
    // TODO: IF TAB IS REVIEWS, LOAD ALL REVIEWS
    setTabIndex(newValue)
  }
  const openModal = photoId => () => {
    openImagesModal(photoId)
  }

  const closeMessageModal = () => setMessageModalIsOpen(false)
  const openMessageModal = () => {
    logEvent(EVENT_KEYS.CONTACT_PROFESSIONAL)
    setMessageModalIsOpen(true)
  }
  const scrollIntoBottom = () => isMobile
    ? mainInfoElmtRef.current.scrollIntoView()
    : tabsElmtRef.current.scrollIntoView()

  const onSubmitMessage = (name, message) => {
    const packUserEmail = pack.user.email || ''
    window.location.href = `mailto:${packUserEmail}?subject=PLOTU::Nova mensagem de ${name}&body=${message}`
    closeMessageModal()
  }

  if (pack === undefined && loading === false) {
    throw new PackNotFoundError('Pack not found')
  }

  // if there is less than 2 sections or just one section but it's not information section, we should not render tabs
  // const shouldRenderTabs = pack.tabs.length > 1 || (pack.tabs.length === 1 && pack.tabs[0].id !== 'information')
  const shouldRenderTabs = true

  const packImages = pack.images || []

  return (
    <article>
      <div className='pack-details'>
        <Text className='pack-details__title' as='h1' type='title3'>{pack.title}</Text>
        {pack.price && pack.price.value && (
          <Text className='pack-details__price'>
            {pack.price.priceFrom && 'Desde '}
            {`${pack.price.value}€`}
          </Text>
        )}
        <div className='pack-details__main-info'>
          <div className='pack-details__main-info__image-waterfall'>
            {packImages
              .filter((_, index) => index < MAX_IMAGES)
              .map(image => {
                return (
                  <Image
                    wrapperClassName='pack-details__main-info__image-wrapper'
                    className='pack-details__main-info__image'
                    alt='pack image 1'
                    key={image.src}
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    onClick={openModal(image.id)}
                    thumbnailsSpecs={[
                      { size: 's', media: '(max-width: 600px)' },
                      { size: 'm', media: '(min-width: 600px)' },
                    ]}
                    generateThumbnailIfError
                    lazyLoaded
                  />
                )
              })}
            {packImages.length > MAX_IMAGES && (
              <div className='pack-details__main-info__more-images-btn'>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={openModal(packImages[MAX_IMAGES + 1])}
                >
                  {t('pack_details.images.more')}
                </Button>
              </div>
            )}
          </div>
          <div ref={mainInfoElmtRef} className='pack-details__main-info__side-wrapper'>
            <div className='pack-details__main-info__side'>
              <UserCircular big {...pack.user} href={createUserLink(pack.user.uid)} />
              <div className='pack-details__main-info__about'>
                <TextWithShowMore className='pack-details__main-info__description'>
                  {pack.user?.description}
                </TextWithShowMore>
              </div>
              <div className='pack-details__main-info__message-me'>
                <Button
                  disabled={currentUserIsOwner}
                  onClick={openMessageModal}
                  variant='outlined'
                  color='default'
                >
                  <h3>{t('pack_details.user.talk_with_me')}</h3>
                </Button>
              </div>
              <Categories
                className='pack-details__main-info__categories'
                categories={pack.packInfo.categories}
              />
            </div>
            <div onClick={scrollIntoBottom} className='pack-details__main-info__side-bottom'>
              {t('pack_details.pack.more_info')}
              <KeyboardArrowDownOutlined style={{ fontSize: 30 }} />
            </div>
          </div>
          <div onClick={scrollIntoBottom} className='pack-details__main-info__side-bottom pack-details__main-info__side-bottom--mobile'>
            {t('pack_details.pack.more_info')}
            <KeyboardArrowDownOutlined style={{ fontSize: 30 }} />
          </div>
        </div>
        <section ref={tabsElmtRef} className='pack-details__tabs-wrapper'>
          {shouldRenderTabs && (
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label='Pack details section tabs'
              variant='scrollable'
              scrollButtons='auto'
              indicatorColor='secondary'
            >
              {TABS.map((tab, index) => {
                const tabData = getTabContent(
                  tab,
                  pack,
                  currentUser,
                  postReview,
                  deleteReview,
                  props.loadingReviews
                )
                return tabData.tab && (
                  <Tab
                    label={<Text color='text__primary-color'>{tabData.tab?.label}</Text>}
                    {...a11yProps(index)}
                  />
                )
              })}
            </Tabs>
          )}
          <SwipeableViews
            index={tabIndex}
            onChangeIndex={setTabIndex}
          >
            {TABS.map((tab, index) => {
              const tabData = getTabContent(tab, pack, currentUser, postReview, deleteReview, props.loadingReviews)

              return tabIndex === index && (
                <Box p={isMobile ? 2 : 4}>
                  {tabData.tab.title && <Text as='h2' type='title3'>{tabData.tab.title}</Text>}
                  {tabData.components.map(component => {
                    return (
                      <div key={component.id} className='pack-details__tabs__content'>
                        <DynamicComponentWrapper
                          componentTitle={component.title}
                          componentId={component.id}
                          componentData={component.data}
                          hasMore={component.hasMore}
                          onHasMoreBtnClick={() => {
                          // setTabIndex(pack.tabs.findIndex(tab => tab.id === component.hasMore.tabId))
                            scrollIntoBottom()
                          }}
                        />
                      </div>
                    )
                  })}
                </Box>
              )
            })}
          </SwipeableViews>
          <Carousel
            imgs={packImages}
            imgsLength={packImages.length}
            modalIsOpen={imagesModalIsOpen}
            closeModal={closeImagesModal}
            initialPhotoId={imagesModalContext}
          />
          <Modal
            open={messageModalIsOpen}
            closeModal={closeMessageModal}
            title='Nova mensagem'
          >
            <SimpleMessage
              currentUser={currentUser}
              onSubmit={onSubmitMessage}
              onClose={closeMessageModal}
            />
          </Modal>
        </section>
      </div>
    </article>
  )
}

const PackDetailsLoader = () => {
  const { id: packId } = useParams()
  const pack = useSelector(getPackSelector(packId))
  const dispatch = useDispatch()
  const [loadingReviews, setLoadingReviews] = useState()

  const loading = useSelector(state => state.packs.loading)

  const fetchPack = useCallback(
    packId => dispatch(fetchPackAction(packId)),
    [dispatch]
  )

  const fetchPackReviews = useCallback(
    packId => dispatch(fetchPackReviewsAction(packId)),
    [dispatch]
  )

  useEffect(() => {
    if (!pack && !loading) {
      fetchPack(packId)
        .catch(() => { throw new PackNotLoadedError('Error while trying to load Pack') })
    } else if (pack && !pack.reviews && loadingReviews === undefined) { // if pack is not fully loaded, then fetch the most recent reviews
      setLoadingReviews(true)
      fetchPackReviews(packId)
        .then(() => setLoadingReviews(false))
        .catch(() => { throw new PackNotLoadedError('Error while trying to load Pack Reviews') })
    }
  }, [fetchPack, fetchPackReviews, pack, packId, loadingReviews, loading])

  return (
    <LoadingWrapper
      skeleton={<PackDetailsSkeleton />}
      isLoading={!pack || loading}
    >
      <PackDetails loadingReviews={loadingReviews} />
    </LoadingWrapper>
  )
}

export default PackDetailsLoader
