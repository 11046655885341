import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'

export const LoadingComponent = props => {
  const { isLoading, size, value, className, linear, ...otherProps } = props

  if (!isLoading) {
    return null
  }

  if (linear) {
    return (
      <div className={classNames('linear-loading-component', className)}>
        <LinearProgress {...otherProps} />
      </div>
    )
  }

  if (value) {
    return (
      <div className={classNames('loading-component', 'loading-component__wrapper', className)}>
        <CircularProgress
          style={
            size
              ? { width: `${size}px`, height: `${size}px` }
              : {}
          }
          {...otherProps}
        />
        <div className='loading-component__value-label'>
          {`${Math.round(value)}%`}
        </div>
      </div>
    )
  }

  return (
    <CircularProgress
      style={
        size
          ? { width: `${size}px`, height: `${size}px` }
          : {}
      }
      className={classNames('loading-component', className)}
      {...otherProps}
    />
  )
}

export const LoadingComponentInfo = props => {
  const { label, wrapperClassName, ...otherProps } = props

  if (otherProps.linear) {
    return (
      <div className={classNames('loading-component-info--linear', wrapperClassName)}>
        <div className='loading-component-info__label loading-component-info__label--linear'>{label}</div>
        <LoadingComponent {...otherProps} />
      </div>
    )
  }

  return (
    <div className={classNames('loading-component-info', wrapperClassName)}>
      <div className='loading-component-info__label'>{label}</div>
      <LoadingComponent {...otherProps} />
    </div>
  )
}

export const LoadingWrapper = props => {
  const { isLoading: propsIsLoading, ...otherProps } = props
  const [isLoading, setIsLoading] = useState(propsIsLoading)
  const delayRef = useRef()

  useEffect(() => {
    // always reset the timeout whenever the isLoading props is changed
    if (delayRef.current) {
      clearTimeout(delayRef.current)
    }

    // if delay prop is set, set up the timeout
    if (propsIsLoading && props.delay) {
      delayRef.current = setTimeout(() => setIsLoading(true), props.delay)
      return
    }

    setIsLoading(propsIsLoading)

  }, [propsIsLoading, props.delay])

  // if is loading or props.isLoading becomes true when isLoading is false (happens when changing from loading to not loading), we want to show loading state
  if (isLoading || (propsIsLoading && !isLoading)) {
    if (props.skeleton) {
      return props.skeleton
    }

    return <LoadingComponent isLoading {...otherProps} />
  }

  return props.children || null
}

export const BackdropLoading = props => {
  return (
    <Backdrop className='backdrop-loading' open={props.isLoading}>
      <CircularProgress size={props.size || 60} color={props.color || 'primary'} />
    </Backdrop>
  )
}
