import React from 'react'
import { useSelector } from 'react-redux'

import { TitleImageSideBySide } from 'components/TitleImageSideBySide'

import { BeamStudio } from './components/BeamStudio'
import { JoinUs } from './components/JoinUs'
import { StartEase } from './components/StartEase'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { createAccessLink, subRoutesNames } from 'services/routingService'

import './styles.scss'

const Professionals = () => {
  const isLogedIn = useSelector(state => state.users.currentUser)

  return (
    <div>
      <div className='professionals__section professionals__section--primary'>
        <TitleImageSideBySide
          title={t('professionals.hero.title')}
          subTitle={t('professionals.hero.sub_title')}
          image={getConfigValue('professionals.hero.image')}
          actionButton={{
            href: createAccessLink(subRoutesNames.ACCESS.SIGN_UP),
            text: t('professionals.hero.action_text'),
            variant: 'outlined',
            color: 'default',
          }}
        />
      </div>
      <div className='professionals__section'>
        <BeamStudio />
      </div>
      <div className='professionals__section--side-margin professionals__section--secondary'>
        <StartEase />
      </div>
      {isLogedIn ? null : <JoinUs />}
    </div>
  )
}

export default Professionals
