import classNames from 'classnames'
import React from 'react'

import { Text } from 'components/Text'

import { PLAN_PERIOD } from 'model/Pricing'

import { t } from 'services/i18n'

import './styles.scss'

export const PriceLabel = ({
  periodPriceWithoutDiscount,
  periodPrice,
  planPeriod,
  className,
}) => {
  const periodLabel =
    planPeriod === PLAN_PERIOD.MONTHLY
      ? t('components.price_label.price_monthly_label')
      : t('components.price_label.price_anual_label')

  return (
    <div className={classNames('price-label', className)}>
      {periodPriceWithoutDiscount && (
        <strike className='price-label__price price-label__price--without-discount'>
          <Text as='h4' type='subtitle2'>{t('components.price_label.price_label', { price: periodPriceWithoutDiscount })}</Text>
        </strike>
      )}
      {periodPrice !== undefined && (
        <div className='price-label__price'>
          <Text as='h2' type='title3'>{t('components.price_label.price_label', { price: periodPrice })}</Text>
          <Text as='h6' type='body2'>{periodLabel}</Text>
        </div>
      )}
    </div>
  )
}
