import { collection, deleteDoc, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, runTransaction, setDoc, where } from '@firebase/firestore'
import { getDatabase, deleteImages, postPackImages, getUser, getUsers, getEditUserPacksTransactionData } from './'

import { logError } from 'utils/errorCapture'

const getPacksRef = () => collection(getDatabase(), 'packs')
const getPackRef = packId => doc(getDatabase(), 'packs', packId)
const getUserRef = userUid => doc(getDatabase(), 'users', userUid)
const getReviewsRef = packId => collection(getDatabase(), 'packs', packId, 'reviews')
const getReviewRef = (packId, reviewId) => doc(getDatabase(), 'packs', packId, 'reviews', reviewId)

const populatePackWithReviews = (reviews, users, pack) => {
  const populatedReviews = Object.keys(reviews)
    .reduce((aggregatedReviews, reviewKey) => {
      const review = reviews[reviewKey]
      const reviewUser = users.find(user => user?.uid === review.userUid) || {}
      aggregatedReviews[reviewKey] = {
        ...review,
        user: reviewUser,
      }
      return aggregatedReviews
    }, {})

  return {
    ...pack,
    reviews: populatedReviews,
  }
}

const populatePackWithUser = (pack, packUser) => {
  return {
    ...pack,
    user: {
      uid: packUser.uid,
      name: packUser.name,
      email: packUser.email,
      avatar: packUser.avatar,
      rating: packUser.rating,
      description: packUser.description,
    },
  }
}

export const getPack = packId => {
  const docRef = getPackRef(packId)

  console.info('Fetching pack:', packId)
  return getDoc(docRef)
    .then(pack => {
      console.info('Fetched pack:', pack)
      if (pack.exists()) {
        return pack.data()
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
        return null
      }
    })
    .then(pack => {
      let reviewsData = {}
      const reviewsOptions = {
        limit: 10,
        orderBy: 'createdTimestamp',
        orderByDir: 'asc',
      }
      return getReviews(pack.id, reviewsOptions)
        .then(reviews => {
          reviewsData = reviews
          // if array is empty, firebase will return an error
          if (Object.keys(reviews)?.length) {
            const reviewsUsers = Object.keys(reviews)
              .map(reviewKey => reviews[reviewKey].userUid)
            return getUsers(reviewsUsers)
          }
          return Promise.resolve([])
        })
        .then(users => populatePackWithReviews(reviewsData, users, pack))
    })
    .then(
      populatedPack =>
        getUser(populatedPack.userUid)
          .then(user => populatePackWithUser(populatedPack, user))
    )
    .catch(error => {
      logError(error, `Error fetching pack ${packId}: ${error}`)
      throw error
    })
}

export const getLastPacks = (size = 5, category) => {
  const constraints = []
  if (category) {
    constraints.push(where('packInfo.categories', 'array-contains', category))
  }

  const q = query(
    getPacksRef(),
    ...constraints,
    limit(size),
    orderBy('createdTimestamp', 'desc')
  )

  console.info(`Fetching last ${size} packs`)
  return getDocs(q)
    .then(eventsSnapshot => {
      const events = eventsSnapshot.docs
        ?.reduce((prev, currDoc) => {
          const event = currDoc.data()
          prev[event.id] = event
          return prev
        }, {}) || {}

      return events
    })
    .then(packs => {
      const userPacksPromises = Object.keys(packs).map(packKey => getUser(packs[packKey].userUid))
      return Promise.all(userPacksPromises)
        .then(packUsers => {
          return Object.keys(packs).map((packKey, index) => {
            const pack = packs[packKey]
            return {
              id: pack.id,
              user: packUsers[index],
              pack,
            }
          })
        })
    })
    .catch(error => {
      logError(error, `Error fetching last ${size} packs: ${error}`)
    })
}

export const getPacks = (packsId, user) => {
  console.info('Fetching packs:', packsId)

  if (!packsId || packsId.length === 0) {
    return new Promise(resolve => resolve([]))
  }

  const constraints = []
  // prevent crashing retrieving more than 10 packs
  if (packsId && packsId.length <= 10) {
    constraints.push(where('id', 'in', packsId))
  }

  const q = query(
    getPacksRef(),
    ...constraints
  )

  return getDocs(q)
    .then(querySnapshot => {
      const packs = []
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        packs.push(populatePackWithUser(doc.data(), user))
        // do not populate reviews here if not necessary. fetch then later as needed
      })
      return packs
    })
    .catch(error => {
      logError(error, `Error fetching packs ${packsId}: ${error}`)
      throw error
    })
}

export const postPack = (pack, newVisibility) => {
  const docRef = getPackRef(pack.id)

  const userUid = pack.userUid || pack.user?.uid || null

  // let's remove packInfo.media for now, until the images are uploaded to the storage, if there are any new images
  const packInfoCopy = { ...pack.packInfo }
  delete packInfoCopy.media
  const packData = {
    id: pack.id,
    userUid,
    createdTimestamp: pack.createdTimestamp,
    lastModifiedTimestamp: pack.lastModifiedTimestamp,
    packInfo: packInfoCopy,
    information: pack.information,
    quickInfo: pack.quickInfo,
    faqs: pack.faqs,
  }

  const packNewImages = pack.packInfo.media?.images
    .filter(image => image.file) // only upload edited images

  const packNewImageFiles = packNewImages
    .map(image => {
      // Note: do not try to destructure the image.file. It won't work
      image.file.imageId = image.id // this ID is needed in imagesStorage. This approach could be changed (and pass the id right to the postPackImage function) after the TODO below is addressed
      return image.file
    }) || []

  return runTransaction(getDatabase(), async transaction => {
    const { userRef } = getEditUserPacksTransactionData(userUid)
    const userDoc = await transaction.get(userRef)

    if (!userDoc.exists()) {
      logError(Error(`User ${userDoc} does not exist! Transaction to update pack aborted`))
    }

    // add new or edited pack to packs collection
    // Note: we need to use set since the document might not exist yet
    transaction.set(docRef, { ...packData }, { merge: true })

    /**
     * Update user packs:
     *  - If a new pack is being added, we need to add it to user packs or hidden packs
     *  - If a pack visibility is being changed, we need to move it to the list user pack list
     */
    const userData = userDoc.data()
    const userPacks = userData.packs?.cards || []
    const userHiddenPacks = userData.packs?.hiddenCards || []

    const packIndexInUserPacks = userPacks.findIndex(packCard => packCard.id === pack.id)
    const packIndexInUserHiddenPacks = userHiddenPacks.findIndex(packCard => packCard.id === pack.id)

    let userUpdateNeeded = false

    if (newVisibility === true) {
      // if pack is now visible and it's not present in user packs yet (it might be a new pack), let's add it
      if (packIndexInUserPacks === -1) {
        userPacks.push({ id: pack.id })
        userUpdateNeeded = true
      }
      // if pack is now visible and it's present in user hidden packs, let's remove it
      if (packIndexInUserHiddenPacks > -1) {
        userHiddenPacks.splice(packIndexInUserHiddenPacks, 1)
        userUpdateNeeded = true
      }
    } else {
      // if pack is now hidden and it's present in user packs, let's remove it
      if (packIndexInUserPacks > -1) {
        userPacks.splice(packIndexInUserPacks, 1)
        userUpdateNeeded = true
      }
      // if pack is now visible and it's present in user hidden packs, let's remove it
      // if pack is now hidden and it's not present in user hidden packs yet (it might be a new pack), let's add it
      if (packIndexInUserHiddenPacks === -1) {
        userHiddenPacks.push({ id: pack.id })
        userUpdateNeeded = true
      }
    }

    if (userUpdateNeeded) {
      // Note: let's keep this `getEditUserPacksTransactionData` on users side so that we can keep track of dependencies over "entities"
      const { userDataWithUpdatedPacks } =
        getEditUserPacksTransactionData(userUid, { userPacks, userHiddenPacks })
      transaction.update(userRef, { ...userDataWithUpdatedPacks })
    }
  })
    .then(() => {
      console.log(`Pack ${packData.id} successfully created in pack and user docs without new images`)

      // TODO: Optimize this
      // This should upload images one by one, just like portfolio is doing, so that it can upload them quicker
      if (packNewImageFiles.length > 0) {
        return postPackImages(packNewImageFiles, packData.userUid, packData.id)
          .then(uploadedImageSrcs => {
            return runTransaction(getDatabase(), async transaction => {
              const packDoc = await transaction.get(docRef)

              if (!packDoc.exists()) {
                logError(Error(`Pack ${packDoc} does not exist! Transaction to add new pack images aborted`))
              }

              const newImages = packNewImages.map((image, index) => ({
                id: image.id,
                src: uploadedImageSrcs[index],
                width: image.width,
                height: image.height,
                thumbnails: image.thumbnails,
              }))

              const packDataWithImages = {
                id: pack.id,
                // by updating each variable, we can update with "merge" the inner gallery object
                'packInfo.media.images': newImages,
              }

              // save pack with the uploaded images
              transaction.update(docRef, { ...packDataWithImages })

              return {
                packNewImages,
              }
            })
          })
          .then(returnData => {
            console.log(`Pack ${packData.id} new images were successfully written!`)
            return returnData
          })
          .catch(error => {
            logError(error, `Error uploading pack ${packData.id} images`)
            throw error
          })
      }

      return {
        packNewImages,
      }
    })
    .catch(error => {
      logError(error, 'Error creating pack: ' + error)
      throw error
    })
}

export const putPack = (pack, newVisibility) => {
  const docRef = getPackRef(pack.id)

  const userUid = pack.userUid || pack.user?.uid || null

  const userDocRef = getUserRef(userUid)

  // let's remove packInfo.media for now, until the images are uploaded to the storage, if there are any new images
  // Note: packInfo.media.images will be populated with remote images before updating the pack
  const packInfoCopy = { ...pack.packInfo }
  delete packInfoCopy.media
  const packData = {
    id: pack.id,
    userUid,
    createdTimestamp: pack.createdTimestamp,
    lastModifiedTimestamp: pack.lastModifiedTimestamp,
    packInfo: packInfoCopy,
    information: pack.information,
    quickInfo: pack.quickInfo,
    faqs: pack.faqs,
  }

  const packNewImages = pack.packInfo.media?.images
    .filter(image => image.file) // only upload edited images

  const packNewImageFiles = packNewImages
    .map(image => {
      // Note: do not try to destructure the image.file. It won't work
      image.file.imageId = image.id // this ID is needed in imagesStorage. This approach could be changed (and pass the id right to the postPackImage function) after the TODO below is addressed
      return image.file
    }) || []

  return runTransaction(getDatabase(), async transaction => {
    const packDoc = await transaction.get(docRef)
    const userDoc = await transaction.get(userDocRef)

    if (!packDoc.exists()) {
      logError(Error(`Pack ${packDoc} does not exist! Transaction to update pack aborted`))
    }

    if (!userDoc.exists()) {
      logError(Error(`User ${userDoc} does not exist! Transaction to update pack aborted`))
    }

    const remotePackData = packDoc.data()

    // let's keep the remote images so that no remote images are lost
    // we need to do this before handling the removed photos
    if (remotePackData.packInfo.media) {
      packData.packInfo.media = remotePackData.packInfo.media
    }

    let oldPhotos = []
    // if pack already exists in database, let's check for any deleted photo
    if (remotePackData) {
      // check for images that are in remote pack that are not in the updated pack, so we can remove them
      oldPhotos = remotePackData.packInfo.media?.images
        .filter(oldPackImage => !pack.packInfo.media?.images
          .some(newPackImage => newPackImage.id === oldPackImage.id)) || []

      // we need to remove the deleted photos from the database here
      // so that they're removed in the transaction
      // even if there is an error deleting the photos from the storage database, the user doesn't see them anymore
      // Note: this behaviour could bring storage size errors
      // Note2: If new images are also added, they'll should be added to the database only after added to the storage (done after the transaction is done)
      if (oldPhotos.length > 0) {
        // remote media images should only keep remote images that are not present in oldPhotos array
        packData.packInfo.media = (remotePackData.packInfo.media?.images || [])
          .filter(packImage => oldPhotos.findIndex(oldPhoto => oldPhoto.id === packImage.id) === -1)
      }
    }

    // add new or edited pack to packs collection
    transaction.update(docRef, { ...packData })

    /**
     * Update user packs:
     *  - If a new pack is being added, we need to add it to user packs or hidden packs
     *  - If a pack visibility is being changed, we need to move it to the list user pack list
     */
    const userData = userDoc.data()
    const userPacks = userData.packs?.cards || []
    const userHiddenPacks = userData.packs?.hiddenCards || []

    const packIndexInUserPacks = userPacks.findIndex(packCard => packCard.id === pack.id)
    const packIndexInUserHiddenPacks = userHiddenPacks.findIndex(packCard => packCard.id === pack.id)

    let userUpdateNeeded = false

    if (newVisibility === true) {
      // if pack is now visible and it's not present in user packs yet (it might be a new pack), let's add it
      if (packIndexInUserPacks === -1) {
        userPacks.push({ id: pack.id })
        userUpdateNeeded = true
      }
      // if pack is now visible and it's present in user hidden packs, let's remove it
      if (packIndexInUserHiddenPacks > -1) {
        userHiddenPacks.splice(packIndexInUserHiddenPacks, 1)
        userUpdateNeeded = true
      }
    } else {
      // if pack is now hidden and it's present in user packs, let's remove it
      if (packIndexInUserPacks > -1) {
        userPacks.splice(packIndexInUserPacks, 1)
        userUpdateNeeded = true
      }
      // if pack is now visible and it's present in user hidden packs, let's remove it
      // if pack is now hidden and it's not present in user hidden packs yet (it might be a new pack), let's add it
      if (packIndexInUserHiddenPacks === -1) {
        userHiddenPacks.push({ id: pack.id })
        userUpdateNeeded = true
      }
    }

    if (userUpdateNeeded) {
      const userDataWithUpdatedPacks = {
        uid: userUid,
        'packs.cards': userPacks,
        'packs.hiddenCards': userHiddenPacks,
      }
      transaction.update(userDocRef, { ...userDataWithUpdatedPacks })
    }

    return oldPhotos
  })
    .then(oldPhotos => {
      console.log(`Pack ${packData.id} successfully updated in pack and user docs without new images`)

      // remove deleted photos from storage database
      if (oldPhotos.length > 0) {
        const oldPhotoSrcs = oldPhotos.map(oldPhoto => oldPhoto.src)
        deleteImages(oldPhotoSrcs)
          .then(() => console.log(`Deleted images from pack ${packData.id} were successfully removed from storage`))
          .catch(error => {
            logError(error, `Error deleting from storage the images removed from pack ${packData.id}: ${error}`)
          })
      }

      // TODO: Optimize this
      // This should upload images one by one, just like portfolio is doing, so that it can upload them quicker
      if (packNewImageFiles.length > 0) {
        return postPackImages(packNewImageFiles, packData.userUid, packData.id)
          .then(uploadedImageSrcs => {
            return runTransaction(getDatabase(), async transaction => {
              const packDoc = await transaction.get(docRef)

              if (!packDoc.exists()) {
                logError(Error(`Pack ${packDoc} does not exist! Transaction to add new pack images aborted`))
              }

              const remotePackData = packDoc.data()

              const remoteImagesWithNewImages = [
                ...(remotePackData.packInfo.media?.images || []),
                ...packNewImages.map((image, index) => ({
                  id: image.id,
                  src: uploadedImageSrcs[index],
                  width: image.width,
                  height: image.height,
                  thumbnails: image.thumbnails,
                })),
              ]

              const packDataWithImages = {
                id: pack.id,
                // by updating each variable, we can update with "merge" the inner gallery object
                'packInfo.media.images': remoteImagesWithNewImages,
              }

              // save pack with the uploaded images
              transaction.update(docRef, { ...packDataWithImages })

              return {
                packNewImages,
              }
            })
          })
          .then(returnData => {
            console.log(`Pack ${packData.id} new images were successfully written!`)
            return returnData
          })
          .catch(error => {
            logError(error, `Error uploading pack ${packData.id} images`)
            throw error
          })
      }

      return {
        packNewImages,
      }
    })
    .catch(error => {
      logError(error, 'Error creating pack: ' + error)
      throw error
    })
}

export const createPackChangeListener = (packId, callback) => {
  const unsubscribeListener = onSnapshot(
    getPackRef(packId),
    doc => callback(doc.data(), doc)
  )

  return unsubscribeListener
}

export const deletePack = pack => {
  const docRef = getPackRef(pack.id)

  const promises = [deleteDoc(docRef)]

  if (pack.packInfo.media?.images.length > 0) {
    const toRemoveImages = pack.packInfo.media?.images.map(img => img.src) || []
    promises.push(deleteImages(toRemoveImages))
  }

  return Promise.all(promises)
    .then(() => {
      console.log(`Successfully deleted pack ${pack.id}!`)
    })
    .catch(error => {
      logError(error, 'Error deleting pack: ' + error)
      throw error
    })
}

export const getReviews = (packId, options) => {
  const constraints = []
  if (options?.limit) {
    constraints.push(limit(options.limit))
  }

  if (options?.orderBy) {
    constraints.push(orderBy(options.orderBy, options.orderByDir))
  }

  const q = query(
    getReviewsRef(packId),
    ...constraints
  )

  return getDocs(q)
    .then(querySnapshot => {
      console.info(`Fetched reviews from pack ${packId} successfully`)
      const reviews = {}
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        const review = doc.data()
        console.log(doc.id, ' => ', doc.data())
        reviews[review.id] = review
      })
      return reviews
    })
    .catch(error => {
      logError(error, `Error fetching reviews from pack ${packId}: ${error}`)
      throw error
    })
}

export const postReview = (packId, review) => {
  const docRef = getReviewRef(packId, review.id)

  const reviewData = {
    id: review.id,
    userUid: review.userUid,
    createdTimestamp: review.createdTimestamp,
    editedTimestamp: review.editedTimestamp,
    isEdited: review.isEdited,
    review: review.review,
    rating: review.rating,
    reviewReply: review.reviewReply && {
      review: review.reviewReply.review,
      createdTimestamp: review.reviewReply.createdTimestamp,
      isEdited: review.reviewReply.isEdited,
      editedTimestamp: review.reviewReply.editedTimestamp,
    },
  }

  return setDoc(docRef, { ...reviewData })
    .then(() => {
      console.info(`Review ${reviewData.id} successfully written in pack ${packId}`)
    })
    .catch(error => {
      logError(error, `Error saving review with id ${reviewData.id} in pack id ${packId}: ${error}`)
      throw error
    })
}

export const deleteReview = (packId, reviewId) => {
  const docRef = getReviewRef(packId, reviewId)

  return deleteDoc(docRef)
    .then(() => {
      console.info(`Review ${reviewId} successfully written in pack ${packId}`)
    })
    .catch(error => {
      logError(error, `Error saving review with id ${reviewId} in pack id ${packId}: ${error}`)
      throw error
    })
}

export const getPackPhoto = (packId, photoId) => {
  const docRef = getPackRef(packId)

  console.info('Fetching pack photo:', photoId, 'from pack', packId)
  return getDoc(docRef)
    .then(pack => {
      console.info('Fetched pack:', pack)
      if (pack.exists()) {
        return pack.data()
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
        return null
      }
    })
    .then(pack => {
      const photo = pack.packInfo.media.images.find(image => image.id === photoId) ?? null
      return photo
    })
    .catch(error => {
      logError(error, `Error fetching pack ${packId}: ${error}`)
      throw error
    })
}
