import React, { useEffect, useState } from 'react'
import Markdown from 'markdown-to-jsx'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Event from '@mui/icons-material/Event'
import AccessTime from '@mui/icons-material/AccessTime'

import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { List } from 'components/List'
import { Text } from 'components/Text'
import PostSkeleton from '../PostSkeleton'
import { calculateTime, toHref, markdownOptions } from './utils'
import { createBlogLink } from 'services/routingService'

import './styles.scss'

const Post = ({ slug, metadata }) => {
  const [postContent, setPostContent] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    try {
      import(`../../content/${slug}.md`).then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setPostContent(res))
          .catch(err => {
            console.error(err)
            setError(err)
          })
      })
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }, [slug])

  if (error) {
    throw new Error('Failed to load post content.')
  }

  if (!postContent) {
    return <PostSkeleton />
  }

  const timeToRead = `${calculateTime(postContent)} min`
  const headers = postContent.match(/#{1,5} .+/g) || []

  return (
    <div className='post'>
      <div className='post__metadata'>
        <Link
          className='post__metadata__back-link'
          href={createBlogLink()}
        >
          <ArrowBack />
          <Text>Voltar</Text>
        </Link>
        <div className='post__metadata__tags-container'>
          {metadata.tags.map(tag => (
            <Link className='post__metadata__tags-container__link' key={tag} href={createBlogLink({ queryParameters: { tag } })}>
              {tag}
            </Link>
          ))}
        </div>
        <div className='post__metadata__date'>
          <Event />
          {metadata.date}
        </div>
        <div className='post__metadata__time-to-read'>
          <AccessTime />
          {timeToRead}
        </div>
      </div>
      <div className='post__content'>
        <div className='post__content__image-container'>
          <Image className='post__content__image' src={metadata.img} alt={slug} />
        </div>
        <div className='post__metadata-mobile'>
          <div className='post__metadata-mobile__date'>
            <Event />
            <Text type='body2' color='text__grey-color'>{metadata.date}</Text>
          </div>
          <div className='post__metadata-mobile__time-to-read'>
            <AccessTime />
            <Text type='body2' color='text__grey-color'>{timeToRead}</Text>
          </div>
        </div>
        <Text type='title2' as='h1' color='text__primary-color'>{metadata.title}</Text>
        <Markdown
          className='post__content__markdown'
          options={markdownOptions}
        >
          {postContent}
        </Markdown>
      </div>
      <div className='post__index'>
        <Link
          className='post__index__title'
          href='#'
          target='_self'
        >
          {metadata.title}
        </Link>
        <hr className='post__index__div' />
        <List className='post__index__list'>
          {headers.map(header => {
            const match = header.match(/^#+(?=\s)/)
            const level = match ? match[0].length : 0
            return (
              <Link
                className={`post__index__list__link level-${level}`}
                key={header}
                href={toHref(header)}
                target='_self'
              >
                {header.replace(/#/g, '')}
              </Link>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default Post
