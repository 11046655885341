import defaultConfigDevelopment from './config/default.development'
import defaultConfigProduction from './config/default.production'
import dynamicStaticPages from './config/dynamicStaticPages'
import { memoize } from 'underscore'

import { isDevelopmentOrLess } from 'utils/envs'

export const CONFIG_KEYS = {
  DEFAULT: 'DEFAULT',
  DYNAMIC_STATIC_PAGES: 'DYNAMIC_STATIC_PAGES',
}

export const getConfigDocument = memoize(configKey => {
  switch (configKey) {
    case CONFIG_KEYS.DYNAMIC_STATIC_PAGES:
      return dynamicStaticPages
    case CONFIG_KEYS.DEFAULT:
      if (isDevelopmentOrLess()) {
        return defaultConfigDevelopment
      }

      return defaultConfigProduction
    default:
      return defaultConfigProduction
  }
})

export const getConfigValue = (key, configKey = CONFIG_KEYS.DEFAULT) => {
  const config = getConfigDocument(configKey)

  return key
    .split('.')
    .reduce((prev, keyProp) => {
      return prev && prev[keyProp]
    }, config)
}

