import { editAgendaEventGalleryDataLocally, fetchUserEvent, removeGalleryFromAgendaEvent } from 'store/agendas/actions'
import { incrementUserGalleryCountLocally } from 'store/users/actions'
import { addGalleryToWatermark, removeGalleryFromWatermark } from 'store/watermarks/actions'

import { V2_IMAGE_SUFIX, createGalleryPhotoChangeListener } from 'services/firebaseServer'

import PromiseQueue from 'utils/promiseQueue'

import {
  getGallery,
  postGallery,
  putGallery,
  postGalleryPhoto,
  deleteGallery,
  deleteGalleryPhoto,
  deleteGalleryPhotos,
  postGallerySharingOptions,
  postGalleryMarkedPhotoIds,
  getUserGalleries,
  getGalleryPhoto,
  getGalleryPhotos,
  getGalleryMarkedPhotosIds,
  getUser,
  triggerWatermarkJobToPhoto,
} from 'services/serverBridge'

export const REQUEST_GALLERY = 'REQUEST_GALLERY'
export const REQUEST_EXTRA_DATA = 'REQUEST_EXTRA_DATA'
export const ADD_GALLERIES = 'ADD_GALLERIES'
export const ADD_GALLERY = 'ADD_GALLERY'
export const ADD_GALLERY_USER = 'ADD_GALLERY_USER'
export const ADD_GALLERY_EVENT = 'ADD_GALLERY_EVENT'
export const UPDATE_GALLERY = 'UPDATE_GALLERY'
export const REMOVE_GALLERY = 'REMOVE_GALLERY'
export const ADD_GALLERY_PHOTOS = 'ADD_GALLERY_PHOTOS'
export const SET_GALLERY_PAGES_LOADED = 'SET_GALLERY_PAGES_LOADED'
export const ADD_GALLERY_PHOTO = 'ADD_GALLERY_PHOTO'
export const ADD_GALLERY_COVER_PHOTO = 'ADD_GALLERY_COVER_PHOTO'
export const REMOVE_GALLERY_PHOTOS = 'REMOVE_GALLERY_PHOTOS'
export const FAILED_GALLERY = 'FAILED_GALLERY'
export const SET_GALLERY_SHARING_OPTIONS = 'SET_GALLERY_SHARING_OPTIONS'
export const SET_GALLERY_MARKED_PHOTO_IDS = 'SET_GALLERY_MARKED_PHOTO_IDS'

function fetchGalleryAction() {
  return {
    type: REQUEST_GALLERY,
  }
}

function fetchGalleryExtraDataAction(value = true) {
  return {
    type: REQUEST_EXTRA_DATA,
    data: value,
  }
}

function addGalleriesAction(galleries) {
  return {
    type: ADD_GALLERIES,
    data: galleries,
  }
}

function addGalleryAction(gallery) {
  return {
    type: ADD_GALLERY,
    data: gallery,
  }
}

function addGalleryUserAction(galleryId, user) {
  return {
    type: ADD_GALLERY_USER,
    data: {
      galleryId,
      user,
    },
  }
}

function addGalleryEventAction(galleryId, event) {
  return {
    type: ADD_GALLERY_EVENT,
    data: {
      galleryId,
      event,
    },
  }
}

function updateGalleryAction(galleryId, data) {
  return {
    type: UPDATE_GALLERY,
    data: {
      galleryId,
      data,
    },
  }
}

function removeGalleryAction(gallery) {
  return {
    type: REMOVE_GALLERY,
    data: {
      gallery,
    },
  }
}

function failedGalleryAction(error) {
  return {
    type: FAILED_GALLERY,
    data: error,
  }
}

function addGalleryPhotosAction(galleryId, photos, updatePhotosLayout = true) {
  return {
    type: ADD_GALLERY_PHOTOS,
    data: {
      galleryId,
      photos,
      updatePhotosLayout,
    },
  }
}

function setGalleryPagesLoadedAction(galleryId, pagesLoaded) {
  return {
    type: SET_GALLERY_PAGES_LOADED,
    data: {
      galleryId,
      pagesLoaded,
    },
  }
}

function removeGalleryPhotosAction(galleryId, photoIds) {
  return {
    type: REMOVE_GALLERY_PHOTOS,
    data: {
      galleryId,
      photoIds,
    },
  }
}

function addGalleryPhotoAction(galleryId, photo) {
  return {
    type: ADD_GALLERY_PHOTO,
    data: {
      galleryId,
      photo,
    },
  }
}

function addGalleryCoverPhotoAction(galleryId, coverPhoto) {
  return {
    type: ADD_GALLERY_COVER_PHOTO,
    data: {
      galleryId,
      coverPhoto,
    },
  }
}

function setGallerySharingOptionsAction(galleryId, sharingOptions) {
  return {
    type: SET_GALLERY_SHARING_OPTIONS,
    data: {
      galleryId,
      sharingOptions,
    },
  }
}

function setGalleryMarkedPhotoIdsAction(galleryId, markedPhotoIds) {
  return {
    type: SET_GALLERY_MARKED_PHOTO_IDS,
    data: {
      galleryId,
      markedPhotoIds,
    },
  }
}

export const fetchGalleries = (userId, options, fetchAdjacentData) => {
  return dispatch => {
    dispatch(fetchGalleryAction())
    return getUserGalleries(userId, options)
      .then(galleries => {
        dispatch(addGalleriesAction(galleries))
        if (fetchAdjacentData) {
          const promises = Object.values(galleries)
            .map(gallery => {
              const coverPhotoId = gallery.coverPhotoId || gallery.photosLayout?.[0]
              return dispatch(fetchGalleryCoverPhotoId(gallery.id, coverPhotoId))
            })
          return Promise.all(promises)
        }
      })
      .catch(error => dispatch(failedGalleryAction(error)))
  }
}

export const fetchGalleryCoverPhotoId = (galleryId, coverPhotoId) => {
  return dispatch => {
    if (!coverPhotoId) {
      dispatch(addGalleryCoverPhotoAction(galleryId, null))
      return Promise.resolve()
    }

    return getGalleryPhoto(galleryId, coverPhotoId)
      .then(coverPhoto => {
        dispatch(addGalleryCoverPhotoAction(galleryId, coverPhoto))
      })
  }
}

const fetchGalleryEvent = (galleryId, userUid, eventId) => {
  return (dispatch, getState) => {
    const fetchedEvents = getState().agendas.events.data || {}
    // if event is already in store, let's reuse it
    if (fetchedEvents[eventId]) {
      dispatch(addGalleryEventAction(galleryId, fetchedEvents[eventId]))
      return Promise.resolve(fetchedEvents[eventId])
    } else {
      return dispatch(fetchUserEvent(userUid, eventId))
        .then(event => {
          dispatch(addGalleryEventAction(galleryId, event))
        })
    }
  }
}

/**
 * This will fetch the gallery data (without fetching other associated data like photos)
 */
export const fetchGallery = galleryId => {
  return (dispatch, getState) => {
    dispatch(fetchGalleryAction())
    dispatch(fetchGalleryExtraDataAction())
    return getGallery(galleryId)
      .then(gallery => {
        dispatch(addGalleryAction(gallery))
        // after fetching gallery, let's fetch the rest of the data asynchronously
        if (gallery) {
          const coverPhotoId = gallery.coverPhotoId || gallery.photosLayout?.[0]
          const extraDataPromises = [
            dispatch(fetchGalleryMarkedPhotosIds(galleryId)),
            dispatch(fetchGalleryUser(galleryId)),
            dispatch(fetchGalleryCoverPhotoId(galleryId, coverPhotoId)),
          ]

          // if gallery is aggregated with an event, populate event into gallery asynchronously
          // unless the current user is not the gallery owner (might be just a client seeing the gallery)
          if (gallery.eventId) {
            const currentUserUid = getState().users.currentUser?.uid
            if (currentUserUid === gallery.userUid) {
              extraDataPromises.push(
                dispatch(fetchGalleryEvent(galleryId, gallery.userUid, gallery.eventId))
              )
            }
          }

          return Promise.all(extraDataPromises)
            .finally(() => {
              dispatch(fetchGalleryExtraDataAction(false))
            })
        }

        return Promise.resolve()
      })
      .catch(error => dispatch(failedGalleryAction(error)))
  }
}

export const fetchGalleryPhotos = (galleryId, photoIdsToLoad) => {
  return dispatch => {
    return getGalleryPhotos(galleryId, photoIdsToLoad)
      .then(photos => dispatch(addGalleryPhotosAction(galleryId, Object.values(photos), false)))
  }
}

export const setGalleryPagesLoaded = (galleryId, pagesLoaded) => {
  return dispatch => {
    dispatch(setGalleryPagesLoadedAction(galleryId, pagesLoaded))
  }
}

export const fetchGalleryMarkedPhotosIds = galleryId => {
  return dispatch => {
    return getGalleryMarkedPhotosIds(galleryId)
      .then(markedPhotoIds => dispatch(setGalleryMarkedPhotoIdsAction(galleryId, markedPhotoIds)))
  }
}

export const fetchGalleryUser = galleryId => {
  return (dispatch, getState) => {
    const gallery = getState().galleries.data[galleryId]
    const userId = gallery?.userUid

    return getUser(userId)
      .then(user => {
        dispatch(addGalleryUserAction(galleryId, user))
      })
  }
}

export const addGallery = (gallery, user, galleryEvent) => {
  return dispatch => {
    dispatch(addGalleryAction({
      ...gallery,
      event: galleryEvent || gallery.event || null,
    }))

    // post gallery will do the following atomically:
    // - add gallery to database
    // - increment user gallery counter
    // - add gallery to associated event if there is an eventId under gallery
    return postGallery(gallery)
      .then(() => {
        if (galleryEvent) {
          dispatch(editAgendaEventGalleryDataLocally(galleryEvent.id, gallery))
        }

        // add +1 to the user gallery global counter
        dispatch(incrementUserGalleryCountLocally(user))
      })
  }
}

export const editGalleryEvent = (galleryId, eventData, remove) => {
  return dispatch => {
    // update event data to gallery locally
    if (remove) {
      dispatch(updateGalleryAction(galleryId, {
        eventId: undefined,
        event: undefined,
      }))
    } else {
      dispatch(updateGalleryAction(galleryId, {
        eventId: eventData.id,
        event: eventData,
      }))
    }

    const toUpdateGalleryData = {
      eventId: remove ? null : eventData?.id,
    }

    // update event data to gallery remotelly
    return putGallery(galleryId, toUpdateGalleryData, false, eventData)
      .then(() => {
        // update event data to event locally
        if (remove) {
          dispatch(removeGalleryFromAgendaEvent(eventData?.id, galleryId))
        } else {
          dispatch(editAgendaEventGalleryDataLocally(eventData?.id, { id: galleryId }))
        }
      })
  }
}

export const listenForPhotosWatermarkUpdate = (
  galleryId, watermarkPhotosIDs, watermarkId, ignoredWatermarkIdUpdated
) => {
  return dispatch => {
    // listen for gallery photo changes to check when the watermarks are created to update the local storage with the new src's
    // This prevents that the UI renders the original sized images and avoids more flickering changing image src's
    const unsubscribes = [];
    (watermarkPhotosIDs || []).forEach(watermarkPhotoID => {
      const unsubscribe =
        createGalleryPhotoChangeListener(galleryId, watermarkPhotoID, updatedPhoto => {
          const imageWatermark = updatedPhoto?.watermark
          const watermarkIsValid = imageWatermark?.watermarkId === watermarkId
          const watermarkIsReady = imageWatermark?.src // we should not check only for thumbnails since sometimes they're faster than the src is available
          const watermarkThumbs = imageWatermark?.thumbnails || {}
          const watermarkThumbsAreReady = watermarkThumbs.s && watermarkThumbs.m && !watermarkThumbs.loading
          const imageIsV1 = !updatedPhoto.src.includes(V2_IMAGE_SUFIX)
          if (imageWatermark && watermarkIsValid && watermarkIsReady && (watermarkThumbsAreReady || imageIsV1)) {
          // sync new image src and thumbnails to store, only after every thumbnail is uploaded to the database, so that we don't download the full res image
            dispatch(addGalleryPhotoAction(galleryId, {
              ...updatedPhoto,
              watermark: imageWatermark,
              applyingWatermark: false,
              // not sure why, but sometimes this is not complete
              isLoading: false,
              progress: 100,
            }))

            // unsubcribe the listener after watermarks are handled
            unsubscribe()
          } else if (imageWatermark) {
            // eslint-disable-next-line no-unused-vars
            const { thumbnails, src, watermarkId, ...imageWatermarkUpdateProps } = imageWatermark // ignore src and thumbnails until they're completely ready

            // ignore certain watermark ids to avoid updating with old data
            if (ignoredWatermarkIdUpdated === watermarkId) {
              return
            }
            // update progress properties. Do not update src just yet
            dispatch(addGalleryPhotoAction(galleryId, {
              ...updatedPhoto,
              watermark: imageWatermarkUpdateProps,
            }))
          }
        })
      unsubscribes.push(unsubscribe)
    })

    return () => {
      unsubscribes.forEach(unsubcribe => unsubcribe())
    }
  }
}

export const updateGallery = (galleryId, data, updateLocalState, updateWatermarks) => {
  return (dispatch, getState) => {
    const initialState = getState() // must be done before updating local state
    let unsubscribeAllWatermarkListener

    if (updateLocalState) {
      /**
       * Update local gallery state
       */
      dispatch(updateGalleryAction(galleryId, data)) // this should be done before updating watermark data since this can overwrite that data
    }

    /**
   * Update local watermark state with galleries data
   */
    if (updateWatermarks) {
      const currentGalleryData = initialState.galleries.data[galleryId]
      const previousWatermarkId = currentGalleryData.watermark?.id
      const newWatermarkId = data.watermark?.id
      const galleryWatermarkHasChanges = newWatermarkId !== previousWatermarkId
      if (galleryWatermarkHasChanges) {
      // if a watermark existed in the previous gallery data, we must remove it from watermark data
        if (previousWatermarkId) {
          dispatch(removeGalleryFromWatermark(previousWatermarkId, galleryId))
        }
        // if a new watermark exists in the new gallery data, we must add it to the watermark data
        if (newWatermarkId) {
          dispatch(addGalleryToWatermark(newWatermarkId, galleryId))

          Object.values(data.photos || {}).forEach(photo => {
            dispatch(addGalleryPhotoAction(galleryId, {
              id: photo.id,
              applyingWatermark: true,
              // replicate watermark data as set in Firebase server function just to avoid showing momentaneously the last progress value saved (100%)
              watermark: {
                isLoading: true,
                progress: 0,
                initiatedTimestamp: Date.now(),
              },
            }))
          })

          // listen for gallery photo changes to check when the watermarks are created to update the local storage with the new src's
          // This prevents that the UI renders the original sized images and avoids more flickering changing image src's
          unsubscribeAllWatermarkListener =
          dispatch(listenForPhotosWatermarkUpdate(galleryId, data.photosLayout, newWatermarkId, previousWatermarkId))
        }
      }
    }

    return putGallery(galleryId, data, updateWatermarks)
      .catch(() => {
        if (updateLocalState && updateWatermarks && data.watermark?.id) {
          Object.values(data.photos || {}).forEach(photo => {
            dispatch(addGalleryPhotoAction(galleryId, {
              id: photo.id,
              applyingWatermark: false,
            }))
          })
        }
        if (unsubscribeAllWatermarkListener) {
          unsubscribeAllWatermarkListener()
        }
      })
  }
}

/**
 * Function to trigger addWatermarkToGalleryPhotoCall firebase function
 * It should be used as a safe guard, in case the watermark was not created automatically
 */
export const triggerAddWatermarkToGalleryPhotoJob = (galleryId, watermarkId, photo) => {
  return dispatch => {
    dispatch(addGalleryPhotoAction(galleryId, {
      id: photo.id,
      applyingWatermark: true,
    }))

    const uploadingPhotoID = photo.id
    // Listen for gallery updates in firestore to update the watermark data only when its thumbnails are available
    const unsubscribeAll = dispatch(listenForPhotosWatermarkUpdate(galleryId, [uploadingPhotoID], watermarkId))

    return triggerWatermarkJobToPhoto(galleryId, photo.id)
      .catch(() => {
        dispatch(addGalleryPhotoAction(galleryId, {
          id: photo.id,
          applyingWatermark: false,
        }))
        unsubscribeAll()
      })
  }
}

export const setGalleryPhotos = (galleryId, photos, callbackForEachPhoto, watermarkId) => {
  return dispatch => {
    // update local storage with photos to be uploaded (most probably with a blob src)
    const uploadingPhotos = photos.map(photo => ({
      ...photo,
      uploading: true,
    }))
    dispatch(addGalleryPhotosAction(galleryId, uploadingPhotos))

    // listen for gallery photo changes to check when the photos thumbnails or watermarks are created to update the local storage with the new src's
    // This prevents that the UI renders the original sized images and avoids more flickering changing image src's
    const uploadingPhotoIDs = photos.map(photo => photo.id)
    const thumbnailsCreated = {}
    const watermarksCreated = {}

    uploadingPhotoIDs.forEach(uploadingPhotoId => {
      const unsubscribe = createGalleryPhotoChangeListener(galleryId, uploadingPhotoId, updatedPhoto => {
        const imageThumbs = updatedPhoto?.thumbnails || {}
        if (imageThumbs.s && imageThumbs.m && !imageThumbs.loading && thumbnailsCreated[uploadingPhotoId] !== true) {
          // eslint-disable-next-line no-unused-vars
          const { watermark, ...photoWithThumbnails } = updatedPhoto // ignore watermark data from updatedPhoto to avoid saving it before watermarks are ready (for example, when thumbnails are created but watermarks are not)
          // sync new image src and thumbnails to store, only after every thumbnail is uploaded to the database, so that we don't download the full res image
          dispatch(addGalleryPhotoAction(galleryId, {
            ...photoWithThumbnails,
            applyingThumbnails: false,
          }))

          thumbnailsCreated[uploadingPhotoId] = true
        }

        const imageWatermark = updatedPhoto?.watermark
        const watermarkIsValid = watermarkId && imageWatermark?.watermarkId === watermarkId
        const watermarkThumbs = updatedPhoto?.watermark?.thumbnails || {}
        const watermarkThumbsAreReady = watermarkThumbs.s && watermarkThumbs.m && !watermarkThumbs.loading
        if (watermarkIsValid && watermarkThumbsAreReady && watermarksCreated[uploadingPhotoId] !== true) {
          // sync new image src and thumbnails to store, only after every thumbnail is uploaded to the database, so that we don't download the full res image
          dispatch(addGalleryPhotoAction(galleryId, {
            ...updatedPhoto,
            watermark: imageWatermark,
            applyingWatermark: false,
          }))

          watermarksCreated[uploadingPhotoId] = true
        } else if (imageWatermark) {
          // eslint-disable-next-line no-unused-vars
          const { thumbnails, src, ...imageWatermarkUpdateProps } = imageWatermark // ignore src and thumbnails until they're completely ready
          // update progress properties. Do not update src just yet
          dispatch(addGalleryPhotoAction(galleryId, {
            ...updatedPhoto,
            watermark: imageWatermarkUpdateProps,
          }))
        }

        // unsubcribe the listener if both thumbnails and watermarks are created
        if (thumbnailsCreated[uploadingPhotoId] && watermarksCreated[uploadingPhotoId]) {
          unsubscribe()
        }
      })
    })

    return new Promise(resolve => {
      const queue = new PromiseQueue()

      const uploadedPhotos = []

      const uploadPromisesFuncs = photos.map(photo => {
        return () => postGalleryPhoto(galleryId, photo, callbackForEachPhoto, watermarkId)
          .then(() => {
            const uploadedPhoto = {
              ...photo,
              uploading: false,
              applyingThumbnails: true,
            }
            if (watermarkId) {
              uploadedPhoto.applyingWatermark = true
            }
            // update local storage photos to stop uploading spinner
            // we do not update the real src just yet since thumbnails are not created yet (updated in createGalleryPhotoChangeListener callback)
            dispatch(addGalleryPhotoAction(galleryId, uploadedPhoto))
            uploadedPhotos.push(uploadedPhoto)
          })
          .catch(() => {
            callbackForEachPhoto(photo.id, -1, photo.file.name)
            dispatch(addGalleryPhotoAction(galleryId, {
              id: photo.id,
              uploading: false,
              applyingThumbnails: false,
              applyingWatermark: false,
            }))
            // remove photo from uploadingPhotoIDs so that we're not waiting for it to update in the database
            const index = uploadingPhotoIDs.indexOf(photo.id)
            if (index > -1) {
              uploadingPhotoIDs.splice(index, 1)
            }
          })
      })

      queue.enqueue(uploadPromisesFuncs)

      queue.on('end', () => {
        resolve(uploadedPhotos)
      })
    })
  }
}

export const removeGallery = (gallery, user) => {
  return dispatch => {
    dispatch(removeGalleryAction(gallery))

    if (gallery.watermark?.id) {
      // remove gallery from watermark data, locally
      dispatch(removeGalleryFromWatermark(gallery.watermark?.id, gallery.id))
    }

    return deleteGallery(gallery)
      .then(() => dispatch(incrementUserGalleryCountLocally(user, false, gallery.isSharedInProfile))) // only need to decrement gallery count in user data locally. deleteGallery server function will do the remote update
  }
}

export const removeGalleryPhoto = (galleryId, photo, isMarkedPhoto) => {
  return (dispatch, getState) => {
    dispatch(removeGalleryPhotosAction(galleryId, [photo.id]))
    return deleteGalleryPhoto(galleryId, photo, isMarkedPhoto)
      .then(({ newGalleryData, newMarkedPhotosData }) => {
        const currentState = getState()
        const currentGallery = currentState.galleries.data[galleryId] || {}

        // remove photo and possible associated watermark from the local photos object. This is only used to update local storage!
        // Note: galleryPhotos can be paginated and not have all the photos
        const updatedGalleryPhotos = { ...(currentGallery.photos || {}) }
        delete updatedGalleryPhotos[photo.id]

        const updatedGallery = {
          ...newGalleryData,
          photos: updatedGalleryPhotos,
        }

        dispatch(updateGalleryAction(galleryId, updatedGallery))

        // if photo is a marked one, update the marked photos
        if (newMarkedPhotosData) {
          dispatch(setGalleryMarkedPhotoIdsAction(galleryId, newMarkedPhotosData))
        }
      })
  }
}

export const removeGalleryPhotos = (galleryId, photos, galleryMarkedPhotos) => {
  return (dispatch, getState) => {
    dispatch(removeGalleryPhotosAction(galleryId, photos.map(photo => photo.id)))
    return deleteGalleryPhotos(galleryId, photos, galleryMarkedPhotos)
      .then(({ newGalleryData, newMarkedPhotosData }) => {
        const currentState = getState()
        const currentGallery = currentState.galleries.data[galleryId] || {}

        // remove photo from the local photos object. This is only used to update local storage!
        // Note: galleryPhotos can be paginated and not have all the photos
        const updatedGalleryPhotos = { ...(currentGallery.photos || {}) }
        photos.forEach(photo => {
          delete updatedGalleryPhotos[photo.id]
        })

        const updatedGallery = {
          ...newGalleryData,
          photos: updatedGalleryPhotos,
        }

        dispatch(updateGalleryAction(galleryId, updatedGallery))

        if (newMarkedPhotosData) {
          dispatch(setGalleryMarkedPhotoIdsAction(galleryId, newMarkedPhotosData))
        }
      })
  }
}

export const setGallerySharingOptions = (galleryId, sharingOptions) => {
  return dispatch => {
    dispatch(setGallerySharingOptionsAction(galleryId, sharingOptions))
    return postGallerySharingOptions(galleryId, sharingOptions)
  }
}

export const setGalleryMarkedPhotos = (galleryId, markedPhotoIds) => {
  return dispatch => {
    dispatch(setGalleryMarkedPhotoIdsAction(galleryId, markedPhotoIds))
    return postGalleryMarkedPhotoIds(galleryId, markedPhotoIds)
  }
}
