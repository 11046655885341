import Skeleton from '@mui/material/Skeleton'
import React from 'react'

import './styles.skeleton.scss'

export const PackDetailsSkeleton = () => {
  return (
    <div className='pack-details-skeleton'>
      <Skeleton animation='wave' variant='text' width={240} height={40} />
      <div className='pack-details-skeleton__main'>
        <div className='pack-details-skeleton__main--left'>
          <Skeleton animation='wave' variant='rectangular' width='100%' height='48%' />
          <Skeleton animation='wave' variant='rectangular' width='100%' height='48%' />
        </div>
        <div className='pack-details-skeleton__main--right'>
          <Skeleton animation='wave' variant='rectangular' height='100%' />
        </div>
      </div>
    </div>
  )
}
