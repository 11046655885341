import { UPLOAD_STATUS } from '../UploadStatus'

export class Pack {
  constructor (pack) {
    const {
      id,
      userUid,
      createdTimestamp,
      lastModifiedTimestamp,
      packInfo = {},
      title,
      price,
      description,
      categories,
      quickInfo,
      faqs,
      media,
      user,
      uploadStatus,
      information,
      reviews,
      visibility,
    } = pack

    this.id = id
    this.userUid = userUid
    this.createdTimestamp = createdTimestamp
    this.lastModifiedTimestamp = lastModifiedTimestamp
    this.packInfo = {
      title,
      price,
      description,
      categories,
      media,
      ...packInfo,
    }
    this.user = user
    this.information = information || description || null
    this.quickInfo = (quickInfo || []).map(quickInfoItem => ({
      iconId: quickInfoItem.iconId,
      title: quickInfoItem.title,
      body: quickInfoItem.body || quickInfoItem.description,
    }))
    this.faqs = (faqs || []).map(faq => ({
      question: faq.question,
      answer: faq.answer,
    }))
    this.reviews = reviews
    this.uploadStatus = uploadStatus ?? UPLOAD_STATUS.NOT_UPLOADED
    this.visibility = visibility
  }

  get title () {
    return this.packInfo.title
  }

  get price () {
    return this.packInfo.price
  }

  get description () {
    return this.packInfo.description
  }

  get images () {
    return this.packInfo.media?.images
  }

  get mainImageId () {
    return this.packInfo.media?.mainImageId
  }

  get categories () {
    return this.packInfo.categories
  }

  static getPackImages (pack) {
    return pack.packInfo.media?.images
  }
}
