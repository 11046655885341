import { isLocal, isMock } from 'utils/envs'
import {
  initializeSearchService as algoliaInitializeSearchService,
  savePackObject as algoliaSavePackObject,
  saveUserObject as algoliaSaveUserObject,
  saveCategoriesObjects as algoliaSaveCategoriesObjects,
  updatePackObject as algoliaUpdatePackObject,
  updateUserObject as algoliaUpdateUserObject,
  deleteUserObject as algoliaDeleteUserObject,
  deletePackObject as algoliaDeletePackObject,
  deletePackObjects as algoliaDeletePackObjects,
  searchPackIndex as algoliaSearchPackIndex,
  searchUsersIndex as algoliaSearchUsersIndex,
  searchCategoriesIndex as algoliaSearchCategoriesIndex,
  searchAll as algoliaSearchAll,
} from './algoliaSearchService'
import {
  initializeSearchService as mockInitializeSearchService,
  savePackObject as mockSavePackObject,
  saveUserObject as mockSaveUserObject,
  saveCategoriesObjects as mockSaveCategoriesObjects,
  updatePackObject as mockUpdatePackObject,
  updateUserObject as mockUpdateUserObject,
  deleteUserObject as mockDeleteUserObject,
  deletePackObject as mockDeletePackObject,
  deletePackObjects as mockDeletePackObjects,
  searchPackIndex as mockSearchPackIndex,
  searchUsersIndex as mockSearchUsersIndex,
  searchCategoriesIndex as mockSearchCategoriesIndex,
  searchAll as mockSearchAll,
} from './mockSearchService'

export const initializeSearchService = () => {
  if (isMock() || isLocal()) {
    return mockInitializeSearchService()
  }

  return algoliaInitializeSearchService()
}

export const savePackObject = packData => {
  if (isMock() || isLocal()) {
    return mockSavePackObject()
  }

  return algoliaSavePackObject(packData)
}

export const saveUserObject = searchQuery => {
  if (isMock() || isLocal()) {
    return mockSaveUserObject()
  }

  return algoliaSaveUserObject(searchQuery)
}

export const saveCategoriesObjects = searchQuery => {
  if (isMock() || isLocal()) {
    return mockSaveCategoriesObjects()
  }

  return algoliaSaveCategoriesObjects(searchQuery)
}

export const updatePackObject = packData => {
  if (isMock() || isLocal()) {
    return mockUpdatePackObject(packData)
  }

  return algoliaUpdatePackObject(packData)
}

export const updateUserObject = packData => {
  if (isMock() || isLocal()) {
    return mockUpdateUserObject(packData)
  }

  return algoliaUpdateUserObject(packData)
}

export const deleteUserObject = packData => {
  if (isMock() || isLocal()) {
    return mockDeleteUserObject(packData)
  }

  return algoliaDeleteUserObject(packData)
}

export const deletePackObject = packData => {
  if (isMock() || isLocal()) {
    return mockDeletePackObject(packData)
  }

  return algoliaDeletePackObject(packData)
}

export const deletePackObjects = packIDs => {
  if (isMock() || isLocal()) {
    return mockDeletePackObjects(packIDs)
  }

  return algoliaDeletePackObjects(packIDs)
}

export const searchPackIndex = (searchQuery, pageIndex) => {
  if (isMock() || isLocal()) {
    return mockSearchPackIndex()
  }

  return algoliaSearchPackIndex(searchQuery, pageIndex)
}

export const searchUsersIndex = (searchQuery, pageIndex, isProfessionalUser) => {
  if (isMock() || isLocal()) {
    return mockSearchUsersIndex()
  }

  return algoliaSearchUsersIndex(searchQuery, pageIndex, isProfessionalUser)
}

export const searchCategoriesIndex = (searchQuery, pageIndex) => {
  if (isMock() || isLocal()) {
    return mockSearchCategoriesIndex(searchQuery, pageIndex)
  }

  return algoliaSearchCategoriesIndex(searchQuery, pageIndex)
}

export const searchAll = (searchQuery, pageIndex) => {
  if (isMock() || isLocal()) {
    return mockSearchAll()
  }

  return algoliaSearchAll(searchQuery, pageIndex)
}
