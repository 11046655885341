import VpnKeyIcon from '@mui/icons-material/VpnKey'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import React, { useEffect, useState } from 'react'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import KeyIcon from '@mui/icons-material/Key'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/Button'
import { LoadingComponent } from 'components/LoadingComponent'
import { Text } from 'components/Text'

import { authUserSignedInRecently, configureMultiFactorAuth, getMultiFactorAuths, requestPasswordReset, unenrollMultiFactorAuth } from 'services/authenticationService'
import { t } from 'services/i18n'

import { useModal } from 'hooks/useModal'
import { ConfigureTOTPModal } from './components/ConfigureTOTPModal'
import { ReauthencationModal } from 'components/ReauthencationModal'
import { getNotificationSystem } from 'services/notificationSystem'
import { getRecoveryCode, postRecoveryCode } from 'services/firebaseServer'

import { logError } from 'utils/errorCapture'
import { useGlobalConfirmationModal } from 'hooks/useGlobalConfirmationModal'
import { useCollapsibleAlert } from 'hooks/useCollapsibleAlert'
import { createUserSettingsLink, subRoutesNames } from 'services/routingService'
import { Link } from 'components/Link'

import { MFATableRowCellContent, TOTPTableRowCellCTA, RecoveryCodesTableRowCellCTA } from './components/MFATableRowCells'

import { Table } from 'components/Table'
import { RecoveryCodeModal } from './components/RecoveryCodeModal'

import './styles.scss'

const STATUS = {
  FAILED: 2,
  DONE: 3,
}

const REAUTHENTICATION_MODAL_CALLBACK_ACTION = {
  CONFIGURE_TOTP: 'CONFIGURE_TOTP',
  UNENROLL_TOTP: 'UNENROLL_TOTP',
  GET_RECOVERY_CODE: 'GET_RECOVERY_CODE',
}

const notificationSystem = getNotificationSystem()

export const PasswordSettings = ({ user }) => {
  const history = useHistory()
  const [ passwordChangeIsLoading, setPasswordChangeIsLoading ] = useState(false)
  const [ multiFactorAuthConfigIsLoading, setMultiFactorAuthConfigIsLoading ] = useState(false)
  const [ recoveryCodeIsLoading, setRecoveryCodeIsLoading ] = useState(false)
  const [ totpMfa, setTotpMfa ] = useState()
  const [
    reauthenticationModalIsOpen,
    openReauthenticationModal,
    closeReauthenticationModal,
    reauthenticationModalContext,
  ] = useModal()
  const [
    configureTOTPModalIsOpen,
    openConfigureTOTPModal,
    closeConfigureTOTPModal,
    configureTOTPModalContext,
  ] = useModal()
  const [openConfirmationModal, closeConfirmationModal] = useGlobalConfirmationModal()
  const [alertIsOpen, openAlert, alertContext] = useCollapsibleAlert()

  useEffect(() => {
    if (user) {
      const multiFactorAuths = getMultiFactorAuths()
      const userTotpMfa = multiFactorAuths.find(mfa => mfa.factorId === 'totp')
      setTotpMfa(userTotpMfa)
    }
  }, [user])

  const updateTotpMfa = () => {
    const multiFactorAuths = getMultiFactorAuths()
    const userTotpMfa = multiFactorAuths.find(mfa => mfa.factorId === 'totp')
    setTotpMfa(userTotpMfa)
  }

  const onChangePasswordClick = () => {
    setPasswordChangeIsLoading(true)
    requestPasswordReset()
      .then(() => openAlert(STATUS.DONE))
      .catch(err => {
        console.error(err)
        openAlert(STATUS.FAILED)
      })
      .finally(() => setPasswordChangeIsLoading(false))
  }

  const onReauthenticateSuccess = () => {
    notificationSystem.notify(t('profile_settings.password_settings.reauthentication_success'))
    // reauthenticationModal context saves the action that was being done by the user before reauthenticationModal was prompt
    // Therefore, we should do it now
    switch (reauthenticationModalContext.reason) {
      case REAUTHENTICATION_MODAL_CALLBACK_ACTION.CONFIGURE_TOTP:
        handleConfigureMultiFactorAuth()
        break
      case REAUTHENTICATION_MODAL_CALLBACK_ACTION.UNENROLL_TOTP:
        handleUnenrollMFA(reauthenticationModalContext.data)()
        break
      case REAUTHENTICATION_MODAL_CALLBACK_ACTION.GET_RECOVERY_CODE:
        handleGetRecoveryCode()
        break
      default:
        break
    }

    // call directly closeReauthenticationModal after using its context
    closeReauthenticationModal()
  }

  const handleConfigureMultiFactorAuth = () => {
    setMultiFactorAuthConfigIsLoading(true)
    configureMultiFactorAuth()
      .then(totpData => {
        openConfigureTOTPModal(totpData)
      })
      .catch(err => {
        if (err.code === 'auth/unverified-email') {
          openConfirmationModal({
            modalTitle: t('profile_settings.password_settings.verify_email_before_modal.title'),
            message: t('profile_settings.password_settings.verify_email_before_modal.description', {
              components: {
                link: <Link
                  underlined
                  colored
                  href={createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT)}
                  onClick={closeConfirmationModal}
                />,
              },
            }),
          })
          return
        }

        if (err.code === 'auth/requires-recent-login') {
          openReauthenticationModal({ reason: REAUTHENTICATION_MODAL_CALLBACK_ACTION.CONFIGURE_TOTP })
          return
        }

        logError(err, t('profile_settings.password_settings.multi_factor_auth.alert.error'), true)
      })
      .finally(() => setMultiFactorAuthConfigIsLoading(false))
  }

  const createRecoverCodeAndShowIt = () => {
    const newRecoveryCode = uuidv4()
    postRecoveryCode(user.uid, newRecoveryCode)
      .then(() => {
        const modalContext = {
          modalComponent: RecoveryCodeModal,
          recoveryCode: newRecoveryCode,
        }
        openConfirmationModal(modalContext)
      })
      .catch(error => {
        logError(error, t('profile_settings.password_settings.multi_factor_auth.alert.create_recovery_code_error'), true)
      })
  }

  const onConfigureTOTPSucess = () => {
    closeConfigureTOTPModal()
    updateTotpMfa()
    createRecoverCodeAndShowIt()
  }

  const handleUnenrollMFA = enrollmentUid => () => {
    const modalContext = {
      modalTitle: t('profile_settings.password_settings.unenroll_confirmation_modal.title'),
      message: t('profile_settings.password_settings.unenroll_confirmation_modal.description'),
      actionButtonText: t('profile_settings.password_settings.unenroll_confirmation_modal.cta'),
      actionButtonColor: 'danger',
      secondaryActionButtonText: t('profile_settings.password_settings.unenroll_confirmation_modal.secondary_cta'),
    }

    openConfirmationModal(modalContext)
      .then(confirmed => {
        if (confirmed) {
          setMultiFactorAuthConfigIsLoading(true)
          unenrollMultiFactorAuth(enrollmentUid)
            .then(() => {
              notificationSystem.notify(t('profile_settings.password_settings.multi_factor_auth.alert.unenroll_sucess_message'))
              updateTotpMfa()
            })
            .catch(err => {
              if (err.code === 'auth/requires-recent-login') {
                openReauthenticationModal({
                  reason: REAUTHENTICATION_MODAL_CALLBACK_ACTION.UNENROLL_TOTP,
                  data: enrollmentUid,
                })
                return
              }

              logError(err, t('profile_settings.password_settings.multi_factor_auth.alert.error'), true)
            })
            .finally(() => setMultiFactorAuthConfigIsLoading(false))
        }
      })
  }

  const handleGetRecoveryCode = () => {
    if (!authUserSignedInRecently()) {
      openReauthenticationModal({
        reason: REAUTHENTICATION_MODAL_CALLBACK_ACTION.GET_RECOVERY_CODE,
      })
      return
    }

    setRecoveryCodeIsLoading(true)
    getRecoveryCode(user.uid)
      .then(recoveryCode => {
        // in case there was an error creating the recovery code when the MFA was configured, let's create it again
        // taking into consideration the user is recently authenticated at this step
        if (recoveryCode === null || recoveryCode === undefined) {
          createRecoverCodeAndShowIt()
          return
        }

        const modalContext = {
          modalComponent: RecoveryCodeModal,
          recoveryCode,
        }
        openConfirmationModal(modalContext)
      })
      .catch(err => {
        logError(err, t('profile_settings.password_settings.multi_factor_auth.alert.recovery_code_error'), true)
      })
      .finally(() => setRecoveryCodeIsLoading(false))
  }

  return (
    <div className='password-settings'>
      <Text
        className='password-settings__title'
        as='h2'
        type='title3'
      >
        {t('profile_settings.password_settings.title')}
      </Text>
      <div className='password-settings__content'>
        <Text>
          {t('profile_settings.password_settings.content_text')}
        </Text>
        <div className='password-settings__action-button'>
          <Button
            color='primary'
            variant='contained'
            startIcon={<VpnKeyIcon />}
            onClick={onChangePasswordClick}
            disabled={passwordChangeIsLoading || alertIsOpen}
          >
            {t('profile_settings.password_settings.ask_email_button')}
          </Button>
          <LoadingComponent
            isLoading={passwordChangeIsLoading}
            size={30}
            className='password-settings__action-button-loading'
          />
        </div>
        <Collapse className='password-settings__alert' in={alertIsOpen}>
          <Alert severity={alertContext === STATUS.FAILED ? 'error' : 'success'}>
            {alertContext === STATUS.FAILED
              ? t('profile_settings.password_settings.alert.error')
              : t('profile_settings.password_settings.alert.sent')}
          </Alert>
        </Collapse>
      </div>

      <Text
        className='password-settings__mfa__title'
        as='h2'
        type='title3'
      >
        {t('profile_settings.password_settings.multi_factor_auth.title')}
      </Text>
      <Text className='password-settings__mfa__description'>
        {t('profile_settings.password_settings.multi_factor_auth.description')}
      </Text>
      <div>
        <Table data={{
          header: {
            label: t('profile_settings.password_settings.multi_factor_auth.enrollments_table.header'),
            colSpan: 2,
          },
          rows: [
            {
              id: 'row1',
              cells: [
                {
                  id: 'row1-cell2',
                  value: <MFATableRowCellContent
                    totpMfa={totpMfa}
                    icon={<SmartphoneIcon />}
                    title={t('profile_settings.password_settings.multi_factor_auth.enrollments_table.totp.title')}
                    description={t('profile_settings.password_settings.multi_factor_auth.enrollments_table.totp.description')}
                    content
                  />,
                }, {
                  id: 'row1-cell2',
                  value: <TOTPTableRowCellCTA
                    totpMfa={totpMfa}
                    handleUnenrollMFA={handleUnenrollMFA}
                    handleConfigureMultiFactorAuth={handleConfigureMultiFactorAuth}
                    multiFactorAuthConfigIsLoading={multiFactorAuthConfigIsLoading}
                  />,
                  align: 'right',
                },
              ],
            },
            {
              id: 'row2',
              header: true,
              label: t('profile_settings.password_settings.multi_factor_auth.enrollments_table.header2'),
              colSpan: 2,
            },
            {
              id: 'row3',
              cells: [
                {
                  id: 'row1-cell2',
                  value: <MFATableRowCellContent
                    totpMfa={totpMfa}
                    icon={<KeyIcon />}
                    title={t('profile_settings.password_settings.multi_factor_auth.enrollments_table.recovery_code.title')}
                    description={t('profile_settings.password_settings.multi_factor_auth.enrollments_table.recovery_code.description')}
                    content
                  />,
                }, {
                  id: 'row1-cell2',
                  value: <RecoveryCodesTableRowCellCTA
                    onGetRecoveryCodeClick={handleGetRecoveryCode}
                    recoveryCodeIsLoading={recoveryCodeIsLoading}
                    disabled={!totpMfa?.uid}
                  />,
                  align: 'right',
                },
              ],
            },
          ],
        }}
        />
      </div>
      <ConfigureTOTPModal
        open={configureTOTPModalIsOpen}
        onSucess={onConfigureTOTPSucess}
        closeModal={closeConfigureTOTPModal}
        totpSecret={configureTOTPModalContext?.totpSecret}
        totpUri={configureTOTPModalContext?.totpUri}
        secretKey={configureTOTPModalContext?.secretKey}
      />
      <ReauthencationModal
        open={reauthenticationModalIsOpen}
        closeModal={closeReauthenticationModal}
        onReauthenticateSuccess={onReauthenticateSuccess}
        currentEmail={user?.email}
        history={history}
      />
    </div>
  )
}
