import { combineReducers } from 'redux'

import agendas from '../agendas/reducer'
import feed from '../feed/reducers'
import galleries from '../galleries/reducer'
import packs from '../packs/reducers'
import search from '../search/reducers'
import users from '../users/reducers'
import watermarks from '../watermarks/reducers'

export default combineReducers({
  agendas,
  feed,
  packs,
  search,
  users,
  galleries,
  watermarks,
})
