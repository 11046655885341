import { useState, useEffect, createContext } from 'react'
import { throttle, debounce } from 'underscore/modules/index'

export const WindowSizeContext = createContext()

const getWidth = () => window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

const getHeight = () => window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight

const DEFAULT_DELAY_MS = 500

export const useWindowSize = (useDebounce, delayInMs = DEFAULT_DELAY_MS) => {
  // save current window width in the state object
  const [width, setWidth] = useState(getWidth())
  const [height, setHeight] = useState(getHeight())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth())
      setHeight(getHeight())
    }

    const delayedResizeListener = useDebounce
      ? debounce(resizeListener, delayInMs)
      : throttle(resizeListener, delayInMs)

    // clean possible gargabe
    window.removeEventListener('resize', delayedResizeListener)

    // set resize listener
    window.addEventListener('resize', delayedResizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', delayedResizeListener)
    }
  }, [delayInMs, useDebounce])

  return [width, height]
}
