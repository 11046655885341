import Slider from '@mui/material/Slider'
import React, { useEffect, useState } from 'react'

import { Modal } from 'components/Modal'

import { t } from 'services/i18n'

const DEFAULT_INITIAL_COLUMNS = 1
const DEFAULT_INITIAL_HEIGHT = 100

export const ResizeLayoutModal = ({
  isOpen,
  close,
  initialColumns = DEFAULT_INITIAL_COLUMNS,
  maxColumns,
  initialHeight = DEFAULT_INITIAL_HEIGHT,
  maxHeight,
  resizeImage,
}) => {
  const [imageColumns, setImageColumns] = useState(initialColumns)
  const [imageMaxHeight, setImageMaxHeight] = useState(initialHeight * 100)

  useEffect(() => {
    setImageColumns(initialColumns)
    setImageMaxHeight(initialHeight * 100)
  }, [initialColumns, initialHeight, isOpen])

  const marks = new Array(maxColumns)
    .fill(0).map((_val, index) => ({ value: index + 1, label: index + 1 }))

  const heightMarks = new Array(6)
    .fill(0).map((_val, index) => ({ value: index * 20, label: index * 20 + '%' }))

  const onColumnsChange = (_event, value) => {
    setImageColumns(value)
  }

  const onMaxHeightChange = (_event, value) => {
    setImageMaxHeight(value)
  }

  const save = () => {
    resizeImage(imageColumns, imageMaxHeight / 100)
  }

  return (
    <Modal
      title={t('back_office.layout.resize_modal.title')}
      open={isOpen}
      actionButton={{
        text: t('back_office.layout.resize_modal.save'),
        onClick: save,
      }}
      secondaryActionButton={{
        text: t('back_office.layout.resize_modal.cancel'),
        onClick: close,
      }}
      closeModal={close}
    >
      <h4>{t('back_office.layout.resize_modal.columns_resize_title')}</h4>
      <div className='layout__modal__slider'>
        <Slider
          aria-label='Número de colunas a ser ocupadas pela imagem'
          defaultValue={1}
          step={1}
          min={1}
          max={maxColumns}
          marks={marks}
          value={imageColumns}
          onChange={onColumnsChange}
        />
      </div>

      <h4>{t('back_office.layout.resize_modal.height_resize_title')}</h4>
      <div className='layout__modal__slider'>
        <Slider
          aria-label='Altura máxima da imagem em percentagem'
          defaultValue={maxHeight}
          step={1}
          min={0}
          max={100}
          marks={heightMarks}
          value={imageMaxHeight}
          onChange={onMaxHeightChange}
        />
      </div>
    </Modal>
  )
}
