import { useRef, useState } from 'react'

export const useDragable = ({ changePositions, resetPositions }) => {
  const [ draggingItemId, setDraggingItemId ] = useState(null)
  const onDragLeaveTimeout = useRef(null)

  const onDragEnter = event => {
    event.preventDefault()
    const finalImageId = event.target.id

    clearTimeout(onDragLeaveTimeout.current)

    // if user enters in any draggin container besides the current one being dragged, let's add it to the state
    if (draggingItemId !== finalImageId) {
      changePositions(draggingItemId, finalImageId)
    }
  }

  const onDragLeave = event => {
    event.preventDefault()
    onDragLeaveTimeout.current = setTimeout(() => {
      onDragLeaveTimeout.current = null
      resetPositions()
    }, 500)
  }

  /**
   * The ondragover event specifies where the dragged data can be dropped.
   * By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element.
   * This is done by calling the event.preventDefault() method for the ondragover event:} event
   */
  const onDragOver = event => {
    event.preventDefault()
    const finalImageId = event.target.id

    clearTimeout(onDragLeaveTimeout.current)

    // if user enters in any draggin container besides the current one being dragged, let's add it to the state
    if (draggingItemId !== finalImageId) {
      changePositions(finalImageId)
    }
  }

  const onDragStart = event => {
    setDraggingItemId(event.target.id)
  }

  const onDragEnd = () => {
    setDraggingItemId(null)
  }

  // used to avoid opening a dragged image in the browser
  const onDragDrop = event => {
    event.preventDefault()
    event.stopPropagation()

    setDraggingItemId(null)
  }

  return [onDragStart, onDragEnter, onDragOver, onDragLeave, onDragDrop, onDragEnd, draggingItemId]
}
