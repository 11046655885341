import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import classNames from 'classnames'
import React from 'react'

import { List } from 'components/List'
import { Text } from 'components/Text'

import './styles.scss'

export const Categories = props => {
  return (
    <div className={classNames('categories', props.className)}>
      {
        props.variant === 'dots'
          ? (
            <List
              className={props.listClassName}
              itemClassName={props.itemClassName}
              withSeparator
            >
              {props.categories}
            </List>
          )
          : props.categories.map(category => (
            <Chip
              className={classNames('categories__item categories__item--chips', props.itemClassName)}
              key={category}
              variant='outlined'
              size='small'
              avatar={<Avatar>#</Avatar>}
              label={<Text type='body2' as='span'>{category}</Text>}
            />
          ))
      }
    </div>
  )
}
