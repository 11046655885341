import React from 'react'
import { DeleteForever } from '@mui/icons-material'

import { Badge } from 'components/Badge'
import { Text } from 'components/Text'

import { t } from 'services/i18n'
import { LoadingButton } from 'components/Button'

import './styles.scss'

export const MFATableRowCellContent = ({
  totpMfa,
  icon,
  title,
  description,
}) => {
  return (
    <div className='mfa-table-row-cells__content-cell'>
      {icon}
      <div className='mfa-table-row-cells__content-cell__content'>
        <div className='mfa-table-row-cells__content-cell__header'>
          <Text type='title4'>
            {title}
          </Text>
          {totpMfa?.uid && <Badge outlined small>{t('profile_settings.password_settings.multi_factor_auth.enrollments_table.configured_label')}</Badge>}
        </div>
        <Text type='body2' color='text__grey-color'>
          {description}
        </Text>
      </div>
    </div>
  )
}

export const TOTPTableRowCellCTA = ({
  totpMfa,
  handleUnenrollMFA,
  handleConfigureMultiFactorAuth,
  multiFactorAuthConfigIsLoading,
}) => {
  return (
    <div className='mfa-table-row-cells__totp-cta-cell'>
      {totpMfa?.uid && (
        <LoadingButton
          color='danger'
          variant='contained'
          startIcon={<DeleteForever />}
          onClick={handleUnenrollMFA(totpMfa?.uid)}
          loading={multiFactorAuthConfigIsLoading}
        >
          {t('profile_settings.password_settings.multi_factor_auth.totp.remove_button')}
        </LoadingButton>
      )}
      {!totpMfa?.uid && (
        <LoadingButton
          color='primary'
          variant='contained'
          onClick={handleConfigureMultiFactorAuth}
          loading={multiFactorAuthConfigIsLoading}
        >
          {t('profile_settings.password_settings.multi_factor_auth.totp.configure_button')}
        </LoadingButton>
      )}
    </div>
  )
}

export const RecoveryCodesTableRowCellCTA = ({
  onGetRecoveryCodeClick,
  recoveryCodeIsLoading,
  disabled,
}) => {
  return (
    <div className='mfa-table-row-cells__recovery-code-cta-cell'>
      <LoadingButton
        color='primary'
        variant='contained'
        onClick={onGetRecoveryCodeClick}
        loading={recoveryCodeIsLoading}
        disabled={disabled}
      >
        {t('profile_settings.password_settings.multi_factor_auth.recovery_code.obtain_code_cta')}
      </LoadingButton>
    </div>
  )
}
