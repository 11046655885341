const createError = name => {
  return class PackNotFoundError extends Error {
    constructor(message) {
      super(message)
      this.message = message
      this.name = name
    }
  }
}

const errors = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  PACK_NOT_FOUND: 'PACK_NOT_FOUND',
  PACK_NOT_LOADED: 'PACK_NOT_LOADED',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  GALLERY_NOT_FOUND: 'GALLERY_NOT_FOUND',
  CLIENT_OFFLINE: 'CLIENT_OFFLINE',
}

const PageNotFoundError = createError(errors.PAGE_NOT_FOUND)
const PackNotFoundError = createError(errors.PACK_NOT_FOUND)
const PackNotLoadedError = createError(errors.PACK_NOT_LOADED)
const UserNotFoundError = createError(errors.USER_NOT_FOUND)
const GalleryNotFoundError = createError(errors.GALLERY_NOT_FOUND)
const ClientOfflineError = createError(errors.CLIENT_OFFLINE)

export {
  PageNotFoundError,
  PackNotFoundError,
  PackNotLoadedError,
  UserNotFoundError,
  GalleryNotFoundError,
  ClientOfflineError,
  errors,
}
