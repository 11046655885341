import MUiIcon from '@mui/material/Icon'
import classNames from 'classnames'
import React from 'react'
import { FaRegHeart, FaHeart } from 'react-icons/fa'

import './styles.scss'

export const Icon = props => {
  if (!props.iconId && props.children) {
    const iconId = props.children
    if (iconId.startsWith('char_')) {
      const iconChar = iconId.split('char_')[1]
      const style = {}
      props.size && (style['--background-size'] = `${props.size}px`)
      props.backgroundColor && (style['--background-color'] = props.backgroundColor)

      return (
        <div
          className={classNames('icon__char', {
            'icon__char--background': props.withBackground || props.backgroundColor,
          })}
          style={style}
        >
          <i>{iconChar}</i>
        </div>
      )
    }
    return <MUiIcon {...props}>{iconId}</MUiIcon>
  }

  switch (props.iconId) {
    case 'heart-border':
      return <FaRegHeart />
    case 'heart':
      return <FaHeart />
    default:
      return null
  }
}
