import { ButtonGroup } from '@mui/material'
import React, { useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, LoadingButton } from 'components/Button'
import { Link } from 'components/Link'
import { Modal } from 'components/Modal'
import { PlanBadge } from 'components/PlanBadge'
import { PriceLabel } from 'components/PriceLabel'
import { StorageDoughnut } from 'components/StorageDoughnut'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useModal } from 'hooks/useModal'

import { PLAN_PERIOD } from 'model/Pricing'

import { currentLogedInUser } from 'store/users/selectors'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'
import { goToCheckout, goToPortal } from 'services/paymentsService'
import { createBackOfficeLink, createUserSettingsLink, subRoutesNames } from 'services/routingService'

import { formatBytes } from 'utils/storageData'

import './styles.scss'

export const UPSELL_MODAL_NOTIFICATION_LISTENER_ID = 'upsell-modal-notification-listener-id'

export const UPSELL_TYPE = {
  RESTRICTED_ACTION_UPSELL: 'RESTRICTED_ACTION_UPSELL',
  EXCEEDED_QUOTA_UPSELL: 'EXCEEDED_QUOTA_UPSELL',
}

const STATUS = {
  INITIAL: 0,
  LOADING: 1,
  FAILED: 2,
  DONE: 3,
}

const renderDoughnut = (storage, totalStorage, isTabletAndUp) => {
  return (
    <div className='upsell-modal__info__storage'>
      <div>
        <StorageDoughnut
          storage={storage}
          legendPosition={isTabletAndUp ? 'right' : 'bottom'}
          aspectRatio={isTabletAndUp ? 3 : 2}
        />
        <div className='upsell-modal__info__total-storage'>
          {t('components.upsell_modal.insufficient_quota.total_storage', {
            totalStorage: formatBytes(totalStorage),
          })}
        </div>
      </div>
    </div>
  )
}

export const UpsellModal = () => {
  const logedInUser = useSelector(currentLogedInUser)
  const [modalIsOpen, openModal, closeModal, modalContext] = useModal()
  const [ planPeriod, setPlanPeriod ] = useState(PLAN_PERIOD.MONTHLY)
  const [ plansStatus, setPlansStatus ] = useState({ status: STATUS.INITIAL, index: 0 })
  const { isTabletAndUp } = useBreakpoints()
  const premiumPlanKeys = getConfigValue('general.pricing.plan_keys')
  const premiumPlanCards = getConfigValue('general.pricing.premium_plan_cards')

  const onNotification = useCallback(({
    upsellType,
    featureName,
    description,
    updateLabel,
    recommendedPlanId,
    storage,
    cancelUrl,
    successUrl,
  }) => {
    const recommendedPlanIndex = (premiumPlanKeys.findIndex(planKey => planKey === recommendedPlanId)) || 0
    openModal({
      upsellType: upsellType || UPSELL_TYPE.RESTRICTED_ACTION_UPSELL,
      featureName,
      description,
      updateLabel,
      recommendedPlanIndex,
      recommendedPlanId,
      storage,
      cancelUrl,
      successUrl,
    })
  }, [openModal, premiumPlanKeys])

  useEffect(() => {
    const notificationSystem = getNotificationSystem()
    notificationSystem.subscribe(UPSELL_MODAL_NOTIFICATION_LISTENER_ID, onNotification)

    return () => notificationSystem.unSubscribe(onNotification)
  }, [onNotification])

  const onPlanChoose = newPlanId => () => {
    // if current user has already a configured plan, that is not the FREE plan, it can go to checkout
    if (logedInUser.plan?.stripeUserId && logedInUser.plan?.id !== premiumPlanKeys[0]) {
      onPlanEdit(newPlanId)
      return
    }

    const selectedPriceId = planPeriod === PLAN_PERIOD.MONTHLY
      ? premiumPlanCards[newPlanId].stripe_price_ids[0]
      : premiumPlanCards[newPlanId].stripe_price_ids[1]

    goToCheckout({
      planId: premiumPlanCards[newPlanId].id,
      priceId: selectedPriceId,
      successUrl: modalContext.successUrl ?? createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT),
      cancelUrl: modalContext.cancelUrl ?? createBackOfficeLink(subRoutesNames.USER_SETTINGS.ACCOUNT),
      clientId: logedInUser.uid,
      clientEmail: logedInUser.email,
      customerId: logedInUser.plan?.stripeUserId,
      hasTrial: premiumPlanCards[newPlanId].prices[selectedPriceId]?.hasTrial && !logedInUser.plan?.stripeUserId,
    })

    setPlansStatus({
      status: STATUS.LOADING,
      planId: newPlanId,
    })
  }

  const onPlanEdit = newPlanId => {
    if (newPlanId === premiumPlanKeys[0]) {
      alert('Para alterares o teu plano para o plano Grátis, cancela o teu plano atual')
    }

    goToPortal({
      cancelUrl: modalContext.cancelUrl ?? createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT),
      stripeUserId: logedInUser.plan?.stripeUserId,
    })

    setPlansStatus({
      status: STATUS.LOADING,
      planId: newPlanId,
    })
  }

  const onPlanPeriodChange = newPlanPeriod => () => setPlanPeriod(newPlanPeriod)

  return (
    <Modal
      open={modalIsOpen}
      title='Atualiza o teu plano'
      closeModal={closeModal}
    >
      <div className='upsell-modal__info'>
        <div>
          <h2 className='upsell-modal__feature'>{modalContext?.featureName}</h2>
          <div className='upsell-modal__required-plan-wrapper'>
            <p>{modalContext?.description}</p>
            {modalContext?.upsellType === UPSELL_TYPE.RESTRICTED_ACTION_UPSELL && (
              <PlanBadge small className='upsell-modal__required-plan-badge' planId={modalContext.recommendedPlanId} />
            )}
          </div>
          <p>{modalContext?.updateLabel}</p>
        </div>
        {isTabletAndUp && modalContext?.upsellType === UPSELL_TYPE.EXCEEDED_QUOTA_UPSELL && (
          renderDoughnut(modalContext.storage, modalContext.storage.total, isTabletAndUp)
        )}
      </div>
      {!isTabletAndUp && modalContext?.upsellType === UPSELL_TYPE.EXCEEDED_QUOTA_UPSELL && (
        renderDoughnut(modalContext.storage, modalContext.storage.total, isTabletAndUp)
      )}

      <ButtonGroup className='upsell-modal__plan-period-picker' aria-label='plan picker'>
        <Button onClick={onPlanPeriodChange(PLAN_PERIOD.MONTHLY)} variant={planPeriod === PLAN_PERIOD.MONTHLY ? 'contained' : 'outlined'}>Mensal</Button>
        <Button onClick={onPlanPeriodChange(PLAN_PERIOD.YEARLY)} variant={planPeriod === PLAN_PERIOD.YEARLY ? 'contained' : 'outlined'}>Anual</Button>
      </ButtonGroup>
      <div className='upsell_modal__plan-list'>
        {premiumPlanKeys.map((planKey, planIndex) => {
          const premiumCard = premiumPlanCards[planKey]
          const periodPrice = Object.values(premiumCard.prices)
            .find(price => planPeriod === PLAN_PERIOD.MONTHLY ? price.interval === 'monthly' || price.interval === 'once' : price.interval === 'anual' || price.interval === 'once')
          const userPlanId = logedInUser.plan?.id || premiumPlanKeys[0]
          return (
            <div className='upsell-modal__plan' key={premiumCard.id}>
              <div>
                <div className='upsell-modal__plan__main-info'>
                  <span className='upsell-modal__plan__title'>{t(`general.premium_packs.${premiumCard.i18nKey}.title`)}</span>
                  <PriceLabel
                    className='upsell-modal__plan__price'
                    periodPrice={periodPrice?.price}
                    periodPriceWithoutDiscount={periodPrice?.price_without_discount}
                    planPeriod={planPeriod}
                  />
                  {modalContext?.upsellType === UPSELL_TYPE.EXCEEDED_QUOTA_UPSELL && (
                    <span className='upsell-modal__plan__storage-available'>
                      {premiumCard.available_features.storage.value}
                    </span>
                  )}
                </div>
                <p>{t(`profile_settings.account_settings.premium_plan_picker.${premiumCard.i18nKey}.description`)}</p>
              </div>
              <LoadingButton
                className='upsell-modal__plan__cta'
                onClick={onPlanChoose(premiumCard.id)}
                loading={plansStatus && plansStatus.planId === premiumCard.id && plansStatus.status === STATUS.LOADING}
                disabled={planIndex < modalContext?.recommendedPlanIndex || premiumCard.id === userPlanId}
              >
                {premiumCard.id === userPlanId ? 'Plano atual' : 'Escolher Plano'}
              </LoadingButton>
            </div>
          )
        })}
      </div>
      <Link
        className='upsell-modal__account-settings-link'
        href={createUserSettingsLink(subRoutesNames.USER_SETTINGS.ACCOUNT)}
        underlined
      >
        {t('components.upsell_modal.show_more_link')}
      </Link>
    </Modal>
  )
}
