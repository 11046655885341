import CancelIcon from '@mui/icons-material/CancelOutlined'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import DesktopIcon from '@mui/icons-material/DesktopMacOutlined'
import MobileIcon from '@mui/icons-material/MobileFriendlyOutlined'
import ReorderIcon from '@mui/icons-material/ReorderOutlined'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import TuneIcon from '@mui/icons-material/TuneOutlined'
import classNames from 'classnames'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLocalActions } from 'scenes/BackOffice/actions/useLocalActions'
import { useLayoutEditor } from 'scenes/BackOffice/scenes/Layout/hooks/useLayoutEditor'

import { Breadcrumb } from 'components/Breadcrumb'
import { Button, IconButton, LoadingButton } from 'components/Button'
import { Image } from 'components/Image'
import { MansoryCardsLayout } from 'components/MansoryCardsLayout'
import { MenuWrapper } from 'components/MenuWrapper'
import { Modal, SimpleMessageModal } from 'components/Modal'
import { UploadFeedback } from 'components/UploadFeedback'

// TODO: Move this dependencies to a generic location
import { PortfolioInput } from '../../../UserDetails/components/PortfolioInput'
import { getUserPacksFromState } from '../../../UserDetails/utils/userPackUtils'
import { GenericAddButton } from '../../components/GenericAddButton/index.js'

import { ResizeLayoutModal } from './components/ResizeLayoutModal'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useModal } from 'hooks/useModal'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import { currentLogedInUser, portfolioDataByBreakpointSelector } from 'store/users/selectors'

import { t } from 'services/i18n'

import './styles.scss'

let userPacksAreLoaded = false

const cardsHaveChanged = (initialCards, currentCards) => {
  const initialCardsMeta = initialCards.map(card => [card.id, card.maxHeight || 1, card.colsWidth || 1].join('|')).join(',')
  const currentCardsMeta = currentCards.map(card => [card.id, card.maxHeight || 1, card.colsWidth || 1].join('|')).join(',')

  return initialCardsMeta !== currentCardsMeta
}

export const Layout = () => {
  const logedInUser = useSelector(currentLogedInUser)
  const masonryRef = useRef()
  const [removeImageModalIsOpen, openRemoveImageModal, closeRemoveImageModal, removeImageModalContext] = useModal()

  const [columns, setColumns] = useState(2)
  const [editingLayout, setEditingLayout] = useState(false)
  const [resizeImageModalIsOpen, setResizeImageModalIsOpen] = useState(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [optionsModalIsOpen, setOptionsModalIsOpen] = useState(false)

  const [userPortfolio, setUserPortfolio] = useState([])
  const [temporaryUserPortfolio, setTemporaryUserPortfolio] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasChanges, setHasChanges] = useState(false)
  const [uploadingChanges, setUploadingChanges] = useState(false)
  const [addPortfolioImageModalIsOpen, setAddPortfolioImageModalIsOpen] = useState(false)
  const [imagesUploadProgress, setImagesUploadProgress] = useState({})

  const initialUserPortfolio = useSelector(portfolioDataByBreakpointSelector(columns === 2 ? 'mobile' : 'desktop'))
  const userLoading = useSelector(state => state.users.loading)
  const userPacksLoading = useSelector(state => state.packs.loading)
  const userPacks = useSelector(state => getUserPacksFromState(state, logedInUser))

  const { isMobile } = useBreakpoints()

  // portfolio keys to use it as a useEffect dependency in order to update the portfolio state only when its really needed
  const initialUserPortfolioKeys = initialUserPortfolio.map(card => card.id).join(',')
  // also use srcs as keys so that when images are uploaded, the layout is updated
  const initialUserPortfolioSrcs = initialUserPortfolio.map(card => card.src).join(',')
  // also use uploadStatus as keys so that when images change its uploading status, the layout is updated
  const initialUserPortfolioUploadStatus = initialUserPortfolio.map(card => card.uploadStatus).join(',')

  const {
    fetchUserPacks,
    editUserLayout,
    addUserPortfolioCards,
    removeUserPortfolioCards,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    setUserPortfolio(initialUserPortfolio)
    setTemporaryUserPortfolio(initialUserPortfolio)
    setIsLoading(false)
  // eslint-disable-next-line
  }, [initialUserPortfolioKeys, initialUserPortfolioSrcs, initialUserPortfolioUploadStatus])

  const getImageOptions = photoId => [
    {
      text: 'Redimensionar Imagem',
      iconId: 'photo_size_select_large',
      onClick: () => setResizeImageModalIsOpen(photoId),
    },
    {
      text: 'Editar Layout',
      iconId: 'dashboard',
      onClick: changeLayout,
    },
    {
      text: 'Apagar imagem',
      iconId:  'delete',
      onClick: () => openRemoveImageModal(photoId),
    },
  ]

  const openMenu = cardId => () => {
    if (isMobile) {
      setMenuIsOpen(cardId)
    }
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  const openOptionsModal = () => {
    setOptionsModalIsOpen(true)
  }

  const closeOptionsModal = () => {
    setOptionsModalIsOpen(false)
  }

  const setCardsHaveChanged = currentCards => {
    if (cardsHaveChanged(initialUserPortfolio, currentCards)) {
      setHasChanges(true)
    } else {
      setHasChanges(false)
    }
  }

  const openAddPortfolioImageModal = () => {
    if (userPacksAreLoaded === false) {
      userPacksAreLoaded = true
      fetchUserPacks()
    }

    // TODO: This should be possible
    if (hasChanges) {
      window.alert('Não é possível adicionar novas imagens enquanto estás a editar o Portefólio. Guarda ou cancela as alterações para adicionares as imagens')
      return
    }

    setAddPortfolioImageModalIsOpen(true)
  }
  const closeAddPortfolioImageModal = () => setAddPortfolioImageModalIsOpen(false)

  const removeImage = () => {
    // TODO: This should be possible
    // TODO: make it possible to remove an image in just one specific layout
    if (hasChanges) {
      window.alert('Não é possível removeres imagens enquanto estás a editar o Portefólio. Guarda ou cancela as alterações para removeres as imagens')
      return
    }

    const currentLayoutBreakpoint = columns === 2 ? 'mobile' : 'desktop'
    removeUserPortfolioCards(removeImageModalContext, currentLayoutBreakpoint)
    closeRemoveImageModal()
  }

  const closeResizeImageModal = () => setResizeImageModalIsOpen(null)

  const setImageHeight = (cardIndex, newHeight) => {
    resizeImage(cardIndex, undefined, newHeight)
  }

  const setImageColumns = (cardIndex, newSizeInColumnsNr) => {
    resizeImage(cardIndex, newSizeInColumnsNr)
  }

  const onResizeImageFromModal = (newSizeInColumnsNr, maxHeight) => {
    const contextImageId = resizeImageModalIsOpen
    const imageIndex = userPortfolio
      .findIndex(card => card.id === contextImageId)

    resizeImage(imageIndex, newSizeInColumnsNr, maxHeight)

    closeResizeImageModal()
  }

  const resizeImage = (imageIndex, newSizeInColumnsNr, maxHeight) => {
    const portfolioCopy = [...temporaryUserPortfolio]

    portfolioCopy[imageIndex] = {
      ...portfolioCopy[imageIndex],
      colsWidth: newSizeInColumnsNr ?? portfolioCopy[imageIndex].colsWidth,
      maxHeight: maxHeight ?? portfolioCopy[imageIndex].maxHeight,
    }

    setTemporaryUserPortfolio(portfolioCopy)
    setUserPortfolio(portfolioCopy)
    setCardsHaveChanged(portfolioCopy)
    masonryRef.current.once('layoutComplete', updateRefs)
  }

  const onTemporaryIndexChange = (initialPosition, finalPosition) => {
    if (finalPosition === -1) {
      setTemporaryUserPortfolio([...userPortfolio])
      return
    }

    const portfolioCopy = [...userPortfolio]
    const item = portfolioCopy[initialPosition]
    portfolioCopy[initialPosition] = portfolioCopy[finalPosition]
    portfolioCopy[finalPosition] = item
    setTemporaryUserPortfolio(portfolioCopy)
  }

  const onCardsChangePosition = () => {
    setCardsHaveChanged(temporaryUserPortfolio)
    setUserPortfolio([...temporaryUserPortfolio || []])
    masonryRef.current.once('layoutComplete', updateRefs)
  }

  const reset = () => {
    setTemporaryUserPortfolio(initialUserPortfolio)
    setHasChanges(false)
    masonryRef.current.once('layoutComplete', updateRefs)
  }

  const onCancelEditLayout = () => {
    reset()
    setEditingLayout(false)
  }

  const savePortfolio = () => {
    const currentLayoutBreakpoint = columns === 2 ? 'mobile' : 'desktop'

    const currentLayoutData = userPortfolio.map(portfolioCard => ({
      id: portfolioCard.id,
      colsWidth: portfolioCard.colsWidth,
      maxHeight: portfolioCard.maxHeight,
    }))

    setUploadingChanges(true)
    editUserLayout(currentLayoutBreakpoint, currentLayoutData)
      .then(() => {
        setHasChanges(false)
        setUploadingChanges(false)
      })
    setEditingLayout(false)
  }

  const onProgressChange = (image, progress) => {
    setImagesUploadProgress(imagesUploadProgress => ({
      ...imagesUploadProgress,
      [image.id]: Math.floor(progress),
    }))
  }

  const addPortfolioImages = images => {
    const currentLayoutBreakpoint = columns === 2 ? 'mobile' : 'desktop'
    addUserPortfolioCards(images, currentLayoutBreakpoint, onProgressChange)
  }

  const changeLayout = () => {
    setEditingLayout(!editingLayout)
  }

  const setLayoutType = newColumns => () => {
    setColumns(newColumns)
    setOptionsModalIsOpen(false)
    setEditingLayout(false)
  }

  const [updateRefs] = useLayoutEditor({
    selector: !isLoading ? '.mansory-cards-layout__card' : null,
    onTemporaryIndexChange,
    onIndexChange: onCardsChangePosition,
    editMode: editingLayout,
    onCancel: changeLayout,
    setImageHeight,
    cardsMaxHeights: temporaryUserPortfolio?.map(card => card.maxHeight || 1),
    setImageColumns,
    currentLayoutColumns: columns,
  })

  const renderLayoutTypePicker = () => {
    return (
      <div>
        <div className={classNames('layout__layout-type-picker', {
          'layout__layout-type-picker--mobile': isMobile,
        })}
        >
          <div className='layout__layout-type-picker__item'>
            <IconButton
              color={columns === 2 ? 'primary' : 'default'}
              className='layout__top-buttons__refresh'
              onClick={setLayoutType(2)}
            >
              <MobileIcon />
            </IconButton>
            {t('back_office.layout.layout_type_modal.mobile')}
          </div>
          <div className='layout__layout-type-picker__item'>
            <IconButton
              color={columns === 3 ? 'primary' : 'default'}
              className='layout__top-buttons__refresh'
              onClick={setLayoutType(3)}
            >
              <DesktopIcon />
            </IconButton>
            {t('back_office.layout.layout_type_modal.desktop')}
          </div>
        </div>
      </div>
    )
  }

  const resizeLayoutContextCard = userPortfolio.find(card => card.id === resizeImageModalIsOpen) || {}

  return (
    <div className='layout'>
      <Breadcrumb
        items={[
          {
            title: t('back_office.layout.title'),
          },
        ]}
      />
      <GenericAddButton label='Adicionar Imagem' onClick={openAddPortfolioImageModal} />
      <div className='layout__top-buttons'>
        <div className='layout__top-buttons__left-group'>
          {!isMobile && editingLayout && (
            <LoadingButton
              variant='outlined'
              color='neutral'
              onClick={onCancelEditLayout}
              loading={uploadingChanges}
            >
              {t('back_office.layout.cancel')}
            </LoadingButton>
          )}
          {!isMobile && hasChanges && (
            <LoadingButton
              startIcon={<SaveIcon />}
              variant='contained'
              color='primary'
              onClick={savePortfolio}
              loading={uploadingChanges}
            >
              {t('back_office.layout.save')}
            </LoadingButton>
          )}
          {isMobile && editingLayout && (
            <IconButton color='neutral' onClick={onCancelEditLayout}>
              <CancelIcon />
            </IconButton>
          )}
          {isMobile && hasChanges && (
            <IconButton color='primary' onClick={savePortfolio}>
              <SaveIcon />
            </IconButton>
          )}
        </div>
        <div className='layout__options'>
          <Button
            id='layout-change-option'
            startIcon={<DashboardIcon />}
            color={editingLayout ? 'primary' : 'neutral'}
            onClick={changeLayout}
          >
            {t('back_office.layout.edit')}
          </Button>
          <IconButton id='layout-settings-option' onClick={openOptionsModal}>
            <TuneIcon />
          </IconButton>
        </div>
      </div>

      {isMobile && hasChanges && !editingLayout && (
        <div className='layout__action-buttons'>
          <IconButton onClick={savePortfolio} className='layout__action-buttons__button'>
            <SaveIcon />
          </IconButton>
        </div>
      )}
      {userPortfolio.length === 0 && !isLoading && !userLoading && (
        <div className='layout__empty-portfolio'>
          <p>O teu portefólio está vazio</p>
          <Button onClick={openAddPortfolioImageModal} variant='outlined' color='primary'>
            Cria o teu portefólio
          </Button>
        </div>
      )}

      <div className='layout__editor-wrapper'>
        <div className={classNames('layout__editor', {
          'layout__editor--big': columns > 2,
        })}
        >
          <MansoryCardsLayout
            withPercentage
            columns={columns}
            itemsWidthInColumns={temporaryUserPortfolio.map(card => card.colsWidth || 1)}
            ref={masonryRef}
            animate={editingLayout}
          >
            {temporaryUserPortfolio.map(card => {
              return (
                <MenuWrapper
                  key={card.id}
                  editMode={!editingLayout && card.uploadStatus !== UPLOAD_STATUS.UPLOADING}
                  withoutMenuButton={isMobile}
                  options={getImageOptions(card.id)}
                  open={menuIsOpen && menuIsOpen === card.id}
                  onCloseMenu={closeMenu}
                >
                  <Fragment>
                    <Image
                      onClick={openMenu(card.id)}
                      src={card.src}
                      width={card.width}
                      height={card.height}
                      maxHeightInPercentage={card.maxHeight}
                      className={classNames('layout__mansory__item-image', {
                        'layout__mansory__item-image--editing': editingLayout,
                      })}
                      imageClassName={classNames({
                        'layout__mansory__item-image--uploading': card.uploadStatus === UPLOAD_STATUS.UPLOADING,
                      })}
                      alt='layout image'
                      thumbnailsSpecs={[{ size: 's', media: '(max-width: 0)' }]}
                      generateThumbnailIfError
                    />
                    {editingLayout && <ReorderIcon className='layout__mansory__reorder-icon' />}
                    <UploadFeedback
                      className='layout__mansory__upload-feedback'
                      uploading={card.uploadStatus === UPLOAD_STATUS.UPLOADING}
                      uploaded={card.uploadStatus === undefined || card.uploadStatus === UPLOAD_STATUS.UPLOADED}
                      progressValue={imagesUploadProgress[card.id]}
                    />
                  </Fragment>
                </MenuWrapper>
              )
            })}
          </MansoryCardsLayout>
        </div>
      </div>
      <ResizeLayoutModal
        isOpen={Boolean(resizeImageModalIsOpen)}
        maxColumns={columns}
        maxHeight={resizeLayoutContextCard.height}
        initialColumns={resizeLayoutContextCard.colsWidth}
        initialHeight={resizeLayoutContextCard.maxHeight}
        resizeImage={onResizeImageFromModal}
        close={closeResizeImageModal}
      />
      <Modal
        title={t('back_office.layout.layout_type_modal.title')}
        open={optionsModalIsOpen}
        closeModal={closeOptionsModal}
      >
        {renderLayoutTypePicker()}
      </Modal>
      <Modal
        open={addPortfolioImageModalIsOpen}
        closeModal={closeAddPortfolioImageModal}
        title={t('user_details.portfolio_input.title')}
      >
        <PortfolioInput
          userPacksLoading={userPacksLoading}
          userPacks={userPacks}
          closeModal={closeAddPortfolioImageModal}
          savePortfolioImages={addPortfolioImages}
        />
      </Modal>
      <SimpleMessageModal
        open={removeImageModalIsOpen}
        closeModal={closeRemoveImageModal}
        modalTitle={t('back_office.layout.remove_image_modal.modal_title')}
        title={t('back_office.layout.remove_image_modal.title')}
        message={t('back_office.layout.remove_image_modal.text')}
        actionButtonText={t('back_office.layout.remove_image_modal.save')}
        secondaryActionButtonText={t('back_office.layout.remove_image_modal.cancel')}
        actionButtonColor='danger'
        secondaryActionButtonVariant='outlined'
        secondaryActionButtonColor='default'
        onActionButton={removeImage}
        onSecondaryActionButton={closeRemoveImageModal}
      />
    </div>
  )
}
