import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React, { Fragment } from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'

import { createGalleryLink } from 'services/routingService'

import './styles.scss'

export const UserGallerySkeleton = () => (
  <div>
    <Skeleton animation='wave' variant='rectangular' height={260} />
    <h2>
      <Skeleton animation='wave' width='70%' height={20} />
    </h2>
    <p>
      <Skeleton animation='wave' width='100%' height={20} />
      <Skeleton animation='wave' width='100%' height={20} />
      <Skeleton animation='wave' width='100%' height={20} />
    </p>
  </div>
)

const userGalleryUI = (image, title, galleryPhotosLength) => {
  return (
    <Fragment>
      <Image
        className='user-gallery__image'
        alt='gallery image 1'
        thumbnailsSpecs={[
          { size: 's', media: '(max-width: 600px)' },
          { size: 'm', media: '(max-width: 960px)' },
          { size: 's', media: '(max-width: 1280px)' },
          { size: 'm', media: '(min-width: 1280px)' },
        ]}
        minHeight={120}
        generateThumbnailIfError
        {...image}
      />
      <div className='user-gallery__info-wrapper'>
        <div className='user-gallery__info'>
          <h3
            className={classNames('user-gallery__info__title', {
              'user-gallery__info__title--alone': !galleryPhotosLength,
            })}
          >
            {title}
          </h3>
          {!!galleryPhotosLength && <p className='user-gallery__info__photos-count'>{galleryPhotosLength + ' fotos'}</p>}
        </div>
      </div>
    </Fragment>
  )
}

export const UserGallery = props => {
  const userGallery = userGalleryUI(props.image, props.title, props.galleryPhotosLength)

  const userGalleryClassName = classNames('user-gallery', {
    'user-gallery--clickable': props.onClick || props.editMode,
    'user-gallery--with-min-height': !props.image,
  })

  return props.onClick || props.editMode
    ? (
      <div tabIndex={0} className={userGalleryClassName} onClick={props.onClick}>
        {userGallery}
      </div>
    )
    : (
      <div className={userGalleryClassName}>
        <Link href={createGalleryLink(props.galleryId)}>
          {userGallery}
        </Link>
      </div>
    )
}
