import React from 'react'

import './styles.scss'

export const NewFeatureTooltip = props => {
  if (!props.isOpen) {
    return props.children
  }

  return (
    <div className='new-feature-tooltip-wrapper'>
      {props.children}
      <div className='new-feature-tooltip'>{props.content}</div>
    </div>
  )
}
