import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { getLocalStorageBoolean } from 'utils/localStorage'

import './styles.scss'

const DEFAULT_GAP_SIZE = 8 // in px

const getBestColumnIndex = (nrOfColumns, columnsMaxHeight, minimumHeight, thresholdInPx) => {
  const minimumHeightColumnIndex = columnsMaxHeight.indexOf(minimumHeight)

  const columnsToTheLeft = new Array(nrOfColumns).fill()
    .map((_, i) => i)
    .filter(index => index < minimumHeightColumnIndex)

  // let's find the first column (starting from left) that has a higher height than the minimum height column but it's not higher than the threshold
  // This way, we can prioritise the order of the images by placing them in order instead of just relying on the smaller column
  const firstColumnWithHeightDifferenceOk = columnsToTheLeft
    .findIndex(columnIndex => {
      const columnIndexHeight = columnsMaxHeight[columnIndex]
      return columnIndexHeight - minimumHeight <= thresholdInPx
    })

  return firstColumnWithHeightDifferenceOk >= 0
    ? firstColumnWithHeightDifferenceOk
    : minimumHeightColumnIndex
}

const getGapSize = (marginOffset, isSkeleton) => {
  if (isSkeleton) {
    return Math.max(DEFAULT_GAP_SIZE + DEFAULT_GAP_SIZE / 4 * marginOffset, 1)

  }
  return Math.max(DEFAULT_GAP_SIZE + DEFAULT_GAP_SIZE * marginOffset, 1)
}

/**
 * Libraries used as masonry layout:
 *  - masonry-layout - masonry layout is still used in some parts of the app. But it has some problems with the Layout (especially the last items) and is very complex
 *  - @mui/lab/Masonry - Best library to render (0 problems with layout) but it has problems adding paginated photos into the layout (scroll jumps to the top)
 *  - react-masonry-css - Pagination is very good but it has some layout problems (also in the last items)
 *  - react-masonry-layout - Problems configuring it
 */

/**
 * This is a custom made masonry layout highly coupled to Gallery data
 * This will loop all the available children and create X columns (based on getColumns) and then distribute all the photos for each column,
 * based on the height of the photos and the current total height of each column
 */
export const GalleryMasonryLayout = ({
  children,
  photoSizes,
  boxHeighPercentages,
  previewNrOfColumns,
  isSelectable,
  layoutOptions,
}) => {
  const { marginOffset = 0, columnsOffset = 0, wideImages: allowWideImages } = layoutOptions || {}
  const { isTabletAndUp, isLargeDesktopAndUp } = useBreakpoints()
  const [ refresh, setRefresh ] = useState(0)

  const getColumns = useCallback(() => {
    if (previewNrOfColumns) {
      return previewNrOfColumns + columnsOffset
    }

    if (isLargeDesktopAndUp) {
      return 4 + columnsOffset
    }

    if (isTabletAndUp) {
      return 3 + columnsOffset
    }

    return 2 + columnsOffset
  }, [columnsOffset, isLargeDesktopAndUp, isTabletAndUp, previewNrOfColumns])

  const [nrOfColumns, setNrOfColumns] = useState(getColumns())

  const columnsRefs = useRef([])
  const wideImagesIndexes = useRef([])
  const showGalleryIndexes = useRef(false)

  const [columns, setColumns] = useState(new Array(nrOfColumns).fill().map((_, i) => ({ key: i, images: [] })))

  // We need to put all photos in the correspondent column
  // For now, we can't rely on the image ref size since we want to distribute the photos as soon as possible
  useEffect(() => {
    const newColumns = new Array(nrOfColumns).fill().map((_, i) => ({ key: i, images: [] }))
    const columnsMaxHeight = new Array(nrOfColumns).fill(0)
    wideImagesIndexes.current = []

    React.Children.map(children, (child, index) => {
      if (child === null) {
        return
      }
      const columnWidth = columnsRefs.current[0].offsetWidth

      const skeletonWidth = columnWidth
      const skeletonHeight = boxHeighPercentages ? columnWidth * boxHeighPercentages[index] : columnWidth * 0.65
      const width = photoSizes?.[child?.key]?.width || skeletonWidth
      const height = photoSizes?.[child?.key]?.height || skeletonHeight
      const isHorizonal = width > height
      const lastWideImageIndex = wideImagesIndexes.current[wideImagesIndexes.current.length - 1]
      const imageIsWide = isHorizonal && nrOfColumns > 2

      const IMAGE_BORDER_SIZE = isSelectable ? 2 : 0
      const GAP_SIZE = getGapSize(marginOffset, boxHeighPercentages)
      const imageWidth = Math.round(columnWidth - IMAGE_BORDER_SIZE * 2)
      const scaledHeight = Math.round(height * imageWidth / width)
      const imageAspectRatio = height / width
      const wideImageWidth = Math.round(columnWidth * 2 + GAP_SIZE - IMAGE_BORDER_SIZE * 2)
      const wideImageHeight = Math.round(wideImageWidth * imageAspectRatio)

      const minimumHeight = Math.min(...columnsMaxHeight)
      const bestColumnIndex = getBestColumnIndex(nrOfColumns, columnsMaxHeight, minimumHeight, columnWidth / 3)

      const bestColumnIsLastColumn = bestColumnIndex === nrOfColumns - 1
      const differenceBetweenBestAndNextColumnIsLow =
        Math.abs(columnsMaxHeight[bestColumnIndex] - columnsMaxHeight[bestColumnIndex + 1]) < 10
      const bestColumnCanHaveWideImage = !bestColumnIsLastColumn && differenceBetweenBestAndNextColumnIsLow
      const lastWideImageIsFarAway = lastWideImageIndex === undefined || index - lastWideImageIndex > 5

      if (imageIsWide && bestColumnCanHaveWideImage && lastWideImageIsFarAway && allowWideImages) {
        wideImagesIndexes.current.push(index)

        // if image is wide, we need to add its height to the next column
        // and current column as well, if the next column is bigger than this one
        // const thisColumnIndex = bestColumnIndex >= 0 ? bestColumnIndex : 0
        const thisColumnHeight = columnsMaxHeight[bestColumnIndex]
        const nextColumnHeight = columnsMaxHeight[bestColumnIndex + 1]
        const nextColumnIsBiggerThanCurrentOne = nextColumnHeight > thisColumnHeight
        const nextColumnIsSmallerThanCurrentOne = thisColumnHeight > nextColumnHeight

        // if next column is bigger than the current one, we must fill the current column space with the diff
        if (nextColumnIsBiggerThanCurrentOne) {
          const diff = nextColumnHeight - thisColumnHeight
          newColumns[bestColumnIndex].images.push({ index, ghost: true, diff })
          columnsMaxHeight[bestColumnIndex] = columnsMaxHeight[bestColumnIndex] + diff
        }

        // we must be add the size of the wide image in the next column
        // if next column is smaller than the current one, we must also fill the space wiin the next column with the difference
        const diff = nextColumnIsSmallerThanCurrentOne ? thisColumnHeight - nextColumnHeight : 0
        const imageFullHeight = wideImageHeight + IMAGE_BORDER_SIZE * 2 + GAP_SIZE
        const nextColumnFillHeight = imageFullHeight + diff
        newColumns[bestColumnIndex + 1].images.push({ index, ghost: true, diff: nextColumnFillHeight })
        columnsMaxHeight[bestColumnIndex + 1] = columnsMaxHeight[bestColumnIndex + 1] + nextColumnFillHeight

        // and add the actual image to the current column
        newColumns[bestColumnIndex].images.push({ image: child, index, wide: imageIsWide })
        columnsMaxHeight[bestColumnIndex] =
          columnsMaxHeight[bestColumnIndex] + imageFullHeight

        return
      }

      // this should never happen, but when changing device width
      if (newColumns[bestColumnIndex]) {
        newColumns[bestColumnIndex].images.push({ image: child, index })
        columnsMaxHeight[bestColumnIndex] =
          columnsMaxHeight[bestColumnIndex] + scaledHeight + IMAGE_BORDER_SIZE * 2 + GAP_SIZE
      }
      return
    })

    setColumns(newColumns)
  }, [allowWideImages, boxHeighPercentages, children, isSelectable, marginOffset, nrOfColumns, photoSizes, refresh])

  useEffect(() => {
    setNrOfColumns(getColumns())
    // force masonry to update when nr of columns are updated
    // Note: this was not working when using boxes or skeletons
    setTimeout(() => {
      setRefresh(prev => prev + 1)
    }, 0)
  }, [getColumns, setRefresh])

  useEffect(() => {
    showGalleryIndexes.current = getLocalStorageBoolean('showGalleryIndexes') ?? false
  }, [])

  return (
    <div
      style={marginOffset !== 0 ? { '--gap-size': getGapSize(marginOffset, boxHeighPercentages) + 'px' } : {}}
      className='gallery-masonry-layout'
    >
      {columns.map((column, index) => {
        return (
          <div
            key={column.key}
            ref={ref => columnsRefs.current[index] = ref}
            className={classNames('gallery-masonry-layout__column', {
              'gallery-masonry-layout__column--first': index === 0,
              'gallery-masonry-layout__column--last': index === columns.length - 1,
            })}
          >
            {column.images.map(image => {
              if (image.ghost) {
                const paddingTop = `${image.diff}px`
                return (
                  <div
                    data-index={image.index}
                    key={image.key}
                    style={{ paddingTop }}
                  />
                )
              }

              return (
                <div
                  data-index={image.index}
                  key={image.key}
                  className={classNames('gallery-masonry-layout__column__item', {
                    'gallery-masonry-layout__column__item--wide': image.wide,
                    'gallery-masonry-layout__column__item--show-indexes': showGalleryIndexes.current,
                  })}
                >
                  {image.image}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
