import { getDatabase, getFunctions, getStorage } from '.'
import { getPutAgendaEventGalleryBatchData } from './agendas'
import { postGalleryImage, deleteImage } from './imagesStorage'
import { getIncrementUserGalleryBatchData } from './users'
import { httpsCallable } from 'firebase/functions'
import { ref, getDownloadURL } from 'firebase/storage'

import { isProduction } from 'utils/envs'
import { logError } from 'utils/errorCapture'
import { wait } from 'utils/fetch'
import { collection, deleteField, doc, getDoc, getDocs, onSnapshot, query, runTransaction, setDoc, writeBatch, where } from '@firebase/firestore'

const MARKED_PHOTOS_IDS_KEYS = 'markedPhotosIds'

const getGalleryRef = galleryId => doc(getDatabase(), 'galleries', galleryId)
const getGalleryPhotosRef = galleryId => collection(getDatabase(), 'galleries', galleryId, 'photos')
const getGalleryPhotoRef = (galleryId, photoId) => doc(getDatabase(), 'galleries', galleryId, 'photos', photoId)

export const getGallery = galleryId => {
  const docRef = getGalleryRef(galleryId)

  console.info('Fetching gallery:', galleryId)
  return getDoc(docRef)
    .then(gallery => {
      console.info('Fetched gallery:', galleryId)
      return gallery.data()
    })
    .catch(error => {
      logError(error, `Error fetching gallery ${galleryId}: ${error}`)
      throw error
    })
}

export const createGalleryArchive = galleryId => {
  const functionUrl = isProduction()
    ? `https://create-gallery-archive-v2-ldybcuycyq-ew.a.run.app?galleryId=${galleryId}`
    : `https://create-gallery-archive-v2-b45h646jgq-ew.a.run.app?galleryId=${galleryId}`

  return fetch(functionUrl)
    .then(response => response.json())
    .then(result => {
      if (result.errorMessage) {
        throw new Error(result.errorMessage)
      }
      console.log(`Gallery archive ${galleryId} was completed successfully!`)
      return result.signedUrl
    })
    .catch(error => {
      logError(error, `Error creating gallery ${galleryId} archive: ${error}`)
      throw error
    })
}

// This function will create a downloadURL from the already created gallery archive file
// Ideally, this was done in the backend but there are multiple constraints to achieve (we went through some of them)
// See why in these threads and in the plotu-firebase-functions repository story (galleries.js file)
//    https://github.com/googleapis/nodejs-storage/issues/244
//    https://github.com/googleapis/nodejs-storage/issues/697
// For more info about "Firebase Storage download URLs and tokens" see here: https://www.sentinelstand.com/article/guide-to-firebase-storage-download-urls-tokens
export const getGalleryArchiveDownloadURL = (
  galleryId,
  archiveFilePath,
  retries = 5,
  delay = 750,
  delayMultiplier = 2
) => {

  return getDownloadURL(ref(getStorage(), archiveFilePath))
    .then(downloadURL => {
      console.log(`Gallery ${galleryId} Download URL successfully created!`)
      // We cannot save the Download URL in the database since no one has write permissions besides the owner of the gallery
      return downloadURL
    })
    .catch(error => {
      if (error.code === 'storage/object-not-found') {
        if (retries > 0) {
          return wait(delay)
            .then(() => getGalleryArchiveDownloadURL(galleryId, archiveFilePath, retries - 1, delay * delayMultiplier))
        } else {
          logError(error, `Error creating Download URL for gallery with id ${galleryId}. Archive file is not found: ${error}`)
          throw error
        }
      }

      logError(error, `Error creating Download URL for gallery with id ${galleryId}: ${error}`)
      throw error
    })
}

export const createGalleryChangesListener = (galleryId, callback) => {
  const unsubscribeListener = onSnapshot(
    getGalleryRef(galleryId),
    doc => callback(doc.data())
  )

  return unsubscribeListener
}

export const getGalleryMarkedPhotosIds = galleryId => {
  const docRef = getGalleryPhotoRef(galleryId, MARKED_PHOTOS_IDS_KEYS)

  console.info('Fetching marked photos from gallery', galleryId)
  return getDoc(docRef)
    .then(markedPhotoIds => {
      console.info('Fetched markedPhotoIds from gallery:', galleryId)

      if (markedPhotoIds.exists()) {
        return markedPhotoIds.data()
      } else {
        return {}
      }
    })
    .catch(error => {
      logError(error, `Error fetching marked photos from gallery ${galleryId}: ${error}`)
      throw error
    })
}

export const getGalleryPhotos = (galleryId, photoIdsToLoad) => {
  const constraints = []
  if (photoIdsToLoad?.length > 0) {
    constraints.push(where('id', 'in', photoIdsToLoad))
  }

  const q = query(
    getGalleryPhotosRef(galleryId),
    ...constraints
  )

  console.info('Fetching photos from gallery', galleryId)
  return getDocs(q)
    .then(eventsSnapshot => {
      console.info('Fetched photos from gallery:', galleryId)

      const galleryPhotos = eventsSnapshot.docs
        ?.reduce((prev, currDoc) => {
          // marked photos doc must be ignored
          if (currDoc.id === MARKED_PHOTOS_IDS_KEYS) {
            return prev
          }
          const photo = currDoc.data()
          prev[photo.id] = photo
          return prev
        }, {}) || {}

      return galleryPhotos
    })
    .catch(error => {
      logError(error, `Error fetching photos from gallery ${galleryId}: ${error}`)
      throw error
    })
}
export const getGalleryPhoto = (galleryId, photoId) => {
  const photoIsWatermark = photoId.includes('_wm')
  const parsedPhotoId = photoIsWatermark
    ? photoId.split('_wm')[0]
    : photoId

  const docRef = getGalleryPhotoRef(galleryId, parsedPhotoId)

  if (photoIsWatermark) {
    console.info(`Fetching photo ${parsedPhotoId} from gallery ${galleryId} on behalf of watermark photo with id ${photoId}`)
  } else {
    console.info(`Fetching photo ${parsedPhotoId} from gallery ${galleryId}`)
  }

  return getDoc(docRef)
    .then(photo => {
      console.info(`Fetched photo ${parsedPhotoId} from gallery ${galleryId}`)

      const photoData = photo.data()
      if (photoIsWatermark) {
        return photoData.watermark
      }

      return photoData
    })
    .catch(error => {
      logError(error, `Error fetching photo ${photoId} from gallery ${galleryId}: ${error}`)
      throw error
    })
}

const getParsedGallery = gallery => {
  const parsedGallery = {
    creationDate: gallery.creationDate,
    eventId: gallery.eventId,
    id: gallery.id,
    title: gallery.title,
    coverPhotoId: gallery.coverPhotoId,
    userUid: gallery.userUid,
    downloadIsAllowed: gallery.downloadIsAllowed,
    sharingOptions: gallery.sharingOptions,
    isSharedInProfile: gallery.isSharedInProfile,
    photosLayout: gallery.photosLayout,
    watermark: gallery.watermark,
    personalisation: gallery.personalisation,
  }

  if (gallery.markedPhotos) {
    parsedGallery.markedPhotos = {
      markedPhotosAreAllowed: gallery.markedPhotos?.markedPhotosAreAllowed ?? false,
      markedPhotosMaxLimit: parseInt(gallery.markedPhotos?.markedPhotosMaxLimit),
    }
  }

  return parsedGallery
}

const getUpdateWatermarkGalleriesData = (userUid, galleryId, watermarkId, value) => {
  const watermarkData = {
    id: watermarkId,
  }

  watermarkData[`galleries.${galleryId}`] = value

  const watermarkDocRef = doc(getDatabase(), 'users', userUid, 'watermarks', watermarkId)

  return { watermarkDocRef, watermarkData }
}

export const postGallery = gallery => {
  const batch = writeBatch(getDatabase())

  /**
   * Create gallery
   */
  const galleryDocRef = getGalleryRef(gallery.id)
  const parsedGallery = getParsedGallery(gallery)
  batch.set(galleryDocRef, { ...parsedGallery })

  /**
   * Update user gallery count atomically
   */
  const { galleryUserRef, galleryUserData } = getIncrementUserGalleryBatchData(gallery.userUid)
  batch.update(galleryUserRef, galleryUserData)

  /**
   * Update associated event with gallery if gallery has any event
   */
  if (gallery.eventId) {
    const { eventRef, eventData } = getPutAgendaEventGalleryBatchData(gallery.userUid, gallery.eventId, gallery.id)
    batch.update(eventRef, eventData)
  }

  /**
   * Update associated watermark data with gallery info
   */
  const watermarkId = parsedGallery.watermark?.id
  // if gallery was created with a watermark, we must add it to the watermark data
  // Note that at this point, we don't need to trigger the job to create the watermarks since there is no photos in the gallery yet
  if (watermarkId) {
    const { watermarkDocRef, watermarkData } =
      getUpdateWatermarkGalleriesData(parsedGallery.userUid, parsedGallery.id, watermarkId, true)
    batch.update(watermarkDocRef, watermarkData)
  }

  return batch.commit()
    .then(() => {
      console.log(`Gallery ${gallery.id} successfully written!`)
    })
    .catch(error => {
      logError(error, `Error creating gallery with id ${gallery.id}: ${error}`)
      throw error
    })
}

export const putGallery = (galleryId, newGalleryData, updateWatermarks, updateEventData) => {
  const galleryDocRef = getGalleryRef(galleryId)

  const parsedNewGallery = getParsedGallery(newGalleryData)

  return runTransaction(getDatabase(), async transaction => {
    const galleryDoc = await transaction.get(galleryDocRef)
    if (!galleryDoc.exists()) {
      logError(Error(`Gallery ${galleryId} does not exist! Transaction to remove photos aborted`))
    }
    const galleryData = galleryDoc.data()

    /**
     * Update gallery with new data
     */
    transaction.update(galleryDocRef, parsedNewGallery)

    /**
     * Update associated watermark data with gallery info
     */
    const previousWatermarkId = galleryData.watermark?.id
    const newWatermarkId = parsedNewGallery.watermark?.id
    const galleryWatermarkHasChanges = previousWatermarkId !== newWatermarkId
    if (galleryWatermarkHasChanges && updateWatermarks) {
      // if a watermark existed in the previous gallery data, we must remove it from watermark data
      if (previousWatermarkId) {
        const deleteValue = deleteField()
        const { watermarkDocRef, watermarkData } =
          getUpdateWatermarkGalleriesData(parsedNewGallery.userUid, galleryData.id, previousWatermarkId, deleteValue)
        transaction.update(watermarkDocRef, watermarkData)
      }

      // if a new watermark exists in the new gallery data, we must add it to the watermark data
      if (newWatermarkId) {
        const newValue = true
        const { watermarkDocRef, watermarkData } =
          getUpdateWatermarkGalleriesData(parsedNewGallery.userUid, galleryData.id, newWatermarkId, newValue)
        transaction.update(watermarkDocRef, watermarkData)
      }
    }

    /**
     * Update associated event with gallery if gallery has any event
     */
    if (updateEventData && parsedNewGallery.eventId) {
      const { eventRef, eventData } =
        // we must use galleryData to get userUid since the userUid might not be in parsedNewGallery
        getPutAgendaEventGalleryBatchData(galleryData.userUid, parsedNewGallery.eventId, galleryId)
      transaction.update(eventRef, eventData)
    } else if (updateEventData && !parsedNewGallery.eventId && galleryData.eventId) {
      const { eventRef, eventData } =
        getPutAgendaEventGalleryBatchData(galleryData.userUid, updateEventData.id, galleryId, true)
      transaction.update(eventRef, eventData)
    }

    return {
      shouldTriggerWatermarkJob: galleryWatermarkHasChanges && newWatermarkId && updateWatermarks,
    }
  })
    .then(data => {
      console.log(`Gallery ${galleryId} successfully updated!`)

      // If a new watermark is associated to the gallery, we must call the firebase functions job to create watermarks for all the gallery photos
      if (data.shouldTriggerWatermarkJob) {
        const createGalleryWatermarksFunction = httpsCallable(getFunctions(), 'addWatermarkToGalleryCall')
        return createGalleryWatermarksFunction({ galleryId })
          .then(() => {
            console.log(`Gallery ${galleryId} watermarks were created successfully!`)
            return data
          })
          .catch(error => {
            logError(error, `Error creating watermarks for gallery ${galleryId}: ${error}`)
            throw error
          })
      }

      return data
    })
    .catch(error => {
      logError(error, `Error updating gallery with id ${galleryId}: ${error}`)
      throw error
    })
}

export const addWatermarkToGalleryPhoto = (galleryId, photoId) => {
  console.log(`Creating watermark of photo ${photoId} from gallery ${galleryId}`)
  const createPhotoWatermarkFunction = httpsCallable(getFunctions(), 'addWatermarkToGalleryPhotoCall')
  return createPhotoWatermarkFunction({ galleryId, galleryPhotoId: photoId })
      .then(watermarkData => {
        console.log(`Watermark of photo ${photoId} from gallery ${galleryId} was created successfully!`)
        return watermarkData.data
      })
      .catch(error => {
        logError(error, `Error creating watermark of photo ${photoId} from gallery ${galleryId}: ${error}`)
        throw error
      })
}

export const postGalleryPhoto = (galleryId, photo, progressCallback, watermarkId) => {
  const db = getDatabase()
  const galleryDocRef = getGalleryRef(galleryId)
  const galleryPhotoDocRef = getGalleryPhotoRef(galleryId, photo.id)

  const { file, ...photoData } = photo

  return postGalleryImage(file, galleryId, photo.id, progressCallback)
    .then(src => {
      const updatedPhotoWithSrc = {
        ...photoData,
        name: file.name,
        src,
      }

      return runTransaction(db, async transaction => {
        const galleryDoc = await transaction.get(galleryDocRef)

        if (!galleryDoc.exists()) {
          logError(Error(`Gallery ${galleryId} does not exist! Transaction to remove photos aborted`))
        }

        const galleryData = galleryDoc.data()

        // update photo with new src
        transaction.set(galleryPhotoDocRef, updatedPhotoWithSrc, { merge: true })

        // update gallery photosLastModifiedTimestamp and photosLayout
        const newPhotosLayout = [...(galleryData.photosLayout || []), photo.id]
        transaction.update(galleryDocRef, {
          photosLastModifiedTimestamp: Date.now(),
          photosLayout: newPhotosLayout,
        })
      })
      .then(() => {
        console.log(`Gallery ${galleryId} image src were successfully updated!`)

        // If a new watermark is associated to the gallery,
        // we must call the firebase functions job to create watermarks for all the gallery photos
        if (watermarkId) {
          return addWatermarkToGalleryPhoto(galleryId, photo.id)
        }

        return updatedPhotoWithSrc
      })
      .catch(error => {
        logError(error, `Error adding photo with id ${photo.id} to gallery with id ${galleryId}: ${error}`)
        throw error
      })
    })
    .catch(error => {
      logError(error, `Error posting gallery ${galleryId} photos`)
      throw error
    })
}

/**
 * Creates a listener to changes for all photos from a specified gallery
 * Use `createGalleryPhotoChangeListener` instead unless strictly necessary
 */
export const createGalleryPhotosChangeListener = (galleryId, callback) => {
  const unsubscribeListener = onSnapshot(
    getGalleryPhotosRef(galleryId),
    // Note: Do not use 'in' filter since it only supports a maximum of 10 elements in the value array
    querySnapshot => {
      const photos = {}
      querySnapshot.forEach(doc => {
        photos[doc.id] = doc.data()
      })

      callback(photos)
    }
  )

  return unsubscribeListener
}

/**
 * Creates a listener to changes for a specific gallery photo
 * Note that it's better to use multiple listeners of this than to listen for the whole collection.
 * We cannot filter the whole collection with the "in" filter since it only supports a maximum of 10 elements in the value array
 */
export const createGalleryPhotoChangeListener = (galleryId, photoId, callback) => {
  const unsubscribeListener = onSnapshot(
    getGalleryPhotoRef(galleryId, photoId),
    docSnapshot => callback(docSnapshot.data())
  )

  return unsubscribeListener
}

export const deleteGallery = gallery => {
  const deleteGalleryFunction = httpsCallable(getFunctions(), 'deleteGallery')
  return deleteGalleryFunction({ galleryId: gallery.id })
    .then(() => {
      console.log(`Gallery ${gallery.id} was removed successfully!`)
    })
    .catch(error => {
      logError(error, `Error removing gallery ${gallery.id}: ${error}`)
      throw error
    })
}

export const deleteGalleryPhotos = (galleryId, photos, galleryMarkedPhotos) => {
  const db = getDatabase()
  const galleryDocRef = getGalleryRef(galleryId)

  // get all the photos to be deleted that are marked
  const markedPhotoIdsToBeDeleted = photos
    .reduce((prev, photo) => {
      if (galleryMarkedPhotos[photo.id]) {
        prev.push(photo.id)
      }
      return prev
    }, [])

  return runTransaction(db, async transaction => {
    const galleryDoc = await transaction.get(galleryDocRef)
    if (!galleryDoc.exists()) {
      logError(Error(`Gallery ${galleryId} does not exist! Transaction to remove photos aborted`))
    }

    const galleryData = galleryDoc.data()

    // create new photos layout array
    const galleryPhotosLayoutUpdated = [...galleryData.photosLayout]
    photos.forEach(photo => {
      const deletedPhotoIndex = galleryPhotosLayoutUpdated.indexOf(photo.id)
      if (deletedPhotoIndex > -1) {
        galleryPhotosLayoutUpdated.splice(deletedPhotoIndex, 1)
      }
    })

    const currentCoverPhotoId = galleryData.coverPhotoId || galleryData.photosLayout[0]
    const newDefaultCoverPhotoId = galleryPhotosLayoutUpdated[0]
    const coverPhotoIsDeleted = photos.some(photo => photo.id === currentCoverPhotoId)

    // fetch the gallery marked photos if there is any marked photo to be removed
    // Note that we cannot be sure the photo is a marked photo since we're relying on the local store data
    // however, the side effects should not be a big deal (worst case, we probably set an unmarked photo as unmarked again)
    let newMarkedPhotosData
    if (markedPhotoIdsToBeDeleted.length > 0) {
      const galleryMarkedPhotosDocRef = getGalleryPhotoRef(galleryId, MARKED_PHOTOS_IDS_KEYS)

      const galleryMarkedPhotosDoc = await transaction.get(galleryMarkedPhotosDocRef)
      newMarkedPhotosData = galleryMarkedPhotosDoc.data()

      markedPhotoIdsToBeDeleted.forEach(markedPhotoIdToBeDeleted => {
        delete newMarkedPhotosData[markedPhotoIdToBeDeleted]
      })
    }

    // delete all photos from database
    photos.forEach(photo => {
      const photoDocRef = getGalleryPhotoRef(galleryId, photo.id)

      // delete gallery photo from database
      transaction.delete(photoDocRef)
    })

    // update gallery data from database:
    //  - update photosLastModifiedTimestamp
    //  - coverPhotoId if coverPhoto was deleted
    //  - photosLayout updated without the deleted photo ids
    const newGalleryData = {
      photosLastModifiedTimestamp: Date.now(),
      photosLayout: galleryPhotosLayoutUpdated,
    }

    if (coverPhotoIsDeleted) {
      newGalleryData.coverPhotoId = newDefaultCoverPhotoId
    }

    transaction.update(galleryDocRef, newGalleryData)

    // if any photo is a marked one, update the marked photos in the database
    if (markedPhotoIdsToBeDeleted && newMarkedPhotosData) {
      const galleryMarkedPhotosDocRef = getGalleryPhotoRef(galleryId, MARKED_PHOTOS_IDS_KEYS)

      transaction.set(galleryMarkedPhotosDocRef, newMarkedPhotosData)
    }

    return {
      newGalleryData,
      transactionTimestamp: Date.now(),
      newMarkedPhotosData,
    }
  })
  .then(async data => {
    const photoIds = photos.map(photo => photo.id)
    console.log(`Photos with ids ${photoIds} were removed from gallery ${galleryId}!`)

    // we need to get both original and watermark srcs
    // however, we need to make sure the src exist (using .filter) since watermark may not be in use
    const photoSrcs = photos
      .flatMap(photo => [photo.src, photo.watermark?.src])
      .filter(photoSrc => photoSrc)

    // delete the actual photos from storage and associated watermarks if they exit
    const deleteImagePromise = photoSrcs.map(photoSrc => {
      return deleteImage(photoSrc)
    })
    await Promise.all(deleteImagePromise)

    console.log(`Photos with id ${photoIds} were removed from storage`)

    return data
  })
  .catch(error => {
    const photoIds = photos.map(photo => photo.id)
    logError(error, `Error removing photos with ids ${photoIds} from gallery with id ${galleryId}: ${error}`)
    throw error
  })
}

export const deleteGalleryPhoto = (galleryId, photo, isMarkedPhoto) => {
  const db = getDatabase()
  const galleryDocRef = getGalleryRef(galleryId)
  const photoDocRef = getGalleryPhotoRef(galleryId, photo.id)

  return runTransaction(db, async transaction => {
    const galleryDoc = await transaction.get(galleryDocRef)
    if (!galleryDoc.exists()) {
      logError(Error(`Gallery ${galleryId} does not exist! Transaction to remove photo ${photo.id} aborted`))
    }

    const galleryData = galleryDoc.data()

    // create new photos layout array
    const galleryPhotosLayoutUpdated = [...galleryData.photosLayout]
    const deletedPhotoIndex = galleryPhotosLayoutUpdated.indexOf(photo.id)
    if (deletedPhotoIndex > -1) {
      galleryPhotosLayoutUpdated.splice(deletedPhotoIndex, 1)
    }

    const currentCoverPhotoId = galleryData.coverPhotoId || galleryData.photosLayout[0]
    const newDefaultCoverPhotoId = galleryPhotosLayoutUpdated[0]
    const deletedPhotoIsCoverPhoto = currentCoverPhotoId === photo.id

    // fetch the gallery marked photos if photo to be removed is marked
    // Note that we cannot be sure the photo is a marked photo since we're relying on the local store data
    // however, the side effects should not be a big deal (worst case, we probably set an unmarked photo as unmarked again)
    let newMarkedPhotosData
    if (isMarkedPhoto) {
      const galleryMarkedPhotosDocRef = getGalleryPhotoRef(galleryId, MARKED_PHOTOS_IDS_KEYS)

      const galleryMarkedPhotosDoc = await transaction.get(galleryMarkedPhotosDocRef)
      newMarkedPhotosData = galleryMarkedPhotosDoc.data()

      if (newMarkedPhotosData[photo.id]) {
        delete newMarkedPhotosData[photo.id]
      }
    }

    // delete gallery photo from database
    transaction.delete(photoDocRef)

    // update gallery data from database:
    //  - update photosLastModifiedTimestamp
    //  - coverPhotoId if coverPhoto was deleted
    //  - photosLayout updated without the deleted photo ids
    const newGalleryData = {
      photosLastModifiedTimestamp: Date.now(),
      photosLayout: galleryPhotosLayoutUpdated,
    }

    if (deletedPhotoIsCoverPhoto) {
      newGalleryData.coverPhotoId = newDefaultCoverPhotoId
    }

    transaction.update(galleryDocRef, newGalleryData)

    // if photo is a marked one, update the marked photos in the database
    if (isMarkedPhoto && newMarkedPhotosData) {
      const galleryMarkedPhotosDocRef = getGalleryPhotoRef(galleryId, MARKED_PHOTOS_IDS_KEYS)

      transaction.set(galleryMarkedPhotosDocRef, newMarkedPhotosData)
    }

    return {
      newGalleryData,
      transactionTimestamp: Date.now(),
      newMarkedPhotosData,
    }
  })
  .then(async data => {
    console.log(`Photo with id ${photo.id} was removed from gallery ${galleryId}!`)

    // delete the actual photo from storage and the watermark photo is configured
    await deleteImage(photo.src)
    if (photo.watermark) {
      await deleteImage(photo.watermark.src)
    }

    console.log(`Photo with id ${photo.id}${photo.watermark ? ' and the associated watermark were' : ' was'} removed from storage`)

    return data
  })
  .catch(error => {
    logError(error, `Error removing photo with id ${photo.id} from gallery with id ${galleryId}: ${error}`)
    throw error
  })
}

export const postGallerySharingOptions = (galleryId, sharingOptions) => {
  const galleryDocRef = getGalleryRef(galleryId)

  return setDoc(galleryDocRef, { ...sharingOptions }, { merge: true })
    .then(() => {
      console.log(`Sharing options were updated in gallery ${galleryId}!`)
    })
    .catch(error => {
      logError(error, `Error updating sharing options in gallery with id ${galleryId}: ${error}`)
      throw error
    })
}

export const postGalleryMarkedPhotoIds = (galleryId, markedPhotoIds) => {
  const docRef = getGalleryPhotoRef(galleryId, MARKED_PHOTOS_IDS_KEYS)

  const parsedMarkedPhotoIds = Object.keys(markedPhotoIds)
    .reduce((prev, curr) => {
      if (markedPhotoIds[curr] === false) {
        return {
          ...prev,
          [curr]: deleteField(),
        }
      }
      return {
        ...prev,
        [curr]: true,
      }
    }, {})

  return setDoc(docRef, { ...parsedMarkedPhotoIds }, { merge: true })
    .then(() => {
      console.log(`Marked photos were updated in gallery ${galleryId}!`)
    })
    .catch(error => {
      logError(error, `Error updating marked photos in gallery with id ${galleryId}: ${error}`)
      throw error
    })
}
