import React from 'react'

import { Image } from 'components/Image'
import { Text } from 'components/Text'

import { t } from 'services/i18n'

import './styles.scss'

export const WatermarkPin = ({ watermark }) => {
  return (
    <div className='watermarks-pin'>
      <Image
        alt={`watermark ${watermark.id}`}
        className='watermarks-pin__image'
        thumbnailsSpecs={[
          { size: 's', media: '(max-width: 600px)' },
          { size: 'm', media: '(max-width: 960px)' },
          { size: 's', media: '(max-width: 1280px)' },
          { size: 'm', media: '(min-width: 1280px)' },
        ]}
        minHeight={120}
        generateThumbnailIfError
        wrapperClassName='watermarks-pin__image-wrapper'
        {...watermark.image}
      />
      <div>
        <Text bold>{watermark.name}</Text>
      </div>
      {watermark.options?.position && (
        <div>
          <Text type='body2' color='text__secondary-color'>
            {
              `${t(`components.watemark_picker.position.${watermark.options.position}`)}${watermark.options.repeat ? ' | ' + t('components.watemark_picker.position.repeat') : ''}`
            }
          </Text>
        </div>
      )}
    </div>
  )
}
