import React from 'react'
import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { createBlogLink } from 'services/routingService'

import './styles.scss'

const PostCard = ({ slug, img, title, tags }) => {
  return (
    <div className='postCard'>
      <Link href={createBlogLink({ slug })} className='postCard__link'>
        <Image src={img} className='postCard__link__img' />
        <div className='postCard__link__text'>
          <p className='postCard__link__text__title'>{title}</p>
          <div className='postCard__tags-container'>
            {tags.map(tag => (
              <div key={tag} className='postCard__tags-container__tag'>
                {tag}
              </div>
            ))}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PostCard
