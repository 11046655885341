export const products = {
  'object': 'list',
  'data': [
    {
      'id': 'prod_LYAzbZOulW0YJu',
      'object': 'product',
      'active': true,
      'attributes': [],
      'created': 1648758967,
      'description': 'O plano ideal para ti se és um PLOTU professional a sério',
      'images': ['https://files.stripe.com/links/MDB8YWNjdF8xSkZQd2pCSzJSOXptdG91fGZsX3Rlc3RfamU5Z291MjlVd2NWamhzMG82UVY3cW9a00wgsyMIKd'],
      'livemode': false,
      'metadata': {
        'xpto': 'xpto-value',
      },
      'name': 'Plano PLOTU PRO +',
      'package_dimensions': null,
      'shippable': null,
      'statement_descriptor': null,
      'tax_code': null,
      'type': 'service',
      'unit_label': null,
      'updated': 1649099265,
      'url': null,
    },
    {
      'id': 'prod_LYAyrsKQzzoIRZ',
      'object': 'product',
      'active': true,
      'attributes': [],
      'created': 1626814954,
      'description': 'Packs publicados ilimitados; 10 GB de armazenamento; Galerias Ilimitadas; PLOTUStudio; Estatísticas (brevemente)',
      'images': [],
      'livemode': false,
      'metadata': {},
      'name': 'Plano PLOTU PRO Base',
      'package_dimensions': null,
      'shippable': null,
      'statement_descriptor': null,
      'tax_code': null,
      'type': 'service',
      'unit_label': null,
      'updated': 1648758936,
      'url': null,
    },
    {
      'id': 'prod_LYAxawHXIXJARv',
      'object': 'product',
      'active': true,
      'attributes': [],
      'created': 1626814874,
      'description': 'Plano inicial, GRÁTIS',
      'images': [],
      'livemode': false,
      'metadata': {},
      'name': 'Plano PLOTU Start',
      'package_dimensions': null,
      'shippable': null,
      'statement_descriptor': null,
      'tax_code': null,
      'type': 'service',
      'unit_label': null,
      'updated': 1648758847,
      'url': null,
    },
  ],
  'has_more': false,
  'url': '/v1/products',
}
