// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isIOSWebApp = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

// https://stackoverflow.com/questions/6031412/detect-android-phone-via-javascript-jquery/28236817
export const isAndroid = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf('android') > -1
}

export const isAndroidMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf('android') > -1 && userAgent.indexOf('mobile')
}

export const isStandaloneWebApp = () => window.navigator.standalone
