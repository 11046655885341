import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import React from 'react'

import { Icon } from 'components/Icon'
import { Link } from 'components/Link'

import { createSearchLink } from 'services/routingService'

import cssVariables from 'styles/theme/colors.scss'

import './styles.scss'
import { SEARCH_GROUP_IDS } from 'utils/search'

export const CategorySearchResultItemSkeleton = props => {
  return (
    <div className={classNames('category-search-result-item', {
      'category-search-result-item--gallery': props.selectedViewId === 'gallery',
    })}
    >
      <Skeleton animation='wave' variant='circular' width={80} height={80} />
      <div className={classNames('category-search-result-item__content', {
        'category-search-result-item__content--gallery': props.selectedViewId === 'gallery',
      })}
      >
        <Skeleton animation='wave' variant='rectangular' width={240} height={25} />
      </div>
    </div>
  )
}

export const CategorySearchResultItem = props => {
  return (
    <div className={classNames('category-search-result-item', {
      'category-search-result-item--gallery': props.selectedViewId === 'gallery',
    }, props.className)}
    >
      <Link href={createSearchLink(SEARCH_GROUP_IDS.PACKS, props.title)}>
        <Icon backgroundColor={cssVariables.lightGrey} size={80}>char_#</Icon>
      </Link>
      <div className={classNames('category-search-result-item__content', {
        'category-search-result-item__content--gallery': props.selectedViewId === 'gallery',
      })}
      >
        <Link href={createSearchLink(SEARCH_GROUP_IDS.PACKS, props.title)} underlined>
          <h2 className='category-search-result-item__content__title'>{props.title}</h2>
        </Link>
      </div>
    </div>
  )
}
