import moment from 'moment'
import 'moment/locale/pt'

moment.locale('pt')

export const currentDateText = date => moment(date).calendar()

export const now = () => moment()

/**
 * Checks if date1 is after date2
 * If date2 is undefined, the current date is used
 * If offset is provided, it's first added to date2
 * @param {*} date1
 * @param {*} date2
 * @param {*} offset offset in milliseconds to be added to date2
 */
export const isGreaterThan = (date1, date2, offset1 = 0, offset2 = 0) =>
  moment(date1).add(offset1, 'ms').isAfter(moment(date2).add(offset2, 'ms'))

export const weekOfMonth = (date = moment()) =>
  date.week() - moment(date).startOf('month').week() + 1

export const getDurationFromMilis = duration => {
  const momentDuration = moment.duration(duration)
  const durationH = momentDuration.hours()
  const durationM = momentDuration.minutes()
  return durationH > 0 ? `${durationH}h${durationM}min` : `${durationM}min`
}

export const getFullDurationFromMilis = duration => {
  const d = moment.duration(duration, 'milliseconds')
  const hours = Math.floor(d.asHours())
  const mins = d.minutes()
  const secs = d.seconds()
  return `${hours > 0 ? `${hours}h` : ''}${mins > 0 ? `${mins}m` : ''}${secs > 0 ? `${secs}s` : ''}`
}
