import Skeleton from '@mui/material/Skeleton'
import React from 'react'

import './styles.skeleton.scss'

export const UserDetailsSkeleton = () => {
  return (
    <div className='user-details-skeleton'>
      <div className='user-details-skeleton__header'>
        <div className='user-details-skeleton__header__content'>
          <Skeleton animation='wave' variant='circular' width={160} height={160} />
          <div className='user-details-skeleton__header__content--text'>
            <Skeleton animation='wave' variant='rectangular' width='100%' height='100%' />
          </div>
        </div>
      </div>
      <div className='user-details-skeleton__main'>
        <div className='user-details-skeleton__main--left'>
          <Skeleton animation='wave' variant='rectangular' width='50%' height='80%' />
        </div>
        <div className='user-details-skeleton__main--right'>
          <div className='user-details-skeleton__main__box'>
            <Skeleton animation='wave' variant='rectangular' width='100%' height='100%' />
          </div>
          <div className='user-details-skeleton__main__box'>
            <Skeleton animation='wave' variant='rectangular' width='100%' height='100%' />
          </div>
          <div className='user-details-skeleton__main__box'>
            <Skeleton animation='wave' variant='rectangular' width='100%' height='100%' />
          </div>
        </div>
      </div>
    </div>
  )
}
