export const MOCK_PHOTOGRAPHER = {
  exhaustiveNbHits: true,
  hits: [
    {
      name: 'Photographer 2',
      numberOfJobs: 2,
      numberOfLikes: 5,
      numberOfPacks: 2,
      objectID: '1218010',
      pro: true,
      totalNumberOfComments: 3,
      _highlightResult: {
        name: {
          fullyHighlighted: false,
          matchLevel: 'full',
          matchedWords: ['ph'],
          value: '<em>Ph</em>otographer 2',
        },
      },
    },
    {
      name: 'Photographer 1',
      numberOfJobs: 1,
      numberOfLikes: 3,
      numberOfPacks: 1,
      objectID: '1217990',
      pro: false,
      totalNumberOfComments: 2,
      _highlightResult: {
        name: {
          fullyHighlighted: false,
          matchLevel: 'full',
          matchedWords: ['ph'],
          value: '<em>Ph</em>otographer 1',
        },
      },
    },
  ],
  hitsPerPage: 20,
  index: 'dev_Photographers',
  nbHits: 2,
  nbPages: 1,
  page: 0,
  params: 'query=ph&',
  processingTimeMS: 1,
  query: 'ph',
  queryAfterRemoval: 'ph',
}
