import classNames from 'classnames'
import { nanoid } from 'nanoid'
import React, { useRef, useEffect, useState } from 'react'

import { LoadingComponent } from 'components/LoadingComponent'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver'

import { generateThumbnailsFunctionUrl, getImageV2DataFromSrc } from 'services/firebaseServer'

import { logError } from 'utils/errorCapture'

import './styles.scss'

const IMAGE_STATUS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  RETRYING_THUMBNAIL: 'RETRYING_THUMBNAIL',
  FAILED_FIRST_TIME: 'FAILED_FIRST_TIME',
  FAILED: 'FAILED',
}

const DEFAULT_FALLBACK_SRC = 'https://upload.wikimedia.org/wikipedia/commons/1/16/No_image_available_450_x_600.svg'

const THUMBNAIL_SUFIX = '__thumb_'

const THUMBNAILS_INITIATED_TIME_THRESHOLD = 30000 // 30 seconds in milliseconds

const V2_IMAGE_SUFIX = '_v2'

const sizeToLegacyWidthMapper = {
  xs: 256,
  s: 512,
  m: 1024,
  l: 1024,
}

const sizePriority = ['xs', 's', 'm', 'l']

const imageIsV2 = src => src.includes(V2_IMAGE_SUFIX)

// TODO: optimize and create tests
const getThumbnailSrc = (src, size, lazyLoaded, intersected, imageStatus) => {
  if (!src || !size) {
    return src
  }

  if (lazyLoaded && !intersected) {
    return null
  }

  const isV2Image = imageIsV2(src)

  if (isV2Image) {
    const urlBuilder = new URL(src)
    const pathName = urlBuilder.pathname
    const urlSplitted = pathName.split('.')
    const thumbnailPath = THUMBNAIL_SUFIX + size
    urlSplitted[urlSplitted.length - 2] = urlSplitted[urlSplitted.length - 2] + thumbnailPath
    urlBuilder.pathname = urlSplitted.join('.')

    if (imageStatus === IMAGE_STATUS.RETRYING_THUMBNAIL) {
      const rdm4Digit = Math.floor(1000 + Math.random() * 9000)
      urlBuilder.searchParams.append('retry', rdm4Digit)
    }

    return urlBuilder.href
  }

  // create legacy thumbnails src
  let urlBuilder
  try {
    urlBuilder = new URL(src)
  } catch (err) {
    return src
  }
  const pathName = urlBuilder.pathname
  const urlSplitted = pathName.split('.')
  const thumbnailData = THUMBNAIL_SUFIX + sizeToLegacyWidthMapper[size]
  urlSplitted[urlSplitted.length - 2] = urlSplitted[urlSplitted.length - 2] + thumbnailData
  urlBuilder.pathname = urlSplitted.join('.')

  if (imageStatus === IMAGE_STATUS.RETRYING_THUMBNAIL) {
    const rdm4Digit = Math.floor(1000 + Math.random() * 9000)
    urlBuilder.searchParams.append('retry', rdm4Digit)
  }

  return urlBuilder.href
}

const getSrc = (lazyLoaded, intersected, imageStatus, src, fallbackSrc) => {
  if ((lazyLoaded && intersected) || !lazyLoaded) {
    // if image is already in failed state, let's display the fallback image
    if (imageStatus === IMAGE_STATUS.FAILED || src == null) {
      return fallbackSrc || DEFAULT_FALLBACK_SRC
    }
    return src
  }

  // if lazy loading is enabled but the image was not intersected yet, let's display a low quality thumbnail
  if (lazyLoaded && !intersected) {
    return getThumbnailSrc(src, 'xs')
  }

  return null
}

export const SimpleImage = ({
  src,
  alt,
  withFallback,
  thumbnailsSpecs,
  fallbackSrc,
  onImageError,
  className,
  pictureClassName,
  imageClassName,
  lazyLoaded,
  generateThumbnailIfError,
  ...otherProps
}) => {
  const imageElmt = useRef(null)
  const [ thumbnailGenerated, setThumbnailGenerated ] = useState(false)
  const [ imageStatus, setImageStatus ] = useState(IMAGE_STATUS.LOADING)
  const [observeElement] = useIntersectionObserver()
  const imageId = useRef(nanoid())
  const [ intersected, setIntersected ] = useState(false)
  const [ thumbnailRetryCount, setThumbnailRetryCount ] = useState(0)
  const [ creatingThumbnails, setCreatingThumbnails ] = useState(false)

  useEffect(() => {
    setImageStatus(IMAGE_STATUS.LOADING)
  }, [src])

  useEffect(() => {
    if (imageElmt.current && lazyLoaded) {
      observeElement(imageElmt.current, imageId.current, () => setIntersected(true))
    }
  }, [lazyLoaded, observeElement])

  useEffect(() => {
    const imageRef = imageElmt.current

    if (!imageRef) {
      return
    }

    const onImgError = async event => {
      // if error is because we're displaying the 256 thumbnail as a lazyloading first image, we should ignore it
      if (lazyLoaded && !intersected) {
        return
      }

      // TODO: If image is version v2 and there was an error, try to find from the URL if the thumbnails are still loading, by querying the database
      const isV2Image = imageIsV2(src)

      // let's try again one more time fetching the original image
      if (isV2Image) {
        if (imageStatus !== IMAGE_STATUS.FAILED_FIRST_TIME) {
          if (generateThumbnailIfError && !thumbnailGenerated) {
            // TODO: IMPLEMENT CACHING
            // Note: This data is not being persisted in the local storage. That means if we change pages or reload quickly, the requests might be duplicated.
            // However, if we use normally, I don't think this will be a problem since the thumbnails don't rely on the local storage
            const imageV2Data = await getImageV2DataFromSrc(src)
            let forceReload = false

            // if by any chance the thumbnails were created in the meantime, we need to ignore the error
            if (!imageV2Data || (imageV2Data?.thumbnails?.xs && imageV2Data?.thumbnails?.s)) {
              if (!imageV2Data) {
                return
              }

              if (imageStatus !== IMAGE_STATUS.RETRYING_THUMBNAIL) {
                // if image has thumbnails after all, let's try to ignore the error
                // however, we should set the image as FAILED_FIRST_TIME so that the thumbnails reload again.
                // if after the reload it has another error, we should trigger the thumbnails creation anyway
                // not sure why, but this can happen with some watermarks thumbnails
                setImageStatus(IMAGE_STATUS.RETRYING_THUMBNAIL)
                return
              }

              forceReload = true
            }

            const thumbnailsAreLoading = Boolean(imageV2Data.thumbnails?.loading)
            const thumbnailsHaveError = Boolean(imageV2Data.thumbnails?.error)
            const thumbnailsAreInitiating =
              imageV2Data.thumbnails?.initiated && imageV2Data.thumbnails?.loading === undefined // image was uploaded but Firebase function that handles thumbnails didn't start yet
            const thumbnailsAreInitiatedOrLoading =
              imageV2Data?.thumbnails && (thumbnailsAreInitiating || thumbnailsAreLoading)

            if (thumbnailsAreInitiatedOrLoading || thumbnailsHaveError || forceReload) { // check if thumbnails were initialised but there's no thumbnails yet
              const initiatedMSAgo = Date.now() - imageV2Data.thumbnails.initiated
              const initiatedTooLongAgo = initiatedMSAgo >= THUMBNAILS_INITIATED_TIME_THRESHOLD
              const loadingForMS = Date.now() - imageV2Data.thumbnails.loading
              const loadingForTooLong = loadingForMS >= THUMBNAILS_INITIATED_TIME_THRESHOLD

              if (initiatedTooLongAgo || loadingForTooLong || thumbnailsHaveError) {
                // thumbnails creation process was initiated too long ago. We should initiate the process again
                setCreatingThumbnails(true)
                generateThumbnailsFunctionUrl(src)
                  .then(() => {
                    setCreatingThumbnails(false)
                    setThumbnailGenerated(true)
                    setImageStatus(IMAGE_STATUS.RETRYING_THUMBNAIL)
                  })
                  .catch(() => {})
              } else {
                setCreatingThumbnails(true)
                // if thumbnails creation process was initiated just a while ago, let's keep trying in a few seconds
                setTimeout(() => {
                  setCreatingThumbnails(false)
                  setImageStatus(IMAGE_STATUS.RETRYING_THUMBNAIL)
                  setThumbnailRetryCount(thumbnailRetryCount + 1) // updating the retry count only to rerender and request the image again (only used for that at least for now)
                }, 1500) // at max, this logic should run for THUMBNAILS_INITIATED_TIME_THRESHOLD
              }
              return
            } else if (imageV2Data && imageV2Data.thumbnails === undefined) {
              setCreatingThumbnails(true)
              generateThumbnailsFunctionUrl(src) // probably there was an error because thumbnails were not created. We should create it and then reload the image once again
                .then(() => {
                  setCreatingThumbnails(false)
                  setThumbnailGenerated(true)
                  setImageStatus(IMAGE_STATUS.RETRYING_THUMBNAIL)
                })
                .catch(() => {})
              return
            }
          }
          // if nothing above works, we should log the failed image
        }
      } else {
        if (imageStatus !== IMAGE_STATUS.FAILED_FIRST_TIME) {
          setImageStatus(IMAGE_STATUS.FAILED_FIRST_TIME)
          if (generateThumbnailIfError && !thumbnailGenerated) {
            generateThumbnailsFunctionUrl(src) // probably there was an error because thumbnails were not created. We should create it and then reload the image once again
              .then(() => {
                setThumbnailGenerated(true)
                setImageStatus(IMAGE_STATUS.RETRYING_THUMBNAIL)
              })
              .catch(() => {})
          }
          return
        }
      }

      // if none of the retries worked, we need to stop trying and do not show anything
      logError(new Error(event.error), `Image with src: ${src} failed download and has no fallback`)
      setImageStatus(IMAGE_STATUS.FAILED)
      onImageError && onImageError()
    }

    imageRef.addEventListener('error', onImgError)

    return () => {
      imageRef.removeEventListener('error', onImgError)
    }
  }, [
    generateThumbnailIfError,
    imageStatus,
    intersected,
    lazyLoaded,
    onImageError,
    src,
    thumbnailGenerated,
    thumbnailsSpecs,
    thumbnailRetryCount,
  ])

  if ((imageStatus === IMAGE_STATUS.FAILED || !src) && !withFallback & !fallbackSrc) {
    return null
  }

  // disable thumbnails when there is any error in the image
  const thumbnailsEnabled = thumbnailsSpecs &&
    imageStatus !== IMAGE_STATUS.FAILED_FIRST_TIME && imageStatus !== IMAGE_STATUS.FAILED

  return (
    <picture className={classNames(className, pictureClassName)}>
      {thumbnailsEnabled && (thumbnailsSpecs || [])
        .sort((thumbSpec1, thumbSpec2) => sizePriority.indexOf(thumbSpec2) - sizePriority.indexOf(thumbSpec1))
        .map(({ size, ...thumbnailProps }) => (
          <source
            key={size + thumbnailProps.media}
            srcSet={getThumbnailSrc(src, size, lazyLoaded, intersected, imageStatus)}
            {...thumbnailProps}
          />
        ))}
      <img
        ref={imageElmt}
        data-id={imageId.current}
        {...otherProps}
        className={classNames(className, imageClassName)}
        src={getSrc(lazyLoaded, intersected, imageStatus, src, fallbackSrc)}
        alt={imageStatus === IMAGE_STATUS.FAILED ? 'No image available' : alt}
      />
      {creatingThumbnails && (
        <LoadingComponent
          isLoading
          size={20}
          className='simple-image__thumbnails-loading'
        />
      )}
    </picture>
  )
}
