import TextField from '@mui/material/TextField'
import React from 'react'

import { Button } from 'components/Button'

import { Comment } from '../Comment'

import { currentDateText } from './../../utils/date'

import './styles.scss'

export const CommentSection = props => {
  return (
    <div className='comment-section'>
      {props.comments?.map(comment => {
        return (
          <div key={`${comment.user.name}-${comment.date}`} className='comment-section__comment'>
            <Comment
              date={currentDateText(comment.date)}
              user={{
                name: comment.user.name,
                avatar: comment.user.avatar,
              }}
              comment={comment.review}
            />
            {comment.subComments && comment.subComments.map(subComment => (
              <div key={subComment.id} className='comment-section__sub-comment'>
                <Comment
                  date={currentDateText(subComment.date)}
                  user={{
                    name: subComment.user.name,
                    avatar: subComment.user.avatar,
                  }}
                  comment={subComment.comment}
                />
              </div>
            ))}
          </div>
        )
      })}
      <form>
        <TextField
          placeholder='Escreve a tua review'
          className='comment-section__comment-input'
          multiline
          id='comment-input'
          variant='outlined'
        />
        <Button
          type='submit'
          className='comment-section__comment-submit'
          variant='outlined'
          color='default'
          href='#message-box'
          onClick={() => { alert('review enviada') }}
        >
          Enviar
        </Button>
      </form>
    </div>
  )
}
