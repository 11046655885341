export const ENVIRONMENTS = {
  MOCK: 'mock',
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
}

export const isMock = () =>
  process.env.REACT_APP_STAGE === ENVIRONMENTS.MOCK

export const isLocal = () =>
  process.env.REACT_APP_STAGE === ENVIRONMENTS.LOCAL

export const isDevelopment = () =>
  process.env.REACT_APP_STAGE === ENVIRONMENTS.DEVELOPMENT
export const isDevelopmentOrLess = () =>
  process.env.REACT_APP_STAGE === ENVIRONMENTS.DEVELOPMENT ||
    process.env.REACT_APP_STAGE === ENVIRONMENTS.LOCAL ||
    process.env.REACT_APP_STAGE === ENVIRONMENTS.MOCK

export const isProduction = () =>
  process.env.REACT_APP_STAGE === ENVIRONMENTS.PRODUCTION
export const isProductionOrLess = () =>
  process.env.REACT_APP_STAGE === ENVIRONMENTS.MOCK ||
    process.env.REACT_APP_STAGE === ENVIRONMENTS.DEVELOPMENT ||
    process.env.REACT_APP_STAGE === ENVIRONMENTS.PRODUCTION

