import React, { useEffect, useState } from 'react'

import { LoadingComponent } from 'components/LoadingComponent'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'

import { t } from 'services/i18n'
import { getUser } from 'services/serverBridge'

import './styles.scss'

const MAX_ATTEMPTS = 6
const INTERVAL_BETWEEN_ATTEMPTS = 750
const MAX_INTERVAL_BETWEEN_ATTEMPTS = 5000

// returns 750ms, 1500ms, 3000ms, 4500ms, 5000ms, 5000ms, etc
const getNextInterval = currentAttempts => {
  return Math.min(currentAttempts * 2 * INTERVAL_BETWEEN_ATTEMPTS, MAX_INTERVAL_BETWEEN_ATTEMPTS)
}

// This modal is used to show a success feedback to the user when a Plan is chosen with success
// This modal communicates with the backend to confirm the planId & priceId received in the URL parameters so that the user doesn't have to manually wait and reload the page until the plan is updated
export const PaymentSuccessModal = ({ open, closeModal, userId, planId, priceId }) => {
  const [attempts, setAttempts] = useState(0)
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newPlan, setNewPlan] = useState()

  useEffect(() => {
    if (!planId || !priceId || !loading) {
      return
    }

    if (attempts >= MAX_ATTEMPTS) {
      setHasError(true)
      setLoading(false)
      return
    }

    getUser(userId)
      .then(userData => {
        if (userData.plan?.priceId === priceId && userData.plan?.id === planId) {
          setNewPlan(userData.plan)
          setLoading(false)
        } else {
          const nextInterval = getNextInterval(attempts)
          setTimeout(() => setAttempts(attempts + 1), nextInterval)
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempts, planId, priceId, userId])

  const onCloseModal = () => closeModal(newPlan)

  const reloadPage = () => window.location.reload()

  if (!planId || !priceId) {
    return null
  }

  return (
    <Modal
      open={open}
      title={t('profile_settings.account_settings.payment_success_modal.modal_title')}
      closeModal={onCloseModal}
      actionButton={{
        text: hasError ? 'Atualizar página' : 'OK',
        disabled: loading,
        onClick: hasError ? reloadPage : onCloseModal,
      }}
    >
      <LoadingComponent isLoading={loading} />
      {!loading && !hasError && (
        <div>
          <Text as='h2' type='subtitle2' className='payment-success-modal__title'>{t('profile_settings.account_settings.payment_success_modal.title')}</Text>
          <Text as='p' type='body'>{t('profile_settings.account_settings.payment_success_modal.text')}</Text>
        </div>
      )}
      {hasError && (
        <div>
          <Text as='h2' type='subtitle2' className='payment-success-modal__title'>{t('profile_settings.account_settings.payment_success_modal.title_error')}</Text>
          <Text as='p' type='body'>{t('profile_settings.account_settings.payment_success_modal.text_error')}</Text>
        </div>
      )}
    </Modal>
  )
}
