import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useImageInput } from 'hooks/useImageInput'

import './styles.scss'

const DEFAULT_ACCEPT_OPTIONS = '.jpg, .png, .jpeg, .gif, .bmp'

export const ImageInput = props => {
  const [ images, onImageChoose ] = useImageInput(!props.multiple, props.initialImages)
  const [ inputIsFocused, setInputIsFocused ] = useState(false)

  useEffect(() => {
    if (props.multiple) {
      props.onChange && props.onChange(images)
    } else if (images[0] !== undefined) {
      props.onChange && props.onChange(images[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, props.multiple])

  const onInputFocus = value => () => setInputIsFocused(value)

  return (
    <div className={classNames('images-input', props.classNameWrapper, {
      [props.classNameWrapperFocused]: inputIsFocused,
    })}
    >
      <label htmlFor='image-upload' className={classNames('images-input__label', props.className)}>
        {props.children}
      </label>
      <input
        onFocus={onInputFocus(true)}
        onBlur={onInputFocus(false)}
        onChange={onImageChoose}
        id='image-upload'
        type='file'
        multiple={!!props.multiple}
        // TODO: support .tiff images: accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"> or accept="image/*"> ==> check if already supported by Chrome. See https://conceptclarity.ca/display-tiff-files-web/
        accept={props.acceptOptions ?? DEFAULT_ACCEPT_OPTIONS}
      />
    </div>
  )
}
