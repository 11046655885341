import { useCallback, useEffect, useRef, useState } from 'react'
import { throttle } from 'underscore/modules/index'

const getScreenY = event => {
  if (event instanceof TouchEvent) {
    return event.touches[0].screenY
  } else {
    return event.screenY
  }
}

const PERCENTAGE_OF_SCROLL_THRESHOLD = 0.2
const QUICK_GESTURE_TIMING_THRESHOLD_MS = 200
const QUICK_GESTURE_MINIMUM_SWIPE_MS = 20

// this is not using object as props to ease the creation of listeners
export const useSwipeToDismissModal = (
  ref,
  onDismiss,
  isVisible,
  swipeIsEnabled,
  alwaysEnabledNodes,
  handleDesktop = false
) => {
  const node = ref?.current

  const pressedPosition = useRef(null)
  const pressedTimestamp = useRef(null)

  const verticalOffsetPosition = useRef(0)
  const transitioning = useRef(false)
  const [opacity, setOpacity] = useState(1)

  const remove = useCallback(() => {
    transitioning.current = false
    verticalOffsetPosition.current = node.offsetHeight
    setOpacity(0)
    onDismiss()
  }, [onDismiss, node])

  const onMouseUp = useCallback(() => {
    if (!node) {
      return
    }

    const heightThreshold = node.offsetHeight * PERCENTAGE_OF_SCROLL_THRESHOLD
    if (verticalOffsetPosition.current >= heightThreshold){
      remove()
      return
    }

    // if user does a quick swipe gesture, let's consider it too
    const nowTimestamp = Date.now()
    if (
      nowTimestamp - pressedTimestamp.current <= QUICK_GESTURE_TIMING_THRESHOLD_MS &&
      verticalOffsetPosition.current >= QUICK_GESTURE_MINIMUM_SWIPE_MS
    ) {
      remove()
      return
    }

    pressedPosition.current = null
    pressedTimestamp.current = null
    verticalOffsetPosition.current = 0
    transitioning.current = false
    setOpacity(1)

  }, [node, remove])

  const onMouseMove = useCallback(event => {
    if (!node) {
      return
    }

    const screenY = getScreenY(event)

    // prevent modal scroll when moving it to dismiss
    if (transitioning.current && screenY > pressedPosition.current) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (pressedPosition.current) {
      const newVerticalOffsetPosition = screenY - pressedPosition.current
      verticalOffsetPosition.current = Math.max(0, newVerticalOffsetPosition)
      setOpacity((100 - (newVerticalOffsetPosition * 100 / (node.offsetHeight * 2))) / 100)
    }
  }, [node])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledOnMouseMove = useCallback(throttle(onMouseMove, 25), [onMouseMove])

  const onMouseDown = useCallback(event => {
    if (!node || (!swipeIsEnabled && !alwaysEnabledNodes.some(node => event.target === node))) {
      return
    }

    pressedTimestamp.current = Date.now()
    pressedPosition.current = getScreenY(event)
    verticalOffsetPosition.current = 0
    transitioning.current = true
    setOpacity(1)
  }, [node, swipeIsEnabled, alwaysEnabledNodes])

  useEffect(() => {
    if (node) {
      if (handleDesktop) {
        node.addEventListener('mousedown', onMouseDown)
        node.addEventListener('mouseup', onMouseUp)
        node.addEventListener('mousemove', throttledOnMouseMove)
      }

      node.addEventListener('touchstart', onMouseDown)
      node.addEventListener('touchend', onMouseUp)
      node.addEventListener('touchmove', throttledOnMouseMove)
    }

    return () => {
      if (node) {
        if (handleDesktop) {
          node.removeEventListener('mousedown', onMouseDown)
          node.removeEventListener('mouseup', onMouseUp)
          node.removeEventListener('mousemove', throttledOnMouseMove)
        }

        node.removeEventListener('touchstart', onMouseDown)
        node.removeEventListener('touchend', onMouseUp)
        node.removeEventListener('touchmove', throttledOnMouseMove)
      }
    }
  }, [node, handleDesktop, onMouseDown, onMouseUp, throttledOnMouseMove])

  useEffect(() => {
    if (isVisible) {
      pressedPosition.current = null
      pressedTimestamp.current = null
      verticalOffsetPosition.current = 0
      transitioning.current = false
      setOpacity(1)
    }
  }, [isVisible])

  return transitioning.current
    ? {
      transform: `translateY(${verticalOffsetPosition.current}px)`,
      opacity,
      transition: transitioning ? 'none' : undefined,
    }
    : {}
}
