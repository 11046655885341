// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app'

import { getConfigValue } from 'services/configService'
import { isLocal } from 'utils/envs'

import { connectAuthEmulator, getAuth as getFirebaseAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage as getFirebaseStorage } from 'firebase/storage'
import { connectFunctionsEmulator, getFunctions as getFirebaseFunctions } from 'firebase/functions'
import { getAnalytics as getFirebaseAnalytics } from 'firebase/analytics'

export * from './users'
export * from './packs'
export * from './agendas'
export * from './galleries'
export * from './watermarks'
export * from './imagesStorage'

const FirebaseServerService = (function () {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
    authDomain: getConfigValue('general.endpoints.firebase.auth_domain'),
    databaseURL: getConfigValue('general.endpoints.firebase.database_url'),
    projectId: getConfigValue('general.endpoints.firebase.project_id'),
    storageBucket: getConfigValue('general.endpoints.firebase.storage_bucket'),
    messagingSenderId: getConfigValue('general.endpoints.firebase.messaging_sender_id'),
    appId: getConfigValue('general.endpoints.firebase.app_id'),
    measurementId: getConfigValue('general.endpoints.firebase.measurement_id'),
  }

  var firebaseService

  return {
    initService: () => {
      const firebaseApp = initializeApp(firebaseConfig)

      // this is overriding the firebase host so it cannot be done when useEmulator is used
      initializeFirestore(firebaseApp, {
        ignoreUndefinedProperties: true,
      })

      firebaseService = {
        app: firebaseApp,
        database: getFirestore(firebaseApp),
        storage: getFirebaseStorage(firebaseApp),
        analytics: getFirebaseAnalytics(firebaseApp),
        functions: getFirebaseFunctions(firebaseApp),
        auth: getFirebaseAuth(firebaseApp),
      }

      if (isLocal()) {
        connectFirestoreEmulator(firebaseService.database, 'localhost', 5002)
        connectStorageEmulator(firebaseService.storage, 'localhost', 9199)
        connectFunctionsEmulator(firebaseService.functions, 'localhost', 5001)
        connectAuthEmulator(firebaseService.auth, 'http://localhost:9099')
      }
    },

    getInstance: () => {
      if (firebaseService === undefined) {
        this.initService()

        return firebaseService
      }

      return firebaseService
    },
  }
})()

export const initializeRemoteServerService = () => {
  FirebaseServerService.initService()
}

export const getFirebaseApp = () => {
  return FirebaseServerService.getInstance().app
}

export const getDatabase = () => {
  return FirebaseServerService.getInstance().database
}

export const getStorage = () => {
  return FirebaseServerService.getInstance().storage
}

export const getAnalytics = () => {
  return FirebaseServerService.getInstance().analytics
}

export const getFunctions = () => {
  return FirebaseServerService.getInstance().functions
}

export const getAuth = () => {
  return FirebaseServerService.getInstance().auth
}
