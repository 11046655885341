import classNames from 'classnames'
import React from 'react'

import { ContainedImage } from '../ContainedImage'
import { SimpleImage } from '../SimpleImage'

import './styles.scss'

const DEFAULT_GRADIENT_TO = 'bottom'
const DEFAULT_GRADIENT_FROM_COLOR = 'black'
const DEFAULT_GRADIENT_TO_COLOR = 'rgba(255, 255, 255, 0.25)'

const renderImage = props => {

  if (props.width && props.height) {
    return <ContainedImage className={props.imgClassName} {...props} />
  }

  return <SimpleImage className={props.imgClassName} {...props} />
}

export const ImageWithGradient = props => {
  const { className, gradient, ...otherProps } = props

  const { to, fromColor, toColor } = gradient

  const gradientStyle = {
    backgroundImage: `linear-gradient(to ${to || DEFAULT_GRADIENT_TO}, ${fromColor || DEFAULT_GRADIENT_FROM_COLOR}, ${toColor || DEFAULT_GRADIENT_TO_COLOR})`,
  }

  return (
    <div className={classNames('image-with-gradient', className)}>
      <div style={gradientStyle} className='image-with-gradient__gradient' />
      {renderImage(otherProps)}
    </div>
  )
}
