import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'

import { IconButton } from 'components/Button'
import { Text } from 'components/Text'

import './styles.scss'

const NotificationSlide = () => props => <Slide {...props} direction='left' />

/**
 * Component to display a notification.
 * It can be a simple message, or it can have a type associated.
 * If a type is associated, a small icon is displayed along with the background color associated with the type
 * @param {
 *  open: boolean,
 *  message: string,
 *  handleClose: () => {},
 *  withAnimation: boolean,
 *  style: {[styleKey]: style},
 *  type: string => error | warning | info | success
 * } param0 parameters
 * @returns Notification component
 */
export const Notification = ({ open, message, handleClose, withAnimation, style, type }) => {
  return (
    <Snackbar
      className='notification'
      style={style}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      TransitionComponent={
        withAnimation
          ? NotificationSlide
          : undefined
      }
      ContentProps={{
        className: 'notification__content',
      }}
      message={type ? null : message}
      action={(
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={handleClose}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
    >
      {type && (
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          <Text>{message}</Text>
        </Alert>
      )}
    </Snackbar>
  )
}
