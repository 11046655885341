const galleries = {
  'eiqwdcbfgfda23sdyuhdjnsa_default': {
    id: 'eiqwdcbfgfda23sdyuhdjnsa_default',
    title: 'Galeria padrão',
    eventId: 'eiqwdcbfgfda23sdyuhdjnsa',
    userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
    sharingOptions: {
      shared: true,
    },
    photos: {
      'photo-id-1': {
        url: 'https://images-na.ssl-images-amazon.com/images/I/61vEwCfFwvL.jpg',
        width: 400,
        height: 800,
      },
      'photo-id-2': {
        url: 'https://i.pinimg.com/originals/99/09/09/990909f8f4b18d0c5612fb624fccafb4.jpg',
        width: 1920,
        height: 1200,
      },
      'photo-id-3': {
        url: 'https://images4.alphacoders.com/206/thumb-350-20658.jpg',
        width: 350,
        height: 219,
      },
      'photo-id-4': {
        url: 'https://images.pexels.com/photos/821736/pexels-photo-821736.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        width: 500,
        height: 749,
      },
      'photo-id-5': {
        url: 'https://c0.wallpaperflare.com/preview/956/681/123/animal-animal-photography-blur-cat.jpg',
        width: 910,
        height: 1365,
      },
      'photo-id-6': {
        url: 'https://c0.wallpaperflare.com/preview/742/7/785/animal-animal-photography-cat-cat-face.jpg',
        width: 910,
        height: 1370,
      },
    },
  },
  'fhbjfuhgjfnkjbdhnk': {
    id: 'fhbjfuhgjfnkjbdhnk',
    title: 'Galeria padrão',
    eventId: 'eiqwdcbfgfda23sdyuhdjnsa',
    userUid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
    photos: [
      {
        url: 'https://images-na.ssl-images-amazon.com/images/I/61vEwCfFwvL.jpg',
        width: 400,
        height: 800,
      },
      {
        url: 'https://i.pinimg.com/originals/99/09/09/990909f8f4b18d0c5612fb624fccafb4.jpg',
        width: 1920,
        height: 1200,
      },
      {
        url: 'https://images4.alphacoders.com/206/thumb-350-20658.jpg',
        width: 350,
        height: 219,
      },
      {
        url: 'https://images.pexels.com/photos/821736/pexels-photo-821736.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        width: 500,
        height: 749,
      },
      {
        url: 'https://c0.wallpaperflare.com/preview/956/681/123/animal-animal-photography-blur-cat.jpg',
        width: 910,
        height: 1365,
      },
      {
        url: 'https://c0.wallpaperflare.com/preview/742/7/785/animal-animal-photography-cat-cat-face.jpg',
        width: 910,
        height: 1370,
      },
    ],
  },
}

export default galleries
