import classNames from 'classnames'
import React from 'react'

import { Grid } from 'components/Grid'
import { Image } from 'components/Image'
import { LoadingWrapper } from 'components/LoadingComponent'

import './styles.scss'

export const CoverPhotoPicker = ({ photosLayout, photos, onCoverPhotoPick, loading, coverPhotoId }) => {
  const handlePhotoClick = photo => () => {
    onCoverPhotoPick(photo)
  }

  return (
    <LoadingWrapper
      isLoading={loading}
    >
      <div>
        <Grid spacing={16} xs={6} sm={3} md={2}>
          {photosLayout.map((photoId, index) => {
            const photo = photos?.[photoId]
            if (photo === undefined) {
              return null
            }

            const isCoverPhoto = coverPhotoId ? photoId === coverPhotoId : index === 0

            return (
              <div
                key={photoId}
                onClick={handlePhotoClick(photo)}
                className={classNames('cover-photo-picker__image-wrapper', {
                  'cover-photo-picker__image-wrapper--cover-photo': isCoverPhoto,
                })}
              >
                <Image
                  className='cover-photo-picker__image'
                  src={photo.src}
                  thumbnailsSpecs={[{ size: 'xs', media: '(min-width: 0px)' }]}
                  sized
                />
              </div>
            )
          })}
        </Grid>
      </div>
    </LoadingWrapper>
  )
}
