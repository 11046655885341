import React, { useCallback, useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'

import { Modal, SimpleMessageModal } from 'components/Modal'
import { Text } from 'components/Text'

import { t } from 'services/i18n'

import { Link } from 'components/Link'
import { useModal } from 'hooks/useModal'
import { CodeInput } from 'components/CodeInput'

import './styles.scss'
import { LoadingButton } from 'components/Button'
import { Alert, Collapse } from '@mui/material'
import { verifyTOTPCode } from 'services/authenticationService'
import { logError } from 'utils/errorCapture'
import { useAlert } from 'hooks/useAlert'

export const ConfigureTOTPModal = ({ open, closeModal, onSucess, totpSecret, totpUri, secretKey }) => {
  const [ QRCodeValue, setQRCodeValue ] = useState(totpUri)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ errorContext, setErrorContext ] = useState('')
  const TOTPVerificationCode = useRef('')
  const onCloseModal = () => closeModal()
  const [
    setupKeyModalIsOpen,
    openSetupKeyModal,
    closeSetupKeyModal,
  ] = useModal()
  const [ successAlertIsOpen, openSuccessAlert ] = useAlert()
  const [ errorAlertIsOpen, openErrorAlert ] = useAlert()

  // useEffect used to prevent qr code quickly disapearing when closing the modal
  useEffect(() => {
    if (totpUri) {
      setQRCodeValue(totpUri)
    }
  }, [totpUri])

  const onChangeCode = useCallback(code => {
    TOTPVerificationCode.current = code
  }, [])

  const handleVerifyTOTPCode = () => {
    const code = TOTPVerificationCode.current
    if (!code || code === '' || code.length < 6) {
      setErrorContext(t('profile_settings.account_settings.totp_modal.errors.invalid-verification-code'))
      openErrorAlert()
      return
    }

    setIsLoading(true)
    verifyTOTPCode(totpSecret, code)
      .then(() => {
        openSuccessAlert()
        onSucess()
      })
      .catch(error => {
        logError(error)

        if (error.code === 'auth/invalid-verification-code') {
          setErrorContext(t('profile_settings.account_settings.totp_modal.errors.invalid-verification-code'))
        } else if (error.code === 'auth/too-many-enrollment-attempts') {
          setErrorContext(t('profile_settings.account_settings.totp_modal.errors.too-many-enrollment-attempts'))
        } else {
          setErrorContext(t('profile_settings.account_settings.totp_modal.errors.generic'))
        }
        openErrorAlert()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal
      open={open}
      title={t('profile_settings.account_settings.totp_modal.title')}
      closeModal={onCloseModal}
      secondaryActionButton={{
        text: 'Cancelar',
        onClick: onCloseModal,
      }}
    >
      <div className='configure-totp-modal__content'>
        <Text as='p' type='body'>{t('profile_settings.account_settings.totp_modal.description')}</Text>
        <Text className='configure-totp-modal__title' as='h3' type='title4'>{t('profile_settings.account_settings.totp_modal.scan_title')}</Text>
        <Text as='p' type='body'>{t('profile_settings.account_settings.totp_modal.scan_description')}</Text>
        <div className='configure-totp-modal__qr-code'>
          {QRCodeValue && <QRCode value={QRCodeValue} />}
        </div>
        <Text as='p' type='body2' color='text__secondary-color'>
          {t('profile_settings.account_settings.totp_modal.scan_unable', {
            components: {
              setupKey: <Link
                underlined
                colored
                onClick={openSetupKeyModal}
              />,
            },
          })}
        </Text>

        <Text className='configure-totp-modal__title' as='h3' type='title4'>{t('profile_settings.account_settings.totp_modal.verify_title')}</Text>

        <div className='configure-totp-modal__code-input-wrapper'>
          <CodeInput
            autoSubmit
            onSubmit={handleVerifyTOTPCode}
            onChangeCode={onChangeCode}
            disabled={isLoading}
          />
          <div>
            <LoadingButton
              variant='contained'
              loading={isLoading}
              onClick={handleVerifyTOTPCode}
            >
              {t('profile_settings.account_settings.totp_modal.verify_button')}
            </LoadingButton>
          </div>
          <Collapse in={successAlertIsOpen}>
            <Alert severity='success'>
              {t('profile_settings.password_settings.alert.sent')}
            </Alert>
          </Collapse>
          <Collapse in={errorAlertIsOpen}>
            <Alert severity='error'>
              {errorContext}
            </Alert>
          </Collapse>
        </div>
      </div>
      <SimpleMessageModal
        open={setupKeyModalIsOpen}
        closeModal={closeSetupKeyModal}
        modalTitle={t('profile_settings.account_settings.totp_modal.setup_key_modal.title')}
        message={secretKey}
      />
    </Modal>
  )
}
