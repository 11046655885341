export const prices = {
  'object': 'list',
  'data': [
    {
      'id': 'price_1KjUiyBK2R9zmtou3Tk77nFZ',
      'object': 'price',
      'active': true,
      'billing_scheme': 'per_unit',
      'created': 1648758968,
      'currency': 'eur',
      'livemode': false,
      'lookup_key': null,
      'metadata': {},
      'nickname': null,
      'product': 'prod_LYAzbZOulW0YJu',
      'recurring': {
        'aggregate_usage': null,
        'interval': 'year',
        'interval_count': 1,
        'trial_period_days': null,
        'usage_type': 'licensed',
      },
      'tax_behavior': 'unspecified',
      'tiers_mode': null,
      'transform_quantity': null,
      'type': 'recurring',
      'unit_amount': 10000,
      'unit_amount_decimal': '10000',
    },
    {
      'id': 'price_1KjUiyBK2R9zmtouewxhtUyH',
      'object': 'price',
      'active': true,
      'billing_scheme': 'per_unit',
      'created': 1648758968,
      'currency': 'eur',
      'livemode': false,
      'lookup_key': null,
      'metadata': {},
      'nickname': null,
      'product': 'prod_LYAzbZOulW0YJu',
      'recurring': {
        'aggregate_usage': null,
        'interval': 'month',
        'interval_count': 1,
        'trial_period_days': null,
        'usage_type': 'licensed',
      },
      'tax_behavior': 'unspecified',
      'tiers_mode': null,
      'transform_quantity': null,
      'type': 'recurring',
      'unit_amount': 1000,
      'unit_amount_decimal': '1000',
    },
  ],
  'has_more': false,
  'url': '/v1/prices',
}
