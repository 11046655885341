import { useEffect, useState } from 'react'
import { EVENTS, ACTIONS, STATUS } from 'react-joyride'

export const ACTION = {
  NEXT: 'next',
  PREV: 'prev',
  FINISHED: 'finished',
  SKIPPED: 'skipped',
}

export const useWalkthrough = (walkthroughData, callback, loading = false, isEnabled = true) => {
  const [ stepIndex, setStepIndex ] = useState(walkthroughData.index || 0)
  const [ isRunning, setIsRunning ] = useState(loading ? false : walkthroughData.done !== true && isEnabled)
  const [ isContinuous, setIsContinuous ] =
    useState(loading ? false : walkthroughData.done !== true && walkthroughData.index === 0) // only run walkthrought automatically when user hasn't yet interacted with it

  useEffect(() => {
    setStepIndex(walkthroughData.index || 0)
    setIsRunning(loading ? false : walkthroughData.done !== true && isEnabled)
    setIsContinuous(loading ? false : walkthroughData.done !== true && walkthroughData.index === 0) // only run walkthrought automatically when user hasn't yet interacted with it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walkthroughData.done, loading]) // only set state if loading or done props are changed. Otherwise, isContinuous is always reset in each step

  const handleJoyrideCallback = data => {
    const { action, index, type, status, lifecycle } = data

    // set isContinue to true if the user re-started the walkthrough in a middle step
    if ((action === ACTIONS.UPDATE && lifecycle === 'tooltip') || (action === ACTIONS.NEXT && lifecycle === 'init')) {
      setIsContinuous(true)
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const newIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      setStepIndex(newIndex)
      callback(data, ACTIONS.PREV ? ACTION.PREV : ACTION.NEXT, newIndex)
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setIsRunning(false)
      const customAction = [STATUS.SKIPPED].includes(status) ? ACTION.SKIPPED : ACTION.FINISHED
      callback(data, customAction, index)
    }

    callback(data, null, index)
  }

  return [stepIndex, isRunning, isContinuous, handleJoyrideCallback]
}
