import ClearIcon from '@mui/icons-material/Clear'
import classNames from 'classnames'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'

import { IconButton, Button } from 'components/Button'
import { ModalActionButtons } from 'components/ModalActionButtons'
import { Text } from 'components/Text'

import { useScrollPosition } from 'hooks/useScrollPosition'
import { useStopScroll } from 'hooks/useStopScroll'
import { useSwipeToDismissModal } from 'hooks/useSwipeToDismissModal'

import './styles.scss'

export const ModalSection = props => {
  if (props.footer) {
    return (
      <div className='modal-footer'>
        <div className='modal-footer__action-buttons'>
          {props.children}
        </div>
      </div>
    )
  }

  return (
    <div className={classNames('modal-section', {
      'modal-section--no-margin': props.noMargin,
      'modal-section--last': props.last,
    })}
    >
      {props.title && <Text type='subtitle' className='modal-section__title'>{props.title}</Text>}
      {props.subTitle && <Text type='body2' color='text__secondary-color' className='modal-section__sub-title'>{props.subTitle}</Text>}
      {props.children}
    </div>
  )
}

export const CustomModal = props => {
  const [isShown, setIsShown] = useState(props.open)
  const modalRef = useRef()
  const modalHeaderRef = useRef()
  const modalContentRef = useRef()
  const modalContentRefScrollOffset = useScrollPosition(isShown ? modalContentRef.current : null)

  const closeIfOpen = useCallback(() => {
    // only close the modal if is the last modal that was opened
    if (props.open && isShown) {
      props.closeModal()
    }
  }, [isShown, props])

  const swipeToDismissModalStyle = useSwipeToDismissModal(
    modalRef,
    closeIfOpen,
    props.open && isShown,
    modalContentRefScrollOffset === 0,
    [modalHeaderRef.current]
  )

  useStopScroll(props.open)

  useEffect(() => {
    if (!props.open && isShown) {
      setTimeout(() => {
        setIsShown(false)
      }, 500) // wait for the close animation
    }

    if (props.open && !isShown) {
      // first render a first time so that the appearance animation can be done
      // this is necessary since we must unmount the Modal once is not visible
      setTimeout(() => setIsShown(true), 0)
    }
  }, [props.open, isShown])

  const style = props.width
    ? { '--modal-width': `${props.width}px` }
    : {}

  if (props.fitToContent) {
    style['--modal-width'] = 'auto'
  }

  // Modals must be removed from tree once they're not used
  // This is necessary to handle Modals inside Modals.
  // If this was not done, a child Modal could be rendered behind a parent Modal
  if (!isShown && !props.open) {
    return null
  }

  const modal = (
    <div className='modal-wrapper'>
      <div
        onClick={closeIfOpen}
        className={classNames('modal-wrapper__backdrop', {
          'modal-wrapper__backdrop--open': isShown,
          'modal-wrapper__backdrop--hidden': !props.open,
        })}
      />
      <div
        ref={modalRef}
        className={classNames('modal', {
          'modal--open': isShown,
          'modal--hidden': !props.open,
        })}
        style={{
          ...style,
          ...swipeToDismissModalStyle,
        }}
      >
        <header
          className={classNames('modal__header', {
            'modal__header--danger': props.danger,
          }, props.headerClassname)}
          ref={modalHeaderRef}
        >
          <Text bold>{props.title}</Text>
          <IconButton
            className='modal__header__close-btn'
            color='default'
            size='small'
            aria-label='close Modal'
            onClick={props.closeModal}
          >
            <ClearIcon />
          </IconButton>
        </header>
        <div
          className={
            classNames('modal__content', {
              'modal__content--min-width': props.minWidth,
              'modal__content--overflow-visible': props.overflowVisible,
              'modal__content--no-padding': props.noPadding,
            }, props.className)
          }
          style={{ maxHeight: `${window.innerHeight * 0.97}px` }} // 97vh with the innerheight updated. This is an hack to show toolbar in IOS devices, so that it won't overlap modals
          ref={modalContentRef}
        >
          {props.children}
        </div>
      </div>
    </div>
  )

  // The modal will be rendered outside the tree, to prevent issues with z-index
  // Also it prevents issues if another Modal is rendered inside other modal.
  //   Because the first modal has a transform translate, the fixed position of the second modal would be relative to the initial one
  return ReactDOM.createPortal(modal, document.body)
}

export const Modal = ({
  open,
  minWidth,
  width,
  fitToContent,
  overflowVisible,
  closeModal,
  className,
  headerClassname,
  children,
  actionButton,
  secondaryActionButton,
  title,
  noPadding,
  danger,
  withSections,
}) => {
  const parsedClassName = classNames(className, {
    'modal__with-sections': withSections,
  })

  const parsedHeaderClassName = classNames(headerClassname, {
    'modal__with-sections__header': withSections,
  })

  if (children && !actionButton && !secondaryActionButton) {
    return (
      <CustomModal
        open={open}
        minWidth={minWidth}
        width={width}
        fitToContent={fitToContent}
        overflowVisible={overflowVisible}
        closeModal={closeModal}
        className={parsedClassName}
        headerClassname={parsedHeaderClassName}
        title={title}
        noPadding={noPadding}
        danger={danger}
      >
        {children}
      </CustomModal>
    )
  }

  return (
    <CustomModal
      open={open}
      minWidth={minWidth}
      width={width}
      fitToContent={fitToContent}
      overflowVisible={overflowVisible}
      closeModal={closeModal}
      className={parsedClassName}
      headerClassname={parsedHeaderClassName}
      title={title}
      noPadding={noPadding}
      danger={danger}
    >
      {children}
      <ModalActionButtons
        secondaryButton={secondaryActionButton && {
          ...secondaryActionButton,
          action: secondaryActionButton.onClick,
          label: secondaryActionButton.text,
        }}
        primaryButton={actionButton && {
          ...actionButton,
          action: actionButton.onClick,
          label: actionButton.text,
        }}
      />
    </CustomModal>
  )
}

export const SimpleMessageModal = ({
  open,
  closeModal,
  modalTitle,
  title,
  message,
  actionButtonText,
  secondaryActionButtonText,
  actionButtonColor = 'primary',
  actionButtonVariant = 'contained',
  secondaryActionButtonVariant = 'outlined',
  secondaryActionButtonColor = 'danger',
  onActionButton,
  onSecondaryActionButton,
  danger,
  width,
  minWidth,
}) => {
  return (
    <Modal title={modalTitle} open={open} closeModal={closeModal} danger={danger} width={width} minWidth={minWidth}>
      <div className='simple-modal__content'>
        {title && <Text type='title3' className='simple-modal__title'>{title}</Text>}
        {message && <Text type='body' className='simple-modal__message'>{message}</Text>}
        <div className='simple-modal__actions'>
          {secondaryActionButtonText && (
            <Button
              onClick={onSecondaryActionButton}
              variant={secondaryActionButtonVariant}
              color={secondaryActionButtonColor}
            >
              {secondaryActionButtonText}
            </Button>
          )}
          {actionButtonText && (
            <Button
              color={actionButtonColor}
              variant={actionButtonVariant}
              onClick={onActionButton}
            >
              {actionButtonText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}
