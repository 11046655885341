import React from 'react'

import { CommentSection } from '../CommentSection'
import { FaqSection } from '../FaqSection'
import { ImageInputFormStep } from '../ImageInputFormStep'
import { InformationSection } from '../InformationSection'
import { ConnectedReviewSection } from './components/ConnectedReviewSection'

// TODO: See generic way to handle listeners

export const COMPONENTS_IDS = {
  ImageInputFormStep: 'ImageInputFormStep',
  CommentSection: 'CommentSection',
  ReviewSection: 'ReviewSection',
  InformationSection: 'InformationSection',
  FaqSection: 'FaqSection',
}

/**
 * This ComponentGetter is a generic and abstract way to get components dynamically
 * Every component that need specific properties from global state, should receive them throught a wrapper component inside ./components
 * This should be one of the few exceptions where a component in /components folder is connected to global store
 */
export const ComponentGetter = props => {
  switch (props.componentId) {
    case COMPONENTS_IDS.ImageInputFormStep:
      return <ImageInputFormStep {...props} {...props.componentContent} />
    case COMPONENTS_IDS.CommentSection:
      return <CommentSection {...props} {...props.componentContent} />
    case COMPONENTS_IDS.ReviewSection:
      return <ConnectedReviewSection {...props} {...props.componentContent} />
    case COMPONENTS_IDS.InformationSection:
      return <InformationSection {...props} {...props.componentContent} />
    case COMPONENTS_IDS.FaqSection:
      return <FaqSection {...props} {...props.componentContent} />
    default:
      return null
  }
}
