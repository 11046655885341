import { Icon, Divider } from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import { LinkButton } from 'components/Button'

import './styles.scss'

export const ValueWidget = props => {
  return (
    <div className='value-widget'>
      <div className='value-widget__content'>
        {props.iconId && (
          <div
            style={props.iconColor ? { '--value-widget-icon-color': props.iconColor } : {}}
            className={classNames('value-widget__icon-wrapper', {
              'value-widget__icon-wrapper--small': props.smallIcon,
            })}
          >
            <Icon>{props.iconId}</Icon>
          </div>
        )}
        <div className='value-widget__content__title-value'>
          <h5 className='value-widget__content__title'>{props.title}</h5>
          <div className='value-widget__content__value'>{props.value}</div>
        </div>
      </div>
      {(props.footerIconId || props.footerText || props.actionHref) && <Divider />}
      {(props.footerIconId || props.footerText || props.actionHref) && (
        <div className='value-widget__footer'>
          {props.footerText && (
            <div className='value-widget__footer__text'>
              {props.footerIconId && <Icon className='value-widget__footer__icon'>{props.footerIconId}</Icon>}
              {props.footerText}
            </div>
          )}
          {props.actionHref && (
            <LinkButton
              wrapperClassName='value-widget__footer__action-button'
              href={props.actionHref}
              variant='outlined'
            >
              {props.actionText}
            </LinkButton>
          )}
        </div>
      )}
    </div>
  )
}
