import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { UPSELL_MODAL_NOTIFICATION_LISTENER_ID, UPSELL_TYPE } from 'components/UpsellModal'

import { currentLogedInUser } from 'store/users/selectors'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'
import { createBackOfficeLink, subRoutesNames } from 'services/routingService'

import { convertToBytes, formatBytes } from 'utils/storageData'

export const ACTION_KEYS = {
  BACKOFFICE_LAYOUT: 'BACKOFFICE_LAYOUT',
  BACKOFFICE_PACKS: 'BACKOFFICE_PACKS',
  BACKOFFICE_GALLERIES: 'BACKOFFICE_GALLERIES',
}

const ACTION_KEYS_CANCEL_URL = {
  BACKOFFICE_LAYOUT: createBackOfficeLink(subRoutesNames.BACK_OFFICE.LAYOUT),
  BACKOFFICE_PACKS: createBackOfficeLink(subRoutesNames.BACK_OFFICE.PACKS),
  BACKOFFICE_GALLERIES: createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES),
}

const notificationSystem = getNotificationSystem()

export const useRestrictedQuota = (extraQuota = 0, actionKey) => {
  const planKeys = getConfigValue('general.pricing.plan_keys')
  const plans = getConfigValue('general.pricing.premium_plan_cards')
  const logedInUser = useSelector(currentLogedInUser)
  const userLoading = !logedInUser || !logedInUser.username
  const [hasQuota, setHasQuota] = useState()

  const openUpsell = () => {
    const recommendedPlanIndex = Math.max(
      planKeys.findIndex(planKey => planKey === logedInUser.plan?.id),
      1
    )

    notificationSystem.notify({
      upsellType: UPSELL_TYPE.EXCEEDED_QUOTA_UPSELL,
      storage: logedInUser.storageSize,
      recommendedPlanId: planKeys[recommendedPlanIndex],
      featureName: t('components.upsell_modal.insufficient_quota.title'),
      description: t('components.upsell_modal.insufficient_quota.description', { extraQuota: formatBytes(extraQuota) }),
      updateLabel: t('components.upsell_modal.insufficient_quota.update_label'),
      cancelUrl: actionKey && ACTION_KEYS_CANCEL_URL[actionKey],
    }, UPSELL_MODAL_NOTIFICATION_LISTENER_ID)
  }

  useEffect(() => {
    if (userLoading) {
      return
    }

    const userCurrentPlanId = logedInUser.plan?.id

    const userPlan = plans[userCurrentPlanId] || plans[planKeys[0]]

    const userQuota = (logedInUser.storageSize?.total || 0) + extraQuota
    const maxQuota = convertToBytes(userPlan.available_features.storage.value, 'GB')

    setHasQuota(maxQuota >= userQuota)
  }, [logedInUser, extraQuota, plans, planKeys, userLoading])

  return [hasQuota, openUpsell]
}
