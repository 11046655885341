import Icon from '@mui/material/Icon'
import classNames from 'classnames'
import React from 'react'

import { Button } from 'components/Button'
import { List } from 'components/List'
import { Text } from 'components/Text'

import './styles.scss'

export const UISelector = props => {
  const onSelectView = viewId => () => props.onSelectView && props.onSelectView(viewId)

  return (
    <List className='ui-selector' separator='|' separatorClassName='ui-selector__view__separator'>
      {props.views.map(view => {
        const isSelected = view.id === props.selectedId
        return (
          <Button
            key={view.id}
            className={classNames('ui-selector__view', { 'ui-selector__view--selected': view.id === props.selectedId })}
            startIcon={(
              <Icon>
                {view.iconId}
              </Icon>
            )}
            onClick={onSelectView(view.id)}
          >
            <Text
              color={isSelected ? 'text__primary-color' : 'text__grey-color'}
            >
              {view.text}
            </Text>
          </Button>
        )
      })}
    </List>
  )
}
