import React from 'react'
import { useSelector } from 'react-redux'

import { LinkButton } from 'components/Button'
import { Grid } from 'components/Grid'
import { Link } from 'components/Link'
import { LoadingComponent } from 'components/LoadingComponent'
import { Text } from 'components/Text'
import { WatermarkPin } from 'components/WatermarkPin'

import { t } from 'services/i18n'
import { createUserSettingsLink, subRoutesNames } from 'services/routingService'

import './styles.scss'

export const WatermarksPicker = props => {
  const watermarksAreLoading = useSelector(state => state.watermarks.loading)
  const watermarksKeys = props.logedInUser.watermarks || []
  const watermarksData = props.watermarksData

  const onWatermarkClick = watermark => () => {
    props.onWatermarkPick(watermark)
  }

  if (Object.keys(watermarksKeys).length === 0 && !watermarksAreLoading) {
    return (
      <div className='watermarks-picker__empty'>
        <Text
          as='h4'
          type='body'
        >
          {t('back_office.gallery.watermarks_picker.empty.header')}
        </Text>
        <Text
          as='p'
          type='body2'
        >
          {t('back_office.gallery.watermarks_picker.empty.add_label', {
            components: {
              link: <Link
                underlined
                colored
                href={createUserSettingsLink(subRoutesNames.USER_SETTINGS.PERSONALISATION)}
              />,
            },
          })}
        </Text>
        <div className='watermarks-picker__empty__button'>
          <LinkButton href={createUserSettingsLink(subRoutesNames.USER_SETTINGS.PERSONALISATION)} variant='outlined' color='primary'>
            {t('back_office.gallery.watermarks_picker.empty.add_cta')}
          </LinkButton>
        </div>
      </div>
    )
  }

  if (watermarksAreLoading) {
    return (
      <LoadingComponent
        isLoading={watermarksAreLoading}
        size={30}
        className='watermarks-picker__loading-wrapper'
      />
    )
  }

  return (
    <div>
      <Grid spacing={5} xs={6} sm={3} md={2}>
        {watermarksKeys.map(watermarkKey => {
          const watermark = watermarksData?.[watermarkKey]
          if (watermark === undefined) {
            return null
          }

          return (
            <div onClick={onWatermarkClick(watermark)} key={watermarkKey} className='watermarks-picker__watermark'>
              <WatermarkPin watermark={watermark} />
            </div>
          )
        })}
      </Grid>
      <Text className='watermarks-picker__add-more-label' as='p' type='body2'>
        {t('back_office.gallery.watermarks_picker.add_more_label', {
          components: {
            link: <Link
              underlined
              colored
              href={createUserSettingsLink(subRoutesNames.USER_SETTINGS.PERSONALISATION)}
            />,
          },
        })}
      </Text>
    </div>
  )
}
