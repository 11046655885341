import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { HomeVideo } from 'scenes/Home/components/HomeVideo'

import { SignUpWidget } from 'components/SignUpWidget'

import { HomeContainer } from './components/HomeContainer'
import { LastPacks } from './components/LastPacks'

import { createBackOfficeLink, createProfessionalsLink, createSearchLink, subRoutesNames } from 'services/routingService'

import { SEARCH_GROUP_IDS } from 'utils/search'

import './styles.scss'

const Home = () => {
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const history = useHistory()

  return (
    <div>
      <div className='home'>
        <div className='home__box'>
          <HomeVideo />
        </div>

        <div className='home__box'>
          <HomeContainer
            title='Galerias'
            text='A forma mais simples, rápida e visual de selecionar e partilhar as fotos com os teus clientes'
            buttonLabel='Sabe mais'
            imageSrc='https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhome_1.png?alt=media'
            cutted
            href={createProfessionalsLink()}
          />
        </div>

        <div className='home__box'>
          <HomeContainer
            title='Packs'
            subtitle='Cria packs e permite que os teus clientes agendem sessões contigo num estalar de dedos'
            text='Potenciais clientes podem encontrar os teus serviços no nosso motor de busca'
            buttonLabel='Ver packs'
            color='secondary'
            imageSrc='https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhomepage_v2%2Fpacks%20de%20sesso%CC%83es%20foto%CC%81grafos_homepage.png?alt=media'
            href={createSearchLink(SEARCH_GROUP_IDS.PACKS)}
          />
        </div>

        <div className='home__box home__box--fullscreen'>
          <LastPacks />
        </div>

        <div className='home__box'>
          <HomeContainer
            title='Calendário'
            subtitle='Torna-te mestre da organização!'
            text='Atualiza a tua agenda e associa a cada evento a respetiva galeria'
            buttonLabel='Organizar'
            color='secondary'
            imageSrc='https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhome_2.jpg?alt=media'
            cutted
            href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.CALENDAR)}
          />
        </div>
        {!logedInUserId && (
          <div className='home__box home__box--fullscreen'>
            <SignUpWidget history={history} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
