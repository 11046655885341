import React from 'react'

import './styles.scss'

const PostSkeleton = () => {
  return (
    <div className='post skeleton'>
      <div className='metadata' />
      <div className='content'>
        <div className='img' />
        <div className='text' />
      </div>
      <div className='index' />
    </div>
  )
}

export default PostSkeleton
