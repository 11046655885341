import React from 'react'

import './styles.scss'

const PostCardSkeleton = () => {
  return (
    <div className='postCard skeleton'>
      <div className='img' />
      <div className='text'>
        <div className='title' />
        <div className='tags' />
      </div>
    </div>
  )
}

export default PostCardSkeleton
