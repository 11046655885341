import DirectionsIcon from '@mui/icons-material/KeyboardArrowRight'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import React, { useEffect, useState } from 'react'

import { IconButton } from 'components/Button'
import { Link } from 'components/Link'
import { Rating } from 'components/Rating'

import { createUserLink } from 'services/routingService'

import './styles.scss'

const renderCommentEditUI = (comment, onEditChange, onEditCancel, onEditSave) => {
  return (
    <div>
      <Paper component='form' className='comment__input-form'>
        <InputBase
          multiline
          className='comment__input-form__input'
          placeholder='Escreve a tua review'
          inputProps={{ 'aria-label': 'Escreve a tua review' }}
          defaultValue={comment}
          onChange={evt => onEditChange(evt.target.value)}
        />
        <IconButton onClick={onEditSave} className='comment__input-form__button' color='primary' aria-label='send comment'>
          <DirectionsIcon />
        </IconButton>
      </Paper>
      <button tabIndex='0' onClick={onEditCancel} className='comment__footer__action'>
        Cancelar
      </button>
    </div>
  )
}

export const Comment = props => {
  const [temporaryEdit, setTemporaryEdit] = useState(props.comment)
  const [isEditing, setIsEditing] = useState(props.editMode)

  useEffect(() => {
    setIsEditing(props.editMode)
  }, [props.editMode])

  const onEditChange = val => {
    setTemporaryEdit(val)
  }

  const onClickEdit = () => {
    setIsEditing(true)
  }

  const onEditSave = () => {
    setIsEditing(false)
    props.onEdit && props.onEdit(temporaryEdit)
  }

  const onEditCancel = () => {
    setTemporaryEdit(props.comment)
    setIsEditing(false)
  }

  const onRatingChange = newRating => {
    props.onRatingChange && props.onRatingChange(newRating)
  }

  const {
    user,
    comment,
    onClickReply,
    onClickRemove,
    canReply,
    canEdit,
    rating,
    canSetRating,
  } = props

  return (
    <div className='comment'>
      <img
        className='comment__avatar'
        alt='avatar'
        src={user.avatar}
      />
      <div className='comment__content'>
        <div className='comment__header'>
          <Link
            className='comment__header__author'
            href={createUserLink(user.uid)}
          >
            {user.name}
          </Link>
          <div className='comment__header__metadata'>{props.date}</div>
          {props.isEdited && <div className='comment__header__metadata'> (editado)</div>}
        </div>
        {(rating || (isEditing && canSetRating)) && (
          <Rating
            name='comment-rating'
            readOnly={!isEditing}
            precision={isEditing ? 0.5 : 0.1}
            initialValue={props.rating}
            onChange={onRatingChange}
            showTextValue
          />
        )}
        {!isEditing && <div className='comment__body'>{comment}</div>}
        {isEditing &&
        renderCommentEditUI(temporaryEdit, onEditChange, onEditCancel, onEditSave)}
        {!isEditing && (
          <div className='comment__footer'>
            {canReply && (
              <button tabIndex='0' onClick={onClickReply} className='comment__footer__action'>
                Responder
              </button>
            )}
            {canEdit && (
              <button tabIndex='0' onClick={onClickEdit} className='comment__footer__action'>
                Editar
              </button>
            )}
            {canEdit && (
              <button tabIndex='0' onClick={onClickRemove} className='comment__footer__action comment__footer__action--right'>
                Eliminar
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
