import Icon from '@mui/material/Icon'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import classNames from 'classnames'
import React from 'react'

import { useMenuItemActive } from '../../hooks/useMenuItemActive'

import { useHideOnScroll } from 'hooks/useHideOnScroll'

import { changeRoute } from 'services/routingService'

import './styles.scss'

export const BackOfficeFooterMenu = props => {
  const changeLocation = url => () => changeRoute(props.history, url)
  const isHidden = useHideOnScroll({ topOffset: 50, bottomOffset: 100 })
  const activeMenuItemId = useMenuItemActive(props.list, props.activeId)

  return (
    <nav
      className={classNames('back-office-footer-menu', {
        'back-office-footer-menu--hidden': isHidden,
      })}
    >
      <List>
        {props.list.map(page => {
          const id = `${page.id}-menu-item`
          return (
            <ListItem className='back-office-footer-menu__list-item' onClick={changeLocation(page.url)} button key={page.id} id={id}>
              <ListItemIcon
                className={classNames('back-office-footer-menu__list-item-icon', {
                  'back-office-footer-menu__list-item-icon--active': activeMenuItemId === page.id,
                })}
              >
                <div className='back-office-footer-menu__list-item-content'>
                  <Icon>{page.icon}</Icon>
                  <div>{page.text}</div>
                </div>
              </ListItemIcon>
            </ListItem>
          )
        })}
      </List>
    </nav>
  )
}
