import classNames from 'classnames'
import React from 'react'

import './styles.scss'

export const IconLabel = props => {
  return (
    <div className={classNames('icon-label', { 'icon-label--inverted': props.inverted }, props.className)}>
      {props.icon && (
        <props.icon
          className={classNames('icon-label__icon', { 'icon-label__icon--no-left-margin': props.noLeftMargin })}
        />
      )}
      {props.label}
    </div>
  )
}
