import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { createSearchLink, createPackLink, createUserLink } from 'services/routingService'
import { searchAll, searchUsersIndex, searchPackIndex, searchCategoriesIndex } from 'services/searchService'

import { SEARCH_GROUP_IDS } from 'utils/search'

export const RECOMMENDED_CATEGORIES_DEFAULT = [
  {
    name: t('components.search.categories.weddings'),
    href: createSearchLink(SEARCH_GROUP_IDS.PACKS, 'Casamentos'),
    image: { src: 'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/home%2Fhero_pins%2Fblake-cheek-R7sKX3PXZ1A-unsplash.jpg?alt=media&token=b1308a9c-74cd-4421-885b-9f53cabcd449' },
  },
  {
    name: t('components.search.categories.christenings'),
    href: createSearchLink(SEARCH_GROUP_IDS.PACKS, 'Batizados'),
    image: { src: 'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/home%2Fhero_pins%2Fjonathan-borba-CgWTqYxHEkg-unsplash.jpg?alt=media&token=42a335cc-940c-4277-a037-190b4b22b283' },
  },
  {
    name: t('components.search.categories.family'),
    href: createSearchLink(SEARCH_GROUP_IDS.PACKS, 'Família'),
    image: { src: 'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/home%2Fhero_pins%2Fekaterina-shakharova-L4nwL3195U0-unsplash.jpg?alt=media&token=065b5bd4-0fb3-45ea-b6aa-a36a60da694b' },
  },
  {
    name: t('components.search.categories.babies'),
    href: createSearchLink(SEARCH_GROUP_IDS.PACKS, 'Bebés'),
    image: { src: 'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/home%2Fhero_pins%2Fjonathan-borba-CgWTqYxHEkg-unsplash.jpg?alt=media&token=42a335cc-940c-4277-a037-190b4b22b283' },
  },
  {
    name: t('components.search.categories.baby_showers'),
    href: createSearchLink(SEARCH_GROUP_IDS.PACKS, 'Baby Showers'),
    image: { src: 'https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/home%2Fhero_pins%2Fjonathan-borba-CgWTqYxHEkg-unsplash.jpg?alt=media&token=42a335cc-940c-4277-a037-190b4b22b283' },
  },
]

export const getRecommendedCategories = () => {
  const configRecommendedCategories = getConfigValue('search.recommended-categories') || RECOMMENDED_CATEGORIES_DEFAULT
  return configRecommendedCategories
    .map(cat => ({
      name: t(cat.name),
      href: createSearchLink(SEARCH_GROUP_IDS.PACKS, t(cat.name)),
      image: cat.image,
    }))
}

export const searchResults = (selectedSearchGroup, searchQuery) => {
  if (selectedSearchGroup === SEARCH_GROUP_IDS.ALL) {
    return searchAll(searchQuery)
  } else {
    switch (selectedSearchGroup) {
      case SEARCH_GROUP_IDS.PACKS:
        return searchPackIndex(searchQuery)
      case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
        return searchUsersIndex(searchQuery, 0, true)
      case SEARCH_GROUP_IDS.CATEGORIES:
        return searchCategoriesIndex(searchQuery)
      default:
        return Promise.resolve([])
    }
  }
}

export const getSearchResultHref = (searchGroup, query) => {
  switch (searchGroup) {
    case SEARCH_GROUP_IDS.PHOTOGRAPHERS:
      return createUserLink(query)
    case SEARCH_GROUP_IDS.PACKS:
      return createPackLink(query)
    default:
      return createSearchLink(searchGroup, query)
  }
}
