import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'

import cssVariables from 'styles/theme/colors.scss'

const customTheme = theme => createTheme({
  palette: {
    ...theme,
  },
})

// too add new colors & color modes: https://mui.com/customization/palette/#adding-new-colors
const theme = createTheme({
  palette: {
    primary: {
      main: cssVariables.primaryColor,
      contrastText: cssVariables.textDefaultColor,
      light: cssVariables.primaryColorLight,
      dark: cssVariables.primaryColorDark,
    },
    secondary: {
      main: cssVariables.secondaryColor,
      light: cssVariables.secondaryColorLight,
      contrastText: '#ffffff',
    },
    danger: {
      main: cssVariables.dangerColor,
      contrastText: '#ffffff',
    },
    neutral: {
      main: cssVariables.grey,
    },
    default: {
      main: cssVariables.darkGrey,
      contrastText: cssVariables.textLightColor,
    },
  },
})

export const ThemedComponent = props => (
  <ThemeProvider theme={props.customTheme ? customTheme(props.customTheme) : theme}>
    {props.children}
  </ThemeProvider>
)
