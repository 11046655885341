import React from 'react'

import { Text } from 'components/Text'

import './styles.scss'

export const Faq = props => {
  return (
    <li className='faq'>
      <Text className='faq__question' bold>{props.question}</Text>
      <Text>{props.answer}</Text>
    </li>
  )
}
