import QuestionMarkOutlined from '@mui/icons-material/QuestionMarkOutlined'
import { Tooltip, styled, tooltipClasses } from '@mui/material'
import Icon from '@mui/material/Icon'
import React, { useState } from 'react'

import { IconButton } from 'components/Button'

import { useBreakpoints } from 'hooks/useBreakpoints'

import './styles.scss'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}))

export const HelpTooltip = ({ children, title, iconId, fontSize, color }) => {
  const { isMobile } = useBreakpoints()
  const [showHelperTooltip, setShowHelperTooltip] = useState(false)

  const tooltipTitle = children ?? title
  return (
    <div>
      <LightTooltip
        open={showHelperTooltip}
        onOpen={() => setShowHelperTooltip(true)}
        onClose={() => setShowHelperTooltip(false)}
        arrow
        placement={isMobile ? 'bottom-end' : 'right-start'}
        title={tooltipTitle}
        leaveDelay={300}
        leaveTouchDelay={8000}
      >
        <IconButton
          onClick={() => setShowHelperTooltip(!showHelperTooltip)}
          aria-label='help-button'
          size='small'
          color={color || 'default'}
        >
          {!iconId && (
            <QuestionMarkOutlined
              className='help-tooltip__button'
              fontSize='small'
              style={fontSize ? { fontSize } : {}}
            />
          )}
          {iconId && (
            <Icon
              className='help-tooltip__button'
              fontSize='small'
              style={fontSize ? { fontSize } : {}}
            >
              {iconId}
            </Icon>
          )}
        </IconButton>
      </LightTooltip>
    </div>
  )
}
