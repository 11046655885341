import FormControl from '@mui/material/FormControl'
import React, { useEffect, useState } from 'react'

import { Button } from 'components/Button'
import { TextField } from 'components/TextField'

import './styles.scss'

export const SimpleMessage = props => {

  const [name, setName] = useState(props.currentUser?.name || '')
  const [message, setMessage] = useState('')

  useEffect(() => {
    setName(props.currentUser?.name)
  }, [props.currentUser])

  const onNameChange = e => {
    const newName = e.target.value
    setName(newName)
  }

  const onMessageChange = e => {
    const newMessage = e.target.value
    setMessage(newMessage)
  }

  const onSubmit = e => {
    props.onSubmit(name, message)
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className='simple-message'>
      <FormControl
        onSubmit={onSubmit}
        className='simple-message__form'
        component='form'
        id='message-form'
      >
        <TextField
          disabled={props.currentUser}
          className='simple-message__form__name'
          label='Nome'
          variant='outlined'
          onChange={onNameChange}
          value={name}
        />
        <TextField
          className='simple-message__form__message'
          label='Mensagem'
          placeholder='Escreve a tua mensagem para o John Doe'
          multiline
          variant='outlined'
          rows={5}
          rowsMax={12}
          onChange={onMessageChange}
          value={message}
        />
        <div className='simple-message__form__buttons'>
          <Button
            onClick={props.onClose}
            variant='outlined'
            color='default'
          >
            Cancelar
          </Button>
          <Button
            className='simple-message__form__button--send'
            color='primary'
            variant='contained'
            onSubmit={onSubmit}
            type='submit'
            form='message-form'
          >
            Enviar
          </Button>
        </div>
      </FormControl>
    </div>
  )
}
