import React, { Fragment, useState } from 'react'

import { Button } from 'components/Button'
import { ImageListInput } from 'components/ImageListInput'
import { ItemList } from 'components/ItemList'
import { LoadingWrapper } from 'components/LoadingComponent'
import { Modal } from 'components/Modal'

import { UserPack, UserPackSkeleton } from '../UserPack'

import { PackImagePicker } from './components/PackImagePicker'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useRestrictedQuota } from 'hooks/useRestrictedQuota'

import { t } from 'services/i18n'

import { getImagesTotalSize } from 'utils/images'

import './styles.scss'

const PORTFOLIO_IMAGES_FROM_PACKS_FF = false // This flag should be disabled until #168 is solved

export const PortfolioInput = props => {
  const [ images, setImages ] = useState([]) // overal selected images
  const [ selectedPackImagesIdxs, setSelectedPackImagesIdxs ] = useState(new Array(props.userPacks.length).fill([])) // array of arrays. each array item save the selected images for that pack
  const [ openedPackIndex, setOpenedPackIndex ] = useState(-1)
  const { isMobile } = useBreakpoints()
  const [hasQuota, openUpsell] = useRestrictedQuota(getImagesTotalSize(images))

  const onImagesChange = newImages => {
    setImages(newImages)
  }

  const onPackImagesSave = packIndex => images => {
    const packImagesCopy = [...selectedPackImagesIdxs]
    packImagesCopy[packIndex] = images
    setSelectedPackImagesIdxs(packImagesCopy)
    onClosePackImageSelectorModal()
  }

  const onPackImagesRemove = packImage => {
    const packImagesCopy = [...selectedPackImagesIdxs]
    const imageIndexInPackImagesCopy = packImagesCopy[packImage.packIdx]
      .findIndex(imgIdx => imgIdx === packImage.imageIndex)
    if (imageIndexInPackImagesCopy >= 0) {
      packImagesCopy[packImage.packIdx].splice(imageIndexInPackImagesCopy, 1)
      setSelectedPackImagesIdxs(packImagesCopy)
    }
  }

  const onClosePackImageSelectorModal = () => setOpenedPackIndex(-1)
  const savePortfolioImages = () => {
    if (!hasQuota) {
      openUpsell()
      return
    }

    const packImages = selectedPackImagesIdxs
      .flatMap((imgIdx, packIdx) => ({
        ...(props.userPacks[packIdx].images?.[imgIdx] || {}),
        packId: props.userPacks[packIdx].id,
      }))
      .filter(packImage => packImage.id)
    const allImages = [...images, ...packImages].filter(img => img)
    props.savePortfolioImages && props.savePortfolioImages(allImages)
    props.closeModal()
  }

  const packsSelectedImages = selectedPackImagesIdxs.flatMap((packImagesIndexes, packIdx) => {
    return packImagesIndexes.map(imageIndex => ({
      ...props.userPacks[packIdx].images[imageIndex],
      packIdx,
      imageIndex,
    }))
  })

  const userPacksAreAvailable = props.userPacks?.length > 0 && PORTFOLIO_IMAGES_FROM_PACKS_FF

  return (
    <Fragment>
      <p>{t('user_details.portfolio_input.images.title')}</p>
      <ImageListInput
        extraImages={packsSelectedImages}
        multiple
        onChange={onImagesChange}
        removeExtraImage={onPackImagesRemove}
        ignoreMainImage
      />
      {userPacksAreAvailable && (
        <div>
          <p className='portfolio-input__or'>{t('user_details.portfolio_input.packs.or')}</p>
          <p>{t('user_details.portfolio_input.packs.title')}</p>
          <ItemList cols={isMobile ? 2 : 3}>
            {props.userPacks.map((userPack, packIndex) => (
              <LoadingWrapper
                key={userPack.id}
                isLoading={props.userPacksLoading}
                skeleton={<UserPackSkeleton />}
              >
                <UserPack
                  onClick={() => setOpenedPackIndex(packIndex)}
                  image={{
                    ...userPack.images?.[0],
                    maxHeight: isMobile ? 150 : 210,
                  }}
                  title={userPack.title}
                />
              </LoadingWrapper>
            ))}
          </ItemList>
        </div>
      )}
      <div className='portfolio-input__actions'>
        <Button
          className='portfolio-input__actions__button'
          variant='outlined'
          onClick={props.closeModal}
        >
          {t('user_details.portfolio_input.cancel')}
        </Button>
        <Button
          className='portfolio-input__actions__button'
          variant='contained'
          color='primary'
          onClick={savePortfolioImages}
        >
          {t('user_details.portfolio_input.save')}
        </Button>
      </div>
      <Modal
        closeModal={onClosePackImageSelectorModal}
        open={openedPackIndex !== -1}
        title={t('user_details.portfolio_input.packs.image_picker.title', { 'pack_title': props.userPacks[openedPackIndex]?.title })}
      >
        <PackImagePicker
          selectedImagesIdx={selectedPackImagesIdxs[openedPackIndex]}
          onSave={onPackImagesSave(openedPackIndex)}
          onCancel={onClosePackImageSelectorModal}
          images={props.userPacks[openedPackIndex]?.images || []}
        />
      </Modal>
    </Fragment>
  )
}
