const users = [
  {
    uid: 'klc102oFTUggLJ2uz6rxW32Hi1H2',
    name: 'Photographer 1',
    email: 'photographer1@email.com',
    username: 'photographer1-BFAvA8',
    avatar: 'https://i.ytimg.com/vi/X_BFAvA8Scc/maxresdefault.jpg',
    externalLinks: [
      {
        name: 'Facebook',
        url: '/#',
      }, {
        name: 'Instagram',
        url: '/#',
      },
    ],
    description: 'Fotógrafo profissional à sua disposição para trabalhar com diversas coisas como tirar forgrafias a chimpazés, Fotógrafo profissional à sua disposição para trabalhar com diversas coisas como tirar forgrafias a chimpazés',
    rating: {
      value: 4.72,
      total: 210,
    },
    categories: [
      {
        name: 'Casamentos',
      }, {
        name: 'Batizados',
      }, {
        name: 'Fotografia de Perfil',
      },
    ],
    isPro: true,
    portfolio: {
      cards: [
        {
          id: 'portfolio1',
          src: 'https://i.pinimg.com/originals/43/b0/7c/43b07c593d69192e172670ccafd0fbaf.jpg',
          width: 500,
          height: 729,
        }, {
          id: 'portfolio2',
          src: 'https://img.pixers.pics/pho_wat(s3:700/FO/35/77/84/57/700_FO35778457_1b124535f76efddd500cd3277c0fefa6.jpg,700,467,cms:2018/10/5bd1b6b8d04b8_220x50-watermark.png,over,480,417,jpg)/murais-de-parede-funny-kittens.jpg.jpg',
          width: 700,
          height: 467,
          colsWidth: 2,
        }, {
          id: 'portfolio3',
          src: 'https://catonauts.files.wordpress.com/2012/12/cat-kitty-katze-patrol-guard-duty-kitten.jpg',
          width: 446,
          height: 505,
        }, {
          id: 'portfolio4',
          src: 'https://us.v-cdn.net/5021879/uploads/editor/95/ivbr50oaci1f.jpeg',
          width: 634,
          height: 840,
        }, {
          id: 'portfolio5',
          src: 'https://i.pinimg.com/originals/9e/13/db/9e13dbcc911e482a6d39741cf2015411.png',
          width: 534,
          height: 459,
        }, {
          id: 'portfolio6',
          src: 'https://www.dhresource.com/0x0/f2/albu/g10/M01/A3/59/rBVaVlwQgLqAfH5kAADn8X9lOJw196.jpg',
          width: 725,
          height: 722,
        }, {
          id: 'portfolio7',
          src: 'https://ae01.alicdn.com/kf/HTB1gHy0XYsTMeJjSsziq6AdwXXaf.jpg?size=179690&height=800&width=800&hash=bae1ab6ed4209a0888e661813e3a267a',
          width: 800,
          height: 800,
        }, {
          id: 'portfolio8',
          src: 'https://ae01.alicdn.com/kf/HTB1gHy0XYsTMeJjSsziq6AdwXXaf.jpg?size=179690&height=800&width=800&hash=bae1ab6ed4209a0888e661813e3a267a',
          width: 800,
          height: 800,
        },
      ],
    },
    packs: {
      cards: [
        {
          id: 'packId1',
        },
      ],
      hiddenCards: [
        {
          id: 'packId2',
        },
      ],
    },
  },
  {
    avatar: 'https://giantbomb1.cbsistatic.com/uploads/scale_medium/27/273442/2755049-5937493672-Jon_S.jpg',
    name: 'John Doe',
    uid: 'klc102oFTUgsfdad123J2uz6rxW32Hi1H2',
    rating: {
      value: 4.72,
      total: 210,
    },
  },
  {
    avatar: 'https://www.coolcatcollars.co.uk/user/products/large/Leopold%20Yellow.jpg',
    name: 'Mat',
    uid: 'klcasd2oFTUgsfdadfgLJ2uz6rxW32Hi1H2',
  },
  {
    avatar: 'https://media.wired.com/photos/5cdefc28b2569892c06b2ae4/master/w_2560%2Cc_limit/Culture-Grumpy-Cat-487386121-2.jpg',
    name: 'Cat',
    uid: 'klc102oFTUgsfdadfgLJ2uz6rxW32asd1H2',
  },
  {
    uid: 'nqGvYhOvSTcpOtFvOiBzhWGwJiy1',
    name: 'Ernesto Silva',
    avatar: 'https://i.ytimg.com/vi/X_BFAvA8Scc/maxresdefault.jpg',
    description: 'Fotógrafo profissional à sua disposição para trabalhar com diversas coisas como tirar forgrafias a chimpazés, Fotógrafo profissional à sua disposição para trabalhar com diversas coisas como tirar forgrafias a chimpazés',
    categories: [
      {
        name: 'Paisagens',
      },
    ],
    isPro: true,
    portfolio: {
      cards: [],
    },
    packs: {
      cards: [],
    },
  },
]

export default users
