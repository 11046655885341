import React from 'react'

import './styles.scss'

export const Iframe = props => {
  const { aspectRatio, borderRadius, ...otherProps } = props

  const aspectRatioStyle = {
    paddingBottom: `${(1 / aspectRatio) * 100}%`,
    borderRadius: borderRadius || 0,
  }

  return (
    <div className='iframe-wrapper' style={aspectRatioStyle}>
      <iframe
        title='Embedded Content'
        {...otherProps}
      />
    </div>
  )
}
