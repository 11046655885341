import React, { useState, useEffect, useRef, useContext } from 'react'

import { WindowSizeContext } from 'hooks/useWindowSize'

import './styles.scss'

const getTabSelectorMobileParams = (tabsListElmt, tabIndex) => {
  return {
    width: tabsListElmt?.children[tabIndex]?.offsetWidth + 'px',
    left: tabsListElmt?.children[tabIndex]?.offsetLeft + 'px',
  }
}

export const TabsList = props => {
  const initialTabIndex = props.tabs.findIndex(tab => tab.id === props.selectedTabId) || 0
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialTabIndex)
  const [tabSelectorMobileParams, setTabSelectorMobileParams] = useState({})
  const tabsListElmt = useRef(null)
  const [ windowSize ] = useContext(WindowSizeContext)

  useEffect(() => {
    setTabSelectorMobileParams(getTabSelectorMobileParams(tabsListElmt.current, selectedTabIndex))
  }, [selectedTabIndex, windowSize])

  useEffect(() => {
    setSelectedTabIndex(props.tabs.findIndex(tab => tab.id === props.selectedTabId) || 0)
  }, [props.selectedTabId, props.tabs])

  const selectTabId = newId => () => {
    const newIndex = props.tabs.findIndex(tab => tab.id === newId) || 0
    props.selectedTabId === undefined && setSelectedTabIndex(newIndex) // if selectTabId is not defined by parent, set the selected tab here
    props.onSelectTabId && props.onSelectTabId(newId)
  }

  return (
    <div className='tabs-list'>
      <div
        className='tabs-list__selector'
        style={{
          '--tabsListSelectorIndex': selectedTabIndex,
          '--tabsListSelectorMobileLeft': tabSelectorMobileParams.left,
          '--tabsListSelectorMobileWidth': tabSelectorMobileParams.width,
        }}
      />
      <ol className='tabs-list__list' ref={tabsListElmt}>
        {props.tabs.map(tab => {
          return (
            <li key={tab.id} className='tabs-list__item' onClick={selectTabId(tab.id)}>
              {tab.name}
            </li>
          )
        })}
      </ol>
    </div>
  )
}
