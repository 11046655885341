import classNames from 'classnames'
import React from 'react'

import { Button } from 'components/Button'

import './styles.scss'

export const ModalActionButtons = ({ leftButton, secondaryButton, primaryButton, className }) => {
  return (
    <div className={classNames('modal-action-buttons', className)}>
      {leftButton && !leftButton.hidden && (
        <Button
          className='modal-action-buttons__button modal-action-buttons__button--left'
          variant='outlined'
          color='danger'
          onClick={leftButton.action}
          disabled={leftButton.disabled}
        >
          {leftButton.label}
        </Button>
      )}
      {secondaryButton && !secondaryButton.hidden && (
        <Button
          className='modal-action-buttons__button'
          variant='outlined'
          color='default'
          onClick={secondaryButton.action}
          disabled={secondaryButton.disabled}
        >
          {secondaryButton.label}
        </Button>
      )}
      {primaryButton && !primaryButton.hidden && (
        <Button
          className='modal-action-buttons__button'
          type='submit'
          variant='contained'
          color='primary'
          onClick={primaryButton.action}
          disabled={primaryButton.disabled}
        >
          {primaryButton.label}
        </Button>
      )}
    </div>
  )
}
