import classNames from 'classnames'
import React from 'react'

import { Text } from 'components/Text'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'

import './styles.scss'

export const PlanBadge = ({ planId, small, className }) => {
  const premiumPlanKeys = getConfigValue('general.pricing.plan_keys')
  const premiumPlanCards = getConfigValue('general.pricing.premium_plan_cards')

  const plan = premiumPlanCards[planId] ||
    premiumPlanCards[premiumPlanKeys[0]]

  return (
    <div className={classNames('plan-badge', {
      'plan-badge--small': small,
    }, className)}
    >
      <Text>
        {t(`general.premium_packs.${plan.i18nKey}.title`)}
      </Text>
    </div>
  )
}
