import { getQueryResult } from 'utils/search'

export const queryHitsSelector = (query, searchGroup, pageIndex) => state => {
  const searchQuery = getQueryResult(state.search.searchQueries, query, searchGroup, pageIndex)

  if (searchQuery) {
    return { hits: searchQuery.hits, metadata: searchQuery.metadata }
  }

  return {}
}

export const queryIsLoadingSelector = state =>
  state.search.loading
