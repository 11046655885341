import React from 'react'

import { TAB_IDS } from 'scenes/UserDetails'

import { Image } from 'components/Image'
import { LoadingWrapper } from 'components/LoadingComponent'
import { UploadFeedback } from 'components/UploadFeedback'

import { UserGallery, UserGallerySkeleton } from '../UserGallery'
import { UserPack, UserPackSkeleton } from '../UserPack'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import './styles.scss'

const GenericUserCard = props => {
  return (
    <LoadingWrapper
      isLoading={props.isLoading}
      skeleton={props.loadingSkeleton}
    >
      <div className='user-card__generic-card-wrapper'>
        {props.children}
      </div>
      {props.uploadStatus && (
        <div className='user-card__upload-feedback'>
          <UploadFeedback
            uploading={props.uploadStatus === UPLOAD_STATUS.UPLOADING}
            uploaded={props.uploadStatus === UPLOAD_STATUS.UPLOADED}
            progressValue={props.progressValue}
          />
        </div>
      )}
    </LoadingWrapper>
  )
}

export const UserCard = ({ tabId, card, cardIdx, progressValue }) => {
  return (
    <GenericUserCard
      isLoading={tabId === TAB_IDS.PORTFOLIO ? false : !card.title}
      loadingSkeleton={tabId === TAB_IDS.PACKS ? <UserPackSkeleton /> : <UserGallerySkeleton />}
      cardIdx={cardIdx}
      uploadStatus={card.uploadStatus}
      progressValue={progressValue}
    >
      {tabId === TAB_IDS.PORTFOLIO
        ? (
          <Image
            {...card}
            maxHeightInPercentage={card.maxHeight}
            className='user-card__card-image'
            alt='pack image 1'
            thumbnailsSpecs={[
              { size: 's', media: '(max-width: 600px)' },
              { size: 'l', media: '(min-width: 600px)' },
            ]}
            lazyLoaded
            generateThumbnailIfError
          />
        )
        : null}
      {tabId === TAB_IDS.PACKS
        ? (
          <UserPack
            packId={card.id}
            image={card.images?.find(image => image.id === card.mainImageId) || card.images?.[0]}
            title={card.title}
            price={card.price}
            description={card.description}
            uploading={card.uploadStatus === UPLOAD_STATUS.UPLOADING}
          />
        )
        : null}
      {tabId === TAB_IDS.GALLERIES
        ? (
          <UserGallery
            galleryId={card.id}
            image={card.coverPhoto}
            title={card.title}
            galleryPhotosLength={card.photosLayout?.length}
          />
        )
        : null}
    </GenericUserCard>
  )
}
