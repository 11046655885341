import {
  getFunctions,
  getStorage,
  getGalleryPhoto,
  getPackPhoto,
  getUserPortfolioPhoto,
} from './'
import { httpsCallable } from 'firebase/functions'
import { ref, deleteObject, getDownloadURL, uploadBytes, uploadBytesResumable } from 'firebase/storage'

import { logError } from 'utils/errorCapture'

const getFileExtensionFromSrc = src =>
  src.split('.').pop().split('?')[0]

const getFileExtension = file =>
  file.name.split('.').pop()

export const V2_IMAGE_SUFIX = '_v2'

export const postUserImage = (item, userUid) => {
  const splitedFileName = item.file?.name.split('.') || item.name.split('.') // if a blob is given instead of a file, a name should be also added
  const fileType = splitedFileName[splitedFileName.length - 1]
  const storageRef = ref(getStorage(), `users/${userUid}/avatar.${fileType}`)

  return uploadBytes(storageRef, item.file || item.blob)
    .then(snapshot => {
      console.log('Uploaded the main image of user: ' + userUid)
      return getDownloadURL(snapshot.ref) // return download URL
    })
    .then(downloadUrl => {
      // Remove the token query parameter from the URL
      // This way the Firebase Storage rules are not overriden with the token
      // This way, gallery main images cannot be accessed if not permited
      const urlWithoutToken = downloadUrl.replace(/&token=.*/, '')
      return urlWithoutToken
    })
    .catch(err => {
      logError(err, `There was a problem uploading avatar blog for user ${userUid}: ${err.message}`)
      throw err
    })
}

export const postPackImage = (file, imageId, userUid, packId) => {
  const imagePath = `users/${userUid}/packs/${packId}/${imageId}.${getFileExtension(file)}`
  const storageRef = ref(getStorage(), imagePath)

  return uploadBytes(storageRef, file)
    .then(snapshot => {
      console.log(`Uploaded image for pack ${packId} of user ${userUid}`)
      return getDownloadURL(snapshot.ref) // return download URL
    })
    .then(downloadUrl => {
      // Remove the token query parameter from the URL
      // This way the Firebase Storage rules are not overriden with the token
      // This way, gallery main images cannot be accessed if not permited
      const urlWithoutToken = downloadUrl.replace(/&token=.*/, '')
      return urlWithoutToken
    })
    .catch(err => {
      logError(err, `There was a problem uploading pack ${packId} image of user: ${userUid}: ${err.message}`)
      throw err
    })
}

export const postPackImages = (files, userUid, packId) =>
  Promise.all(files.map(file => postPackImage(file, file.imageId, userUid, packId)))

export const postPortfolioImage = (item, userUid, progressCallback) => {
  if (!item.file) {
    return Promise.resolve(item.src)
  }

  const portfolioImageRef = ref(getStorage(), `users/${userUid}/portfolio/${item.id}.${getFileExtension(item.file)}`)
  const uploadTask = uploadBytesResumable(portfolioImageRef, item.file)

  return new Promise((resolve, reject) => {
    uploadTask
      .on('state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          progressCallback && progressCallback(progress)
        },
        err => {
          logError(err, `There was a problem uploading porfolio images of user: ${userUid}: ${err.message}`)
          reject(err)
        },
        () => {
          console.log(`Uploaded porfolio images of user ${userUid}`)
          getDownloadURL(uploadTask.snapshot.ref)
            .then(downloadUrl => {
              // Remove the token query parameter from the URL
              // This way the Firebase Storage rules are not overriden with the token
              // This way, gallery main images cannot be accessed if not permited
              const urlWithoutToken = downloadUrl.replace(/&token=.*/, '')
              resolve(urlWithoutToken) // return download URL
            })
        })
  })
}

export const postPortfolioImages = (items, userUid) =>
  Promise.all(items.map(item => postPortfolioImage(item, userUid)))

export const postGalleryImage = (file, galleryId, photoId, progressCallback) => {
  const galleryImageRef = ref(getStorage(), `galleries/${galleryId}/${photoId}.${getFileExtension(file)}`)
  const uploadTask = uploadBytesResumable(galleryImageRef, file)

  return new Promise((resolve, reject) => {
    uploadTask
      .on('state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          progressCallback && progressCallback(photoId, progress, file.name)
        },
        err => {
          logError(err, `There was a problem uploading gallery ${galleryId} image: ${err.message}`)
          reject(err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(downloadUrl => {
              // Remove the token query parameter from the URL
              // This way the Firebase Storage rules are not overriden with the token
              // This way, gallery main images cannot be accessed if not permited
              const urlWithoutToken = downloadUrl.replace(/&token=.*/, '')
              resolve(urlWithoutToken) // return download URL
            })
        })
  })
}

export const deleteImage = imageSrc => {
  const imageRef = ref(getStorage(), imageSrc)

  return deleteObject(imageRef)
    .then(() => {
      console.log(`Image with src ${imageSrc} was deleted`)
      return
    })
    .catch(err => {
      logError(err, `There was a problem deleting image with src ${imageSrc}: ${err.message}`)
      throw err
    })
}

export const deleteImages = imageSrcs =>
  Promise.all(imageSrcs.map(deleteImage))

export const generateThumbnailsFunctionUrl = imageSrc => {
  const generateThumbsForUrlCall = httpsCallable(getFunctions(), 'generateThumbsForUrlCall')

  return generateThumbsForUrlCall({ imageSrc })
    .then(() => {
      console.log(`Thumbnails for image ${imageSrc} is now created!`)
    })
    .catch(error => {
      logError(error, `Error creating thumbnails for image ${imageSrc}: ${error}`)
      throw error
    })
}

export const getImageV2DataFromSrc = imageSrc => {
  const decodedUri = decodeURIComponent(imageSrc)

  // gallery image src example: https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/galleries/d7fa6a7d-5f5e-4051-b5ea-ce68bb623dbc/0696f908-ba46-477c-ab1e-da3d8d43632c.JPG?alt=media&token=3fdb0337-4d63-42e4-860a-53c4d354b236
  if (decodedUri.includes('/galleries/')) {
    // gallery images
    const src2ndPart = decodedUri.split('/galleries/')[1]
    const galleryIdsString = src2ndPart.split('.')[0]
    const [galleryId, photoId] = galleryIdsString.split('/')

    return getGalleryPhoto(galleryId, photoId)

  // pack image src example: https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/users/jBUUBcORtrM003GhDKB7UvNjYOb2/packs/1673aec2-61bb-4654-9663-fcfddb805685/c8751b48-bf56-4aca-b4aa-d450c8c12181.jpg?alt=media&token=95533978-9faf-4fef-88b7-ede129311bdf
  } else if (decodedUri.includes('/packs/')) {
    // pack images
    const src2ndPart = decodedUri.split('/packs/')[1]
    const idsString = src2ndPart.split('.')[0]
    const [packId, photoId] = idsString.split('/')

    return getPackPhoto(packId, photoId)

  // portfolio image src example: https://firebasestorage.googleapis.com/v0/b/lenz-dev.appspot.com/o/users/jBUUBcORtrM003GhDKB7UvNjYOb2/portfolio/9d33a03a-1c20-4c61-b429-6e259ace5dc0.JPG?alt=media&token=c2ebfc05-5420-4e52-8f28-31cca5306ee7
  } else if (decodedUri.includes('/portfolio/')) {
    // portfolio images
    const userPart = decodedUri.split('/users/')[1]
    const userId = userPart.split('/portfolio/')[0]

    const portfolioPart = decodedUri.split('/portfolio/')[1]
    const photoId = portfolioPart.split('.')[0]

    return getUserPortfolioPhoto(userId, photoId)
  }

  // other images:
  //  - user avatar

  return Promise.resolve(null)
}

export const postWatermarkImage = (file, userUid, watermarkId, progressCallback) => {
  const watermarkImageRef = ref(getStorage(), `users/${userUid}/watermarks/${watermarkId}.${getFileExtension(file)}`)
  const uploadTask = uploadBytesResumable(watermarkImageRef, file)

  return new Promise((resolve, reject) => {
    uploadTask
      .on('state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          progressCallback && progressCallback(watermarkId, progress, file.name)
        },
        err => {
          logError(err, `There was a problem uploading watermark ${watermarkId} image: ${err.message}`)
          reject(err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(downloadUrl => {
              // Remove the token query parameter from the URL
              // This way the Firebase Storage rules are not overriden with the token
              // This way, watermark main images cannot be accessed if not permited
              const urlWithoutToken = downloadUrl.replace(/&token=.*/, '')
              resolve(urlWithoutToken) // return download URL
            })
        })
  })
}

export const getGalleryImageDownloadUrl = (galleryId, imageId, imageSrc) => {
  const imagePath = `/galleries/${galleryId}/${imageId}.${getFileExtensionFromSrc(imageSrc)}`
  const storageRef = ref(getStorage(), imagePath)
  return getDownloadURL(storageRef)
}
