import {
  logEvent as mockLogEvent,
} from './mockLoggingService'

import {
  logEvent as remoteLogEvent,
} from '../firebaseServer/logger'

import { isMock } from 'utils/envs'

// automatically collected events: https://support.google.com/firebase/answer/9234069?hl=en
// default event keys: https://support.google.com/firebase/answer/9267735?hl=en&ref_topic=6317484&visit_id=637625713270005046-714195743&rd=1
export const EVENT_KEYS = {
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  SEARCH: 'search',
  CREATE_PACK: 'create_pack',
  CONTACT_PROFESSIONAL: 'contact_professional',
  REGISTER_JOURNEY: {
    NEXT_CTA: 'register_journey_next_cta',
    BACK_CTA: 'register_journey_back_cta',
    SKIP_CTA: 'register_journey_skip_cta',
  },
  ERROR: 'error',
  DELETE_ACCOUNT: 'delete_account',
}

export const logEvent = (eventKey, data) => {
  if (isMock()) {
    return mockLogEvent(eventKey, data)
  }

  return remoteLogEvent(eventKey, data)
}

const getSignUpMethod = providerId => {
  switch (providerId) {
    case 'password': return 'email address'
    default: return ''
  }
}

export const logSignUp = (providerId, userUid) => {
  if (isMock()) {
    return mockLogEvent(EVENT_KEYS.SIGN_UP)
  }

  return remoteLogEvent(EVENT_KEYS.SIGN_UP, {
    method: getSignUpMethod(providerId),
    userUid,
  })
}

export const logLogin = (providerId, userUid) => {
  if (isMock()) {
    return mockLogEvent(EVENT_KEYS.LOGIN)
  }

  return remoteLogEvent(EVENT_KEYS.LOGIN, {
    method: getSignUpMethod(providerId),
    userUid,
  })
}
