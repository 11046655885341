import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check'
import { getFirebaseApp } from 'services/firebaseServer'
import { isLocal } from 'utils/envs'

const AppCheckService = (function () {
  var appCheckService

  return {
    initService: () => {

      // App Check needs to be properly configured to run in local environments
      // https://firebase.google.com/docs/app-check/web/debug-provider#web-modular-api
      // if FIREBASE_APPCHECK_DEBUG_TOKEN true, it will generate a new token in the Browser devtools
      if (isLocal()) {
        window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APP_CHECK_DEBUG_TOKEN ?? true
      }

      const firebaseApp = getFirebaseApp()
      appCheckService = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_ENTERPRISE_PUBLIC_KEY),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
      })
    },

    getInstance: () => {
      if (appCheckService === undefined) {
        this.initService()

        return appCheckService
      }

      return appCheckService
    },
  }
})()

export const initiateBotPreventionService = () => {
  AppCheckService.initService()
}
