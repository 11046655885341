import React from 'react'

import { ClientOfflineError } from './components/ClientOfflineError'
import { GalleryNotFoundError } from './components/GalleryNotFoundError'
import { GenericError } from './components/GenericError'
import { PackNotFoundError } from './components/PackNotFoundError'
import { PageNotFoundError } from './components/PageNotFoundError'
import { UserNotFoundError } from './components/UserNotFoundError'

import { errors } from 'services/errorHandling'

export const Error = props => {
  switch (props.error?.name) {
    case errors.PAGE_NOT_FOUND:
      return <PageNotFoundError />
    case errors.PACK_NOT_FOUND:
      return <PackNotFoundError />
    case errors.USER_NOT_FOUND:
      return <UserNotFoundError />
    case errors.GALLERY_NOT_FOUND:
      return <GalleryNotFoundError />
    case errors.CLIENT_OFFLINE:
      return <ClientOfflineError error={props.error} />
    default:
      return <GenericError error={props.error} />
  }
}
