import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Menu from '@mui/icons-material/Menu'
import Icon from '@mui/material/Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'
import React, { useState, useRef } from 'react'

import { IconButton } from 'components/Button'
import { Link } from 'components/Link'
import { Text } from 'components/Text'

import { useMenuItemActive } from '../../hooks/useMenuItemActive'

import { useOnClickOutside } from 'hooks/useOnClickOutside'

import './styles.scss'

export const SideMenu = props => {
  const [ sideMenuIsOpen, setSideMenuIsOpen ] = useState(false)
  const sideBarRef = useRef(null)
  useOnClickOutside(sideBarRef, () => closeSideMenu(), sideMenuIsOpen)
  const activeMenuItemId = useMenuItemActive(props.menuItems, props.activeId)

  const toggleSideMenu = event => {
    event.stopPropagation()
    setSideMenuIsOpen(!sideMenuIsOpen)
  }
  const closeSideMenu = () => setSideMenuIsOpen(false)

  return (
    <div ref={sideBarRef} className='side-bar'>
      {/* Used to maintain the width of the side bar */}
      <div className={classNames('side-bar__list-wrapper', {
        'side-bar__list-wrapper--open': sideMenuIsOpen,
      })}
      >
        {/* Used to animate the width of the side bar when it opens */}
        <ul className='side-bar__list'>
          <li className='side-bar__list__item side-bar__list__item--menu'>
            <IconButton onClick={toggleSideMenu}>
              {!sideMenuIsOpen ? <Menu /> : <ChevronLeftIcon />}
            </IconButton>
          </li>
          <li>
            <hr className='side-bar__list__divider' />
          </li>
          {props.menuItems.map(page => {
            const id = `${page.id}-menu-item`
            return (
              <Link href={page.url} key={page.id}>
                <li
                  className={classNames('side-bar__list__item', {
                    'side-bar__list__item--active': activeMenuItemId === page.id,
                  })}
                  key={page.id}
                  id={id}
                >
                  <Tooltip arrow disableInteractive title={sideMenuIsOpen ? '' : page.text} placement='right'>
                    <button className='side-bar__list__item-button'>
                      <ListItemIcon><Icon>{page.icon}</Icon></ListItemIcon>
                      <Text className='side-bar__list__item-button__text' as='p'>{page.text}</Text>
                    </button>
                  </Tooltip>
                </li>
              </Link>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
