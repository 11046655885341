import FilterListIcon from '@mui/icons-material/FilterList'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useState } from 'react'

import { IconButton } from 'components/Button'

export const FilterMenu = ({ onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [markedPhotosFilterIsChecked, setMarkedPhotosFilterIsChecked] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onMarkedPhotosCheckboxChange = event => {
    const value = event.target.checked
    setMarkedPhotosFilterIsChecked(value)
    onFilterChange('markedPhotosFilter', value)
  }

  return (
    <div>
      <IconButton
        size='small'
        aria-label='filter'
        onClick={handleClick}
      >
        <FilterListIcon fontSize='medium' />
      </IconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {}} disableRipple>
          <FormControlLabel control={<Checkbox checked={markedPhotosFilterIsChecked} onChange={onMarkedPhotosCheckboxChange} />} label='Fotos marcadas' />
        </MenuItem>
      </Menu>
    </div>
  )
}
