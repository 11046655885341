import { UPLOAD_STATUS } from 'model/UploadStatus'

import { addUserWatermark, removeUserWatermark } from 'store/users/actions'

import {
  postWatermark,
  getUserWatermarks,
  getUserWatermark,
  putWatermark,
  deleteWatermark,
} from 'services/serverBridge'

export const REQUEST_WATERMARKS = 'REQUEST_WATERMARKS'
export const RECEIVE_WATERMARKS_DATA = 'RECEIVE_WATERMARKS_DATA'
export const ADD_WATERMARK_DATA = 'ADD_WATERMARK_DATA'
export const REMOVE_WATERMARK_DATA = 'REMOVE_WATERMARK_DATA'
export const EDIT_WATERMARK_GALLERY_DATA = 'EDIT_WATERMARK_GALLERY_DATA'

function requestWatermarksAction() {
  return {
    type: REQUEST_WATERMARKS,
  }
}

function receiveWatermarksAction(watermarks) {
  return {
    type: RECEIVE_WATERMARKS_DATA,
    data: {
      watermarks,
    },
  }
}

function addWatermarkAction(watermark) {
  return {
    type: ADD_WATERMARK_DATA,
    data: {
      watermark,
    },
  }
}

function removeWatermarkAction(watermarkId) {
  return {
    type: REMOVE_WATERMARK_DATA,
    data: {
      watermarkId,
    },
  }
}

function addGalleryToWatermarkAction(watermarkId, galleryId, remove) {
  return {
    type: EDIT_WATERMARK_GALLERY_DATA,
    data: {
      watermarkId,
      galleryId,
      remove,
    },
  }
}

export const fetchUserWatermarks = user => {
  return dispatch => {
    dispatch(requestWatermarksAction())

    return getUserWatermarks(user.uid)
      .then(watermarks => {
        dispatch(receiveWatermarksAction(watermarks))
      })
  }
}

export const fetchUserWatermark = (user, watermarkId) => {
  return dispatch => {

    return getUserWatermark(user.uid, watermarkId)
      .then(watermark => {
        dispatch(addWatermarkAction(watermark))
      })
  }
}

export const editWatermark = (user, watermark) => {
  return dispatch => {
    dispatch(addWatermarkAction({
      ...watermark,
      uploadingStatus: UPLOAD_STATUS.UPLOADING,
    }))

    return putWatermark(user.uid, watermark)
      .then(() => {
        dispatch(addWatermarkAction({
          ...watermark,
          uploadingStatus: UPLOAD_STATUS.UPLOADED,
        }))
      })
  }
}

export const addWatermark = (user, watermark) => {
  return dispatch => {
    // add watermark id to watermarks property in user data, only in local storage
    dispatch(addUserWatermark(user, watermark))

    dispatch(addWatermarkAction({
      ...watermark,
      uploadingStatus: UPLOAD_STATUS.UPLOADING,
    }))

    return postWatermark(user.uid, watermark)
      .then(uploadedWatermark => {
        dispatch(addWatermarkAction({
          ...watermark,
          image: uploadedWatermark.image,
          uploadingStatus: UPLOAD_STATUS.UPLOADED,
        }))
      })
  }
}

export const removeWatermark = (user, watermark) => {
  return dispatch => {
    // remove watermark id from watermarks property in user data, only in local storage
    dispatch(removeUserWatermark(user, watermark))

    dispatch(removeWatermarkAction(watermark.id))

    return deleteWatermark(user.uid, watermark)
  }
}

export const addGalleryToWatermark = (watermarkId, galleryId) => {
  return dispatch => {
    dispatch(addGalleryToWatermarkAction(watermarkId, galleryId))
  }
}

export const removeGalleryFromWatermark = (watermarkId, galleryId) => {
  return dispatch => {
    dispatch(addGalleryToWatermarkAction(watermarkId, galleryId, true))
  }
}
