import { SimpleMessageModal } from 'components/Modal'
import React, { useCallback, useState } from 'react'

export const GlobalConfirmationModalContext = React.createContext(undefined)

let modalId = 1

export const GlobalConfirmationModalProvider = ({ children }) => {
  const [modals, setModals] = useState([])

  const showModal = useCallback(
    modalContext => {
      return new Promise(resolve => {
        setModals(prev => {
          return [...prev, { context: modalContext, resolve, id: modalId++ }]
        })
      })
    },
    []
  )

  const closeModal = useCallback(data => {
    setModals(prev => {
      const newModals = [...prev]
      const lastModal = newModals.pop()
      lastModal?.resolve(data ?? false)
      return newModals
    })
  }, [])

  return (
    <GlobalConfirmationModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      {modals.map(modal => {
        const { modalComponent: ModalComponent, ...otherProps } = modal.context

        if (ModalComponent) {
          return (
            <ModalComponent
              key={modal.id}
              open
              closeModal={() => closeModal(false)}
              {...otherProps}
            />
          )
        }

        return (
          <SimpleMessageModal
            key={modal.id}
            open
            closeModal={() => closeModal(false)}
            actionButtonText={modal.context.actionButtonText ?? 'Ok'}
            secondaryActionButtonText={modal.context.secondaryActionButtonText ?? 'Cancelar'}
            actionButtonColor={modal.context.actionButtonColor ?? 'primary'}
            secondaryActionButtonVariant={modal.context.secondaryActionButtonVariant ?? 'outlined'}
            secondaryActionButtonColor={modal.context.secondaryActionButtonColor ?? 'default'}
            onActionButton={() => closeModal(true)}
            onSecondaryActionButton={() => closeModal(false)}
            {...modal.context}
          />
        )
      })}
    </GlobalConfirmationModalContext.Provider>
  )
}
