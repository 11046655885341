import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { AutoCompleteInput } from 'components/AutoCompleteInput'
import { Image } from 'components/Image'

import PoweredByGoogleImg from 'assets/powered_by_google_on_white.png'

import { getAutoCompleteSuggestion, initializeLocationService } from 'services/locationService'

import './styles.scss'

const renderOption = option => {
  const matches = option.structured_formatting.main_text_matched_substrings
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map(match => [match.offset, match.offset + match.length])
  )

  return (
    <Grid container alignItems='center'>
      <Grid item>
        <LocationOnIcon className='' />
      </Grid>
      <Grid item xs>
        {parts.map((part, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}

        <Typography variant='body2' color='textSecondary'>
          {option.structured_formatting.secondary_text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const AutoCompleteLocation = props => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    initializeLocationService()
  }, [])

  React.useEffect(() => {
    let active = true

    getAutoCompleteSuggestion(inputValue)
      .then(results => {
        if (active) {
          let newOptions = []

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
      })

    return () => {
      active = false
    }
  }, [inputValue])

  const onAutocompleteChange = value => props.onAutocompleteChange?.(value)

  const autoCompleteFooter = () => {
    return (
      <div className='auto-complete-location__paper-footer'>
        <Image src={PoweredByGoogleImg} />
      </div>
    )
  }

  return (
    <AutoCompleteInput
      initialInputValue={props.initialInputValue}
      className={classNames('auto-complete-location__auto-complete-input', props.className)}
      inputLabel={props.inputLabel}
      renderOption={renderOption}
      onAutocompleteChange={onAutocompleteChange}
      onInputChange={setInputValue}
      isLoading={false}
      results={options}
      optionLabel={option => option.description}
      paperFooter={autoCompleteFooter()}
    />
  )
}
