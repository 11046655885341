import { nanoid } from 'nanoid'

import { t } from 'services/i18n'
import { checkIfUsernameExists } from 'services/serverBridge'

export const getErrorType = error => {
  // list of errors: https://firebase.google.com/docs/auth/admin/errors

  // The provided value for the displayName user property is invalid. It must be a non-empty string.
  if (error.code === 'auth/invalid-display-name') {
    return 'name'
  }

  // The provided value for the email user property is invalid. It must be a string email address.
  if (error.code === 'auth/invalid-email') {
    return 'email'
  }

  // The provided value for the password user property is invalid. It must be a string with at least six characters.
  if (error.code === 'auth/invalid-password') {
    return 'password'
  }

  if (error.code === 'auth/wrong-password') {
    return 'password'
  }

  // There is no existing user record corresponding to the provided identifier.
  if (error.code === 'auth/user-not-found') {
    return 'email'
  }

  // There is no existing user record corresponding to the provided identifier.
  if (error.code === 'auth/weak-password') {
    return 'password'
  }

  // The provided email is already in use by an existing user. Each user must have a unique email.
  // If user already exists, this means that the user data was not created because of some error
  // (This true because we check if the inserted email exists in the users data)
  // Therefore, we need to delete the user server side and ask the user to try again
  if (error.code === 'auth/email-already-exists') {
    return 'email'
  }

  // The provided email is already in use by an existing user. Each user must have a unique email.
  if (error.code === 'auth/email-already-in-use') {
    return 'email'
  }

  return 'email'
}

export const getErrorMessage = (error, defaultErrorMessage) => {
  // list of errors: https://firebase.google.com/docs/auth/admin/errors

  // The provided value for the displayName user property is invalid. It must be a non-empty string.
  if (error.code === 'auth/invalid-display-name') {
    return t('sign_up.error.invalid_name')
  }

  // The provided value for the email user property is invalid. It must be a string email address.
  if (error.code === 'auth/invalid-email' || error.code === 'auth/invalid-new-email') {
    return t('sign_up.error.invalid_email')
  }

  // The provided value for the password user property is invalid. It must be a string with at least six characters.
  if (error.code === 'auth/invalid-password') {
    return t('sign_up.error.invalid_password')
  }

  if (error.code === 'auth/wrong-password') {
    return t('sign_up.error.wrong_password')
  }

  // There is no existing user record corresponding to the provided identifier.
  if (error.code === 'auth/user-not-found') {
    return t('sign_up.error.user_not_found')
  }

  // There is no existing user record corresponding to the provided identifier.
  if (error.code === 'auth/weak-password') {
    return t('sign_up.error.weak_password')
  }

  // The provided email is already in use by an existing user. Each user must have a unique email.
  // If user already exists, this means that the user data was not created because of some error
  // (This true because we check if the inserted email exists in the users data)
  // Therefore, we need to delete the user server side and ask the user to try again
  if (error.code === 'auth/email-already-exists') {
    return t('sign_up.error.email_already_exists')
  }

  // The provided email is already in use by an existing user. Each user must have a unique email.
  if (error.code === 'auth/email-already-in-use') {
    return t('sign_up.error.email_already_exists')
  }

  return defaultErrorMessage ?? t('sign_up.error.generic_error')
}

export const generateNewUsername = async (name, uid) => {
  let newUsername = name.toLowerCase() // to lower case
  newUsername = newUsername.replace(/ /g, '-') // replace spaces with '-'
  newUsername = newUsername.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '') // remove special characters
  newUsername = newUsername.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove accents/diacritics

  let usernameAlreadyExists = await checkIfUsernameExists(newUsername)
  if (!usernameAlreadyExists) {
    return newUsername
  }

  newUsername = `${newUsername}-${nanoid(6)}`
  usernameAlreadyExists = await checkIfUsernameExists(newUsername)
  if (!usernameAlreadyExists) {
    return newUsername
  }

  return uid
}
