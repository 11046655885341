export const currentLogedInUser = state => {
  const logedInUserId = state.users.currentUser?.uid
  return logedInUserId !== undefined && state.users.users[state.users.currentUser.uid]
}

export const currentUserPacksSelector = state => {
  if (state.packs?.loading !== false) {
    return []
  }

  const currentUser = state.users.currentUser && state.users.users[state.users.currentUser.uid]
  const packsIds = currentUser?.packsIds || []

  return packsIds
    .map(userPackId => state.packs.data?.[userPackId] || {})
}

export const currentUserGalleriesSelector = state => {
  const currentUserId = state.users.currentUser.uid
  const galleries = state.galleries.data || {}
  const userGalleryKeys = Object.keys(galleries)
    .filter(galleryKey => galleries[galleryKey].userUid === currentUserId)

  return userGalleryKeys.reduce((prev, currGalleryKey) => {
    return {
      ...prev,
      [currGalleryKey]: galleries[currGalleryKey],
    }
  }, {})
}

export const currentUserHiddenPacksSelectors = state => {
  if (state.packs?.loading !== false) {
    return []
  }

  const currentUser = state.users.currentUser && state.users.users[state.users.currentUser.uid]
  const hiddenPacksIds = currentUser?.hiddenPacksIds || []

  return hiddenPacksIds
    .map(userPackId => state.packs.data?.[userPackId] || { id: userPackId })
}

export const portfolioDataByBreakpointSelector = (breakpoint, userId) => state => {
  const contextUserId = userId || state.users.currentUser?.uid
  const user = state.users.users[contextUserId]
  if (!user?.portfolio) {
    return []
  }

  const basePortfolioCards = user.portfolio.cards || {}
  const userLayouts = user.portfolio.layouts || {}
  let layoutKey = Object.keys(userLayouts)
    .find(layoutKey => userLayouts[layoutKey].breakpoints?.includes(breakpoint))

  // if the layout for the specified breakpoint does not exist, let's use the default layout, if available (only not available if it's a really old gallery)
  if (!layoutKey) {
    layoutKey = Object.keys(userLayouts)
      .find(layoutKey => userLayouts[layoutKey].id === 'default')
  }

  // if layout for the present breakpoint is not available, return the base portfolio
  if (!layoutKey) {
    return Object.values(user.portfolio.cards)
  }

  const layoutsData = user.portfolio.layoutsData || {}
  // there was a bug where a new layout was created without any card associated.
  // To prevent this, default cards are used as back up, since if the layout has really 0 cards, then the default cards would also have 0 cards (since portfolio cards deletion are layout agnostic)
  const layoutDataCards = layoutsData[layoutKey]?.cards || user.portfolio.cards || []
  return layoutDataCards.map(layoutCard => {
    const basePortfolioCard = basePortfolioCards[layoutCard.id]
    return {
      ...basePortfolioCard,
      ...layoutCard,
    }
  })
}
