import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { SimpleImage } from 'components/Image/components/SimpleImage'

import { WindowSizeContext } from 'hooks/useWindowSize'

import './styles.scss'

/**
 * Image type to be used when the image size is pre known
 * In this type, the wrapper of the image will be rendered, saving the correct space in the UI for the image render
 * The height of the image is calculated dynamically accordingly with the *image wrapper width*
 * To use width as the final size of the image, use SizedImage
 */
export const ContainedImage = props => {
  const { alt, wrapperClassName, minHeight, maxHeight: maxHeightProps, ...otherProps } = props
  const imageRef = useRef(null)
  const [windowSize] = useContext(WindowSizeContext)
  const [wrapperOffsetWidth, setWrapperOffsetWidth] = useState(imageRef.current?.offsetWidth)

  useEffect(() => {
    if (props.width && props.height) {
      const imageWrapperWidth = props.src !== undefined ? imageRef.current?.offsetWidth : props.width
      const scaledHeight = props.height * imageWrapperWidth / props.width
      const maxHeight = props.maxHeightInPercentage ? props.maxHeightInPercentage * scaledHeight : maxHeightProps
      if (maxHeight && maxHeight < scaledHeight) {
        imageRef.current.style.setProperty('--image-height', maxHeight + 'px')
      } else if (minHeight && scaledHeight < minHeight) {
        imageRef.current.style.setProperty('--image-height', minHeight + 'px')
      } else {
        imageRef.current.style.setProperty('--image-height', scaledHeight + 'px')
      }
    }
  }, [
    imageRef,
    props.height,
    maxHeightProps,
    props.maxHeightInPercentage,
    props.src,
    props.width,
    minHeight,
    windowSize,
    wrapperOffsetWidth,
  ])

  // This useEffect will make all calculations run again when the image wrapper width is changed, for instance, when the user changes the width of an image in the Backoffice Layout page
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (imageRef.current?.offsetWidth !== wrapperOffsetWidth) {
      setWrapperOffsetWidth(imageRef.current?.offsetWidth)
    }
  })

  return (
    <div ref={imageRef} className={classNames('contained-image', wrapperClassName)}>
      <SimpleImage
        className={props.className && props.className}
        alt={alt}
        {...otherProps}
      />
    </div>
  )
}
