import { useRef, useEffect, useCallback } from 'react'

var intersectionObserver
var observedElements

export const useIntersectionObserver = () => {
  const lazyloadThrottleTimeout = useRef()

  const lazyload = useCallback(() => {
    if (lazyloadThrottleTimeout.current) {
      clearTimeout(lazyloadThrottleTimeout.current)
    }

    lazyloadThrottleTimeout.current = setTimeout(() => {
      const scrollTop = window.pageYOffset
      Object.keys(observedElements)
        .forEach(elmt => {
          if (elmt.offsetTop < (window.innerHeight + scrollTop)) {
            elmt.callback()
            delete observedElements[elmt.id]
          }
        })
    }, 20)
  }, [])

  useEffect(() => {
    if (intersectionObserver !== undefined) {
      return
    }

    if ('IntersectionObserver' in window) {
      intersectionObserver = new IntersectionObserver((entries, _observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const targetElement = entry.target
            const elementId = targetElement.dataset.id
            observedElements[elementId].callback()
            intersectionObserver.unobserve(targetElement)
            delete observedElements[elementId]
          }
        })
      })
    } else {
      document.addEventListener('scroll', lazyload)
      window.addEventListener('resize', lazyload)
      window.addEventListener('orientationChange', lazyload)
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect()
      } else {
        document.removeEventListener('scroll', lazyload)
        window.removeEventListener('resize', lazyload)
        window.removeEventListener('orientationChange', lazyload)
      }
    }
  }, [lazyload])

  const observeElement = useCallback((element, id, callback) => {
    if (!element || !id || !callback) {
      return
    }

    observedElements = {
      ...observedElements,
      [id]: {
        element,
        callback,
      },
    }

    if (intersectionObserver) {
      intersectionObserver.observe(element)
    }
  }, [])

  return [observeElement]
}
