import { Divider } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useLocalActions } from 'scenes/BackOffice/actions/useLocalActions'

import { Breadcrumb } from 'components/Breadcrumb/index.js'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { LoadingComponent } from 'components/LoadingComponent/index.js'
import { MenuWrapper } from 'components/MenuWrapper'
import { Modal, SimpleMessageModal } from 'components/Modal'
import { PackInput } from 'components/PackInput'
import { PackPin } from 'components/PackPin'
import { Text } from 'components/Text'
import { UploadFeedback } from 'components/UploadFeedback'

import { GenericAddButton } from '../../components/GenericAddButton/index.js'

import { useModal } from 'hooks/useModal'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import { allPacksAreLoaded } from 'store/packs/selectors'
import { currentLogedInUser, currentUserPacksSelector, currentUserHiddenPacksSelectors } from 'store/users/selectors'

import { t } from 'services/i18n'
import { changeRoute, createPackLink } from 'services/routingService'
import { deletePack as deletePackServer } from 'services/serverBridge'

import './styles.scss'

const renderPack = ({ userPack, mainImage, openModal, options }) => {
  return (
    <MenuWrapper key={userPack.id} editMode={userPack.uploadStatus !== UPLOAD_STATUS.UPLOADING} options={options}>
      <PackPin
        packId={userPack.id}
        title={userPack.title}
        price={userPack.price}
        description={userPack.description}
        headerLabel=''
        headerImageSize={{
          imgWidth: mainImage?.width,
          imgHeight: mainImage?.height,
        }}
        sized
        headerImageSrc={mainImage?.src}
        onClick={openModal}
        noInfo
      />
      {userPack.uploadStatus && (
        <div className='user-card__upload-feedback'>
          <UploadFeedback
            uploading={userPack.uploadStatus === UPLOAD_STATUS.UPLOADING}
            uploaded={userPack.uploadStatus === UPLOAD_STATUS.UPLOADED}
          />
        </div>
      )}
    </MenuWrapper>
  )
}

let modalPackContext // saves user pack id and visibility in modal context

export const Packs = () => {
  const history = useHistory()
  const logedInUser = useSelector(currentLogedInUser)

  const userPacksIds = logedInUser.packsIds
  const userHiddenPacksIds = logedInUser.hiddenPacksIds
  const userHasLoaded = logedInUser.username
  const hasNotPacks = userHasLoaded && userPacksIds.length === 0 && userPacksIds.length === 0

  const userPacksAreLoaded = useSelector(allPacksAreLoaded([...userPacksIds, ...userHiddenPacksIds]))
  const userPacks = useSelector(currentUserPacksSelector)
  const userHiddenPacks = useSelector(currentUserHiddenPacksSelectors)
  const isLoading = useSelector(state => state.packs?.loading ?? true)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [removePackModalIsOpen, openRemovePackModal, closeRemovePackModal, removePackModalContext] = useModal()

  const {
    uploadPack,
    editPack,
    addUserPack,
    hideUserPack,
    showUserHiddenPack,
    fetchUserPacks,
    removeUserPack,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    if (!userPacksAreLoaded || hasNotPacks) {
      fetchUserPacks()
    }
  }, [fetchUserPacks, userPacksAreLoaded, hasNotPacks])

  const onSavePack = (newPack, visibility) => {
    if (modalPackContext === undefined) {
      addUserPack(newPack.id, false, visibility, false) // set the pack in the UI before adding it to server. Only do this after pack is fully uploaded

      uploadPack(newPack, visibility) // Add the pack to packs collection and to the user doc in a single transaction
    } else {
      editPack(newPack, visibility)
    }

    closeModal()
  }

  const changeUserPackVisibility = (packId, newVisibility) => {
    if (newVisibility) {
      showUserHiddenPack(packId)
    } else {
      hideUserPack(packId)
    }
  }

  const deletePack = () => {
    const pack = removePackModalContext
    closeRemovePackModal()
    removeUserPack(pack.id, pack.visibility)
      .then(() => deletePackServer(pack))
  }

  const openModal = (userPackId, visibility = true) => () => {
    if (userPackId) {
      const contextPack = visibility
        ? userPacks.find(userPack => userPack.id === userPackId)
        : userHiddenPacks.find(userPack => userPack.id === userPackId)

      if (contextPack.uploadStatus === UPLOAD_STATUS.UPLOADING) {
        return
      }

      modalPackContext = contextPack
      modalPackContext.visibility = visibility // do not destrcuture so that Pack instance is not lost
    }
    setModalIsOpen(true)
  }

  const closeModal = () => {
    modalPackContext = undefined
    setModalIsOpen(false)
  }

  const onRemovePackModal = (pack, packVisibility) => {
    const removePackModalContext = pack
    removePackModalContext.visibility = packVisibility // do not destrcuture so that Pack instance is not lost
    openRemovePackModal(removePackModalContext)
  }

  const getPackOptions = (userPack, visibility = true) => {
    return [
      {
        text: 'Ver pack',
        onClick: () => changeRoute(history, createPackLink(userPack.id)),
      },
      {
        text: 'Editar pack',
        onClick: openModal(userPack.id, visibility),
      },
      {
        text: visibility === false
          ? 'Mostrar pack no perfil'
          : 'Esconder pack do perfil',
        onClick: () => changeUserPackVisibility(userPack.id, !visibility),
      },
      {
        text: 'Apagar pack',
        onClick: () => onRemovePackModal(userPack, visibility),
      },
    ]
  }

  return (
    <div className='packs'>
      <Breadcrumb
        items={[
          {
            title: t('back_office.packs.title'),
          },
        ]}
      />
      <GenericAddButton label='Adicionar Pack' onClick={openModal(undefined)} />
      {Object.keys(userPacks).length === 0 && !isLoading && (
        <div>
          <p>Ainda não tens nenhum pack criado.</p>
          <Button onClick={openModal(undefined)} variant='outlined' color='primary'>
            Adiciona o teu primeiro pack
          </Button>
        </div>
      )}
      <Grid className='packs__packs' spacing={5} xs={6} sm={4} md={3} xl={2}>
        {userPacks.map(userPack => {
          const mainImage = userPack?.images &&
            (userPack.images.find(image => image.id === userPack.mainImageId) || userPack.images[0])
          return renderPack({
            userPack,
            mainImage,
            openModal: openModal(userPack.id),
            options: getPackOptions(userPack),
          })
        })}
      </Grid>
      {userHiddenPacks?.length > 0 && (
        <div>
          <Text type='title4' className='packs__hidden-packs-title'>Packs Escondidos</Text>
          <Divider />
          <div className='packs__hidden-packs'>
            <Grid spacing={5} xs={6} sm={4} md={3} xl={2}>
              {userHiddenPacks.map(userPack => {
                const mainImage = userPack?.images &&
                  (userPack.images.find(image => image.id === userPack.mainImageId) || userPack.images[0])
                return renderPack({
                  userPack,
                  mainImage,
                  openModal: openModal(userPack.id, false),
                  options: getPackOptions(userPack, false),
                })
              })}
            </Grid>
          </div>
        </div>
      )}

      <Modal
        open={modalIsOpen}
        closeModal={closeModal}
        title={modalPackContext ? t('user_details.pack_input.title_edit') : t('user_details.pack_input.title')}
        className='packs__input-modal'
        headerClassname='packs__input-modal__header'
      >
        <PackInput
          user={logedInUser}
          initialPack={modalPackContext}
          initialPackVisibility={modalPackContext?.visibility}
          savePack={onSavePack}
          onCancel={closeModal}
        />
      </Modal>
      <SimpleMessageModal
        open={removePackModalIsOpen}
        closeModal={closeRemovePackModal}
        title='Apagar pack'
        message='Tens a certeza que pretendes apagar o pack?'
        actionButtonText='Apagar'
        secondaryActionButtonText='Cancelar'
        actionButtonColor='danger'
        secondaryActionButtonColor='default'
        onActionButton={deletePack}
        onSecondaryActionButton={closeRemovePackModal}
      />
      <LoadingComponent
        size={40}
        isLoading={isLoading}
      />
    </div>
  )
}
