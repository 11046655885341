import { findPageById } from 'utils/dynamicStaticPages'

export const getPageFromId = pageId => {
  const staticPagePath = findPageById(pageId)?.pagePath

  // https://medium.com/front-end-weekly/webpack-and-dynamic-imports-doing-it-right-72549ff49234
  return import(
    /* webpackMode: "eager" */
    /* webpackChunkName: "static-pages" */
    `./pages${staticPagePath}/${pageId}-html.html`
  )
    .then(staticPageHtml => {
      console.log('DEBUG_SESSION::staticPageHtml:', staticPageHtml.default)
      return staticPageHtml.default
    })
}
