import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import classNames from 'classnames'
import React from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import { Header } from 'scenes/Header'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { Footer } from 'components/Footer'
import { GlobalNotifications } from 'components/GlobalNotifications'
import { ScrollBoundary } from 'components/ScrollBoundary'
import { SignInRequirement } from 'components/SignInRequirement'
import { ThemedComponent } from 'components/ThemedComponent'
import { UpsellModal } from 'components/UpsellModal'
import { _DevTools as DevTools } from 'components/_DevTools'

import { useWindowSize, WindowSizeContext } from 'hooks/useWindowSize'

import { store } from 'store/root/store'

import { routesConfig, fullScreenRoutesConfig } from 'services/routingService'
import { GlobalConfirmationModalProvider } from 'services/GlobalConfirmationModalProvider'

import { isDevelopmentOrLess } from 'utils/envs'

import './App.scss'

const DefaultTree = ({ component: RouteComponent }) => {
  return (
    <ScrollBoundary>
      <ErrorBoundary>
        <GlobalNotifications />
        <UpsellModal />
        <RouteComponent />
      </ErrorBoundary>
    </ScrollBoundary>
  )
}

const renderRoute = route => {
  const { path, options, requireSignIn, component } = route

  return (
    <Route key={path} path={path} {...options}>
      <SignInRequirement requireSignIn={requireSignIn}>
        {options?.header !== false && <Header />}
        <div className={classNames('app-content', {
          'app-content--no-header': options?.header === false,
          'app-content--no-footer': options?.footer === false,
        })}
        >
          <DefaultTree component={component} />
        </div>
        {options?.footer === false ? null : <Footer />}
      </SignInRequirement>
    </Route>
  )
}

const renderRouteFullScreen = route => {
  const { path, options, requireSignIn, component } = route

  if (options?.acceptedEnvs && !options.acceptedEnvs.includes(process.env.REACT_APP_STAGE)) {
    return null
  }

  return (
    <Route key={path} path={path} {...options}>
      <SignInRequirement requireSignIn={requireSignIn}>
        <DefaultTree component={component} />
      </SignInRequirement>
    </Route>
  )
}

function App() {
  const windowSize = useWindowSize(true)
  const queryClient = new QueryClient()

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <WindowSizeContext.Provider value={windowSize}>
          <ThemedComponent>
            <Router>
              <GlobalConfirmationModalProvider>
                <Switch>
                  {fullScreenRoutesConfig.map(renderRouteFullScreen)}
                  {routesConfig.map(renderRoute)}
                </Switch>
                {isDevelopmentOrLess() && <DevTools />}
              </GlobalConfirmationModalProvider>
            </Router>
          </ThemedComponent>
        </WindowSizeContext.Provider>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
