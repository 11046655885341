import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import './styles.scss'

const UPLOADED_ANIMATION_DURATION = 1200

export const UploadFeedback = props => {
  const { uploaded, uploading, onAnimationEnd, className: customClassName, progressValue } = props

  useBeforeunload(event => {
    if (uploading) {
      event.preventDefault()
    }
  })

  const [uploadAnimationDone, setUploadAnimationDone] = useState(uploading ? false : undefined)

  useEffect(() => {
    if (uploading) {
      setUploadAnimationDone(false)
    }

    if (uploaded && uploadAnimationDone === false) {
      setTimeout(() => {
        setUploadAnimationDone(true)

        onAnimationEnd && setTimeout(() => {
          onAnimationEnd()
        }, 300)

      }, UPLOADED_ANIMATION_DURATION)
    }
  }, [onAnimationEnd, uploadAnimationDone, uploaded, uploading])

  return (
    <div className={classNames('upload-feedback', customClassName)}>
      <Fab
        size='small'
        aria-label='save'
        color='primary'
        className={classNames('upload-feedback__uploaded-icon', {
          'upload-feedback__uploaded-icon--uploaded': uploaded && uploadAnimationDone === false,
          'upload-feedback__uploaded-icon--uploaded-done': uploaded && uploadAnimationDone,
        })}
      >
        {uploaded && uploadAnimationDone !== undefined ? <CheckIcon /> : null}
      </Fab>
      {uploading && <CircularProgress size={40} className='upload-feedback__progress' />}
      {uploading && progressValue !== undefined && (
        <div className='upload-feedback__progress-text'>
          {`${progressValue}%`}
        </div>
      )}
    </div>
  )
}
