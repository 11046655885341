import { Alert, Collapse } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { WatermarkInput } from 'scenes/UserSettings/components/PersonalisationSettings/components/WatermarkInput'

import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Image } from 'components/Image'
import { LoadingComponent } from 'components/LoadingComponent'
import { MenuWrapper } from 'components/MenuWrapper'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { UploadFeedback } from 'components/UploadFeedback'

import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import { currentLogedInUser } from 'store/users/selectors'
import { addWatermark, editWatermark, fetchUserWatermarks, removeWatermark } from 'store/watermarks/actions'

import { t } from 'services/i18n'

import { convertToPng, resizeImage } from 'utils/images'

import './styles.scss'

const MAX_SIZE_IN_BYTES = 6000000 // 6MB
const ALERT_DURATION = 5000

export const PersonalisationSettings = () => {
  const [ alertIsOpen, openAlert ] = useAlert({ timeout: ALERT_DURATION })
  const [modalIsOpen, openModal, closeModal, modalContext ] = useModal()

  const currentUserIsLoading = useSelector(state => state.users.loading)
  const logedInUser = useSelector(currentLogedInUser)
  const watermarksAreLoading = useSelector(state => state.watermarks.loading)
  const watermarksKeys = logedInUser.watermarks || []
  const watermarksData = useSelector(state => state.watermarks.data)

  const dispatch = useDispatch()
  const uploadWatermark = useCallback(
    watermark => dispatch(addWatermark(logedInUser, watermark)),
    [dispatch, logedInUser]
  )

  const deleteWatermark = useCallback(
    watermark => dispatch(removeWatermark(logedInUser, watermark)),
    [dispatch, logedInUser]
  )

  const updateWatermark = useCallback(
    watermark => dispatch(editWatermark(logedInUser, watermark)),
    [dispatch, logedInUser]
  )

  const fetchWatermarks = useCallback(
    () => dispatch(fetchUserWatermarks(logedInUser)),
    [dispatch, logedInUser]
  )

  useEffect(() => {
    if (currentUserIsLoading === false && watermarksAreLoading === undefined && watermarksData === undefined) {
      fetchWatermarks()
    }
  }, [currentUserIsLoading, fetchWatermarks, watermarksAreLoading, watermarksData])

  const sendConvertedImage = (watermark, convertedImageData) => {
    convertedImageData.blob.name = watermark.image.file.name // keep file name
    delete watermark.image.file // delete original file (not resized)
    uploadWatermark({
      ...watermark,
      image: {
        ...watermark.image,
        ...convertedImageData,
      },
    })
  }

  const onAddWatermark = watermark => {
    const imageSizeInBytes = watermark.image.file?.size

    if (imageSizeInBytes > MAX_SIZE_IN_BYTES) {
      openAlert(true)
      // first convert to webp so that image size in bytes is not dependent in image type
      convertToPng(watermark.image.file)
        .then(webpImageData => {
          if (webpImageData.blob.size > MAX_SIZE_IN_BYTES) {
            // resize to a maximum width or height of 600px
            resizeImage({
              imageSrc: webpImageData.src,
              maxWidth: 1600,
              maxHeight: 1600,
              fileFormat: 'image/png',
            })
            .then(convertedImageData => sendConvertedImage(watermark, convertedImageData))
          } else {
            sendConvertedImage(watermark, webpImageData)
          }
        })
    } else {
      modalContext
        ? updateWatermark(watermark)
        : uploadWatermark(watermark)
    }

    closeModal()
  }

  const onDeleteWatermark = watermark => {
    const galleryCount = Object.keys(watermark.galleries || {}).length
    if (galleryCount > 0) {
      return alert(t('profile_settings.personalisation_settings.watermark.watermark_in_use'))
    }

    return deleteWatermark(watermark)
  }

  const getMenuOptions = watermark => {
    return [
      {
        text: t('profile_settings.personalisation_settings.watermark.options.edit'),
        onClick: () => openModal(watermark.id),
      },
      {
        text: t('profile_settings.personalisation_settings.watermark.options.delete'),
        onClick: () => onDeleteWatermark(watermark),
      },
    ]
  }

  const openWatermarkModal = () => openModal() // open modal without context

  return (
    <div className='account-settings'>
      <Text
        className='account-settings__title'
        as='h2'
        type='title3'
      >
        {t('profile_settings.personalisation_settings.title')}
      </Text>
      <div className='account-settings__content'>
        <div className='account-settings__section account-settings__watermarks'>
          <Button className='account-settings__watermarks__add-watermark' onClick={openWatermarkModal}>
            {t('profile_settings.personalisation_settings.watermark.add_watermark_cta')}
          </Button>
          <Text
            as='h3'
            type='subtitle'
          >
            {t('profile_settings.personalisation_settings.watermark.title')}
          </Text>
          <LoadingComponent
            isLoading={watermarksAreLoading}
            size={30}
            className='account-settings__watermarks__loading-wrapper'
          />
          {Object.keys(watermarksKeys).length === 0 && !watermarksAreLoading && (
            <div className='account-settings__watermarks__empty'>
              <Text
                as='h4'
                type='body'
              >
                {t('profile_settings.personalisation_settings.watermark.none_created.title')}
              </Text>
              <div className='account-settings__watermarks__empty__button'>
                <Button onClick={openWatermarkModal} variant='outlined' color='primary'>
                  {t('profile_settings.personalisation_settings.watermark.none_created.cta')}
                </Button>
              </div>
            </div>
          )}
          {!watermarksAreLoading && (
            <Grid className='account-settings__watermarks__grid' spacing={8} xs={6} sm={4} md={3} xl={2}>
              {watermarksKeys.map(watermarkKey => {
                const watermark = watermarksData?.[watermarkKey]
                if (watermark === undefined) {
                  return null
                }

                const galleryCount = Object.keys(watermark.galleries || {}).length

                return (
                  <MenuWrapper
                    key={watermark.id}
                    editMode={watermark.uploadStatus !== UPLOAD_STATUS.UPLOADING}
                    options={getMenuOptions(watermark)}
                  >
                    <div onClick={() => openModal(watermark.id)} className='account-settings__watermarks__watermark'>
                      <Image
                        alt={`watermark ${watermark.id}`}
                        className='account-settings__watermarks__watermark-image'
                        thumbnailsSpecs={[
                          { size: 's', media: '(max-width: 600px)' },
                          { size: 'm', media: '(max-width: 960px)' },
                          { size: 's', media: '(max-width: 1280px)' },
                          { size: 'm', media: '(min-width: 1280px)' },
                        ]}
                        minHeight={120}
                        generateThumbnailIfError
                        wrapperClassName='account-settings__watermarks__image-wrapper'
                        {...watermark.image}
                      />
                      <div className='account-settings__watermarks__info'>
                        <Text bold>{watermark.name}</Text>
                        <Text>{`${galleryCount} galeria(s)`}</Text>
                      </div>
                      {watermark.uploadStatus && (
                        <div className='account-settings__watermarks__upload-status'>
                          <UploadFeedback
                            uploading={watermark.uploadStatus === UPLOAD_STATUS.UPLOADING}
                            uploaded={watermark.uploadStatus === UPLOAD_STATUS.UPLOADED}
                          />
                        </div>
                      )}
                    </div>
                  </MenuWrapper>
                )
              })}
            </Grid>
          )}
        </div>
      </div>
      <Collapse className='photo-picker__alert' in={alertIsOpen}>
        <Alert severity='warning'>
          {t('components.photo_picker.oversize_alert', {
            'image_size': MAX_SIZE_IN_BYTES,
          })}
        </Alert>
      </Collapse>
      <Modal
        open={modalIsOpen}
        closeModal={closeModal}
        title={watermarksData?.[modalContext]
          ? t('profile_settings.personalisation_settings.watermark.input.edit_title')
          : t('profile_settings.personalisation_settings.watermark.input.title')}
        withSections
      >
        <WatermarkInput
          initialWatermark={watermarksData?.[modalContext]}
          onCancel={closeModal}
          onAddWatermark={onAddWatermark}
        />
      </Modal>
    </div>
  )
}
