import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { Fragment, useState } from 'react'

import { Button } from 'components/Button'

export const NewGalleryButton = ({ openAddGalleryModal, openAddExistingGalleryModal, label }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNewGalleryClick = () => {
    handleClose()
    openAddGalleryModal()
  }

  const handleExistingGalleryClick = () => {
    handleClose()
    openAddExistingGalleryModal()
  }

  return (
    <Fragment>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='outlined'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleNewGalleryClick} disableRipple>
          Adicionar nova galeria
        </MenuItem>
        <MenuItem onClick={handleExistingGalleryClick} disableRipple>
          Adicionar galeria existente
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
