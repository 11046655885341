import { collection, getDocs, getDoc, runTransaction, updateDoc, doc } from '@firebase/firestore'
import { deleteImage, getDatabase, postWatermarkImage } from 'services/firebaseServer'

import { logError } from 'utils/errorCapture'

const getUserRef = userUid => doc(getDatabase(), 'users', userUid)
const getUserWatermarksRef = userUid => collection(getDatabase(), 'users', userUid, 'watermarks')
const getUserWatermarkRef = (userUid, watermarkId) => doc(getDatabase(), 'users', userUid, 'watermarks', watermarkId)

export const fetchUserWatermarks = userUid => {
  const watermarksDocRef = getUserWatermarksRef(userUid)

  console.info('Fetching watermarks from user', userUid)
  return getDocs(watermarksDocRef)
    .then(eventsSnapshot => {
      console.info('Fetched watermarks from user:', userUid)

      const watermarks = eventsSnapshot.docs
        ?.reduce((prev, currDoc) => {
          const watermark = currDoc.data()
          prev[watermark.id] = watermark
          return prev
        }, {}) || {}

      return watermarks
    })
    .catch(error => {
      logError(error, `Error fetching watermarks from user ${userUid}: ${error}`)
      throw error
    })
}

export const fetchUserWatermark = (userUid, watermarkId) => {
  const watermarkDocRef = getUserWatermarkRef(userUid, watermarkId)

  console.info(`Fetching watermark ${watermarkId} from user ${userUid}`)
  return getDoc(watermarkDocRef)
    .then(watermark => {
      console.info(`Fetched watermark ${watermarkId} from user ${userUid}`)
      if (watermark.exists()) {
        return watermark.data()
      } else {
        throw new Error('No such watermark with id', watermarkId)
      }
    })
    .catch(error => {
      logError(error, `Error fetching watermark ${watermarkId} from user ${userUid}: ${error}`)
      throw error
    })
}

export const postUserWatermark = async (userUid, watermark, progressCallback) => {
  const userDocRef = getUserRef(userUid)

  let uploadedImageSrc
  try {
    const imageFile = watermark.image.file || watermark.image.blob // blob is adapted before (injected file name) in PersonalisationSettings component so it can be used as a file
    uploadedImageSrc = await postWatermarkImage(imageFile, userUid, watermark.id, progressCallback)
  } catch (error) {
    logError(error, 'Error uploading watermark image: ' + error)
    throw error
  }

  return runTransaction(getDatabase(), transaction => {
    // This code may get re-run multiple times if there are conflicts.
    return transaction.get(userDocRef)
        .then(sfDoc => {
          if (!sfDoc.exists()) {
            throw Error(`Error adding watermark to user ${userUid}. Error: User does not exist!`)
          }

          // Add the new image to all layouts
          const userWatermarks = sfDoc.data().watermarks || []

          userWatermarks.push(watermark.id)

          const userWatermarksData = {
            id: userUid,
            watermarks: userWatermarks,
          }

          transaction.update(userDocRef, userWatermarksData)

          const watermarkDocRef = getUserWatermarkRef(userUid, watermark.id)

          const parsedWatermarkData = {
            id: watermark.id,
            name: watermark.name,
            galleries: watermark.galleries,
            image: {
              src: uploadedImageSrc,
              height: watermark.image.height,
              width: watermark.image.width,
              thumbnails: watermark.image.thumbnails,
            },
            options: watermark.options,
          }

          transaction.set(watermarkDocRef, parsedWatermarkData)

          return parsedWatermarkData
        })
  }).then(parsedWatermarkData => {
    console.log(`User ${userUid} watermark ${watermark.id} successfully uploaded!`)
    return parsedWatermarkData
  }).catch(error => {
    logError(error, `Error posting user ${userUid} watermark ${watermark.id}: ` + error)
    throw error
  })
}

export const putUserWatermark = async (userUid, watermark) => {
  const watermarkDocRef = getUserWatermarkRef(userUid, watermark.id)

  const watermarkUpdatedParsedData = {
    name: watermark.name,
    options: watermark.options,
  }

  console.info(`Editing watermark ${watermark.id} in user ${userUid}`)
  return updateDoc(watermarkDocRef, { ...watermarkUpdatedParsedData })
    .then(() => {
      console.log(`Watermark ${watermark.id} successfully updated in user ${userUid}!`)
    })
    .catch(error => {
      logError(error, `Error updating watermark ${watermark.id} in user ${userUid}: ${error}`)
      throw error
    })
}

export const deleteUserWatermark = async (userUid, watermark) => {
  const userDocRef = getUserRef(userUid)
  const watermarkId = watermark.id

  console.info(`Deleting watermark ${watermarkId} from user ${userUid}`)
  return runTransaction(getDatabase(), transaction => {
    // This code may get re-run multiple times if there are conflicts.
    return transaction.get(userDocRef)
        .then(sfDoc => {
          if (!sfDoc.exists()) {
            throw Error(`Error deleting watermark from user ${userUid}. Error: User does not exist!`)
          }

          // Add the new image to all layouts
          const userWatermarks = sfDoc.data().watermarks || []

          const toRemoveWatermarkIndex = userWatermarks.findIndex(watermark => watermark === watermarkId)
          if (toRemoveWatermarkIndex > -1) {
            userWatermarks.splice(toRemoveWatermarkIndex, 1)
          }

          const userWatermarksData = {
            id: userUid,
            watermarks: userWatermarks,
          }

          // update user watermarks with deleted watermark ID
          transaction.update(userDocRef, userWatermarksData)

          const watermarkDocRef = getUserWatermarkRef(userUid, watermarkId)

          transaction.delete(watermarkDocRef)
        })
  }).then(() => {
    console.log(`User ${userUid} watermark ${watermarkId} successfully deleted!`)
    console.info(`Deleting watermark ${watermarkId} image`)
    return deleteImage(watermark.image.src)
      .then(() => {
        console.info(`Watermark ${watermarkId} image successfully deleted!`)
      })
  }).catch(error => {
    logError(error, `Error deleting user ${userUid} watermark ${watermarkId}: ` + error)
    throw error
  })
}
