import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ShareOutlined from '@mui/icons-material/ShareOutlined'
import TuneIcon from '@mui/icons-material/TuneOutlined'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { Slider } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import SelectionArea from '@viselect/vanilla'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { GenericAddButton } from 'scenes/BackOffice/components/GenericAddButton'
import { SharePopover } from 'scenes/BackOffice/scenes/EventGallery/components/SharePopover/index.js'
import { GalleryPhotoSkeleton } from 'scenes/Gallery/components/GalleryPhotosSkeleton'

import { Breadcrumb } from 'components/Breadcrumb'
import { Button, LinkButton, IconButton } from 'components/Button'
import { GalleryMasonryLayout } from 'components/GalleryMasonryLayout/index.js'
import { HelpTooltip } from 'components/HelpTooltip'
import { ImageListInput } from 'components/ImageListInput'
import { Link } from 'components/Link'
import { List } from 'components/List'
import { BackdropLoading, LoadingComponent } from 'components/LoadingComponent'
import { Modal, SimpleMessageModal } from 'components/Modal'
import { NewFeatureTooltip } from 'components/NewFeatureTooltip/index.js'
import { Text } from 'components/Text'

import { useLocalActions } from '../../actions/useLocalActions.js'
import { GalleryEdit } from '../../components/GalleryEdit'

import { FilterMenu } from './components/FilterMenu'
import { GalleryImage } from './components/GalleryImage'
import { UploadingSnackbar } from './components/UploadingSnackbar'

import { useBreakpoints } from 'hooks/useBreakpoints/index.js'
import { useGalleryPagination } from 'hooks/useGalleryPagination/useGalleryPagination.js'
import { useHeaderIsHidden } from 'hooks/useHeaderIsHidden/index.js'
import { useModal } from 'hooks/useModal'
import { useNewFeatureNotification } from 'hooks/useNewFeatureNotification/index.js'
import { useRestrictedQuota } from 'hooks/useRestrictedQuota'
import { useUploading } from 'hooks/useUploading/index.js'
import { useUrlQuery } from 'hooks/useUrlQuery'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import { GalleryNotFoundError, ClientOfflineError } from 'services/errorHandling'
import { t } from 'services/i18n'
import { subRoutesNames, createBackOfficeLink, createGalleryLink } from 'services/routingService'

import { getImagesTotalSize } from 'utils/images.js'
import { getLocalStorageJSON, setLocalStorage } from 'utils/localStorage.js'
import { notifyError, notifySuccess } from 'utils/notifyUser.js'
import { formatBytes } from 'utils/storageData.js'

import './styles.scss'

const LS_COLUMNS_OFFSET = 'gallery_columns_offset'

const MAX_ZOOMING_VALUE = 5

export const EventGallery = () => {
  const srcContextQuery = useUrlQuery('srcContext')
  const srcContextIsEvent = srcContextQuery === 'event-page'
  const { subPage: galleryId } = useParams()
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(
    state => logedInUserId !== undefined && state.users.users[state.users.currentUser.uid]
  )
  const galleryIsLoading = useSelector(state => !!state.galleries.loading)
  const [galleryLoadedOnce, setGalleryLoadedOnce] = useState(false)
  const galleryIsLoadingExtraData = useSelector(state => !!state.galleries.fetchingExtraData)
  const galleryError = useSelector(state => state.galleries.error)
  const galleryFetchHasError = useSelector(state => state.galleries.error !== undefined)
  const eventGallery = useSelector(state => galleryId && state.galleries.data?.[galleryId])
  const galleryIsAvailable = Boolean(eventGallery)
  const galleryUserIsAvailable = Boolean(eventGallery?.user)
  const galleryEventIsAvailable = Boolean(eventGallery?.event)
  const missingExtraData =
    !galleryUserIsAvailable ||
    (eventGallery.eventId && !galleryEventIsAvailable) ||
    !eventGallery.coverPhoto

  const photos = useMemo(() => eventGallery?.photos || {}, [eventGallery])
  const galleryPhotoKeys = useMemo(() => eventGallery?.photosLayout || [], [eventGallery])
  const [ addImagesModalIsOpen, setAddImagesModalIsOpen ] = useState(false)
  const [ editGalleryModalIsOpen, setEditGalleryModalIsOpen ] = useState(false)
  const [ tempGalleryNewImagesBucket, setTempGalleryNewImagesBucket ] = useState([])
  const [ deletingGallery, setDeletingGallery ] = useState(false)
  const [ importedImagesLoaded, setImportedImagesLoaded ] = useState(false)
  const [ inputImagesTotalSize, setInputImagesTotalSize ] = useState()
  const [ removeGalleryModalIsOpen, openRemoveGalleryModal, closeRemoveGalleryModal ] = useModal()
  const [ deletePhotosModalIsOpen, openDeletePhotosModal, closeDeletePhotosModal ] = useModal()
  const history = useHistory()
  const [hasQuota, openUpsell] = useRestrictedQuota(getImagesTotalSize(tempGalleryNewImagesBucket))
  const [filterMarkedPhotos, setFilterMarkedPhotos] = useState(false)
  const [showHelperTooltip, setShowHelperTooltip] = useState(false)
  const [
    uploadingStatus,
    startUploading,
    finishUploading,
    uploadingProgress,
    setUploadingProgress,
    uploadingHasError,
    setUploadingHasError,
    uploadingLabel,
    setUploadingLabel,
  ] = useUploading()
  const { isMobile, isTabletAndUp } = useBreakpoints()
  const [ uploadingImagesState, setUploadingImagesState ] = useState({})
  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ selectedPhotos, setSelectedPhotos ] = useState({})
  const [ isDeletingPhotos, setIsDeletingPhotos ] = useState(false)
  const nrOfSelectedPhotos = Object.keys(selectedPhotos).length
  const selection = useRef()
  const galleryWrapperRef = useRef(null)
  const [isOpen, onFeatureNotificationDismiss] = useNewFeatureNotification('gallery-watermarks', logedInUser.uid)
  const [ isDraggingSelection, setIsDraggingSelection ] = useState(false)
  const [ sliderValue, setSliderValue ] = useState(getLocalStorageJSON(LS_COLUMNS_OFFSET) || MAX_ZOOMING_VALUE)
  const headerIsHidden = useHeaderIsHidden()

  const backLink = srcContextIsEvent && eventGallery?.eventId
    ? createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, eventGallery.eventId, true, ['srcContext'])
    : createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES)

  const {
    fetchGallery,
    setGalleryPhotos,
    updateGallery,
    incrementUserGalleryCount,
    removeGallery,
    removeGalleryPhoto,
    removeGalleryPhotos,
    setGallerySharingOptions,
    triggerAddWatermarkToGalleryPhotoJob,
    listenForWatermarkChanges,
  } = useLocalActions(logedInUser)

  const gallery = useMemo(() => eventGallery || {}, [eventGallery])
  const galleryPhotoCount = galleryPhotoKeys.length || 0
  const galleryMarkedPhotos = useMemo(() => (gallery?.markedPhotos?.photoIds) || {}, [gallery])
  const galleryMarkedPhotosLenght = Object.values(galleryMarkedPhotos)
    .filter(isMarkedPhoto => isMarkedPhoto) // remove unmarked one (global state can have unmarked photos as false)
    .length

  const onGalleryPhotosAdd = () => {
    if (selection.current) {
      selection.current.resolveSelectables()
    }
  }

  const {
    photosLayoutAreAllFetched,
    hasPagesToLoadOnBottom,
    fetchAllGalleryPhotos,
    sentryRef,
  } = useGalleryPagination(gallery, galleryId, galleryError, galleryIsLoading, onGalleryPhotosAdd)

  // Note: galleryPhotos might be paginated and not have all the photos.
  const galleryPhotos = useMemo(() => galleryPhotoKeys
    .map(photoKey => photos[photoKey])
    .filter(photo => {
      if (filterMarkedPhotos && photo?.id) {
        const isMarked = galleryMarkedPhotos[photo.id]
        return (filterMarkedPhotos === true && isMarked)
      }

      return !!photo?.id
    })
    .reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr,
      }
    }, {}), [galleryPhotoKeys, photos, filterMarkedPhotos, galleryMarkedPhotos])

  const photoSizes = useMemo(() => Object.values(galleryPhotos)
    .reduce((prev, currPhoto) => {
      return {
        ...prev,
        [currPhoto.id]: {
          height: currPhoto.height,
          width: currPhoto.width,
        },
      }
    }, {}), [galleryPhotos])

  useEffect(() => {
    const uploadingImagesStateValues = Object.values(uploadingImagesState)

    const uploadingImagesStateSum = uploadingImagesStateValues
      .reduce((prev, uploadingImageState) => prev + uploadingImageState.progress, 0)

    setUploadingProgress(uploadingImagesStateSum / uploadingImagesStateValues.length) // average of uploading
  }, [setUploadingProgress, uploadingImagesState])

  useEffect(() => {
    if (
      (!galleryIsAvailable || missingExtraData) &&
      !galleryIsLoading &&
      !galleryIsLoadingExtraData &&
      !galleryLoadedOnce
    ) {
      fetchGallery(galleryId)
        .then(() => setGalleryLoadedOnce(true))
    }
  }, [
    fetchGallery,
    galleryId,
    galleryIsAvailable,
    missingExtraData,
    galleryIsLoading,
    galleryIsLoadingExtraData,
    galleryLoadedOnce,
  ])

  /**
   * Listen clicks outside the selectable area
   */
  useEffect(() => {
    function handleMouseUpOutside(event) {
      const galleryWrapper = galleryWrapperRef.current
      const isTargetInGallery =
        event.target === galleryWrapper || galleryWrapper.contains(event.target)

      if (event.target.nodeName.startsWith('BUTTON') || event.target.nodeName.startsWith('svg') || event.target.nodeName.startsWith('path')) {
        // This is an SVG or button element.
        // It might be delete icon and we don't want to interfer with that click action
        // even if it's another button, we don't want to interfer
        return
      }

      // if target is not in gallery wrapper, no need to search more
      if (!isTargetInGallery) {
        setSelectedPhotos({})
        return
      }

      // otherwise, let's check if the user clicked inside the gallery wrapper but outside any image
      const isTargetInImage = !!event.target.closest('.event-gallery-image')
      if (!isTargetInImage) {
        setSelectedPhotos({})
      }
    }

    document.addEventListener('mouseup', handleMouseUpOutside)
    document.addEventListener('touchend', handleMouseUpOutside)

    return () => {
      document.removeEventListener('mouseup', handleMouseUpOutside)
      document.removeEventListener('touchend', handleMouseUpOutside)
    }
  }, [])

  /**
   * Listen to CMD + A or CTRL + A to select all photos
   */
  useEffect(() => {
    const handleKeyPress = event => {
      if ((event.metaKey || event.ctrlKey) && (event.keyCode === 65 || event.which === 65)) {
        event.stopPropagation()
        event.preventDefault()

        // Command + A was pressed
        // select all available photos
        const selectedPhotos = { ...galleryPhotos }
        Object.keys(galleryPhotos)
          .forEach(key => {
            selectedPhotos[key] = true
          })
        setSelectedPhotos(selectedPhotos)

        // TODO: ADDRESS THIS
        // not possible yet to select all gallery photos because the photo src is needed in deleteGalleryPhotos
        // function. However, this can be done without the photo src
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [galleryPhotos, selectedPhotos])

  useBeforeunload(event => {
    const allPhotosAreReady = Object.values(uploadingImagesState)
      .every(uploadingImage => uploadingImage.progress === 100 || uploadingImage.hasError)

    if (!allPhotosAreReady || isDeletingPhotos) {
      event.preventDefault()
    }
  })

  const openAddImagesModal = () => setAddImagesModalIsOpen(true)
  const closeAddImagesModal = () => setAddImagesModalIsOpen(false)

  const saveNewGalleryPhotosTemp = images => {
    setTempGalleryNewImagesBucket(images)
  }

  const onImagesLoadedChange = imagesLoaded => {
    if (imagesLoaded !== importedImagesLoaded) {
      setImportedImagesLoaded(imagesLoaded)
    }
  }

  const onImagesTotalSizeChange = inputImagesTotalSize => {
    setInputImagesTotalSize(inputImagesTotalSize)
  }

  const addPhotosToGallery = () => {
    if (!hasQuota) {
      openUpsell()
      return
    }

    startUploading()

    closeAddImagesModal()
    const newPhotos = tempGalleryNewImagesBucket.map(img => ({
      id: img.id,
      src: img.src,
      width: img.width,
      height: img.height,
      file: img.file,
      thumbnails: img.thumbnails,
    }))

    const uploadedPhotos = []
    let hasWarnedUserAboutError = false
    const callbackForEachPhoto = (photoId, progress, fileName) => {
      if (progress === -1) {
        setUploadingImagesState(prev => ({
          ...prev,
          [photoId]: {
            id: photoId,
            hasError: true,
            name: fileName,
            progress: 0,
          },
        }))
        // log error to user if it's the first image with error.
        // Note: This cannot be in the setGalleryPhotos catch because to do so, we couldn't handle the errors individually
        if (!hasWarnedUserAboutError) {
          hasWarnedUserAboutError = true
          notifyError('Houve um erro a enviar pelo menos uma imagem. Recarrega a página e tenta novamente')
        }

        setUploadingHasError(true)
        return
      }

      setUploadingImagesState(prev => ({
        ...prev,
        [photoId]: {
          id: photoId,
          name: fileName,
          progress,
        },
      }))

      // if image is now uploaded, we must update photosLayout
      // photosLayout should be updated dinamically once every photo is uploaded so that we don't miss any uploaded photo in the case of an error or user closes the app abruptly
      if (progress === 100 && !uploadedPhotos.includes(photoId)) {
        uploadedPhotos.push(photoId)
      }

      setUploadingLabel(`${uploadedPhotos.length}/${newPhotos.length}`)
    }

    setUploadingImagesState(newPhotos.reduce((prev, imageToBeUploaded) => {
      prev[imageToBeUploaded.id] = {
        id: imageToBeUploaded.id,
        name: imageToBeUploaded.file?.name,
        progress: 0,
      }
      return prev
    }, {}))
    setUploadingLabel(`${uploadedPhotos.length}/${newPhotos.length}`)

    setGalleryPhotos(galleryId, newPhotos, callbackForEachPhoto, eventGallery.watermark?.id)
      .then(uploadedPhotos => {
        const uploadedPhotosWithoutError = uploadedPhotos.filter(photo => photo?.id) // filter photos without error
        setUploadingLabel(`${uploadedPhotosWithoutError.length}/${newPhotos.length}`)

        // we don't need to update the gallery data in the server. If any, we would need to update the coverPhotoId, but even this is defaulted to the initial image.
        // In order to avoid more bugs, we should not do it

        if (!hasWarnedUserAboutError) {
          finishUploading()
          notifySuccess('Feito! Todas as fotos foram enviadas')
        }
      })

    setTempGalleryNewImagesBucket([])
  }

  const deleteSelectedPhotos = () => {
    const toRemovePhotos = Object.keys(selectedPhotos).map(photoId => galleryPhotos[photoId])
    setIsDeletingPhotos(true)
    removeGalleryPhotos(galleryId, toRemovePhotos, galleryMarkedPhotos)
      .then(() => {
        setSelectedPhotos({}) // reset selection after deleting it
      })
      .catch(() => {
        notifyError('Houve um erro a remover as imagens. Recarrega a página e tenta novamente')
      })
      .finally(() => {
        setIsDeletingPhotos(false)
      })
    closeDeletePhotosModal()
  }

  const removeImage = useCallback(photoId => {
    // Note: galleryPhotos might be paginated and not have all the photos.
    // We can only be sure it contains photoId because removeImage needs the photo to be already here
    const isMarkedPhoto = !!galleryMarkedPhotos[photoId]
    setIsDeletingPhotos(true)
    removeGalleryPhoto(galleryId, galleryPhotos[photoId], isMarkedPhoto)
      .catch(() => {
        notifyError('Houve um erro a remover a imagem. Recarrega a página e tenta novamente')
      })
      .finally(() => {
        setIsDeletingPhotos(false)
      })
  }, [
    galleryId,
    galleryMarkedPhotos,
    galleryPhotos,
    removeGalleryPhoto,
  ])

  const setDefaultImage = useCallback(photoId => {
    // Note: galleryPhotos might be paginated and not have all the photos.
    // We can only be sure it contains photoId because setDefaultImage needs the photo to be already here
    const coverImage = galleryPhotos[photoId] || galleryPhotos[galleryPhotoKeys[0]] || {}
    // TODO: create a PUT function instead of using the POST
    updateGallery(gallery.id, {
      coverPhotoId: photoId,
      coverPhoto: coverImage, // this will not be persisted to the database. Only used in local storage
    }, true)
  }, [
    gallery.id,
    galleryPhotos,
    updateGallery,
    galleryPhotoKeys,
  ])

  const getImageOptions = useCallback(photoId => [
    {
      text: 'Apagar',
      iconId:  'delete',
      disabled: isDeletingPhotos,
      onClick: () => removeImage(photoId),
    },
    {
      text: 'Usar como foto de capa',
      iconId:  'photo',
      onClick: () => setDefaultImage(photoId),
    },
    {
      text: 'Copiar nome',
      iconId:  'content_copy',
      onClick: () => {
        const photoName = galleryPhotos[photoId]?.name
        return navigator.clipboard?.writeText(photoName)
      },
    },
  ], [setDefaultImage, removeImage, isDeletingPhotos, galleryPhotos])

  const openEditGalleryModal = () => setEditGalleryModalIsOpen(true)
  const closeEditGalleryModal = () => setEditGalleryModalIsOpen(false)

  const onSaveGallery = (newGallery, updateWatermarks) => {
    closeEditGalleryModal()
    return updateGallery(gallery.id, newGallery, true, updateWatermarks)
  }

  const onDeleteGallery = () => {
    setDeletingGallery(true)
    removeGallery(eventGallery)
      .then(() => history.push(backLink))
    closeEditGalleryModal()
    closeRemoveGalleryModal()
  }

  const setSharingGallery = newGallerySharingValue => {
    setGallerySharingOptions(galleryId, {
      sharingOptions: {
        shared: newGallerySharingValue,
      },
    })
  }

  const getBreadcrumbItems = () => {
    if (srcContextIsEvent && eventGallery?.event) {
      return [
        {
          title: t('back_office.gallery.breadcrumb_title_event'),
          url: createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, null, true, ['srcContext']),
        }, {
          title: gallery.event?.title,
          url: createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, gallery.eventId, true, ['srcContext']),
        }, {
          title: gallery.title,
        },
      ]
    }

    return [
      {
        title: t('back_office.gallery.breadcrumb_title'),
        url: createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES),
      }, {
        title: gallery.title,
      },
    ]
  }

  const onFilterChange = (_filterKey, value) => {
    setFilterMarkedPhotos(value)
  }

  const openShareGalleryModal = event => setAnchorEl(event.currentTarget)

  const closeShareGalleryModal = () => setAnchorEl(null)

  /**
   * Function to save only 1 selected photo
   */
  const onSelectPhoto = useCallback(photoKey => append => {
    const photo = galleryPhotos[photoKey]
    if (uploadingStatus === UPLOAD_STATUS.UPLOADING || photo.watermark?.isLoading) {
      return
    }

    const shouldAppend = append || !isTabletAndUp
    // if the selected key is selected and is the only one selected
    // or the selected key is selected and the ctrl key or command key is selected
    // unselect it
    if (
      (selectedPhotos[photoKey] && Object.keys(selectedPhotos).length === 1) ||
      (selectedPhotos[photoKey] && shouldAppend)
    ) {
      const currentPhotoSelection = { ...selectedPhotos }
      delete currentPhotoSelection[photoKey]
      setSelectedPhotos(currentPhotoSelection)
      return
    }

    setSelectedPhotos({
      ...(shouldAppend ? selectedPhotos : {}),
      [photoKey]: true,
    })
  }, [galleryPhotos, uploadingStatus, isTabletAndUp, selectedPhotos])

  useEffect(() => {
    selection.current = new SelectionArea({
      // Class for the selection-area itself (the element).
      selectionAreaClass: 'selection-area',
      // Class for the selection-area container.
      selectionContainerClass: 'event-gallery__masonry-wrapper',
      // Query selector or dom-node to set up container for the selection-area element.
      container: 'body',
      // document object - if you want to use it within an embed document (or iframe).
      // If you're inside of a shadow-dom make sure to specify the shadow root here.
      document: window.document,
      // Query selectors for elements which can be selected.
      selectables: ['.event-gallery-image'],
      // Query selectors for elements from where a selection can be started from.
      startareas: ['html'],
      // Query selectors for elements which will be used as boundaries for the selection.
      // The boundary will also be the scrollable container if this is the case.
      boundaries: ['.event-gallery__masonry-wrapper'],
      // Behaviour related options.
      behaviour: {
        // Specifies what should be done if already selected elements get selected again.
        //   invert: Invert selection for elements which were already selected
        //   keep: Keep selected elements (use clearSelection() to remove those)
        //   drop: Remove stored elements after they have been touched
        overlap: 'invert',
        // On which point an element should be selected.
        // Available modes are cover (cover the entire element), center (touch the center) or
        // the default mode is touch (just touching it).
        intersect: 'touch',
        // px, how many pixels the point should move before starting the selection (combined distance).
        // Or specifiy the threshold for each axis by passing an object like {x: <number>, y: <number>}.
        startThreshold: 10,
        // Scroll configuration.
        scrolling: {
          // On scrollable areas the number on px per frame is devided by this amount.
          // Default is 10 to provide a enjoyable scroll experience.
          speedDivider: 10,
          // Browsers handle mouse-wheel events differently, this number will be used as
          // numerator to calculate the mount of px while scrolling manually: manualScrollSpeed / scrollSpeedDivider.
          manualSpeed: 750,
          // This property defines the virtual inset margins from the borders of the container
          // component that, when crossed by the mouse/touch, trigger the scrolling. Useful for
          // fullscreen containers.
          startScrollMargins: { x: 50, y: 50 },
        },
      },
      // Features.
      features: {
        // Enable / disable touch support.
        touch: false,
        // Range selection.
        range: true,
        // Configuration in case a selectable gets just clicked.
        singleTap: {
          // Enable single-click selection (Also disables range-selection via shift + ctrl).
          allow: false,
          // 'native' (element was mouse-event target) or 'touch' (element visually touched).
          intersect: 'native',
        },
      },
    })

    selection.current
    // Use this event to decide whether a selection should take place or not.
    // For example if the user should be able to normally interact with input-elements you
    // may want to prevent a selection if the user clicks such a element:
    .on('beforestart', ({ event }) => {
      if (event.target.offsetParent?.className?.includes('event-gallery-image__name')) {
        return false
      }
      return true
    })
    // Same as 'beforestart' but before a selection via dragging happens.
    // .on('beforedrag', evt => {
    // console.log('DEBUG_SESSION::beforedrag', evt)
    // })
    // A selection got initiated, you could now clear the previous selection or
    // keep it if in case of multi-selection.
    .on('start', () => {
      setIsDraggingSelection(true)
    })
    // Here you can update elements based on their state.
    // .on('move', evt => {
    // console.log('DEBUG_SESSION::move', evt)
    // })
    .on('stop', evt => {
      // Do something with the selected elements.
      console.log('DEBUG_SESSION::stop', evt)
      setIsDraggingSelection(false)

      if (!evt.event) {
        return
      }

      if (evt.store.selected.length > 0) {
        const initialSelection = (evt.event.metaKey || evt.event.ctrlKey) ? selectedPhotos : {}

        const photoKeys = evt.store.selected
          .reduce((prev, selected) => {
            return {
              ...prev,
              [selected.dataset.photoKey]: true,
            }
          }, initialSelection)

        setSelectedPhotos(photoKeys)
      }
    })

    return () => selection.current.destroy()
  }, [selectedPhotos])

  const onFeatureNotificationClick = () => {
    openEditGalleryModal()
    onFeatureNotificationDismiss()
  }

  const handleSliderChange = (_event, newValue) => {
    if (typeof newValue === 'number') {
      setLocalStorage(LS_COLUMNS_OFFSET, JSON.stringify(newValue))
      setSliderValue(newValue)
    }
  }

  const fetchAllGalleryPhotosIfNecessary = () => {
    if (galleryIsLoading || photosLayoutAreAllFetched) {
      return
    }

    if (hasPagesToLoadOnBottom) {
      fetchAllGalleryPhotos(galleryId)
    }
  }

  if (galleryFetchHasError) {
    if (galleryError?.code === 'unavailable') {
      throw new ClientOfflineError('Não conseguimos chegar ao servidor. Verifica que estás conetado à internet e tenta novamente')
    }

    throw new GalleryNotFoundError('Gallery not found')
  }

  return (
    <div className='event-gallery'>
      <Breadcrumb
        items={getBreadcrumbItems()}
      />

      <div className='event-gallery__main-info'>
        <Text className='event-gallery__main-info__title' type='title3'>{gallery.title}</Text>
        <GenericAddButton
          disabled={uploadingStatus === UPLOAD_STATUS.UPLOADING || isDeletingPhotos}
          label='Adicionar fotos à galeria'
          onClick={openAddImagesModal}
        />

        <List className='event-gallery__main-info__info-list' withSeparator>
          <div>{`${galleryPhotoCount} fotos`}</div>
          <div>{gallery.sharingOptions?.shared ? t('general.gallery_sharing_type.public') : t('general.gallery_sharing_type.private')}</div>
        </List>

        <span className='event-gallery__main-info__event'>
          Evento:&nbsp;
          {gallery.event
            ? (
              <Link
                underlined
                href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, gallery.eventId, true, ['srcContext'])}
              >
                {gallery.event.title}
              </Link>
            )
            : (
              <Tooltip
                arrow
                open={showHelperTooltip}
                onOpen={() => setShowHelperTooltip(true)}
                onClose={() => setShowHelperTooltip(false)}
                title={(
                  <div className='event-gallery__main-info__no-event-helper'>
                    <h2 className='event-gallery__main-info__no-event-helper__title'>
                      Associa esta galeria a um evento
                    </h2>
                    <p>Acede aos teus eventos e associa a galeria a um dos teus eventos</p>
                    <LinkButton
                      size='small'
                      variant='outlined'
                      href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS)}
                      color='secondary'
                    >
                      Acede aos teus eventos
                    </LinkButton>
                  </div>
                )}
                placement={isMobile ? 'bottom-end' : 'right'}
                leaveDelay={300}
                leaveTouchDelay={8000}
              >
                <div
                  onClick={() => setShowHelperTooltip(!showHelperTooltip)}
                  className='event-gallery__main-info__no-event'
                >
                  [Sem evento]
                </div>
              </Tooltip>
            )}
        </span>

        <div className='event-gallery__main-info__actions'>
          <Button
            onClick={openShareGalleryModal}
            variant='outlined'
            color='primary'
            startIcon={<ShareOutlined />}
            disabled={uploadingStatus === UPLOAD_STATUS.UPLOADING}
          >
            Partilhar
          </Button>
          <SharePopover
            anchorEl={anchorEl}
            closeShareGalleryModal={closeShareGalleryModal}
            gallery={gallery || {}}
            setSharingGallery={setSharingGallery}
            onSaveGallery={onSaveGallery}
            incrementUserGalleryCount={incrementUserGalleryCount}
          />
          <NewFeatureTooltip
            isOpen={isOpen}
            content={(
              <div className='event-gallery__main-info__actions__new-feature-content'>
                <IconButton
                  className='event-gallery__main-info__actions__new-feature-close-button'
                  size='small'
                  aria-label='close'
                  color='inherit'
                  onClick={onFeatureNotificationDismiss}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
                <Text color='text__primary-color' type='title4'>Novidade!</Text>
                <Text type='body2'>Já experimentaste marcas de água na PLOTU?</Text>
                <Text type='body2'>Cria uma marca de água para protegeres a tua galeria!</Text>
                <Button onClick={onFeatureNotificationClick} variant='outlined' color='primary'>Experimentar!</Button>
              </div>
            )}
          >
            <Button
              onClick={openEditGalleryModal}
              variant='outlined'
              color='primary'
              startIcon={<TuneIcon />}
              disabled={uploadingStatus === UPLOAD_STATUS.UPLOADING}
            >
              Configurar
            </Button>
          </NewFeatureTooltip>
          <LinkButton
            href={createGalleryLink(gallery.id)}
            variant='outlined'
            color='primary'
            disabled={uploadingStatus === UPLOAD_STATUS.UPLOADING}
          >
            Ver galeria
          </LinkButton>
          <div>
            <IconButton
              color='primary'
              size='small'
              aria-label='delete images'
              onClick={openDeletePhotosModal}
              disabled={nrOfSelectedPhotos === 0 || isDeletingPhotos}
            >
              <DeleteOutlinedIcon />
            </IconButton>
            <LoadingComponent
              isLoading={isDeletingPhotos}
              size={12}
              className='event-gallery__main-info__actions__loading'
            />
          </div>
        </div>
      </div>
      <div
        className={classNames('event-gallery__photos-info', {
          'event-gallery__photos-info--sticky': nrOfSelectedPhotos > 0 || galleryMarkedPhotosLenght > 0,
          'event-gallery__photos-info--with-header': headerIsHidden,
        })}
      >
        <div className='event-gallery__photos-info__photo-count-slider'>
          <IconButton
            color='primary'
            size='small'
            aria-label='zoom out images'
            onClick={() => handleSliderChange(null, sliderValue - 1)}
            disabled={sliderValue === 1}
          >
            <ZoomOutIcon fontSize='inherit' />
          </IconButton>
          <Slider
            aria-label='Número de fotos'
            value={sliderValue}
            valueLabelDisplay='auto'
            step={1}
            marks
            min={1}
            max={MAX_ZOOMING_VALUE}
            size='small'
            onChange={handleSliderChange}
          />
          <IconButton
            color='primary'
            size='small'
            aria-label='zoom in images'
            onClick={() => handleSliderChange(null, sliderValue + 1)}
            disabled={sliderValue === MAX_ZOOMING_VALUE}
          >
            <ZoomInIcon fontSize='inherit' />
          </IconButton>
        </div>
        {nrOfSelectedPhotos > 0 && <Text type='body2' color='text__grey-color'>{`${nrOfSelectedPhotos} fotos selecionadas`}</Text>}
        {gallery?.markedPhotos && galleryMarkedPhotosLenght > 0 && nrOfSelectedPhotos > 0 && <Text type='body2' color='text__grey-color'>|</Text>}
        {gallery?.markedPhotos && galleryMarkedPhotosLenght > 0 && (
          <div className='event-gallery__marked-photos-sub-menu'>
            <Text type='body2' color='text__grey-color'>
              {`Fotos marcadas: ${galleryMarkedPhotosLenght}${gallery.markedPhotos.markedPhotosMaxLimit > 0 ? `/${gallery.markedPhotos.markedPhotosMaxLimit}` : ''}`}
            </Text>
            <FilterMenu onFilterChange={onFilterChange} />
          </div>
        )}
      </div>
      <div className='event-gallery__masonry-wrapper' ref={galleryWrapperRef}>
        <div style={{ width: `${(100 / MAX_ZOOMING_VALUE) * sliderValue}%` }}>
          <GalleryMasonryLayout
            photoSizes={photoSizes}
            layoutOptions={gallery?.personalisation?.layoutOptions}
            isSelectable
          >
            {Object.keys(galleryPhotos)
                .map((galleryPhotoKey, index) => {
                  const galleryPhoto = galleryPhotos[galleryPhotoKey]
                  // if there are any error with the photosLayout not being updated properly, we should not break the UI
                  if (!galleryPhoto) {
                    return null
                  }
                  const isMarked = galleryMarkedPhotos[galleryPhoto.id]

                  const isCoverPhoto = gallery.coverPhotoId
                    ? galleryPhotoKey === gallery.coverPhotoId
                    : index === 0

                  // if there are still pages of images to be fetched from the server and user is uploading some images
                  // we need to hide those images until we have all the images displayed
                  if (galleryPhoto.uploading && hasPagesToLoadOnBottom) {
                    return null
                  }

                  if (gallery?.personalisation?.coverOptions.hideCover && isCoverPhoto) {
                    return null
                  }

                  return (
                    <GalleryImage
                      index={index}
                      key={galleryPhotoKey}
                      galleryPhotoKey={galleryPhotoKey}
                      isMarked={isMarked}
                      isCoverPhoto={isCoverPhoto}
                      getImageOptions={getImageOptions}
                      galleryPhoto={galleryPhoto}
                      disabled={uploadingStatus === UPLOAD_STATUS.UPLOADING}
                      uploadingState={uploadingImagesState[galleryPhotoKey] || {}}
                      onSelectPhoto={onSelectPhoto(galleryPhotoKey)}
                      isSelected={selectedPhotos[galleryPhotoKey]}
                      isDraggingSelection={isDraggingSelection}
                      galleryWatermark={eventGallery.watermark}
                      triggerAddWatermarkToGalleryPhotoJob={
                        // eslint-disable-next-line max-len
                        () => triggerAddWatermarkToGalleryPhotoJob(eventGallery.id, eventGallery.watermark?.id, galleryPhoto)
                      }
                      triggerListenForWatermarkChanges={
                        () => listenForWatermarkChanges(eventGallery.id, [galleryPhoto.id], eventGallery.watermark?.id)
                      }
                      rectAngles={gallery?.personalisation?.layoutOptions?.rectAngles}
                    />
                  )
                })}
            {/* Show the gallery photos sekeleton to show that next photos are loading.
                This will also serve as the sentry to react-infinite-scroll-hook
                When gallery is in the initial loading, this skeletons will also appear
              */}
            {((!photosLayoutAreAllFetched && hasPagesToLoadOnBottom) || galleryIsLoading) && ['gallery-photo-skeleton-0', 'gallery-photo-skeleton-1', 'gallery-photo-skeleton-2', 'gallery-photo-skeleton-3', 'gallery-photo-skeleton-4', 'gallery-photo-skeleton-5']
                .map((id, index) => (
                  <GalleryPhotoSkeleton
                    key={id}
                    ref={index === 0 && !galleryIsLoading ? sentryRef : null}
                    rectAngles={gallery?.personalisation?.layoutOptions?.rectAngles}
                  />
                ))}
          </GalleryMasonryLayout>
        </div>
      </div>

      <Modal
        open={addImagesModalIsOpen}
        closeModal={closeAddImagesModal}
        title='Adicionar Fotos'
      >
        <div className='event-gallery__photos-input-modal__image-list'>
          <ImageListInput
            multiple
            onChange={saveNewGalleryPhotosTemp}
            onImagesLoadedChange={onImagesLoadedChange}
            onImagesTotalSizeChange={onImagesTotalSizeChange}
          />
        </div>
        <div className='event-gallery__photos-input-modal__footer'>
          <div className='event-gallery__photos-input-modal__footer__action-buttons'>
            {inputImagesTotalSize > 0 && (
              <div className='event-gallery__photos-input-modal__total-size'>
                <p className='event-gallery__photos-input-modal__total-size-label'>
                  {formatBytes(inputImagesTotalSize)}
                </p>
                {inputImagesTotalSize > 30000000 && (
                  <HelpTooltip fontSize={isMobile ? '1rem' : '0.8rem'} iconId='error_outline'>
                    <p>
                      Dependendo da velocidade da internet, poderá demorar algum tempo enviar todas as fotos
                    </p>
                  </HelpTooltip>
                )}
              </div>
            )}
            <Button
              onClick={closeAddImagesModal}
              variant='text'
              color='default'
            >
              Cancelar
            </Button>
            <Button
              disabled={!importedImagesLoaded}
              onClick={addPhotosToGallery}
              variant='contained'
              color='primary'
            >
              Adicionar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={editGalleryModalIsOpen}
        closeModal={closeEditGalleryModal}
        title={t('back_office.gallery.edit_modal.title')}
        withSections
      >
        <GalleryEdit
          initialGallery={gallery}
          onCancel={closeEditGalleryModal}
          onSaveGallery={onSaveGallery}
          onRemove={openRemoveGalleryModal}
          logedInUser={logedInUser}
          photosAreLoading={(!photosLayoutAreAllFetched && hasPagesToLoadOnBottom) || galleryIsLoading}
          fetchAllGalleryPhotos={fetchAllGalleryPhotosIfNecessary}
          onCoverPhotoPick={setDefaultImage}
        />
      </Modal>
      <SimpleMessageModal
        open={removeGalleryModalIsOpen}
        closeModal={closeRemoveGalleryModal}
        title={t('back_office.gallery.remove_gallery_confirmation_modal.title')}
        message={t('back_office.gallery.remove_gallery_confirmation_modal.confirmation', {
          gallery_title: gallery?.title,
        })}
        actionButtonText={t('back_office.gallery.remove_gallery_confirmation_modal.action_button')}
        secondaryActionButtonText={t('back_office.gallery.remove_gallery_confirmation_modal.secondary_action_button')}
        actionButtonColor='danger'
        secondaryActionButtonVariant='outlined'
        secondaryActionButtonColor='default'
        onActionButton={onDeleteGallery}
        onSecondaryActionButton={closeRemoveGalleryModal}
      />
      <BackdropLoading
        size={30}
        isLoading={deletingGallery}
      />
      <UploadingSnackbar
        uploadingStatus={uploadingStatus}
        isTabletAndUp={isTabletAndUp}
        uploadingImagesState={uploadingImagesState}
        uploadingProgress={uploadingProgress}
        uploadingLabel={uploadingLabel}
        uploadingHasError={uploadingHasError}
        finishUploading={finishUploading}
      />
      <SimpleMessageModal
        open={deletePhotosModalIsOpen}
        closeModal={closeDeletePhotosModal}
        modalTitle={t('back_office.gallery.delete_photos_confirmation_modal.title')}
        message={t('back_office.gallery.delete_photos_confirmation_modal.confirmation', {
          photos_count: Object.keys(selectedPhotos).length,
        })}
        actionButtonText={t('back_office.gallery.delete_photos_confirmation_modal.action_button')}
        secondaryActionButtonText={t('back_office.gallery.delete_photos_confirmation_modal.secondary_action_button')}
        actionButtonColor='danger'
        secondaryActionButtonVariant='outlined'
        secondaryActionButtonColor='default'
        onActionButton={deleteSelectedPhotos}
        onSecondaryActionButton={closeDeletePhotosModal}
        minWidth
      />
    </div>
  )
}
