import {
  REQUEST_USER,
  RECEIVE_USER,
  EDIT_USER,
  SET_CURRENT_USER,
  SET_USER_STORAGE_SIZE,
  REMOVE_CURRENT_USER,
} from './actions'

import { User } from 'model/User'

const initialState = {
  currentUser: undefined,
  loading: true,
  // since the majority of the actions with this property is indexing a specific key,
  // an object is a better option than an array
  users: {},
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER:
      return {
        ...state,
        loading: true,
      }
    case RECEIVE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.data.uid]: new User(action.data),
        },
        loading: false,
      }
    case EDIT_USER: {
      const cleanedNewUser = action.data.user
      if (!action.data.resetData) {
        // we should first clean not defined properties from user so that those would not override already defined properties
        Object.keys(cleanedNewUser)
          .forEach(key => cleanedNewUser[key] === undefined && delete cleanedNewUser[key])
      }

      const newUser = new User({
        ...(action.data.resetData ? {} : state.users[cleanedNewUser.uid]),
        ...cleanedNewUser,
      })

      const newUsers = {
        ...state.users,
        [cleanedNewUser.uid]: newUser,
      }

      return {
        ...state,
        users: newUsers,
        loading: false,
      }
    }
    case SET_CURRENT_USER: {
      const newCurrentUser = new User({
        ...state.users[action.data.user.uid],
        ...action.data.user,
      })
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          uid: newCurrentUser.uid,
        },
        users: {
          ...state.users,
          [newCurrentUser.uid]: newCurrentUser,
        },
      }
    }
    case SET_USER_STORAGE_SIZE: {
      const updatedUser = new User({
        ...state.users[action.data.userUid],
        storageSize: action.data.storageSize,
      })
      return {
        ...state,
        users: {
          ...state.users,
          [action.data.userUid]: updatedUser,
        },
      }
    }
    case REMOVE_CURRENT_USER:
      return {
        ...state,
        currentUser: undefined,
      }
    default:
      return state
  }
}

export default users
