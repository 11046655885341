import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import moment from 'moment'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { AutoCompleteInput } from 'components/AutoCompleteInput'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { TextField } from 'components/TextField'
import UserCircular from 'components/UserCircular'

import { useAlert } from 'hooks/useAlert'

import { searchQuery as searchQueryAction } from 'store/search/actions'
import { queryHitsSelector, queryIsLoadingSelector } from 'store/search/selectors'

import { t } from 'services/i18n'

import { SEARCH_GROUP_IDS } from 'utils/search'

import './styles.scss'

const DATE_INPUT_FORMAT = 'YYYY-MM-DD[T]HH:mm'

const UserAutocompleteComponent = ({ onFieldChange, initialUser }) => {
  const dispatch = useDispatch()
  const [ userAutoCompleteValue, setUserAutoCompleteValue] = useState(null)
  const { hits: queryHits = [] } = useSelector(queryHitsSelector(userAutoCompleteValue, SEARCH_GROUP_IDS.CUSTOMERS))
  const queryIsLoading = useSelector(queryIsLoadingSelector)

  const searchQuery = useCallback(
    (value, searchGroup) => dispatch(searchQueryAction(value, searchGroup)),
    [dispatch]
  )

  useEffect(() => {
    searchQuery(userAutoCompleteValue, SEARCH_GROUP_IDS.CUSTOMERS)
  }, [searchQuery, userAutoCompleteValue])

  const onUserAutocompletionChange = value => {
    if (!value) {
      onFieldChange(null)
    } else if (value.objectID) {
      onFieldChange({ uid: value.objectID })
    } else {
      onFieldChange({
        name: value.inputValue,
      })
    }
  }

  const onBlur = () => {
    if (userAutoCompleteValue) {
      onFieldChange({
        name: userAutoCompleteValue,
      })
    }

    // if name was removed, persist it
    if (initialUser?.name && !userAutoCompleteValue) {
      onFieldChange(null)
    }
  }

  const onClearInput = () => {
    onFieldChange(null)
  }

  return (
    <div className='appointment__more-info-field'>
      <AutoCompleteInput
        initialInputValue={initialUser?.name}
        inputLabel={t('back_office.calendar.appointment.user_auto_complete.label')}
        renderOption={option => <UserCircular size={30} avatar={option.imgSrc} name={option.name} />}
        onAutocompleteChange={onUserAutocompletionChange}
        addFreeSoloText={freeSolotInput => t('back_office.calendar.appointment.user_auto_complete.free_solo_text', { 'free_solo': freeSolotInput || '' })}
        onInputChange={setUserAutoCompleteValue}
        onClearInput={onClearInput}
        isLoading={queryIsLoading}
        results={queryHits}
        noResultsLabel='Nenhum utilizador encontrado'
        onBlur={onBlur}
      />
    </div>
  )
}

// gets hour rounded to the next  30 minutes
const getRoundedTo30Minutes = (time = moment()) => {
  // get rounded to 30 minutes
  const remainder = 30 - (time.minute() % 30)

  return moment(time)
    .add(remainder, 'minutes')
    .format(DATE_INPUT_FORMAT)
}

const getInitialDate = startDate => {
  if (startDate) {
    return moment.unix(startDate).format(DATE_INPUT_FORMAT)
  }

  return getRoundedTo30Minutes()
}

const getEndDate = (startDate, endDate) => {
  if (endDate) {
    return moment.unix(endDate).format(DATE_INPUT_FORMAT)
  }

  if (startDate) {
    return getRoundedTo30Minutes(moment.unix(startDate).add(1, 'hours'))
  }

  return getRoundedTo30Minutes(moment().add(1, 'hours'))
}

export const EventEdit = props => {
  const initialAppointment = props.initialAppointment || {}

  const [ title, setTitle ] = useState(initialAppointment.title || '')
  const [ startDate, setStartDate ] = useState(getInitialDate(initialAppointment.startDate))
  const [ endDate, setEndDate ] = useState(getEndDate(initialAppointment.startDate, initialAppointment.endDate))
  const [ packId, setPackId ] = useState(initialAppointment.packId || '')
  const [ user, setUser ] = useState(props.eventUser || null)
  const [ location, setLocation ] = useState(initialAppointment.location?.address || '')
  const [ description, setDescription ] = useState(initialAppointment.description || '')
  const titleRef = useRef()
  const [ alertIsOpen, openAlert ] = useAlert()

  useEffect(() => {
    setTitle(initialAppointment.title || '')
    setStartDate(getInitialDate(initialAppointment.startDate))
    setEndDate(getEndDate(initialAppointment.startDate, initialAppointment.endDate))
    setPackId(initialAppointment.packId || '')
    setUser(props.eventUser || initialAppointment.user || null) // if the user was loaded when Appointment modal was opened, use it. Otherwise, it's because event only have the user name so we can use it right away
    setLocation(initialAppointment.location?.address || '')
    setDescription(initialAppointment.description || '')
  }, [
    initialAppointment.description,
    initialAppointment.endDate,
    initialAppointment.location,
    initialAppointment.packId,
    initialAppointment.startDate,
    initialAppointment.title,
    initialAppointment.user,
    props.eventUser,
  ])

  const onTitleChange = event => {
    const newTitle = event.target.value
    setTitle(newTitle)
  }

  const onStartDateChange = event => {
    const newStartDate = event.target.value
    setStartDate(newStartDate)
  }

  const onStartDateBlur = () => {
    if (moment(startDate).isAfter(moment(endDate), 'second')) {
      const newEndDate = moment(startDate).add(1, 'hours').format(DATE_INPUT_FORMAT)
      setEndDate(newEndDate)
    }
  }

  const onEndDateChange = event => {
    const newEndDate = event.target.value
    setEndDate(newEndDate)
  }

  const onEndDateBlur = () => {
    if (moment(endDate).diff(moment(startDate)) <= 0) {
      const newStartDate = moment(endDate).subtract(1, 'hours').format(DATE_INPUT_FORMAT)
      setStartDate(newStartDate)
    }
  }

  const onAutocompleteChange = newPack => setPackId(newPack.id)

  const onUserChange = newUser => {
    setUser(newUser)
  }

  const onLocationChange = event => {
    const newLocation = event.target.value
    setLocation(newLocation)
  }

  const onDescriptionChange = event => {
    const newDescription = event.target.value
    setDescription(newDescription)
  }

  const onSaveAppointment = () => {
    if (!title) {
      openAlert()
      titleRef.current.scrollIntoView()
      return
    }

    props.onSaveAppointment({
      id: initialAppointment.id || uuidv4(),
      title,
      startDate: moment(startDate).unix(),
      endDate: moment(endDate).unix(),
      packId,
      user: { // maping needed if event is being edited
        uid: user?.uid || null,
        name: user?.name || null,
      },
      location: {
        address: location,
      },
      description,
    })
  }

  return (
    <div>
      <div ref={titleRef} className='appointment__primary-field'>
        <TextField
          label={t('back_office.calendar.appointment.title_label')}
          value={title}
          onChange={onTitleChange}
          fullWidth
        />
      </div>
      <Collapse in={alertIsOpen}>
        <Alert className='appointment__alert' severity='warning'>{t('back_office.calendar.appointment.no_title_alert')}</Alert>
      </Collapse>
      <div className='appointment__primary-field appointment__primary-field--margin-top'>
        <TextField
          id='datetime-local'
          label={t('back_office.calendar.appointment.start_date_label')}
          type='datetime-local'
          value={startDate}
          onChange={onStartDateChange}
          onBlur={onStartDateBlur}
          fullWidth
        />
      </div>
      <div className='appointment__primary-field appointment__primary-field--margin-top'>
        <TextField
          id='datetime-local'
          label={t('back_office.calendar.appointment.end_date_label')}
          type='datetime-local'
          value={endDate}
          onChange={onEndDateChange}
          onBlur={onEndDateBlur}
          fullWidth
        />
      </div>
      <Text as='h2' type='subtitle' className='appointment__more-info-title'>
        {t('back_office.calendar.appointment.more_info')}
      </Text>
      <div className='appointment__more-info-field'>
        <AutoCompleteInput
          initialInputValue={props.userPacks
            ? props.userPacks.find(pack => pack.id === packId)?.packInfo.title
            : undefined}
          inputLabel={t('back_office.calendar.appointment.pack_label')}
          renderOption={option => option.title}
          onAutocompleteChange={onAutocompleteChange}
          noResultsLabel='sem resultados'
          results={props.userPacks || []}
          optionLabel={option => option.title}
          openOnFocus
          onInputFocus={props.fetchUserPacksIfNecessary}
          isLoading={props.userPacksLoading}
        />
      </div>
      <UserAutocompleteComponent
        onFieldChange={onUserChange}
        initialUser={user}
      />
      <div className='appointment__more-info-field'>
        <TextField
          id='datetime-local'
          variant='outlined'
          label={t('back_office.calendar.appointment.location_label')}
          type='text'
          fullWidth
          value={location}
          onChange={onLocationChange}
        />
      </div>
      <div className='appointment__more-info-field'>
        <TextField
          id='datetime-local'
          variant='outlined'
          label={t('back_office.calendar.appointment.description_label')}
          type='text'
          fullWidth
          multiline
          rows={2}
          rowsMax={5}
          value={description}
          onChange={onDescriptionChange}
        />
      </div>
      <div className='appointment__action-buttons'>
        {initialAppointment.title && (
          <Button
            className='appointment__action-buttons__button appointment__action-buttons__button--left'
            variant='outlined'
            color='danger'
            onClick={props.onRemove}
          >
            {t('back_office.calendar.appointment.delete')}
          </Button>
        )}
        <Button
          className='appointment__action-buttons__button'
          variant='text'
          color='default'
          onClick={props.onCancel}
        >
          {t('back_office.calendar.appointment.cancel')}
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          onClick={onSaveAppointment}
        >
          {t('back_office.calendar.appointment.confirm')}
        </Button>
      </div>
    </div>
  )
}
