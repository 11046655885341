import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'

export const MultipleInputList = props => {
  const [ values, setValues ] = useState(props.initialValues || [])
  const [ currentValueInput, setCurrentValueInput ] = useState('')

  useEffect(() => {
    setValues(props.initialValues || [])
  }, [props.initialValues])

  const freeSolo = props.freeSolo ?? true

  const onValuesChange = (_event, newValues) => {
    let filteredValues = newValues

    if (!freeSolo && props.options) {
      filteredValues = newValues.filter(value => props.options.includes(value))
    }
    setCurrentValueInput('')
    setValues(filteredValues)
    props.onChange(filteredValues)
  }

  const onCurrentValueChange = (_event, value) => {
    setCurrentValueInput(value)
  }

  const onValuesBlur = _event => {
    if (currentValueInput !== '') {
      onValuesChange(_event, [...values, currentValueInput])
      setCurrentValueInput('')
    }
  }

  return (
    <div onBlur={onValuesBlur}>
      <Autocomplete
        onChange={onValuesChange}
        onInputChange={onCurrentValueChange}
        multiple
        options={props.options || []}
        value={values}
        defaultValue={values}
        inputValue={currentValueInput}
        freeSolo={freeSolo}
        disableCloseOnSelect={props.disableCloseOnSelect}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option}
              variant='outlined'
              label={option}
              {...getTagProps({ index })}
            />
          ))}
        renderInput={params => (
          <TextField {...params} variant='outlined' label={props.inputLabel} />
        )}
      />
    </div>
  )
}
