import {
  MapOutlined as MapOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  AppsOutlined as AppsOutlinedIcon,
} from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import moment from 'moment'
import React from 'react'

import { EventDate } from 'scenes/BackOffice/components/EventDate'

import { Button } from 'components/Button'
import { IconLabel } from 'components/IconLabel'
import { Link } from 'components/Link'
import { Text } from 'components/Text'
import UserCircular from 'components/UserCircular'

import { t } from 'services/i18n'
import { createBackOfficeLink, createPackLink, subRoutesNames } from 'services/routingService'

import './styles.scss'

export const AppointmentQuickView = props => {
  const startDate = moment.unix(props.appointment.startDate)
  const endDate = moment.unix(props.appointment.endDate)
  const appointmentPack = (props.userPacks || []).find(pack => pack.id === props.appointment.packId)

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      transition
      onClose={props.closeAppointmentQuickView}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper className='appointment-quick-view'>
        <Link underlined href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, props.appointment.id)}>
          <Text as='h2' type='subtitle' className='appointment-quick-view__title'>{props.appointment.title}</Text>
        </Link>
        {(props.eventUser || props.appointment.user?.name) && (
          <UserCircular
            className='appointment-quick-view__user'
            size={30}
            avatar={props.eventUser?.avatar}
            withFallback
            name={props.eventUser?.name || props.appointment.user.name || ''} // if the user was loaded when Appointment was opened, use it. Otherwise, it's because event only have the user name so we can use it right away
          />
        )}
        <EventDate
          startDate={startDate}
          endDate={endDate}
        />
        {appointmentPack && (
          <Link underlined href={createPackLink(appointmentPack.id)}>
            <IconLabel
              className='appointment-quick-view__icon-info'
              icon={AppsOutlinedIcon}
              label={appointmentPack.packInfo.title}
            />
          </Link>
        )}
        {props.appointment.location?.address && (
          <IconLabel
            className='appointment-quick-view__icon-info'
            icon={MapOutlinedIcon}
            label={props.appointment.location.address}
          />
        )}
        {props.appointment.description && (
          <IconLabel
            className='appointment-quick-view__icon-info'
            icon={DescriptionOutlinedIcon}
            label={props.appointment.description}
          />
        )}
        <div className='appointment-quick-view__action-buttons'>
          <Button
            className='appointment-quick-view__action-buttons__button'
            variant='outlined'
            color='default'
            onClick={props.closeAppointmentQuickView}
          >
            {t('back_office.calendar.appointment_quick_view.cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={props.editAppointment}
          >
            {t('back_office.calendar.appointment_quick_view.edit')}
          </Button>
        </div>
      </Paper>
    </Popover>
  )
}
