import { TAB_IDS } from 'scenes/UserDetails'
import { useLocalActions } from 'scenes/UserDetails/actions/useLocalActions'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { ACTION, useWalkthrough } from 'hooks/useWalkthrough'

export const WALKTHROUGH_KEY = 'walkthrough-user-details'

export const useUserDetailsWalkthrough = (walkthroughData, logedInUser, onSelectTabId, isEnabled) => {
  const { isSmallTabletAndUp } = useBreakpoints()
  const isMobileOrSmallTablet = !isSmallTabletAndUp

  const {
    updateWalkthroughData,
  } = useLocalActions({ user: logedInUser, userId: logedInUser.uid, username: logedInUser.username })

  const walkthroughSteps = [
    {
      target: '.user-details__header',
      title: 'Bem-vindo ao teu perfil!',
      content: 'Esta é a tua nova cara.\nQueres ter uma visita guiada de 1 minuto? Carrega em "Próximo"',
      disableBeacon: true,
      isFixed: true,
      placement: 'center',
    },
    {
      target: '.user-info',
      content: 'Aqui estão os teus detalhes. Podes editar o teu nome, localização, descrição, redes sociais e categorias em "EDITAR PERFIL"',
    },
    {
      target: '.user-info__user-info-edit-btn',
      content: 'Podes editar o teu nome, localização, descrição, redes sociais e categorias em "EDITAR PERFIL"',
    },
    {
      target: '.user-circular-input__avatar-action',
      content: 'Para editares a tua foto de perfil, clica no icon da máquina fotográfica',
    },
    {
      target: '.mansory-cards-layout',
      content: 'Este é o teu portefólio. É a primeira impressão de quem te visita. Usa o portefólio para mostares as fotos dos teus melhores trabalhos',
      next: () => onSelectTabId(TAB_IDS.PACKS),
      location: () => onSelectTabId(TAB_IDS.PORTFOLIO),
    },
    {
      target: '.tabs-list__item:nth-child(2)',
      content: 'Acede aqui aos Packs',
      next: () => onSelectTabId(TAB_IDS.PACKS),
      prev: () => onSelectTabId(TAB_IDS.PORTFOLIO),
      location: () => onSelectTabId(TAB_IDS.PACKS),
    },
    {
      target: '.mansory-cards-layout',
      content: 'Aqui podes partilhar os teus Packs. Usa Packs para detalhares os trabalhos que tens atualmente.\nPack Família? Pack Batizado? Tu decides.',
      location: () => onSelectTabId(TAB_IDS.PACKS),
    },
    {
      target: '.tabs-list__item:nth-child(3)',
      content: 'Acede aqui às Galerias',
      next: () => onSelectTabId(TAB_IDS.GALLERIES),
      prev: () => onSelectTabId(TAB_IDS.PACKS),
      location: () => onSelectTabId(TAB_IDS.GALLERIES),
    },
    {
      target: '.mansory-cards-layout',
      content: 'Aqui podes partilhar Galerias. Lembras-te daquela sessão que fizeste incrível? Usa este espaço para partilhares trabalhos completos de uma sessão.',
      location: () => onSelectTabId(TAB_IDS.GALLERIES),
    },
    {
      target: isMobileOrSmallTablet ? '.header__menu-button' : '.back-office-logo-link',
      content: 'Pronto para descobrir o mundo incrível do PLOTUStudio? Entra nele quando estiveres pront@',
    },
  ]

  const handleJoyrideCustomCallback = (data, customAction, newIndex) => {
    const { action, index, lifecycle } = data

    if (customAction === ACTION.NEXT || customAction === ACTION.PREV) {
      updateWalkthroughData(WALKTHROUGH_KEY, {
        index: newIndex,
      })
    } else if (customAction === ACTION.SKIPPED || customAction === ACTION.FINISHED) {
      updateWalkthroughData(WALKTHROUGH_KEY, {
        done: true,
        skiped: customAction === ACTION.SKIPPED,
        finished: customAction === ACTION.FINISHED,
      })
    }

    walkthroughSteps.forEach((step, stepIndex) => {
      if (action === 'next' && step.next && stepIndex === index && lifecycle === 'complete') {
        return step.next()
      }

      if (action === 'prev' && step.prev && stepIndex === index && lifecycle === 'complete') {
        return step.prev()
      }

      if (action === 'update' && step.location && stepIndex === index && lifecycle === 'tooltip') {
        return step.location()
      }
    })
  }

  const [walkthroughStepIndex, walkthroughIsRunning, walkthroughIsContinuous, handleJoyrideCallback] =
    useWalkthrough(walkthroughData, handleJoyrideCustomCallback, !logedInUser.username, isEnabled)

  return {
    walkthroughStepIndex,
    walkthroughIsRunning,
    walkthroughIsContinuous,
    handleJoyrideCallback,
    walkthroughSteps,
  }
}
