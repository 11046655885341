import { Switch, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'

import { AutoCompleteLocation } from 'components/AutoCompleteLocation'
import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { MultipleInputList } from 'components/MultipleInputList'
import { PhotoPicker } from 'components/PhotoPicker'
import { TextField } from 'components/TextField'
import UserCircular from 'components/UserCircular'

import { UserType } from './components/UserType'

import { t } from 'services/i18n'

import './styles.scss'

export const ImageInputFormStep = props => {
  const inputs = props.inputs || []

  const [inputsState, setInputsState] = useState(props.initialValues || new Array(inputs.length).fill(undefined))

  useEffect(() => {
    setInputsState(props.initialValues || new Array(inputs.length).fill(undefined))
  }, [inputs.length, props.initialValues, props.inputs])

  const onSubmit = e => {
    setInputsState([])

    props.onSubmit && props.onSubmit(inputsState)
    e.preventDefault()
  }

  const onSkip = () => {
    setInputsState([])

    props.onSkip && props.onSkip()
  }

  const onBack = () => {
    setInputsState([])
    props.onBack(inputsState)
  }

  const onInputChange = (index, type) => event => {
    const inputsStateCopy = [...inputsState]

    if (type === 'checkbox' || type === 'switch') {
      inputsStateCopy[index] = event.target.checked
    } else if (type === 'avatar-picker') {
      inputsStateCopy[index] = event
    } else if (type === 'user-type') {
      inputsStateCopy[index] = event
    } else if (type === 'multiple-input-list') {
      inputsStateCopy[index] = event
    } else {
      inputsStateCopy[index] = event.target.value
    }

    setInputsState(inputsStateCopy)
  }

  // const onLocationAutocompletionChange = value => {
  //   if (!value) {
  //     onFieldChange({ location: undefined })
  //   } else {
  //     onFieldChange({ location: {
  //       description: value.description,
  //       placeId: value.place_id,
  //       terms: value.terms,
  //       types: value.types
  //     }})
  //   }
  // }

  const isDisabled = () =>
    inputsState.some(state => state === undefined || (state && state.length === 0))

  return (
    <div className='image-input-form-step'>
      <div className='image-input-form-step__content--left'>
        <h1 className='image-input-form-step__title'>{props.title}</h1>
        {props.subTitle && <h3 className='image-input-form-step__title'>{props.subTitle}</h3>}
        {inputs && (
          <form onSubmit={onSubmit} className='image-input-form-step__form'>
            {inputs.map((input, index) => (
              <div key={input.id} className='image-input-form-step__form-item'>
                {input.title && <label className='image-input-form-step__form-item-label'>{input.title}</label>}

                {input.type === 'avatar-picker' && (
                  <PhotoPicker
                    src={inputsState[index]?.src} // src could come from user.avatar of image input
                    onImageChange={onInputChange(index, input.type)}
                  />
                )}

                {input.type === 'user-type' && (
                  <UserType
                    content={input.content}
                    onChange={onInputChange(index, input.type)}
                    initialValue={inputsState[index]}
                  />
                )}

                {input.type === 'checkbox' && (
                  <Checkbox
                    onChange={onInputChange(index, input.type)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    checked={inputsState[index]}
                  />
                )}

                {input.type === 'location' && (
                  <AutoCompleteLocation
                    inputLabel={input.label}
                    noResultsLabel={t('back_office.calendar.appointmentForm.event_component.client_label')}
                    renderOption={option => <UserCircular size={30} avatar={option.imgSrc} name={option.name} />}
                    onAutocompleteChange={()=> {}}
                    addFreeSoloText={freeSolotInput => t('back_office.calendar.appointment_form.event_component.user_auto_complete.free_solo_text', { 'free_solo': freeSolotInput })}
                  />
                )}

                {input.type === 'switch' && (
                  <Switch
                    size='small'
                    onChange={onInputChange(index, input.type)}
                    checked={inputsState[index]}
                  />
                )}

                {input.type === 'multiple-input-list' && (
                  <MultipleInputList
                    id={input.id}
                    inputLabel={input.label}
                    initialValues={inputsState[index] || []}
                    onChange={onInputChange(index, input.type)}
                    freeSolo={input.freeSolo}
                    options={input.options}
                    disableCloseOnSelect={input.disableCloseOnSelect}
                  />
                )}

                {input.type === 'text' && (
                  <TextField
                    className='image-input-form-step__form-item__text-field'
                    id={input.id}
                    label={input.label}
                    type={input.type}
                    value={inputsState[index] || ''}
                    onChange={onInputChange(index, input.type)}
                  />
                )}
              </div>
            ))}
            <div className='image-input-form-step__actions'>
              {props.backButton && (
                <div className='image-input-form-step__form-submit image-input-form-step__form-submit--back'>
                  <Button
                    onClick={onBack}
                    variant='contained'
                    color='primary'
                  >
                    {props.backButton.text}
                  </Button>
                </div>
              )}
              {props.skipButton && (
                <div className='image-input-form-step__form-submit image-input-form-step__form-submit--skip'>
                  <Button
                    onClick={onSkip}
                    variant='outlined'
                    color='default'
                  >
                    {props.skipButton.text}
                  </Button>
                </div>
              )}
              {props.submitButton && (
                <div className='image-input-form-step__form-submit'>
                  <Button
                    disabled={isDisabled()}
                    onClick={onSubmit}
                    onSubmit={onSubmit}
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    {props.submitButton.text}
                  </Button>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
      {props.image && (
        <div className='image-input-form-step__content--right'>
          <Image
            className='image-input-form-step__image'
            src={props.image.imgSrc}
            alt={props.image.alt}
            thumbnailsSpecs={[{ size: 'm', media: '(min-width: 0px)' }]}
          />
        </div>
      )}
    </div>
  )
}
