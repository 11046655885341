import {
  DateRange as DateRangeIcon,
  Event as EventIcon,
  QueryBuilder as QueryBuilderIcon,
} from '@mui/icons-material'
import moment from 'moment'
import React, { Fragment } from 'react'

import { IconLabel } from 'components/IconLabel/index.js'

import './styles.scss'

const getEventDuration = (startDate, endDate) => {
  const duration = moment.duration(endDate.diff(startDate))
  const hours = duration.asHours()

  if (hours < 1) {
    return `${duration.asMinutes()}m`
  }

  if (hours > 24) {
    const numberOfDays = Math.floor(hours / 24)
    const hoursRest = hours - (24 * numberOfDays)
    return `${numberOfDays}d e ${hoursRest}h`
  }

  return `${hours}h`
}

export const EventDate = ({ startDate, endDate }) => {
  if (startDate.isSame(endDate, 'days')) {
    const label = moment().isSame(startDate, 'year')
      ? startDate.format('D MMM')
      : startDate.format('D MMM YYYY')

    return (
      <Fragment>
        <IconLabel
          className='event-date__icon-info'
          icon={EventIcon}
          label={label}
        />

        <IconLabel
          className='event-date__icon-info'
          icon={QueryBuilderIcon}
          label={`${startDate.format('HH:mm')} (${getEventDuration(startDate, endDate)})`}
        />
      </Fragment>
    )
  }

  if (!startDate.isSame(endDate, 'days')) {
    const startText = startDate.isSame(endDate, 'year')
      ? startDate.isSame(endDate, 'month')
        ? `${startDate.format('D')} - ${endDate.format('D MMM')}`
        : `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`
      : `${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`

    return (
      <Fragment>
        <IconLabel
          className='event-date__icon-info'
          icon={DateRangeIcon}
          label={(
            <div className='event-date__date'>
              <div>{startText}</div>
            </div>
          )}
        />
        <IconLabel
          className='event-date__icon-info'
          icon={QueryBuilderIcon}
          label={(
            <div>
              <div>{`${startDate.format('HH:mm')} (${getEventDuration(startDate, endDate)})`}</div>
            </div>
          )}
        />
      </Fragment>
    )
  }
}
