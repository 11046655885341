import { useWindowSize } from 'hooks/useWindowSize'

import cssVariables from 'styles/resources/variables.scss'

export const useBreakpoints = () => {
  const [ innerWidth ] = useWindowSize(window.innerWidth)

  return {
    isMobile: innerWidth >= cssVariables.mobileAndUp && innerWidth < cssVariables.smallTabletAndUp,
    isMobileAndUp: innerWidth >= cssVariables.mobileAndUp,
    isSmallTablet: innerWidth >= cssVariables.smallTabletAndUp && innerWidth < cssVariables.tabletAndUp,
    isSmallTabletAndUp: innerWidth >= cssVariables.smallTabletAndUp,
    isTablet: innerWidth >= cssVariables.tabletAndUp && innerWidth < cssVariables.desktopAndUp,
    isTabletAndUp: innerWidth >= cssVariables.tabletAndUp,
    isDesktop: innerWidth >= cssVariables.desktopAndUp && innerWidth < cssVariables.largeDesktopAndUp,
    isDesktopAndUp: innerWidth >= cssVariables.desktopAndUp,
    isLargeDesktopAndUp: innerWidth >= cssVariables.largeDesktopAndUp,
  }
}
