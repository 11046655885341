import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlined from '@mui/icons-material/KeyboardArrowUpOutlined'
import React, { useState, useRef, useEffect } from 'react'

import { Button } from 'components/Button'
import { Text } from 'components/Text'

import { t } from 'services/i18n'

import { isOverflown } from 'utils/dom'

import './styles.scss'

const DEFAULT_MAX_LINES_SHRINKED = 3

export const TextWithShowMore = ({ maxLinesShrinked, className, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isTextOverflown, setIsTextOverflown] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    if (!isExpanded){
      setIsTextOverflown(isOverflown(textRef.current))
    }
  }, [isExpanded])

  const onButtonClick = () => {
    setIsExpanded(val => !val)
  }

  return (
    <div>
      <Text
        ref={textRef}
        as='p'
        lineLimit={isExpanded ? undefined : maxLinesShrinked || DEFAULT_MAX_LINES_SHRINKED}
        className={className}
      >
        {children}
      </Text>
      {isTextOverflown && (
        <Button
          color='neutral'
          size='small'
          variant='text'
          onClick={onButtonClick}
          className='text-with-show-more__expand-button'
          endIcon={
            isExpanded
              ? <KeyboardArrowUpOutlined />
              : <KeyboardArrowDownOutlined />
          }
        >
          {t(isExpanded ? 'components.text_with_show_more.see_less' : 'components.text_with_show_more.see_more')}
        </Button>
      )}
    </div>
  )
}
