import { routes } from './routes'

import BackOffice from 'scenes/BackOffice'
import FAQs from 'scenes/FAQs'
import Gallery from 'scenes/Gallery'
import Home from 'scenes/Home'
import PackDetails from 'scenes/PackDetails'
import Pricing from 'scenes/Pricing'
import Professionals from 'scenes/Professionals'
import RegisterJourney from 'scenes/RegisterJourney'
import Search from 'scenes/Search'
import UserDetails from 'scenes/UserDetails'
import UserSettings from 'scenes/UserSettings'
import SignUp from 'scenes/SignUp'
import Blog from 'scenes/Blog'

export const fullScreenRoutesConfig = [
  {
    path: routes.REGISTER_JOURNEY,
    component: RegisterJourney,
    requireSignIn: true,
    options: {
      exact: true,
    },
  },
  {
    path: routes.ACCESS,
    component: SignUp,
    options: {
      exact: true,
    },
  },
]

export const routesConfig = [
  {
    path: routes.PACK_DETAILS,
    component: PackDetails,
    options: {
      exact: true,
    },
  },
  {
    path: routes.USER_DETAILS,
    component: UserDetails,
    options: {
      exact: true,
    },
  },
  {
    path: routes.USER_SETTINGS,
    requireSignIn: true,
    component: UserSettings,
    options: {
      exact: true,
    },
  },
  {
    path: routes.BACK_OFFICE,
    requireSignIn: true,
    component: BackOffice,
    options: {
      exact: false,
      footer: false,
    },
  },
  {
    path: routes.SEARCH,
    component: Search,
    options: {
      exact: true,
    },
  },
  {
    path: routes.GALLERY,
    component: Gallery,
    options: {
      exact: true,
      header: false,
    },
  },
  {
    path: routes.PROFESSIONALS,
    component: Professionals,
  },
  {
    path: routes.PRICING,
    component: Pricing,
  },
  {
    path: routes.FAQS,
    component: FAQs,
  },
  {
    path: routes.BLOG,
    component: Blog,
  },
  // This should be right before Home
  {
    path: routes.USER,
    component: UserDetails,
    options: {
      exact: true,
    },
  },
  // This should be the last route
  {
    path: routes.HOME,
    component: Home,
  },
]
