import AddCircleOutlineOutlinedIcon from '@mui/icons-material/DoneAllOutlined'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Image } from 'components/Image'

import { t } from 'services/i18n'

import './styles.scss'

export const PackImagePicker = props => {
  const { title, onSave, selectedImagesIdx, images } = props
  const [ selectedPackImagesIdxs, setSelectedPackImagesIdxs ] = useState(selectedImagesIdx)

  useEffect(() => {
    setSelectedPackImagesIdxs(selectedImagesIdx)
  }, [selectedImagesIdx])

  const toggleChooseImage = idx => {
    const selectedImagesIdxCopy = [...selectedPackImagesIdxs]
    if (selectedImagesIdxCopy.includes(idx)) {
      const selectedImageIdx = selectedImagesIdxCopy.findIndex(imgIdx => imgIdx === idx)
      selectedImagesIdxCopy.splice(selectedImageIdx, 1)
    } else {
      selectedImagesIdxCopy.push(idx)
    }
    setSelectedPackImagesIdxs(selectedImagesIdxCopy)
  }

  const saveSelectedPackImages = () => {
    onSave(selectedPackImagesIdxs)
  }

  return (
    <div>
      <h2>{title}</h2>
      <div className='pack-image-picker'>
        <Grid spacing={10} xs={6} sm={4} md={3} xl={2}>
          {images.map((img, idx) => (
            <div key={img.src} className='pack-image-picker__image-wrapper'>
              <Image
                className={classNames('pack-image-picker__image', {
                  'pack-image-picker__image--selected': selectedPackImagesIdxs.includes(idx),
                })}
                onClick={() => toggleChooseImage(idx)}
                src={img.src}
                thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
              />
              {selectedPackImagesIdxs.includes(idx) && (
                <AddCircleOutlineOutlinedIcon
                  className='pack-image-picker__selected-image'
                />
              )}
            </div>
          ))}
        </Grid>
      </div>
      <div className='pack-image-picker__actions'>
        <Button
          className='pack-image-picker__actions__button'
          variant='outline'
          onClick={props.onCancel}
        >
          {t('user_details.portfolio_input.cancel')}
        </Button>
        <Button
          className='pack-image-picker__actions__button'
          variant='contained'
          color='primary'
          onClick={saveSelectedPackImages}
        >
          {t('user_details.portfolio_input.save')}
        </Button>
      </div>
    </div>
  )
}
