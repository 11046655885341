import { Grid } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLocalActions } from '../../actions/useLocalActions.js'
import { StorageLimitWidget } from '../../components/StorageLimitWidget'
import { ValueWidget } from '../../components/ValueWidget'
import { WeekCalendar } from '../../components/WeekCalendar'
import { WelcomeComponent } from '../../components/WelcomeComponent'

import { currentLogedInUser } from 'store/users/selectors.js'

import { t } from 'services/i18n'
import { createBackOfficeLink, subRoutesNames } from 'services/routingService'

import { formatBytes } from 'utils/storageData.js'

import './styles.scss'

const getEventsCountThisMonth = (calendarData = {}) => {
  return Object.keys(calendarData)
    .filter(eventKey => {
      const event = calendarData[eventKey]
      return moment.unix(event.startDate).isSameOrAfter(moment().startOf('month')) && moment.unix(event.startDate).isSameOrBefore(moment().endOf('month'))
    })
    .length
}

const getPacksCount = user => user.packs?.cards.length || 0

export const Dashboard = () => {
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(currentLogedInUser)
  const calendarData = useSelector(state => state.agendas.events.data)

  const {
    fetchUserAgendaEvents,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    // logedInUserId as a dependecy to perform only one request
    const startDateUnix = moment().subtract(2, 'week').unix()
    const endDateUnix = moment().add(2, 'week').unix()

    const options = { startDateUnix, endDateUnix }
    logedInUserId && fetchUserAgendaEvents(options)
  }, [fetchUserAgendaEvents, logedInUser, logedInUserId])

  return (
    <div className='dashboard'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <div className='dashboard__widget'>
            <WelcomeComponent calendarData={calendarData} user={logedInUser} />
          </div>
          <div className='dashboard__widget'>
            <WeekCalendar calendarData={calendarData} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid className='dashboard__box' item xs={6} sm={12} md={6}>
              <div className='dashboard__widget'>
                <ValueWidget
                  iconId='apps'
                  smallIcon
                  title={t('back_office.dashboard.value_widgets.packs.title')}
                  value={getPacksCount(logedInUser)}
                  footerText={t('back_office.dashboard.value_widgets.packs.footerText')}
                  actionHref={createBackOfficeLink(subRoutesNames.BACK_OFFICE.PACKS)}
                  actionText={t('back_office.dashboard.value_widgets.packs.actionText')}
                />
              </div>
            </Grid>
            <Grid className='dashboard__box' item xs={6} sm={12} md={6}>
              <div className='dashboard__widget'>
                <ValueWidget
                  iconId='event'
                  smallIcon
                  title={t('back_office.dashboard.value_widgets.events.title')}
                  value={getEventsCountThisMonth(calendarData)}
                  footerIconId='date_range'
                  footerText={t('back_office.dashboard.value_widgets.events.footerText')}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid className='dashboard__box' item md={12} lg={6}>
              <div className='dashboard__widget'>
                <StorageLimitWidget
                  iconId='sd_storage'
                  smallIcon
                  title={t('back_office.dashboard.value_widgets.storage_limit.title')}
                  value={formatBytes(logedInUser.storageSize?.total || 0)}
                  storage={{
                    other: logedInUser.storageSize?.other,
                    packs: logedInUser.storageSize?.packs,
                    portfolio: logedInUser.storageSize?.portfolio,
                    galleries: logedInUser.storageSize?.galleries,
                  }}
                  isLoading={!logedInUser.username}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
