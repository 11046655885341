import moment from 'moment'

import {
  getUserEvent,
  getUserEvents,
  postAgendaEvent,
  putAgendaEvent,
  deleteAgendaEvent,
} from 'services/serverBridge'

export const REQUEST_AGENDA_EVENTS = 'REQUEST_AGENDA_EVENTS'
export const ADD_AGENDA_EVENTS = 'ADD_AGENDA_EVENTS'
export const ADD_CALENDAR_EVENTS = 'ADD_CALENDAR_EVENTS'
export const ADD_RECENT_EVENTS = 'ADD_RECENT_EVENTS'
export const ADD_AGENDA_EVENT = 'ADD_AGENDA_EVENT'
export const SET_REMOVING_AGENDA_EVENT = 'SET_REMOVING_AGENDA_EVENT'
export const REMOVE_AGENDA_EVENT = 'REMOVE_AGENDA_EVENT'
export const EDIT_AGENDA_EVENT = 'EDIT_AGENDA_EVENT'
export const EDIT_AGENDA_EVENT_GALLERY_DATA = 'EDIT_AGENDA_EVENT_GALLERY_DATA'
export const UPDATE_CALENDAR_EVENTS_CACHE = 'UPDATE_CALENDAR_EVENTS_CACHE'

function fetchAgendaAction() {
  return {
    type: REQUEST_AGENDA_EVENTS,
  }
}

function addAgendaEventsAction(events) {
  return {
    type: ADD_AGENDA_EVENTS,
    data: events,
  }
}

function addCalendarEventsAction(events, calendarCacheEntryKey) {
  return {
    type: ADD_CALENDAR_EVENTS,
    data: {
      events,
      calendarCacheEntryKey,
    },
  }
}

function updateCalendarEventsCacheAction(events, calendarCacheEntryKey) {
  return {
    type: UPDATE_CALENDAR_EVENTS_CACHE,
    data: {
      events,
      calendarCacheEntryKey,
    },
  }
}

function addRecentEventsAction(events) {
  return {
    type: ADD_RECENT_EVENTS,
    data: events,
  }
}

function addAgendaEventAction(event) {
  return {
    type: ADD_AGENDA_EVENT,
    data: event,
  }
}

function setRemovingAgendaEventAction(eventId) {
  return {
    type: SET_REMOVING_AGENDA_EVENT,
    data: {
      eventId,
    },
  }
}

function removeAgendaEventAction(eventId) {
  return {
    type: REMOVE_AGENDA_EVENT,
    data: {
      eventId,
    },
  }
}

function editAgendaEventAction(event) {
  return {
    type: EDIT_AGENDA_EVENT,
    data: event,
  }
}

export function editAgendaEventGalleryDataAction(galleryEventId, galleryId, shouldDelete) {
  return {
    type: EDIT_AGENDA_EVENT_GALLERY_DATA,
    data: {
      galleryEventId,
      galleryId,
      shouldDelete,
    },
  }
}

export const addAgendaEvent = (userUid, event) => {
  return (dispatch, getState) => {
    dispatch(addAgendaEventAction(event))
    const calendarCacheEntryKey = moment.unix(event.startDate).startOf('month').format('MM-YYYY')
    // only if calendar has already fetched the data associated with this new event, the event can be added to calendar cache
    // otherwise cache will return only this event, instead of fetching all events
    if (getState().agendas.calendar.cacheFifo.includes(calendarCacheEntryKey)) {
      dispatch(addCalendarEventsAction({ [event.id]: event }, calendarCacheEntryKey))
    }
    dispatch(addRecentEventsAction({ [event.id]: event }))
    return postAgendaEvent(userUid, event)
  }
}

export const editAgendaEvent = (userUid, event) => {
  return dispatch => {
    dispatch(editAgendaEventAction(event))
    return putAgendaEvent(userUid, event)
  }
}

export const setRemovingAgendaEvent = eventId => {
  return dispatch => {
    dispatch(setRemovingAgendaEventAction(eventId))
  }
}

export const removeAgendaEvent = (userUid, eventId) => {
  return dispatch => {
    dispatch(removeAgendaEventAction(eventId))
    return deleteAgendaEvent(userUid, eventId)
  }
}

export const editAgendaEventGalleryDataLocally = (galleryEventId, gallery) => {
  return dispatch => {
    dispatch(editAgendaEventGalleryDataAction(galleryEventId, gallery.id))
  }
}

export const removeGalleryFromAgendaEvent = (eventId, galleryId) => {
  return dispatch => {
    dispatch(editAgendaEventGalleryDataAction(eventId, galleryId, true))
  }
}

export const fetchUserEvent = (userId, eventId) => {
  return (dispatch, getState) => {
    dispatch(fetchAgendaAction())

    const agendasState = getState().agendas
    const calendarData = agendasState.events.data
    if (calendarData?.[eventId]) {
      return Promise.resolve(calendarData[eventId])
    }

    return getUserEvent(userId, eventId)
      .then(event => {
        dispatch(addAgendaEventsAction({ [event.id]: event }))
        return event
      })
  }
}

export const fetchUserMonthlyAgenda = (userId, options = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchAgendaAction())

    const calendarCacheEntryKey = options.cacheKey || `${options.startDateUnix}-${options.endDateUnix}`
    const agendasState = getState().agendas
    const calendarData = agendasState.calendar.data
    if (calendarData[calendarCacheEntryKey]) {
      const calendarEvents = calendarData[calendarCacheEntryKey].map(eventKey => agendasState.events.data[eventKey])

      dispatch(updateCalendarEventsCacheAction(calendarEvents, calendarCacheEntryKey)) // update cache

      return Promise.resolve(calendarEvents)
    }

    return getUserEvents(userId, options)
      .then(events => {
        // even if there is no events, add entry to cache
        // first, this will avoid more requests when month has no events,
        // secondly, this prevents ensures that a new event is can be added to calendar, when the month is empty. Otherwise, addAgendaEvent would not add the new event to the calendar state
        if (events) {
          dispatch(addAgendaEventsAction(events))
          dispatch(addCalendarEventsAction(events, calendarCacheEntryKey))
        }
      })
  }
}

export const fetchUserRecentAgenda = (userId, options) => {
  return dispatch => {
    dispatch(fetchAgendaAction())

    return getUserEvents(userId, options)
      .then(events => dispatch(addRecentEventsAction(events)))
  }
}

export const fetchUserAgendaEvents = (userId, options) => {
  return dispatch => {
    dispatch(fetchAgendaAction())
    return getUserEvents(userId, options)
      .then(events => dispatch(addAgendaEventsAction(events)))
  }
}
