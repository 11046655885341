import { useState, useEffect } from 'react'

import { UPSELL_MODAL_NOTIFICATION_LISTENER_ID } from 'components/UpsellModal'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { getNotificationSystem } from 'services/notificationSystem'

export const ACTION_KEYS = {
  GALLERIES: 'GALLERIES',
  WATERMARKS: 'WATERMARKS',
}

// TODO: We are using plan indexes since the plan ids are not the same in development and production environments
// This should be improved
const ACTION_KEYS_MIN_PLAN = {
  GALLERIES: 1, // PLOTU PRO Base
  WATERMARKS: 1, // PLOTU PRO Base (limited to 2 galleries)
}

const i18nKeys = {
  GALLERIES: 'galleries',
  WATERMARKS: 'watermarks',
}

const notificationSystem = getNotificationSystem()

/**
 * This hooks checks if the user current plan is lower than the required plan to a certain action (identified with actionKey)
 * a isRestrictedPredicate function can be passed under options prop so that not only the current plan is checked but also extra conditions
 * @param {*} user current user
 * @param {*} actionKey key to identify action user is trying to access
 * @param {
 *  isRestrictedPredicate: (userPlanIsLowerThanRequired: boolean) => boolean;
 * } options
 * @returns `hasPermission` label that can be checked if the user has permission for the specific action & `showUpsell` that can be used to prompt the upsell modal
 *
 */
export const useRestrictedAction = (user, actionKey, options) => {
  const [hasPermission, setHasPermission] = useState()
  const [recommendedPlanId, setRecommendedPlanId] = useState()
  const [customNotifyOptions, setCustomNotifyOptions] = useState()

  const showUpsell = () => {
    notificationSystem.notify({
      recommendedPlanId,
      featureName: customNotifyOptions?.titlei18nSuffix
        ? t(`components.upsell_modal.${i18nKeys[actionKey]}.${customNotifyOptions.titlei18nSuffix}`)
        : t(`components.upsell_modal.${i18nKeys[actionKey]}.title`),
      description: customNotifyOptions?.descriptioni18nSuffix
        ? t(`components.upsell_modal.${i18nKeys[actionKey]}.${customNotifyOptions.descriptioni18nSuffix}`)
        : t(`components.upsell_modal.${i18nKeys[actionKey]}.description`),
      updateLabel: customNotifyOptions?.updateLabeli18nSuffix
        ? t(`components.upsell_modal.${i18nKeys[actionKey]}.${customNotifyOptions.updateLabeli18nSuffix}`)
        : t(`components.upsell_modal.${i18nKeys[actionKey]}.update_label`),
      ...(customNotifyOptions || {}),
    }, UPSELL_MODAL_NOTIFICATION_LISTENER_ID)
  }

  useEffect(() => {
    const planKeys = getConfigValue('general.pricing.plan_keys')

    const userCurrentPlanId = user.plan?.id

    const userPlanIndex = Math.max(
      planKeys.findIndex(planKey => planKey === userCurrentPlanId),
      0
    )

    const minimumPlanIndex = ACTION_KEYS_MIN_PLAN[actionKey] || 1
    setRecommendedPlanId(planKeys[minimumPlanIndex])
    if (options?.isRestrictedPredicate) {
      const predicateOptions = {
        userPlanIndex,
        minimumPlanIndex,
      }

      if (options.customNotifyOptionsPredicate) {
        setCustomNotifyOptions(options.customNotifyOptionsPredicate(userPlanIndex < minimumPlanIndex, predicateOptions))
      }

      setHasPermission(!options.isRestrictedPredicate(userPlanIndex < minimumPlanIndex, predicateOptions))
    } else {
      setHasPermission(userPlanIndex >= minimumPlanIndex)
    }
  }, [actionKey, user.plan, options])

  return [hasPermission, showUpsell]
}
