import { getConfigValue, CONFIG_KEYS } from 'services/configService'

const recursiveFindPageById = (pagesConfig, pageId) => {
  const pageKeys = Object.keys(pagesConfig)

  for (let index = 0; index < pageKeys.length; index++) {
    const pageConfig = pagesConfig[pageKeys[index]]
    if (pageKeys[index] === pageId) {
      return pageConfig
    }

    if (pageConfig.subPages) {
      const res = recursiveFindPageById(pageConfig.subPages, pageId)
      if (res) {
        return res
      }
    }
  }

  return null
}

export const findPageById = pageId => {
  const staticPagesRoutes = getConfigValue('staticPages', CONFIG_KEYS.DYNAMIC_STATIC_PAGES)
  return recursiveFindPageById(staticPagesRoutes, pageId)
}
