import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { useDebounce } from 'hooks/useDebounce'

import { t } from 'services/i18n'

import './styles.scss'

export const SearchBar = props => {
  const [ isFocused, setIsFocused ] = useState(false)
  const [ searchInputValue, setSearchInputValue ] = useState(props.initialValue || '')

  useEffect(() => {
    props.initialValue !== undefined && setSearchInputValue(props.initialValue)
  }, [props.initialValue])

  const debouncedSearchTerm = useDebounce(searchInputValue, 250)

  const onSubmit = e =>
    props.onSubmit && props.onSubmit(e, searchInputValue)

  const { onSearchInputChange } = props

  useEffect(() => {
    onSearchInputChange && onSearchInputChange(debouncedSearchTerm)
  }, [debouncedSearchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = event => setSearchInputValue(event.target.value)

  const focusedState = isFocused || searchInputValue.length > 0

  return (
    <form
      className={classNames('search-bar', props.className)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onSubmit={onSubmit}
    >
      <label
        className={`search-bar__label${focusedState ? ' search-bar__label--focused' : ''}`}
      >
        {t('home.search.label')}
      </label>
      <input
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={focusedState ? t('home.search.placeholder') : ''}
        value={searchInputValue}
        onChange={handleChange}
        className={classNames('search-bar__input', props.inputClassName)}
        type='search'
        aria-label='search'
      />
    </form>
  )
}
