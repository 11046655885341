import * as Sentry from '@sentry/react'

import { NOTIFICATION_TYPE } from 'components/GlobalNotifications'

import { getNotificationSystem } from 'services/notificationSystem'

import { isDevelopmentOrLess } from 'utils/envs'

const notificationSystem = getNotificationSystem()

export const logError = (error, customMessage, notifyUser) => {
  if (isDevelopmentOrLess()) {
    customMessage && console.error(customMessage)
    console.error(error)
  }

  if (notifyUser === true) {
    notificationSystem.notify({
      message: customMessage,
      type: NOTIFICATION_TYPE.ERROR,
    })
  }

  Sentry.captureException(error)
  Sentry.captureMessage(error.message)
}

export const logErrorToUser = errorMessage => {
  notificationSystem.notify({
    message: errorMessage,
    type: NOTIFICATION_TYPE.ERROR,
  })
}
