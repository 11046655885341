import LocationOn from '@mui/icons-material/LocationOn'
import Share from '@mui/icons-material/Share'
import React, { useState } from 'react'

import { Button } from 'components/Button'
import { Categories } from 'components/Categories'
import { Link } from 'components/Link'
import { Modal } from 'components/Modal'
import { PhotoPicker } from 'components/PhotoPicker'
import { TextWithShowMore } from 'components/TextWithShowMore'
import { UploadFeedback } from 'components/UploadFeedback'
import UserCircular from 'components/UserCircular'
import { UserCircularInput } from 'components/UserCircularInput'
import { UserInfoInput } from 'components/UserInfoInput'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import { t } from 'services/i18n'

import './styles.scss'

const getExternalLinkGenericName = link => {
  if (link.includes('instagram')) {
    return 'Instagram'
  }

  if (link.includes('facebook')) {
    return 'Facebook'
  }

  return ''
}

export const UserInfo = props => {
  const [ photoModalIsOpen, setPhotoModalIsOpen ] = useState(false)
  const [ userAvatar, setUserAvatar ] = useState({})
  const [ isUserInfoEditModalOpen, setIsUserInfoEditModalOpen ] = useState(false)

  const closePhotoModal = () => {
    setPhotoModalIsOpen(false)
  }

  const openPhotoModal = () => {
    setPhotoModalIsOpen(true)
  }

  const onUserAvatarChange = avatar => {
    setUserAvatar(avatar || {})
  }

  const onSaveUserAvatar = () => {
    props.onSaveUserAvatar(userAvatar)
    setPhotoModalIsOpen(false)
  }

  const toggleUserInfoEditModal = () => {
    setIsUserInfoEditModalOpen(lastState => !lastState)
  }

  const closeUserInfoEditModal = () => setIsUserInfoEditModalOpen(false)

  const onSaveUserInfo = info => {
    props.onSaveUserInfo(info)
    setIsUserInfoEditModalOpen(false)
  }

  const { user, externalLinks, location, description, categories, isOwnerUser, withFallback } = props

  return (
    <div className='user-info'>
      <div className='user-info__avatar-wrapper'>
        <div className='user-info__avatar-edit-wrapper'>
          {!isOwnerUser && (
            <UserCircular
              className='user-info__avatar'
              noInfo
              withFallback={withFallback || true}
              size={160}
              {...user}
            />
          )}
          {isOwnerUser && (
            <UserCircularInput
              withFallback={withFallback || true}
              noInfo
              onAddImageClick={openPhotoModal}
              uploadStatus={props.uploadingAvatar}
              avatarAnimationIsDone={props.avatarAnimationIsDone}
              {...user}
            />
          )}
        </div>
      </div>
      {isOwnerUser && (
        <div className='user-info__user-info-edit-btn'>
          <Button
            onClick={toggleUserInfoEditModal}
            variant='outlined'
            color='primary'
            size='small'
          >
            {t('user_details.user_info.info_edit')}
          </Button>
        </div>
      )}
      <div className='user-info__content'>
        <h1 className='user-info__name'>{user.name}</h1>
        <div className='user-info__secondary-info'>
          {location?.address && (
            <span className='user-info__location-wrapper'>
              <LocationOn />
              <div className='user-info__location'>{location.address}</div>
            </span>
          )}
          {externalLinks && externalLinks.length > 0 && (
            <span className='user-info__external-links-wrapper'>
              <Share />
              <ol className='user-info__external-links'>
                {externalLinks.map((externalLink, idx) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={idx} className='user-info__external-links__link'>
                      <Link
                        target='_blank'
                        external
                        href={externalLink.url}
                      >
                        {externalLink.name || getExternalLinkGenericName(externalLink.url) }
                      </Link>
                    </li>
                  )
                })}
              </ol>
            </span>
          )}
        </div>
        {description && (
          <TextWithShowMore className='user-info__description'>
            {description}
          </TextWithShowMore>
        )}
        {categories && (
          <Categories
            className='user-info__categories'
            categories={categories.map(category => category.name)}
          />
        )}
      </div>
      {isOwnerUser && props.uploadingUserInfo !== UPLOAD_STATUS.NOT_UPLOADED && (
        <div className='user-info__user-info-upload-status'>
          <UploadFeedback
            uploading={props.uploadingUserInfo === UPLOAD_STATUS.UPLOADING}
            uploaded={props.uploadingUserInfo === UPLOAD_STATUS.UPLOADED}
            animationIsDone={props.userInfoAnimationIsDone}
          />
        </div>
      )}
      <Modal
        minWidth
        open={photoModalIsOpen}
        closeModal={closePhotoModal}
        title={t('user_details.user_info_avatar_input.title')}
      >
        <PhotoPicker
          src={user.avatar}
          onImageChange={onUserAvatarChange}
        />
        <div className='user-info__user-photo-modal__actions'>
          <Button
            className='user-info__user-photo-modal__actions__button'
            variant='outlined'
            color='default'
            onClick={closePhotoModal}
          >
            {t('user_details.user_info_avatar_input.cancel')}
          </Button>
          <Button
            className='user-info__user-photo-modal__actions__button'
            variant='contained'
            color='primary'
            onClick={onSaveUserAvatar}
          >
            {t('user_details.user_info_avatar_input.save')}
          </Button>
        </div>
      </Modal>
      <Modal
        open={isUserInfoEditModalOpen}
        closeModal={closeUserInfoEditModal}
        title={t('components.user_info_input.title')}
      >
        <UserInfoInput
          userType={user.type}
          initialName={user.name}
          initialDescription={description}
          initialLocation={location}
          initialCategories={categories}
          initialExternalLinks={externalLinks}
          onSaveUserInfo={onSaveUserInfo}
          onCancelClick={closeUserInfoEditModal}
          initialServices={user.services}
        />
      </Modal>
    </div>
  )
}
