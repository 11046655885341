import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'

import { Button } from 'components/Button'
import { List } from 'components/List'
import { SearchBar } from 'components/Search'

import { SearchResults } from './components/SearchResults'
import { UISelector } from './components/UISelector'

import { useBreakpoints } from 'hooks/useBreakpoints'
import { useUrlQuery } from 'hooks/useUrlQuery'

import { searchQuery as searchQueryAction } from 'store/search/actions'
import { queryHitsSelector, queryIsLoadingSelector } from 'store/search/selectors'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { changeRoute, createSearchLink } from 'services/routingService'

import { SEARCH_GROUP_IDS, getAvailableSearchGroups } from 'utils/search'

import './styles.scss'

const getUIViews = () =>
  getConfigValue('search.views.list')
    .map(view => ({ ...view, text: t(view.text) }))

const getSearchGroupIdBySearchCategoryParam = searchCategoryParam =>
  searchCategoryParam ? SEARCH_GROUP_IDS[searchCategoryParam.toUpperCase()] : SEARCH_GROUP_IDS.ALL

const Search = () => {
  const { searchCategory: searchCategoryParam } = useParams()

  const searchUrlGroup = getSearchGroupIdBySearchCategoryParam(searchCategoryParam)
  const searchUrlQuery = useUrlQuery('q') || ''
  const selectedViewId = useUrlQuery('v') || getConfigValue('search.views.initialSelectionId')
  const pageIndex = parseInt(useUrlQuery('i')) || 0
  const dispatch = useDispatch()
  const history = useHistory()
  const { isMobile } = useBreakpoints()
  const { hits: queryHits = [], metadata: queryMetadata = {} } =
    useSelector(queryHitsSelector(searchUrlQuery, searchUrlGroup, pageIndex))
  const queryIsLoading = useSelector(queryIsLoadingSelector)

  const searchQuery = useCallback(
    (searchUrlQuery, searchGroup) => dispatch(searchQueryAction(searchUrlQuery, searchGroup, pageIndex)),
    [dispatch, pageIndex]
  )

  useEffect(() => {
    searchQuery(searchUrlQuery, searchUrlGroup)
  }, [searchUrlGroup, searchQuery, searchUrlQuery])

  const onSubmit = e => e.preventDefault()

  const onSearchInputChange = useCallback(searchInputValue => {
    if (searchInputValue) {
      changeRoute(history, createSearchLink(searchUrlGroup, searchInputValue, 0))
    } else if (searchInputValue === '' && searchInputValue !== searchUrlQuery) {
      changeRoute(history, createSearchLink(searchUrlGroup, undefined, 0, undefined, true, ['q', 'v', 'i']))
    }
  }, [history, searchUrlGroup, searchUrlQuery])

  const onSearchGroupChange = groupId => changeRoute(history, createSearchLink(groupId, searchUrlQuery, 0))

  const onSelectedViewChange = selectedViewId =>
    changeRoute(history, createSearchLink(searchUrlGroup, searchUrlQuery, pageIndex, selectedViewId))

  return (
    <article className='search'>
      <div className='search__search-bar'>
        <SearchBar
          onSubmit={onSubmit}
          onSearchInputChange={onSearchInputChange}
          initialValue={searchUrlQuery}
        />
      </div>
      <UISelector
        views={getUIViews()}
        selectedId={selectedViewId}
        onSelectView={onSelectedViewChange}
      />
      <div className='search__main'>
        <div className='search__side-options'>
          <List
            className='search__search-groups-wrapper'
            vertical={!isMobile}
            withSeparator={isMobile}
            separatedIndexes={[0]}
            separator='|'
            nowrap
            padding={10}
            separatorClassName='search__search-groups__separator'
          >
            {getAvailableSearchGroups().map(group => (
              <Button
                href={createSearchLink(group.id, undefined, 0)}
                key={group.id}
                onClick={() => onSearchGroupChange(group.id)}
                variant='outlined'
                color={searchUrlGroup === group.id ? 'primary' : 'default'}
              >
                {group.name}
              </Button>
            ))}
          </List>
          <div className='search__filters'>
            {/* {t('search.filters.title')} */}
          </div>
        </div>
        <SearchResults
          isLoading={queryIsLoading}
          results={queryHits}
          searchUrlGroup={searchUrlGroup}
          selectedViewId={selectedViewId}
        />
      </div>
      {queryMetadata.totalPagesCount > 1 && (
        <Pagination
          className='search__pagination'
          count={queryMetadata.totalPagesCount}
          color='primary'
          page={pageIndex + 1}
          onClick={() => window.scrollTo(0, 0)}
          renderItem={item => (
            <PaginationItem
              component={Link}
              to={createSearchLink(searchUrlGroup, searchUrlQuery, item.page - 1, selectedViewId)}
              {...item}
            />
          )}
        />
      )}
    </article>
  )
}

export default Search
