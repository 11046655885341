import Divider from '@mui/material/Divider'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState, Fragment } from 'react'
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from 'react-icons/md'

import { EventDate } from 'scenes/BackOffice/components/EventDate'

import { IconButton, LinkButton } from 'components/Button'
import { Text } from 'components/Text'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { t } from 'services/i18n'
import { createBackOfficeLink, subRoutesNames } from 'services/routingService'

import { weekOfMonth } from 'utils/date'

import './styles.scss'

const THRESHOLD = 2
const MOBILE_THRESHOLD = 8

export const WeekCalendar = props => {
  const [weekIndex, setWeekIndex] = useState(0)
  const [currentDate, setCurrentDate] = useState(moment())
  const [selectedDate, setSelectedDate] = useState(moment())
  const { isMobile } = useBreakpoints()
  const calendarData = props.calendarData || {}
  const currentDateEvents = Object.keys(calendarData)
    .filter(key => moment.unix(calendarData[key].startDate).isSame(selectedDate, 'days'))
    .map(key => calendarData[key])

  const startDate = isMobile
    ? currentDate.clone().subtract(2, 'days')
    : currentDate.clone().startOf('week')
  const firstDay = startDate.format('DD')
  const endDate = startDate.clone().add(isMobile ? 4 : 6, 'days')
  const lastDay = endDate.format('DD')

  const incrementDate = () => {
    if (isMobile) {
      if (weekIndex < MOBILE_THRESHOLD) {
        setWeekIndex(weekIndex + 1)
        setCurrentDate(currentDate.clone().add(1, 'day'))
      }

      if (!selectedDate.isBetween(startDate.clone(), endDate.clone())) {
        setSelectedDate(selectedDate.clone().add(1, 'day'))
      }
      return
    }

    if (weekIndex < THRESHOLD) {
      const newWeekIndex = weekIndex + 1
      setWeekIndex(newWeekIndex)
      setCurrentDate(moment().add(newWeekIndex, 'week'))
    }
  }

  const decrementDate = () => {
    if (isMobile) {
      if (weekIndex > -MOBILE_THRESHOLD) {
        setWeekIndex(weekIndex - 1)
        setCurrentDate(currentDate.clone().subtract(1, 'day'))
      }

      if (!selectedDate.isBetween(startDate.clone(), endDate.clone())) {
        setSelectedDate(selectedDate.clone().subtract(1, 'day'))
      }
      return
    }

    if (weekIndex > -THRESHOLD) {
      const newWeekIndex = weekIndex - 1
      setWeekIndex(newWeekIndex)
      setCurrentDate(moment().add(newWeekIndex, 'week'))
    }
  }

  const changeCurrentDate = newDate => () => {
    if (isMobile) {
      const dateDiff = Math.abs(newDate.diff(moment(), 'day'))
      if (dateDiff < -MOBILE_THRESHOLD + 2 || dateDiff > MOBILE_THRESHOLD + 2) {
        return
      }
    }
    setSelectedDate(newDate)
  }

  return (
    <div className='week-calendar'>
      <div className='week-calendar__header'>
        <Text type='subtitle2'>{`${firstDay}-${lastDay} ${startDate.format('MMMM [de] YYYY')}, semana ${weekOfMonth(startDate)}`}</Text>
      </div>
      <div className='week-calendar__picker'>
        <div className='week-calendar__side-arrow week-calendar__side-arrow--left'>
          <IconButton
            disabled={isMobile ? weekIndex <= -MOBILE_THRESHOLD : weekIndex <= -THRESHOLD}
            color='default'
            size='big'
            aria-label='delete'
            onClick={decrementDate}
          >
            <MdKeyboardArrowLeft />
          </IconButton>
        </div>
        <ol className='week-calendar__list'>
          {new Array(isMobile ? 5 : 7).fill(true).map((_, dateIndex) => {
            const date = startDate.clone().add(dateIndex, 'day')
            const hasEvents = Object.keys(calendarData)
            .find(key => moment.unix(calendarData[key].startDate).isSame(date, 'days'))

            const isToday = date.isSame(moment(), 'day')
            const isCurrentDate = date.isSame(selectedDate, 'day')

            return (
              <li
                key={date.unix()}
                className={classNames('week-calendar__list-item', {
                  'week-calendar__list-item--selected': isCurrentDate,
                })}
              >
                <button onClick={changeCurrentDate(date)} className='week-calendar__list-item__button'>
                  <div className='week-calendar__list-item__week-day'>{date.format('ddd')}</div>
                  <div className={classNames('week-calendar__list-item__day', {
                    'week-calendar__list-item__day--today': isToday,
                    'week-calendar__list-item__day--with-events': hasEvents,
                  })}
                  >
                    {date.format('D')}
                  </div>
                </button>
              </li>
            )
          })}
        </ol>
        <div className='week-calendar__side-arrow week-calendar__side-arrow--right'>
          <IconButton
            disabled={isMobile ? weekIndex >= MOBILE_THRESHOLD : weekIndex >= THRESHOLD}
            color='default'
            size='big'
            aria-label='delete'
            onClick={incrementDate}
          >
            <MdKeyboardArrowRight />
          </IconButton>
        </div>
      </div>

      {/* Events content from selected day */}
      <div className='week-calendar__content'>
        {moment(selectedDate).format('dddd, DD [de] MMMM')}
        <div className='week-calendar__content__events'>
          {currentDateEvents.length === 0 && (
            <p className='week-calendar__content__no-events'>
              {t(moment(selectedDate).isSame(moment(), 'day') ? 'back_office.dashboard.week_calendar.no_events_today' : 'back_office.dashboard.week_calendar.no_events')}
            </p>
          )}
          {currentDateEvents.length > 0 && (
            <ol className='week-calendar__content__events-list'>
              {currentDateEvents.map((event, index) => {
                return (
                  <Fragment key={event.startDate + event.title}>
                    <li className='week-calendar__content__event'>
                      <h4 className='week-calendar__content__event-title'>{event.title}</h4>
                      <div className='week-calendar__content__event-time'>
                        <EventDate
                          startDate={moment.unix(event.startDate)}
                          endDate={moment.unix(event.endDate)}
                        />
                      </div>
                    </li>
                    {index < currentDateEvents.length - 1 && (
                      <div className='week-calendar__content__divider'>
                        <Divider component='li' />
                      </div>
                    )}
                  </Fragment>
                )
              })}
            </ol>
          )}
          <LinkButton
            className='week-calendar__action-button'
            variant='outlined'
            href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.CALENDAR)}
          >
            Gerir Calendário
          </LinkButton>
        </div>
      </div>
    </div>
  )
}
