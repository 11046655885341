import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { PackPinLite, PackPinLiteSkeleton } from 'components/PackPinLite'
import { Text } from 'components/Text'

import { fetchFeed as fetchFeedAction } from 'store/feed/actions'

import './styles.scss'

const FILTER_CATEGORIES = [ 'Casamentos', 'Batizados', 'Produto', 'Família', 'Bebé' ]

const getPackPinProps = pinData => {
  return {
    headerImageSrc: pinData.header.image.imgSrc,
    headerImageSize: pinData.header.image.imgSize,
    like: pinData.header.like,
    title: pinData.body.title,
    description: pinData.body.description,
    footerImgSrc: pinData.footer.imgSrc,
    packId: pinData.body.packId,
    userUid: pinData.footer.userUid,
    name: pinData.footer.name,
    rating: pinData.footer.rating,
    price: pinData.pack.packInfo.price,
  }
}

export const LastPacks = () => {
  const [selectedCategory, setSelectedCategory] = useState()
  const lastPacks = useSelector(state => state.feed.data)
  const feedIsLoading = useSelector(state => state.feed.loading)
  const dispatch = useDispatch()

  const fetchFeed = useCallback(
    () => {
      dispatch(fetchFeedAction(selectedCategory))
    },
    [dispatch, selectedCategory]
  )

  useEffect(() => {
    fetchFeed()
  }, [fetchFeed])

  const onCategoryChange = category => {
    if (category === selectedCategory) {
      setSelectedCategory()
    } else {
      setSelectedCategory(category)
    }
  }

  return (
    <div className='last-packs'>
      <div className='last-packs__header'>
        <Text type='title' as='h2' className='last-packs__title'>{'Os últimos packs\ndos nossos fotógrafos'}</Text>
        <div className='last-packs__categories'>
          {FILTER_CATEGORIES.map(category => {
            return (
              <Button
                className='last-packs__categories__category'
                key={category}
                color={category === selectedCategory ? 'primary' : 'neutral'}
                size='medium'
                variant={category === selectedCategory ? 'contained' : 'outlined'}
                onClick={() => onCategoryChange(category)}
              >
                {category}
              </Button>
            )
          })}
        </div>
      </div>
      <div className='last-packs__packs'>
        {lastPacks && !feedIsLoading && lastPacks.map(packPin => <PackPinLite className='last-packs__pack' key={packPin.id} {...getPackPinProps(packPin)} noInfo />)}
        {feedIsLoading && [...Array(4).keys()].map(key => <PackPinLiteSkeleton className='last-packs__pack' key={key} /> )}
      </div>
    </div>
  )
}
