import { Divider } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useLocalActions } from 'scenes/BackOffice/actions/useLocalActions'
import { GalleryCard } from 'scenes/BackOffice/components/GalleryCard'
import { GalleryEdit } from 'scenes/BackOffice/components/GalleryEdit'

import { Breadcrumb } from 'components/Breadcrumb'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { LoadingComponent } from 'components/LoadingComponent/index.js'
import { MenuWrapper } from 'components/MenuWrapper'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'

import { GenericAddButton } from '../../components/GenericAddButton'

import { useModal } from 'hooks/useModal'
import { useRestrictedAction, ACTION_KEYS } from 'hooks/useRestrictedAction'

import { currentLogedInUser, currentUserGalleriesSelector } from 'store/users/selectors'

import { t } from 'services/i18n'
import { changeRoute, createBackOfficeLink, createGalleryLink, subRoutesNames } from 'services/routingService'

import './styles.scss'

const renderGalleries = (galleries, getCardOptions) => {
  return galleries
    .sort((gallery1, gallery2) => gallery2.creationDate - gallery1.creationDate)
    .map((gallery => {
      const coverImageSrc = gallery.coverPhoto?.src || ''
      const imagesCount = gallery.photosLayout?.length
      return (
        <MenuWrapper
          key={gallery.id}
          editMode
          options={getCardOptions(gallery)}
        >
          <GalleryCard
            key={gallery.id}
            id={gallery.id}
            coverImageSrc={coverImageSrc}
            title={gallery.title}
            imagesCount={imagesCount}
            sharingOptions={gallery.sharingOptions}
            galleryEvent={gallery.event}
          />
        </MenuWrapper>
      )
    }))
}

export const Galleries = () => {
  const history = useHistory()
  const logedInUser = useSelector(currentLogedInUser)
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)

  const [isLoadingGalleries, setIsLoadingGalleries] = useState()

  const galleries = useSelector(currentUserGalleriesSelector)

  const [addGalleryModalIsOpen, openAddGalleryModal, closeAddGalleryModal] = useModal()

  const isRestrictedPredicate = useCallback(userPlanIsLowerThanRequired => {
    return !!(userPlanIsLowerThanRequired && Object.keys(galleries).length >= 2)
  }, [galleries])

  const [hasPermission, showUpsell] =
    useRestrictedAction(logedInUser, ACTION_KEYS.GALLERIES, { isRestrictedPredicate })

  const {
    fetchUserGalleries,
    addGallery: addGalleryAction,
    updateGallery: updateGalleryAction,
    setGallerySharingOptions,
    incrementUserGalleryCount,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    if (isLoadingGalleries === undefined) {
      setIsLoadingGalleries(true)
      fetchUserGalleries(null, true)
        .then(() => setIsLoadingGalleries(false))
    }
  }, [fetchUserGalleries, isLoadingGalleries])

  const onCreateGallery = event => {
    if (hasPermission) {
      openAddGalleryModal(event)
    } else {
      showUpsell()
    }
  }

  const createGallery = newGallery => {
    closeAddGalleryModal()
    addGalleryAction(
      {
        ...newGallery,
        userUid: logedInUserId ?? null,
      },
      logedInUser
    )
      .then(() => changeRoute(history, createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES, newGallery.id)))
  }

  const setSharingGallery = (gallery, newGallerySharingValue) => {
    setGallerySharingOptions(gallery.id, {
      sharingOptions: {
        shared: newGallerySharingValue,
      },
    })
  }

  const onShareInProfile = gallery => {
    const isSharedInProfilePrevValue = gallery.isSharedInProfile
    const isSharedInProfileNewValue = !gallery.isSharedInProfile
    // if user shared this gallery in Profile but the gallery is not public, the gallery must be switched to public
    if (isSharedInProfileNewValue && !gallery.sharingOptions?.shared) {
      const confirmRes = window.confirm('Para partilhar a galeria no teu perfil é necessário que ela seja pública. Desejas continuar?')
      if (!confirmRes) {
        return
      } else {
        setSharingGallery(gallery, true)
      }
    }

    const wasAddedToPortefolio = isSharedInProfileNewValue && !isSharedInProfilePrevValue
    const wasRemovedFromPortefolio = !isSharedInProfileNewValue && isSharedInProfilePrevValue
    closeAddGalleryModal()
    return updateGalleryAction(gallery.id, { isSharedInProfile: isSharedInProfileNewValue }, true)
      .then(() => {
        if (wasAddedToPortefolio) {
          return incrementUserGalleryCount(true, true) // add +1 to the user gallery portefolio galleries counter
        }
        if (wasRemovedFromPortefolio) {
          return incrementUserGalleryCount(false, true) // add -1 to the user gallery portefolio galleries counter
        }
        return Promise.resolve()
      })
  }

  const getCardOptions = gallery => {
    return [
      {
        text: 'Ver galeria',
        onClick: () => changeRoute(history, createGalleryLink(gallery.id)),
      },
      {
        text: 'Editar galeria',
        onClick: () => changeRoute(history, createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES, gallery.id)),
      },
      {
        text: gallery.isSharedInProfile
          ? 'Esconder galeria do perfil'
          : 'Partilhar galeria no perfil',
        onClick: () => onShareInProfile(gallery),
      },
    ]
  }

  const galleriesValues = Object.values(galleries)
  const profileGalleries = galleriesValues
    .filter(gallery => gallery.isSharedInProfile === true)
  const hiddenGalleries = galleriesValues
    .filter(gallery => !gallery.isSharedInProfile)

  return (
    <div className='galleries'>
      <Breadcrumb
        items={[
          {
            title: t('back_office.galleries.title'),
          },
        ]}
      />
      <GenericAddButton label='Adicionar Galeria' onClick={onCreateGallery} />
      {Object.keys(galleries).length === 0 && !isLoadingGalleries && (
        <div>
          <p>Ainda não tens nenhuma galeria criada.</p>
          <Button onClick={openAddGalleryModal} variant='outlined' color='primary'>
            Adiciona a tua primeira galeria
          </Button>
        </div>
      )}
      {profileGalleries && profileGalleries.length > 0 && (
        <div>
          <Text as='h3' type='title4' className='galleries__subtitle'>Galerias no teu perfil</Text>
          <Grid spacing={5} xs={6} sm={4} md={3} xl={2}>
            {renderGalleries(profileGalleries, getCardOptions)}
          </Grid>
          <Divider style={{ margin: '24px 0' }} />
        </div>
      )}
      {profileGalleries && profileGalleries.length > 0 &&
        <Text as='h3' type='title4' className='galleries__subtitle'>Galerias privadas</Text>}
      <Grid spacing={5} xs={6} sm={4} md={3} xl={2}>
        {renderGalleries(hiddenGalleries, getCardOptions)}
      </Grid>

      <Modal
        open={addGalleryModalIsOpen}
        closeModal={closeAddGalleryModal}
        title={t('back_office.galleries.gallery_edit_modal.title')}
      >
        <GalleryEdit
          onCancel={closeAddGalleryModal}
          onSaveGallery={createGallery}
          logedInUser={logedInUser}
        />
      </Modal>
      <LoadingComponent
        size={40}
        isLoading={isLoadingGalleries}
      />
    </div>
  )
}
