import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'

import { Button, IconButton } from 'components/Button'

import { useBreakpoints } from 'hooks/useBreakpoints'

import './styles.scss'

export const KeyValueInput = props => {
  const [ keyValues, setKeyValues ] = useState(props.initialKeyValues || [{ key: '', value: '' }])
  const { isMobile } = useBreakpoints()

  useEffect(() => {
    setKeyValues(props.initialKeyValues || [{ key: '', value: '' }])
  }, [props.initialKeyValues])

  const addKeyValue = () => setKeyValues([...keyValues, { key: '', value: '' }])

  const removeKeyValue = keyValueIdx => {
    const newKeyValues = [...keyValues]
    newKeyValues.splice(keyValueIdx, 1)
    setKeyValues(newKeyValues)
    props.onChange && props.onChange(newKeyValues)
  }

  const onKeyValuePropertyEdit = (keyValueIdx, property, value) => {
    const keyValue = { ...keyValues[keyValueIdx] }
    keyValue[property] = value
    const newKeyValues = [...keyValues.slice(0, keyValueIdx), keyValue, ...keyValues.slice(keyValueIdx + 1)]
    setKeyValues(newKeyValues)
    props.onChange && props.onChange(newKeyValues)
  }

  const onKeyEdit = (keyValueIdx, value) => onKeyValuePropertyEdit(keyValueIdx, 'key', value)
  const onValueEdit = (keyValueIdx, value) => onKeyValuePropertyEdit(keyValueIdx, 'value', value)

  return (
    <div className='key-value-input'>
      {keyValues.map((keyValue, keyValueIdx) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={keyValueIdx} className='key-value-input__wrapper'>
            <TextField
              className='key-value-input__key'
              label={props.keyInputLabel}
              onChange={event => onKeyEdit(keyValueIdx, event.target.value)}
              value={keyValue.key}
            />
            <TextField
              className='key-value-input__value'
              label={props.valueInputLabel}
              variant='outlined'
              multiline
              onChange={event => onValueEdit(keyValueIdx, event.target.value)}
              value={keyValue.value}
            />
            <div className='key-value-input__options'>
              {!isMobile
                ? (
                  <Button
                    variant='outlined'
                    color='danger'
                    size='small'
                    startIcon={<DeleteIcon />}
                    onClick={() => removeKeyValue(keyValueIdx)}
                  >
                    Remover
                  </Button>
                )
                : (
                  <IconButton
                    color='danger'
                    size='small'
                    aria-label='delete'
                    onClick={() => removeKeyValue(keyValueIdx)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
            </div>
          </div>
        )
      })}
      <Button
        className='key-value-input__add-key-value'
        variant='outlined'
        color='default'
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={addKeyValue}
      >
        {props.moreButtonLabel}
      </Button>
    </div>
  )
}
