import MUILinearProgress from '@mui/material/LinearProgress'
import React from 'react'

import './styles.scss'

export const LinearProgress = ({ value, label }) => {
  return (
    <div className='linear-progress'>
      <div className='linear-progress__progress'>
        <MUILinearProgress value={value} variant='determinate' />
      </div>
      {value && (
        <div className='linear-progress__label'>
          {label ? label : `${Math.round(value)}%`}
        </div>
      )}
    </div>
  )
}
