import classNames from 'classnames'
import moment from 'moment'
import React from 'react'

import { Text } from 'components/Text'

import { MAX_ITEMS_BY_DAY, EVENT_SHIFTING } from '../../utils/calendarEventsUtils'

import { t } from 'services/i18n'

import './styles.scss'

export const CalendarRow = ({
  dates = [],
  onCalendarDayClick,
  onCalendarDayDoubleClick,
  selectedCurrentDate,
  currentMonthDate,
  onEventClick,
  onEventDoubleClick,
  rowEventsRefs,
}) => {

  const eventClick = calendarEvent => event =>
    onEventClick(event, calendarEvent, rowEventsRefs.current[calendarEvent.id])

  return (
    <tr className='calendar-row'>
      {dates.map(({ date, events }) => {
        const isCurrentMonth = date.clone().startOf('month').diff(currentMonthDate.clone().startOf('month'), 'month') === 0
        const isToday = date.clone().isSame(moment(), 'day')
        const withMonthLabel = date.clone().isSame(date.clone().startOf('month'), 'days') ||
          date.clone().isSame(date.clone().endOf('month'), 'days')
        return (
          <td
            key={date.unix()}
            onClick={onCalendarDayClick(date)}
            onDoubleClick={onCalendarDayDoubleClick(date)}
            className={classNames('calendar-row__day', {
              'calendar-row__day--adjacent-month': !isCurrentMonth,
              'calendar-row__day--selected': selectedCurrentDate && date.isSame(selectedCurrentDate, 'day'),
            })}
          >
            <div className={classNames('calendar-row__day__label', { 'calendar-row__day__label--is-today': isToday })}>
              {withMonthLabel ? date.format(t('back_office.calendar.month.start_end_of_month_date')) : date.format('D')}
            </div>
            {events.map(event => (
              <div
                key={event.id}
                ref={elmtRef => rowEventsRefs.current[event.id] = elmtRef}
                className={classNames('calendar-row__day__event', {
                  'calendar-row__day__event--shifting-right': event.shifting === EVENT_SHIFTING.SHIFTING_RIGHT,
                  'calendar-row__day__event--shifting': event.shifting === EVENT_SHIFTING.SHIFTING_ALL,
                  'calendar-row__day__event--shifting-left': event.shifting === EVENT_SHIFTING.SHIFTING_LEFT,
                  'calendar-row__day__event--second-row': event.rowIndex === 1,
                  'calendar-row__day__event--third-row': event.rowIndex === 2,
                  'calendar-row__day__event--hidden': event.rowIndex > 2,
                })}
                onClick={eventClick(event)}
                onDoubleClick={onEventDoubleClick(event)}
              >
                {(event.shifting === EVENT_SHIFTING.SHIFTING_RIGHT || event.shifting === undefined) && moment.unix(event.startDate).format('H:mm')}
                {(event.shifting === EVENT_SHIFTING.SHIFTING_RIGHT || event.shifting === undefined) && (
                  <Text className='calendar-row__day__event__title' lineLimit={1} type='body2'>{event.title}</Text>
                )}
              </div>
            ))}
            {events.length > MAX_ITEMS_BY_DAY && <div className='calendar-row__day__more-events'><b>...</b></div>}
          </td>
        )
      })}
    </tr>
  )
}
