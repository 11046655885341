import { NOTIFICATION_TYPE } from 'components/GlobalNotifications'

import { createPackChangeListener } from 'services/firebaseServer'
import {
  EVENT_KEYS,
  logEvent,
} from 'services/loggingService'
import { getNotificationSystem } from 'services/notificationSystem'
import {
  getPack,
  getPacks,
  postPack,
  putPack,
  deletePack,
  postReview,
  deleteReview,
} from 'services/serverBridge'

export const REQUEST_PACK = 'REQUEST_PACK'
export const ADD_PACKS = 'ADD_PACKS'
export const PACK_UPLOADED = 'PACK_UPLOADED'
export const PACK_UPLOAD_ERROR = 'PACK_UPLOAD_ERROR'
export const UPLOAD_PACK = 'UPLOAD_PACK'
export const EDIT_PACK = 'EDIT_PACK'
export const REMOVE_PACK = 'REMOVE_PACK'
export const ADD_PACK_REVIEW = 'ADD_PACK_REVIEW'
export const REMOVE_PACK_REVIEW = 'REMOVE_PACK_REVIEW'

function fetchPackAction() {
  return {
    type: REQUEST_PACK,
  }
}

function addPacksAction(pack) {
  return {
    type: ADD_PACKS,
    data: pack,
  }
}

function uploadPackAction(pack) {
  return {
    type: UPLOAD_PACK,
    data: pack,
  }
}

function editPackAction(pack, uploading) {
  return {
    type: EDIT_PACK,
    data: {
      id: pack.id,
      pack,
      uploading,
    },
  }
}

function packUploadedAction(packId) {
  return {
    type: PACK_UPLOADED,
    data: {
      id: packId,
    },
  }
}

function packUploadErrorAction(packId) {
  return {
    type: PACK_UPLOAD_ERROR,
    data: {
      id: packId,
    },
  }
}

function removePackAction(packId) {
  return {
    type: REMOVE_PACK,
    data: packId,
  }
}

function addPackReviewAction(packId, review) {
  return {
    type: ADD_PACK_REVIEW,
    data: {
      id: packId,
      review,
    },
  }
}

function deletePackReviewAction(packId, reviewId) {
  return {
    type: REMOVE_PACK_REVIEW,
    data: {
      id: packId,
      reviewId,
    },
  }
}

export const fetchPack = packId => {
  return dispatch => {
    dispatch(fetchPackAction())
    return getPack(packId)
      .then(pack => dispatch(addPacksAction(pack)))
  }
}

export const fetchPackReviews = packId => {
  return dispatch => {
    // TODO: OPTIMIZE TO JUST FETCH REVIEWS INSTEAD OF FETCHING THE WHOLE PACK
    return getPack(packId)
      .then(pack => dispatch(addPacksAction(pack)))
  }
}

export const fetchPacks = (packIds = [], user) => {
  return (dispatch, getState) => {
    const state = getState()

    if (state.packs.loading) {
      return
    }

    dispatch(fetchPackAction())

    // if by any chance there is already a loaded pack, we don't have to load it
    const packIdsToLoad = packIds
      .filter(packId => !state.packs.data?.[packId])

    return getPacks(packIdsToLoad, user)
      .then(pack => dispatch(addPacksAction(pack)))
  }
}

// action used to create a new pack
export const uploadPack = (pack, visibility, user) => {
  return dispatch => {
    const packData = {
      ...pack,
      user,
    }
    dispatch(uploadPackAction(packData))

    let unsubscribe
    const listenToPackChanges = imageIds => {
      const creatingThumbnailsImageIDs = imageIds
      unsubscribe = createPackChangeListener(pack.id, data => {
        creatingThumbnailsImageIDs.forEach((creatingThumbnailsImageID, index) => {
          const image = data.packInfo?.media?.images.find(image => image.id === creatingThumbnailsImageID)
          const imageThumbs = image?.thumbnails || {}
          if (imageThumbs.s && imageThumbs.m && !imageThumbs.loading) {
            // sync new image src and thumbnails to store, only after every thumbnail is uploaded to the database, so that we don't download the full res image
            const localImageIndex = packData.packInfo.media.images
              .findIndex(image => image.id === creatingThumbnailsImageID)
            packData.packInfo.media.images[localImageIndex] = image
            dispatch(editPackAction(packData, false))

            // remove the ID from the creatingThumbnailsImageIDs array
            creatingThumbnailsImageIDs.splice(index, 1)
          }
        })

        // unsubcribe the listener if all thumbnails of every image were uploaded
        if (creatingThumbnailsImageIDs.length <= 0) {
          unsubscribe()
        }
      })
    }

    return postPack(packData, visibility)
      .then(({ packNewImages }) => {
        listenToPackChanges((packNewImages || []).map(newImage => newImage.id))
        dispatch(packUploadedAction(packData.id))
        logEvent(EVENT_KEYS.CREATE_PACK)
      })
      .catch(() => {
        dispatch(packUploadErrorAction(packData.id))
        const notificationSystem = getNotificationSystem()
        notificationSystem.notify({ message: 'Houve um erro ao criar o teu Pack. Por favor tenta novamente', type: NOTIFICATION_TYPE.ERROR })
        unsubscribe()
      })
  }
}

export const editPack = (pack, newVisibility, user) => {
  return dispatch => {
    const packData = {
      ...pack,
      user,
    }

    let unsubscribe
    const listenToPackChanges = imageIds => {
      const creatingThumbnailsImageIDs = imageIds
      unsubscribe = createPackChangeListener(pack.id, data => {
        creatingThumbnailsImageIDs.forEach((creatingThumbnailsImageID, index) => {
          const image = data.packInfo?.media?.images.find(image => image.id === creatingThumbnailsImageID)
          const imageThumbs = image?.thumbnails || {}
          if (imageThumbs.s && imageThumbs.m && !imageThumbs.loading) {
            // sync new image src and thumbnails to store, only after every thumbnail is uploaded to the database, so that we don't download the full res image
            const localImageIndex = packData.packInfo.media.images
              .findIndex(image => image.id === creatingThumbnailsImageID)
            packData.packInfo.media.images[localImageIndex] = image
            dispatch(editPackAction(packData, false))

            // remove the ID from the creatingThumbnailsImageIDs array
            creatingThumbnailsImageIDs.splice(index, 1)
          }
        })

        // unsubcribe the listener if all thumbnails of every image were uploaded
        if (creatingThumbnailsImageIDs.length <= 0) {
          unsubscribe()
        }
      })
    }

    dispatch(editPackAction(packData, true))
    return putPack(packData, newVisibility)
      .then(({ packNewImages }) => {
        listenToPackChanges((packNewImages || []).map(newImage => newImage.id))
        dispatch(packUploadedAction(packData.id))
      })
      .catch(() => {
        dispatch(packUploadErrorAction(packData.id))
        const notificationSystem = getNotificationSystem()
        notificationSystem.notify({ message: 'Houve um erro ao editar o teu Pack. Por favor tenta novamente', type: NOTIFICATION_TYPE.ERROR })
        unsubscribe()
      })
  }
}

export const removePack = pack => {
  return dispatch => {
    dispatch(removePackAction(pack.id))
    return deletePack(pack)
  }
}

export const postPackReview = (packId, review) => {
  return dispatch => {
    dispatch(addPackReviewAction(packId, review))
    return postReview(packId, review)
  }
}

export const deletePackReview = (packId, review) => {
  return dispatch => {
    dispatch(deletePackReviewAction(packId, review))
    return deleteReview(packId, review)
  }
}
