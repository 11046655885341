
// algorith src: https://imagekit.io/blog/how-to-resize-image-in-javascript/
export const resizeImage = ({ imageFile, imageSrc, maxWidth, maxHeight, fileFormat }) => {
  if (imageFile) {
    return resizeImageFromFile(imageFile, maxWidth, maxHeight, fileFormat)
  } else {
    return resizeImageFromImageSrc(imageSrc, maxWidth, maxHeight, fileFormat)
  }
}

export const resizeImageFromFile = (imageFile, maxWidth, maxHeight) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = e => {
      resolve(resizeImageFromImageSrc(e.target.result, maxWidth, maxHeight))
    }
    reader.readAsDataURL(imageFile)
  })
}

export const resizeImageFromImageSrc = (imageSrc, maxWidth, maxHeight, fileFormat = 'image/webp') => {
  return new Promise(resolve => {
    const img = document.createElement('img')
    img.onload = () => {
      let width = img.width
      let height = img.height

      // Change the resizing logic
      if (width > height) {
        if (width > maxWidth) {
          height = height * (maxWidth / width)
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width = width * (maxHeight / height)
          height = maxHeight
        }
      }

      // Dynamically create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')

      // Actual resizing
      ctx.drawImage(img, 0, 0, width, height)

      // convert image to webp
      canvas.toBlob(blob => {
        resolve({
          blob,
          src: URL.createObjectURL(blob),
        })
      }, fileFormat)
    }

    img.src = imageSrc
  })
}

const convertTo = (file, fileFormat) => {
  // Load the data into an image
  return new Promise(resolve => {
    const rawImage = new Image()

    rawImage.addEventListener('load', () => {
      resolve(rawImage)
    })

    rawImage.src = URL.createObjectURL(file)
  })
  .then(rawImage => {
    // Convert image to webp ObjectURL via a canvas blob
    return new Promise(resolve => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = rawImage.width
      canvas.height = rawImage.height
      ctx.drawImage(rawImage, 0, 0)

      canvas.toBlob(blob => {
        resolve({
          blob,
          src: URL.createObjectURL(blob),
        })
      }, fileFormat)
    })
  })
}

export const convertToWebP = file => {
  return convertTo(file, 'image/webp')
}

export const convertToPng = file => {
  return convertTo(file, 'image/png')
}

export const getImagesTotalSize = images => {
  return images.reduce((prev, currImage) => {
    const imageSize = currImage.size || currImage.file?.size || 0
    return prev + imageSize
  }, 0)
}

export const downloadImage = (src, fileName, callback) => {
  const xhr = new XMLHttpRequest()
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const blob = xhr.response
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName || 'image.jpg'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    if (callback) {
      callback()
    }
  }
  xhr.open('GET', src)
  xhr.send()
}
