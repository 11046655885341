import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import Joyride from 'react-joyride'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { Button, LinkButton } from 'components/Button'
import { LoadingWrapper } from 'components/LoadingComponent'
import { Modal } from 'components/Modal'
import { PackInput } from 'components/PackInput'

import { CardsLayout } from './components/CardsLayout'
import { PortfolioInput } from './components/PortfolioInput'
import { TabsList } from './components/TabsList'
import { UserCard } from './components/UserCard'
import { UserInfo } from './components/UserInfo'

import { useUserDetailsSelector } from './selectors/useUserDetailsSelector.js'

import { useLocalActions } from './actions/useLocalActions.js'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { UPLOAD_STATUS } from 'model/UploadStatus'
import { USER_TYPE } from 'model/User'

import { UserNotFoundError, PageNotFoundError } from 'services/errorHandling'
import { t } from 'services/i18n'
import { createBackOfficeLink, createHomeLink, subRoutesNames } from 'services/routingService'
import { createUserNameLink } from 'services/routingService/linkFactory'

import { UserDetailsSkeleton } from './index.skeleton'

import cssVariables from 'styles/theme/colors.scss'
import './styles.scss'
import { useUserDetailsWalkthrough, WALKTHROUGH_KEY } from 'scenes/UserDetails/hooks/useUserDetailsWalkthrough'
import { ErrorBoundary } from 'components/ErrorBoundary'
import classNames from 'classnames'

// caution: thise IDS are in sync with the URL query parameters
export const TAB_IDS = {
  PORTFOLIO: 'portfolio',
  PACKS: 'packs',
  GALLERIES: 'galleries',
}

const TABS = {
  [TAB_IDS.PORTFOLIO]: {
    name: 'Portefólio',
    id: TAB_IDS.PORTFOLIO,
  },
  [TAB_IDS.PACKS]: {
    name: 'Packs',
    id: TAB_IDS.PACKS,
    isHidden: (user, isOwnerUser) => !user.packs?.cards?.length && !isOwnerUser,
  },
  [TAB_IDS.GALLERIES]: {
    name: 'Galerias',
    id: TAB_IDS.GALLERIES,
    isHidden: (user, isOwnerUser) => !user.galleries?.portefolioGalleryCount && !isOwnerUser,
  },
}

const UserDetails = ({ userId }) => {
  const { tabId = TAB_IDS.PORTFOLIO } = useParams()
  const [ addToStart, setAddToStart ] = useState(false)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ uploadingAvatar, setUploadingAvatar ] = useState(UPLOAD_STATUS.NOT_UPLOADED)
  const [ uploadingUserInfo, setUploadingUserInfo ] = useState(UPLOAD_STATUS.NOT_UPLOADED)
  const [ imagesUploadProgress, setImagesUploadProgress ] = useState({})
  const { isDesktopAndUp } = useBreakpoints()
  const tabsRef = useRef()
  const {
    user, isOwnerUser, userPorfolio, userPacks, userPacksLoading, userGalleries,
  } = useUserDetailsSelector({ userId, breakpoint: isDesktopAndUp ? 'desktop' : 'mobile' })
  const {
    uploadPack, addUserPack, addUserPortfolioCards, editUserAvatar, editUserInfo,
  } = useLocalActions({ user, userId })
  const history = useHistory()
  const walkthroughData = user.walkthroughData?.[WALKTHROUGH_KEY] || {}

  const visibleTabs = useMemo(() => {
    return Object.values(TABS).filter(tab => !tab.isHidden?.(user, isOwnerUser))
  }, [user, isOwnerUser])

  const changeTab = useCallback(tabId => {
    history.push(createUserNameLink(user.username, tabId === TAB_IDS.PORTFOLIO ? undefined : tabId)) // ignore portfolio tab to maintain the URL short
  }, [history, user.username])

  const onSelectTabId = newId => {
    changeTab(newId)
    if (window.scrollY > 300) {
      tabsRef.current.scrollIntoView(true)
    }
  }

  const {
    walkthroughStepIndex,
    walkthroughIsRunning,
    walkthroughIsContinuous,
    handleJoyrideCallback,
    walkthroughSteps,
  } = useUserDetailsWalkthrough(walkthroughData, user, onSelectTabId, isOwnerUser)

  // reset selected tab after user is changed
  useEffect(() => {
    if (user && userId !== user.uid) {
      changeTab(TAB_IDS.PORTFOLIO)
    }
  }, [user, userId, changeTab])

  const onSavePack = (newPack, visible) => {
    closeModal()

    addUserPack(newPack.id, addToStart, visible, false) // set the pack in the UI before adding it to server. Only do this after pack is fully uploaded

    uploadPack(newPack, visible) // Add the pack to packs collection and to the user doc in a single transaction
  }

  const onProgressChange = (image, progress) => {
    setImagesUploadProgress({
      ...imagesUploadProgress,
      [image.id]: Math.floor(progress),
    })
  }

  const onSavePortfolio = images =>
    addUserPortfolioCards(images, addToStart, onProgressChange)

  const onSaveUserAvatar = avatarFile => {
    setUploadingAvatar(UPLOAD_STATUS.UPLOADING)
    // This component should not be aware of the parent functions. However this is better for performance
    editUserAvatar(avatarFile)
      .then(() => setUploadingAvatar(UPLOAD_STATUS.UPLOADED))
  }

  const avatarAnimationIsDone = () => setUploadingAvatar(UPLOAD_STATUS.NOT_UPLOADED)

  const onSaveUserInfo = info => {
    setUploadingUserInfo(UPLOAD_STATUS.UPLOADING)
    // This component should not be aware of the parent functions. However this is better for performance
    editUserInfo(info)
      .then(() => setUploadingUserInfo(UPLOAD_STATUS.UPLOADED))
  }

  const userInfoAnimationIsDone = () => setUploadingUserInfo(UPLOAD_STATUS.NOT_UPLOADED)

  const openModal = (addToStart = false) => {
    setAddToStart(addToStart === true)
    setIsModalOpen(true)
  }

  const closeModal = () => setIsModalOpen(false)

  const getCards = () => {
    if (tabId === TAB_IDS.PORTFOLIO) {
      return userPorfolio
    } else if (tabId === TAB_IDS.PACKS) {
      return userPacks
    } else if (tabId === TAB_IDS.GALLERIES) {
      return userGalleries
    }
  }

  const addToTheTop = () => openModal(false) // always add to the bottom because there's a bug when saving the added top images to the database (they're saved as bottom images)

  return (
    <article>
      <ErrorBoundary silenceError>
        <Joyride
          disableScrolling
          showProgress
          steps={walkthroughSteps}
          continuous={walkthroughIsContinuous}
          locale={{
            back: 'Anterior', close: 'Fechar', last: 'Fim', next: 'Próximo', open: 'Abrir', skip: 'Ignorar',
          }}
          styles={{
            options: {
              primaryColor: cssVariables.primaryColor,
              width: 480,
              zIndex: 1000,
            },
          }}
          callback={handleJoyrideCallback}
          run={walkthroughIsRunning}
          stepIndex={walkthroughStepIndex}
          showSkipButton
        />
      </ErrorBoundary>
      <header className='user-details__header'>
        <UserInfo
          user={{
            name: user.name,
            rating: user.rating,
            avatar: user.avatar,
            type: user.type,
            services: user.services,
          }}
          location={user.location}
          externalLinks={user.externalLinks}
          description={user.description}
          categories={user.categories}
          isOwnerUser={isOwnerUser}
          onSaveUserInfo={onSaveUserInfo}
          onSaveUserAvatar={onSaveUserAvatar}
          avatarAnimationIsDone={avatarAnimationIsDone}
          userInfoAnimationIsDone={userInfoAnimationIsDone}
          uploadingAvatar={uploadingAvatar}
          uploadingUserInfo={uploadingUserInfo}
        />
      </header>
      {user.type === USER_TYPE.PROFESSIONAL && (
        <div ref={tabsRef} className='user-details__tabs-wrapper'>
          {isOwnerUser && (
            <div className='user-details__tabs-options'>
              {(tabId === TAB_IDS.PORTFOLIO || tabId === TAB_IDS.PACKS) && (
                <Button
                  className='user-details__tabs-options__item'
                  onClick={addToTheTop}
                  variant='outlined'
                  color='primary'
                  size='small'
                >
                  {tabId === TAB_IDS.PORTFOLIO ? t('user_details.content.add_to_top_portfolio') : t('user_details.content.add_to_top_packs')}
                </Button>
              )}
              <LinkButton
                wrapperClassName='user-details__tabs-options__item'
                href={tabId === TAB_IDS.PORTFOLIO
                  ? createBackOfficeLink(subRoutesNames.BACK_OFFICE.LAYOUT)
                  : tabId === TAB_IDS.PACKS
                    ? createBackOfficeLink(subRoutesNames.BACK_OFFICE.PACKS)
                    : createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES)}
                variant='outlined'
                color='primary'
                size='small'
              >
                {tabId === TAB_IDS.PORTFOLIO ? t('user_details.content.edit_portfolio') : tabId === TAB_IDS.PACKS ? t('user_details.content.edit_packs') : t('user_details.content.edit_galleries')}
              </LinkButton>
            </div>
          )}
          <div className='user-details__tabs'>
            <aside className='user-details__side-menu'>
              <TabsList
                selectedTabId={tabId}
                tabs={visibleTabs}
                onSelectTabId={onSelectTabId}
              />
            </aside>
            <CardsLayout
              openModal={openModal}
              isOwnerUser={isOwnerUser}
              tabKey={tabId}
              itemsWidthInColumns={(getCards() || []).map(card => card.colsWidth || 1)}
              layoutID={`layout-${tabId}`}
              addCardHref={tabId === TAB_IDS.GALLERIES
                ? createBackOfficeLink(subRoutesNames.BACK_OFFICE.GALLERIES)
                : null}
            >
              {(getCards() || []).map((card, idx) => {
                return (
                  <UserCard
                    key={card.id}
                    card={card}
                    cardIdx={idx}
                    tabId={tabId}
                    progressValue={imagesUploadProgress[card.id]}
                  />
                )
              })}
            </CardsLayout>
          </div>
        </div>
      )}
      <Modal
        open={isModalOpen}
        closeModal={closeModal}
        title={tabId === TAB_IDS.PORTFOLIO ? t('user_details.portfolio_input.title') : t('user_details.pack_input.title')}
        className={classNames({
          'user-details__packs-input-modal': tabId === TAB_IDS.PACKS,
        })}
        headerClassname={classNames({
          'user-details__packs-input-modal__header': tabId === TAB_IDS.PACKS,
        })}
      >
        {tabId === TAB_IDS.PORTFOLIO && (
          <PortfolioInput
            userPacksLoading={userPacksLoading}
            userPacks={userPacks}
            closeModal={closeModal}
            savePortfolioImages={onSavePortfolio}
          />
        )}
        {tabId === TAB_IDS.PACKS && (
          <PackInput
            user={user}
            savePack={onSavePack}
            onCancel={closeModal}
          />
        )}
      </Modal>
    </article>
  )
}
/**
 * This loader will also handle the user routes USER & USER_DETAILS
 * Whenever possible, USER route with username will be used instead of USER_DETAILS route, which only requires the user uid
 * Since in most parts of the app (because of historic decisions) only the id is available, it is necessary to maintain the support of USER_DETAILS route
 */
const UserDetailsLoader = () => {
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const { id: paramUserId, username: paramUsername, tabId = TAB_IDS.PORTFOLIO } = useParams()
  const [ userNotFound, setUserNotFound ] = useState(false)
  const [ userPacksHasLoaded, setUserPacksHasLoaded ] = useState(false)
  const history = useHistory()

  const userId = paramUsername ? null : paramUserId ?? logedInUserId // if username is set, we should not fallback to current user
  const { loading, user, isOwnerUser } = useUserDetailsSelector({ userId, username: paramUsername })

  const { fetchUser, fetchUserByUsername, fetchUserPacks, fetchUserGalleries } =
    useLocalActions({ userId: userId || user?.uid, username: paramUsername, user })

  useEffect(() => {
    if (!user && paramUsername) {
      fetchUserByUsername()
        .catch(_err => {
          setUserNotFound(true)
        })
    } else if (!user && userId) {
      fetchUser()
        .catch(_err => {
          setUserNotFound(true)
        })
    }
  }, [fetchUser, fetchUserByUsername, setUserNotFound, user, userId, paramUsername])

  // load necessary data related to selected tab
  useEffect(() => {
    if (loading) { // only fetch data after initial user data is loaded
      return
    }

    if (tabId === TAB_IDS.PACKS && !userPacksHasLoaded) {
      // const visibleTabIds = TAB_IDS.filter(tab => !tab.isHidden?.(user, isOwnerUser))
      // if page is open with packs URL but use has no packs, we should redirect to portfolio
      if (TABS[TAB_IDS.PACKS].isHidden(user, isOwnerUser)) {
        history.push(createUserNameLink(user.username, undefined)) // ignore portfolio tab to maintain the URL short
        return
      }
      setUserPacksHasLoaded(true)
      fetchUserPacks()
    }

    // TODO: This is always fetching user galleries. Improve this
    if (tabId === TAB_IDS.GALLERIES) {
      fetchUserGalleries({
        onlyPublicGalleries: !isOwnerUser,
      }, true)
    }
  }, [fetchUserGalleries, fetchUserPacks, isOwnerUser, tabId, userPacksHasLoaded, loading, user, history])

  // reset state after user id is changed
  useEffect(() => {
    if (user && userId !== user.uid) {
      setUserPacksHasLoaded(false)
    }
  }, [user, userId])

  if (!userId && !paramUsername) {
    history.replace(createHomeLink())
  }

  // use USER route instead of USER_DETAILS when possible
  if (user && user.username && userId && !paramUsername) {
    window.history.replaceState(null, `Plotu - ${user.name}`, createUserNameLink(user.username))
  }

  if (userNotFound && paramUsername) {
    throw new PageNotFoundError()
  }

  if (userNotFound) {
    throw new UserNotFoundError('User not found')
  }

  return (
    <LoadingWrapper
      skeleton={<UserDetailsSkeleton />}
      isLoading={loading || !user}
    >
      <UserDetails userId={user?.uid} />
    </LoadingWrapper>
  )
}

export default UserDetailsLoader
