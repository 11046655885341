import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'
import TodayIcon from '@mui/icons-material/Today'
import classNames from 'classnames'
import moment from 'moment'
import React, { useMemo, useState, useEffect } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { EventDate } from 'scenes/BackOffice/components/EventDate'

import { Breadcrumb } from 'components/Breadcrumb/index.js'
import { Button, LinkButton } from 'components/Button'
import { IconLabel } from 'components/IconLabel/index.js'
import { Icon } from 'components/Icon/index.js'
import { Link } from 'components/Link'
import { LoadingComponent } from 'components/LoadingComponent/index.js'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import UserCircular from 'components/UserCircular'

import { useLocalActions } from '../../actions/useLocalActions.js'
import { EventEdit } from '../../components/EventEdit'
import { GenericAddButtonList } from '../../components/GenericAddButton/index.js'

import { allPacksAreLoaded } from 'store/packs/selectors'
import { currentUserPacksSelector } from 'store/users/selectors'

import { t } from 'services/i18n'
import { subRoutesNames, createBackOfficeLink, createPackLink } from 'services/routingService'

import { getEventsByCategory } from './utils/eventsUtils'

import './styles.scss'

const renderEvents = (title, events, userPacks) => {
  return (
    <Fragment>
      <Text as='h1' type='title4' className='events__section-title'>{title}</Text>
      {/* Using custom events__list instead of Grid so we can maintain the same height in every event box */}
      <div className='events__list'>
        {events.map(event => {
          const startDate = moment.unix(event.startDate)
          const endDate = moment.unix(event.endDate)
          const eventMonth = startDate.month()
          const eventYear = startDate.year()
          const eventPack = userPacks?.find(pack => pack.id === event.packId)

          return (
            <Link
              href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, event.id)}
              className={classNames('events__event', {
                'events__event--deleting': event.deleting,
              })}
              key={event.id}
            >
              <div className='events__relative-date'>
                <TodayIcon fontSize='small' />
                <Text type='body2'>{endDate.fromNow()}</Text>
              </div>
              {event.user?.name
                ? (
                  <UserCircular
                    className='events__user'
                    withFallback
                    name={event.user.name}
                    size={28}
                  />
                )
                : <div className='events__user events__user--empty'>[Sem cliente]</div>}

              <EventDate
                startDate={startDate}
                endDate={endDate}
              />

              {eventPack && (
                <Link underlined href={createPackLink(eventPack.id)} className='events__pack'>
                  <IconLabel
                    className='events__pack__link'
                    icon={AppsOutlinedIcon}
                    label={eventPack.packInfo.title}
                  />
                </Link>
              )}

              <Text as='h2' type='title4' lineLimit={2} className='events__event-title'>{event.title}</Text>

              <div className='events__actions'>
                <LinkButton
                  disabled={event.deleting}
                  variant='text'
                  href={createBackOfficeLink(
                    subRoutesNames.BACK_OFFICE.CALENDAR,
                    null,
                    false,
                    null,
                    { m: eventMonth, y: eventYear, event: event.id }
                  )}
                  startIcon={<Icon>date_range</Icon>}
                >
                  Calendário
                </LinkButton>
                <LinkButton
                  disabled={event.deleting}
                  variant='outlined'
                  href={createBackOfficeLink(subRoutesNames.BACK_OFFICE.EVENTS, event.id)}
                >
                  Detalhes
                </LinkButton>
              </div>
              <LoadingComponent
                className='events__deleting-icon'
                size={32}
                isLoading={event.deleting}
              />
            </Link>
          )
        })}
      </div>
    </Fragment>
  )
}

const sortByDate = direction => () => {
  if (direction === 'asc') {
    return (event1, event2) => event1.startDate - event2.startDate
  } else {
    return (event1, event2) => event2.startDate - event1.startDate
  }
}

export const Events = () => {
  const history = useHistory()
  const logedInUserId = useSelector(state => state.users.currentUser?.uid)
  const logedInUser = useSelector(
    state => logedInUserId !== undefined && state.users.users[state.users.currentUser.uid]
  )
  const [ eventModalIsOpen, setEventModalIsOpen ] = useState(false)

  const events = useSelector(
    state => (state.agendas.recentEvents.data || [])
      .map(recentEventKey => state.agendas.events.data[recentEventKey] || {})
  )
  const loading = useSelector(state => state.agendas.recentEvents.loading ?? true)

  const userPacksIds = logedInUser.packsIds
  const userPacksAreLoaded = useSelector(allPacksAreLoaded(userPacksIds))
  const userPacksAreLoading = useSelector(state => state.packs?.loading)
  const userPacks = useSelector(currentUserPacksSelector)

  const {
    addAgendaEvent,
    fetchUserPacks: fetchUserPacksAction,
    fetchUserRecentAgenda,
  } = useLocalActions(logedInUser)

  useEffect(() => {
    // logedInUserId as a dependecy to perform only one request
    const nowDateUnix = moment().unix()
    const limit = 10
    const pastRecentAgendaOptions = { endDateUnix: nowDateUnix, limit, orderBy: 'startDate', orderByDir: 'desc' }
    const nextRecentAgendaOptions = { startDateUnix: nowDateUnix, limit }
    logedInUserId && fetchUserRecentAgenda(pastRecentAgendaOptions)
    logedInUserId && fetchUserRecentAgenda(nextRecentAgendaOptions)
  }, [fetchUserRecentAgenda, logedInUserId])

  useEffect(() => {
    if (!userPacksAreLoaded && !userPacksAreLoading) {
      fetchUserPacksAction()
    }
  }, [fetchUserPacksAction, userPacksAreLoaded, userPacksAreLoading])

  const openEventModal = () => setEventModalIsOpen(true)
  const closeEventModal = () => setEventModalIsOpen(false)

  const eventsByCategory = useMemo(
    () => getEventsByCategory(events),
    [events]
  )

  const onSaveAppointment = newEvent => {
    closeEventModal()
    addAgendaEvent({
      ...newEvent,
      userUid: logedInUserId ?? null,
    })
  }

  return (
    <div className='events'>
      <Breadcrumb
        items={[
          {
            title: t('back_office.events.title'),
          },
        ]}
      />
      {Object.keys(events).length === 0 && !loading && (
        <div>
          <p>Ainda não tens nenhum evento marcado.</p>
          <Button onClick={openEventModal} variant='outlined' color='primary'>
            Adiciona o teu primeiro evento
          </Button>
        </div>
      )}
      <GenericAddButtonList
        list={[
          {
            key: 'calendar-cta',
            label: 'Calendário',
            startIcon: true,
            iconId: 'date_range',
            onClick: () => history.push(createBackOfficeLink(subRoutesNames.BACK_OFFICE.CALENDAR)),
          },
          {
            key: 'add-event-cta',
            label: 'Adicionar Evento',
            onClick: openEventModal,
            isDisabled: !Object.keys(events) || loading,
          },
        ]}
      />
      {eventsByCategory.onGoing && renderEvents('A decorrer', eventsByCategory.onGoing.sort(sortByDate('asc')), userPacks)}
      {eventsByCategory.coming && renderEvents('Próximos eventos', eventsByCategory.coming.sort(sortByDate('asc')), userPacks)}
      {eventsByCategory.past && renderEvents('Ultimos Eventos Completos', eventsByCategory.past.sort(sortByDate('desc')), userPacks)}
      <Modal
        open={eventModalIsOpen}
        closeModal={closeEventModal}
        title={t('back_office.calendar.appointment.title')}
      >
        <EventEdit
          onCancel={closeEventModal}
          onSaveAppointment={onSaveAppointment}
          userPacks={userPacks}
          userPacksLoading={userPacksAreLoading}
        />
      </Modal>
      <LoadingComponent
        size={40}
        isLoading={loading}
      />
    </div>
  )
}
