import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ReviewSection } from '../../ReviewSection'

export const ConnectedReviewSection = props => {
  const { id: packId } = useParams()
  const hasUserLogedIn = useSelector(state => state.users.currentUser)
  const logedInUser = useSelector(state => hasUserLogedIn && state.users.users[state.users.currentUser.uid])

  const currentPack = useSelector(state => state.packs.data[packId])

  const canAddReview = hasUserLogedIn && logedInUser?.uid !== currentPack.userUid // TODO: Check if user has ordered this product before
  const isAdmin = logedInUser?.uid === currentPack.userUid

  return (
    <ReviewSection
      {...props}
      currentPack={currentPack}
      currentUser={logedInUser}
      canAddReview={canAddReview}
      isAdmin={isAdmin}
    />
  )
}
