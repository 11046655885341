import { Pack } from 'model/Pack'
import { UPLOAD_STATUS } from 'model/UploadStatus'

import {
  REQUEST_PACK,
  ADD_PACKS,
  UPLOAD_PACK,
  PACK_UPLOADED,
  PACK_UPLOAD_ERROR,
  EDIT_PACK,
  REMOVE_PACK,
  ADD_PACK_REVIEW,
  REMOVE_PACK_REVIEW,
} from './actions'

const initialState = {
  data: undefined,
  loading: undefined,
}

// TODO: Limit the number of packs in the store

const packs = (state = initialState, action) => {
  const currPacks = state.data || []
  const packsCopy = { ...currPacks }
  const contextPack = packsCopy[action.data?.id]
  switch (action.type) {
    case REQUEST_PACK:
      return {
        ...state,
        loading: true,
      }
    case ADD_PACKS: {
      const packs = Array.isArray(action.data)
        ? action.data
        : [action.data]

      const data = packs.reduce((prev, currPack) => {
        const pack = new Pack(currPack)
        prev[pack.id] = pack
        return prev
      }, { ...currPacks })

      return {
        ...state,
        data,
        loading: false,
      }
    }
    case UPLOAD_PACK: {
      const newPack = new Pack({ ...action.data, uploadStatus: UPLOAD_STATUS.UPLOADING })

      return {
        ...state,
        data: {
          ...currPacks,
          [newPack.id]: newPack,
        },
        loading: false,
      }
    }
    case EDIT_PACK: {
      const newPackCopy = { ...action.data.pack }
      // first remove undefineds so that they won't override contextPack useful data
      Object.keys(newPackCopy)
        .forEach(key => newPackCopy[key] === undefined && delete newPackCopy[key])

      const newEditedPack = new Pack({
        ...(contextPack || {}),
        ...newPackCopy,
      })

      if (action.data.uploading) {
        newEditedPack.uploadStatus = UPLOAD_STATUS.UPLOADING
      }

      return {
        ...state,
        data: {
          ...currPacks,
          [newPackCopy.id]: newEditedPack,
        },
        loading: false,
      }
    }
    case PACK_UPLOADED:
      contextPack.uploadStatus = UPLOAD_STATUS.UPLOADED

      return {
        ...state,
        data: {
          ...currPacks,
          [contextPack.id]: contextPack,
        },
        loading: false,
      }
    case PACK_UPLOAD_ERROR:
      contextPack.uploadStatus = UPLOAD_STATUS.ERROR

      return {
        ...state,
        data: {
          ...currPacks,
          [contextPack.id]: contextPack,
        },
        loading: false,
      }
    case REMOVE_PACK:
      delete currPacks[action.data.id]

      return {
        ...state,
        data: { ...currPacks },
        loading: false,
      }
    case ADD_PACK_REVIEW: {
      const packWithNewReview = new Pack({
        ...contextPack,
        reviews: {
          [action.data.review.id]: action.data.review,
          ...(contextPack.reviews || {}),
        },
      })

      return {
        ...state,
        data: {
          ...currPacks,
          [contextPack.id]: packWithNewReview,
        },
        loading: false,
      }
    }
    case REMOVE_PACK_REVIEW: {
      const packReviewsCopy = { ...contextPack.reviews }

      delete packReviewsCopy[action.data.reviewId]

      const packWithoutReview = new Pack({
        ...contextPack,
        reviews: packReviewsCopy,
      })

      return {
        ...state,
        data: {
          ...currPacks,
          [contextPack.id]: packWithoutReview,
        },
        loading: false,
      } }
    default:
      return state
  }
}

export default packs
