import { useState, useEffect } from 'react'

import { getPageFromId } from 'services/staticPagesBuilder'

import { findPageById } from 'utils/dynamicStaticPages'

export const useDynamicStaticPage = pageId => {
  const [pageHTML, setPageHTML] = useState({ __html: '' })

  const staticPagePath = findPageById(pageId)?.pagePath

  useEffect(() => {
    getPageFromId(pageId)
      .then(html => {
        const parsedHtml = { __html: html }
        setPageHTML(parsedHtml)
      })
  }, [pageId, staticPagePath])

  return [pageHTML]
}
