import LinkIcon from '@mui/icons-material/Link'
import React, { useRef } from 'react'

import { IconButton } from 'components/Button'
import { TextField } from 'components/TextField'

import './styles.scss'

export const ShareLink = ({ linkToShare }) => {
  const shareLinkTextFieldRef = useRef()

  const copyLink = () => {
    if (!shareLinkTextFieldRef.current) {
      return
    }
    shareLinkTextFieldRef.current.select()
    shareLinkTextFieldRef.current.setSelectionRange(0, 99999) // For mobile devices

    // Copy the text inside the text field
    document.execCommand('copy')
  }

  return (
    <div className='share-link__share-wrapper'>
      <IconButton
        onClick={copyLink}
        className='share-link__share-button'
        color='default'
        size='big'
        aria-label='Copiar Link'
        bottomText='Copiar Link'
      >
        <LinkIcon />
      </IconButton>
      <TextField
        id='share-url-input'
        className='share-link__share__url-text'
        variant='outlined'
        value={linkToShare}
        size='small'
        inputRef={shareLinkTextFieldRef}
      />
    </div>
  )
}
