import React from 'react'

import { useBreakpoints } from 'hooks/useBreakpoints'

import './styles.scss'

export const HomeVideo = () => {
  const { isTabletAndUp } = useBreakpoints()

  const setVideoLoop = e => {
    const videoElmt = e.target
    setTimeout(() => {
      videoElmt.play()
    }, 5000)
  }

  return (
    <video className='home-video' autoPlay='autoplay' preload='auto' muted onEnded={setVideoLoop} playsInline>
      {!isTabletAndUp && <source src='https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhomepage_v2%2Fplotu_homepage_video_galerias_mobile.mp4?alt=media&token=5a4d4cdf-59c6-46fc-8c74-a449a32f27d8' type='video/mp4' />}
      {isTabletAndUp && <source src='https://firebasestorage.googleapis.com/v0/b/plotu-prod.appspot.com/o/assets%2Fhome%2Fhomepage_v2%2Fplotu_homepage_video_galerias.mp4?alt=media&token=944c478c-d4ab-4a9c-80ca-f161b1a68839' type='video/mp4' />}
      Your browser does not support the video tag.
    </video>
  )
}
