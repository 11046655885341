import React from 'react'

import { GalleryCard } from 'scenes/BackOffice/components/GalleryCard'

import { Grid } from 'components/Grid'
import { LoadingComponent } from 'components/LoadingComponent'
import { ModalSection } from 'components/Modal'

import { t } from 'services/i18n'

export const AddExistingGalleryModal = ({ isLoading, galleries, associateExistingGallery }) => {

  const handleGalleryCardClick = galleryId => () => {
    associateExistingGallery(galleryId)
  }

  if (isLoading) {
    return (
      <LoadingComponent
        isLoading
        size={20}
      />
    )
  }

  return (
    <ModalSection
      title={t('back_office.event.add_existing_gallery.galleries_section.title')}
      subTitle={t('back_office.event.add_existing_gallery.galleries_section.sub-title')}
      noMargin
    >
      <Grid spacing={5} xs={4} sm={3} md={3} xl={2}>
        {galleries
          .sort((gallery1, gallery2) => gallery2.creationDate - gallery1.creationDate)
          .map((gallery => {
            const defaultCoverPhotoId = (gallery.photosLayout || [])[0]
            const coverPhotosrc = gallery.coverPhoto?.src || (gallery.photos || {})[defaultCoverPhotoId]?.src
            return (
              <GalleryCard
                key={gallery.id}
                id={gallery.id}
                coverImageSrc={coverPhotosrc}
                title={gallery.title}
                imagesCount={(gallery.photosLayout || []).length}
                sharingOptions={gallery.sharingOptions}
                srcContext='event-page'
                onClick={handleGalleryCardClick(gallery.id)}
              />
            )
          }))}
      </Grid>
    </ModalSection>
  )
}
