import { Divider, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import React, { useState, useEffect } from 'react'

import { HelpTooltip } from 'components/HelpTooltip'
import { LoadingComponent } from 'components/LoadingComponent'
import { ShareLink } from 'components/ShareLink'
import { Text } from 'components/Text'

import { t } from 'services/i18n'
import { createGalleryLink } from 'services/routingService'

import './styles.scss'

export const SharePopover = props => {
  const [sharingGalleryUploading, setSharingGalleryUploading] = useState(false)
  const [sharingGallery, setSharingGallery] = useState(props.gallery.sharingOptions?.shared || false)
  const [isSharedInProfile, setIsSharedInProfile] = useState(props.gallery.isSharedInProfile || false)
  const [savingGalleryUploading, setSavingGalleryUploading] = useState(false)

  const saveSharingInProfile = newValue => {
    setSavingGalleryUploading(true)
    props.onSaveGallery({
      ...props.gallery,
      id: props.gallery.id,
      isSharedInProfile: newValue,
    })
    .then(() => props.incrementUserGalleryCount(newValue, true))
    .finally(() => setSavingGalleryUploading(false))
  }

  const onIsSharedInProfileChange = event => {
    const newIsSharedInProfileValue = event.target.checked
    setIsSharedInProfile(newIsSharedInProfileValue)
    saveSharingInProfile(newIsSharedInProfileValue)
  }

  useEffect(() => {
    setSharingGallery(props.gallery.sharingOptions?.shared || false)
    setIsSharedInProfile(props.gallery.isSharedInProfile || false)
  }, [props.gallery])

  const toggleSharingGallery = () => {
    setSharingGalleryUploading(true)
    const newSharingGalleryValue = !sharingGallery

    if (newSharingGalleryValue === false && isSharedInProfile) {
      setIsSharedInProfile(false)
      saveSharingInProfile(false)
    }

    setSharingGallery(newSharingGalleryValue)
    props.setSharingGallery(newSharingGalleryValue)

    setTimeout(() => {
      setSharingGalleryUploading(false)
    }, 1000)
  }

  return (
    <Popover
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      transition
      onClose={props.closeShareGalleryModal}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper>
        <div className='share-popover'>
          <Text as='h3' type='title4' className='share-popover__title'>Partilhar</Text>
          <div className='share-popover__share-checkbox'>
            <FormControlLabel
              control={(
                <Checkbox
                  name='checkbox partilhar galeria'
                  checked={sharingGallery}
                  onChange={toggleSharingGallery}
                  color='primary'
                />
              )}
              label='Partilhar galeria'
            />
            <LoadingComponent
              size={20}
              isLoading={sharingGalleryUploading}
            />
          </div>
          {sharingGallery && !sharingGalleryUploading && (
            <ShareLink
              linkToShare={createGalleryLink(props.gallery.id, undefined, false, undefined, true)}
            />
          )}
          <div className='share-popover__divider'>
            <Divider light />
          </div>
          <Text as='h3' type='subtitle'>
            {t('back_office.components.gallery_edit.portefolio-share-title')}
          </Text>
          {props.gallery && (
            <div className='share-popover__primary-field'>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!sharingGallery}
                      checked={isSharedInProfile}
                      onChange={onIsSharedInProfileChange}
                    />
                  )}
                  label={t('back_office.gallery.edit_modal.is_shared_in_profile_label')}
                />
              </FormGroup>
              <HelpTooltip>
                <React.Fragment>
                  <h2 className='share-popover__help__title'>{t('back_office.gallery.edit_modal.is_shared_in_profile_help.title')}</h2>
                  <p>{t('back_office.gallery.edit_modal.is_shared_in_profile_help.description')}</p>
                </React.Fragment>
              </HelpTooltip>
              <LoadingComponent
                className='share-popover__uploading-gallery'
                size={20}
                isLoading={savingGalleryUploading}
              />
            </div>
          )}
        </div>
      </Paper>
    </Popover>
  )
}
