const getQuery = (query, param) => {
  const urlQuery = query.get(param)
  return urlQuery === '' ? true : urlQuery
}

export const getUrlQuery = params => {
  const query = new URLSearchParams(window.location.search)
  if (Array.isArray(params)){
    return params.map(param => getQuery(query, param))
  }
  return getQuery(query, params)
}
