import ArrowDown from '@mui/icons-material/ChevronRight'
import { IconButton } from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { Text } from 'components/Text'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { createUserLink } from 'services/routingService'

import './styles.scss'

const DEFAULT_COVER_TYPE = 'center-expanded'
const DEFAULT_COVER_OPTIONS = {
  type: DEFAULT_COVER_TYPE,
  border: true,
  uppercase: true,
  colorSet: {
    backgroundColor: '#fff',
    fontColor: '#fff',
  },
  rectAngles: true,
}

const getClassNames = (baseClassName, isDesktopView) => {
  return classNames(baseClassName, {
    [`${baseClassName}--tablet-and-up`]: isDesktopView,
  })
}

const ScrollDownArrow = ({ contentRef }) => {
  return (
    <IconButton
      onClick={() => contentRef.current?.scrollIntoView({ behavior: 'smooth' })}
      aria-label='Go to gallery content'
      color='inherit'
    >
      <ArrowDown />
    </IconButton>
  )
}

const CoverBorder = () => {
  return (
    <div className='gallery-cover__border' />
  )
}

const CenterHorizontalCover = ({ coverPhoto, coverOptions, gallery, contentRef, isDesktopView }) => {
  return (
    <div className='gallery-cover-ch'>
      <div className={getClassNames('gallery-cover-ch__picture-wrapper', isDesktopView)}>
        <Image
          src={coverPhoto.src}
          pictureClassName='gallery-cover-ch__picture'
          imageClassName='gallery-cover-ch__image'
          thumbnailsSpecs={[
            { size: 'm', media: '(max-width: 600px)' },
            { size: 'l', media: '(min-width: 600px) and (max-width: 1920px)' },
            { size: 'l', media: '(min-width: 1920px)' },
          ]}
          generateThumbnailIfError
        />
      </div>
      {coverOptions.border && <CoverBorder />}
      <div className={getClassNames('gallery-cover-ch__content', isDesktopView)}>
        <Text
          as='h1'
          type='title'
          color='text__white-color'
          uppercase
          className={getClassNames('gallery-cover-ch__title', isDesktopView)}
        >
          {gallery?.title}
        </Text>
        <div className='gallery-cover-ch__professional-name-wrapper'>
          <Link
            className={getClassNames('gallery-cover-ch__professional-name', isDesktopView)}
            href={createUserLink(gallery?.userUid)}
          >
            <div>
              {gallery?.user?.name}
            </div>
          </Link>
        </div>
        <div className='gallery-cover-ch__arrow-down'>
          <ScrollDownArrow contentRef={contentRef} />
        </div>
      </div>
    </div>
  )
}

const CenterVerticalLeftCover = ({ coverPhoto, coverOptions, gallery, contentRef, isDesktopView }) => {
  return (
    <div className='gallery-cover-cvl'>
      <div className='gallery-cover-cvl__wrapper-image'>
        <Image
          src={coverPhoto.src}
          pictureClassName='gallery-cover-cvl__picture'
          imageClassName='gallery-cover-cvl__image'
          thumbnailsSpecs={[
            { size: 'm', media: '(max-width: 600px)' },
            { size: 'l', media: '(min-width: 600px) and (max-width: 1920px)' },
            { size: 'l', media: '(min-width: 1920px)' },
          ]}
          generateThumbnailIfError
        />
      </div>
      {coverOptions.border && <CoverBorder />}
      <div className='gallery-cover-cvl__content'>
        <Text
          as='h1'
          type='title'
          color='text__white-color'
          uppercase
          className={getClassNames('gallery-cover-cvl__title', isDesktopView)}
        >
          {gallery?.title}
        </Text>
        <div className='gallery-cover-cvl__professional-name-wrapper'>
          <Link
            className={getClassNames('gallery-cover-cvl__professional-name', isDesktopView)}
            href={createUserLink(gallery?.userUid)}
          >
            <div>
              {gallery?.user?.name}
            </div>
          </Link>
        </div>
      </div>
      <div className='gallery-cover-cvl__arrow-down'>
        <ScrollDownArrow contentRef={contentRef} />
      </div>
    </div>
  )
}

const CenterExpandedCover = ({ coverPhoto, coverOptions, gallery, contentRef, isDesktopView }) => {
  return (
    <div className='gallery-cover'>
      <Image
        src={coverPhoto.src}
        className='gallery-cover__image'
        imageClassName='gallery-cover__img'
        thumbnailsSpecs={[
          { size: 'm', media: '(max-width: 600px)' },
          { size: 'l', media: '(min-width: 600px) and (max-width: 1920px)' },
          { size: 'l', media: '(min-width: 1920px)' },
        ]}
        generateThumbnailIfError
      />
      <div className='gallery-cover__title-gradient' />
      {coverOptions.border && <CoverBorder />}
      <div className={getClassNames('gallery-cover__content', isDesktopView)}>
        <Text
          as='h1'
          type='title'
          color='text__white-color'
          uppercase
          className={getClassNames('gallery-cover__title', isDesktopView)}
        >
          {gallery?.title}
        </Text>
        <div className='gallery-cover__professional-name-wrapper'>
          <Link
            className={getClassNames('gallery-cover__professional-name', isDesktopView)}
            href={createUserLink(gallery?.userUid)}
          >
            <div>
              {gallery?.user?.name}
            </div>
          </Link>
        </div>
        <div className='gallery-cover__arrow-down'>
          <ScrollDownArrow contentRef={contentRef} />
        </div>
      </div>
    </div>
  )
}

export const GalleryCover = props => {
  const { gallery, isMobilePreview, isPreview } = props
  const { isTabletAndUp } = useBreakpoints()

  const coverOptions = gallery?.personalisation?.coverOptions ?? DEFAULT_COVER_OPTIONS
  const coverType = coverOptions.type ?? DEFAULT_COVER_TYPE
  // Cover photo is being fetched in fetchGalleryAction so even if the gallery photos are fetched in a paginated way, we always ensure that the cover photo is loaded
  // However, some legacy galleries might have not photosLayout property yet. If so, coverPhoto will be set to null in fetchGalleryAction and we use the first gallery photo as cover
  // TODO: Once all galleries have photosLayout, check if it's safe to remove this
  const coverPhoto = gallery?.coverPhoto === null
    ? gallery.photos?.[0] || {}
    : gallery?.coverPhoto || {}

  const coverWrapperStyle = {
    '--cover-color': `${coverOptions.colorSet.fontColor}`,
    '--cover-background-color': `${coverOptions.colorSet.backgroundColor}`,
    '--cover-text-transform': `${coverOptions.uppercase ? 'uppercase' : 'none'}`,
    '--cover-border-radius': `${coverOptions.rectAngles ? '0px' : '10px'}`,
  }

  const isDesktopView = (isTabletAndUp && !isPreview) || (isPreview && !isMobilePreview)

  if (isDesktopView) {
    return (
      <div
        className={classNames('gallery-cover-wrapper', {
          'gallery-cover-wrapper--mobile-preview': isMobilePreview,
          'gallery-cover-wrapper--preview': isPreview,
        })}
        style={coverWrapperStyle}
      >
        {coverType === 'center-vertical-left' && <CenterVerticalLeftCover {...props} coverOptions={coverOptions} coverPhoto={coverPhoto} isDesktopView={isDesktopView} />}
        {coverType === 'center-horizontal' && <CenterHorizontalCover {...props} coverOptions={coverOptions} coverPhoto={coverPhoto} isDesktopView={isDesktopView} />}
        {coverType === 'center-expanded' && <CenterExpandedCover {...props} coverOptions={coverOptions} coverPhoto={coverPhoto} isDesktopView={isDesktopView} />}
      </div>
    )
  }

  return (
    <div
      className='gallery-cover-wrapper gallery-cover-wrapper--mobile-preview'
      style={coverWrapperStyle}
    >
      {(coverType === 'center-horizontal' || coverType === 'center-vertical-left') && <CenterHorizontalCover {...props} coverOptions={coverOptions} coverPhoto={coverPhoto} />}
      {coverType === 'center-expanded' && <CenterExpandedCover {...props} coverOptions={coverOptions} coverPhoto={coverPhoto} />}
    </div>
  )
}
