import { getFeed } from 'services/serverBridge'

export const REQUEST_FEED = 'REQUEST_FEED'
export const RECEIVE_FEED = 'RECEIVE_FEED'

const FEED_MAX_SIZE = 6

function requestFeed() {
  return {
    type: REQUEST_FEED,
  }
}

function receiveFeed(feed) {
  return {
    type: RECEIVE_FEED,
    data: feed,
  }
}

export const fetchFeed = category => {
  return dispatch => {
    dispatch(requestFeed())
    return getFeed(FEED_MAX_SIZE, category)
      .then(feed => dispatch(receiveFeed(feed)))
  }
}
