import { useCallback, useEffect, useMemo, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useDispatch } from 'react-redux'

import {
  fetchGalleryPhotos as fetchGalleryPhotosAction,
  setGalleryPagesLoaded as setGalleryPagesLoadedAction,
} from 'store/galleries/actions'

const PHOTOS_PER_PAGE = 10 // this is the maximum a page can have since firebase limits this number

export const useGalleryPagination = (gallery, galleryId, galleryError, galleryIsLoading, onGalleryPhotosAdd) => {
  const dispatch = useDispatch()

  const galleryPhotoKeys = useMemo(() => gallery?.photosLayout || [], [gallery])

  const galleryPagesLoaded = useMemo(() => (gallery?.pagesLoaded) || [], [gallery])
  const bottomPageLoadedIndex = useMemo(() => Math.max(...galleryPagesLoaded), [galleryPagesLoaded])

  const maxNumberOfPhotoPages =
    useMemo(() => Math.ceil(galleryPhotoKeys.length / PHOTOS_PER_PAGE), [galleryPhotoKeys.length]) // max pages that can be loaded in the gallery
  const hasPagesToLoadOnBottom = bottomPageLoadedIndex < maxNumberOfPhotoPages - 1

  const [galleryPhotosAreLoading, setGalleryPhotosAreLoading] = useState(false)
  const [fetchingAllPhotos, setFetchingAllPhotos] = useState(false)
  const [photosLayoutAreAllFetched, setPhotosLayoutAreAllFetched] =
    useState(galleryPagesLoaded.length >= maxNumberOfPhotoPages)

  useEffect(() => {
    setPhotosLayoutAreAllFetched(galleryPagesLoaded.length >= maxNumberOfPhotoPages)
  }, [galleryPagesLoaded.length, maxNumberOfPhotoPages])

  const fetchGalleryPhotos = useCallback(
    (galleryId, pageIndex) => {
      setGalleryPhotosAreLoading(true)
      const startIndex = pageIndex * PHOTOS_PER_PAGE
      const endIndex = pageIndex * PHOTOS_PER_PAGE + PHOTOS_PER_PAGE
      const photoIdsToLoad = galleryPhotoKeys.slice(startIndex, endIndex)
      console.log(`Fetching gallery photos [${startIndex} - ${endIndex}]`)
      return dispatch(fetchGalleryPhotosAction(galleryId, photoIdsToLoad))
        .then(() => {
          setGalleryPhotosAreLoading(false)
          dispatch(setGalleryPagesLoadedAction(galleryId, [...galleryPagesLoaded, pageIndex]))
        })
    },
    [dispatch, galleryPhotoKeys, galleryPagesLoaded]
  )

  const fetchAllGalleryPhotos = useCallback(
    galleryId => {
      setFetchingAllPhotos(true)
      setGalleryPhotosAreLoading(true)
      return dispatch(fetchGalleryPhotosAction(galleryId))
        .then(() => {
          setGalleryPhotosAreLoading(false)
          setFetchingAllPhotos(false)
          const newGalleryPagesLoaded = new Array(maxNumberOfPhotoPages).fill(true).map((_, index) => index)
          dispatch(setGalleryPagesLoadedAction(galleryId, newGalleryPagesLoaded))
        })
    },
    [dispatch, maxNumberOfPhotoPages]
  )

  const loadMore = useCallback(() => {
    const prevPageLoaded = galleryPagesLoaded.length ? Math.max(...galleryPagesLoaded) : -1 // we must set -1 so that the first page to be loaded is page 0
    const nextPageToLoad = prevPageLoaded + 1
    fetchGalleryPhotos(galleryId, nextPageToLoad)
      .then(() => {
        if (onGalleryPhotosAdd) {
          onGalleryPhotosAdd()
        }
      })
  }, [fetchGalleryPhotos, galleryPagesLoaded, galleryId, onGalleryPhotosAdd])

  const [sentryRef] = useInfiniteScroll({
    loading: galleryPhotosAreLoading,
    hasNextPage: hasPagesToLoadOnBottom && !photosLayoutAreAllFetched,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: galleryError || galleryIsLoading,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 1200px 0px',
  })

  return {
    galleryPhotosAreLoading,
    fetchingAllPhotos,
    photosLayoutAreAllFetched,
    hasPagesToLoadOnBottom,
    fetchGalleryPhotos,
    fetchAllGalleryPhotos,
    sentryRef,
    PHOTOS_PER_PAGE,
  }
}
