import Icon from '@mui/material/Icon'
import ListItem from '@mui/material/ListItem'
import React, { useEffect, useState } from 'react'
import { FixedSizeList } from 'react-window'

import './styles.scss'

const NUMBER_OF_ICONS_PER_ROW = 4
const ITEM_SIZE = 80
const LIST_HEIGHT = 400

const renderIcon = (name, onClick) => (
  <Icon
    className='icon-picker__list-item'
    onClick={() => onClick(name)}
  >
    {name}
  </Icon>
)

const renderRow = (icons, chooseIcon) => props => {
  const { index, style } = props
  const parsedIndex = index * NUMBER_OF_ICONS_PER_ROW

  return (
    <ListItem style={style}>
      <div className='icon-picker__list-row'>
        {[...Array(NUMBER_OF_ICONS_PER_ROW).keys()].map(parcialIndex => {
          return icons[parsedIndex + parcialIndex] && renderIcon(icons[parsedIndex + parcialIndex].name, chooseIcon)
        })}
      </div>
    </ListItem>
  )
}

export const IconPicker = props => {
  const [icons, setIcons] = useState(null)

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/google/material-design-icons/3.0.2/iconfont/codepoints')
      .then(response => response.text())
      .then(data => data.split('\n'))
      .then(namesAndCodes =>
        namesAndCodes.map(nameAndCode => {
          const parts = nameAndCode.split(' ')
          return {
            name: parts[0],
            code: parts[1],
          }
        }))
      .then(setIcons)
  }, [])

  if (icons === null) {
    return null
  }

  return (
    <FixedSizeList
      className='icon-picker__list'
      height={LIST_HEIGHT}
      itemSize={ITEM_SIZE}
      itemCount={icons.length / NUMBER_OF_ICONS_PER_ROW}
    >
      {renderRow(icons, props.chooseIcon)}
    </FixedSizeList>
  )
}
