import ptPT from './locales/pt-PT'
import Polyglot from 'node-polyglot'
import React, { cloneElement } from 'react'

import { getLocalStorageBoolean } from 'utils/localStorage'

var polyglot

if (!polyglot) {
  polyglot = new Polyglot({
    phrases: ptPT,
    interpolation: { prefix: '{{', suffix: '}}' },
    locale: 'pt',
  })
}

/**
 * function to check if key exists using polyglot js as a translator
 */
export const hasTranslation = id => polyglot?.has(id)

/**
 * function to use polyglot js as a translator
 * @param {string} id
 * @param {{[key]: value}} options interpolation options
 *
 * @example
 *  give 'translation.key' ==> 'translated key with {{transpolationKey}}'
 *  t('translation.key', {transpolationKey: "value"}) ==> translated key with value
 */
export const t = (id, options) => {
  const showUndefinedI18nAsIds = getLocalStorageBoolean('showUndefinedI18nAsIds')
  const showAllI18nAsIds = getLocalStorageBoolean('showAllI18nAsIds')

  if (showAllI18nAsIds === true) {
    return id
  }

  if (!polyglot.has(id) && !showUndefinedI18nAsIds) {
    return undefined
  }

  // NOTE: Currently, this only support 1 component by label
  if (options?.components) {
    const translation = polyglot?.t(id, options)

    let parsedTranslation = translation

    Object.keys(options?.components).forEach(componentKey => {
      const componentValue = options?.components[componentKey]

      const parts = parsedTranslation.split(`<${componentKey}>`)
      const componentText = parts[1].split(`</${componentKey}>`)[0]

      parsedTranslation = (
        <div>
          {parts[0]}
          {cloneElement(componentValue, {}, componentText)}
          {parts[1].split(`</${componentKey}>`)[1]}
        </div>
      )
    })

    return parsedTranslation
  }

  return polyglot?.t(id, options)
}

