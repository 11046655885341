import CameraIcon from '@mui/icons-material/CameraAltRounded'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { IconButton } from 'components/Button'
import { ImageInput } from 'components/ImageInput'
import { UploadFeedback } from 'components/UploadFeedback'
import UserCircular from 'components/UserCircular'

import { UPLOAD_STATUS } from 'model/UploadStatus'

import './styles.scss'

export const UserCircularInput = props => {
  const { size, ...userCircularProps } = props

  const [ imageError, setImageError ] = useState(false)

  const onImageError = () => setImageError(true)

  useEffect(() => {
    if (imageError && props.avatar) {
      setImageError(false)
    }
  }, [imageError, props.avatar])

  return (
    <div
      className='user-circular-input'
      style={props.size ? { '--user-circular-input-width': props.size + 'px' } : {}}
    >
      <UserCircular
        className={classNames('user-circular-input__avatar', {
          'user-circular-input__avatar--no-image': imageError || !props.avatar,
        })}
        size={size || 160}
        withFallback={props.withFallback || false}
        onImageError={onImageError}
        onAddImageClick={() => {}}
        avatar={props.avatar}
        {...userCircularProps}
      />
      {(props.onAddImageClick || props.onImageInputChange) && (
        <div className='user-circular-input__avatar-action user-circular-input__avatar-action--edit'>
          <IconButton
            onClick={props.onAddImageClick}
            size='small'
            color='primary'
            variant='contained'
          >
            <CameraIcon />
          </IconButton>
        </div>
      )}
      {props.onRemoveImageClick && (
        <div className='user-circular-input__avatar-action user-circular-input__avatar-action--remove'>
          <IconButton
            disabled={props.disabledRemoveImageButton}
            onClick={props.onRemoveImageClick}
            size='small'
            color='danger'
            variant='contained'
          >
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      )}
      {props.uploadStatus !== UPLOAD_STATUS.NOT_UPLOADED && (
        <div className='user-circular-input__avatar-upload-status'>
          <UploadFeedback
            uploading={props.uploadStatus === UPLOAD_STATUS.UPLOADING}
            uploaded={props.uploadStatus === UPLOAD_STATUS.UPLOADED}
            onAnimationEnd={props.avatarAnimationIsDone}
          />
        </div>
      )}
      {!props.onAddImageClick && (
        <ImageInput
          onChange={props.onImageInputChange}
          className='user-circular-input__avatar-action user-circular-input__avatar-action--edit user-circular-input__image-input'
        />
      )}
    </div>
  )
}
