import React from 'react'

import { LinkButton } from 'components/Button'

import { createHomeLink } from 'services/routingService'

import './styles.scss'

export const PageNotFoundError = () => {
  return (
    <div className='page-not-found-error'>
      <h1>404 - Página não encontrada</h1>
      <p>Oops, parece que a página que estás a tentar aceder não existe</p>
      <LinkButton
        href={createHomeLink()}
      >
        Página inicial
      </LinkButton>
    </div>
  )
}
