import { useEffect } from 'react'

export const useStopScroll = stopScroll => {
  useEffect(() => {
    if (stopScroll) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [stopScroll])
}
