import ClearIcon from '@mui/icons-material/Clear'
import ErrorIcon from '@mui/icons-material/Error'
import { Snackbar } from '@mui/material'
import classNames from 'classnames'
import React, { useState } from 'react'

import { Button, IconButton } from 'components/Button'
import { HelpTooltip } from 'components/HelpTooltip'
import { LinearProgress } from 'components/LinearProgress/index.js'

import { UPLOAD_STATUS } from 'model/UploadStatus'

export const UploadingSnackbar = ({
  uploadingStatus,
  isTabletAndUp,
  uploadingImagesState,
  uploadingProgress,
  uploadingLabel,
  uploadingHasError,
  finishUploading,
}) => {
  const [ openUploadingProgressDetails, setOpenUploadingProgressDetails ] = useState(false)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={uploadingStatus === UPLOAD_STATUS.UPLOADING}
      style={isTabletAndUp ? { bottom: '16px', left: '114px' } : { bottom: '80px' }}
    >
      <div className={classNames('event-gallery__snackbar', {
        'event-gallery__snackbar--open': openUploadingProgressDetails,
      })}
      >
        <div className={classNames('event-gallery__snackbar__details', {
          'event-gallery__snackbar__details--open': openUploadingProgressDetails,
        })}
        >
          <ol>
            {Object.values(uploadingImagesState).map(uploadingImage => (
              <li key={uploadingImage.id}>
                <div className='event-gallery__snackbar__details__name'>{uploadingImage.name || uploadingImage.id }</div>
                {!uploadingImage.hasError && <div>{`${Math.round(uploadingImage.progress)}%`}</div>}
                {uploadingImage.hasError && (
                  <HelpTooltip iconId='error' fontSize={24} color='error'>
                    <React.Fragment>
                      <h2>Houve um erro a enviar esta foto!</h2>
                      <p>Após as restantes fotos serem enviadas, recarrega a página e tenta novamente</p>
                    </React.Fragment>
                  </HelpTooltip>
                )}
              </li>
            ))}
          </ol>
        </div>
        <div className='event-gallery__snackbar__content'>
          A enviar fotografias...
          <LinearProgress value={uploadingProgress} label={uploadingLabel} />
        </div>
        <Button
          className='event-gallery__snackbar__more'
          onClick={() => setOpenUploadingProgressDetails(!openUploadingProgressDetails)}
          variant='text'
          color='primary'
          endIcon={uploadingHasError ? <ErrorIcon color='error' /> : null}
        >
          Detalhes
        </Button>
        <IconButton
          color='default'
          size='small'
          aria-label='close Modal'
          onClick={finishUploading}
        >
          <ClearIcon />
        </IconButton>
      </div>
    </Snackbar>
  )
}
