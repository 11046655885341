import { NOTIFICATION_TYPE } from 'components/GlobalNotifications'

import { getNotificationSystem } from 'services/notificationSystem'

const notificationSystem = getNotificationSystem()

export const notifyError = errorMessage =>
  notify(NOTIFICATION_TYPE.ERROR, errorMessage)

export const notifyWarning = warning =>
  notify(NOTIFICATION_TYPE.WARNING, warning)

export const notifyInfo = info =>
  notify(NOTIFICATION_TYPE.INFO, info)

export const notifySuccess = successMessage =>
  notify(NOTIFICATION_TYPE.SUCCESS, successMessage)

export const notify = (notificationType, errorMessage) => {
  notificationSystem.notify({
    message: errorMessage,
    type: notificationType,
  })
}
