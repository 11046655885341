import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import classNames from 'classnames'
import React from 'react'

import { Button } from 'components/Button'
import { LoadingComponent } from 'components/LoadingComponent'
import { PriceLabel } from 'components/PriceLabel'
import { SimpleList } from 'components/SimpleList'
import { Text } from 'components/Text'

import './styles.scss'

export const PremiumPlanCard = props => {
  const onPlanChoose = () =>
    props.onPlanChoose(props.id)

  return (
    <Card className={classNames('premium-plan-card', props.className, {
      'premium-plan-card--highlighted': props.highlighted,
    })}
    >
      <CardHeader
        className='premium-plan-card__header'
        title={<Text as='span' type='title3'>{props.title}</Text>}
        subheader={<Text>{props.subTitle}</Text>}
      />
      <CardContent>
        <Text
          as='p'
          type='body'
          color='text__grey-color'
          className='premium-plan-card__description'
        >
          {props.description}
        </Text>
        <div className='premium-plan-card__prices'>
          <PriceLabel
            className='premium-plan-card__price'
            periodPrice={props.periodPrice}
            periodPriceWithoutDiscount={props.periodPriceWithoutDiscount}
            planPeriod={props.planPeriod}
          />
        </div>
        <SimpleList className='premium-plan-card__features'>
          {props.features.map(feature => (
            <div
              key={feature}
              className='premium-plan-card__features__item'
            >
              <CheckCircleIcon />
              <Text as='span' type='body'>{feature}</Text>
            </div>
          ))}
        </SimpleList>
      </CardContent>
      {props.moreButtonLabel && (
        <CardActions>
          <Button
            className='premium-plan-card__action-button'
            color='default'
            variant='outlined'
          >
            {props.moreButtonLabel}
          </Button>
        </CardActions>
      )}
      {props.pickButtonLabel && (
        <CardActions className='premium-plan-card__action-wrapper'>
          <Button
            className='premium-plan-card__action-button'
            color='primary'
            variant='contained'
            onClick={onPlanChoose}
            disabled={props.uploading || props.selected || props.disabled}
          >
            {props.pickButtonLabel}
          </Button>
          <LoadingComponent
            isLoading={props.uploading}
            size={30}
            className='premium-plan-card__action-button__loading'
          />
        </CardActions>
      )}
    </Card>
  )
}
