import AddIcon from '@mui/icons-material/Add'
import classNames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'

import { BackOfficeLogo } from 'components/BackOfficeLogo'
import { IconButton, LinkButton } from 'components/Button'
import { Image } from 'components/Image'
import { Text } from 'components/Text'

import { getConfigValue } from 'services/configService'
import { t } from 'services/i18n'
import { createBackOfficeLink } from 'services/routingService'

import './styles.scss'

const BeamStudioItem = ({ title, text, opened, onClick }) => {
  const [textHeight, setTextHeight] = useState()
  const textRef = useRef(null)

  useEffect(() => {
    setTextHeight(textRef.current.offsetHeight)
  }, [textRef])

  const textStyle = {
    height: textRef.current === null
      ? 'auto' // check initial height
      : opened
        ? `${textHeight}px`
        : '0px',
  }

  return (
    <div className='beam-studio__item'>
      <div onClick={onClick} className='beam-studio__item__header'>
        <Text color='text__primary-color' as='h3' type='title3'>
          {title}
        </Text>
        <IconButton
          onClick={onClick}
          size='large'
          color='primary'
          className={classNames('beam-studio__item__open-button', {
            'beam-studio__item__open-button--hide': opened,
          })}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div
        ref={textRef}
        onClick={onClick}
        className={classNames('beam-studio__item__collapsible', {
          'beam-studio__item__collapsible--open': opened || textRef.current === null,
        })}
        style={textStyle}
      >
        <Text color='text__default-color'>{text}</Text>
      </div>
    </div>
  )
}

export const BeamStudio = () => {
  const items = getConfigValue('professionals.beam_studio.items')

  const [openedItemIndex, setOpenedItemIndex] = useState(0)

  const onItemClick = index => () => setOpenedItemIndex(index)

  return (
    <div className='beam-studio'>
      <div className='beam-studio__backoffice-logo-wrapper'>
        <BackOfficeLogo className='beam-studio__backoffice-logo' primary />
      </div>
      <h4 className='beam-studio__sub-title'>{t('professionals.beam_studio.sub_title')}</h4>
      <div className='beam-studio__content'>
        <div className='beam-studio__items'>
          {
            items.map((item, index) => (
              <BeamStudioItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                opened={openedItemIndex === index}
                onClick={onItemClick(index)}
                title={t(item.title)}
                text={t(item.text)}
              />
            ))
          }
          <LinkButton
            wrapperClassName='beam-studio__action-button'
            variant='outlined'
            color='primary'
            href={createBackOfficeLink()}
          >
            {t('professionals.beam_studio.action_button')}
          </LinkButton>
        </div>
        <div className='beam-studio__image-wrapper'>
          {items[openedItemIndex].image && (
            <Image
              className='beam-studio__image-wrapper__image'
              src={items[openedItemIndex].image.src}
              alt={items[openedItemIndex].image.alt}
              thumbnailsSpecs={[{ size: 's', media: '(min-width: 0px)' }]}
            />
          )}
        </div>
      </div>
    </div>
  )
}
