import React from 'react'
import { Paper, Table as MUITable, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import { Text } from 'components/Text'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.secondary.light,
  },
}))

export const Table = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <MUITable>
        <TableHead>
          <TableRow>
            <StyledTableCell align='left' colSpan={data.header.colSpan}>
              <Text type='subtitle3'>
                {data.header.label}
              </Text>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map(row => {
            if (row.header) {
              return (
                <TableRow key={row.id}>
                  <StyledTableCell align='left' colSpan={row.colSpan}>
                    <Text type='subtitle3'>
                      {row.label}
                    </Text>
                  </StyledTableCell>
                </TableRow>
              )
            }
            return (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {row.cells.map(cell => {
                  return (
                    <TableCell key={cell.key} component='th' scope='row' align={cell.align ?? 'left'}>
                      {cell.value}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MUITable>
    </TableContainer>
  )
}
