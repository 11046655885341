import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollBoundary = props => {
  const currentPathname = useRef()
  const location = useLocation()
  const pathname = location.pathname

  useEffect(() => {
    if (pathname !== currentPathname.current) {
      currentPathname.current = pathname
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return props.children
}
