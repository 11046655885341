import { useState, useRef } from 'react'

const DEFAULT_NOTIFICATION_DURATION = 6000
const DEFAULT_NOTIFICATION_DURATION_AFTER_PAUSE = 3000

export const INTERACTION_TYPES = {
  MOUSE_DOWN: 'mouseDown',
  MOUSE_UP: 'mouseUp',
  MOUSE_ENTER: 'mouseEnter',
  MOUSE_LEAVE: 'mouseLeave', // carefull: Use onMouseLeave event instead of onMouseOut. Otherwise, timeout will continue after mouse enters children components
}

export const useNotification = ({ notificationDuration = DEFAULT_NOTIFICATION_DURATION } = {}) => {
  const [ notifications, setNotifications ] = useState([])
  const timeout = useRef()

  const showNotification = notification => {
    setNotifications(prevNotifications => {
      return [...prevNotifications, notification]
    })

    if (notifications.length === 0) {
      timeout.current = setTimeout(popNotification, notificationDuration)
    }
  }

  const popNotification = () => {
    setNotifications(prevNotifications => {
      const nextNotificationsState = [...prevNotifications.slice(1)]
      if (nextNotificationsState.length >= 1) {
        timeout.current = setTimeout(popNotification, notificationDuration)
      } else {
        clearTimeout(timeout.current)
      }

      return nextNotificationsState
    })
  }

  const onInteraction = type => {
    clearTimeout(timeout.current)
    if (type === INTERACTION_TYPES.MOUSE_LEAVE || type === INTERACTION_TYPES.MOUSE_UP) {
      timeout.current = setTimeout(popNotification, DEFAULT_NOTIFICATION_DURATION_AFTER_PAUSE)
    }
  }

  const onCloseNotification = notificationId => {
    setNotifications(prevNotifications => {
      const nextNotificationsState = [...prevNotifications]

      const notificationIndex = nextNotificationsState.findIndex(notification => notification.id === notificationId)
      if (notificationIndex > -1) {
        nextNotificationsState.splice(notificationIndex, 1)
      }

      if (nextNotificationsState.length >= 1) {
        timeout.current = setTimeout(popNotification, notificationDuration)
      } else {
        clearTimeout(timeout.current)
      }

      return nextNotificationsState
    })
  }

  return [notifications, showNotification, onInteraction, onCloseNotification]
}
