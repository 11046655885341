import { Pack } from '../Pack'
import { User } from '../User'

// TODO: This structure is not good. Change it.

export class FeedItem {
  constructor (idOrFeedItem, pack, user) {
    if (typeof idOrFeedItem === 'object') {
      return this.initializerWithFeedItem(idOrFeedItem)
    }

    return this.initializeWithProperties(idOrFeedItem, pack, user)
  }

  initializerWithFeedItem (feedItem) {
    this.initializeWithProperties(feedItem.id, feedItem.pack, feedItem.user)
  }

  initializeWithProperties (id, pack, user) {
    this.id = id
    this.pack = new Pack(pack)
    this.user = new User(user)
  }

  get header () {
    return {
      image: {
        imgSrc: this.pack.packInfo.media.images[0]?.src,
        imgSize: {
          imgWidth: this.pack.packInfo.media.images[0]?.width,
          imgHeight: this.pack.packInfo.media.images[0]?.height,
        },
      },
      label: this.user.isPro ? 'pro' : '',
      // TODO: CHANGE THIS
      like: true,
    }
  }

  get body () {
    return {
      packId: this.pack.id,
      title: this.pack.packInfo.title,
      description: this.pack.packInfo.description,
    }
  }

  get footer () {
    return {
      imgSrc: this.user.avatar,
      name: this.user.name,
      userUid: this.user.uid,
    }
  }
}
