import classNames from 'classnames'
import React, { Children } from 'react'

import './styles.scss'

const DEFAULT_COLS = 4
const DEFAULT_SPACING = 10

export const ItemList = props => (
  <div
    className={classNames('item-list', props.className)}
    style={{
      '--item-width': (100 / (props.cols || DEFAULT_COLS)) + '%',
      '--spacing': (props.spacing || DEFAULT_SPACING) + 'px',
    }}
  >
    {props.children && (
      Children.map(props.children, child => {
        return (
          <div className={classNames('item-list__item', props.itemClassName)}>
            {child}
          </div>
        )
      })
    )}
  </div>
)
