import { useCallback, useEffect, useRef } from 'react'
import { throttle } from 'underscore'

import { useWindowSize } from 'hooks/useWindowSize'

import {
  RESIZE_BOX,
  isInsideRect,
  setProtectionCoverStyle,
  createProtectionCover,
  createDuplicatedCard,
  createCardResizerElmt,
  setProtectionCoverCursor,
  CURSOR_TYPE,
  isInsideResizerBox,
} from './utils'

const MOVE_THRESHOLD_PX = 5
const MOVE_THRESHOLD_MS = 250

const getClientPos = event => ({
  clientX: event.changedTouches ? event.changedTouches[0].pageX : event.pageX,
  clientY: event.changedTouches ? event.changedTouches[0].pageY : event.pageY,
})

// prevent scrolling
const preventScrolling = () => {
  document.body.style.overflow = 'hidden'
  document.documentElement.style.overflow = 'hidden'
}

const resumeScrolling = () => {
  document.body.style.overflow = 'scroll'
  document.documentElement.style.overflow = 'scroll'
}

const userHasMoved = (initialMovingPos, event) => {
  const { clientX, clientY } = getClientPos(event)
  const newXOffset = Math.abs(clientX - initialMovingPos.current.x)
  const newYOffset = Math.abs(clientY - initialMovingPos.current.y)

  return newXOffset > MOVE_THRESHOLD_PX || newYOffset > MOVE_THRESHOLD_PX
}

// This useLayoutEditor hook is mainly used to move items in the Masonry layout
// It does not use any library since the Masonry layout it's a complex library and too coupled to the DOM
// and the libraries that can handle it well, are paid

export const useLayoutEditor = ({
  selector,
  editMode,
  onTemporaryIndexChange,
  onIndexChange,
  onCancel,
  setImageHeight,
  cardsMaxHeights,
  setImageColumns,
  currentLayoutColumns,
}) => {
  const windowSize = useWindowSize()
  const refs = useRef([])
  const coverElmntRef = useRef()
  const duplicatedNodeRef = useRef()
  const selectedCardIdx = useRef(-1)
  const selectedCardAfterInitialDelay = useRef(false)
  const selectedCardInitialTimestampTimer = useRef(null)
  const initialMovingPos = useRef({ x: -1, y: -1 })
  const currentFinalIndex = useRef(-1)
  const hoveredElementIndex = useRef(-1)
  const selectedResizeBox = useRef(-1)
  const resizeElmt = useRef()
  const hasChanged = useRef(false)
  const onItemMoveTimeout = useRef()

  // This function updates the layout elements saved variables, like its bounding client rect
  // Firstly, this rect must be saved so that any change in the DOM is not mapped here until the user really changes the item's position
  // This function should be called after the layout DOM is updated, after any item position is change
  const updateRefs = useCallback(() => {
    if (selector) {
      refs.current = []
      document.querySelectorAll(selector)
        .forEach(elmt => {
          refs.current.push({
            elmt: elmt,
            rect: elmt.getBoundingClientRect(),
          })
        })
    }
  }, [selector])

  const updateWrapper = useCallback(() => setProtectionCoverStyle(
    coverElmntRef.current,
    document.querySelector('.mansory-cards-layout').getBoundingClientRect()
  ), [])

  const updateDragable = useCallback(() => {
    if (!editMode) {
      return
    }
    updateRefs()
    updateWrapper()
    if (hoveredElementIndex.current !== -1) {
      createCardResizer(refs.current[hoveredElementIndex.current].rect)
    }
  }, [editMode, updateRefs, updateWrapper])

  const removeResizeElement = useCallback(() => {
    if (resizeElmt.current) {
      hoveredElementIndex.current = -1
      selectedResizeBox.current = -1
      resizeElmt.current.remove()
      resizeElmt.current = null
    }
  }, [])

  const removeCoverElement = useCallback(() => {
    if (coverElmntRef.current) {
      coverElmntRef.current.remove()
      coverElmntRef.current = null
    }
  }, [])

  const cleanDOMElements = useCallback(() => {
    // only remove elements if not moving
    if (selectedCardIdx.current === -1) {
      removeCoverElement()
    }
    removeResizeElement()
  }, [removeCoverElement, removeResizeElement])

  const createCardResizer = cardRect => {
    if (!resizeElmt.current) {
      resizeElmt.current = document.createElement('div')
      document.body.appendChild(resizeElmt.current)
    }

    createCardResizerElmt(resizeElmt, cardRect)
  }

  const onItemResize = useCallback(event => {
    const { clientX, clientY } = getClientPos(event)
    const newXOffset = clientX - initialMovingPos.current.x
    const newYOffset = clientY - initialMovingPos.current.y
    const cardRect = refs.current[hoveredElementIndex.current].rect

    if (selectedResizeBox.current === RESIZE_BOX.TOP) {
      resizeElmt.current.style.top = cardRect.top + 5 + newYOffset + 'px'
      resizeElmt.current.style.height = cardRect.height - 10 - newYOffset + 'px'
    } else if (selectedResizeBox.current === RESIZE_BOX.RIGHT) {
      resizeElmt.current.style.width = cardRect.width - 20 + newXOffset + 'px'
    } else if (selectedResizeBox.current === RESIZE_BOX.BOTTOM) {
      resizeElmt.current.style.height = cardRect.height - 10 + newYOffset + 'px'
    } else if (selectedResizeBox.current === RESIZE_BOX.LEFT) {
      resizeElmt.current.style.left = cardRect.left + 5 + newXOffset + 'px'
      resizeElmt.current.style.width = cardRect.width - 10 - newXOffset + 'px'
    }
  }, [])

  // This function is used to create the resize UI for a specific tile that is being hovered or selected in mobile
  const onItemHover = useCallback(event => {
    const { clientX, clientY } = getClientPos(event)

    refs.current.forEach((elmt, index) => {
      if (isInsideRect(clientX, clientY, elmt.rect)) {
        hoveredElementIndex.current = index
      }
    })

    if (hoveredElementIndex.current > -1) {
      setProtectionCoverCursor(coverElmntRef.current, CURSOR_TYPE.MOVE)
      const hoveredElementRect = refs.current[hoveredElementIndex.current].rect
      createCardResizer(hoveredElementRect)

      const hoveredResizeBox = isInsideResizerBox(clientX, clientY, hoveredElementRect)
      if (hoveredResizeBox >= 0) {
        if (hoveredResizeBox === RESIZE_BOX.TOP || hoveredResizeBox === RESIZE_BOX.BOTTOM) {
          setProtectionCoverCursor(coverElmntRef.current, CURSOR_TYPE.HEIGHT_SIZE)
        } else {
          setProtectionCoverCursor(coverElmntRef.current, CURSOR_TYPE.WIDTH_SIZE)
        }
      }
    } else if (resizeElmt.current) {
      removeResizeElement()
    }
  }, [removeResizeElement])

  const onItemMove = useCallback(event => {
    if (!editMode || selectedCardIdx.current === -1) {
      return
    }

    clearTimeout(onItemMoveTimeout.current)

    const currentRef = duplicatedNodeRef.current

    const itemsBoundingRects = refs.current.map(ref => ref.rect)

    const { clientX, clientY } = getClientPos(event)

    const newXOffset = clientX - initialMovingPos.current.x
    const newYOffset = clientY - initialMovingPos.current.y
    currentRef.style.transform = `translate(${newXOffset}px, ${newYOffset}px)`

    const tempSwapedItemIdx = itemsBoundingRects
      .findIndex(rect => isInsideRect(clientX, clientY, rect))

    const finalIndexHasChanged = tempSwapedItemIdx !== currentFinalIndex.current &&
     tempSwapedItemIdx !== selectedCardIdx.current

    // this should be under a timeout to avoid errors with race conditions
    if (tempSwapedItemIdx !== -1 && finalIndexHasChanged) {
      onItemMoveTimeout.current = setTimeout(() => {
        currentFinalIndex.current = tempSwapedItemIdx
        onTemporaryIndexChange && onTemporaryIndexChange(selectedCardIdx.current, tempSwapedItemIdx)
        hasChanged.current = true
      }, 40)
    }

    // if user is moving the item to its original position, we should reset layout
    if (hasChanged.current && tempSwapedItemIdx === selectedCardIdx.current) {
      // this should be under a timeout to avoid errors with race conditions
      onItemMoveTimeout.current = setTimeout(() => {
        currentFinalIndex.current = tempSwapedItemIdx
        hasChanged.current = false
        onTemporaryIndexChange && onTemporaryIndexChange(selectedCardIdx.current, tempSwapedItemIdx)
      }, 40)
    }

    if (currentFinalIndex.current === -1) {
      currentFinalIndex.current = tempSwapedItemIdx
    }
  }, [editMode, onTemporaryIndexChange])

  const onMouseMove = useCallback(event => {
    if (!editMode) {
      return
    }

    // in mobile, first wait for the move threshold and see if the user just wants to scroll
    if (event.changedTouches && selectedCardAfterInitialDelay.current === false && selectedResizeBox.current === -1) {
      // if the user has moved beyound the move threshold, the timeout should be cleared and the move should be treated as a scroll
      if (userHasMoved(initialMovingPos, event)) {
        clearTimeout(selectedCardInitialTimestampTimer.current)
      }

      return
    }

    // if user is hovering a tile, without clicking it, let's simulate onItemHover
    if (selectedCardIdx.current === -1 && selectedResizeBox.current === -1) {
      onItemHover(event)
      return
    }

    if (selectedResizeBox.current !== -1 && hoveredElementIndex.current !== -1) {
      onItemResize(event)
      return
    }

    onItemMove(event)

    event.stopPropagation()
    event.preventDefault()
  }, [editMode, onItemHover, onItemResize, onItemMove])

  const throttledOnMouseMove = throttle(onMouseMove, 25)

  const setMoveMode = clickedElementIndex => {
    // create a duplicated item so that it can frelly move without changing the DOM
    createDuplicatedCard(refs, clickedElementIndex, duplicatedNodeRef)
    selectedCardIdx.current = clickedElementIndex
    refs.current[clickedElementIndex].elmt.style.opacity = '0.35'
  }

  const onItemSelect = useCallback(event => {
    const { clientX, clientY } = getClientPos(event)

    let clickedElementIndex = -1
    let clickedResizeBox = null

    refs.current.forEach((elmt, index) => {
      if (isInsideRect(clientX, clientY, elmt.rect)) {
        clickedResizeBox = isInsideResizerBox(clientX, clientY, elmt.rect)

        if (clickedResizeBox === undefined) {
          clickedElementIndex = index
        }
      }
    })

    // if in mobile device and in tile's move mode, we should first wait for the move threshold to see if the user just wants to scroll
    // if in the time within that threshold the user moves beyound MOVE_THRESHOLD_PX pixels, then the move should continue
    // otherwise, this touch should be treated as a tile move or a normal touch if user deselects the tile
    if (editMode && event.changedTouches && clickedElementIndex >= 0) {
      selectedCardAfterInitialDelay.current = false
      clearTimeout(selectedCardInitialTimestampTimer.current)
      selectedCardInitialTimestampTimer.current = setTimeout(() => {
        selectedCardAfterInitialDelay.current = true
        // in mobile, as there is no hover, set hover UI when pressed
        onItemHover(event)
        // prevent scrolling in mobile devices after pressing tile for a while
        preventScrolling()
        setMoveMode(clickedElementIndex)
      }, MOVE_THRESHOLD_MS)

      // initial moving position should be set anyways so that onItemHover can be called correctly in onDeSelect when user is on mobile
      initialMovingPos.current = {
        x: clientX,
        y: clientY,
      }

      return
    }

    if (editMode) {
      if (clickedElementIndex >= 0) {
        setMoveMode(clickedElementIndex)
      } else if (clickedResizeBox >= 0) {
        selectedResizeBox.current = clickedResizeBox
        // if user is in resize mode, prevent scroll right away
        if (event.changedTouches) {
          preventScrolling()
        }
      } else {
        onCancel()
        return
      }

      initialMovingPos.current = {
        x: clientX,
        y: clientY,
      }
    } else {
      onCancel()
    }
  }, [editMode, onCancel, onItemHover])

  const swapItems = useCallback((initialIndex, finalIndex) => {
    if (initialIndex !== -1 && finalIndex !== -1) {
      onIndexChange(selectedCardIdx.current, finalIndex)
    }
  }, [onIndexChange])

  const onDeSelect = useCallback(event => {
    if (event.changedTouches) {
      // if in mobile and the user has not moved, present the tile resize UI
      if (selectedCardAfterInitialDelay.current === false && !userHasMoved(initialMovingPos, event)) {
        onItemHover(event)
      }
      // set scrolling back in mobile devices
      resumeScrolling()
      clearTimeout(selectedCardInitialTimestampTimer.current)
      selectedCardAfterInitialDelay.current = false
    }

    if (editMode && selectedCardIdx.current !== -1) {
      duplicatedNodeRef.current.remove() // remove duplicated element from dom

      if (currentFinalIndex.current !== -1 && currentFinalIndex.current !== selectedCardIdx.current) {
        swapItems(selectedCardIdx.current, currentFinalIndex.current)
      }

      refs.current[selectedCardIdx.current].elmt.style.opacity = 1

      // reset useLayoutEditor state
      currentFinalIndex.current = -1
      selectedCardIdx.current = -1
      hasChanged.current = false
      clearTimeout(onItemMoveTimeout.current)
      updateDragable()
    }

    if (hoveredElementIndex.current !== -1 && selectedResizeBox.current !== -1) {
      if (selectedResizeBox.current === RESIZE_BOX.RIGHT || selectedResizeBox.current === RESIZE_BOX.LEFT) {
        const newWidth = resizeElmt.current.style.width.slice(0, -2) // remove "px"
        const layoutWidth = coverElmntRef.current.style.width.slice(0, -2) // remove "px"
        const imageWidthInColumns = Math.floor(newWidth * currentLayoutColumns / layoutWidth) + 1
        setImageColumns(hoveredElementIndex.current, imageWidthInColumns)
      }

      if (selectedResizeBox.current === RESIZE_BOX.BOTTOM || selectedResizeBox.current === RESIZE_BOX.TOP) {
        const newHeight = resizeElmt.current.style.height.slice(0, -2) // remove "px"
        const cardCurrentMaxHeight = cardsMaxHeights[hoveredElementIndex.current]
        const cardElementCurrentHeight = refs.current[hoveredElementIndex.current].rect.height - 10 // remove card margin
        const newCardHeightInPercentage = Math.max(
          Math.min(((newHeight / cardElementCurrentHeight) * cardCurrentMaxHeight), 1), 0.1
        )
        setImageHeight(hoveredElementIndex.current, newCardHeightInPercentage)
      }

      // reset useLayoutEditor state
      removeResizeElement()
      updateDragable()
    }

    event.preventDefault()
  }, [
    editMode,
    onItemHover,
    updateDragable,
    swapItems,
    removeResizeElement,
    currentLayoutColumns,
    setImageColumns,
    cardsMaxHeights,
    setImageHeight,
  ])

  const removeListeners = useCallback(() => {
    window.removeEventListener('scroll', updateDragable)
    coverElmntRef.current.removeEventListener('mousedown', onItemSelect)
    coverElmntRef.current.removeEventListener('touchstart', onItemSelect)
    coverElmntRef.current.removeEventListener('mousemove', throttledOnMouseMove)
    coverElmntRef.current.removeEventListener('touchmove', throttledOnMouseMove)
    coverElmntRef.current.removeEventListener('mouseup', onDeSelect)
    coverElmntRef.current.removeEventListener('mouseout', onDeSelect)
    coverElmntRef.current.removeEventListener('touchend', onDeSelect)
    coverElmntRef.current.removeEventListener('blur', onDeSelect)
    coverElmntRef.current.removeEventListener('touchcancel', onDeSelect)
  }, [updateDragable, onItemSelect, throttledOnMouseMove, onDeSelect])

  useEffect(() => {
    if (!selector || !editMode) {
      if (!editMode && coverElmntRef.current) {
        removeListeners()
        cleanDOMElements()
      }
      return
    }

    if (!coverElmntRef.current) {
      const coverElmnt = createProtectionCover(document.querySelector('.mansory-cards-layout'))
      coverElmntRef.current = coverElmnt
    }

    window.addEventListener('scroll', updateDragable)
    coverElmntRef.current.addEventListener('mousedown', onItemSelect)
    coverElmntRef.current.addEventListener('touchstart', onItemSelect)
    coverElmntRef.current.addEventListener('mousemove', throttledOnMouseMove)
    coverElmntRef.current.addEventListener('touchmove', throttledOnMouseMove)
    coverElmntRef.current.addEventListener('mouseup', onDeSelect)
    coverElmntRef.current.addEventListener('mouseout', onDeSelect)
    coverElmntRef.current.addEventListener('touchend', onDeSelect)
    coverElmntRef.current.addEventListener('blur', onDeSelect)
    coverElmntRef.current.addEventListener('touchcancel', onDeSelect)

    return () => {
      removeListeners()
      cleanDOMElements()
    }
  }, [
    selector,
    editMode,
    cleanDOMElements,
    updateRefs,
    updateDragable,
    onItemSelect,
    throttledOnMouseMove,
    onDeSelect,
    removeListeners,
  ])

  useEffect(() => {
    // update windowSize after debounce and user is not moving any card
    if (
      coverElmntRef.current &&
      currentFinalIndex.current === -1 &&
      selectedCardIdx.current === -1 &&
      selectedResizeBox.current === -1
    ) {
      updateDragable()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  return [updateDragable]
}
