import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import classNames from 'classnames'
import React from 'react'

import { IconButton } from 'components/Button'

export const MobileMenuIcon = ({ mobileMenuIsOpen, toggleMobileMenu }) => {
  return (
    <div
      className={classNames('header__menu-button', {
        'header__menu-button--close': mobileMenuIsOpen,
      })}
    >
      <IconButton
        aria-label='Open Menu'
        color='inherit'
        onClick={toggleMobileMenu}
      >
        {mobileMenuIsOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    </div>
  )
}
