import { nanoid } from 'nanoid'

const DEFAULT_MAX_WORKING_PROMISES = 6

export default class PromiseQueue {
  queue = []
  workingPromises = {}
  onEndCallbacks = []
  concurrent = 0
  canceled = false
  cancelOnError = false

  constructor(props) {
    this.concurrent = props?.concurrent || DEFAULT_MAX_WORKING_PROMISES
    this.cancelOnError = props?.cancelOnError || false
  }

  enqueue(promise) {
    if (Array.isArray(promise)) {
      const promises = []
      promise.forEach(promiseItem => {
        const newPromise = new Promise((resolve, reject) => {
          this.queue.push({
            id: nanoid(),
            promise: promiseItem,
            resolve,
            reject,
          })
          this.dequeue()
        })
        promises.push(newPromise)
      })
      return promises
    }

    return new Promise((resolve, reject) => {
      this.queue.push({
        id: nanoid(),
        promise,
        resolve,
        reject,
      })
      this.dequeue()
    })
  }

  dequeue() {
    if (Object.keys(this.workingPromises).length >= this.concurrent) {
      return false
    }

    if (this.canceled) {
      return false
    }

    const item = this.queue.shift()
    if (!item) {
      return false
    }

    try {
      this.workingPromises[item.id] = true
      item.promise()
        .then(value => {
          delete this.workingPromises[item.id]
          item.resolve(value)
          this.dequeue()
        })
        .catch(err => {
          delete this.workingPromises[item.id]
          item.reject(err)
          if (this.cancelOnError === false) {
            this.dequeue()
          } else {
            this.canceled = true
            this.queue = []
          }
        })
        .finally(() => {
          if (Object.keys(this.workingPromises).length === 0) {
            this.onEnd()
          }
        })
    } catch (err) {
      delete this.workingPromises[item.id]
      item.reject(err)
      if (this.cancelOnError === false) {
        this.dequeue()
      } else {
        this.canceled = true
        this.queue = []
      }
    } finally {
      if (Object.keys(this.workingPromises).length === 0) {
        this.onEnd()
      }
    }
    return true
  }

  cancel() {
    this.queue = []
    this.canceled = true
  }

  onEnd() {
    this.onEndCallbacks.forEach(cb => {
      cb()
    })
  }

  on(typeOfEvent, callback) {
    if (typeOfEvent === 'end') {
      this.onEndCallbacks.push(callback)
    }
  }
}
