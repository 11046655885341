import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import config from './config'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { IconButton } from 'components/Button'
import { Logo } from 'components/Logo'

import { ComponentGetter } from '../../components/ComponentGetter'

import { useBreakpoints } from 'hooks/useBreakpoints'

import { USER_TYPE } from 'model/User'

import {
  editUserAndAvatar as editUserAndAvatarAction,
} from 'store/users/actions'

import { EVENT_KEYS, logEvent } from 'services/loggingService'
import { changeRoute, createRegisterJourneyLink, dynamicLinkFactory } from 'services/routingService'

import { setLocalStorage } from 'utils/localStorage'

import './styles.scss'

const parseGoToHrefRoutes = (registerJourneyStep, state) => {
  return {
    ...registerJourneyStep,
    componentContent: {
      ...registerJourneyStep.componentContent,
      submitButton: registerJourneyStep.componentContent.submitButton && {
        ...registerJourneyStep.componentContent.submitButton,
        goToHref: registerJourneyStep.componentContent.submitButton?.goToHref &&
          dynamicLinkFactory(registerJourneyStep.componentContent.submitButton.goToHref, state),
      },
      backButton: registerJourneyStep.componentContent.backButton && {
        ...registerJourneyStep.componentContent.backButton,
        goToHref: registerJourneyStep.componentContent.backButton.goToHref &&
          dynamicLinkFactory(registerJourneyStep.componentContent.backButton.goToHref, state),
      },
      skipButton: registerJourneyStep.componentContent.skipButton && {
        ...registerJourneyStep.componentContent.skipButton,
        goToHref: registerJourneyStep.componentContent.skipButton.goToHref &&
          dynamicLinkFactory(registerJourneyStep.componentContent.skipButton.goToHref, state),
      },
    },
  }
}

function RegisterJourney() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id: journeyId } = useParams()
  const selectedStepsIdx = Math.max(config.findIndex(data => data.id === journeyId), 0)
  const { isMobile } = useBreakpoints()
  const currentUser = useSelector(state => state.users.users[state.users.currentUser.uid])
  const registerJourney = useSelector(
    state => config
      .map(registerJourneyStep => parseGoToHrefRoutes(registerJourneyStep, state))
  )

  const editUserAndAvatar = useCallback(
    (userInfo, avatarFile) => dispatch(editUserAndAvatarAction(currentUser, userInfo, avatarFile, false)),
    [dispatch, currentUser]
  )

  const getUserInfoToSave = inputsState => {
    const toSaveUserInfo = {}

    inputsState.forEach((inputState, index) => {
      const input = registerJourney[selectedStepsIdx].componentContent.inputs[index]
      switch (input.id) {
        case 'avatar':
          toSaveUserInfo.avatar = inputState.src
          toSaveUserInfo.avatarFile = inputState
          break
        case 'name':
          toSaveUserInfo.name = inputState
          break
        case 'location':
          toSaveUserInfo.location = {
            address: inputState,
          }
          break
        case 'professional-services':
          toSaveUserInfo.services = (inputState || []).map(service => ({ name: service }))
          break
        case 'professional-services-categories':
          toSaveUserInfo.categories = (inputState || []).map(category => ({ name: category }))
          break
        case 'user-type':
          toSaveUserInfo.type = inputState === 1 ? USER_TYPE.PROFESSIONAL : USER_TYPE.CLIENT
          break
        default:
          break
      }
    })

    return toSaveUserInfo
  }

  const saveUserInfo = (inputsState, toSaveUserInfo = {}) => {
    setLocalStorage(registerJourney[selectedStepsIdx].componentContent.id, JSON.stringify(inputsState))

    if (Object.keys(toSaveUserInfo).length > 0) {
      onSaveUserInfo(toSaveUserInfo)
    }
  }

  const onBack = inputsState => {
    const toSaveUserInfo = getUserInfoToSave(inputsState)
    saveUserInfo(inputsState, toSaveUserInfo)

    logEvent(EVENT_KEYS.REGISTER_JOURNEY.BACK_CTA, {
      journeyId,
    })
    onGenericButtonClick(registerJourney[selectedStepsIdx].componentContent.backButton)
  }

  const onSkip = () => {
    logEvent(EVENT_KEYS.REGISTER_JOURNEY.SKIP_CTA, {
      journeyId,
    })
    onGenericButtonClick(registerJourney[selectedStepsIdx].componentContent.skipButton)
  }

  const onGenericButtonClick = button => {
    const goToHref = button.goToHref

    if (goToHref) {
      changeRoute(history, goToHref)
      return
    }

    const previousId = button.goToId
    const previousIndex = Math.max(registerJourney.findIndex(data => data.id === previousId), 0)
    changeRoute(history, createRegisterJourneyLink(registerJourney[previousIndex].id, null, false))
  }

  const getInitialValues = () => {
    const currentRegisterJourneyInputs = registerJourney[selectedStepsIdx].componentContent?.inputs || []
    return currentRegisterJourneyInputs.reduce((prev, input) => {
      switch (input.id) {
        case 'name':
          prev.push(currentUser.name)
          break
        case 'avatar':
          prev.push({ src: currentUser.avatar })
          break
        case 'location':
          prev.push(currentUser.location?.address)
          break
        case 'professional':
          prev.push(currentUser.isPro ? 1 : 0)
          break
        case 'user-type':
          prev.push(currentUser.type === undefined ? undefined : currentUser.type === USER_TYPE.CLIENT ? 0 : 1)
          break
        case 'professional-services':
          prev.push(currentUser.services?.map(service => service.name) || [])
          break
        case 'professional-services-categories':
          prev.push(currentUser.categories?.map(category => category.name) || [])
          break
        default:
          break
      }
      return prev
    }, [])
  }

  const onSubmit = inputsState => {
    const toSaveUserInfo = getUserInfoToSave(inputsState)
    saveUserInfo(inputsState, toSaveUserInfo)

    logEvent(EVENT_KEYS.REGISTER_JOURNEY.NEXT_CTA, {
      journeyId,
      userType: toSaveUserInfo.type,
    })

    const goToHref = registerJourney[selectedStepsIdx].componentContent.submitButton.goToHref

    if (goToHref) {
      changeRoute(history, goToHref)
      return
    }

    const handleGoToId = registerJourney[selectedStepsIdx].componentContent.submitButton.handleGoToId

    if (handleGoToId) {
      // iterate assertions of type: [inputIndex, inputStateValue, next component id]
      for (let index = 0; index < handleGoToId.length; index++) {
        const assertion = handleGoToId[index]
        const inputIndex = assertion[0]
        const toCheckValue = assertion[1]
        const nextId = assertion[2]

        if (inputsState[inputIndex] === toCheckValue) {
          const nextIndex = Math.max(registerJourney.findIndex(data => data.id === nextId), 0)
          changeRoute(history, createRegisterJourneyLink(registerJourney[nextIndex].id, null, false))
          return
        }
      }
    }

    const nextId = registerJourney[selectedStepsIdx].componentContent.submitButton.goToId
    const nextIndex = Math.max(registerJourney.findIndex(data => data.id === nextId), 0)
    changeRoute(history, createRegisterJourneyLink(registerJourney[nextIndex].id, null, false))
  }

  const onSaveUserInfo = info => {
    editUserAndAvatar(info, info.avatarFile)
  }

  return (
    <div className='register-journey'>
      <Logo className='register-journey__logo' />
      {isMobile && selectedStepsIdx > 0 && (
        <IconButton
          className='register-journey__go-back-button'
          aria-label='Go back'
          onClick={onBack}
          color='inherit'
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      <ComponentGetter
        onBack={onBack}
        onSubmit={onSubmit}
        onSkip={onSkip}
        componentId={registerJourney[selectedStepsIdx].componentId}
        componentContent={registerJourney[selectedStepsIdx].componentContent}
        initialValues={getInitialValues()}
      />
    </div>
  )
}

export default RegisterJourney
